<div class="p-grid table-demo">
    <div class="p-col-12">
     <div class="card">
         <p-tabView [activeIndex]="index">
   <p-tabPanel leftIcon="pi pi-sign-in">
    <ng-template pTemplate="header">
      Pending Upload ({{ Count }})
   </ng-template>
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px ;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Product InCollaboration Tasks - Pending Upload</h5> 
        <span class="p-input-icon-left" style="float:right;">
          <p-checkbox (click)="getproducts('Active')" label="My Role" binary="false" class="padding" [(ngModel)]="MyRoleValue" ></p-checkbox>
          <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
            <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
            <input pInputText type="text" (input)="CountGlobalFilter($event.target.value,dtactive)"
            placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('invoice-content','trSearch')" ></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px;" (click)="download('excel-table')"></button>
          </span>
        </span> 
    </div>
    <div class="invoice invoice-header" id="invoice-content">
    <p-table #dtactive [value]="activeproducts" id="excel-table" [columns]="cols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
    styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedactiveProducts" [scrollable]="true" scrollHeight="550px"
    [globalFilterFields]="['productName','phase','templates','departmentName','documentName','roleName','documentLabels']"
       dataKey="productID"> 
       <ng-template pTemplate="header" let-columns class="invoice-items">
        <tr>              
          <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
          [ngClass]="{'isrequire':i == 1,'width180':i==2,'width150':i==3,'width120':i==5, 'width100':i ==6,'w':i==0 || i ==4}" >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
          <th style="text-align: center;width: 100px;">Actions</th>
        </tr>
        <tr id="trSearch">
          <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
            <input pInputText type="text" (input)="CountFilter($event.target.value,col.field,dtactive)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px ;"/>
        </th>
          <th style="text-align: center"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
        <tr [pSelectableRow]="rowData">
          <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
            <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 30%;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 1" style="text-align: center;width: 120px;font-weight: bold;" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 180px;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 150px">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 30%;">{{ rowData[col.field] }}</td>
            <!-- <td *ngIf="i == 5" style="text-align: center;width: 120px;">{{ rowData[col.field] }}</td> -->
            <td *ngIf="i == 5" style="text-align: center;width: 120px;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 6" style="text-align:center; width: 100px;">
              <ul *ngFor="let temp of rowData.templates" class="tempul">
                  <li class="templi"><span *ngIf="temp.isDocAccess==true"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                    <span *ngIf="temp.isDocAccess!=true">{{temp.templateName}}</span></li>
              </ul>
            </td>
          </ng-container>
          <td style="text-align: center;width: 100px;">
            <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="Details(Data.productID, Data.phaseID,Data.type,Data.transactionID)"
              icon="pi pi-eye" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align: left;">No records found...</td>
        </tr>
    </ng-template>

         </p-table>
    </div>
     </p-tabPanel>
     <p-tabPanel leftIcon="pi pi-upload">
      <ng-template pTemplate="header">
        Uploaded ({{ Count1 }})
     </ng-template>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Product InCollaboration Tasks - Uploaded</h5> 
            <span class="p-input-icon-left" style="float:right;">
              <p-checkbox (click)="getproducts('Completed')" label="My Role" binary="false" class="padding" [(ngModel)]="MyRoleValueC" ></p-checkbox>
              <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
                <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
                <input pInputText type="text" (input)="Count1GlobalFilter($event.target.value,dtcomplete)"
                placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
                  <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('invoice-content1','trSearch1')" ></button>
                  <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px;" (click)="download('excel-table1')"></button>
              </span>
            </span>
        </div>
        <div class="invoice invoice-header" id="invoice-content1">
        <p-table #dtcomplete [value]="completedproducts" id="excel-table1" [columns]="cols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
        styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedcompletedProducts" [scrollable]="true" scrollHeight="550px"
        [globalFilterFields]="['productName','phase','templates','departmentName','documentName','roleName','documentLabels']"
           dataKey="productID"> 
           <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>              
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
              [ngClass]="{'isrequire':i == 1,'width180':i==2,'width150':i==3,'width120':i==5, 'width100':i ==6,'w':i==0 || i ==4}" >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
              <th style="text-align: center;width: 100px;">Actions</th>
            </tr>
            <tr id="trSearch1">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                <input pInputText type="text" (input)="Count1Filter($event.target.value,col.field,dtcomplete)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px;"/>
            </th>
              <th style="text-align: center"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 30%;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 1" style="text-align: center;width: 120px;font-weight: bold;" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 180px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 150px">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 30%;">{{ rowData[col.field] }}</td>
                <!-- <td *ngIf="i == 5" style="text-align: center;width: 120px;">{{ rowData[col.field] }}</td> -->
                <td *ngIf="i == 5" style="text-align: center;width: 120px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 6" style="text-align:center; width: 100px;">
                  <ul *ngFor="let temp of rowData.templates" class="tempul">
                      <li class="templi"><span *ngIf="temp.isDocAccess==true"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                        <span *ngIf="temp.isDocAccess!=true">{{temp.templateName}}</span></li>
                  </ul>
                </td>
              </ng-container>
              <td style="text-align: center;width: 100px;">
                <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="Details(Data.productID, Data.phaseID,Data.type,Data.transactionID)"
                icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" style="text-align: left;">No records found...</td>
            </tr>
        </ng-template>    
             </p-table>
        </div>
         </p-tabPanel>
         </p-tabView>
     </div>
 </div>
 </div>

 <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>