import { Component, OnInit, ViewChild } from '@angular/core';
import {MyDocuments} from "../../model/DashboardData";
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../services/printcommon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-mydocuments',
  templateUrl: './mydocuments.component.html',
  styleUrls: ['./mydocuments.component.scss']
})
export class MyDocumentsComponent implements OnInit {
  MyRoleValue:boolean = true;
  MyRoleValueC:boolean = true;
  userId = sessionStorage.getItem('userGuid');
  loading: "loading";
  showLoading:boolean;
  userintId = sessionStorage.getItem("userId");
  cols: any[];
  activeproducts: any;
  selectedactiveProducts: MyDocuments[];
  @ViewChild('dtactive') tableactive: Table;

  completedproducts: any;
  selectedcompletedProducts: MyDocuments[];
  @ViewChild('dtcomplete') tablecomplete: Table;

  index: number = 0;
  Count: number = 0;
  Count1: number = 0;
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintcommonService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
      this.showLoading = true; 
      this.MyRoleValue = true;
      this.MyRoleValueC = true;
      this.cols = [
        { field: 'productName', header: 'Product' },
        { field: 'phase', header: 'Phase' },
        { field: 'departmentName', header: 'Department' },
        { field: 'roleName', header: 'Role' },
        { field: 'documentName', header: 'Task' },
        // { field: 'isRequired', header: 'Task Required' },
        { field: 'documentLabels', header: 'Document Submission' },
        { field: 'templates', header: 'Template' },
      ];
      sessionStorage.productName = "";
      sessionStorage.phase = "";
      sessionStorage.departmentName = "";
      sessionStorage.roleName = "";
      sessionStorage.documentName = "";
      // sessionStorage.isRequired = "";
      sessionStorage.documentLabels = "";
      sessionStorage.templates = "";
      
      sessionStorage.productName1 = "";
      sessionStorage.phase1 = "";
      sessionStorage.departmentName1 = "";
      sessionStorage.roleName1 = "";
      sessionStorage.documentName1 = "";
      // sessionStorage.isRequired1 = "";
      sessionStorage.documentLabels1 = "";
      sessionStorage.templates1 = "";
      this.getproducts("Active");
      this.getproducts("Completed");
      this.index = 0;
      if(this.route.snapshot.paramMap.get('index')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('index');
        if(ii != '0' && ii != '')
          this.index = Number(ii);
      }
  }
  CountM()
  {
    if(this.activeproducts != undefined && this.activeproducts != null)
    {
      var temp = this.activeproducts;
      if(sessionStorage.productName != "")
        temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
      if(sessionStorage.phase != "")
        temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase.toLowerCase()));
      if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
      if(sessionStorage.roleName != "")
        temp = temp.filter(x=> x.roleName.toLowerCase().startsWith(sessionStorage.roleName.toLowerCase()));
      if(sessionStorage.documentName != "")
        temp = temp.filter(x=> x.documentName.toLowerCase().startsWith(sessionStorage.documentName.toLowerCase()));
      // if(sessionStorage.isRequired != "")
      //   temp = temp.filter(x=> x.isRequired.toLowerCase().startsWith(sessionStorage.isRequired.toLowerCase()));
      if(sessionStorage.documentLabels != "")
        temp = temp.filter(x=> x.documentLabels.toLowerCase().startsWith(sessionStorage.documentLabels.toLowerCase()));
      if(sessionStorage.templates != "")
        temp = temp.filter(x=> x.templates.toLowerCase().startsWith(sessionStorage.templates.toLowerCase()));
      this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  CountFilter(val,field,dt)
  {
    if(field == "productName")
      sessionStorage.productName = val;
    else if(field == "phase")
      sessionStorage.phase = val;
    else if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "documentName")
      sessionStorage.documentName = val;
    else if(field == "roleName")
      sessionStorage.roleName = val;
    else if(field == "documentLabels")
      sessionStorage.documentLabels = val;
    else if(field == "templates")
      sessionStorage.templates = val;
    dt = dt.filter(val,field, 'startsWith');
    this.CountM();
  }
  CountM1()
  {
    if(this.completedproducts != undefined && this.completedproducts != null)
    {
      var temp = this.completedproducts;
      if(sessionStorage.productName1 != "")
        temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName1.toLowerCase()));
      if(sessionStorage.phase1 != "")
        temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase1.toLowerCase()));
      if(sessionStorage.departmentName1 != "")
        temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName1.toLowerCase()));
      if(sessionStorage.documentName1 != "")
        temp = temp.filter(x=> x.documentName.toLowerCase().startsWith(sessionStorage.documentName1.toLowerCase()));
      if(sessionStorage.roleName1 != "")
        temp = temp.filter(x=> x.roleName.toLowerCase().startsWith(sessionStorage.roleName1.toLowerCase()));
      if(sessionStorage.documentLabels1 != "")
        temp = temp.filter(x=> x.documentLabels.toLowerCase().startsWith(sessionStorage.documentLabels1.toLowerCase()));
      if(sessionStorage.templates1 != "")
        temp = temp.filter(x=> x.templates.toLowerCase().startsWith(sessionStorage.templates1.toLowerCase()));
      this.Count1 = temp.length;
    }
    else
      this.Count1 = 0;
  }
  Count1Filter(val,field,dt)
  {
    if(field == "productName")
      sessionStorage.productName1 = val;
    else if(field == "phase")
      sessionStorage.phase1 = val;
    else if(field == "departmentName")
      sessionStorage.departmentName1 = val;
    else if(field == "documentName")
      sessionStorage.documentName1 = val;
    else if(field == "roleName")
      sessionStorage.roleName1 = val;
    else if(field == "documentLabels")
      sessionStorage.documentLabels1 = val;
    else if(field == "templates")
      sessionStorage.templates1 = val;
    dt = dt.filter(val,field, 'startsWith');
    this.CountM1();
  }
  CountGlobalFilter(val,dt)
  {
    sessionStorage.productName = val;
    sessionStorage.phase = val;
    sessionStorage.departmentName = val;
    sessionStorage.documentName = val;
    sessionStorage.roleName = val;
    sessionStorage.documentLabels = val;
    sessionStorage.templates = val;
    dt = dt.filterGlobal(val, 'startsWith');
    this.CountM();
  }
  Count1GlobalFilter(val,dt)
  {
    sessionStorage.productName1 = val;
    sessionStorage.phase1 = val;
    sessionStorage.departmentName1 = val;
    sessionStorage.documentName1 = val;
    sessionStorage.roleName1 = val;
    sessionStorage.documentLabels1 = val;
    sessionStorage.templates1 = val;
    dt = dt.filterGlobal(val, 'startsWith');
    this.CountM1();
  }

  getproducts(Type) {
    this.showLoading = true;
    var t :boolean = true;
    if(Type == "Active")
      t = this.MyRoleValue;
    else
      t = this.MyRoleValueC;
    this._facadeService.Documents(this.userId, Type,true,t).subscribe(
      (data) => {
        if (Type == "Active") {
          this.activeproducts = data.returnObject;
          this.CountM();
        }
        if (Type == "Completed") {
          this.completedproducts = data.returnObject;
          this.CountM1();
        }
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  download(val) {
      /* table id is passed over here */   
      let element = document.getElementById(val); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 250 },{ wpx : 100 },{ wpx : 150 },{ wpx : 150 },{ wpx : 420 },{ wpx : 150 },{ wpx : 150 }];
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      if(val == "excel-table")
        XLSX.writeFile(wb, "My Product Tasks - Pending Upload.xlsx");
       if(val == "excel-table1")
        XLSX.writeFile(wb, "My Product Tasks - Uploaded.xlsx");
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  Details(ProductID, PhaseID,Type,TransactionID) {
    sessionStorage.ProductID = ProductID;
    sessionStorage.PhaseID = PhaseID;
    sessionStorage.DisplayType = Type;
    sessionStorage.TransactionID = TransactionID;
    sessionStorage.FromWhere = "mdoc";
    this.router.navigate(['/phasedetails']);
  }
  print(MainID,SearchID) {
    var Content = document.getElementById(MainID).innerHTML;
    var Title = "";
    if(MainID == "invoice-content")
      Title =  "My Product Tasks - Pending Upload";
    if(MainID == "invoice-content1")
      Title = "My Product Tasks - Uploaded";
    this.printService.Print(Content,Title,SearchID,true);
}  
DMSToken(rowData){
  this.showLoading = true;
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadDMSFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');
}
}
