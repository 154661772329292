import { Injectable } from "@angular/core";
import { HttputilityService } from "./httputility.service";
@Injectable({
  providedIn: "root",
})
export class ApprovalService {
  constructor(private _httputilityService: HttputilityService) {}

  GetUserApproved(model)
  {
    return this._httputilityService.Add("Approval/GetApprovedDetails", model);
  }
  ApprovalList(UserID) {
    return this._httputilityService.Get("Approval/GetApprovalProducts?UserID=" + UserID,"");
  }
  ApprovalCancelList(UserID) {
    return this._httputilityService.Get("Approval/GetCancelProducts?UserID=" + UserID,"");
  }
  ClarificationProducts(UserID,PE) {
    return this._httputilityService.Get("Approval/GetClarificationProducts?UserID=" + UserID+"&PE="+ PE,"");
  }
  
  SendApproval(model) {
    return this._httputilityService.Add("Approval/SendforApproval", model);
  }
  Reminder(model) {
    return this._httputilityService.Add("Approval/ReminderforApproval", model);
  }
  ECCNReminder(ProductID)
  {
    return this._httputilityService.Get("Approval/ECCNReminder?ProductID="+ProductID,"");
  }
  SendCancelApproval(model) {
    return this._httputilityService.Add("Approval/SendCancelApproval", model);
  }
  CancelWithdraw(model) {
    return this._httputilityService.Add("Approval/CancelWithdraw", model);
  }
  CancelReminder(model) {
    return this._httputilityService.Add("Approval/ReminderCancelApproval", model);
  }
  ClarificationDetails(PhaseID) {
    return this._httputilityService.Get("Approval/GetApprovalClarificationDetails?PhaseID=" + PhaseID,"");
  }

  ClarificationDetailsbyID(ID,Type) {
    return this._httputilityService.Get("Approval/GetClarificationDetailsbyID?ID=" + ID+"&Type=" + Type,"");
  }
  ClarificationDetailsbyParentID(ID,Type) {
    return this._httputilityService.Get("Approval/GetClarificationDetailsbyParentID?ID=" + ID+"&Type=" + Type,"");
  }

  TeamAction(model) {
    return this._httputilityService.Add("Approval/TeamAction", model);
  }

  CancelApprovalDetails(ProductID) {
    return this._httputilityService.Get("Approval/GetCancelDetails?ProductID=" + ProductID,"");
  }
  GetAllCancelApprovalDetails(ProductID) {
    return this._httputilityService.Get("Approval/GetAllCancelApprovalDetails?ProductID=" + ProductID,"");
  }
  GetCancelClarificationDetails(ProductID) {
    return this._httputilityService.Get("Approval/GetCancelClarificationDetails?ProductID=" + ProductID,"");
  }

  ApprovalDetails(PhaseID) {
    return this._httputilityService.Get("Approval/GetApprovalDetails?PhaseID=" + PhaseID,"");
  }
  GetApprovalDetailsByParentID(ID,Type) {
    return this._httputilityService.Get("Approval/GetApprovalDetailsByParentID?ID=" + ID + "&Type=" + Type,"");
  }
  
  Action(model) {
    return this._httputilityService.Add("Approval/Action", model);
  }
}
