<br />
<p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
<br />
<div class="card" *ngIf="activeIndex==0" style="min-height:680px;">
    <br>
    <div class="p-fluid p-formgrid p-grid" style="margin-right:5px;">
         <div class="p-field p-col-12 p-md-1"></div>
        <div class="p-field p-col-12 p-md-2">
            <label for="Phase"> Product Line<span style="color: red">*</span></label>
            <p-dropdown placeholder="Select Product Line" optionLabel="name" [disabled]="this.tmpProductId>0?true:false"
            [options]="ProdLineData" [(ngModel)]="selectedProdLine" (onChange)="ChangeProduLine()"
              ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="Phase">Template<span style="color: red">*</span></label>
            <p-dropdown [options]="templatesData" [disabled]="this.tmpProductId>0?true:false" placeholder="Select Template" optionLabel="templateName"
            [(ngModel)]="selectedTemplate" (onChange)="ChangeTemplate()"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="productName">Project Name <span style="color: red">*</span></label>
           <input id="productName" type="text" maxlength="80" [(ngModel)]="productName" pInputText placeholder="Enter Project"/>
           <small>{{80 - productName?.length}} characters are remaining</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="targetDate"> Target Start Date <span style="color: red">*</span> </label>
            <p-calendar [showIcon]="true" [(ngModel)]="targetDate" inputId="icon" placeholder="Start Date" [disabledDays]="[0,6]" ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="Phase"> Product Engineer<span style="color: red">*</span></label>
            <p-dropdown [options]="prodOwnerData" [(ngModel)]="selectedProdOwner" placeholder="Select Product Engineer" optionLabel="fullName"
               ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-1"></div> 
       
        <div class="p-field p-col-12">
            <label> Notes</label>
            <textarea type="text" [(ngModel)]="description" rows="4" pInputTextarea placeholder="Enter Notes"></textarea>
        </div>
       
    </div>

    <br>
    <p-tabView styleClass="orientation-right" (onChange)="GetCustomFieldsById($event)" *ngIf="showCust">
        <p-tabPanel header="{{item.customGroupName}}" *ngFor="let item of customGroupsData">
            <div style="height: 420px; overflow-y: scroll; border: 1px solid #e9ecef;margin-top:-14px;">  
                <p-table [value]="CustomFieldgroupId" [(selection)]="selectedCustomField" dataKey="item.customGroupGuid"
                styleClass="p-datatable-striped" [rowHover]="true" [paginator]="false" [filterDelay]="0" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:300px">
                                Custom Attribute
                            </th>
                            <th style="width: 325px;">
                                Value
                            </th>
                            <th style="width: 130px;">
                                CCB Approval
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product>
                        <tr *ngIf="product.fieldName!='Product Line'">
                            <td style="padding-left: 15px;">{{ product.fieldName }}<span *ngIf="product.isRequired" style="color: red">*</span></td>
                            <td>
                                <select class="select-css width100" *ngIf="product.entryType === 'DropDown'"
                                    [(ngModel)]="datasourcelist[product.customFieldID.toString()].value"
                                    aria-placeholder="Select" (change)="CustomAttribute($event,datasourcelist[product.customFieldID.toString()].value,product.customFieldID,datasourcelist[product.customFieldID.toString()].list,product.parentDependent)">
                                    <option [value]="null">Select</option>
                                    <option *ngFor="let item of datasourcelist[product.customFieldID.toString()].list"
                                        [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                                <input *ngIf="product.entryType === 'TextBox' && product.length > 0" style="text-align: center;;width: 100%;" [disabled]="product.isDisabled"
                                [(ngModel)]="datasourcelist[product.customFieldID.toString()].value"
                                  type="text" pInputText  (change)="CustomAttribute($event,datasourcelist[product.customFieldID.toString()].value,product.customFieldID,null,product.parentDependent)"
                                   [attr.maxlength]="product.length" />
                                <input *ngIf="product.entryType === 'TextBox' && product.length == 0" style="text-align: center;;width: 100%;" [disabled]="product.isDisabled"
                                [(ngModel)]="datasourcelist[product.customFieldID.toString()].value"
                                type="text" pInputText  (change)="CustomAttribute($event,datasourcelist[product.customFieldID.toString()].value,product.customFieldID,null,product.parentDependent)"
                                    [attr.maxlength] = "10" />
                            </td>
                            <td style="text-align: center;"><span *ngIf="product.ccbApproval === true">Yes</span><span
                                    *ngIf="product.ccbApproval === false">No</span></td>
                        </tr>
                    </ng-template>
    
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">No custom fields found...</td>
                        </tr>
                    </ng-template>
                </p-table>
        </div>
        </p-tabPanel>
    </p-tabView>
    <div style="float:right; margin-right:50px;">
        <small class="p-invalid">{{ Step1Error }}</small>
    </div><br>
    <hr>
   <div><br>
    <button pButton type="button" label="Save and Resume later" (click)="step1('R')" class="p-button-success" icon="pi pi-check"
        iconPos="left" style="float:right;margin-right:10px; margin-top:-25px;"></button>
    <button pButton type="button" label="Next" (click)="step1('')" class="p-button-info" icon="pi pi-chevron-right"
        iconPos="left" style="float:right;margin-right:10px; margin-top:-25px;"></button>
    </div>
</div>

<div class="card" *ngIf="activeIndex==1">
    <p><b>Project Name:</b> <span style="margin-left:10px;">{{productName}}</span></p>
    <p-tabView styleClass="orientation-right" (onChange)="GetTeamDetails($event)">
        <p-tabPanel header="{{item.phaseName}}" *ngFor="let item of PhasesData">
            <div style="margin-top:-14px;border: 1px solid #e9ecef;">
                <p-accordion>
                    <p-accordionTab header="Team" [selected]="true">
                        <div class="p-fluid p-grid">
                            <div class="p-col-12 p-md-10"> <small class="p-invalid" style="float: right;padding-top: 10px;">{{ TeamError }}</small></div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-input-icon-left">
                                    <button [disabled]="Tclicked" (click)="Tclicked = true;" pButton type="button" icon="pi pi-plus" class="ui-button-info" 
                                    style="float: right; width:120px;" [label]="'Add New'" pAddRow [table]="dtT" [newRow]="newRow('Team')" ></button>
                                </span>
                            </div>
                            <div style="margin-left: 15px;border: 2px solid #e9ecef;">
                                <p-table #dtT [value]="PhaseTeamData" styleClass="p-datatable-striped" class="table-align" editMode="row" selectionMode="multiple" dataKey="userGUID">
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr style="margin-left: 15px;"> 
                                        <th>Department</th>
                                        <th>Role</th>
                                        <th>User</th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-team  let-editing="editing">
                                    <tr *ngIf="team.type=='Team'" [pEditableRow]="team" >
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="departmentsData" [(ngModel)]="selectedTeamDepartment" placeholder="Select Department" 
                                                    optionLabel="departmentName" (onChange)="SelectDepartment('T')"></p-dropdown>
                                                    <small class="p-invalid">{{ departmentNameError }}</small>
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.departmentName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="rolesData" [(ngModel)]="selectedTeamRole" placeholder="Select Role"
                                                        optionLabel="roleName" (onChange)="SelectRole('T')"></p-dropdown>
                                                    <small class="p-invalid">{{ roleNameError }}</small> 
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.roleName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="usersData" [(ngModel)]="selectedTeamUser" placeholder="Select User" 
                                                    optionLabel="fullName" (onChange)="addCancelTeam('T')"></p-dropdown>
                                                    <small class="p-invalid">{{ userNameError }}</small>
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.userName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:center">
                                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" pTooltip="Edit" tooltipPosition="right"
                                            (click)="onRowEdit(team,'T')" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                                            <button *ngIf="!editing" pButton type="button" icon="pi pi-minus" class="ui-button-info" (click)="Delete(team,'T')" pTooltip="Delete" tooltipPosition="right"
                                                style="height:25px; width:25px;"></button>
                                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times-circle" class="ui-button-danger"pTooltip="Cancel" tooltipPosition="right"
                                                (click)="onRowEditCancel(team, 'T')" style="height:25px; width:25px;"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage"><tr><td colspan="4" style="text-align: left;">No records found...</td></tr> </ng-template>
                            </p-table>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion>
                    <p-accordionTab header="Approvers">
                        <div class="p-fluid p-grid">
                            <div class="p-col-12 p-md-10"> <small class="p-invalid" style="float: right;padding-top: 10px;">{{ ApproverError }}</small></div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-input-icon-left">
                                    <button [disabled]="Aclicked" (click)="Aclicked = true;" pButton type="button" icon="pi pi-plus" class="ui-button-info" 
                                    style="float: right; width:120px;" [label]="'Add New'" pAddRow [table]="dtA" [newRow]="newRow('Approval')" ></button>
                                </span>
                            </div>
                            <div style="margin-left: 15px;border: 2px solid #e9ecef;">
                                <p-table #dtA [value]="PhaseTeamData" styleClass="p-datatable-striped" class="table-align" editMode="row" selectionMode="multiple" dataKey="userGUID">
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr style="margin-left: 15px;"> 
                                        <th>Department</th>
                                        <th>Role</th>
                                        <th>User</th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-team  let-editing="editing">
                                    <tr *ngIf="team.type=='Approval'" [pEditableRow]="team" >
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="departmentsAData" [(ngModel)]="selectedApproverDepartment" placeholder="Select Department" 
                                                    optionLabel="departmentName" (onChange)="SelectDepartment('A')"></p-dropdown>
                                                    <small class="p-invalid">{{ departmentNameAError }}</small>
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.departmentName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="rolesAData" [(ngModel)]="selectedApproverRole" placeholder="Select Role"
                                                        optionLabel="roleName" (onChange)="SelectRole('A')"></p-dropdown>
                                                    <small class="p-invalid">{{ roleNameAError }}</small> 
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.roleName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="usersAData" [(ngModel)]="selectedApproverUser" placeholder="Select User" 
                                                    optionLabel="fullName" (onChange)="addCancelTeam('A')"></p-dropdown>
                                                    <small class="p-invalid">{{ userNameAError }}</small>
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.userName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:center">
                                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" pTooltip="Edit" tooltipPosition="right" class="ui-button-info" 
                                            (click)="onRowEdit(team,'A')" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                                            <button *ngIf="!editing" pButton type="button" icon="pi pi-minus" class="ui-button-info" (click)="Delete(team,'A')"  pTooltip="Delete" tooltipPosition="right"
                                                style="height:25px; width:25px;"></button>
                                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times-circle" class="ui-button-danger" pTooltip="Cancel" tooltipPosition="right"
                                                (click)="onRowEditCancel(team, 'A')" style="height:25px; width:25px;"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage"><tr><td colspan="4" style="text-align: left;">No records found...</td></tr> </ng-template>
                            </p-table>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion>
                    <p-accordionTab header="Notification Recipients">
                        <div class="p-fluid p-grid">
                            <div class="p-col-12 p-md-10"> <small class="p-invalid" style="float: right;padding-top: 10px;">{{ NotiError }}</small></div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-input-icon-left">
                                    <button [disabled]="Nclicked" (click)="Nclicked = true;" pButton type="button" icon="pi pi-plus" class="ui-button-info" 
                                    style="float: right; width:120px;" [label]="'Add New'" pAddRow [table]="dtN" [newRow]="newRow('Collaborator')" ></button>
                                </span>
                            </div>
                            <div style="margin-left: 15px;border: 2px solid #e9ecef;">
                                <p-table #dtN [value]="PhaseTeamData" styleClass="p-datatable-striped" class="table-align" editMode="row" selectionMode="multiple" dataKey="userGUID">
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr style="margin-left: 15px;"> 
                                        <th>Department</th>
                                        <th>Role</th>
                                        <th>User</th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-team  let-editing="editing">
                                    <tr *ngIf="team.type=='Collaborator'" [pEditableRow]="team" >
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="departmentsNData" [(ngModel)]="selectedNotiDepartment" placeholder="Select Department" 
                                                    optionLabel="departmentName" (onChange)="SelectDepartment('N')"></p-dropdown>
                                                    <small class="p-invalid">{{ departmentNameNError }}</small>
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.departmentName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="rolesNData" [(ngModel)]="selectedNotiRole" placeholder="Select Role"
                                                        optionLabel="roleName" (onChange)="SelectRole('N')"></p-dropdown>
                                                    <small class="p-invalid">{{ roleNameNError }}</small> 
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.roleName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="usersNData" [(ngModel)]="selectedNotiUser" placeholder="Select User" 
                                                    optionLabel="fullName" (onChange)="addCancelTeam('N')"></p-dropdown>
                                                    <small class="p-invalid">{{ userNameNError }}</small>
                                                </ng-template>
                                                <ng-template pTemplate="output">{{team.userName}}</ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align:center">
                                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" pTooltip="Edit" tooltipPosition="right"
                                            (click)="onRowEdit(team,'N')" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                                            <button *ngIf="!editing" pButton type="button" icon="pi pi-minus" class="ui-button-info" (click)="Delete(team,'N')" pTooltip="Delete" tooltipPosition="right"
                                                style="height:25px; width:25px;"></button>
                                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times-circle" class="ui-button-danger" pTooltip="Cancel" tooltipPosition="right"
                                                (click)="onRowEditCancel(team, 'N')" style="height:25px; width:25px;"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage"><tr><td colspan="4" style="text-align: left;">No records found...</td></tr> </ng-template>
                            </p-table>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>              
            </div>
        </p-tabPanel>
    </p-tabView>
    <div style="float:right; margin-right:50px;">
        <small class="p-invalid">{{ Step3Error }}</small>
    </div><br>
    <hr>
    <button pButton type="button" label="Back" class="p-button-info" icon="pi pi-chevron-left" iconPos="left"
        (click)="stepBack1()"></button>
    <button (click)="step2('R')" pButton type="button" label="Save and Resume later" class="p-button-success" icon="pi pi-check"
        iconPos="left" style="float:right;margin-right:10px ; "></button>
    <button pButton type="button" label="Next" (click)="step2('')" class="p-button-info" icon="pi pi-chevron-right"
        iconPos="left" style="float:right;margin-right:10px ; "></button>
      
</div>
<div class="card" *ngIf="activeIndex==2">
    <p><b>Project Name:</b> <span style="margin-left:10px;">{{productName}}</span></p>
    <p-tabView styleClass="orientation-right" (onChange)="GetPhaseDetails($event)">
        <p-tabPanel header="{{item.phaseName}}" *ngFor="let item of PhasesData">
            <div style="margin-top: -14px;border: 2px solid #e9ecef;">
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-2">
                        <h5 style="margin-top: 15px;padding-left: 10px;"> Phase Owner:</h5>                    
                    </div>
                    <div class="p-field p-col-12 p-md-3" style="margin-bottom: -15px;padding-left: 25px;margin-top: 10px;">
                        <p-dropdown placeholder="Select Phase Owner" optionLabel="userName" [options]="phaseOwnerData" [(ngModel)]="selectedPhaseOwner"
                        (onChange)="OwnerChange($event,item.phaseID)"
                           ></p-dropdown> 
                    </div>
                    <div class="p-field p-col-12 p-md-7" style="margin-top: 15px;padding-left: 10px;">
                         Note: If Phase Owner is not selected Product Engineer will be Phase Owner
                    </div>
                </div>
                <p-tabView styleClass="orientation-left" id="p-tabView" [activeIndex]="index"(onChange)="function($event)" > 
                    <p-tabPanel header="Milestones" leftIcon="pi pi-star-o">
                        <p-table [value]="PhaseTaskData" dataKey="item.taskID" styleClass="p-datatable-striped">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 40px;">Seq</th>
                                    <th>Milestone</th>
                                    <th>Department</th>
                                    <th style="width:80px;">Target Start Date</th>
                                    <th style="width:150px;">Target End Date</th>
                                    <th style="width:80px;">Override</th>
                                    <th style="width:50px;">Cycle Time</th>
                                    <th style="width:50px;">Overlap Days</th>
                                    <th>Predecessor</th>
                                    <th>Assigned To</th>
                                    <th style="width:80px;">Is Weekend</th>
                                    <th style="width:100px;">Allow Multiple Assignee</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-mileStone>
                                <tr>
                                    <td style="text-align: center;">{{mileStone.taskSeq}}</td>
                                    <td style="padding-left: 15px;">{{mileStone.taskName}}</td>
                                    <td style="padding-left: 15px;">{{mileStone.departmentName}}</td>
                                    <td style="text-align: center;" [ngStyle]="{'background': mileStone.override === true ? '#ffe5b4': '','font-weight' : mileStone.override === true ? 'bold': ''}">
                                        <span *ngIf="mileStone.startDate != '--' && mileStone.startDate != null && mileStone.cycleTime != 0">{{mileStone.startDate | date:'MM/dd/yyyy'}}</span>
                                        <span *ngIf="mileStone.startDate == '--' || mileStone.startDate == null || mileStone.cycleTime == 0">--</span>
                                    </td>
                                    <td style="text-align: center;" [ngStyle]="{'background': mileStone.override === true ? '#ffe5b4': '','font-weight' : mileStone.override === true ? 'bold': ''}">
                                        <span *ngIf="mileStone.endDate != '--' && mileStone.endDate != null && mileStone.cycleTime != 0">
                                            <p-calendar [showIcon]="true" placeholder="End Date" inputId="endDate" [(ngModel)]="mileStone.endDate"
                                            [disabledDays]="mileStone.isWeekend == false ? [0,6] : ''" 
                                            [disabled]="!mileStone.override" (onClickOutside)="Getdates(mileStone.phaseID)" (onSelect)="Getdates(mileStone.phaseID)"></p-calendar>
                                        </span>
                                        <span *ngIf="mileStone.endDate == '--' || mileStone.endDate == null || mileStone.cycleTime == 0">--</span>
                                     </td>
                                     <td style="margin-top:50px;text-align: center;">
                                        <p-checkbox binary="mileStone.override" [(ngModel)]="mileStone.override" [disabled]="mileStone.cycleTime == 0"
                                        [value]="mileStone.override" (click)="OverrideChange(mileStone.taskID,$event,mileStone.phaseID)"></p-checkbox>
                                    </td>
                                    <td style="text-align: center;">
                                        <input (change)="CycleTimeChange(mileStone.taskID,$event,mileStone.phaseID)" [disabled]="!mileStone.cycleTimeEdit"
                                         (keypress)="numberOnly($event)" type="text" maxlength="3" style="width: 50px;" [(ngModel)]="mileStone.cycleTime" pInputText/>
                                    </td>
                                    <td style="text-align: center;">
                                        <input (change)="OverlapChange(mileStone.taskID,$event,mileStone.phaseID)" [disabled]="!mileStone.overlapDaysEdit"
                                         (keypress)="numberOnly($event)" type="text" maxlength="3" style="width: 50px;" [(ngModel)]="mileStone.overlapDays" pInputText/>
                                    </td>
                                    <td style="padding-left: 15px;">{{mileStone.predecessor}}</td>
                                    <td style="text-align: center;">
                                        <p-dropdown [options]="mileStone.TeamData" [(ngModel)]="mileStone.taskAssignees1" class="text" *ngIf="!mileStone.allowMultipleAssignee"
                                            placeholder="Select Assignee" optionLabel="assignedName" (onChange)="AssigneeChange($event,mileStone.taskID)"
                                            autoWidth="false" [style]="{'width':'95%'}"
                                        ></p-dropdown>
                                        <p-multiSelect [options]="mileStone.TeamData" [(ngModel)]="mileStone.taskAssignees" *ngIf="mileStone.allowMultipleAssignee" 
                                        placeholder="Select Assignee" optionLabel="assignedName" class="text"  (onChange)="AssigneeMultiChange($event,mileStone.taskID)"
                                        autoWidth="false" [style]="{'width':'95%'}"
                                        ></p-multiSelect>
                                        <!-- {{mileStone.assignTo}} -->
                                    </td>
                                    <td style="margin-top:50px;text-align: center;">
                                        <p-checkbox binary="mileStone.isWeekend" [(ngModel)]="mileStone.isWeekend" 
                                        [value]="mileStone.isWeekend" (click)="IsWeekedRequired(mileStone.taskID,$event,mileStone.phaseID)"></p-checkbox>
                                    </td>
                                    <td style="margin-top:50px;text-align: center;">
                                        <p-checkbox binary="mileStone.allowMultipleAssignee" [(ngModel)]="mileStone.allowMultipleAssignee" 
                                        [value]="mileStone.allowMultipleAssignee" (click)="IsAllowMuultipleRequired(mileStone.taskID,$event)"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template> 
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="11">There are no milestones for this product yet.</td>
                                </tr>
                            </ng-template>               
                        </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Tasks" leftIcon="pi pi-file">
                        <!-- <button pButton type="button" pTooltip="Add" tooltipPosition="right" 
                        class="p-button-raised p-button-primary p-mr-2 p-mb-2" icon="pi pi-plus" 
                        style="margin-top: 5px; height: 25px; width: 25px" ></button> -->
                        <p-table #dt [value]="PhaseDockData" dataKey="requiredDocumentID" styleClass="p-datatable-striped" editMode="row">
                            <ng-template pTemplate="header">
                                <tr>
                                    <!-- <th style="width: 60px;"></th> -->
                                    <th style="width:150px;">Department</th>
                                    <th style="width:150px;">Role</th>
                                    <th>Task</th>
                                    <th style="width:120px;">Document Label</th>
                                    <th style="width:120px;">Template</th>
                                    <th style="width:80px;">Task Required</th>
                                    <th style="width:80px;">Actions</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-editing="editing" let-index="rowIndex" let-document>
                                <tr [pEditableRow]="document" [pReorderableRow]="index">
                                    <!-- <td style="text-align: center;">
                                        <i class="pi pi-bars" pReorderableRowHandle  (handler)="updateSeq($event)" ></i>
                                         {{index}} - {{document.seq}}
                                    </td> -->
                                    <td style="padding-left: 15px;">{{document.departmentName}}</td>
                                    <td style="padding-left: 15px;">{{document.roleName}}</td>
                                    <td style="padding-left: 15px;">{{document.documentName}}</td>
                                    <td style="padding-left: 15px;">{{document.documentLabel}}</td>
                                    <td style="padding-left: 15px;">{{document.templatesNames}}</td>
                                    <td style="text-align: center;">
                                        <p-checkbox binary="document.isRequired" [(ngModel)]="document.isRequired" 
                                        [value]="document.isRequired" (click)="DocumentRequired(document.requiredDocumentID,$event)"></p-checkbox>
                                    </td>
                                    <td style="text-align:center">
                                        <span>
                                        <!-- <button pButton type="button" pTooltip="Edit" tooltipPosition="right" 
                                        class="p-button-raised p-button-primary p-mr-2 p-mb-2" icon="pi pi-pencil" 
                                        style="margin-left:15px;margin-top: 5px; height: 25px; width: 25px" ></button> -->
                                        <button (click)="DocumentDelete(document.requiredDocumentID,document.documentName)" pButton pRipple type="button" icon="pi pi-times" tooltipPosition="right" pTooltip="Delete"
                                        class="p-button-raised p-button-danger p-mr-2 p-mb-2"
                                        style="width: 25px; height:25px;margin-top: 10px;"></button>
                                    </span>    
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">There are no tasks for this product yet.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </p-tabPanel>
    </p-tabView>

    <div style="float:right; margin-right:50px;">
        <small class="p-invalid">{{ Step2Error }}</small>
    </div><br>
    <hr>
<button pButton type="button" class="p-button-info" label="Back" icon="pi pi-chevron-left" iconPos="left"
    (click)="stepBack2()"></button>&nbsp;

<button (click)="step3('R')" pButton type="button" label="Save and Resume later" class="p-button-success" icon="pi pi-check" iconPos="left"
    style="float:right;margin-right:10px ; "></button>
<button pButton type="button" label="Next" (click)="step3('')" class="p-button-info" icon="pi pi-chevron-right"
    iconPos="left" style="float:right;margin-right:10px ; "></button>
   
</div>
<div class="card" *ngIf="activeIndex==3">
    <div class="card-header">

        <h4 style="color:#495057; padding:10px 0 0 15px;">Preview and Confirmation:</h4>

    </div>
    <p-accordion>
        <p-accordionTab header="Project Details" [selected]="true">
            <div class="p-fluid p-formgrid p-grid" >
            
             
                <div class="p-field p-col-12 p-md-3" style="border-right:1px solid #eee;">
                    <p><b>Product Line:</b> <span style="color:#2196F3; margin-left:10px;float:right;">{{selectedProdLine.name}}</span></p>
 
              
                <p><b>Template:</b> <span style="color:#2196F3; margin-left:10px;float:right;">{{selectedTemplate.templateName}}</span></p>
                
            
                <p><b>Project Name:</b> <span style="color:#2196F3; margin-left:10px;float:right;">{{productName}}</span></p>
             
                  
             
                <p><b>Target Start Date:</b> <span style="color:#2196F3; margin-left:10px; float:right;">{{targetDate| date: "MM/dd/yyyy"}}</span></p>
             
             
              
                <p><b>Product Engineer:</b> <span style="color:#2196F3; margin-left:10px;float:right;">{{selectedProdOwner?.fullName}}</span></p>
                     
               </div>
              
              
               <div class="p-field p-col-12 p-md-9">
                   <p><b>Notes:</b></p>
                  <div style="border:1px solid #eee; min-height:130px;">
                    <p style="padding:10px;">
                       {{description}}
                    </p>

                  </div>
               </div>
              
           </div>
            <div class="card-header">
               
                <h4 style="color:#495057; padding:10px 0 0 15px;"></h4> 
                <span class="p-input-icon-left" style="float:right;margin-bottom:8px;">

                    <button (click)="FirstStepEdit()" pButton type="button" label="Edit" class="p-button-info" icon="pi pi-pencil" iconPos="left"
                        style="margin-right:10px ; "></button>
                </span>
            </div>
            <div style="width:100%;height: 380px; overflow-y: scroll; border: 2px solid #e9ecef;">  
                <p-table [value]="CustomFieldsFromById" dataKey="tmpCustomFieldId"
                    styleClass="p-datatable-striped" [rowHover]="true" [paginator]="false" [filterDelay]="0" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:300px">
                                Custom Group
                            </th>
                            <th style="width:300px">
                                Custom Attribute
                            </th>
                            <th style="width: 325px;">
                                Value
                            </th>
                            <th style="width: 130px;">
                                CCB Approval
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product>
                        <tr *ngIf="product.customFieldValueName!=''&&product.customFieldValueName!=undefined">
                            <td style="padding-left: 15px;text-align: left;">{{ product.customGroupName }}</td>
                            <td style="padding-left: 15px;text-align: left;">{{ product.fieldName }}</td>
                            <td style="padding-left: 15px;text-align: left;">
                                {{ product.customFieldValueName }}
                            </td>
                            <td style="text-align: center;"><span *ngIf="product.ccbApproval === true">Yes</span><span
                                    *ngIf="product.ccbApproval === false">No</span></td>
                        </tr>
    
                    </ng-template>
    
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">No custom fields found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-accordionTab>
    </p-accordion>

    <p-accordion>
        <p-accordionTab header="Phases and Teams">
            <div class="card-header">

                <h4 style="color:#495057; padding:10px 0 0 15px;"></h4>
                <span class="p-input-icon-left" style="float:right;margin-bottom:8px;">

                    <button (click)="SecondStepEdit()" pButton type="button" label="Edit" class="p-button-info" icon="pi pi-pencil" iconPos="left"
                        style="margin-right:10px ; "></button>
                </span>
            </div>
            <p-table [value]="originalPhaseTeamData" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Phase</th>
                        <th>Type</th>
                        <th>Department</th>
                        <th>Role</th>
                        <th>User</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td style="padding-left: 50px;text-align: left;">{{product.phaseName}}</td>
                        <td style="padding-left: 50px;text-align: left;">{{product.typeDisplay}}</td>
                        <td style="padding-left: 50px;text-align: left;">{{product.departmentName}}</td>
                        <td style="padding-left: 50px;text-align: left;">{{product.roleName}}</td>
                        <td style="padding-left: 50px;text-align: left;">{{product.userName}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
    </p-accordion>
    <p-accordion>
        <p-accordionTab header="Phases, Milestones and Tasks">
            <div class="card-header">

                <h4 style="color:#495057; padding:10px 0 0 15px;">Phase Owner:</h4>
                <span class="p-input-icon-left" style="float:right;margin-bottom:8px;">

                    <button (click)="ThirdStepEdit()" pButton type="button" label="Edit" class="p-button-info" icon="pi pi-pencil" iconPos="left"
                        style="margin-right:10px ; "></button>
                </span>
            </div>
            
            <p-table [value]="PhasesData" dataKey="phaseID" styleClass="p-datatable-striped" [rowHover]="true" [paginator]="false" [filterDelay]="0">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Phase</th>
                        <th>Owner</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body"  let-mileStone>
                    <tr>
                        <td style="padding-left: 15px;">{{mileStone.phaseName}}</td>
                        <td style="padding-left: 15px;">{{mileStone.ownerName}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <h4>Milestones: </h4>
            <p-table [value]="originalPhaseTaskData" dataKey="phaseTaskId" styleClass="p-datatable-striped" [rowHover]="true" [paginator]="false" [filterDelay]="0">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Phase</th>
                        <th>Seq</th>
                        <th>Milestone</th>
                        <th>Department</th>
                        <th>Target Start Date</th>
                        <th>Target End Date</th>
                        <th>Overrided</th>
                        <th>Cycle Time</th>
                        <th>Overlap Days</th>
                        <th>Predecessor</th>
                        <th>Assigned To</th>
                        <th>Is Weekend</th>
                        <th>Allow Multiple Assignee</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body"  let-mileStone>
                    <tr>
                        <td style="padding-left: 15px;">{{mileStone.phaseName}}</td>
                        <td style="text-align: center;">{{mileStone.taskSeq}}</td>
                        <td style="padding-left: 15px;">{{mileStone.taskName}}</td>
                        <td style="padding-left: 15px;">{{mileStone.departmentName}}</td>
                        <td style="text-align: center;"  [ngStyle]="{'background': mileStone.override === true ? '#ffe5b4': '','font-weight' : mileStone.override === true ? 'bold': ''}">
                            <span *ngIf="mileStone.startDate != '--' && mileStone.startDate != null && mileStone.cycleTime != 0">{{mileStone.startDate | date:'MM/dd/yyyy'}}</span>
                            <span *ngIf="mileStone.startDate == '--' || mileStone.startDate == null || mileStone.cycleTime == 0">--</span>
                        </td>
                        <td style="text-align: center;"  [ngStyle]="{'background': mileStone.override === true ? '#ffe5b4': '','font-weight' : mileStone.override === true ? 'bold': ''}">
                            <span *ngIf="mileStone.endDate != '--' && mileStone.endDate != null && mileStone.cycleTime != 0">{{mileStone.endDate | date:'MM/dd/yyyy'}}</span>
                            <span *ngIf="mileStone.endDate == '--' || mileStone.endDate == null || mileStone.cycleTime == 0">--</span>
                         </td>
                         <td style="text-align: center;"><span *ngIf="mileStone.override === true">Yes</span><span
                            *ngIf="mileStone.override === false">No</span></td>
                        <td style="text-align: center;">{{mileStone.cycleTime}}</td>
                        <td style="text-align: center;">{{mileStone.overlapDays}}</td>
                        <td style="padding-left: 15px;">{{mileStone.predecessor}}</td>
                        <td style="padding-left: 15px;">{{mileStone.assignTo}}</td>
                        <td style="text-align: center;"><span *ngIf="mileStone.isWeekend === true">Yes</span><span
                            *ngIf="mileStone.isWeekend === false">No</span></td>
                        <td style="text-align: center;"><span *ngIf="mileStone.allowMultipleAssignee === true">Yes</span><span
                            *ngIf="mileStone.allowMultipleAssignee === false">No</span></td>
                    </tr>
                </ng-template>
            </p-table>
            <h4>Tasks: </h4>
            <p-table [value]="originalPhaseDocsData" dataKey="tmpReqDocumentId" styleClass="p-datatable-striped" [rowHover]="true" [paginator]="false" [filterDelay]="0">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:100px;">Phase</th>
                        <th style="width:150px;">Department</th>
                        <th style="width:150px;">Role</th>
                        <th>Task</th>
                        <th style="width:120px;">Document Label</th>
                        <th style="width:120px;">Template</th>
                        <th style="width:80px;">Task Required</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body"  let-document>
                    <tr  [style.background]="document.isRequired == true ? 'lightblue' : '' ">
                        <td style="padding-left: 15px;">{{document.phaseName}}</td>
                        <td style="padding-left: 15px;">{{document.departmentName}}</td>
                        <td style="padding-left: 15px;">{{document.roleName}}</td>
                        <td style="padding-left: 15px;">{{document.documentName}}</td>
                        <td style="padding-left: 15px;">{{document.documentLabel}}</td>
                        <td style="padding-left: 15px;">{{document.templatesNames}}</td>
                        <td style="text-align: center;"><span *ngIf="document.isRequired === true">Yes</span><span
                            *ngIf="document.isRequired === false">No</span></td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
    </p-accordion>
    <div style="float:right; margin-right:50px;">
        <small class="p-invalid">{{ Step4Error }}</small>
    </div><br>
    <hr>
    <button pButton type="button" label="Back" class="p-button-info" icon="pi pi-chevron-left" iconPos="left"
        (click)="stepBack3()"></button>
    <button pButton (click)="FinalStep()" type="button" label="Confirm" class="p-button-success" icon="pi pi-check" iconPos="left"
        style="float:right;margin-right:10px ; "></button>
       
    <!-- <span class="p-input-icon-left" style="float:right; margin-right:20px; padding:10px;">
        <p-checkbox name="product" label="Create New Product" binary="false" inputId="binary"></p-checkbox>


    </span> -->
    <!-- <span class="p-input-icon-left" style="float:right; margin-right:15px; padding:10px;">

        <p-checkbox name="template" label="Save as Product Template" binary="false" inputId="binary"></p-checkbox>

    </span> -->

</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>
  <p-dialog
  header="Delete Document"
  [(visible)]="deleteDisplay"
  modal="modal"
  showEffect="fade"
  [style]="{ width: '60%' }"
>
  <hr />
  <div class="p-grid">
    <div
      class="p-col-12 p-md-8"
      style="text-align: center; margin-top: 8px"
    >
      <h4 style="color: red">{{ AreYouSureText }}</h4>
    </div>
    <div class="p-col-12 p-md-2">
      <span
        class="p-input-icon-left"
        style="float: right; margin-right: 10px; margin-top: 8px"
      >
        <button
          pButton
          type="button"
          label="Delete"
          icon="pi pi-trash"
          iconPos="left"
          (click)="deleteDoc()"
          class="p-button-raised p-button-danger"
        ></button>
      </span>
    </div>
    <div class="p-col-12 p-md-2">
      <span
        class="p-input-icon-left"
        style="margin-right: 10px; margin-top: 8px"
      >
        <button
          pButton
          type="button"
          label="Close"
          icon="pi pi-times"
          iconPos="left"
          (click)="deleteDisplay = false"
          class="p-button-raised p-button-secondary"
        ></button>
      </span>
    </div>
  </div>
</p-dialog>

<p-dialog header="Delete Team" [(visible)]="deleteTDisplay" modal="modal" showEffect="fade" [style]="{ width: '60%' }" >
    <hr />
    <div class="p-grid">
      <div class="p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
        <h4 style="color: red">{{ AreYouSureText }}</h4>
      </div>
      <div class="p-col-12 p-md-2">
        <span
          class="p-input-icon-left"
          style="float: right; margin-right: 10px; margin-top: 8px"
        >
          <button pButton type="button" label="Delete" icon="pi pi-trash" iconPos="left" (click)="deleteTeam('T')" class="p-button-raised p-button-danger"
          ></button>
        </span>
      </div>
      <div class="p-col-12 p-md-2">
        <span
          class="p-input-icon-left"
          style="margin-right: 10px; margin-top: 8px"
        >
          <button
            pButton
            type="button"
            label="Close"
            icon="pi pi-times"
            iconPos="left"
            (click)="deleteTDisplay = false"
            class="p-button-raised p-button-secondary"
          ></button>
        </span>
      </div>
    </div>
    </p-dialog>
    
    <p-dialog
    header="Delete Approver"
    [(visible)]="deleteADisplay"
    modal="modal"
    showEffect="fade"
    [style]="{ width: '60%' }"
    >
    <hr />
    <div class="p-grid">
      <div
        class="p-col-12 p-md-8"
        style="text-align: center; margin-top: 8px"
      >
        <h4 style="color: red">{{ AreYouSureText }}</h4>
      </div>
      <div class="p-col-12 p-md-2">
        <span
          class="p-input-icon-left"
          style="float: right; margin-right: 10px; margin-top: 8px"
        >
          <button
            pButton
            type="button"
            label="Delete"
            icon="pi pi-trash"
            iconPos="left"
            (click)="deleteTeam('A')"
            class="p-button-raised p-button-danger"
          ></button>
        </span>
      </div>
      <div class="p-col-12 p-md-2">
        <span
          class="p-input-icon-left"
          style="margin-right: 10px; margin-top: 8px"
        >
          <button
            pButton
            type="button"
            label="Close"
            icon="pi pi-times"
            iconPos="left"
            (click)="deleteADisplay = false"
            class="p-button-raised p-button-secondary"
          ></button>
        </span>
      </div>
    </div>
    </p-dialog>
    
    <p-dialog
    header="Delete Notification Recipients"
    [(visible)]="deleteNDisplay"
    modal="modal"
    showEffect="fade"
    [style]="{ width: '60%' }"
    >
    <hr />
    <div class="p-grid">
      <div
        class="p-col-12 p-md-8"
        style="text-align: center; margin-top: 8px"
      >
        <h4 style="color: red">{{ AreYouSureText }}</h4>
      </div>
      <div class="p-col-12 p-md-2">
        <span
          class="p-input-icon-left"
          style="float: right; margin-right: 10px; margin-top: 8px"
        >
          <button
            pButton
            type="button"
            label="Delete"
            icon="pi pi-trash"
            iconPos="left"
            (click)="deleteTeam('N')"
            class="p-button-raised p-button-danger"
          ></button>
        </span>
      </div>
      <div class="p-col-12 p-md-2">
        <span
          class="p-input-icon-left"
          style="margin-right: 10px; margin-top: 8px"
        >
          <button
            pButton
            type="button"
            label="Close"
            icon="pi pi-times"
            iconPos="left"
            (click)="deleteNDisplay = false"
            class="p-button-raised p-button-secondary"
          ></button>
        </span>
      </div>
    </div>
    </p-dialog>