<div class="p-grid">
    <div class="p-col-12">
    <div class="card">
        <p-accordion>
            <p-accordionTab header="Product Details" [selected]="true">
                <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-xl-4">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                            <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;">Project:</span>
                                    <span>{{ Product.productName }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Product Line:</span>
                                    <span>{{ Product.spaceName }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Die Code:</span>
                                    <span>{{ Product.dieCode }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Product Engineer:</span>
                                    <span>{{ Product.productOwner }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-xl-4">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                            <ul class="widget-image-list">
                                <!-- <li>
                                    <span style="font-weight: bold;">Start Date:</span>
                                    <span class="listitem-value">{{ Product.startDate | date:'MM/dd/yyyy' }}</span>
                                </li> -->
                                <li>
                                    <span style="font-weight: bold;">Project Description:</span>
                                    <span>{{ Product.projectDescription}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;align-self: baseline;">Description:</span>
                                    <span style="text-align:right;">{{ Product.description }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Mfg Part:</span>
                                    <span>{{ Product.mfgPart }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Project Status:</span>
                                    <span>{{ Product.productStatus }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-xl-4">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1"
                            style="min-height:80px; overflow-y: auto;">
                            <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;">Current Phase:</span>
                                    <span class="listitem-value">{{ Product.currentPhase }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Reasons:</span>
                                    <span class="listitem-value" style="float:right; margin-bottom:0px; height:20px;">
                                        <button pButton type="button" pTooltip="View Reasons" tooltipPosition="right"
                                        (click)="projectreasonsdisplay=true" icon="pi pi-eye" class="p-button-raised p-button-primary"
                                        style="height:25px; width:25px;"></button>
                                    </span>
                                </li>
                                <li>
                                    <span></span>
                                    <span class="listitem-value" style="float:right; margin-bottom:0px; height:20px;">
                                        <button pButton type="button" pTooltip="View Details" tooltipPosition="right"
                                            (click)="projectdisplay=true" icon="pi pi-eye"
                                            class="p-button-raised p-button-primary"
                                            style="height:25px; width:25px;"></button>
                                        &nbsp;&nbsp;
                                        <a (click)="open(Product.productGuid)" style="cursor: pointer">
                                            <button pButton pRipple type="button" icon="pi pi-th-large"
                                                pTooltip="Additional Attributes" tooltipPosition="right" iconPos="left"
                                                class="p-button-raised p-button-primary"
                                                style="height:25px; width:25px;"></button></a>
                                        &nbsp;&nbsp;
                                        <button *ngIf="DisplayPClare" pButton type="button"
                                            pTooltip="View Discontinuation Details" tooltipPosition="right"
                                            (click)="PClare=true" icon="pi pi-times-circle"
                                            class="p-button-raised p-button-primary"
                                            style="height:25px; width:25px;"></button>
                                    </span>
                                </li>
                                <li *ngIf="Product.eccn != ''">
                                    <span style="font-weight: bold;">ECCN:</span>
                                    <span>{{ Product.eccn }}</span>
                                </li>
                                <li *ngIf="Product.eccn != ''">
                                    <span style="font-weight: bold;">ECCN Document:</span>
                                    <span *ngIf="Product.eccnDocID > 0"><a href="javascript:;" (click)="DMSToken1()">{{ Product.eccnDocCode }}</a></span>
                                    <span *ngIf="Product.eccnDocID == 0">{{ Product.eccnDocCode }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
        <div class="card"> 
            <div class="card-header" style="border-radius: 5px; margin-top:-5px;height:40px;" [style.background-color]="PhaseColor">
                <h5 style="color:#fff; padding:15px 0 0 15px;">{{ CurrentPhase.phaseName }}</h5> 
            </div>
            <p-tabView  *ngIf="CurrentPhase.phaseName != '' && ViewOnlyTabs === true" [activeIndex]="index">
                <p-tabPanel header="Details">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                            <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;">Phase:</span>
                                    <span>{{ CurrentPhase.phaseName }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Phase Status:</span>
                                    <span>{{ CurrentPhase.phaseStatus }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Phase Owner:</span>
                                    <span>{{ CurrentPhase.phaseOwner }}</span>
                                </li>
                                <li *ngIf="IsPrevEvalDisplay">  
                                    <span style="font-weight: bold;">Previous Eval Sample Release:</span>
                                    <span><button (click)="PrevCR(2)" pButton type="button"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                    </span>
                                </li>
                                <li *ngIf="IsEvalutionExist">  
                                    <span style="font-weight: bold;">Evaluation Sample Release:</span>
                                    <span class="listitem-value"><button (click)="GetConditionalReleaseDetails(2,true)" pButton type="button"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                    </span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                            <ul class="widget-image-list">
                                <li>
                                    <span  style="font-weight: bold;">Responsible Departments:</span>
                                    <span class="listitem-value" style="float:right;">
                                        <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                        <p-dialog header="Responsible Departments" [(visible)]="departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                        <hr>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                <p-table #dtDepartments [value]="CurrentDepartments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedDepartments"> 
                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                    <tr><th style="text-align: center;width: 50px;">S.No</th>
                                                    <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                    <tr [pSelectableRow]="rowData">
                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                        <td *ngIf="i == 0" style="text-align: center;width: 50px;">{{ j + 1 }}</td>
                                                        <td *ngIf="i == 0" style="text-align: left;">{{ rowData[col.field] }}</td>
                                                    </ng-container>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr><td colspan="2">No records found...</td></tr>
                                                </ng-template>
                                                </p-table>
                                            </div>
                                        </div>   
                                        </p-dialog>
                                    </span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Team:</span>
                                    <span class="listitem-value" style="float:right;">
                                        <button pButton type="button" (click)="teamdisplay=true"  pTooltip="View" tooltipPosition="right" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                        <p-dialog header="Team Members" [(visible)]="teamdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}" >
                                            <hr>
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-12 p-xl-12">
                                                    <p-table #dtTeam [value]="CurrentTeam" [columns]="Teamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedTeam"> 
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr><th style="text-align: center; width:50px;">S.No</th>
                                                            <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                            <tr [pSelectableRow]="rowData" >
                                                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                <td *ngIf="i == 0" style="text-align: center;width: 50px;;">{{ j + 1 }}</td>
                                                                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                            </ng-container>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="4">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>              
                                        </p-dialog>
                                    </span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Approvers:</span>
                                    <span class="listitem-value" style="float:right;">
                                    <button pButton type="button" (click)="approvalmembersdisplay=true"  pTooltip="View" tooltipPosition="right" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                        <p-dialog header="Approvers" [(visible)]="approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'95%'}" >
                                            <hr>
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-12 p-xl-12">
                                                    <h5 style="text-align:center">{{ Product.productName }} - {{ CurrentPhase.phaseName }} Approvers</h5>
                                                    <p-table #dtApprovalTeam [value]="CurrentApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple"
                                                            [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0"
                                                            selectionMode="multiple" [(selection)]="selectedApprovalTeam">
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center;width: 60px;">S.No</th>
                                                                    <th *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field" [ngClass]="{'width150': i == 3, 'width180': i == 0 || i == 1 || i == 2}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 3" style="text-align: center">
                                                                            <span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                        </td>
                                                                        <td *ngIf="i == 4" style="padding-left:15px;"  title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="6">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <h5 style="text-align:center">{{ Product.productName }} - {{CurrentPhase.phaseName}} Notification Recipient</h5>
                                                        <p-table #dtNRTeam [value]="CurrentNRTeam" [columns]="NRTeamcols" sortMode="multiple"
                                                            [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0"
                                                            selectionMode="multiple">
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center;width: 60px;">S.No </th>
                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">{{ col.header }}
                                                                        <p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData
                                                                let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="padding-left:15px;">{{  rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage"><tr><td colspan="4">No records found...</td></tr>
                                                            </ng-template>
                                                        </p-table>
                                                    <div [innerHTML]="listApprovalMembers"></div>
                                                    <h5 style="text-align:center">Clarification Details</h5>
                                                    <p-table #dtClarification [value]="Clarification" [columns]="ClarificationCols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification"> 
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <!-- <th style="text-align: center">S.No</th> -->
                                                            <tr>
                                                            <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                <th>Sought To</th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-columns="columns" >
                                                            <tr [pSelectableRow]="rowData" >
                                                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                <!-- <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td> -->
                                                                <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                                                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                                                                <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                                                                <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                                                            </ng-container>
                                                            <td style="text-align: center;">
                                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="GetTeamDetails(rowData)" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                            </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>          
                                        </p-dialog>
                                    </span>
                                </li>
                                <li *ngIf="IsPrevCRDisplay">  
                                    <span style="font-weight: bold;">Previous Conditional Release:</span>
                                    <span><button (click)="PrevCR(1)" pButton type="button"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                    </span>
                                </li>
                                <li *ngIf="IsConditionalExist">  
                                    <span style="font-weight: bold;">Conditional Release:</span>
                                    <span><button (click)="GetConditionalReleaseDetails(1,true)" pButton type="button"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                    </span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                <ul class="widget-image-list">
                                    <li>
                                        <span style="font-weight: bold;">Phase Start Date:</span>
                                        <span>{{ CurrentPhase.phaseStartDate }}</span>
                                    </li>
                                    <li>
                                        <span style="font-weight: bold;">Phase End Date:</span>
                                        <span>{{ CurrentPhase.phaseEndDate }}</span>
                                    </li>
                                    <li>    
                                        <span style="font-weight: bold;">Documents:</span>
                                        <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled]="!CurrentPhase.isDocuments" [ngClass]="{'p-button-success': CurrentPhase.isDocuments}" (click)="Documents('Phase',CurrentPhase.phaseID,'Phase',CurrentPhase.phaseName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                        </span>
                                    </li>
                                    <li>    
                                        <span style="font-weight: bold;">Notes:</span>
                                        <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled]="!CurrentPhase.isNotes" [ngClass]="{'p-button-success': CurrentPhase.isNotes}" (click)="Notes('Phase',CurrentPhase.phaseID,'Phase',CurrentPhase.phaseName)" ptooltip="Notes" tooltipposition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="CurrentRequiredDocuments?.length > 0" >
                            <div  class="invoice invoice-header" id="invoice-content">
                            <p-table #dtDocs [value]="CurrentRequiredDocuments" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                            selectionMode="multiple" [(selection)]="selectedDocs" dataKey="reqDocumentID" [autoLayout]="true" [scrollable]="true" scrollHeight="300px" >
                                <ng-template pTemplate="caption">
                                    <div>Tasks - {{TasksCount}}
                                        <!-- <span class="p-input-icon-left" style="float:right">
                                            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float: right; margin-left:15px; width:25px; height:25px; margin-top: -4px;" (click)="Docsdownload('phase')"></button>
                                        </span> -->
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr>
                                        <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
                                        [ngClass]="{'width120': i == 3 || i ==5, 'width180': i == 4,'width200': i == 0 || i ==1,'w':i==2}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                        <th style="text-align: center;width: 80px;">Documents</th>
                                        <th style="text-align: center;width: 80px;">Notes</th>
                                    </tr>
                                    <tr>
                                        <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                            <input pInputText type="text" (input)="test($event.target.value,col.field,dtDocs)" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                        <td style="text-align:left; padding-left:15px;width: 200px;"><span>{{rowData.departmentName}}</span></td>
                                        <td style="text-align:left; padding-left:15px;width: 200px;"><span>{{rowData.roleName}}</span></td>
                                        <td style="text-align:left; padding-left:15px;width: 50%;">
                                            <span style="color: red;font-weight: bold;" *ngIf="rowData.isMilestoneRelated">{{rowData.documentName}}</span>
                                            <span *ngIf="!rowData.isMilestoneRelated">{{rowData.documentName}}</span>
                                        </td>
                                        <td style="text-align: center;width: 120px;"><span>{{ rowData.isRequired }} </span></td>
                                        <td style="text-align:center;width: 180px;"><span>{{rowData.documentLabels }}</span></td>
                                        <td style="text-align:left; padding-left:15px;width: 120px;">
                                            <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                <li class="templi">
                                                    <span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                    <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                </li>
                                            </ul>
                                        </td>
                                        <td style="text-align: center;width: 80px;">
                                            <span class="listitem-value">
                                                <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('phasedocument',rowData.reqDocumentID,'Document',rowData.documentName)" ptooltip="Documents" tooltipposition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                            </span>
                                        </td>
                                        <td style="text-align: center;width: 80px;">
                                            <span class="listitem-value">
                                                <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('phasedocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr><td colspan="7">No records found...</td></tr>
                                </ng-template>
                            </p-table>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Milestones?.length" iconPos="left">
                    <p-accordion [activeIndex]="milestoneindex">
                        <p-accordionTab header="{{ Milestone1.listName }}" *ngIf="Milestones?.length > 0" [selected]="true" >
                            <ng-template pTemplate="header"><span *ngIf="Milestone1.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Name:</span>
                                            <span>{{ Milestone1.listName }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Status:</span>
                                            <span>{{ Milestone1.listStatus }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Owner:</span>
                                            <span>{{ Milestone1.listOwner }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="mile1departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                <p-dialog header="Responsible Departments" [(visible)]="mile1departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                <hr>
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                        <p-table #dtMile1Departments [value]="Milestone1Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile1Departments" > 
                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr><td colspan="2">No records found...</td></tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>   
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approval Required:</span>
                                            <span style="padding-right:10px;">{{ Milestone1.approval }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approvers:</span>
                                            <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled] = "Milestone1.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="mile1approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                <p-dialog header="Approvers" [(visible)]="mile1approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <h5 style="text-align:center">{{ Milestone1.listName }} Approvers</h5>
                                                            <p-table #dtMile1ApprovalTeam [value]="Mile1ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile1ApprovalTeam" > 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                    {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                    <tr [pSelectableRow]="rowData" >
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>             
                                                </p-dialog>
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>    
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone1.isDocuments" [ngClass]="{'p-button-success': Milestone1.isDocuments}" (click)="Documents('List',Milestone1.listID,'Step',Milestone1.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>    
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone1.isNotes" [ngClass]="{'p-button-success': Milestone1.isNotes}" (click)="Notes('List',Milestone1.listID,'Step',Milestone1.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile1Documents?.length > 0" >
                                    <div  class="invoice invoice-header" id="invoice-content">
                                    <p-table #dtMile1Docs [value]="Mile1Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                        selectionMode="multiple" [(selection)]="selectedMile1Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                        <ng-template pTemplate="caption">
                                            <div>Tasks
                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float: right; margin-left:15px; width:25px; height:25px; margin-top: -4px;" (click)="Docsdownload('mile1')"></button>
                                                </span> -->
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                            <tr>
                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                <th style="text-align: center">Documents</th>
                                                <th style="text-align: center">Notes</th>
                                            </tr>
                                            <tr>
                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                    <input pInputText type="text" (input)="dtMile1Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                </th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                <td style="text-align:left; padding-left:15px;">
                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                        <li class="templi"><a href="javascript:;" (click)="DMSToken(temp)"> {{temp.templateName}} </a></li>
                                                    </ul>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr><td colspan="7">No records found...</td></tr>
                                        </ng-template>
                                    </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone2.listName }}" *ngIf="Milestones?.length > 1">
                            <ng-template pTemplate="header"><span *ngIf="Milestone2.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Name:</span>
                                            <span>{{ Milestone2.listName }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Status:</span>
                                            <span>{{ Milestone2.listStatus }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Owner:</span>
                                            <span>{{ Milestone2.listOwner }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="mile2departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                <p-dialog header="Responsible Departments" [(visible)]="mile2departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                <hr>
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                        <p-table #dtMile2Departments [value]="Milestone2Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile2Departments" > 
                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr><td colspan="2">No records found...</td></tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>   
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approval Required:</span>
                                            <span style="padding-right:10px;">{{ Milestone2.approval }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approvers:</span>
                                            <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled] = "Milestone2.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="mile2approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                <p-dialog header="Approvers" [(visible)]="mile2approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <h5 style="text-align:center">{{ Milestone2.listName }} Approvers</h5>
                                                            <p-table #dtMile2ApprovalTeam [value]="Mile2ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile2ApprovalTeam"> 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                    <tr [pSelectableRow]="rowData" >
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>             
                                                </p-dialog>
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>    
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone2.isDocuments" [ngClass]="{'p-button-success': Milestone2.isDocuments}" (click)="Documents('List',Milestone2.listID,'Step',Milestone2.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>    
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone2.isNotes" [ngClass]="{'p-button-success': Milestone2.isNotes}" (click)="Notes('List',Milestone2.listID,'Step',Milestone2.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile2Documents?.length > 0" >
                                    <div  class="invoice invoice-header" id="invoice-content">
                                    <p-table #dt2Docs [value]="Mile2Documents" sortMode="multiple" [columns]="documents" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                    selectionMode="multiple" [(selection)]="selectedMile2Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                    <ng-template pTemplate="caption">
                                        <div>Tasks
                                            <!-- <span class="p-input-icon-left" style="float:right">
                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float: right; margin-left:15px; width:25px; height:25px; margin-top: -4px;" (click)="Docsdownload('mile2')"></button>
                                            </span> -->
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header" let-columns class="invoice-items">
                                        <tr>
                                            <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                            <th style="text-align: center">Documents</th>
                                            <th style="text-align: center">Notes</th>
                                        </tr>
                                        <tr>
                                            <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                <input pInputText type="text" (input)="dtMile2Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                            </th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData>
                                        <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                            <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                            <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                            <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                            <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                            <td style="text-align:left; padding-left:15px;">
                                                <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                    <li class="templi">
                                                        <span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                        <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="text-align: center;">
                                                <span class="listitem-value">
                                                    <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                </span>
                                            </td>
                                            <td style="text-align: center;">
                                                <span class="listitem-value">
                                                    <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr><td colspan="7">No records found...</td></tr>
                                    </ng-template>
                                    </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone3.listName }}" *ngIf="Milestones?.length > 2" >
                            <ng-template pTemplate="header"><span *ngIf="Milestone3.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Name:</span>
                                            <span>{{ Milestone3.listName }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Status:</span>
                                            <span>{{ Milestone3.listStatus }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Owner:</span>
                                            <span>{{ Milestone3.listOwner }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="mile3departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                <p-dialog header="Responsible Departments" [(visible)]="mile3departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                <hr>
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                        <p-table #dtMile3Departments [value]="Milestone3Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile3Departments" > 
                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr><td colspan="2">No records found...</td></tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>   
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approval Required:</span>
                                            <span style="padding-right:10px;">{{ Milestone3.approval }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approvers:</span>
                                            <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled] = "Milestone3.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="mile3approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                <p-dialog header="Approvers" [(visible)]="mile3approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <h5 style="text-align:center">{{ Milestone3.listName }} Approvers</h5>
                                                            <p-table #dtMile3ApprovalTeam [value]="Mile3ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile3ApprovalTeam"
                                                                > 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                    <tr [pSelectableRow]="rowData" >
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>             
                                                </p-dialog>
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>    
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone3.isDocuments" [ngClass]="{'p-button-success': Milestone3.isDocuments}" (click)="Documents('List',Milestone3.listID,'Step',Milestone3.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>    
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone3.isNotes" [ngClass]="{'p-button-success': Milestone3.isNotes}" (click)="Notes('List',Milestone3.listID,'Step',Milestone3.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile3Documents?.length > 0" >
                                    <div  class="invoice invoice-header" id="invoice-content">
                                    <p-table #dtMile3Docs [value]="Mile3Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                    selectionMode="multiple" [(selection)]="selectedMile3Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                        <ng-template pTemplate="caption">
                                            <div>Tasks
                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('mile3')"></button>
                                                </span> -->
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                            <tr>
                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                <th style="text-align: center">Documents</th>
                                                <th style="text-align: center">Notes</th>
                                            </tr>
                                            <tr>
                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                    <input pInputText type="text" (input)="dtMile3Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                </th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                <td style="text-align:left; padding-left:15px;">
                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                    </ul>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr><td colspan="7">No records found...</td></tr>
                                        </ng-template>
                                    </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone4.listName }}" *ngIf="Milestones?.length > 3">
                            <ng-template pTemplate="header"><span *ngIf="Milestone4.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Name:</span>
                                            <span>{{ Milestone4.listName }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Status:</span>
                                            <span>{{ Milestone4.listStatus }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Owner:</span>
                                            <span>{{ Milestone4.listOwner }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="mile4departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                <p-dialog header="Responsible Departments" [(visible)]="mile4departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                <hr>
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                        <p-table #dtMile4Departments [value]="Milestone4Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile4Departments" > 
                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr><td colspan="2">No records found...</td></tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>   
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approval Required:</span>
                                            <span style="padding-right:10px;">{{ Milestone4.approval }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approvers:</span>
                                            <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled] = "Milestone4.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="mile4approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                <p-dialog header="Approvers" [(visible)]="mile4approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <h5 style="text-align:center">{{ Milestone4.listName }} Approvers</h5>
                                                            <p-table #dtMile4ApprovalTeam [value]="Mile4ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile4ApprovalTeam"
                                                                > 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                    <tr [pSelectableRow]="rowData" >
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>             
                                                </p-dialog>
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>    
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone4.isDocuments" [ngClass]="{'p-button-success': Milestone4.isDocuments}" (click)="Documents('List',Milestone4.listID,'Step',Milestone4.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>    
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone4.isNotes" [ngClass]="{'p-button-success': Milestone4.isNotes}" (click)="Notes('List',Milestone4.listID,'Step',Milestone4.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile4Documents?.length > 0" >
                                    <div  class="invoice invoice-header" id="invoice-content">
                                    <p-table #dtMile4Docs [value]="Mile4Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                    selectionMode="multiple" [(selection)]="selectedMile4Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                        <ng-template pTemplate="caption">
                                            <div>Tasks
                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('mile4')"></button>
                                                </span> -->
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                            <tr>
                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                <th style="text-align: center">Documents</th>
                                                <th style="text-align: center">Notes</th>
                                            </tr>
                                            <tr>
                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                    <input pInputText type="text" (input)="dtMile4Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                </th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                <td style="text-align:left; padding-left:15px;">
                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                        <li class="templi">
                                                            <span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                    <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr><td colspan="7">No records found...</td></tr>
                                        </ng-template>
                                    </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone5.listName }}" *ngIf="Milestones?.length > 4" >
                            <ng-template pTemplate="header"><span *ngIf="Milestone5.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Name:</span>
                                            <span>{{ Milestone5.listName }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Status:</span>
                                            <span>{{ Milestone5.listStatus }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Owner:</span>
                                            <span>{{ Milestone5.listOwner }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button"  (click)="mile5departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                <p-dialog header="Responsible Departments" [(visible)]="mile5departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                <hr>
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                        <p-table #dtMile5Departments [value]="Milestone5Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile5Departments" > 
                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                <tr [pSelectableRow]="rowData">
                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                </ng-container>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr><td colspan="2">No records found...</td></tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>   
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approval Required:</span>
                                            <span style="padding-right:10px;">{{ Milestone5.approval }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Approvers:</span>
                                            <span class="listitem-value" style="float:right;">
                                            <button pButton type="button" [disabled] = "Milestone5.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="mile5approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                <p-dialog header="Approvers" [(visible)]="mile5approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <h5 style="text-align:center">{{ Milestone5.listName }} Approvers</h5>
                                                            <p-table #dtMile5ApprovalTeam [value]="Mile5ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectMile5ApprovalTeam"
                                                                > 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                    <tr [pSelectableRow]="rowData" >
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>             
                                                </p-dialog>
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>    
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone5.isDocuments" [ngClass]="{'p-button-success': Milestone5.isDocuments}" (click)="Documents('List',Milestone5.listID,'Step',Milestone5.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>    
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="!Milestone5.isNotes" [ngClass]="{'p-button-success': Milestone5.isNotes}" (click)="Notes('List',Milestone5.listID,'Step',Milestone5.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12"  *ngIf="Mile5Documents?.length > 0">
                                    <div  class="invoice invoice-header" id="invoice-content">
                                    <p-table #dtMile5Docs [value]="Mile5Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                     selectionMode="multiple" [(selection)]="selectedMile5Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                        <ng-template pTemplate="caption">
                                            <div>Tasks
                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('mile5')"></button>
                                                </span> -->
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                            <tr>
                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                <th style="text-align: center">Documents</th>
                                                <th style="text-align: center">Notes</th>
                                            </tr>
                                            <tr>
                                                <th *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                    <input pInputText type="text" (input)="dtMile5Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                </th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                <td style="text-align:left; padding-left:15px;">
                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                    </ul>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                                <td style="text-align: center;">
                                                    <span class="listitem-value">
                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr><td colspan="7">No records found...</td></tr>
                                        </ng-template>
                                    </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
                <p-tabPanel header="Stages" leftIcon="pi pi-globe" *ngIf="Stages?.length" iconPos="left">
                    <p-accordion [activeIndex]="stageindex">
                        <p-accordionTab header="{{ Stage1.listName }}" *ngIf="Stages?.length > 0" [selected]="true" >
                            <ng-template pTemplate="header"><span *ngIf="Stage1.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <p-tabView [activeIndex]="stage1index" >
                                <p-tabPanel header="Details" leftIcon="pi pi-eye" iconPos="left">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                            <ul class="widget-image-list">
                                                <li>
                                                    <span style="font-weight: bold;">Name:</span>
                                                    <span>{{ Stage1.listName }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Status:</span>
                                                    <span>{{ Stage1.listStatus }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Owner:</span>
                                                    <span>{{ Stage1.listOwner }}</span>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                            <ul class="widget-image-list">
                                                <li>
                                                    <span  style="font-weight: bold;">Responsible Departments:</span>
                                                    <span class="listitem-value" style="float:right;">
                                                        <button pButton type="button"   pTooltip="View" tooltipPosition="right" (click)="Stage1departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                        <p-dialog header="Responsible Departments" [(visible)]="Stage1departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtStage1Departments [value]="Stage1Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Departments"> 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 50px;">S.No</th>
                                                                    <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 50px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left;">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="2">No records found...</td></tr>
                                                                </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>   
                                                        </p-dialog>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Approval Required:</span>
                                                    <span style="padding-right:10px;">{{ Stage1.approval }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Approvers:</span>
                                                    <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled] = "Stage1.approval=='N'"  pTooltip="View" tooltipPosition="right" (click)="Stage1approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                        <p-dialog header="Approvers" [(visible)]="Stage1approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                            <hr>
                                                            <div class="p-grid">
                                                                <div class="p-col-12 p-md-12 p-xl-12">
                                                                    <h5 style="text-align:center">{{ Stage1.listName }} Approvers</h5>
                                                                    <p-table #dtStage1ApprovalTeam [value]="Stage1ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1ApprovalTeam"> 
                                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                            <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                            <tr [pSelectableRow]="rowData" >
                                                                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                    <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage">
                                                                            <tr><td colspan="7">No records found...</td></tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                </div>
                                                            </div>          
                                                        </p-dialog>
                                                    </span>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>    
                                                        <span style="font-weight: bold;">Documents:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled]="!Stage1.isDocuments" [ngClass]="{'p-button-success': Stage1.isDocuments}" (click)="Documents('List',Stage1.listID,'Stage',Stage1.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                    <li>    
                                                        <span style="font-weight: bold;">Notes:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled]="!Stage1.isNotes" [ngClass]="{'p-button-success': Stage1.isNotes}" (click)="Notes('List',Stage1.listID,'Stage',Stage1.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-xl-12">
                                            <div  class="invoice invoice-header" id="invoice-content">
                                            <p-table #dtStage1Docs [value]="Stage1Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                            selectionMode="multiple" [(selection)]="selectedStage1Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                <ng-template pTemplate="caption">
                                                    <div>Tasks
                                                        <!-- <span class="p-input-icon-left" style="float:right">
                                                            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1')"></button>
                                                        </span> -->
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                    <tr>
                                                        <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                        <th style="text-align: center">Documents</th>
                                                        <th style="text-align: center">Notes</th>
                                                    </tr>
                                                    <tr>
                                                        <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                            <input pInputText type="text" (input)="dtStage1Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                        <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                        <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                        <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                        <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                        <td style="text-align:left; padding-left:15px;">
                                                            <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                    <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span class="listitem-value">
                                                                <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                            </span>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span class="listitem-value">
                                                                <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr><td colspan="7">No records found...</td></tr>
                                                </ng-template>
                                            </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Stage1Milestones?.length" iconPos="left">
                                    <p-accordion [activeIndex]="stage1mileindex">
                                        <p-accordionTab header="{{ Stage1Milestone1.listName }}" *ngIf="Stage1Milestones?.length > 0" [selected]="true" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage1Milestone1.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage1Milestone1.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage1Milestone1.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button" pTooltip="View" tooltipPosition="right"  (click)="Stage1mile1departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage1mile1departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage1Mile1Departments [value]="Stage1Milestone1Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Mile1Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage1Milestone1.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage1Milestone1.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="Stage1mile1approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage1mile1approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage1Milestone1.listName }} Approvers</h5>
                                                                            <p-table #dtStage1Mile1ApprovalTeam [value]="Stage1Mile1ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Mile1ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage1Milestone1.isDocuments" [ngClass]="{'p-button-success': Stage1Milestone1.isDocuments}" (click)="Documents('List',Stage1Milestone1.listID,'Step',Stage1Milestone1.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage1Milestone1.isNotes" [ngClass]="{'p-button-success': Stage1Milestone1.isNotes}" (click)="Notes('List',Stage1Milestone1.listID,'Step',Stage1Milestone1.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage1Mile1Documents?.length > 0" >
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage1Mile1Docs [value]="Stage1Mile1Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage1Mile1Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1mile1')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage1Mile1Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage1Milestone2.listName }}" *ngIf="Stage1Milestones?.length > 1" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage1Milestone2.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage1Milestone2.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage1Milestone2.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="Stage1mile2departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage1mile2departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage1Mile2Departments [value]="Stage1Milestone2Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Mile2Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage1Milestone2.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage1Milestone2.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="Stage1mile2approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage1mile2approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage1Milestone2.listName }} Approvers</h5>
                                                                            <p-table #dtStage1Mile2ApprovalTeam [value]="Stage1Mile2ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Mile2ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage1Milestone2.isDocuments" [ngClass]="{'p-button-success': Stage1Milestone2.isDocuments}" (click)="Documents('List',Stage1Milestone2.listID,'Step',Stage1Milestone2.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage1Milestone2.isNotes" [ngClass]="{'p-button-success': Stage1Milestone2.isNotes}" (click)="Notes('List',Stage1Milestone2.listID,'Step',Stage1Milestone2.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage1Mile2Documents?.length > 0">
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage1Mile2Docs [value]="Stage1Mile2Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage1Mile2Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1mile2')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage1Mile2Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage1Milestone3.listName }}" *ngIf="Stage1Milestones?.length > 2" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage1Milestone3.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage1Milestone3.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage1Milestone3.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button"  (click)="Stage1mile3departmentsdisplay=true" pTooltip="View" tooltipPosition="right"  icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage1mile3departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage1Mile3Departments [value]="Stage1Milestone3Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Mile3Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage1Milestone3.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage1Milestone3.approval=='N'" (click)="Stage1mile3approvalmembersdisplay=true" class="p-button-info"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage1mile3approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage1Milestone3.listName }} Approvers</h5>
                                                                            <p-table #dtStage1Mile3ApprovalTeam [value]="Stage1Mile3ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage1Mile3ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage1Milestone3.isDocuments" [ngClass]="{'p-button-success': Stage1Milestone3.isDocuments}" (click)="Documents('List',Stage1Milestone3.listID,'Step',Stage1Milestone3.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage1Milestone3.isNotes" [ngClass]="{'p-button-success': Stage1Milestone3.isNotes}" (click)="Notes('List',Stage1Milestone3.listID,'Step',Stage1Milestone3.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage1Mile3Documents?.length > 0" >
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage1Mile3Docs [value]="Stage1Mile3Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage1Mile3Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1mile3')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage1Mile3Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </p-tabPanel>
                            </p-tabView>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Stage2.listName }}" *ngIf="Stages?.length > 1" >
                            <ng-template pTemplate="header"><span *ngIf="Stage2.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <p-tabView [activeIndex]="stage2index" >
                                <p-tabPanel header="Details" leftIcon="pi pi-eye" iconPos="left">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                            <ul class="widget-image-list">
                                                <li>
                                                    <span style="font-weight: bold;">Name:</span>
                                                    <span>{{ Stage2.listName }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Status:</span>
                                                    <span>{{ Stage2.listStatus }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Owner:</span>
                                                    <span>{{ Stage2.listOwner }}</span>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                            <ul class="widget-image-list">
                                                <li>
                                                    <span  style="font-weight: bold;">Responsible Departments:</span>
                                                    <span class="listitem-value" style="float:right;">
                                                        <button pButton type="button"   pTooltip="View" tooltipPosition="right" (click)="Stage2departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                        <p-dialog header="Responsible Departments" [(visible)]="Stage2departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtStage2Departments [value]="Stage2Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Departments"> 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 50px;">S.No</th>
                                                                    <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 50px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left;">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="2">No records found...</td></tr>
                                                                </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>   
                                                        </p-dialog>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Approval Required:</span>
                                                    <span style="padding-right:10px;">{{ Stage2.approval }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Approvers:</span>
                                                    <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled] = "Stage2.approval=='N'"  pTooltip="View" tooltipPosition="right" (click)="Stage2approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                        <p-dialog header="Approvers" [(visible)]="Stage2approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                            <hr>
                                                            <div class="p-grid">
                                                                <div class="p-col-12 p-md-12 p-xl-12">
                                                                    <h5 style="text-align:center">{{ Stage2.listName }} Approvers</h5>
                                                                    <p-table #dtStage2ApprovalTeam [value]="Stage2ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2ApprovalTeam"> 
                                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                            <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                            <tr [pSelectableRow]="rowData" >
                                                                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                    <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage">
                                                                            <tr><td colspan="7">No records found...</td></tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                </div>
                                                            </div>          
                                                        </p-dialog>
                                                    </span>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>    
                                                        <span style="font-weight: bold;">Documents:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled]="!Stage2.isDocuments" [ngClass]="{'p-button-success': Stage2.isDocuments}" (click)="Documents('List',Stage2.listID,'Stage',Stage2.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                    <li>    
                                                        <span style="font-weight: bold;">Notes:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled]="!Stage2.isNotes" [ngClass]="{'p-button-success': Stage2.isNotes}" (click)="Notes('List',Stage2.listID,'Stage',Stage2.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-xl-12">
                                            <div  class="invoice invoice-header" id="invoice-content">
                                            <p-table #dtStage2Docs [value]="Stage2Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                            selectionMode="multiple" [(selection)]="selectedStage2Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                <ng-template pTemplate="caption">
                                                    <div>Tasks
                                                        <!-- <span class="p-input-icon-left" style="float:right">
                                                            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2')"></button>
                                                        </span> -->
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                    <tr>
                                                        <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                        <th style="text-align: center">Documents</th>
                                                        <th style="text-align: center">Notes</th>
                                                    </tr>
                                                    <tr>
                                                        <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                            <input pInputText type="text" (input)="dtStage2Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                        <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                        <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                        <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                        <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                        <td style="text-align:left; padding-left:15px;">
                                                            <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                    <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span class="listitem-value">
                                                                <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                            </span>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span class="listitem-value">
                                                                <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr><td colspan="7">No records found...</td></tr>
                                                </ng-template>
                                            </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Stage2Milestones?.length" iconPos="left">
                                    <p-accordion [activeIndex]="stage2mileindex">
                                        <p-accordionTab header="{{ Stage2Milestone1.listName }}" *ngIf="Stage2Milestones?.length > 0" [selected]="true" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage2Milestone1.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage2Milestone1.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage2Milestone1.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button" pTooltip="View" tooltipPosition="right"  (click)="Stage2mile1departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage2mile1departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage2Mile1Departments [value]="Stage2Milestone1Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Mile1Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage2Milestone1.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage2Milestone1.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="Stage2mile1approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage2mile1approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage2Milestone1.listName }} Approvers</h5>
                                                                            <p-table #dtStage2Mile1ApprovalTeam [value]="Stage2Mile1ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Mile1ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage2Milestone1.isDocuments" [ngClass]="{'p-button-success': Stage2Milestone1.isDocuments}" (click)="Documents('List',Stage2Milestone1.listID,'Step',Stage2Milestone1.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage2Milestone1.isNotes" [ngClass]="{'p-button-success': Stage2Milestone1.isNotes}" (click)="Notes('List',Stage2Milestone1.listID,'Step',Stage2Milestone1.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage2Mile1Documents?.length > 0" >
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage2Mile1Docs [value]="Stage2Mile1Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage2Mile1Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2mile1')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage2Mile1Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage2Milestone2.listName }}" *ngIf="Stage2Milestones?.length > 1" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage2Milestone2.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage2Milestone2.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage2Milestone2.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="Stage2mile2departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage2mile2departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage2Mile2Departments [value]="Stage2Milestone2Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Mile2Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage2Milestone2.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage2Milestone2.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="Stage2mile2approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage2mile2approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage2Milestone2.listName }} Approvers</h5>
                                                                            <p-table #dtStage2Mile2ApprovalTeam [value]="Stage2Mile2ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Mile2ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage2Milestone2.isDocuments" [ngClass]="{'p-button-success': Stage2Milestone2.isDocuments}" (click)="Documents('List',Stage2Milestone2.listID,'Step',Stage2Milestone2.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage2Milestone2.isNotes" [ngClass]="{'p-button-success': Stage2Milestone2.isNotes}" (click)="Notes('List',Stage2Milestone2.listID,'Step',Stage2Milestone2.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage2Mile2Documents?.length > 0">
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage2Mile2Docs [value]="Stage2Mile2Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage2Mile2Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2mile2')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage2Mile2Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage2Milestone3.listName }}" *ngIf="Stage2Milestones?.length > 2" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage2Milestone3.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage2Milestone3.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage2Milestone3.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button"  (click)="Stage2mile3departmentsdisplay=true" pTooltip="View" tooltipPosition="right"  icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage2mile3departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage2Mile3Departments [value]="Stage2Milestone3Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Mile3Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage2Milestone3.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage2Milestone3.approval=='N'" (click)="Stage2mile3approvalmembersdisplay=true" class="p-button-info"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage2mile3approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage2Milestone3.listName }} Approvers</h5>
                                                                            <p-table #dtStage2Mile3ApprovalTeam [value]="Stage2Mile3ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage2Mile3ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage2Milestone3.isDocuments" [ngClass]="{'p-button-success': Stage2Milestone3.isDocuments}" (click)="Documents('List',Stage2Milestone3.listID,'Step',Stage2Milestone3.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage2Milestone3.isNotes" [ngClass]="{'p-button-success': Stage2Milestone3.isNotes}" (click)="Notes('List',Stage2Milestone3.listID,'Step',Stage2Milestone3.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage2Mile3Documents?.length > 0" >
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage2Mile3Docs [value]="Stage2Mile3Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage2Mile3Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2mile3')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage2Mile3Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </p-tabPanel>
                            </p-tabView>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Stage3.listName }}" *ngIf="Stages?.length > 2">
                            <ng-template pTemplate="header"><span *ngIf="Stage3.active == 'N'" style="color: red;"> (Not yet ready to start) </span></ng-template>
                            <p-tabView [activeIndex]="stage3index" >
                                <p-tabPanel header="Details" leftIcon="pi pi-eye" iconPos="left">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                            <ul class="widget-image-list">
                                                <li>
                                                    <span style="font-weight: bold;">Name:</span>
                                                    <span>{{ Stage3.listName }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Status:</span>
                                                    <span>{{ Stage3.listStatus }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Owner:</span>
                                                    <span>{{ Stage3.listOwner }}</span>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                            <ul class="widget-image-list">
                                                <li>
                                                    <span  style="font-weight: bold;">Responsible Departments:</span>
                                                    <span class="listitem-value" style="float:right;">
                                                        <button pButton type="button"   pTooltip="View" tooltipPosition="right" (click)="Stage3departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                        <p-dialog header="Responsible Departments" [(visible)]="Stage3departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtStage3Departments [value]="Stage3Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Departments"> 
                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                    <tr><th style="text-align: center;width: 50px;">S.No</th>
                                                                    <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                        <td *ngIf="i == 0" style="text-align: center;width: 50px;">{{ j + 1 }}</td>
                                                                        <td *ngIf="i == 0" style="text-align: left;">{{ rowData[col.field] }}</td>
                                                                    </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr><td colspan="2">No records found...</td></tr>
                                                                </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>   
                                                        </p-dialog>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Approval Required:</span>
                                                    <span style="padding-right:10px;">{{ Stage3.approval }}</span>
                                                </li>
                                                <li>
                                                    <span style="font-weight: bold;">Approvers:</span>
                                                    <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled] = "Stage3.approval=='N'"  pTooltip="View" tooltipPosition="right" (click)="Stage3approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                        <p-dialog header="Approvers" [(visible)]="Stage3approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                            <hr>
                                                            <div class="p-grid">
                                                                <div class="p-col-12 p-md-12 p-xl-12">
                                                                    <h5 style="text-align:center">{{ Stage3.listName }} Approvers</h5>
                                                                    <p-table #dtStage3ApprovalTeam [value]="Stage3ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                                                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3ApprovalTeam"> 
                                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                            <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                            <tr [pSelectableRow]="rowData" >
                                                                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                    <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage">
                                                                            <tr><td colspan="7">No records found...</td></tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                </div>
                                                            </div>          
                                                        </p-dialog>
                                                    </span>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>    
                                                        <span style="font-weight: bold;">Documents:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled]="!Stage3.isDocuments" [ngClass]="{'p-button-success': Stage3.isDocuments}" (click)="Documents('List',Stage3.listID,'Stage',Stage3.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                    <li>    
                                                        <span style="font-weight: bold;">Notes:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled]="!Stage3.isNotes" [ngClass]="{'p-button-success': Stage3.isNotes}" (click)="Notes('List',Stage3.listID,'Stage',Stage3.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-xl-12">
                                            <div  class="invoice invoice-header" id="invoice-content">
                                            <p-table #dtStage3Docs [value]="Stage3Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                            selectionMode="multiple" [(selection)]="selectedStage3Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                <ng-template pTemplate="caption">
                                                    <div>Tasks
                                                        <!-- <span class="p-input-icon-left" style="float:right">
                                                            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3')"></button>
                                                        </span> -->
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                    <tr>
                                                        <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                        <th style="text-align: center">Documents</th>
                                                        <th style="text-align: center">Notes</th>
                                                    </tr>
                                                    <tr>
                                                        <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                            <input pInputText type="text" (input)="dtStage3Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                        <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                        <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                        <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                        <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                        <td style="text-align:left; padding-left:15px;">
                                                            <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                    <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span class="listitem-value">
                                                                <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                            </span>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span class="listitem-value">
                                                                <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr><td colspan="7">No records found...</td></tr>
                                                </ng-template>
                                            </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Stage3Milestones?.length" iconPos="left">
                                    <p-accordion [activeIndex]="stage3mileindex">
                                        <p-accordionTab header="{{ Stage3Milestone1.listName }}" *ngIf="Stage3Milestones?.length > 0" [selected]="true" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage3Milestone1.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage3Milestone1.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage3Milestone1.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button" pTooltip="View" tooltipPosition="right"  (click)="Stage3mile1departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage3mile1departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage3Mile1Departments [value]="Stage3Milestone1Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Mile1Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage3Milestone1.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage3Milestone1.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="Stage3mile1approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage3mile1approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage3Milestone1.listName }} Approvers</h5>
                                                                            <p-table #dtStage3Mile1ApprovalTeam [value]="Stage3Mile1ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Mile1ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage3Milestone1.isDocuments" [ngClass]="{'p-button-success': Stage3Milestone1.isDocuments}" (click)="Documents('List',Stage3Milestone1.listID,'Step',Stage3Milestone1.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage3Milestone1.isNotes" [ngClass]="{'p-button-success': Stage3Milestone1.isNotes}" (click)="Notes('List',Stage3Milestone1.listID,'Step',Stage3Milestone1.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage3Mile1Documents?.length > 0" >
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage3Mile1Docs [value]="Stage3Mile1Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage3Mile1Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3mile1')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage3Mile1Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage3Milestone2.listName }}" *ngIf="Stage3Milestones?.length > 1" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage3Milestone2.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage3Milestone2.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage3Milestone2.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="Stage3mile2departmentsdisplay=true" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage3mile2departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage3Mile2Departments [value]="Stage3Milestone2Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Mile2Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage3Milestone2.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage3Milestone2.approval=='N'" pTooltip="View" tooltipPosition="right" (click)="Stage3mile2approvalmembersdisplay=true" class="p-button-info" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage3mile2approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage3Milestone2.listName }} Approvers</h5>
                                                                            <p-table #dtStage3Mile2ApprovalTeam [value]="Stage3Mile2ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Mile2ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage3Milestone2.isDocuments" [ngClass]="{'p-button-success': Stage3Milestone2.isDocuments}" (click)="Documents('List',Stage3Milestone2.listID,'Step',Stage3Milestone2.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage3Milestone2.isNotes" [ngClass]="{'p-button-success': Stage3Milestone2.isNotes}" (click)="Notes('List',Stage3Milestone2.listID,'Step',Stage3Milestone2.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage3Mile2Documents?.length > 0">
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage3Mile2Docs [value]="Stage3Mile2Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage3Mile2Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3mile2')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage3Mile2Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage3Milestone3.listName }}" *ngIf="Stage3Milestones?.length > 2" >
                                            <div class="p-grid">
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"  style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span style="font-weight: bold;">Name:</span>
                                                            <span>{{ Stage3Milestone3.listName }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Status:</span>
                                                            <span>{{ Stage3Milestone3.listStatus }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Owner:</span>
                                                            <span>{{ Stage3Milestone3.listOwner }}</span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                    <ul class="widget-image-list">
                                                        <li>
                                                            <span  style="font-weight: bold;">Responsible Departments:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                                <button pButton type="button"  (click)="Stage3mile3departmentsdisplay=true" pTooltip="View" tooltipPosition="right"  icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                                                                <p-dialog header="Responsible Departments" [(visible)]="Stage3mile3departmentsdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage3Mile3Departments [value]="Stage3Milestone3Departments" [columns]="Departmentscols" sortMode="multiple" [loading]="loading"
                                                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Mile3Departments" > 
                                                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr><td colspan="2">No records found...</td></tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>   
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approval Required:</span>
                                                            <span style="padding-right:10px;">{{ Stage3Milestone3.approval }}</span>
                                                        </li>
                                                        <li>
                                                            <span style="font-weight: bold;">Approvers:</span>
                                                            <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" [disabled] = "Stage3Milestone3.approval=='N'" (click)="Stage3mile3approvalmembersdisplay=true" class="p-button-info"  pTooltip="View" tooltipPosition="right" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"  ></button>
                                                                <p-dialog header="Approvers" [(visible)]="Stage3mile3approvalmembersdisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                                    <hr>
                                                                    <div class="p-grid">
                                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                                            <h5 style="text-align:center">{{ Stage3Milestone3.listName }} Approvers</h5>
                                                                            <p-table #dtStage3Mile3ApprovalTeam [value]="Stage3Mile3ApprovalTeam" [columns]="ApprovalTeamcols" sortMode="multiple" [loading]="loading"
                                                                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectStage3Mile3ApprovalTeam" > 
                                                                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                                                                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                                                                                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                                                                    <tr [pSelectableRow]="rowData" >
                                                                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                                                                                        <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                            <span *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span></td>
                                                                                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage">
                                                                                    <tr><td colspan="7">No records found...</td></tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                        </div>
                                                                    </div>             
                                                                </p-dialog>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>    
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage3Milestone3.isDocuments" [ngClass]="{'p-button-success': Stage3Milestone3.isDocuments}" (click)="Documents('List',Stage3Milestone3.listID,'Step',Stage3Milestone3.listName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>    
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" [disabled]="!Stage3Milestone3.isNotes" [ngClass]="{'p-button-success': Stage3Milestone3.isNotes}" (click)="Notes('List',Stage3Milestone3.listID,'Step',Stage3Milestone3.listName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Stage3Mile3Documents?.length > 0" >
                                                    <div  class="invoice invoice-header" id="invoice-content">
                                                    <p-table #dtStage3Mile3Docs [value]="Stage3Mile3Documents" [columns]="documents" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage3Mile3Docs" dataKey="reqDocumentID" [autoLayout]="true">
                                                        <ng-template pTemplate="caption">
                                                            <div>Tasks
                                                                <!-- <span class="p-input-icon-left" style="float:right">
                                                                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3mile3')"></button>
                                                                </span> -->
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                                            <tr>
                                                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width120': i == 2, 'width120': i == 4}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                                                                <th style="text-align: center">Documents</th>
                                                                <th style="text-align: center">Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                                                    <input pInputText type="text" (input)="dtStage3Mile3Docs.filter($event.target.value,doc.field, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData>
                                                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                                                                <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                                                                <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td>
                                                                <td style="text-align: center;"><span>{{rowData.documentLabels }}</span></td>
                                                                <td style="text-align:left; padding-left:15px;">
                                                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                                        <li class="templi"><span *ngIf="temp.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                                                            <span *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isDocuments" [ngClass]="{'p-button-success': rowData.isDocuments}" (click)="Documents('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Documents" tooltipPosition="right" icon="pi pi-file" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <span class="listitem-value">
                                                                        <button pButton type="button" [disabled]="!rowData.isNotes" [ngClass]="{'p-button-success': rowData.isNotes}" (click)="Notes('listdocument',rowData.reqDocumentID,'Document',rowData.documentName)" pTooltip="Notes" tooltipPosition="right" icon="pi pi-comment" style="width:25px;height:25px;"></button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr><td colspan="7">No records found...</td></tr>
                                                        </ng-template>
                                                    </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </p-tabPanel>
                            </p-tabView>
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div class="card">
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;">{{ phasename }} Approval Details </h5> 
                <span class="p-input-icon-left" style="float:right; padding-right: 10px;" *ngIf="DisplayOthers">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="MoreApprovalDetails = true" label="Other Approvals"
                    icon="pi pi-eye" class="p-button-raised p-button-primary" style="height:25px; width:160px;"></button>
                </span>
            </div>
                
            <p-table #dtApprove [value]="ApproveDetails" sortMode="multiple" [columns]="approvers" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
            selectionMode="multiple" [(selection)]="selectedApprove"
                [globalFilterFields]="['departmentName','userName','approvalStatus','approveDate','comments']">
            <ng-template pTemplate="header" let-columns  class="invoice-items">
                <tr>
                    <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr class="p-selectable-row">
                    <td style="padding-left:15px;"><span>{{rowData.type}}</span></td>
                    <td style="padding-left:15px;"><span>{{rowData.userName}}</span></td>
                    <td style="padding-left:15px;"><span>{{rowData.roleName}}</span></td>
                    <td style="padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                    <td style="padding-left:15px;"><span *ngIf="rowData.type != 'Notification Recipient'">
                        <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                        <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                    </span></td>
                    <td style="text-align: center;"><span *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                    <td style="padding-left:15px;" title="{{rowData.comments}}"><span>{{rowData.shortComments}}</span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr><td colspan="7">No records found...</td></tr>
            </ng-template>
            </p-table>
            <br>
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;">Clarification Details </h5> 
            </div>
            <p-table #dtClarification1 [value]="Clarification1" [columns]="ClarificationCols" sortMode="multiple" [loading]="loading"
                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification1"
                > 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                    <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                        <th>Sought To</th>
                    </tr>
                    
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData" >
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center;padding-left:15px;">{{ rowData[col.field] }}</td>
                    </ng-container>
                    <td style="text-align: center;">
                        <button pButton type="button"  pTooltip="View" tooltipPosition="right" (click)="GetTeamDetails(rowData)" icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;" ></button>
                    </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-col-12 p-md-12 p-xl-12" >
                <label> Remark / Question  <span style="color: red">*</span> </label> 
                <small class="p-invalid" style="float: right;">{{1000 - popupremark.length}} characters are remaining</small>
                <textarea [disabled]="!approveview" rows="5" pInputTextarea id="popupremark" [(ngModel)]="popupremark" placeholder="Approved"
                    maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
                <br>
                <small class="p-invalid"><b>{{ RemarkError }}</b></small>
                <span style="float: right;">
                    <button pButton type="button" [disabled]="!approveview || Management || notready" (click)="Add('Approve')" label="Approve" icon="pi pi-check" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 100px;height: 25px;float: right;"></button>
                    <button pButton type="button" [disabled]="!approveview || Management" (click)="Add('Clare')" label="Seek Clarification" pTooltip="Seek Clarification" tooltipPosition="right" icon="pi pi-question" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width:160px; height:25px;" ></button>
                </span>
            </div>
            <div *ngIf="ViewIssues">
                <div class="card-header" style="border-radius: 5px; margin-top:-5px;height:40px;">
                    <h5 style="color:#fff; padding:15px 0 0 15px;">Issues</h5> 
                </div>
                <p-table #dtIssues [value]="Issues" [columns]="issuesCol" sortMode="multiple" [loading]="loading"
                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectIssues"> 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                   <tr> <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData" >
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field]| date:'MM/dd/yyyy'  }}</td>
                        <td *ngIf="i == 3" style="text-align: center">{{ rowData[col.field]| date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
            <br>
            </div>
            <div *ngIf="ViewTasks">
                <div class="card-header" style="border-radius: 5px; margin-top:-5px;height:40px;">
                    <h5 style="color:#fff; padding:15px 0 0 15px;">Milestones</h5> 
                </div>
                <p-table #dtTasks [value]="Tasks" [columns]="TasksCol" sortMode="multiple" [loading]="loading"
                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectTasks"> 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr><th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData" >
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 4" style="text-align: center;">{{ rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 5" style="text-align: center;">{{ rowData[col.field] | date:'MM/dd/yyyy'}}</td>
                    </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
            </div>
        </div>
    </div>
    </div>
    </div>
    
    
<p-dialog header="Discontinuation Details" [(visible)]="PClare" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center">{{Product.productName}} Discontinuation Approval Details</h5>
            <p-table #dtDiscontinueApprove [value]="DiscontinueApproveDetails" sortMode="multiple" [columns]="discontinueapprovers" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
            selectionMode="multiple" [(selection)]="selectedDiscontinue"
                [globalFilterFields]="['actionDate','departmentName','userName','approvalStatus','approveDate','comments']">
            <ng-template pTemplate="header" let-columns  class="invoice-items">
                <tr>
                    <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field" [ngClass]="{'width140': i == 0 || i == 6, 'width60': i == 1}">
                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr class="p-selectable-row">
                    <td style="text-align: center;"><span>{{rowData.actionDate | date:'MM/dd/yyyy'}}</span></td>
                    <td style="text-align: center;"><span>{{rowData.seq}}</span></td>
                    <td style="padding-left:15px;"><span>{{rowData.userName}}</span></td>
                    <td style="padding-left:15px;"><span>{{rowData.roleName}}</span></td>
                    <td style="padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                    <td style="padding-left:15px;"><span *ngIf="rowData.type != 'Notification Recipient'">
                        <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                        <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                    </span></td>
                    <td style="text-align: center;"><span *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                    <td style="padding-left:15px;" title="{{rowData.comments}}"><span>{{rowData.shortComments}}</span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr><td colspan="8">No records found...</td></tr>
            </ng-template>
            </p-table>
            <h5 style="text-align:center">{{Product.productName}} Discontinuation Clarification Details</h5>
            <p-table #dtPClarification [value]="PClarification" [columns]="ClarificationCols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedPClarification"> 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                    <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                        style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" >
                    <tr [pSelectableRow]="rowData" >
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                    </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr><td colspan="6">No records found...</td></tr>
                </ng-template>
            </p-table>
        </div>
    </div>          
</p-dialog>

    <p-dialog header="Other Approval Details" [(visible)]="MoreApprovalDetails" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
        <hr>                                       
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-xl-12">
                <h5 style="color:#495057; padding:15px 0 0 15px;">Approval Details </h5> 
                <p-table #dtOtherApprove [value]="OtherApproveDetails" [columns]="other" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                selectionMode="multiple" [(selection)]="selectedOtherApprove"
                    [globalFilterFields]="['departmentName','userName','approvalStatus','approveDate','comments']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                            style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="p-selectable-row">
                        <td style="padding-left:15px;"><span>{{rowData.phase}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.userName}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.roleName}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.approvalStatus}}</span></td>
                        <td style="text-align: center;"><span *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                        <!-- <td style="padding-left:15px;"><span>{{rowData.approveDate | date:'MM/dd/yyyy HH:mm'}}</span></td> -->
                        <td style="padding-left:15px;" title="{{rowData.comments}}"><span>{{rowData.shortComments}}</span></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr><td colspan="7">No records found...</td></tr>
                </ng-template>
                </p-table>
                <h5 style="color:#495057; padding:15px 0 0 15px;">Clarification Details</h5>
                <p-table #dtOtherClarification [value]="OtherClarification" [columns]="ClarificationCols" sortMode="multiple" [loading]="loading"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedOtherClarification"
                    > 
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr><th style="text-align: center;width: 60px;">S.No</th>
                        <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                            style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" >
                        <tr [pSelectableRow]="rowData" >
                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                            <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 1" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                            <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 3" style="text-align: center">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 4" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                            <td *ngIf="i == 5" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 6" style="text-align: center">{{ rowData[col.field] }}</td>
                        </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr><td colspan="7">No records found...</td></tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-dialog>

    <p-dialog header="{{ Product.productName }}" [(visible)]="projectdisplay" modal="modal" showEffect="fade" >
        <hr>
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-xl-6">
                <!-- <h5>Product Information</h5> -->
                <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:250px;">
                    <ul class="widget-image-list">
                    <li>
                        <span style="font-weight: bold;">Product Line:</span>
                        <span>{{ Product.spaceName }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Project:</span>
                        <span>{{ Product.productName }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Project Status:</span>
                        <span>{{ Product.productStatus }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Product Engineer:</span>
                        <span>{{ Product.productOwner }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Current Phase:</span>
                        <span>{{ Product.currentPhase }}</span>
                    </li>
                    </ul>
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-xl-6">
                <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:250px;">
                    <ul class="widget-image-list">
                        <li>
                            <span style="font-weight: bold;">Start Date:</span>
                            <span>{{ Product.startDate | date:'MM/dd/yyyy' }}</span>
                        </li>
                        <li>
                            <span style="font-weight: bold;">Due Date:</span>
                            <span>{{ Product.dueDate | date:'MM/dd/yyyy' }}</span>
                        </li>
                        <li>
                            <span style="font-weight: bold;align-self: baseline;">Description:</span>
                            <span style="text-align:right;">{{ Product.description }}</span>
                        </li>
                        <!-- <li>
                            <span style="font-weight: bold;vertical-align: top;">Concept Files:</span>
                            <span>
                                <div *ngIf='ProductFiles != undefined'>
                                    <div style="padding-bottom:10px;" *ngFor="let item of ProductFiles;">
                                        <span>
                                        <a target="_blank" href="{{ DMSURL }}{{item.filePath}}"> 
                                        {{ item.fileName }} 
                                        </a>
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="p-col-12 p-md-6 p-xl-6" *ngIf='CustomFields != undefined'>
                <h5>Additional Attributes</h5>
                <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:380px;">
                    <ul class="widget-image-list">
                        <li *ngFor="let item of CustomFields;">
                            <span style="font-weight: bold;">{{item.customFieldName}}:</span>
                            <span>{{item.value}}</span>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </p-dialog>
    
    <p-dialog header="View Documents" [(visible)]="documentsdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}" >
        <hr>                                       
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-xl-12">
                <h5 style="text-align:center;">{{Level}}: {{LevelName}}</h5>
                <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="viewdoccols" sortMode="multiple" [loading]="loading" [filterDelay]="0" [autoLayout]="true"
                styleClass="p-datatable-striped" selectionMode="multiple" [(selection)]="selectedViewAddedDocuments" [globalFilterFields]="['displayName','addedByName','addedDate']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                        </tr>
                        <tr>
                            <th style="text-align: center" [ngSwitch]="addedByName">
                                <input pInputText type="text" (input)="dtViewDocuments.filter($event.target.value,addedByName, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%; height: 25px;"/>
                            </th>
                            <th style="text-align: center" [ngSwitch]="addedDate">
                                <input pInputText type="text" (input)="dtViewDocuments.filter($event.target.value,addedDate, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height: 25px"/>
                            </th>
                            <th style="text-align: center" [ngSwitch]="displayName">
                                <input pInputText type="text" (input)="dtViewDocuments.filter($event.target.value,displayName, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height: 25px"/>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr class="p-selectable-row">
                            <td style="text-align: center"><span>{{rowData.addedByName}}</span></td>
                            <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                            <td style="text-align: center">
                                <span *ngIf="rowData.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(rowData)">{{rowData.displayName}} </a> </span>
                                <span *ngIf="rowData.isDocAccess!=true && !ispe && !ispo">{{rowData.displayName}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr><td colspan="3">No records found...</td></tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-dialog>
    <p-dialog header="View Notes" [(visible)]="notesdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}" >
         <hr>                                       
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-xl-12">
                <h5 style="text-align:center;">{{Level}}: {{LevelName}}</h5>
                <p-table #dtViewNotes [value]="ViewAddedNotes" [columns]="viewnotecols" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0" [autoLayout]="true"
                selectionMode="multiple" [(selection)]="selectedViewAddedNotes" [globalFilterFields]="['note','addedByName','addedDate']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                    </tr>
                    <tr>
                        <th style="text-align: center" [ngSwitch]="addedByName">
                            <input pInputText type="text" (input)="dtViewNotes.filter($event.target.value,addedByName, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                        </th>
                        <th style="text-align: center" [ngSwitch]="addedDate">
                            <input pInputText type="text" (input)="dtViewNotes.filter($event.target.value,addedDate, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                        </th>
                        <th style="text-align: center" [ngSwitch]="note">
                            <input pInputText type="text" (input)="dtViewNotes.filter($event.target.value,note, 'startsWith')" placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="p-selectable-row">
                        <td style="text-align: center">{{rowData.addedByName}}</td>
                        <td style="text-align: center">{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</td>
                        <!-- <td style="text-align: center">{{rowData.note}}</td> -->
                        <td style="text-align: center"><div class="p-mb-3 demo-container p-text-nowrap p-text-truncate" style="width:15rem" (click)=tablenotes(rowData.note)>{{rowData.shortnote}}</div></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr><td colspan="3">No records found...</td></tr>
                </ng-template>
            </p-table>
            </div>
            <div class="p-col-12 p-md-12 p-xl-12">
                <label> Notes</label>
                <textarea rows="5" pInputTextarea id="popupnote" [(ngModel)]="popupnote" placeholder="Enter Notes"
                    required="true" requiredMessage="please enter notes." maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
                <small class="p-invalid">{{1000 - popupnote.length}} characters are remaining</small>
            </div>
        </div>            
    </p-dialog>
     <p-dialog header="{{PrevCRName}}" [(visible)]="PrevDisplay" modal="modal" showEffect="fade" [style]="{width:'90%'}" >
    <hr>
    <div class="p-fluid p-formgrid p-grid" *ngFor="let cr of prevCR">                                              
        <div class="p-field p-col-12 p-md-2">
          <label><b>Quantity Limit:</b>&nbsp;&nbsp;{{cr.quantityLimit}}</label>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label><b>Time Limit:</b>&nbsp;&nbsp;{{cr.timeLimit | date:'MM/dd/yyyy'}}</label>
        </div> 
        <div class="p-field p-col-12 p-md-2">
          <label><b>Status:</b>&nbsp;&nbsp;{{cr.status}}</label>
      </div>
      <div class="p-field p-col-12 p-md-2" *ngIf="prevcrtype == 1">
            <label><b>Manufacturing:</b>&nbsp;&nbsp;<span *ngIf="cr.isManufacturingAndShipping == true" >Yes</span>
                <span *ngIf="cr.isManufacturingAndShipping != true ">No</span></label>
        </div>
        <div class="p-field p-col-12 p-md-4" *ngIf="prevcrtype == 1">
            <label><b>Manufacturing and Shipping:</b>&nbsp;&nbsp;<span *ngIf="cr.isManufacturingAndShipping == true" >No</span>
                <span *ngIf="cr.isManufacturingAndShipping != true ">Yes</span></label>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="cr.viewDocuments != null && cr.viewDocuments.length > 0">
            <label><b>Supporting Docs</b></label>
            <ul>
                <li *ngFor="let file of cr.viewDocuments">
                    <span *ngIf="file.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(file)">{{file.displayName}} </a> </span>
                    <span *ngIf="file.isDocAccess!=true && !ispe && !ispo">{{file.displayName}}</span>
                </li>
            </ul>
      </div>
      <div class="p-field p-col-12">
          <label><b>Justification:</b>&nbsp;&nbsp;{{cr.justification}}</label>
          <br/>
          <div *ngIf="cr.approvals != null && cr.approvals != undefined && cr.approvals.length > 0">
              <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                  <h5 style="color:#495057; padding:15px 0 0 15px;">Approval Details </h5> 
              </div>
              <p-table #dt [value]="cr.approvals" [columns]="approvers1" sortMode="multiple" class="table-align"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                            {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                    <tr [pSelectableRow]="rowData" >
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 4" style="text-align: center;">
                            <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                            <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                        </td>
                        <td *ngIf="i == 5" style="text-align: center;"><span *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                        <td *ngIf="i == 6" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                        </ng-container>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8" style="text-align: left;">No records found...</td>
                    </tr>
                    </ng-template>
                </p-table>
          </div> 
          <br>
          <div *ngIf="cr.clarifications != null && cr.clarifications != undefined && cr.clarifications.length > 0">
              <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                  <h5 style="color:#495057; padding:15px 0 0 15px;">Clarification Details </h5> 
              </div>
              <p-table #dtClarification1 [value]="cr.clarifications" [columns]="ClarificationCols" sortMode="multiple" [loading]="loading"
                  styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" 
                  > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align: center;width: 60px;">S.No</th>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                      <tr [pSelectableRow]="rowData" >
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                          <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                          <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                          <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                          <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                          <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                          <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                          <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
          <br>
          <div style="border-top:solid 1px;"></div>
        </div>
  </div>               
</p-dialog>

    <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;" *ngIf="showLoading">
        <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
      </div>
      <p-dialog header="{{ConditionalReleaseType}}" [(visible)]="conditionaldisplay" modal="modal" showEffect="fade" [style]="{width:'90%'}" >
        <hr>
        <div class="p-fluid p-formgrid p-grid">                                              
            <div class="p-field p-col-12 p-md-2">
                <label>Quantity Limit</label>
                <input [(ngModel)]="QuantityLimit" type="text" pInputText disabled/>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Time Limit</label>
                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="TimeLimit" [disabled]="true"></p-calendar>
            </div> 
            <div class="p-field p-col-12 p-md-2">
                <label>Status</label>
                <input [(ngModel)]="status" type="text" pInputText disabled/>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="EvalConditionalType==1"><br>
                <div class="p-field-radiobutton">
                    <p-radioButton name="radiobutton" value="Yes" [(ngModel)]="IsManufacturingAndShipping" disabled></p-radioButton>
                    <label>Manufacturing</label>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-4" *ngIf="EvalConditionalType==1"><br>
                <div class="p-field-radiobutton">
                <p-radioButton name="radiobutton" value="No" [(ngModel)]="IsManufacturingAndShipping" disabled></p-radioButton>
                <label>Manufacturing and Shipping</label>
            </div>
            </div>
            <div class="p-field p-col-12 p-md-12" *ngIf="conditionalDocs.length>0">
                <label>Supporting Docs</label>
                <ul>
                    <li *ngFor="let file of conditionalDocs" style="padding-top: 15px;">
                        <span *ngIf="file.isDocAccess==true || ispe || ispo"><a href="javascript:;" (click)="DMSToken(file)">{{file.displayName}} </a> </span>
                        <span *ngIf="file.isDocAccess!=true && !ispe && !ispo">{{file.displayName}}</span>
                    </li>
                </ul>
            </div>
            <div class="p-field p-col-12">
                <label>Justification</label>
                <textarea [(ngModel)]="Justification" rows="3" placeholder="Your Justification" maxlength="280" pInputTextarea style="opacity: 1;overflow:auto;resize:none;" disabled>
                </textarea>
            </div>
        </div>        
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Approval Details </h5> 
        </div>
        <p-table #dtApprovecon [value]="ApproveDetailscon" sortMode="multiple" [columns]="approvers1" styleClass="p-datatable-striped" [loading]="loading" [filterDelay]="0"
        selectionMode="multiple" [(selection)]="selectedApprovecon"
            [globalFilterFields]="['departmentName','userName','approvalStatus','approveDate','comments']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr><th style="text-align: center;width: 60px;">S.No</th>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}" >
                        {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                <tr [pSelectableRow]="rowData" >
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="text-align: center;">
                        <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                        <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                    </td>
                    <td *ngIf="i == 5" style="text-align: center;"><span *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                    <td *ngIf="i == 6" style="text-align: left; padding-left:15px;" title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                    </ng-container>
                </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
                </ng-template>
            </p-table>
        <br>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Clarification Details </h5> 
        </div>
        <p-table #dtClarificationcon [value]="Clarificationcon" [columns]="ClarificationCols" sortMode="multiple" [loading]="loading"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarificationcon"
            > 
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                    <th style="text-align: center;width: 60px;">S.No</th>
                    <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                  
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                <tr [pSelectableRow]="rowData" >
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                    <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                    <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                    <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                </ng-container>
               
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7" style="text-align: left;">No records found...</td>
                </tr>
            </ng-template>
        </p-table>

      </p-dialog>

      <p-dialog header="Reasons" [(visible)]="projectreasonsdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
        <hr>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-xl-12">
                <p-table #dtReasons [value]="Reasons" [columns]="Reasoncols"
                    sortMode="multiple" [loading]="loading" [autoLayout]="true"
                    styleClass="p-datatable-striped" [filterDelay]="0"
                    selectionMode="multiple" [(selection)]="selectedReason">
                    <ng-template pTemplate="header" let-columns
                        class="invoice-items">
                        <tr>
                            <th style="text-align: center; width:50px;">S.No
                            </th>
                            <th style="text-align:center;"
                                *ngFor="let col of columns"
                                [pSortableColumn]="col.field">{{ col.header }}
                                <p-sortIcon [field]="col.field"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                </p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData
                        let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container
                                *ngFor="let col of columns; let i = index"
                                [ngSwitch]="col.field">
                                <td *ngIf="i == 0"
                                    style="text-align: center;width: 50px;;">{{
                                    j + 1 }}</td>
                                <td *ngIf="i == 0" style="padding-left:15px;">{{
                                    rowData[col.field] }}</td>
                                <td *ngIf="i == 1" style="padding-left:15px;">{{
                                    rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                                <td *ngIf="i == 2" style="padding-left:15px;">{{
                                    rowData[col.field] }}</td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        </p-dialog>

        <span>
            <p-dialog header="Team Members" [(visible)]="seekteamdisplay" modal="modal" showEffect="fade" [style]="{width:'30%'}" >
                <hr>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-xl-12">
                        <p-table #dtTeam [value]="CurrentTeam" [columns]="Seekteamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedSeekTeam"> 
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                     <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                    </td>
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    
                                    <td *ngIf="i == 0" style="text-align: center; padding-left:15px;">{{ rowData[col.field] }}</td>
                                </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr><td colspan="2">No records found...</td></tr>
                            </ng-template>
                        </p-table>
                        <br/>
                        <button pButton type="button" (click)="SelectTeamClarifi('Clare')" label="Seek Clarification" pTooltip="Seek Clarification" tooltipPosition="right" icon="pi pi-question" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width:160px; height:25px;float: right;" ></button>
                       <br/>
                        <span style="color:red;">{{seekError}}</span>
                    </div>
                </div>              
            </p-dialog>
        </span>

        <p-dialog header="Team" [(visible)]="clariTeamdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}" >
            <hr>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-xl-12">
                    <p-table #dt [value]="SelectedTeamDisplay" [columns]="Seekteamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"> 
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr><th style="text-align: center;width: 50px;">S.No</th>
                        <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                            style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                            <td *ngIf="i == 0" style="text-align: center;width: 50px;">{{ j + 1 }}</td>
                            <td *ngIf="i == 0" style="text-align: left;">{{ rowData[col.field] }}</td>
                        </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr><td colspan="2">No records found...</td></tr>
                    </ng-template>
                    </p-table>
                </div>
            </div>   
            </p-dialog>
       