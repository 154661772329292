import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { FacadeService } from "../../facade/facade.service";
import { Table } from "primeng/table";
import { PrintService } from "../../services/print.service";
import {
  PhaseBar,
  Product,
  CustomFields,
  ProductFiles,
  Phases,
  PhaseDepartments,
  PhaseTeam,
  PhaseRequiredDocuments,
  List,
  ListDepartments,
  ListTeam,
  ListRequiredDocuments,
  ViewDocuments,
  ViewNotes,
  AddNotes,
  Approval,
  Clarification,
  DeleteModel
} from "../../model/PhaseDetails";
import { FileUpload } from "primeng/fileupload";
import { BASE_API_URL_TOKEN } from "src/app/injectors";
import { Inject } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";
import { ConfirmationService } from "primeng/api";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-phasedetails",
  templateUrl: "./phasedetails.component.html",
  styleUrls: ["./phasedetails.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService],
})
export class PhasedetailsComponent implements OnInit {
  //taskId = sessionStorage.getItem('taskId');
  TeamInfoDisplay: boolean;
  departmentsdisplay: boolean;
  teamdisplay: boolean;
  approvalmembersdisplay: boolean;
  documents: any[];
  selectedDoc: any[];
  @ViewChildren("cmp") components: QueryList<MultiSelect>;
  phaseitems: any;
  items: any;
  viewdoccols: any[];
  viewnotecols: any[];
  CR: string = "";
  //productdescription:any;
  @ViewChild("dtDiscontinueApprove") tablePApproval: Table;
  DiscontinueApproveDetails: any[];
  selectedDiscontinue: any[];

  PClarification: any[];
  DisplayPClare: boolean = false;
  @ViewChild("dtPClarification") tablePClarification: Table;
  selectedPClarification: any[];
  PClare: boolean = false;
  discontinueapprovers: any[]

  showLoading: boolean;
  applicationID = sessionStorage.getItem("applicationID");
  userId = sessionStorage.getItem("userGuid"); //"2A6E2C91-C022-4743-9691-6FAEC9CEF6E1";
  userintId = parseInt(sessionStorage.getItem('userId'));
  UserDepartmentID = sessionStorage.getItem("userDepartmentID");
  UserDepartment = sessionStorage.getItem("userDepartment");

  ProductID = Number(sessionStorage.getItem("ProductID"));
  PhaseID = Number(sessionStorage.getItem("PhaseID"));
  // ProductLine= sessionStorage.getItem("ProductLine");
  PhaseGUID: string;
  ImageName: string = "";
  PhaseColor: string = "";
  loading: "loading";
  Bar: PhaseBar[];
  Product: Product;
  CustomFields: CustomFields[];
  ProductFiles: ProductFiles[];
  projectdisplay: boolean;

  Clarification1: any[];
  selectedClarification1: any[];
  @ViewChild('dtClarification1') tableClarification1: Table;
  // Clarification1Cols: any[];

  ViewOnly: boolean;
  ViewOnlyTabs: boolean;
  documentsdisplay: boolean = false;
  notesdisplay: boolean = false;
  deletenote: boolean = false;
  deletedocument: boolean = false;
  ErrorDisplay: boolean = false;
  Error: string = "";
  DeleteModel: DeleteModel;
  AllPhases: Phases[];
  CurrentPhase: Phases;
  CurrentDepartments: PhaseDepartments[];
  selectedDepartments: PhaseDepartments[];
  @ViewChild("dtDepartments") tableDepartments: Table;
  Departmentscols: any[];
  togetherTeam: PhaseTeam[];

  CurrentTeam: PhaseTeam[];
  selectedTeam: PhaseTeam[];
  @ViewChild("dtTeam") tableTeam: Table;
  CurrentApprovalTeam: PhaseTeam[];
  CurrentNRTeam: PhaseTeam[];
  selectedApprovalTeam: PhaseTeam[];
  @ViewChild("dtApprovalTeam") tableApprovalTeam: Table;
  @ViewChild("dtNRTeam") tableNRTeam: Table;

  projectreasonsdisplay: boolean = false;
  Reasons: any[];
  selectedReason: PhaseTeam[];
  @ViewChild("dtReasons") tableReason: Table;
  Reasoncols: any[];

  Teamcols: any[];
  ApprovalTeamcols: any[];
  NRTeamcols: any[];
  listApprovalMembers: any;
  approvalvalues: string[];

  CurrentRequiredDocuments: PhaseRequiredDocuments[];
  selectedDocs: PhaseRequiredDocuments[];
  @ViewChild("dtDocs") tableDocs: Table;

  AllList: List[];
  ListDepartments: ListDepartments[];
  ListTeam: ListTeam[];
  ListRequiredDocuments: ListRequiredDocuments[];

  Milestones: List[];
  Stages: List[];

  Clarification: any[];
  selectedClarification: any[];
  @ViewChild("dtClarification") tableClarification: Table;
  ClarificationCols: any[];

  ClarificationCols1: any[];
  departmentsData: any;
  addnotesdisplay: boolean = false;
  ViewAddnotesdisplay: boolean = true;
  viewnotesdisplay: boolean = false;
  adddocumentsdisplay: boolean = false;
  viewdocumentsdisplay: boolean = false;
  //Stage1 begin
  Stage1: List;
  Stage1Departments: ListDepartments[];
  selectStage1Departments: ListDepartments[];
  @ViewChild("dtStage1Departments") tableStage1Departments: Table;

  Stage1ApprovalTeam: ListTeam[];
  selectStage1ApprovalTeam: ListTeam[];
  @ViewChild("dtStage1ApprovalTeam") tableStage1ApprovalTeam: Table;

  Stage1Documents: ListRequiredDocuments[];
  selectedStage1Docs: ListRequiredDocuments[];
  @ViewChild("dtStage1Docs") tableStage1Docs: Table;

  Stage1Milestones: List[];
  //Milestone1 begin
  Stage1Milestone1: List;
  Stage1Milestone1Departments: ListDepartments[];
  selectStage1Mile1Departments: ListDepartments[];
  @ViewChild("dtStage1Mile1Departments") tableStage1Mile1Departments: Table;

  Stage1Mile1ApprovalTeam: ListTeam[];
  selectStage1Mile1ApprovalTeam: ListTeam[];
  @ViewChild("dtStage1Mile1ApprovalTeam") tableStage1Mile1ApprovalTeam: Table;

  Stage1Mile1Documents: ListRequiredDocuments[];
  selectedStage1Mile1Docs: ListRequiredDocuments[];
  @ViewChild("dtStage1Mile1Docs") tableStage1Mile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Stage1Milestone2: List;
  Stage1Milestone2Departments: ListDepartments[];
  selectStage1Mile2Departments: ListDepartments[];
  @ViewChild("dtStage1Mile2Departments") tableStage1Mile2Departments: Table;

  Stage1Mile2ApprovalTeam: ListTeam[];
  selectStage1Mile2ApprovalTeam: ListTeam[];
  @ViewChild("dtStage1Mile2ApprovalTeam") tableStage1Mile2ApprovalTeam: Table;

  Stage1Mile2Documents: ListRequiredDocuments[];
  selectedStage1Mile2Docs: ListRequiredDocuments[];
  @ViewChild("dtStage1Mile2Docs") tableStage1Mile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Stage1Milestone3: List;
  Stage1Milestone3Departments: ListDepartments[];
  selectStage1Mile3Departments: ListDepartments[];
  @ViewChild("dtStage1Mile3Departments") tableStage1Mile3Departments: Table;

  Stage1Mile3ApprovalTeam: ListTeam[];
  selectStage1Mile3ApprovalTeam: ListTeam[];
  @ViewChild("dtStage1Mile3ApprovalTeam") tableStage1Mile3ApprovalTeam: Table;

  Stage1Mile3Documents: ListRequiredDocuments[];
  selectedStage1Mile3Docs: ListRequiredDocuments[];
  @ViewChild("dtStage1Mile3Docs") tableStage1Mile3Docs: Table;
  //Milestone3 End
  //Stage1 end

  //Stage2 begin
  Stage2: List;
  Stage2Departments: ListDepartments[];
  selectStage2Departments: ListDepartments[];
  @ViewChild("dtStage2Departments") tableStage2Departments: Table;

  Stage2ApprovalTeam: ListTeam[];
  selectStage2ApprovalTeam: ListTeam[];
  @ViewChild("dtStage2ApprovalTeam") tableStage2ApprovalTeam: Table;

  Stage2Documents: ListRequiredDocuments[];
  selectedStage2Docs: ListRequiredDocuments[];
  @ViewChild("dtStage2Docs") tableStage2Docs: Table;

  Stage2Milestones: List[];
  //Milestone1 begin
  Stage2Milestone1: List;
  Stage2Milestone1Departments: ListDepartments[];
  selectStage2Mile1Departments: ListDepartments[];
  @ViewChild("dtStage2Mile1Departments") tableStage2Mile1Departments: Table;

  Stage2Mile1ApprovalTeam: ListTeam[];
  selectStage2Mile1ApprovalTeam: ListTeam[];
  @ViewChild("dtStage2Mile1ApprovalTeam") tableStage2Mile1ApprovalTeam: Table;

  Stage2Mile1Documents: ListRequiredDocuments[];
  selectedStage2Mile1Docs: ListRequiredDocuments[];
  @ViewChild("dtStage2Mile1Docs") tableStage2Mile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Stage2Milestone2: List;
  Stage2Milestone2Departments: ListDepartments[];
  selectStage2Mile2Departments: ListDepartments[];
  @ViewChild("dtStage2Mile2Departments") tableStage2Mile2Departments: Table;

  Stage2Mile2ApprovalTeam: ListTeam[];
  selectStage2Mile2ApprovalTeam: ListTeam[];
  @ViewChild("dtStage2Mile2ApprovalTeam") tableStage2Mile2ApprovalTeam: Table;

  Stage2Mile2Documents: ListRequiredDocuments[];
  selectedStage2Mile2Docs: ListRequiredDocuments[];
  @ViewChild("dtStage2Mile2Docs") tableStage2Mile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Stage2Milestone3: List;
  Stage2Milestone3Departments: ListDepartments[];
  selectStage2Mile3Departments: ListDepartments[];
  @ViewChild("dtStage2Mile3Departments") tableStage2Mile3Departments: Table;

  Stage2Mile3ApprovalTeam: ListTeam[];
  selectStage2Mile3ApprovalTeam: ListTeam[];
  @ViewChild("dtStage2Mile3ApprovalTeam") tableStage2Mile3ApprovalTeam: Table;

  Stage2Mile3Documents: ListRequiredDocuments[];
  selectedStage2Mile3Docs: ListRequiredDocuments[];
  @ViewChild("dtStage2Mile3Docs") tableStage2Mile3Docs: Table;
  //Milestone3 End
  //Stage2 end

  //Stage3 begin
  Stage3: List;
  Stage3Departments: ListDepartments[];
  selectStage3Departments: ListDepartments[];
  @ViewChild("dtStage3Departments") tableStage3Departments: Table;

  Stage3ApprovalTeam: ListTeam[];
  selectStage3ApprovalTeam: ListTeam[];
  @ViewChild("dtStage3ApprovalTeam") tableStage3ApprovalTeam: Table;

  Stage3Documents: ListRequiredDocuments[];
  selectedStage3Docs: ListRequiredDocuments[];
  @ViewChild("dtStage3Docs") tableStage3Docs: Table;

  Stage3Milestones: List[];
  //Milestone1 begin
  Stage3Milestone1: List;
  Stage3Milestone1Departments: ListDepartments[];
  selectStage3Mile1Departments: ListDepartments[];
  @ViewChild("dtStage3Mile1Departments") tableStage3Mile1Departments: Table;

  Stage3Mile1ApprovalTeam: ListTeam[];
  selectStage3Mile1ApprovalTeam: ListTeam[];
  @ViewChild("dtStage3Mile1ApprovalTeam") tableStage3Mile1ApprovalTeam: Table;

  Stage3Mile1Documents: ListRequiredDocuments[];
  selectedStage3Mile1Docs: ListRequiredDocuments[];
  @ViewChild("dtStage3Mile1Docs") tableStage3Mile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Stage3Milestone2: List;
  Stage3Milestone2Departments: ListDepartments[];
  selectStage3Mile2Departments: ListDepartments[];
  @ViewChild("dtStage3Mile2Departments") tableStage3Mile2Departments: Table;

  Stage3Mile2ApprovalTeam: ListTeam[];
  selectStage3Mile2ApprovalTeam: ListTeam[];
  @ViewChild("dtStage3Mile2ApprovalTeam") tableStage3Mile2ApprovalTeam: Table;

  Stage3Mile2Documents: ListRequiredDocuments[];
  selectedStage3Mile2Docs: ListRequiredDocuments[];
  @ViewChild("dtStage3Mile2Docs") tableStage3Mile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Stage3Milestone3: List;
  Stage3Milestone3Departments: ListDepartments[];
  selectStage3Mile3Departments: ListDepartments[];
  @ViewChild("dtStage3Mile3Departments") tableStage3Mile3Departments: Table;

  Stage3Mile3ApprovalTeam: ListTeam[];
  selectStage3Mile3ApprovalTeam: ListTeam[];
  @ViewChild("dtStage3Mile3ApprovalTeam") tableStage3Mile3ApprovalTeam: Table;

  Stage3Mile3Documents: ListRequiredDocuments[];
  selectedStage3Mile3Docs: ListRequiredDocuments[];
  @ViewChild("dtStage3Mile3Docs") tableStage3Mile3Docs: Table;
  //Milestone3 End
  //Stage3 end

  //Milestone1 begin
  Milestone1: List;
  Milestone1Departments: ListDepartments[];
  selectMile1Departments: ListDepartments[];
  @ViewChild("dtMile1Departments") tableMile1Departments: Table;

  Mile1ApprovalTeam: ListTeam[];
  selectMile1ApprovalTeam: ListTeam[];
  @ViewChild("dtMile1ApprovalTeam") tableMile1ApprovalTeam: Table;

  Mile1Documents: ListRequiredDocuments[];
  selectedMile1Docs: ListRequiredDocuments[];
  @ViewChild("dtMile1Docs") tableMile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Milestone2: List;
  Milestone2Departments: ListDepartments[];
  selectMile2Departments: ListDepartments[];
  @ViewChild("dtMile2Departments") tableMile2Departments: Table;

  Mile2ApprovalTeam: ListTeam[];
  selectMile2ApprovalTeam: ListTeam[];
  @ViewChild("dtMile2ApprovalTeam") tableMile2ApprovalTeam: Table;

  Mile2Documents: ListRequiredDocuments[];
  selectedMile2Docs: ListRequiredDocuments[];
  @ViewChild("dtMile2Docs") tableMile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Milestone3: List;
  Milestone3Departments: ListDepartments[];
  selectMile3Departments: ListDepartments[];
  @ViewChild("dtMile3Departments") tableMile3Departments: Table;

  Mile3ApprovalTeam: ListTeam[];
  selectMile3ApprovalTeam: ListTeam[];
  @ViewChild("dtMile3ApprovalTeam") tableMile3ApprovalTeam: Table;

  Mile3Documents: ListRequiredDocuments[];
  selectedMile3Docs: ListRequiredDocuments[];
  @ViewChild("dtMile3Docs") tableMile3Docs: Table;
  //Milestone3 End

  //Milestone4 begin
  Milestone4: List;
  Milestone4Departments: ListDepartments[];
  selectMile4Departments: ListDepartments[];
  @ViewChild("dtMile4Departments") tableMile4Departments: Table;

  Mile4ApprovalTeam: ListTeam[];
  selectMile4ApprovalTeam: ListTeam[];
  @ViewChild("dtMile4ApprovalTeam") tableMile4ApprovalTeam: Table;

  Mile4Documents: ListRequiredDocuments[];
  selectedMile4Docs: ListRequiredDocuments[];
  @ViewChild("dtMile4Docs") tableMile4Docs: Table;
  //Milestone4 End
  TasksCount: number = 0;
  //Milestone5 begin
  Milestone5: List;
  Milestone5Departments: ListDepartments[];
  selectMile5Departments: ListDepartments[];
  @ViewChild("dtMile5Departments") tableMile5Departments: Table;

  Mile5ApprovalTeam: ListTeam[];
  selectMile5ApprovalTeam: ListTeam[];
  @ViewChild("dtMile5ApprovalTeam") tableMile5ApprovalTeam: Table;

  Mile5Documents: ListRequiredDocuments[];
  selectedMile5Docs: ListRequiredDocuments[];
  @ViewChild("dtMile5Docs") tableMile5Docs: Table;
  //Milestone5 End

  Level: string;
  LevelName: string;
  ViewAddedDocuments: ViewDocuments[];
  selectedViewAddedDocuments: ViewDocuments[];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;

  ViewAddedNotes: ViewNotes[];
  selectedViewAddedNotes: ViewNotes[];
  @ViewChild("dtViewNotes") tableViewNotes: Table;

  AddNotesModel: AddNotes;

  NoteError: string;
  popupnote: string;
  popuptype: string;
  popupTransactionID: number;
  popupParentID: number;
  popDID: string;
  DocsError: string;

  viewclarifydisplay: boolean = false;
  ViewClarify: any[];
  selectedViewClarify: any[];
  @ViewChild("dtClarify") tableClarify: Table;
  addclar1: boolean = false;
  addclar2: boolean = false;
  Question: string;
  askedByName: string;
  QuestionError: string;
  popupquestion: string;
  CID: number;
  ClarifyModel: Clarification;
  QButton: boolean = false;

  DocumentData: any;
  @ViewChild("fileInput") fileInput: FileUpload;
  apiResponse: any;
  valRadio: any = "dms";
  docdms: boolean = true;
  docsystem: boolean = false;

  RemoveID: number;
  confirmDeleteDocument = false;
  confirmDeleteNote = false;
  index: number;
  milestoneindex: number;
  stageindex: number;
  stage1mileindex: number;
  stage2mileindex: number;
  stage3mileindex: number;
  stage1index: number;
  stage2index: number;
  stage3index: number;

  confirmHold = false;
  confirmRelease = false;
  reasondisplay = false;
  Action = "";
  popupreason: string;
  ReasonError: string;
  confirmCancel = false;
  confirmCancelwith = false;
  Dept: string = '';

  ViewIssues: boolean = false;
  Issues: any[];
  selectIssues: any[];
  @ViewChild('dtIssues') tableIssues: Table;
  issuesCol: any[];

  ViewTasks: boolean = false;
  Tasks: any[];
  selectTasks: any[];
  @ViewChild('dtTasks') tableTasks: Table;
  TasksCol: any[];
  isuus_tasks: boolean = false;
  issuelink: boolean = false;
  PMbtnDisplay: boolean = false;
  ConditionalReleaseDisplay: boolean;
  IsManufacturingAndShipping: any;
  Justification: any;
  QuantityLimit: any;
  TimeLimit: any;
  status: any = "New";
  QuantityLimitErrorMessage: any;
  TimeLimitError: any;
  ConditionalError: any;
  conditionalId: any = 0;
  conditionalDocs: any = [];
  LIissuesData: any = [];
  cols1: any = [];
  EvalCondiDisplayName: any = "";
  EvalConditionalType: any;
  conditionalFieldsDisabled: boolean;
  minDate = new Date();
  IsConditionalExist: boolean = false;
  IsEvalutionExist: boolean = false;
  IsConditionalExistInProcess: boolean = false;
  IsEvalutionExistInProcess: boolean = false;
  IsConditionalExistNew: boolean = false;
  IsEvalutionExistNew: boolean = false;
  ConditionalconfirmDeleteDocument: boolean;
  deletedCOnditionalFiles: any = [];
  coonditionalFIleGuid: any = "";
  ConditionalDeleteDocument: boolean;
  CommentsShow: any = "";
  CommentsDisplay: boolean;
  // isEOLDisplay:boolean;
  EOL: any[];
  selectedEOL: any;
  EOLError: string = "";
  PrevDisplay: boolean = false;
  prevCR: any[];
  PrevCRName: any;
  prevcrtype: 0;
  IsPrevCRDisplay: boolean;
  IsPrevEvalDisplay: boolean;
  requestData = [];
  yesbtnlabel: any = "p-button-info";
  nobtnlabel: any = "p-button-info";
  allbtnlabel: any = "p-button-info";
  TaskSelectedFilter: string = "Yes";

  Allclass: any = "p-button-info";
  CompletedClass: any = "p-button-info";
  PendingClass: any = "p-button-info";
  CompletedSelectedFilter: string = "All";

  projectDescription: any;
  userFeature: boolean = false;

  ispe: boolean = false;
  ispo: boolean = false;
  clariTeamdisplay = false;
  SelectedTeamDisplay = [];
  Seekteamcols = [];
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintService,
    private router: Router,
    private routerr: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
    private confirmation: ConfirmationService
  ) {
    this.Product = new Product();
    this.CurrentPhase = new Phases();
    this.listApprovalMembers = "";
    this.approvalvalues = [];
    this.ViewOnly = false;
    this.ViewOnlyTabs = false;
  }
  open(val) {
    this.router.navigate(['/managecustomfields', { ProductGuid: val }]);
  }
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if (feature.length > 0) {
        var featureExist = feature.find(m => m.code == "PM_PRDD");
        if (featureExist != null && featureExist != undefined)
          this.userFeature = true;
        else
          this.userFeature = false;
      }
    }

    sessionStorage.DisplayType = "Phase";
    this.showLoading = true;
    this.departmentsdisplay = false;
    this.teamdisplay = false;
    this.approvalmembersdisplay = false;
    this.projectdisplay = false;
    this.ViewOnly = false;
    this.ViewOnlyTabs = false;
    this.documentsdisplay = false;
    this.notesdisplay = false;
    this.documentsdisplay = false; this.ErrorDisplay = false;
    this.Error = "";
    this.approvalvalues = [];
    this.NoteError = "";
    this.DocsError = "";
    this.popupnote = "";
    this.popupreason = "";
    this.popupquestion = "";
    this.valRadio = "dms";
    this.docdms = true;
    this.docsystem = false;
    this.issuelink = false;
    this.getBar();

    this.Departmentscols = [{ field: "departmentName", header: "Department" }];
    this.EOL = [
      { label: 'EOL', value: 'EOL' },
      { label: 'OBS', value: 'OBS' }
    ];

    this.TasksCol = [
      { field: 'workTaskId', header: 'Mileston ID' },
      { field: 'taskSeq', header: 'Seq' },
      { field: 'taskName', header: 'Milestone' },
      { field: 'assigneeName', header: 'Assignee' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'dueDate', header: 'End Date' }
    ];
    this.issuesCol = [
      { field: 'issueCode', header: 'Issue ID' },
      { field: 'title', header: 'Title' },
      { field: 'issueDate', header: 'Issue Date' },
      { field: 'dueDate', header: 'Due Date' },
      { field: 'priority', header: 'Priority' },
      { field: 'statusName', header: 'Status' }
    ];
    this.Teamcols = [
      { field: "userName", header: "Name" },
      { field: "roleName", header: "Role" },
      { field: "departmentName", header: "Department" },
    ];
    this.cols1 = [
      { field: 'type', header: 'Type' },
      { field: 'userName', header: 'Name' },
      { field: 'roleName', header: 'Role' },
      { field: 'departmentName', header: 'Department' },
      { field: 'approvalStatus', header: 'Status' },
      { field: 'approvedDate', header: 'Approved Date' },
      { field: 'comments', header: 'Comments' }
    ];
    this.ApprovalTeamcols = [
      // { field: "type", header: "Type" },
      { field: "userName", header: "Name" },
      { field: "roleName", header: "Role" },
      { field: "departmentName", header: "Department" },
      { field: "approvedDate", header: "Approved Date" },
      { field: "shortRemarks", header: "Remarks" },
    ];
    this.NRTeamcols = [
      { field: "userName", header: "Name" },
      { field: "roleName", header: "Role" },
      { field: "departmentName", header: "Department" }
    ];
    this.Seekteamcols = [
      { field: 'userName', header: 'Name' }
    ];
    this.ClarificationCols = [
      // { field: "phase", header: "Stage / Step" },
      { field: "askedDate", header: "Sought Date" },
      { field: "question", header: "Clarification" },
      { field: "askedByName", header: "Sought By" },
      { field: "answerDate", header: "Response Date" },
      { field: "answer", header: "Response" },
      { field: "answerByName", header: "Response By" },
    ];
    // this.Clarification1Cols = [
    //   { field: 'askedDate', header: 'Sought Date' },
    //   { field: 'question', header: 'Clarification' },
    //   { field: 'askedByName', header: 'Sought By' },
    //   { field: 'answerDate', header: 'Response Date' },
    //   { field: 'answer', header: 'Response' },
    //   { field: 'answerByName', header: 'Response By' }
    // ];

    this.ClarificationCols1 = [
      { field: "askedDate", header: "Sought Date" },
      { field: "question", header: "Clarification" },
      { field: "askedByName", header: "Sought By" },
      { field: "answerDate", header: "Response Date" },
      // { field: "answer", header: "Response" },
      { field: "answerByName", header: "Response By" },
    ];
    this.viewdoccols = [
      { field: "addedByName", header: "Added By" },
      { field: "addedDate", header: "Date" },
      { field: "fileName", header: "Document" },
    ];
    this.viewnotecols = [
      { field: "addedByName", header: "Added By" },
      { field: "addedDate", header: "Date" },
      { field: "note", header: "Note" },
    ];
    this.documents = [
      { field: "departmentName", header: "Responsible Department", dynamicPlaceHolder: 'Search', width: '15%' },
      { field: "roleName", header: "Responsible Role", dynamicPlaceHolder: 'Search', width: '15%' },
      { field: "documentName", header: "Task", dynamicPlaceHolder: 'Search', width: '30%' },
      { field: "targetEndDate", header: "Target End", width: '15%' },
      { field: "startDate", header: "Start Date", width: '15%' },
      { field: "endDate", header: "End Date", width: '15%' },
      { field: "documentLabels", header: "Document Submission", dynamicPlaceHolder: 'Search', width: '15%' },
      { field: "templates", header: "Template", dynamicPlaceHolder: 'Search', width: '15%' },
      // { field: "isRequired", header: "Task Required", width: '15%' },
    ];

    this.Reasoncols = [
      { field: 'type', header: 'Type' },
      { field: 'addedDate', header: 'Added Date' },
      { field: 'reason', header: 'Reason' }
    ];
    sessionStorage.departmentName = "";
    sessionStorage.roleName = "";
    sessionStorage.documentName = "";
    sessionStorage.isRequired = "";
    sessionStorage.documentLabels = "";
    sessionStorage.templates = "";
    this.discontinueapprovers = [
      { field: 'actionDate', header: 'Sent Date' },
      { field: 'seq', header: 'Seq' },
      { field: 'userName', header: 'Approver' },
      { field: 'roleName', header: 'Role' },
      { field: 'departmentName', header: 'Department' },
      { field: 'approvalStatus', header: 'Status' },
      { field: 'approvedDate', header: 'Approved Date' },
      { field: "comments", header: "Remarks" },
    ];
    this.getDepartments();
    this.getProductDetails();
  }
  GetTeamDetails(rowData) {
    this.SelectedTeamDisplay = rowData.selectedTeam;
    this.clariTeamdisplay = true;
  }
  geTasksfilter(type) {
    this.requestData = this.CurrentRequiredDocuments;
    if (type == "Yes") {
      this.TaskSelectedFilter = "Yes";
      this.nobtnlabel = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.yesbtnlabel = "p-button-success p-ripple p-button p-component ng-star-inserted";
      this.allbtnlabel = "p-button-info p-ripple p-button p-component ng-star-inserted";

    }
    if (type == "No") {
      this.TaskSelectedFilter = "No";
      this.nobtnlabel = "p-button-success p-ripple p-button p-component ng-star-inserted";
      this.yesbtnlabel = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.allbtnlabel = "p-button-info p-ripple p-button p-component ng-star-inserted";
    }
    if (type == "All") {
      this.TaskSelectedFilter = "All";
      this.nobtnlabel = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.yesbtnlabel = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.allbtnlabel = "p-button-success p-ripple p-button p-component ng-star-inserted";
    }
    this.filter();
  }
  filter() {
    if (this.CurrentRequiredDocuments != null && this.CurrentRequiredDocuments != undefined) {
      if (this.TaskSelectedFilter == "Yes")
        this.requestData = this.requestData.filter(i => i.isRequired == "Yes");
      else if (this.TaskSelectedFilter == "No")
        this.requestData = this.requestData.filter(i => i.isRequired == "No");

      if (this.CompletedSelectedFilter == "P")
        this.requestData = this.requestData.filter(i => !i.isCompletedDisplay);
      else if (this.CompletedSelectedFilter == "D")
        this.requestData = this.requestData.filter(i => i.isCompletedDisplay);
    }
    this.Count();
  }

  geCompletedfilter(type) {
    this.requestData = this.CurrentRequiredDocuments;
    if (type == "All") {
      this.CompletedSelectedFilter = "All";
      this.PendingClass = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.Allclass = "p-button-success p-ripple p-button p-component ng-star-inserted";
      this.CompletedClass = "p-button-info p-ripple p-button p-component ng-star-inserted";
    }
    if (type == "P") {
      this.CompletedSelectedFilter = "P";
      this.PendingClass = "p-button-success p-ripple p-button p-component ng-star-inserted";
      this.Allclass = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.CompletedClass = "p-button-info p-ripple p-button p-component ng-star-inserted";
    }
    if (type == "D") {
      this.CompletedSelectedFilter = "D";
      this.Allclass = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.PendingClass = "p-button-info p-ripple p-button p-component ng-star-inserted";
      this.CompletedClass = "p-button-success p-ripple p-button p-component ng-star-inserted";
    }
    this.filter();
  }

  back() {
    if (sessionStorage.FromWhere != undefined && sessionStorage.FromWhere != null) {
      if (sessionStorage.FromWhere == "att")
        this.router.navigate(['/productsattributes']);
      else if (sessionStorage.FromWhere == "id")
        this.router.navigate(['/productid']);
      else if (sessionStorage.FromWhere == "doc")
        this.router.navigate(['/productdocuments']);
      else if (sessionStorage.FromWhere == "summ")
        this.router.navigate(['/reports']);
      else if (sessionStorage.FromWhere == "dash")
        this.router.navigate(['/dashboard']);
      else if (sessionStorage.FromWhere == "app")
        this.router.navigate(['/myapproval']);
      else if (sessionStorage.FromWhere == "mdoc")
        this.router.navigate(['/mydocuments']);
      else if (sessionStorage.FromWhere == "mprod")
        this.router.navigate(['/myproducts']);
      else if (sessionStorage.FromWhere == "odoc")
        this.router.navigate(['/otherdocuments']);
      else if (sessionStorage.FromWhere == "oprod")
        this.router.navigate(['/otherproducts']);
      else if (sessionStorage.FromWhere == "piechart")
        this.router.navigate(['/piechartreport']);
      else
        this.router.navigate(['/myproducts']);
    }
    else
      this.router.navigate(['/myproducts']);
  }
  saveeol() {
    this.EOLError = "";
    if (this.selectedEOL != null && this.selectedEOL != undefined) {
      this._facadeService.UpdateEOL(this.selectedEOL.value, this.ProductID, this.PhaseID).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.phases(this.PhaseID, this.ImageName, "All");
            this.EOLError = "Saved sucessfully";
          }
          else
            this.EOLError = this.apiResponse.returnObject;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else
      this.EOLError = "Please select EOL / OBS";
  }
  getDepartments() {
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.departmentsData = data;
        this.departmentsData = this.departmentsData.returnObject;
        console.log(this.departmentsData);
        console.log(
          "Departments data ::" + JSON.stringify(this.departmentsData)
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getBar() {
    this._facadeService.PhaseBar(this.ProductID).subscribe(
      (data) => {
        this.Bar = data.returnObject;
        console.log(this.Bar);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getProductDetails() {

    this._facadeService.ProductDetails(this.ProductID, this.userId, this.userFeature).subscribe(

      (data) => {
        this.Product = data.returnObject;
        if (this.Product.pmProjectID != undefined && this.Product.pmProjectID != null && this.Product.pmProjectID != "") {
          this.PMbtnDisplay = true;
        }

        if (this.Product.dueDate !== null && this.Product.dueDate !== undefined)
          this.Product.dueDate = new Date(new Date(this.Product.dueDate).toDateString() + " " + new Date(this.Product.dueDate).toTimeString() + " UTC");
        if (this.Product.startDate !== null && this.Product.startDate !== undefined)
          this.Product.startDate = new Date(new Date(this.Product.startDate).toDateString() + " " + new Date(this.Product.startDate).toTimeString() + " UTC");
        this.CustomFields = this.Product.customFields;
        this.ProductFiles = this.Product.productFiles;
        this.Reasons = this.Product.reasons;
        if (this.Reasons != null && this.Reasons != undefined) {
          if (this.Reasons.length > 0) {
            this.Reasons.forEach(value => {
              if (value.addedDate !== null && value.addedDate !== undefined && value.addedDate.toString() !== '0001-01-01T00:00:00')
                value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
            });
          }
        }
        if (this.Product.productOwnerID.toLowerCase() == this.userId.toLowerCase())
          this.ispe = true;
        this.phases(this.PhaseID, "bluebtn", "No");
        if (this.Product.isSendForApproval === true) {
          this.items = [
            {
              label: "Send to Discontinue",
              icon: "pi pi-times",
              command: () => {
                this.SendCancelApproval();
              },
            },
          ];
        }
        if (this.Product.isHold === true) {
          this.items = [
            {
              label: "Hold",
              icon: "pi pi-pause",
              command: () => {
                this.Hold();
              },
            },
          ];
        }
        if (this.Product.isReminder === true) {
          this.items = [
            {
              label: "Withdraw",
              icon: "pi pi-replay",
              command: () => {
                this.CancelWithdraw();
              },
            },
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.CancelReminder(this.Product.productID);
              },
            },

          ];
        }
        if (this.Product.isRelease === true) {
          this.items = [
            {
              label: "Release",
              icon: "pi pi-refresh",
              command: () => {
                this.Release();
              },
            },
          ];
        }
        if (this.Product.isClarify === true) {
          this.items = [
            {
              label: "Respond",
              icon: "pi pi-reply",
              command: () => {
                this.Clarify(
                  "Product",
                  this.Product.productID,
                  "Product",
                  this.Product.productName
                );
              },
            },
          ];
        }
        if (
          this.Product.isSendForApproval === true &&
          this.Product.isHold === true
        ) {
          this.items = [
            {
              label: "Send to Discontinue",
              icon: "pi pi-times",
              command: () => {
                this.SendCancelApproval();
              },
            },
            {
              label: "Hold",
              icon: "pi pi-pause",
              command: () => {
                this.Hold();
              },
            },
          ];
        }
        if (
          this.Product.isSendForApproval === true &&
          this.Product.isRelease === true
        ) {
          this.items = [
            {
              label: "Send to Discontinue",
              icon: "pi pi-times",
              command: () => {
                this.SendCancelApproval();
              },
            },
            {
              label: "Release",
              icon: "pi pi-refresh",
              command: () => {
                this.Release();
              },
            },
          ];
        }
        if (
          this.Product.isReminder === true &&
          this.Product.isHold === true) {
          this.items = [
            {
              label: "Withdraw",
              icon: "pi pi-replay",
              command: () => {
                this.CancelWithdraw();
              },
            },
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.CancelReminder(this.Product.productID);
              },
            },
            {
              label: "Hold",
              icon: "pi pi-pause",
              command: () => {
                this.Hold();
              },
            },
          ];
        }
        if (
          this.Product.isReminder === true &&
          this.Product.isRelease === true) {
          this.items = [
            {
              label: "Withdraw",
              icon: "pi pi-replay",
              command: () => {
                this.CancelWithdraw();
              },
            },
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.CancelReminder(this.Product.productID);
              },
            },
            {
              label: "Release",
              icon: "pi pi-refresh",
              command: () => {
                this.Release();
              },
            },
          ];
        }
        if (
          this.Product.isReminder === true &&
          this.Product.isRelease === true &&
          this.Product.isClarify === true
        ) {
          this.items = [
            {
              label: "Withdraw",
              icon: "pi pi-replay",
              command: () => {
                this.CancelWithdraw();
              },
            },
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.CancelReminder(this.Product.productID);
              },
            },
            {
              label: "Release",
              icon: "pi pi-refresh",
              command: () => {
                this.Release();
              },
            },
            {
              label: "Respond",
              icon: "pi pi-reply",
              command: () => {
                this.Clarify(
                  "Product",
                  this.Product.productID,
                  "Product",
                  this.Product.productName
                );
              },
            },
          ];
        }
        if (
          this.Product.isReminder === true &&
          this.Product.isHold === true &&
          this.Product.isClarify === true
        ) {
          this.items = [
            {
              label: "Withdraw",
              icon: "pi pi-replay",
              command: () => {
                this.CancelWithdraw();
              },
            },
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.CancelReminder(this.Product.productID);
              },
            },
            {
              label: "Hold",
              icon: "pi pi-pause",
              command: () => {
                this.Hold();
              },
            },
            {
              label: "Respond",
              icon: "pi pi-reply",
              command: () => {
                this.Clarify(
                  "Product",
                  this.Product.productID,
                  "Product",
                  this.Product.productName
                );
              },
            },
          ];
        }

        if (this.Product.isReminderECCN === true) {
          var phaseitems =
          {
            label: "ECCN Reminder",
            icon: "pi pi-bell",
            command: () => {
              this.ECCNReminder();
            },
          };

          if (this.items != null && this.items != undefined)
            this.items.push(phaseitems);
          else
            this.items = phaseitems;
        }

        let count = false;
        this._facadeService
          .GetCancelClarificationDetails(this.Product.productID)
          .subscribe((data) => {
            this.PClarification = data.returnObject;
            if (this.PClarification.length > 0 && !count)
              count = true;
            this.PClarification.forEach((value, key) => {
              if (value.askedDate !== null && value.askedDate !== undefined)
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
              if (value.answerDate !== null && value.answerDate !== undefined)
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
            });
            if (count)
              this.DisplayPClare = true;
            else
              this.DisplayPClare = false;
          });

        this._facadeService
          .GetAllCancelApprovalDetails(this.Product.productID)
          .subscribe((data) => {
            this.DiscontinueApproveDetails = data.returnObject;
            if (this.DiscontinueApproveDetails.length > 0 && !count)
              count = true;
            this.DiscontinueApproveDetails.forEach((value, key) => {
              if (value.actionDate !== null && value.actionDate !== undefined)
                value.actionDate = new Date(new Date(value.actionDate).toDateString() + " " + new Date(value.actionDate).toTimeString() + " UTC");
            });
            if (count)
              this.DisplayPClare = true;
            else
              this.DisplayPClare = false;
          });

        console.log(this.Product);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  test(val, field, dt) {
    if (field == "departmentName")
      sessionStorage.departmentName = val;
    else if (field == "roleName")
      sessionStorage.roleName = val;
    else if (field == "documentName")
      sessionStorage.documentName = val;
    else if (field == "isRequired")
      sessionStorage.isRequired = val;
    else if (field == "documentLabels")
      sessionStorage.documentLabels = val;
    else if (field == "templates")
      sessionStorage.templates = val;
    dt = dt.filter(val, field, 'startsWith');
    this.Count();
  }
  Count() {
    if (this.CurrentRequiredDocuments != undefined && this.CurrentRequiredDocuments != null) {
      var temp = this.requestData;
      if (sessionStorage.departmentName != "")
        temp = temp.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if (sessionStorage.roleName != "")
        temp = temp.filter(x => x.roleName.toLowerCase().includes(sessionStorage.roleName.toLowerCase()));
      if (sessionStorage.documentName != "")
        temp = temp.filter(x => x.documentName.toLowerCase().includes(sessionStorage.documentName.toLowerCase()));
      if (sessionStorage.isRequired != "")
        temp = temp.filter(x => x.isRequired.toLowerCase().includes(sessionStorage.isRequired.toLowerCase()));
      if (sessionStorage.documentLabels != "")
        temp = temp.filter(x => (x.documentLabels != null ? x.documentLabels.toLowerCase() : "").includes(sessionStorage.documentLabels.toLowerCase()));
      if (sessionStorage.templates != "")
        temp = temp.filter(x => x.templates.filter(y => (y.templateName != null ? y.templateName.toLowerCase() : "").includes(sessionStorage.templates.toLowerCase())));
      this.TasksCount = temp.length;
    }
    else
      this.TasksCount = 0;
  }
  phases(phaseID, ImageName, All) {
    this.showLoading = true;
    this.PhaseID = phaseID;
    this.ImageName = ImageName;
    if (this.ImageName == "greybtn") this.PhaseColor = "#a5a5a5";
    if (this.ImageName == "greenbtn") this.PhaseColor = "#37df73";
    if (this.ImageName == "bluebtn") this.PhaseColor = "#3776df";

    this.CR = "";
    this.departmentsdisplay = false;
    this.teamdisplay = false;
    this.approvalmembersdisplay = false;
    this.projectdisplay = false;
    this.ViewOnly = false;
    this.ViewOnlyTabs = false;
    this.documentsdisplay = false;
    this.notesdisplay = false;
    this.documentsdisplay = false;
    this.ErrorDisplay = false;
    this.Error = "";
    this.approvalvalues = [];
    this.NoteError = "";
    this.DocsError = "";
    this.popupnote = "";
    this.popupreason = "";
    this.popupquestion = "";
    this.valRadio = "dms";
    this.docdms = true;
    this.docsystem = false;
    this.phaseitems = null;
    this.addclar2 = false;
    this.ConditionalReleaseDisplay = false;
    this.ispo = false;
    this.Allclass = "p-button-success";
    this.CompletedClass = "p-button-info";
    this.PendingClass = "p-button-info";
    this.TaskSelectedFilter = "Yes";
    this.yesbtnlabel = "p-button-success";
    this.nobtnlabel = "p-button-info";
    this.allbtnlabel = "p-button-info";
    this.CompletedSelectedFilter = "All";
    this._facadeService.getPhaseByID(this.PhaseID, this.userId).subscribe(
      (data) => {
        this.CurrentPhase = data.returnObject;
        this.PhaseGUID = this.CurrentPhase.phaseGuid;

        if (this.CurrentPhase.phaseOwnerID.toLowerCase() == this.userId.toLowerCase())
          this.ispo = true;

        if (this.CurrentPhase.isSendForApproval === true) {
          this.phaseitems = [
            {
              label: "Send for Approval",
              icon: "pi pi-sign-out",
              command: () => {
                this.SendApproval("Phase", this.CurrentPhase.phaseID);
              },
            },
          ];
        }
        if (this.CurrentPhase.isReminder === true) {
          this.phaseitems = [
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.Reminder("Phase", this.CurrentPhase.phaseID);
              },
            },
          ];
        }
        if (this.CurrentPhase.isClarify === true) {
          this.phaseitems = [
            {
              label: "Respond",
              icon: "pi pi-reply",
              command: () => {
                this.Clarify(
                  "Phase",
                  this.CurrentPhase.phaseID,
                  "Phase",
                  this.CurrentPhase.phaseName
                );
              },
            },
          ];
        }
        if (
          this.CurrentPhase.isReminder === true &&
          this.CurrentPhase.isClarify === true
        ) {
          this.phaseitems = [
            {
              label: "Approval Reminder",
              icon: "pi pi-bell",
              command: () => {
                this.Reminder("Phase", this.CurrentPhase.phaseID);
              },
            },
            {
              label: "Respond",
              icon: "pi pi-reply",
              command: () => {
                this.Clarify(
                  "Phase",
                  this.CurrentPhase.phaseID,
                  "Phase",
                  this.CurrentPhase.phaseName
                );
              },
            },
          ];
        }
        if (this.Product.productStatus.toLocaleLowerCase() === "hold" && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
          this.CurrentPhase.phaseStatus = "Hold";
        if ((this.Product.productStatus.toLocaleLowerCase() === "discontinued" || this.Product.productStatus.toLocaleLowerCase() === "eol"
          || this.Product.productStatus.toLocaleLowerCase() === "obs")
          && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
          this.CurrentPhase.phaseStatus = "--";
        if (this.Product.productStatus.toLocaleLowerCase() === "completed" && (this.CurrentPhase.phaseStatus.toLocaleLowerCase() == "in-process" || this.CurrentPhase.phaseStatus.toLocaleLowerCase() == "ready to start") && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
          this.CurrentPhase.phaseStatus = "In-Production";
        // if (this.Product.productStatus.toLocaleLowerCase() === "completed" && this.CurrentPhase.phaseStatus.toLocaleLowerCase() == "ready to start" && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
        //   this.CurrentPhase.phaseStatus = "In-Process";

        this.IsPrevEvalDisplay = false;
        this.IsPrevCRDisplay = false;
        if (this.CurrentPhase.isPrevEvalDisplay === true)
          this.IsPrevEvalDisplay = true;
        if (this.CurrentPhase.isPrevCRDisplay === true)
          this.IsPrevCRDisplay = true;
        if (this.CurrentPhase.isConditionalReleaseDisplay === true) {
          var conditionalRelease = {
            label: "Conditional Release",
            icon: "pi pi-lock",
            command: () => {
              this.DisplayCreateConditional(1);
            },
          }
          var isExist = "";
          if (this.phaseitems != null && this.phaseitems != undefined)
            isExist = this.phaseitems.find(m => m.label == "Conditional Release");
          if (isExist == null || isExist == "" || isExist == undefined) {
            if (this.phaseitems != null && this.phaseitems != undefined)
              this.phaseitems.push(conditionalRelease);
            else
              this.phaseitems = [
                {
                  label: "Conditional Release",
                  icon: "pi pi-lock",
                  command: () => {
                    this.DisplayCreateConditional(1);
                  },
                },
              ];
          }
        }
        if (this.CurrentPhase.isEvalutionDisplay === true) {
          var conditionalRelease = {
            label: "Evaluation Sample Release",
            icon: "pi pi-lock-open",
            command: () => {
              this.DisplayCreateConditional(2);
            },
          }
          var isExist = "";
          if (this.phaseitems != null && this.phaseitems != undefined)
            isExist = this.phaseitems.find(m => m.label == "Evaluation Sample Release");
          if (isExist == null || isExist == "" || isExist == undefined) {
            if (this.phaseitems != null && this.phaseitems != undefined)
              this.phaseitems.push(conditionalRelease);
            else
              this.phaseitems = [
                {
                  label: "Evaluation Sample Release",
                  icon: "pi pi-lock-open",
                  command: () => {
                    this.DisplayCreateConditional(2);
                  },
                },
              ];
          }
        }
        this.CurrentDepartments = this.CurrentPhase.phaseDepartments;
        this.CurrentPhase.phaseTeam.forEach((value, key) => {
          if (value.approvedDate !== null && value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00')
            value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
        });
        this.CurrentTeam = this.CurrentPhase.phaseTeam.filter(item => item.type.toLocaleLowerCase() === "team");
        this.CurrentApprovalTeam = this.CurrentPhase.phaseTeam.filter(item => item.type.toLocaleLowerCase() === "approval");
        this.CurrentNRTeam = this.CurrentPhase.phaseTeam.filter(item => item.type.toLocaleLowerCase() === "collaborator");
        if (
          this.Product.productStatus.toLocaleLowerCase() === "hold" ||
          this.Product.productStatus.toLocaleLowerCase() === "waiting for discontinuation" ||
          this.Product.productStatus.toLocaleLowerCase() === "seek clarification for discontinuation" ||
          this.Product.productStatus.toLocaleLowerCase() === "discontinued"
        ) {
          this.ViewOnly = true;
          this.ViewOnlyTabs = true;
        }

        if (!this.ViewOnly) {
          this.ViewOnly = this.CurrentPhase.isViewOnly;
          this.ViewOnlyTabs = this.CurrentPhase.isViewOnly;
        }
        this.ViewOnlyTabs = true;
        this.CurrentRequiredDocuments = this.CurrentPhase.phaseRequiredDocuments;
        this.requestData = this.CurrentPhase.phaseRequiredDocuments;
        if (this.CurrentRequiredDocuments != null && this.CurrentRequiredDocuments != undefined)
          this.requestData = this.requestData.filter(i => i.isRequired == "Yes");
        this.Count();
        this.AllList = this.CurrentPhase.list;
        this.Milestones = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "step" && item.listLevel === 1);
        this.Stages = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "stage" && item.listLevel === 1);
        this._facadeService
          .ClarificationDetails(this.PhaseID)
          .subscribe((data) => {
            this.Clarification = data.returnObject;
            this.Clarification.forEach((value, key) => {
              if (value.askedDate !== null && value.askedDate !== undefined)
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
              if (value.answerDate !== null && value.answerDate !== undefined)
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
            });
            var clarificatinId = parseInt(this.routerr.snapshot.queryParamMap.get('QuestionId'));
            if (clarificatinId != null && clarificatinId > 0) {
              var alreadyanswered = this.Clarification.find(m => m.clarificationID == clarificatinId && m.answer != null && m.answer != "");
              if (alreadyanswered != null) {
                this.TeamInfoDisplay = true;
              }
            }
          });

        this.approvalvalues.push(
          "<h5 class='hclass'>Other Approvers</h5>" +
          "<table cellspacing='0' class='apptable'><tr class='apptr'><th class='appths'>S.No</th><th class='apptht'>Type</th><th class='appth'>Name</th>" +
          //"<th class='appth'>Role</th>" +
          "<th class='appth'>Department</th><th class='appth'>Approved Date</th><th class='appth'>Remarks</th></tr>");
        let count = 0;
        for (let i = 0; i < this.AllList.length; i++) {
          this.AllList[i].listTeam.forEach((value, key) => {
            if (value.approvedDate !== null && value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00')
              value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
          });
          let approvalteam: ListTeam[];
          approvalteam = this.AllList[i].listTeam.filter(
            (item) =>
              item.type.toLocaleLowerCase() === "approval" ||
              item.type.toLocaleLowerCase() === "collaborator"
          );

          if (approvalteam.length > 0) {
            for (let j = 0; j < approvalteam.length; j++) {
              count++;
              if (approvalteam[j].type.toLowerCase().includes("coll"))
                approvalteam[j].type = "Notification Recipient";
              else
                approvalteam[j].type = "Approver";
              this.approvalvalues.push(
                "<tr><td class='apptds'>" + count + "</td> <td class='apptd'>" + this.AllList[i].listName + " - " +
                approvalteam[j].type + "</td><td class='apptd'>" + approvalteam[j].userName + "</td>" +
                "<td class='apptd'>" + approvalteam[j].roleName + "</td>" +
                "<td class='apptd'>" + approvalteam[j].departmentName + "</td><td class='apptdc'>" +
                (approvalteam[j].approvedDate.toString() == '0001-01-01T00:00:00' ? '--'
                  : (new Date(approvalteam[j].approvedDate)).toLocaleDateString() + ' ' + (new Date(approvalteam[j].approvedDate)).toLocaleTimeString()) +
                "</td><td class='apptd'>" + (approvalteam[j].remarks) + "</td></tr>"
              );
            }
          }
        }
        if (count == 0)
          this.approvalvalues.push(
            "<tr><th class='apptd' colspan='7'>No record found...</th></tr>"
          );
        this.approvalvalues.push(" </table>");
        if (count != 0)
          this.listApprovalMembers = this.approvalvalues.join("");
        for (let i = 0; i < this.Milestones.length; i++) {
          if (i == 0) {
            this.Milestone1 = this.Milestones[i];
            this.Milestone1Departments = this.Milestone1.listDepartments;
            this.Mile1ApprovalTeam = this.Milestone1.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Mile1Documents = this.Milestone1.listRequiredDocuments;
          }
          if (i == 1) {
            this.Milestone2 = this.Milestones[i];
            this.Milestone2Departments = this.Milestone2.listDepartments;
            this.Mile2ApprovalTeam = this.Milestone2.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Mile2Documents = this.Milestone2.listRequiredDocuments;
          }
          if (i == 2) {
            this.Milestone3 = this.Milestones[i];
            this.Milestone3Departments = this.Milestone3.listDepartments;
            this.Mile3ApprovalTeam = this.Milestone3.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Mile3Documents = this.Milestone3.listRequiredDocuments;
          }
          if (i == 3) {
            this.Milestone4 = this.Milestones[i];
            this.Milestone4Departments = this.Milestone4.listDepartments;
            this.Mile4ApprovalTeam = this.Milestone4.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Mile4Documents = this.Milestone4.listRequiredDocuments;
          }
          if (i == 4) {
            this.Milestone5 = this.Milestones[i];
            this.Milestone5Departments = this.Milestone5.listDepartments;
            this.Mile5ApprovalTeam = this.Milestone5.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Mile5Documents = this.Milestone5.listRequiredDocuments;
          }
        }

        for (let i = 0; i < this.Stages.length; i++) {
          if (i == 0) {
            this.Stage1 = this.Stages[i];
            this.Stage1Departments = this.Stage1.listDepartments;
            this.Stage1ApprovalTeam = this.Stage1.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Stage1Documents = this.Stage1.listRequiredDocuments;
            this.Stage1Milestones = this.AllList.filter(
              (item) =>
                item.listType.toLocaleLowerCase() === "step" &&
                item.listLevel === 2 &&
                item.listParentID === this.Stage1.listID
            );

            for (let i = 0; i < this.Stage1Milestones.length; i++) {
              if (i == 0) {
                this.Stage1Milestone1 = this.Stage1Milestones[i];
                this.Stage1Milestone1Departments = this.Stage1Milestone1.listDepartments;
                this.Stage1Mile1ApprovalTeam = this.Stage1Milestone1.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage1Mile1Documents = this.Stage1Milestone1.listRequiredDocuments;
              }
              if (i == 1) {
                this.Stage1Milestone2 = this.Stage1Milestones[i];
                this.Stage1Milestone2Departments = this.Stage1Milestone2.listDepartments;
                this.Stage1Mile2ApprovalTeam = this.Stage1Milestone2.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage1Mile2Documents = this.Stage1Milestone2.listRequiredDocuments;
              }
              if (i == 2) {
                this.Stage1Milestone3 = this.Stage1Milestones[i];
                this.Stage1Milestone3Departments = this.Stage1Milestone3.listDepartments;
                this.Stage1Mile3ApprovalTeam = this.Stage1Milestone3.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage1Mile3Documents = this.Stage1Milestone3.listRequiredDocuments;
              }
            }
          }
          if (i == 1) {
            this.Stage2 = this.Stages[i];
            this.Stage2Departments = this.Stage2.listDepartments;
            this.Stage2ApprovalTeam = this.Stage2.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Stage2Documents = this.Stage2.listRequiredDocuments;
            this.Stage2Milestones = this.AllList.filter(
              (item) =>
                item.listType.toLocaleLowerCase() === "step" &&
                item.listLevel === 2 &&
                item.listParentID === this.Stage2.listID
            );

            for (let i = 0; i < this.Stage2Milestones.length; i++) {
              if (i == 0) {
                this.Stage2Milestone1 = this.Stage2Milestones[i];
                this.Stage2Milestone1Departments = this.Stage2Milestone1.listDepartments;
                this.Stage2Mile1ApprovalTeam = this.Stage2Milestone1.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage2Mile1Documents = this.Stage2Milestone1.listRequiredDocuments;
              }
              if (i == 1) {
                this.Stage2Milestone2 = this.Stage2Milestones[i];
                this.Stage2Milestone2Departments = this.Stage2Milestone2.listDepartments;
                this.Stage2Mile2ApprovalTeam = this.Stage2Milestone2.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage2Mile2Documents = this.Stage2Milestone2.listRequiredDocuments;
              }
              if (i == 2) {
                this.Stage2Milestone3 = this.Stage2Milestones[i];
                this.Stage2Milestone3Departments = this.Stage2Milestone3.listDepartments;
                this.Stage2Mile3ApprovalTeam = this.Stage2Milestone3.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage2Mile3Documents = this.Stage2Milestone3.listRequiredDocuments;
              }
            }
          }
          if (i == 2) {
            this.Stage3 = this.Stages[i];
            this.Stage3Departments = this.Stage3.listDepartments;
            this.Stage3ApprovalTeam = this.Stage3.listTeam.filter(
              (item) =>
                item.type.toLocaleLowerCase() === "approver" ||
                item.type.toLocaleLowerCase() === "notification recipient"
            );
            this.Stage3Documents = this.Stage3.listRequiredDocuments;
            this.Stage3Milestones = this.AllList.filter(
              (item) =>
                item.listType.toLocaleLowerCase() === "step" &&
                item.listLevel === 2 &&
                item.listParentID === this.Stage3.listID
            );

            for (let i = 0; i < this.Stage3Milestones.length; i++) {
              if (i == 0) {
                this.Stage3Milestone1 = this.Stage3Milestones[i];
                this.Stage3Milestone1Departments = this.Stage3Milestone1.listDepartments;
                this.Stage3Mile1ApprovalTeam = this.Stage3Milestone1.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage3Mile1Documents = this.Stage3Milestone1.listRequiredDocuments;
              }
              if (i == 1) {
                this.Stage3Milestone2 = this.Stage3Milestones[i];
                this.Stage3Milestone2Departments = this.Stage3Milestone2.listDepartments;
                this.Stage3Mile2ApprovalTeam = this.Stage3Milestone2.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage3Mile2Documents = this.Stage3Milestone2.listRequiredDocuments;
              }
              if (i == 2) {
                this.Stage3Milestone3 = this.Stage3Milestones[i];
                this.Stage3Milestone3Departments = this.Stage3Milestone3.listDepartments;
                this.Stage3Mile3ApprovalTeam = this.Stage3Milestone3.listTeam.filter(
                  (item) =>
                    item.type.toLocaleLowerCase() === "approver" ||
                    item.type.toLocaleLowerCase() === "notification recipient"
                );
                this.Stage3Mile3Documents = this.Stage3Milestone3.listRequiredDocuments;
              }
            }
          }
        }
        if (All != "All") {
          this.index = 0;
          this.milestoneindex = 0;
          this.stageindex = 0;
          this.stage1mileindex = 0;
          this.stage2mileindex = 0;
          this.stage3mileindex = 0; this.stage1index = 0; this.stage2index = 0; this.stage3index = 0;
          let TransactionID = 0;
          if (sessionStorage.getItem("TransactionID") != undefined) {
            if (sessionStorage.getItem("TransactionID") != "")
              TransactionID = Number(sessionStorage.getItem("TransactionID"));
          }
          if (sessionStorage.getItem("DisplayType") != undefined) {
            if (sessionStorage.getItem("DisplayType") != "") {
              if (sessionStorage.getItem("DisplayType").toLowerCase() == "phase")
                this.index = 0;
              else if (sessionStorage.getItem("DisplayType").toLowerCase() == "step") {
                this.index = 1;
                if (this.Milestone1.listID == TransactionID) this.milestoneindex = 0;
                else if (this.Milestone2.listID == TransactionID) this.milestoneindex = 1;
                else if (this.Milestone3.listID == TransactionID) this.milestoneindex = 2;
                else if (this.Milestone4.listID == TransactionID) this.milestoneindex = 3;
                else if (this.Milestone5.listID == TransactionID) this.milestoneindex = 4;
              }
              else if (sessionStorage.getItem("DisplayType").toLowerCase() == "stage") {
                if (this.Milestones.length > 0)
                  this.index = 2;
                else
                  this.index = 1;
                if (this.Stage1.listID == TransactionID) {
                  this.stageindex = 0;
                  this.stage1index = 0;
                  if (this.Stage1Milestones.length > 0) {
                    if (this.Stage1Milestone1.listID == TransactionID) { this.stage1index = 1; this.stage1mileindex = 0; }
                    else if (this.Stage1Milestone2.listID == TransactionID) { this.stage1index = 1; this.stage1mileindex = 1; }
                    else if (this.Stage1Milestone3.listID == TransactionID) { this.stage1index = 1; this.stage1mileindex = 2; }
                  }
                }
                else if (this.Stage2.listID == TransactionID) {
                  this.stageindex = 1;
                  this.stage2index = 0;
                  if (this.Stage2Milestones.length > 0) {
                    if (this.Stage2Milestone1.listID == TransactionID) { this.stage2index = 1; this.stage2mileindex = 0; }
                    else if (this.Stage2Milestone2.listID == TransactionID) { this.stage2index = 1; this.stage2mileindex = 1; }
                    else if (this.Stage2Milestone3.listID == TransactionID) { this.stage2index = 1; this.stage2mileindex = 2; }
                  }
                }
                else if (this.Stage3.listID == TransactionID) {
                  this.stageindex = 2;
                  this.stage3index = 0;
                  if (this.Stage3Milestones.length > 0) {
                    if (this.Stage3Milestone1.listID == TransactionID) { this.stage3index = 1; this.stage3mileindex = 0; }
                    else if (this.Stage3Milestone2.listID == TransactionID) { this.stage3index = 1; this.stage3mileindex = 1; }
                    else if (this.Stage3Milestone3.listID == TransactionID) { this.stage3index = 1; this.stage3mileindex = 2; }
                  }
                }
              }
            }
          }
        }

        if (this.CurrentPhase.phaseName.toUpperCase() == "P2")
          this.GetPhaseConditionalDetails();
        this.showLoading = false;
        console.log(this.CurrentPhase);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  function(e) { this.index = e.index; }
  function1(e) { this.milestoneindex = e.index; }
  function2(e) { this.stageindex = e.index; }
  function3(e) { this.stage1mileindex = e.index; }
  function4(e) { this.stage2mileindex = e.index; }
  function5(e) { this.stage3mileindex = e.index; }
  function6(e) { this.stage1index = e.index; }
  function7(e) { this.stage2index = e.index; }
  function8(e) { this.stage3index = e.index; }

  Documents(Type, ParentID, TransactionID, l, ln, DID, DName, Add, View, Delete) {
    this.showLoading = true;

    //this is for viewing documents
    this._facadeService.getDocuments(Type, TransactionID, this.userintId).subscribe(
      (data) => {
        this.ViewAddedDocuments = data.returnObject;
        this.ViewAddedDocuments.forEach((value, key) => {
          if (value.addedDate !== null && value.addedDate !== undefined)
            value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
        });
        console.log(this.ViewAddedDocuments);
      },
      (error) => {
        console.log(error);
      }
    );

    this.documentsdisplay = true;
    this.Level = l;
    this.LevelName = ln;
    this.popuptype = Type;
    this.popupTransactionID = TransactionID;
    this.popupParentID = ParentID;
    this.Dept = "";
    if (DID == 0) { this.popDID = this.UserDepartmentID; this.Dept = this.UserDepartment; }
    else { this.popDID = DID; this.Dept = DName; }
    this.adddocumentsdisplay = Add;
    this.viewdocumentsdisplay = View;
    this.deletedocument = !Delete;
    this.DocsError = "";
    if (!View && Add)
      this.DocsError =
        l +
        " - " +
        ln +
        " is not yet ready to start or you don't have permission.";
    if (this.Product.productStatus.toLocaleLowerCase() === "hold") {
      this.DocsError = "Product is on hold.";
      this.adddocumentsdisplay = true;
    }
    if (this.fileInput != undefined || this.fileInput != null) {
      if (this.fileInput.files.length > 0) {
        this.fileInput.clear();
      }
    }
    var dintid: number = 0;
    if (DID != "0") {
      for (let i = 0; i < this.departmentsData.length; i++) {
        var test: string;
        test = this.departmentsData[i].departmentGuid;
        if (test.toUpperCase() == DID.toUpperCase())
          dintid = this.departmentsData[i].departmentId;
      }
    }
    if (this.Product.productOwnerID.toUpperCase() == this.userId.toUpperCase() || this.CurrentPhase.phaseOwnerID.toUpperCase() == this.userId.toUpperCase())
      dintid = 0;
    //this is for getting dms documents
    this._facadeService.getDOCDocuments(dintid).subscribe(
      (data) => {
        this.DocumentData = data.returnObject;
        if (this.DocumentData != undefined && this.DocumentData != null) {
          if (this.DocumentData.length > 0) {
            this.DocumentData.forEach((element) => {
              element.title = element.code + " : " + element.title;
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.components["_results"].forEach((ds) => {
      ds.value = null;
      ds.updateLabel();
    });
    this.selectedDoc = [];
    this.showLoading = false;
  }
  onRadioChange(event) {
    this.showLoading = true;
    this.valRadio = event;
    this.DocsError = "";
    if (event == "system") {
      this.docdms = false;
      this.docsystem = true;
    } else {
      this.docdms = true;
      this.docsystem = false;
    }
    this.showLoading = false;
  }
  AddDocs() {
    this.showLoading = true;
    let ok = true;
    let ok1 = true;
    let docFields1: string[] = [];
    if (!this.docsystem) {
      if (this.selectedDoc.length <= 0) ok = false;
      else {
        if (this.ViewAddedDocuments != undefined) {
          if (this.ViewAddedDocuments.length > 0) {
            this.ViewAddedDocuments.forEach((element) => {
              if (ok1) {
                this.selectedDoc.forEach((element1) => {
                  if (ok1) {
                    if (
                      element.fileGuid.toLowerCase() ==
                      element1.fileGuid.toLowerCase()
                    ) {
                      this.DocsError = "Selected file already added.";
                      ok1 = false;
                    }
                  }
                });
              }
            });
          }
        }
        if (ok1) {
          this.selectedDoc.forEach((element) => {
            docFields1.push(element.fileGuid);
          });
        }
      }
    } else {
      if (this.fileInput.files.length <= 0) ok = false;
    }
    if (ok) {
      if (ok1) {
        this.DocsError = "";
        if (this.popuptype == undefined || this.popuptype == "") {
          this.DocsError = "Error occured, please referesh the page.";
          this.showLoading = false;
        }
        if (
          this.popupTransactionID == undefined ||
          this.popupTransactionID == 0
        ) {
          this.DocsError = "Error occured, please referesh the page.";
          this.showLoading = false;
        }
        if (this.popupParentID == undefined || this.popupParentID == 0) {
          this.DocsError = "Error occured, please referesh the page.";
          this.showLoading = false;
        }

        if (
          this.popupTransactionID != undefined &&
          this.popupTransactionID != 0 &&
          this.popupParentID != undefined &&
          this.popupParentID != 0 &&
          this.DocsError == ""
        ) {
          const formData = new FormData();
          formData.append("type", this.popuptype);
          formData.append("fromDOC", this.docsystem ? "N" : "Y");
          formData.append("applicationID", this.applicationID);
          formData.append("transactionID", this.popupTransactionID.toString());
          formData.append("isRestricted", "false");
          formData.append(
            "documentTitle",
            this.Product.productName + "-" + this.Product.mfgPart + "-" + this.CurrentPhase.phaseName + "-" + this.LevelName
          );
          formData.append("departmentID", this.popDID);
          formData.append("phaseID", this.popupParentID.toString());
          formData.append("listID", this.popupParentID.toString());
          formData.append("addedBy", this.userId);
          formData.append("addedDate", new Date().toUTCString());
          formData.append("addedByName", this.Product.productGuid);
          if (this.fileInput != undefined) {
            if (this.fileInput.files.length > 0) {
              this.fileInput.files.forEach((file) => {
                formData.append("files", file);
              });
            } else formData.append("files", JSON.stringify([]));
          } else formData.append("files", JSON.stringify([]));
          docFields1.forEach((file) => {
            formData.append("dOCFiles", file);
          });
          console.log(formData);
          this._facadeService.addDocuments(formData)
            .subscribe(
              (data) => {
                console.log(data);
                this.apiResponse = data;
                if (this.apiResponse.returnCode == 0) {
                  this.phases(this.PhaseID, this.ImageName, "All");
                  this.documentsdisplay = true;
                  this.deletedocument = true;
                  this.viewdocumentsdisplay = true;
                  if (this.fileInput != undefined || this.fileInput != null) {
                    if (this.fileInput.files.length > 0) {
                      this.fileInput.clear();
                    }
                  }
                  this.components["_results"].forEach((ds) => {
                    ds.value = null;
                    ds.updateLabel();
                  });
                  this.selectedDoc = [];
                  this._facadeService
                    .getDocuments(this.popuptype, this.popupTransactionID, this.userintId)
                    .subscribe(
                      (data) => {
                        this.ViewAddedDocuments = data.returnObject;
                        this.ViewAddedDocuments.forEach((value, key) => {
                          if (value.addedDate !== null && value.addedDate !== undefined)
                            value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
                        });
                        console.log(this.ViewAddedDocuments);
                        this.showLoading = false;
                        // this.ErrorDisplay = true;
                        // this.Error = "Documents uploaded successfully";
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                } else {
                  this.showLoading = false;
                  this.DocsError = this.apiResponse.returnMessage;
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        this.showLoading = false;
      }
    } else {
      this.showLoading = false;
      this.DocsError = "Please select atleast one file.";
    }
  }
  removedocument(By, ID) {
    // this.showLoading = true;
    this.DocsError = "";
    if (this.userId.toUpperCase() == By.toUpperCase()) {
      this.RemoveID = ID;
      this.confirmation.confirm({
        key: "confirm-document",
        message: "Are you sure you want to delete a document?",
        accept: () => {
          this.confirmDeleteDocument = false;
          this._removedoc();
        },
      });
      this.confirmDeleteDocument = false;
    } else {
      this.DocsError = "You can't delete other user document.";
      this.showLoading = false;
    }
  }
  private _removedoc() {
    this.showLoading = true;
    this.DeleteModel = {
      type: this.popuptype,
      transactionID: this.RemoveID,
      actionBy: this.userId,
      actionDate: new Date(),
      actionByName: "",
    };
    this._facadeService.deleteDocument(this.DeleteModel).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.phases(this.PhaseID, this.ImageName, "All");
          this.documentsdisplay = true;
          this.viewdocumentsdisplay = true;
          this._facadeService
            .getDocuments(this.popuptype, this.popupTransactionID, this.userintId)
            .subscribe(
              (data) => {
                this.ViewAddedDocuments = data.returnObject;
                this.ViewAddedDocuments.forEach((value, key) => {
                  if (value.addedDate !== null && value.addedDate !== undefined)
                    value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
                });
                console.log(this.ViewAddedDocuments);
                this.showLoading = false;
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.showLoading = false;
          this.DocsError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  Notes(Type, ParentID, TransactionID, l, ln, Add, View, Delete) {
    this.showLoading = true;
    this._facadeService.getNotes(Type, TransactionID).subscribe(
      (data) => {
        this.ViewAddedNotes = data.returnObject;
        this.ViewAddedNotes.forEach((value, key) => {
          if (value.addedDate !== null && value.addedDate !== undefined)
            value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
        });
        console.log(this.ViewAddedNotes);
      },
      (error) => {
        console.log(error);
      }
    );
    this.NoteError = "";
    this.popupnote = "";
    this.notesdisplay = true;
    this.Level = l;
    this.LevelName = ln;
    this.popuptype = Type;
    this.popupTransactionID = TransactionID;
    this.popupParentID = ParentID;
    this.addnotesdisplay = Add;
    this.ViewAddnotesdisplay = !Add;
    this.viewnotesdisplay = View;
    this.deletenote = !Delete;
    if (!View && Add)
      this.NoteError =
        l +
        " - " +
        ln +
        " is not yet ready to start or you don't have permission.";
    if (this.Product.productStatus.toLocaleLowerCase() === "hold") {
      this.NoteError = "Product is on hold.";
      this.ViewAddnotesdisplay = false;
    }
    this.showLoading = false;
  }

  tablenotes(rowdata) {
    this.addnotesdisplay = false;
    this.ViewAddnotesdisplay = true;
    this.popupnote = rowdata;
  }

  AddNote() {
    this.showLoading = true;
    this.NoteError = "";
    if (this.popupnote == undefined || this.popupnote == "") {
      this.NoteError = "Please enter Note.";
      this.showLoading = false;
    }
    if (this.popuptype == undefined || this.popuptype == "") {
      this.NoteError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
    if (this.popupTransactionID == undefined || this.popupTransactionID == 0) {
      this.NoteError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
    if (this.popupParentID == undefined || this.popupParentID == 0) {
      this.NoteError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }

    if (
      this.popupnote != undefined &&
      this.popupnote != "" &&
      this.popuptype != undefined &&
      this.popuptype != "" &&
      this.popupTransactionID != undefined &&
      this.popupTransactionID != 0 &&
      this.popupParentID != undefined &&
      this.popupParentID != 0 &&
      this.NoteError == ""
    ) {
      this.AddNotesModel = {
        type: this.popuptype,
        transactionID: this.popupTransactionID,
        phaseID: this.popupParentID,
        listID: this.popupParentID,
        note: this.popupnote,
        addedBy: this.userId,
        addedDate: new Date(),
        addedByName: "",
      };
      this._facadeService.addNotes(this.AddNotesModel).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.phases(this.PhaseID, this.ImageName, "All");
            this.viewnotesdisplay = true;
            this.deletenote = true;
            this.notesdisplay = true;
            this.popupnote = "";
            this._facadeService
              .getNotes(this.popuptype, this.popupTransactionID)
              .subscribe(
                (data) => {
                  this.ViewAddedNotes = data.returnObject;
                  this.ViewAddedNotes.forEach((value, key) => {
                    if (value.addedDate !== null && value.addedDate !== undefined)
                      value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
                  });
                  console.log(this.ViewAddedNotes);
                  this.showLoading = false;
                },
                (error) => {
                  console.log(error);
                }
              );
          } else {
            this.showLoading = false;
            this.NoteError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  removenote(By, ID) {
    // this.showLoading = true;
    this.NoteError = "";
    if (this.userId.toUpperCase() == By.toUpperCase()) {
      this.RemoveID = ID;
      this.confirmation.confirm({
        key: "confirm-note",
        message: "Are you sure you want to delete the note?",
        accept: () => {
          this.confirmDeleteNote = false;
          this._removenote();
        },
      });
      this.confirmDeleteNote = false;
    } else {
      this.NoteError = "You can't delete other user note.";
      this.showLoading = false;
    }
  }
  private _removenote() {
    this.showLoading = true;
    this.DeleteModel = {
      type: this.popuptype,
      transactionID: this.RemoveID,
      actionBy: this.userId,
      actionDate: new Date(),
      actionByName: "",
    };
    this._facadeService.deleteNote(this.DeleteModel).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.phases(this.PhaseID, this.ImageName, "All");
          this.notesdisplay = true;
          this.viewnotesdisplay = true;
          this._facadeService
            .getNotes(this.popuptype, this.popupTransactionID)
            .subscribe(
              (data) => {
                this.ViewAddedNotes = data.returnObject;
                this.ViewAddedNotes.forEach((value, key) => {
                  if (value.addedDate !== null && value.addedDate !== undefined)
                    value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
                });
                console.log(this.ViewAddedNotes);
                this.showLoading = false;
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.showLoading = false;
          this.NoteError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  NotesClear() {
    this.NoteError = "";
    this.popupnote = "";
  }
  Validate(Type, ID) {
    this.ViewIssues = false;
    this.ViewTasks = false;
    this._facadeService.GetIssues(this.ProductID, this.PhaseID).subscribe(
      (data) => {
        this.Issues = data.returnObject;
        if (this.Issues != undefined && this.Issues != null) {
          if (this.Issues.length > 0) {
            this.Issues.forEach((value, key) => {
              if (value.issueDate !== null && value.issueDate !== undefined)
                value.issueDate = new Date(new Date(value.issueDate).toDateString() + " " + new Date(value.issueDate).toTimeString() + " UTC");
              if (value.dueDate !== null && value.dueDate !== undefined)
                value.dueDate = new Date(new Date(value.dueDate).toDateString() + " " + new Date(value.dueDate).toTimeString() + " UTC");
            });
            this.ViewIssues = true;
          }
        }
        this._facadeService.GetTasks(this.Product.productGuid, this.PhaseGUID).subscribe(
          (data) => {
            this.Tasks = data.returnObject;
            if (this.Tasks != null || this.Tasks != undefined) {
              if (this.Tasks.length > 0) {
                this.Tasks.forEach((value, key) => {
                  if (value.StartDate !== null && value.StartDate !== undefined)
                    value.StartDate = new Date(new Date(value.StartDate).toDateString() + " " + new Date(value.StartDate).toTimeString() + " UTC");
                  if (value.DueDate !== null && value.DueDate !== undefined)
                    value.DueDate = new Date(new Date(value.DueDate).toDateString() + " " + new Date(value.DueDate).toTimeString() + " UTC");
                });
                this.ViewTasks = true;
              }
            }
            if (this.ViewIssues || this.ViewTasks) {
              this.issuelink = true;
              this.ErrorDisplay = true;
              if (this.ViewIssues && this.ViewTasks)
                this.Error = "Some issues and milestones are not completed or closed. Please complete those issues and milestones before sending for approval.";
              else if (this.ViewIssues)
                this.Error = "Some issues are not resolved. Please resolve those issues before sending for approval.";
              else if (this.ViewTasks)
                this.Error = "Some milestones are not completed. Please complete those milestones before sending for approval.";
              this.showLoading = false;
            }
            else {
              this._send(Type, ID);
            }
          });
      });
  }
  SendApproval(Type, ID) {
    if (!this.IsEvalutionExistInProcess && !this.IsConditionalExistInProcess && !this.IsEvalutionExistNew && !this.IsConditionalExistNew) {
      this.showLoading = true;
      this.issuelink = false;
      this.isuus_tasks = false;
      this.Issues = null;
      this.Tasks = null;
      this._send(Type, ID);
    }
    else {
      this.ErrorDisplay = true;
      this.Error = "Please complete Conditional Release / Evaluation Sample Release";
    }
  }

  private _send(Type, ID) {
    var approvalModel = {
      type: Type,
      iD: ID,
      desicion: "",
      comments: "",
      actionBy: this.userId,
      actionByName: "",
      actionDate: new Date(),
    };
    this._facadeService.SendApproval(approvalModel).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.showLoading = false;
          // this.router.navigate(["/myproducts"]);
          this.router.navigate(['/myproducts', { index: 1 }]);
        }
        else if (this.apiResponse.returnCode == 1000) {
          this._facadeService.GetIssues(this.ProductID, this.PhaseID).subscribe(
            (data) => {
              this.Issues = data.returnObject;
              if (this.Issues != undefined && this.Issues != null) {
                if (this.Issues.length > 0) {
                  this.Issues.forEach((value, key) => {
                    if (value.issueDate !== null && value.issueDate !== undefined)
                      value.issueDate = new Date(new Date(value.issueDate).toDateString() + " " + new Date(value.issueDate).toTimeString() + " UTC");
                    if (value.dueDate !== null && value.dueDate !== undefined)
                      value.dueDate = new Date(new Date(value.dueDate).toDateString() + " " + new Date(value.dueDate).toTimeString() + " UTC");
                  });
                  this.ViewIssues = true;
                }
              }
            });
          this.issuelink = true;
          this.ErrorDisplay = true;
          this.Error = this.apiResponse.returnMessage;
          this.showLoading = false;
        }
        else if (this.apiResponse.returnCode == 2000) {

          this._facadeService.GetTasks(this.Product.productGuid, this.PhaseGUID).subscribe(
            (data) => {
              this.Tasks = data.returnObject;
              if (this.Tasks != null || this.Tasks != undefined) {
                if (this.Tasks.length > 0) {
                  this.Tasks.forEach((value, key) => {
                    if (value.StartDate !== null && value.StartDate !== undefined)
                      value.StartDate = new Date(new Date(value.StartDate).toDateString() + " " + new Date(value.StartDate).toTimeString() + " UTC");
                    if (value.DueDate !== null && value.DueDate !== undefined)
                      value.DueDate = new Date(new Date(value.DueDate).toDateString() + " " + new Date(value.DueDate).toTimeString() + " UTC");
                  });
                  this.ViewTasks = true;
                }
              }
            });
          this.issuelink = true;
          this.ErrorDisplay = true;
          this.Error = this.apiResponse.returnMessage;
          this.showLoading = false;
        }
        else {
          this.showLoading = false;
          this.ErrorDisplay = true;
          this.Error = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ECCNReminder() {
    this.showLoading = true;
    this._facadeService.ECCNReminder(this.Product.productID).subscribe(
      (data) => {
        this.apiResponse = data;
        this.ErrorDisplay = true;
        if (this.apiResponse.returnCode == 0)
          this.Error = "Sent successfully";
        else
          this.Error = this.apiResponse.returnMessage;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  Reminder(Type, ID) {
    this.showLoading = true;
    var approvalModel = {
      type: Type,
      iD: ID,
      desicion: "",
      comments: "",
      actionBy: this.userId,
      actionByName: "",
      actionDate: new Date(),
    };
    this._facadeService.Reminder(approvalModel).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.showLoading = false;
          this.ErrorDisplay = true;
          // this.Error = "Reminder sent successfully";
          // this.router.navigate(["/myproducts"]);
          this.router.navigate(['/myproducts', { index: 1 }]);
        } else {
          this.showLoading = false;
          this.ErrorDisplay = true;
          this.Error = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  Clarify(Type, ID, l, ln) {
    this.showLoading = true;
    this.addclar1 = false;
    this.viewclarifydisplay = true;
    this.Level = l;
    this.LevelName = ln;
    this._facadeService.ClarificationDetailsbyID(ID, Type).subscribe(
      (data) => {
        this.ViewClarify = data.returnObject;
        this.ViewClarify.forEach((value, key) => {
          if (value.askedDate !== null && value.askedDate !== undefined)
            value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
          if (value.answerDate !== null && value.answerDate !== undefined)
            value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");

          value.active = false;
          if (this.Product.productOwnerID.toUpperCase() == this.userId.toUpperCase() || this.CurrentPhase.phaseOwnerID.toUpperCase() == this.userId.toUpperCase()
          )
            value.active = true;
          if (!value.active) {
            value.selectedTeam.forEach(e => {
              if (e.userID.toUpperCase() == this.userId.toUpperCase() && !value.active)
                value.active = true;
            });
          }
        });
        console.log(this.ViewClarify);
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  Response(ID, Q, A) {
    this.addclar1 = true;
    this.Question = Q;
    this.askedByName = A;
    this.CID = ID;
    if (this.Product.productOwnerID.toUpperCase() == this.userId.toUpperCase() || this.CurrentPhase.phaseOwnerID.toUpperCase() == this.userId.toUpperCase())
      this.QButton = true;
    else
      this.QButton = false;
    this.QuestionError = "";
    this.popupquestion = "";
  }
  ViewResponse(ID, Q, A, An) {
    this.addclar1 = true;
    this.Question = Q;
    this.askedByName = A;
    this.CID = ID;
    this.QuestionError = "";
    this.popupquestion = An;
    this.QButton = false;
  }
  ResponseCon(ID, Q, A) {
    this.addclar2 = true;
    this.Question = Q;
    this.askedByName = A;
    this.CID = ID;
    if (this.Product.productOwnerID.toUpperCase() == this.userId.toUpperCase() || this.CurrentPhase.phaseOwnerID.toUpperCase() == this.userId.toUpperCase())
      this.QButton = true;
    else
      this.QButton = false;
    this.QuestionError = "";
    this.popupquestion = "";
  }
  AddAnswer() {
    this.showLoading = true;
    this.QuestionError = "";
    if (this.popupquestion == undefined || this.popupquestion == "") {
      this.QuestionError = "Please enter Answer.";
      this.showLoading = false;
    }
    if (this.CID == undefined || this.CID == 0) {
      this.QuestionError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }

    if (
      this.popupquestion != undefined &&
      this.popupquestion != "" &&
      this.CID != undefined &&
      this.CID != 0 &&
      this.QuestionError == ""
    ) {
      this.ClarifyModel = {
        clarificationID: this.CID,
        answer: this.popupquestion,
        answerBy: this.userId,
        answerDate: new Date(),
        type: "",
        phaseID: 0,
        phase: "",
        question: "",
        askedBy: "",
        askedByName: "",
        askedDate: new Date(),
        answerByName: "",
        isAnswered: "",
      };
      this._facadeService.TeamAction(this.ClarifyModel).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.viewclarifydisplay = false;
            this.ConditionalReleaseDisplay = false;
            this.phases(this.PhaseID, this.ImageName, "All");
          } else {
            this.showLoading = false;
            this.QuestionError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  QuestionClear() {
    this.addclar1 = false;
    this.addclar2 = false;
    this.Question = "";
    this.QuestionError = "";
    this.popupquestion = "";
    this.CID = 0;
  }

  SendCancelApproval() {
    this.confirmation.confirm({
      key: "confirm-cancel",
      message: "Are you sure you want to discontinue the product?",
      accept: () => {
        this.confirmCancel = false;
        this.reasondisplay = true;
        this.Action = "Send for Discontinue Approval";
        this.popupreason = "";
        this.ReasonError = "";
      },
    });
    this.confirmCancel = false;
  }
  CancelWithdraw() {
    this.confirmation.confirm({
      key: "confirm-cancelwith",
      message: "Are you sure you want to withdraw the discontinuation of the product?",
      accept: () => {
        this.confirmCancelwith = false;
        this.reasondisplay = true;
        this.Action = "Withdraw Discontinuation";
        this.popupreason = "";
        this.ReasonError = "";
      },
    });
    this.confirmCancelwith = false;
  }

  CancelReminder(ProductID) {
    this.showLoading = true;
    var approvalModel = {
      type: "",
      iD: ProductID,
      desicion: "",
      comments: "",
      actionBy: this.userId,
      actionByName: "",
      actionDate: new Date(),
    };
    this._facadeService.CancelReminder(approvalModel).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.showLoading = false;
          this.router.navigate(['/myproducts', { index: 3 }]);
          // this.router.navigate(["/myproducts"]);
          // this.ErrorDisplay = true;
          // this.Error = "Reminder sent successfully";
        } else {
          this.showLoading = false;
          this.ErrorDisplay = true;
          this.Error = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ReasonClear() {
    this.ReasonError = "";
    this.popupreason = "";
    this.Action = "";
  }

  Hold() {
    // this.showLoading = true;
    this.confirmation.confirm({
      key: "confirm-hold",
      message: "Are you sure you want to hold the product?",
      accept: () => {
        this.confirmHold = false;
        this.reasondisplay = true;
        this.Action = "Hold";
        this.popupreason = "";
        this.ReasonError = "";
      },
    });
    this.confirmHold = false;
  }
  AddReason() {
    this.showLoading = true;
    this.ReasonError = "";
    if (this.popupreason == undefined || this.popupreason == "") {
      this.ReasonError = "Please enter Reason.";
      this.showLoading = false;
    }
    if (this.Action == undefined || this.Action == "") {
      this.ReasonError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }

    if (
      this.popupreason != undefined &&
      this.popupreason != "" &&
      this.Action != undefined &&
      this.Action != "" &&
      this.ReasonError == ""
    ) {

      var approvalModel = {
        type: "",
        iD: this.Product.productID,
        desicion: "",
        comments: this.popupreason,
        actionBy: this.userId,
        actionByName: "",
        actionDate: new Date(),
      };
      if (this.Action == "Hold") {
        this._facadeService.Hold(approvalModel).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.router.navigate(['/myproducts', { index: 4 }]);
              // this.router.navigate(["/myproducts"]);
            } else {
              this.showLoading = false;
              this.ErrorDisplay = true;
              this.Error = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      else if (this.Action == "Release") {
        this._facadeService.Release(approvalModel).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.router.navigate(["/myproducts"]);
            } else {
              this.showLoading = false;
              this.ErrorDisplay = true;
              this.Error = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      else if (this.Action == "Withdraw Discontinuation") {
        this._facadeService.CancelWithdraw(approvalModel).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.router.navigate(['/myproducts', { index: 3 }]);
              // this.router.navigate(["/myproducts"]);
            } else {
              this.showLoading = false;
              this.ErrorDisplay = true;
              this.Error = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      else if (this.Action == "Send for Discontinue Approval") {
        this._facadeService.SendCancelApproval(approvalModel).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.router.navigate(['/myproducts', { index: 3 }]);
              // this.router.navigate(["/myproducts"]);
            } else {
              this.showLoading = false;
              this.ErrorDisplay = true;
              this.Error = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
    else {
      this.ReasonError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
  }
  Release() {
    // this.showLoading = true;
    this.confirmation.confirm({
      key: "confirm-release",
      message: "Are you sure you want to release the product?",
      accept: () => {
        this.confirmRelease = false;
        this.reasondisplay = true;
        this.Action = "Release";
        this.popupreason = "";
        this.ReasonError = "";
        this.showLoading = false;

      },
    });
    this.confirmRelease = false;
  }
  DisplayCreateConditional(type) {
    this.showLoading = true;
    this.EvalConditionalType = type;
    this.deletedCOnditionalFiles = [];
    this.TimeLimitError = "";
    this.QuantityLimitErrorMessage = "";
    this.status = "New";
    this.IsManufacturingAndShipping = "No";
    this.conditionalFieldsDisabled = false;
    this.Justification = "";
    this.TimeLimit = "";
    this.QuantityLimit = "";
    this.conditionalId = 0;
    this.LIissuesData = [];
    this.Clarification1 = [];
    this.EvalConditionalType = type;
    this.conditionalDocs = [];
    if (type == 1)
      this.EvalCondiDisplayName = "Conditional Release";
    else
      this.EvalCondiDisplayName = "Evaluation Sample Release";

    if (this.IsConditionalExist && type == 1)
      this.GetConditionalReleaseDetails(type);
    if (this.IsEvalutionExist && type == 2)
      this.GetConditionalReleaseDetails(type);
    this.ConditionalReleaseDisplay = true;
    this.showLoading = false;
  }


  CreateConditionalRelease(type, source, Update) {
    const formData = new FormData();
    if (this.fileInput != undefined) {
      if (this.fileInput.files.length > 0) {
        this.fileInput.files.forEach((file) => {
          formData.append("files", file);
        });
      } else formData.append("files", JSON.stringify([]));
    } else formData.append("files", JSON.stringify([]));
    if (this.deletedCOnditionalFiles != null && this.deletedCOnditionalFiles.length > 0) {
      formData.append("DeletedSupportingDocs", this.deletedCOnditionalFiles);
    }
    formData.append("applicationID", this.applicationID);
    formData.append(
      "documentTitle",
      this.Product.productName + "-" + this.Product.mfgPart + "-" + this.CurrentPhase.phaseName
    );
    formData.append("departmentID", this.UserDepartmentID);
    formData.append("addedBy", this.userId);
    formData.append("addedDate", new Date().toUTCString());
    formData.append("addedByName", this.Product.productGuid);
    formData.append("IsManufacturingAndShipping", this.IsManufacturingAndShipping == "Yes" ? "true" : "false");
    formData.append("Justification", this.Justification);
    formData.append("ProductId", this.ProductID.toString());
    formData.append("QuantityLimit", this.QuantityLimit);
    formData.append("TimeLimit", new Date(this.TimeLimit).toUTCString());
    formData.append("Source", source);
    formData.append("Type", type);
    if (Update)
      formData.append("Id", this.conditionalId);
    var manager = sessionStorage.getItem('ManagerGuid') == null || sessionStorage.getItem('ManagerGuid') == undefined ? "" : sessionStorage.getItem('ManagerGuid');
    if (manager == null || manager == undefined || manager.toLowerCase() == 'null')
      manager = "";

    formData.append("ManagerGuid", manager);
    this.showLoading = true;

    this.ConditionalError = "";
    if (Update) {
      this._facadeService.UpdateEvalutionConditionalRelease(formData)
        .subscribe(
          (data) => {
            console.log(data);
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.ConditionalReleaseDisplay = false;
              if (this.fileInput != undefined || this.fileInput != null) {
                if (this.fileInput.files.length > 0) {
                  this.fileInput.clear();
                }
              }
              this.phases(this.PhaseID, this.ImageName, "All");
            } else {
              this.showLoading = false;
              this.ConditionalError = this.apiResponse.returnObject;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    else {
      this._facadeService.CreateConditionalRelease(formData)
        .subscribe(
          (data) => {
            console.log(data);
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.ConditionalReleaseDisplay = false;
              if (this.fileInput != undefined || this.fileInput != null) {
                if (this.fileInput.files.length > 0) {
                  this.fileInput.clear();
                }
              }
              this.phases(this.PhaseID, this.ImageName, "All");
            } else {
              this.showLoading = false;
              this.ConditionalError = this.apiResponse.returnObject;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  SaveConditionalRelease(source) {
    this.TimeLimitError = "";
    this.QuantityLimitErrorMessage = "";
    if (this.QuantityLimit == null || this.QuantityLimit == "" || this.QuantityLimit == undefined) {
      this.QuantityLimitErrorMessage = "Please enter Quantity Limit";
    }
    else if (this.TimeLimit == null || this.TimeLimit == "" || this.TimeLimit == undefined) {
      this.TimeLimitError = "Plese select Time Limit";
    }
    else {
      if (this.conditionalId > 0) {
        this.CreateConditionalRelease(this.EvalConditionalType, source, true);
      }
      else {
        this.CreateConditionalRelease(this.EvalConditionalType, source, false);
      }
    }
  }

  PrevCR(type) {
    this.showLoading = true;
    this.PrevDisplay = true;
    this.prevcrtype = type;
    if (type == 1)
      this.PrevCRName = "Previous Conditional Release";
    else
      this.PrevCRName = "Previous Evaluation Sample Release";
    this._facadeService.GetPreviousCR_Eval(this.ProductID, type, this.userintId).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          this.prevCR = data.returnObject;
          if (this.prevCR != null && this.prevCR != undefined) {
            this.prevCR.forEach((value, key) => {
              if (value.timeLimit !== null && value.timeLimit !== undefined)
                value.timeLimit = new Date(new Date(value.timeLimit).toDateString() + " " + new Date(value.timeLimit).toTimeString() + " UTC");
            });
          }
          this.showLoading = false;
        }
        else
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  GetConditionalReleaseDetails(type) {
    this.deletedCOnditionalFiles = [];
    this.showLoading = true;
    this.TimeLimitError = "";
    this.QuantityLimitErrorMessage = "";
    this.status = "New";
    this.IsManufacturingAndShipping = "No";
    this.conditionalFieldsDisabled = false;
    this.Justification = "";
    this.TimeLimit = "";
    this.QuantityLimit = "";
    this.conditionalId = 0;
    this.LIissuesData = [];
    this.Clarification1 = [];
    this.EvalConditionalType = type;
    this.conditionalDocs = [];
    this.ConditionalReleaseDisplay = true;
    if (this.fileInput != undefined || this.fileInput != null) {
      if (this.fileInput.files.length > 0) {
        this.fileInput.clear();
      }
    }
    if (type == 1)
      this.EvalCondiDisplayName = "Conditional Release";
    else
      this.EvalCondiDisplayName = "Evaluation Sample Release";
    this._facadeService.GetConditionalReleaseDetails(this.ProductID, type, this.userintId).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          this.showLoading = false;
          if (data.returnObject != null) {
            this.status = data.returnObject.statusName;
            this.IsManufacturingAndShipping = data.returnObject.isManufacturingAndShipping == true ? "Yes" : "No";
            this.Justification = data.returnObject.justification == "null" ? "" : data.returnObject.justification;
            this.TimeLimit = new Date(new Date(data.returnObject.timeLimit).toDateString() + " " + new Date(data.returnObject.timeLimit).toTimeString() + " UTC");
            this.QuantityLimit = data.returnObject.quantityLimit;
            this.conditionalId = data.returnObject.id;
            this.conditionalDocs = data.returnObject.viewDocuments;
            if (this.conditionalDocs == null || this.conditionalDocs == undefined)
              this.conditionalDocs = [];
            this.GetConditionalApprovalsHistory(data.returnObject.id);
            this.getClarificationDetails(data.returnObject.id);
            if (this.status != "New" || (this.userId.toUpperCase() != this.Product.productOwnerID.toUpperCase()
              && this.userId.toUpperCase() != this.CurrentPhase.phaseOwnerID.toUpperCase())) {
              this.conditionalFieldsDisabled = true;
            }
            if (
              this.Product.productStatus.toLocaleLowerCase() === "hold" ||
              this.Product.productStatus.toLocaleLowerCase() === "waiting for discontinuation" ||
              this.Product.productStatus.toLocaleLowerCase() === "seek clarification for discontinuation" ||
              this.Product.productStatus.toLocaleLowerCase() === "discontinued"
            ) {
              this.conditionalFieldsDisabled = true;
            }
          }
        }
        else
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  GetConditionalApprovalsHistory(conditionalId) {
    this.showLoading = true;
    this.LIissuesData = [];
    this._facadeService.GetConditionalApprovalsHistory(conditionalId).subscribe(
      (data) => {
        this.showLoading = false;
        this.LIissuesData = data.returnObject;
        if (this.LIissuesData != null && this.LIissuesData.length > 0) {
          this.LIissuesData.forEach((value, key) => {
            if (value.approvedDate !== null && value.approvedDate !== undefined)
              value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
          });
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getClarificationDetails(conditionalId) {
    this._facadeService.GetConditionalClarifications(conditionalId).subscribe(
      (data) => {
        this.Clarification1 = data.returnObject;
        this.Clarification1.forEach((value, key) => {
          if (value.askedDate !== null && value.askedDate !== undefined)
            value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
          if (value.answerDate !== null && value.answerDate !== undefined)
            value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");

          value.active = false;
          if (this.Product.productOwnerID.toUpperCase() == this.userId.toUpperCase() || this.CurrentPhase.phaseOwnerID.toUpperCase() == this.userId.toUpperCase())
            value.active = true;

        });
        this.showLoading = false;
      });
  }

  GetPhaseConditionalDetails() {
    this._facadeService.GetPhaseConditionalDetails(this.ProductID).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          var phaseConditionalDetailsAvailble = data.returnObject;
          this.IsConditionalExist = phaseConditionalDetailsAvailble.isConditionalExist;
          this.IsEvalutionExist = phaseConditionalDetailsAvailble.isEvalutionExist;
          this.IsConditionalExistInProcess = phaseConditionalDetailsAvailble.isConditionalExistInProcess;
          this.IsEvalutionExistInProcess = phaseConditionalDetailsAvailble.isEvalutionExistInProcess;
          this.IsConditionalExistNew = phaseConditionalDetailsAvailble.isConditionalExistNew;
          this.IsEvalutionExistNew = phaseConditionalDetailsAvailble.isEvalutionExistNew;
          if (this.IsConditionalExist && (this.IsConditionalExistInProcess || this.IsConditionalExistNew))
            this.CR = " - Conditional Release";
          if (this.IsEvalutionExist && (this.IsEvalutionExistInProcess || this.IsEvalutionExistNew))
            this.CR = " - Evaluation Sample Release";
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  DeleteConditionalRelease() {
    this.showLoading = true;
    this.ConditionalError = "";
    this.ConditionalDeleteDocument = false;
    this._facadeService.DeleteConditionalRelease(this.conditionalId).subscribe(
      (data) => {
        this.showLoading = false;
        if (data.returnCode == 0) {
          this.ConditionalReleaseDisplay = false;
          if (this.fileInput != undefined || this.fileInput != null) {
            if (this.fileInput.files.length > 0) {
              this.fileInput.clear();
            }
          }
          this.phases(this.PhaseID, this.ImageName, "All");
        }
        else {
          this.ConditionalError = this.apiResponse.returnMessage;
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  removeCondDoc(fileGuid) {
    this.coonditionalFIleGuid = fileGuid;
    this.ConditionalconfirmDeleteDocument = true;
  }
  ViewComment(coment) {
    this.CommentsDisplay = true;
    if (coment != null && coment != "" && coment != undefined) {
      this.CommentsShow = coment;
    }
    else {
      this.CommentsShow = "No Comment to show.";
    }
  }
  removedConditionaldocument() {
    this.ConditionalconfirmDeleteDocument = false;
    this.deletedCOnditionalFiles.push(this.coonditionalFIleGuid);
    var docs = [];
    this.conditionalDocs.forEach(element => {
      if (this.coonditionalFIleGuid != element.fileGuid) {
        docs.push(element);
      }
    });
    if (docs != null && docs.length > 0) {
      this.conditionalDocs = docs;
    }
    else {
      this.conditionalDocs = [];
    }
  }

  DMSToken1() {
    if (this.Product.eccnDocID != null && this.Product.eccnDocID != undefined) {
      if (this.Product.eccnDocID > 0) {
        this.showLoading = true;
        this._facadeService.CreateSecurityToken().subscribe(
          (data) => {
            if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined) {
              var access_token = data.returnObject;
              var applicationId = "";
              var applications = JSON.parse(sessionStorage.getItem('applications'));
              applications.forEach((value, key) => {
                if (value.projectIdentifier == "DMSUI")
                  applicationId = value.applicationId;
              });
              var userGuid = sessionStorage.getItem('userGuid');
              var IsDCC = sessionStorage.getItem('IsDCC') != '' && sessionStorage.getItem('IsDCC') != null && sessionStorage.getItem('IsDCC') != undefined ? sessionStorage.getItem("IsDCC").toLowerCase() == "true" ? true : false : false;
              var clientId = sessionStorage.getItem('clientId') != '' && sessionStorage.getItem('clientId') != null && sessionStorage.getItem('clientId') != undefined ? parseInt(sessionStorage.getItem('clientId')) : 0;
              window.open(this.baseUrl["API_ENDPOINTS"]["DMSUI"] + "entrypoint?userguid=" + userGuid + "&docId=" + this.Product.eccnDocID + "&userId=" + this.userintId + '&destination=docdetails'
                + '&access_token=' + access_token + '&IsDCC=' + IsDCC + '&bt=' + sessionStorage.getItem('bt') + "&appliId=" + applicationId + "&clientId=" + clientId);
            }
            else
              location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }

  DMSToken(rowData) {
    this.showLoading = true;
    if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
      this.showLoading = true;
      var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
      this.Download(rowData.fileName, newname, rowData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadDMSFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }
  PMToken() {
    this.showLoading = true;
    this._facadeService.CreateSecurityToken().subscribe(
      (data) => {
        this.showLoading = false;
        if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined) {
          var access_token = data.returnObject;
          var applicationId = "";
          var applications = JSON.parse(sessionStorage.getItem('applications'));
          applications.forEach((value, key) => {
            if (value.projectIdentifier == "ProjectUI") {
              applicationId = value.applicationId;
            }
          });
          var userGuid = sessionStorage.getItem('userGuid');
          var IsDCC = sessionStorage.getItem('IsDCC') != '' && sessionStorage.getItem('IsDCC') != null && sessionStorage.getItem('IsDCC') != undefined ? sessionStorage.getItem("IsDCC").toLowerCase() == "true" ? true : false : false;
          var clientId = sessionStorage.getItem('clientId') != '' && sessionStorage.getItem('clientId') != null && sessionStorage.getItem('clientId') != undefined ? parseInt(sessionStorage.getItem('clientId')) : 0;
          window.open(this.baseUrl["API_ENDPOINTS"]["ProjectUI"] + "entrypoint?projectId=" +
            this.Product.pmProjectID + "&userguid=" + userGuid + "&userId=" + this.userintId + '&destination=phasetree'
            + '&access_token=' + access_token + '&IsDCC=' + IsDCC + '&bt=' + sessionStorage.getItem('bt') + "&appliId=" + applicationId + "&clientId=" + clientId);
        }
        else {
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  PRJToken(taskId) {
    console.log("TaskId: ", taskId);
    this.showLoading = true;
    this._facadeService.CreateSecurityToken().subscribe(
      (data) => {
        this.showLoading = false;
        if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined) {
          var access_token = data.returnObject;
          var applicationId = "";
          var applications = JSON.parse(sessionStorage.getItem('applications'));
          applications.forEach((value, key) => {
            if (value.projectIdentifier == "ProjectUI") {
              applicationId = value.applicationId;
            }
          });
          var userGuid = sessionStorage.getItem('userGuid');
          var IsDCC = sessionStorage.getItem('IsDCC') != '' && sessionStorage.getItem('IsDCC') != null && sessionStorage.getItem('IsDCC') != undefined ? sessionStorage.getItem("IsDCC").toLowerCase() == "true" ? true : false : false;
          var clientId = sessionStorage.getItem('clientId') != '' && sessionStorage.getItem('clientId') != null && sessionStorage.getItem('clientId') != undefined ? parseInt(sessionStorage.getItem('clientId')) : 0;
          window.open(this.baseUrl["API_ENDPOINTS"]["ProjectUI"] + "entrypoint?projectId=" +
            this.Product.pmProjectID + "&userguid=" + userGuid + "&userId=" + this.userintId + '&destination=edittask' + '&taskid=' + taskId
            + '&access_token=' + access_token + '&IsDCC=' + IsDCC + '&bt=' + sessionStorage.getItem('bt') + "&appliId=" + applicationId + "&clientId=" + clientId);
          /* Displaying console */
          // console.log("PRJ_Token Method: " + this.baseUrl["API_ENDPOINTS"]["ProjectUI"] + "entrypoint?projectId=" +
          //   this.Product.pmProjectID + "&userguid=" + userGuid + "&userId=" + this.userintId + '&destination=edittask' + '&taskid=' + taskId
          //   + '&access_token=' + access_token + '&IsDCC=' + IsDCC + '&bt=' + sessionStorage.getItem('bt') + "&appliId=" + applicationId + "&clientId=" + clientId);
        }
        else {
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
