export class DashboardCount {
    peActiveProducts : number;
    peHoldProducts : number;
    peClarification : number;
    peApproval : number;
    peDocuments : number;
    activeProducts : number;
    holdProducts : number;
    clarification : number;
    approval : number;
    documents : number;
}
export class Timeline {
    productID: number;
    phaseID: number;
    transactionID: number;
    type: string;
    title: string;
    subTitle: string;
    subTitle1: string;
    subTitle2: string;
    subTitle3: string;
    icon: string;
    color: string;
    actionBy: string;
    actionDate: Date;
}
export class TimelineOptions {
    name: string;
    value: number;
}
export class DashboardProducts {
    select: boolean;
    spaceName: string;
    productID: number;
    productGuid: string;
    productName: string;
    dieCode: string;
    mfgPart: string;
    fgPart: string;
    projectDescription: string;
    productLine: string;
    productOwnerID: string;
    productOwner: string;
    startDate: string;
    dueDate: string;
    productStatus:string;
    phaseID: number;
    phaseGuid: string;
    phase: string;
    phaseStatus: string;
    type:string;
    transactionID: number;
    productIDDisplay : string;
    subcon : string;
    foundry : string;
    phaseStartDate:string;
}

export class MyDocuments {
    spaceName: string;
    productID: number;
    productGuid: string;
    productName: string;
    phaseID: number;
    phaseGuid: string;
    phase: string;
    step : string;
    stage : string;
    type:string;
    transactionID: number;

    reqDocumentID: number;
    reqDocumentGuid: string;
    documentName: string;
    isRequired: boolean;
    departmentGuid: string;
    departmentName: string;
    documentLabels : string;
    templates:Templates[];
    isCompleted: boolean;
}
export class Templates
{
    docTemplateID:number;
    type:string;
    reqDocumentID:number;
    templateID: string;
    templateName: string;
    templatePath: string;
}