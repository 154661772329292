import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { FacadeService } from "../../facade/facade.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private _facadeService: FacadeService) {}
  loginModel: any;
  userName: any;
  password: any;
  loginMessage: any;
  loginFlag: boolean = false;
  showLoading:boolean;

  ngOnInit(): void {}
  login() {
    this.showLoading = true;
    // let upperCaseCharacters = /[A-Z]+/g;
    // let lowerCaseCharacters = /[a-z]+/g;
    // let numberCharacters = /[0-9]+/g;
    let strongPassword: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    if (this.userName == undefined || this.userName == "") {
      this.loginFlag = true;
      this.loginMessage = "Please enter valid username.";
    } else if (this.password == undefined || this.password == "") {
      this.loginFlag = true;
      this.loginMessage = "Please enter  password.";
    } else if (strongPassword.test(this.password) == false) {
      this.loginFlag = true;

      this.loginMessage = "Please enter valid password.";
    }
    if (
      this.userName != undefined &&
      this.userName != "" &&
      this.password != undefined &&
      this.password != "" &&
      strongPassword.test(this.password) == true
    ) {
      this.loginModel = {
        email: this.userName,
        Password: this.password,
      };
      this._facadeService.login(this.loginModel).subscribe(
        (data) => {
          if (data.returnCode == 0) {
            sessionStorage.userId = data.returnObject.userId;
            sessionStorage.userGuid = data.returnObject.userGuid;
            sessionStorage.firstName = data.returnObject.firstName;
            sessionStorage.applicationID = 2;
            console.log(data.returnMessage);
            this.router.navigate(["/dashboard"]);
            console.log(data);
          } else {
            this.loginFlag = true;
            this.loginMessage = data.returnMessage;

            console.log(data.returnMessage);
            this.showLoading = false;
          }
        },

        (error) => {
          console.log(error);
        }
      );
    }
  }
}
