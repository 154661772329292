<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Manage Product Engineer
           
          </h5>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-3 card-header" style="margin-top: 25px;">
                <!-- <label style="text-align: right;">Project:&nbsp;&nbsp;</label>
                <p-multiSelect [options]="ProductDrop" [(ngModel)]="selectedProduct" placeholder="Select Project"
                  (onChange)="productChange($event.value)" optionLabel="productName"></p-multiSelect>
                  <small class="p-invalid">{{ productError }}</small>  -->
                  <label style="text-align: right;">Project:&nbsp;&nbsp;</label>
                  <p-autoComplete [(ngModel)]="selectedProduct" [suggestions]="filteredProdType" class="width350" placeholder="Select Product" 
                  (completeMethod)="filterproductListChange($event)"   field="productName" [dropdown]="true" (onSelect)="productChange()">
                  <ng-template>
                    <div>
                      <div>{{ filteredProdType.productName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ productError }}</small> 
            </div>
        </div>
        <hr />
        <div class="p-grid p-fluid">
        <div class="p-col-12">
          <div class="p-grid">
            <div class="p-col-12 p-md-3">
              <label>Product Line</label>
              <input type="text" pInputText placeholder="Product Line" [(ngModel)]="productLine" disabled />
            </div>
            <div class="p-col-12 p-md-3">
              <label for="ProjecName">Project Name</label>
              <input type="text" pInputText placeholder="Project Name" [(ngModel)]="projecName" disabled />
            </div>
            <div class="p-col-12 p-md-3">
              <label for="state">Die Code</label>
              <input type="text" pInputText placeholder="Die Code" [(ngModel)]="dieCode" disabled />
            </div>
            <div class="p-col-12 p-md-3">
              <label for="state">Mfg Part #</label>
              <input type="text" pInputText placeholder="Mfg Part #" [(ngModel)]="mfgPart" disabled />
            </div>
            <div class="p-col-12 p-md-3">
              <label for="state">Project Description</label>
              <input type="text" pInputText placeholder="Project Description" [(ngModel)]="projectDescription" disabled />
            </div>
            <div class="p-col-12 p-md-3">
              <label for="state">Description</label>
              <input type="text" pInputText placeholder="Description" [(ngModel)]="description" disabled />
            </div>
            <div class="p-col-12 p-md-3">
                <label for="state">Current Phase</label>
                <input type="text" pInputText placeholder="Current Phase" [(ngModel)]="phase" disabled />
            </div>
            <div class="p-col-12 p-md-3">
                <label for="state">Status</label>
                <input type="text" pInputText placeholder="Status" [(ngModel)]="status" disabled />
            </div>
            <div class="p-col-12 p-md-3">
              <label for="startDate">Start Date</label>
              <p-calendar [showIcon]="true" placeholder=" Start Date" inputId="startDate" [(ngModel)]="startDate" [disabled]=true></p-calendar>
            </div>
            <div class="p-col-12 p-md-3">
              <label for="endDate">End Date</label>
              <p-calendar [showIcon]="true" placeholder="End Date" inputId="endDate" [(ngModel)]="endDate" [disabled]=true></p-calendar>
            </div>
            <div class="p-col-12 p-md-3">
              <label for="actualStartDate">Project Engineer</label>
              <p-dropdown [options]="prodOwnerData" [(ngModel)]="selectedProductOwner"
                required="true" placeholder="Select Product Engineer" optionLabel="fullName"
                requiredMessage="The input must not be empty" 
                ></p-dropdown>
            </div>
            <div class="p-col-12 p-md-9" style="vertical-align: center;text-align: right;">
              <small class="p-invalid" style="float: right">{{ProductEngError}}</small>
            </div>
            <div class="p-col-12 p-md-3">
              <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Update"  *ngIf="View"
              class="p-button-success" (click)="savedata()"
              style="width:120px; float:right; margin-left:10px;margin-top: 10px;"></button>
          </div>
  
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>


<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>