import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Product } from 'src/app/model/PhaseDetails';

@Component({
  selector: 'app-eccn',
  templateUrl: './eccn.component.html',
  styleUrls: ['./eccn.component.scss']
})
export class EccnComponent implements OnInit {
  ProductID = Number(sessionStorage.getItem("ProductID"));
  showLoading:boolean;
  userId = sessionStorage.getItem("userGuid");
  userIdInt = sessionStorage.getItem("userId");

  Product: Product;
  ErrorDisplay: boolean = false;
  Error: string = "";
  ECCNAvailable: boolean = false;
  ISGC:boolean = false;
  ProductNot:boolean = false;
  ECCN:string = "";
  Message:string = "";
  @ViewChild("fileInput") fileInput: FileUpload;
  ECCNError:string = "";
  MessageError:string = "";
  FileError:string = "";
  Reminder:boolean = false;
  userFeature: boolean = false;
  constructor( private route: Router,private _facadeService: FacadeService,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object,) { }

  ngOnInit(): void {
    this.showLoading = true;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if (feature.length > 0) {
        var featureExist = feature.find(m => m.code == "PM_PRDD");
        if (featureExist != null && featureExist != undefined)
          this.userFeature = true;
        else
          this.userFeature = false;

        var featureExist1 = feature.find(m=>m.code=="PDM_ISGC");
        if(featureExist1!=null)
          this.ISGC = true;
      }
    }
    if (this.fileInput != undefined || this.fileInput != null) {
      if (this.fileInput.files.length > 0) {
        this.fileInput.clear();
      }
    }
    this.getProductDetails();
  }
  getProductDetails() {
    if(this.ProductID != null && this.ProductID != undefined && this.ProductID != 0)
    {
      this._facadeService.ProductDetails(this.ProductID, this.userId,this.userFeature).subscribe(
        (data) => {
          this.Product = data.returnObject;
          if(this.Product.eccn != "" && this.Product.eccn != null && this.Product.eccn != undefined)
            this.ECCNAvailable = true;

          if ( this.Product.requestedDate !== null && this.Product.requestedDate !== undefined) 
            this.Product.requestedDate = new Date(new Date(this.Product.requestedDate).toDateString() + " " + new Date(this.Product.requestedDate).toTimeString() + " UTC");
          if (this.Product.isReminderECCN === true)
            this.Reminder = true;
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
    {
      this.ProductNot = true;
      this.showLoading = false;
    }
  }
  ECCNReminder()
  {
    this.showLoading = true;
    this._facadeService.ECCNReminder(this.Product.productID).subscribe(
      (data) => {
        this.ErrorDisplay = true;
        if (data.returnCode == 0)
          this.Error = "Sent successfully";
        else
          this.Error = data.returnMessage;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  savedata()
  {
    this.showLoading = true;
    this.ECCNError = '';
    this.MessageError = '';
    this.FileError = "";
    if(this.ECCN == "" || this.ECCN == null || this.ECCN == undefined)
    { this.ECCNError = "Please enter ECCN";this.showLoading = false;}
    // else if(this.Message == "" || this.Message == null || this.Message == undefined)
    // { this.MessageError = "Please enter Message";this.showLoading = false;}
    else if(this.fileInput == null || this.fileInput == undefined)
    { this.FileError = "Please select document";this.showLoading = false;}
    else if(this.fileInput.files == null || this.fileInput.files == undefined)
    { this.FileError = "Please select document";this.showLoading = false;}
    else if(this.fileInput.files.length <= 0)
    { this.FileError = "Please select document";this.showLoading = false;}
    else
    {
      if(this.Message == null || this.Message == undefined)
        this.Message == "";
      const formData = new FormData();
      formData.append("productID", this.Product.productID.toString());
      formData.append("productGUID", this.Product.productGuid);
      formData.append("eccn", this.ECCN);
      formData.append("message", this.Message);
      formData.append("addedBy", this.userId);
      formData.append("addedByInt", this.userIdInt);
      formData.append("departmentID", sessionStorage.getItem("userDepartmentID"));
      formData.append("applicationID", sessionStorage.getItem("applicationID"));
      formData.append("fGPart", this.Product.fgPart);
      formData.append("file", this.fileInput.files[0]);
      this._facadeService.UpdateECCN(formData).subscribe(
        (data) => {
          var apiResponse = data;
          this.ErrorDisplay = true;
            if(apiResponse.returnCode == 0)
            {
              this.Error = "Saved successfully.";
              this.route.navigate(["/dashboard", { IsDCC: false }]);
            }
            else
              this.Error = apiResponse.returnMessage;
            this.showLoading=false;
        },
        (error) => {
        console.log(error);this.showLoading=false;
        }
      );
    }
  }
  DMSToken1() {
    if(this.Product.eccnDocID != null && this.Product.eccnDocID != undefined)
    {
      if(this.Product.eccnDocID > 0)
      {
        this.showLoading = true;
        this._facadeService.CreateSecurityToken().subscribe(
          (data) => {
            if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined) {
              var access_token = data.returnObject;
              var applicationId="";
              var applications = JSON.parse(sessionStorage.getItem('applications'));
              applications.forEach((value, key) => {
                if(value.projectIdentifier == "DMSUI")
                  applicationId=value.applicationId;
              });
              var userGuid = sessionStorage.getItem('userGuid'); 
              var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
              var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
              window.open(this.baseUrl["API_ENDPOINTS"]["DMSUI"] + "entrypoint?userguid=" + userGuid + "&docId="+ this.Product.eccnDocID +"&userId=" + this.userIdInt + '&destination=docdetails' 
                + '&access_token=' + access_token +'&IsDCC='+IsDCC+ '&bt=' + sessionStorage.getItem('bt')+"&appliId="+applicationId+"&clientId="+clientId);
            }
            else
              location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }
  
}
