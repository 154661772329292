import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardProducts } from '../../model/DashboardData';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../services/printcommon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-myapproval',
  templateUrl: './myapproval.component.html',
  styleUrls: ['./myapproval.component.scss']
})
export class MyapprovalComponent implements OnInit {
    loading: "loading";
    userId = sessionStorage.getItem('userGuid');
    showLoading:boolean;

    cols: any[];
    cols1: any[];
    cols2: any[];
    activeproducts: any[];
    selectedactiveProducts: any[];
    @ViewChild('dtactive') tableactive: Table;

    cancelledproducts: any[];
    selectedcancelledProducts: any[];
    @ViewChild('dtcancelled') tablecancelled: Table;

    conditionalproducts: any[];
    selectedconditionalProducts: any[];
    @ViewChild('dtconditional') tableconditional: Table;

    evaluationproducts: any[];
    selectedevaluationProducts: any[];
    @ViewChild('dtevaluation') tableevaluation: Table;

    phase: any[];
    selectedphase: any[];
    @ViewChild('dtphase') tablephase: Table;

    discontinue: any[];
    selecteddiscontinue: any[];
    @ViewChild('dtdiscontinue') tablediscontinue: Table;

    crc: any[];
    selectedcrc: any[];
    @ViewChild('dtcrc') tablecrc: Table;

    cre: any[];
    selectedcre: any[];
    @ViewChild('dtcre') tablecre: Table;

    index:number = 0;
    Count:number = 0;
    Count1:number = 0;
    Count2:number = 0;
    Count3:number = 0;
    Count4:number = 0;
    Count5:number = 0;
    Count6:number = 0;
    Count7:number = 0;

    phaseperiod: any[];
    selectedphasePeriod:any;
    phasedatesdisplay:boolean = false;
    editphaseStartDate:any;
    editphaseEndDate:any;
    phaseerror:string = "";

    discontinueperiod: any[];
    selecteddiscontinuePeriod:any;
    discontinuedatesdisplay:boolean = false;
    editdiscontinueStartDate:any;
    editdiscontinueEndDate:any;
    discontinueerror:string = "";

    crcperiod: any[];
    selectedcrcPeriod:any;
    crcdatesdisplay:boolean = false;
    editcrcStartDate:any;
    editcrcEndDate:any;
    crcerror:string = "";

    creperiod: any[];
    selectedcrePeriod:any;
    credatesdisplay:boolean = false;
    editcreStartDate:any;
    editcreEndDate:any;
    creerror:string = "";

    constructor(
      private _facadeService: FacadeService,
      public printService: PrintcommonService,
      private route: ActivatedRoute,
      private router: Router,
      private datePipe: DatePipe,
      @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) { }
  
    ngOnInit(): void {
          this.showLoading = true;
          this.cols = [
            { field: 'productName', header: 'Product' },
            { field: 'dieCode', header: 'Die Code' },
            { field: 'mfgPart', header: 'Mfg Part' },
            { field: 'projectDescription', header: 'Project Description' },
            { field: 'productLine', header: 'Product Line' },
            { field: 'productOwner', header: 'Product Engineer' },
            { field: 'phase', header: 'Phase' }
          ];
          this.cols1 = [
            { field: 'productName', header: 'Product' },
            { field: 'dieCode', header: 'Die Code' },
            { field: 'mfgPart', header: 'Mfg Part' },
            { field: 'productLine', header: 'Product Line' },
            { field: 'productOwner', header: 'Product Engineer' },
            { field: 'phase', header: 'Phase' },
            { field: 'approvedDate', header: 'Approved Date' },
            { field: 'comments', header: 'Comments' }
          ];
          this.cols2 = [
            { field: 'productName', header: 'Product' },
            { field: 'dieCode', header: 'Die Code' },
            { field: 'mfgPart', header: 'Mfg Part' },
            { field: 'productLine', header: 'Product Line' },
            { field: 'productOwner', header: 'Product Engineer' },
            { field: 'approvedDate', header: 'Approved Date' },
            { field: 'comments', header: 'Comments' }
          ];
          this.phaseperiod = [ 
            {id: 'currentmonth', name: 'Current Month'},
            {id: 'nextmonth', name: 'Next Month'},
            {id: 'next30days', name: 'Next 30 Days'},
            {id: 'custom', name: 'Custom'}
          ];
          this.selectedphasePeriod =  this.phaseperiod.find(m=>m.id=='currentmonth');

          this.discontinueperiod = [ 
            {id: 'currentmonth', name: 'Current Month'},
            {id: 'nextmonth', name: 'Next Month'},
            {id: 'next30days', name: 'Next 30 Days'},
            {id: 'custom', name: 'Custom'}
          ];
          this.selecteddiscontinuePeriod =  this.discontinueperiod.find(m=>m.id=='currentmonth');

          this.crcperiod = [ 
            {id: 'currentmonth', name: 'Current Month'},
            {id: 'nextmonth', name: 'Next Month'},
            {id: 'next30days', name: 'Next 30 Days'},
            {id: 'custom', name: 'Custom'}
          ];
          this.selectedcrcPeriod =  this.crcperiod.find(m=>m.id=='currentmonth');

          this.creperiod = [ 
            {id: 'currentmonth', name: 'Current Month'},
            {id: 'nextmonth', name: 'Next Month'},
            {id: 'next30days', name: 'Next 30 Days'},
            {id: 'custom', name: 'Custom'}
          ];
          this.selectedcrePeriod =  this.creperiod.find(m=>m.id=='currentmonth');

          sessionStorage.productName = "";
          sessionStorage.dieCode = "";
          sessionStorage.mfgPart = "";
          sessionStorage.fgPart = "";
          sessionStorage.productLine = "";
          sessionStorage.productOwner = "";
          sessionStorage.phase = "";

          sessionStorage.productName1 = "";
          sessionStorage.dieCode1 = "";
          sessionStorage.mfgPart1 = "";
          sessionStorage.fgPart1 = "";
          sessionStorage.productLine1 = "";
          sessionStorage.productOwner1 = "";
          sessionStorage.phase1 = "";

          sessionStorage.productName2 = "";
          sessionStorage.dieCode2 = "";
          sessionStorage.mfgPart2 = "";
          sessionStorage.fgPart2 = "";
          sessionStorage.productLine2 = "";
          sessionStorage.productOwner2 = "";
          sessionStorage.phase2 = "";

          sessionStorage.productName3 = "";
          sessionStorage.dieCode3 = "";
          sessionStorage.mfgPart3 = "";
          sessionStorage.fgPart3 = "";
          sessionStorage.productLine3 = "";
          sessionStorage.productOwner3 = "";
          sessionStorage.phase3 = "";

          sessionStorage.productName4 = "";
          sessionStorage.dieCode4 = "";
          sessionStorage.mfgPart4 = "";
          sessionStorage.productLine4 = "";
          sessionStorage.productOwner4 = "";
          sessionStorage.phase4 = "";
          sessionStorage.approvedDate4 = "";
          sessionStorage.comments4 = "";

          sessionStorage.productName5 = "";
          sessionStorage.dieCode5 = "";
          sessionStorage.mfgPart5 = "";
          sessionStorage.productLine5 = "";
          sessionStorage.productOwner5 = "";
          sessionStorage.approvedDate5 = "";
          sessionStorage.comments5 = "";

          sessionStorage.productName6 = "";
          sessionStorage.dieCode6 = "";
          sessionStorage.mfgPart6 = "";
          sessionStorage.productLine6 = "";
          sessionStorage.productOwner6 = "";
          sessionStorage.approvedDate6 = "";
          sessionStorage.comments6 = "";

          sessionStorage.productName7 = "";
          sessionStorage.dieCode7 = "";
          sessionStorage.mfgPart7 = "";
          sessionStorage.productLine7 = "";
          sessionStorage.productOwner7 = "";
          sessionStorage.approvedDate7 = "";
          sessionStorage.comments7 = "";

          this.index = 0;
          if(this.route.snapshot.paramMap.get('index')!=undefined)
          {
            var ii = this.route.snapshot.paramMap.get('index');
            if(ii != '0' && ii != '')
              this.index = Number(ii);
          }

          this.getapprovalproducts();
          this.getcancelproducts();
          this.getConditionalproducts();
          this.getEvaluationproducts();
          this.getphases();
          this.getdiscontinue();
          this.getcrc();
          this.getcre();
    }

    CountFilter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName = val;
      else if(field == "dieCode")
        sessionStorage.dieCode = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart = val;
      else if(field == "phase")
        sessionStorage.phase = val;
      else if(field == "fgPart")
        sessionStorage.fgPart = val;
      else if(field == "productLine")
        sessionStorage.productLine = val;
      else if(field == "productOwner")
        sessionStorage.productOwner = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM();
    }
    Count1Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName1 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode1 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart1 = val;
      else if(field == "phase")
        sessionStorage.phase1 = val;
      else if(field == "fgPart")
        sessionStorage.fgPart1 = val;
      else if(field == "productLine")
        sessionStorage.productLine1 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner1 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM1();
    }
    Count2Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName2 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode2 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart2 = val;
      else if(field == "phase")
        sessionStorage.phase2 = val;
      else if(field == "fgPart")
        sessionStorage.fgPart2 = val;
      else if(field == "productLine")
        sessionStorage.productLine2 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner2 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM2();
    }
    Count3Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName3 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode3 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart3 = val;
      else if(field == "phase")
        sessionStorage.phase3 = val;
      else if(field == "fgPart")
        sessionStorage.fgPart3 = val;
      else if(field == "productLine")
        sessionStorage.productLine3 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner3 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM3();
    }
    Count4Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName4 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode4 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart4 = val;
      else if(field == "productLine")
        sessionStorage.productLine4 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner4 = val;
      else if(field == "phase")
        sessionStorage.phase4 = val;
      else if(field == "approvedDate")
        sessionStorage.approvedDate4 = val;
      else if(field == "comments")
        sessionStorage.comments4 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM4();
    }
    Count5Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName5 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode5 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart5 = val;
      else if(field == "productLine")
        sessionStorage.productLine5 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner5 = val;
      else if(field == "approvedDate")
        sessionStorage.approvedDate5 = val;
      else if(field == "comments")
        sessionStorage.comments5 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM5();
    }
    Count6Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName6 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode6 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart6 = val;
      else if(field == "productLine")
        sessionStorage.productLine6 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner6 = val;
      else if(field == "approvedDate")
        sessionStorage.approvedDate6 = val;
      else if(field == "comments")
        sessionStorage.comments6 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM6();
    }
    Count7Filter(val,field,dt)
    {
      if(field == "productName")
        sessionStorage.productName7 = val;
      else if(field == "dieCode")
        sessionStorage.dieCode7 = val;
      else if(field == "mfgPart")
        sessionStorage.mfgPart7 = val;
      else if(field == "productLine")
        sessionStorage.productLine7 = val;
      else if(field == "productOwner")
        sessionStorage.productOwner7 = val;
      else if(field == "approvedDate")
        sessionStorage.approvedDate7 = val;
      else if(field == "comments")
        sessionStorage.comments7 = val;
      dt = dt.filter(val,field, 'startsWith');
      this.CountM7();
    }
    CountGlobalFilter(val,dt)
    {
      sessionStorage.productName = val;
      sessionStorage.dieCode = val;
      sessionStorage.mfgPart = val;
      sessionStorage.fgPart = val;
      sessionStorage.productLine = val;
      sessionStorage.phase = val;
      sessionStorage.productOwner = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM();
    }
    Count1GlobalFilter(val,dt)
    {
      sessionStorage.productName1 = val;
      sessionStorage.dieCode1 = val;
      sessionStorage.mfgPart1 = val;
      sessionStorage.fgPart1 = val;
      sessionStorage.productLine1 = val;
      sessionStorage.phase1 = val;
      sessionStorage.productOwner1 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM1();
    }
    Count2GlobalFilter(val,dt)
    {
      sessionStorage.productName2 = val;
      sessionStorage.dieCode2 = val;
      sessionStorage.mfgPart2 = val;
      sessionStorage.fgPart2 = val;
      sessionStorage.productLine2 = val;
      sessionStorage.phase2 = val;
      sessionStorage.productOwner2 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM2();
    }
    Count3GlobalFilter(val,dt)
    {
      sessionStorage.productName3 = val;
      sessionStorage.dieCode3 = val;
      sessionStorage.mfgPart3 = val;
      sessionStorage.fgPart3 = val;
      sessionStorage.productLine3 = val;
      sessionStorage.phase3 = val;
      sessionStorage.productOwner3 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM3();
    }
    Count4GlobalFilter(val,dt)
    {
      sessionStorage.productName4 = val;
      sessionStorage.dieCode4 = val;
      sessionStorage.mfgPart4 = val;
      sessionStorage.productLine4 = val;
      sessionStorage.phase4 = val;
      sessionStorage.productOwner4 = val;
      sessionStorage.approvedDate4 = val;
      sessionStorage.comments4 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM4();
    }
    Count5GlobalFilter(val,dt)
    {
      sessionStorage.productName5 = val;
      sessionStorage.dieCode5 = val;
      sessionStorage.mfgPart5 = val;
      sessionStorage.productLine5 = val;
      sessionStorage.productOwner5 = val;
      sessionStorage.approvedDate5 = val;
      sessionStorage.comments5 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM5();
    }
    Count6GlobalFilter(val,dt)
    {
      sessionStorage.productName6 = val;
      sessionStorage.dieCode6 = val;
      sessionStorage.mfgPart6 = val;
      sessionStorage.productLine6 = val;
      sessionStorage.productOwner6 = val;
      sessionStorage.approvedDate6 = val;
      sessionStorage.comments6 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM6();
    }
    Count7GlobalFilter(val,dt)
    {
      sessionStorage.productName7 = val;
      sessionStorage.dieCode7 = val;
      sessionStorage.mfgPart7 = val;
      sessionStorage.productLine7 = val;
      sessionStorage.productOwner7 = val;
      sessionStorage.approvedDate7 = val;
      sessionStorage.comments7 = val;
      dt = dt.filterGlobal(val, 'startsWith');
      this.CountM7();
    }
    CountM()
    {
      if(this.activeproducts != undefined && this.activeproducts != null)
      {
        var temp = this.activeproducts;
        if(sessionStorage.productName != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
        if(sessionStorage.dieCode != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode.toLowerCase()));
        if(sessionStorage.mfgPart != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart.toLowerCase()));
        if(sessionStorage.fgPart != "")
          temp = temp.filter(x=> x.fgPart.toLowerCase().startsWith(sessionStorage.fgPart.toLowerCase()));
        if(sessionStorage.productLine != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine.toLowerCase()));
        if(sessionStorage.productOwner != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner.toLowerCase()));
        if(sessionStorage.phase != "")
          temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase.toLowerCase()));
        this.Count = temp.length;
      }
      else
        this.Count = 0;
    }
    CountM1()
    {
      if(this.cancelledproducts != undefined && this.cancelledproducts != null)
      {
        var temp = this.cancelledproducts;
        if(sessionStorage.productName1 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName1.toLowerCase()));
        if(sessionStorage.dieCode1 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode1.toLowerCase()));
        if(sessionStorage.mfgPart1 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart1.toLowerCase()));
        if(sessionStorage.fgPart1 != "")
          temp = temp.filter(x=> x.fgPart.toLowerCase().startsWith(sessionStorage.fgPart1.toLowerCase()));
        if(sessionStorage.productLine1 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine1.toLowerCase()));
        if(sessionStorage.productOwner1 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner1.toLowerCase()));
        if(sessionStorage.phase1 != "")
          temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase1.toLowerCase()));
        this.Count1 = temp.length;
      }
      else
        this.Count1 = 0;
    }
    CountM2()
    {
      if(this.conditionalproducts != undefined && this.conditionalproducts != null)
      {
        var temp = this.conditionalproducts;
        if(sessionStorage.productName2 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName2.toLowerCase()));
        if(sessionStorage.dieCode2 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode2.toLowerCase()));
        if(sessionStorage.mfgPart2 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart2.toLowerCase()));
        if(sessionStorage.fgPart2 != "")
          temp = temp.filter(x=> x.fgPart.toLowerCase().startsWith(sessionStorage.fgPart2.toLowerCase()));
        if(sessionStorage.productLine2 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine2.toLowerCase()));
        if(sessionStorage.productOwner2 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner2.toLowerCase()));
        if(sessionStorage.phase2 != "")
          temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase2.toLowerCase()));
        this.Count2 = temp.length;
      }
      else
        this.Count2 = 0;
    }
    CountM3()
    {
      if(this.evaluationproducts != undefined && this.evaluationproducts != null)
      {
        var temp = this.evaluationproducts;
        if(sessionStorage.productName3 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName3.toLowerCase()));
        if(sessionStorage.dieCode3 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode3.toLowerCase()));
        if(sessionStorage.mfgPart3 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart3.toLowerCase()));
        if(sessionStorage.fgPart3 != "")
          temp = temp.filter(x=> x.fgPart.toLowerCase().startsWith(sessionStorage.fgPart3.toLowerCase()));
        if(sessionStorage.productLine3 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine3.toLowerCase()));
        if(sessionStorage.productOwner3 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner3.toLowerCase()));
        if(sessionStorage.phase3 != "")
          temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase3.toLowerCase()));
        this.Count3 = temp.length;
      }
      else
        this.Count3 = 0;
    }
    CountM4()
    {
      if(this.phase != undefined && this.phase != null)
      {
        var temp = this.phase;
        if(sessionStorage.productName4 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName4.toLowerCase()));
        if(sessionStorage.dieCode4 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode4.toLowerCase()));
        if(sessionStorage.mfgPart4 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart4.toLowerCase()));
        if(sessionStorage.productLine4 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine4.toLowerCase()));
        if(sessionStorage.productOwner4 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner4.toLowerCase()));
        if(sessionStorage.phase4 != "")
          temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase4.toLowerCase()));
        if(sessionStorage.approvedDate4 != "")
          temp = temp.filter(x=> x.approvedDate.toLowerCase().startsWith(sessionStorage.approvedDate4.toLowerCase()));
        if(sessionStorage.comments4 != "")
          temp = temp.filter(x=> x.comments.toLowerCase().startsWith(sessionStorage.comments4.toLowerCase()));
        this.Count4 = temp.length;
      }
      else
        this.Count4 = 0;
    }
    CountM5()
    {
      if(this.discontinue != undefined && this.discontinue != null)
      {
        var temp = this.discontinue;
        if(sessionStorage.productName5 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName5.toLowerCase()));
        if(sessionStorage.dieCode5 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode5.toLowerCase()));
        if(sessionStorage.mfgPart5 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart5.toLowerCase()));
        if(sessionStorage.productLine5 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine5.toLowerCase()));
        if(sessionStorage.productOwner5 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner5.toLowerCase()));
        if(sessionStorage.approvedDate5 != "")
          temp = temp.filter(x=> x.approvedDate.toLowerCase().startsWith(sessionStorage.approvedDate5.toLowerCase()));
        if(sessionStorage.comments5 != "")
          temp = temp.filter(x=> x.comments.toLowerCase().startsWith(sessionStorage.comments5.toLowerCase()));
        this.Count5 = temp.length;
      }
      else
        this.Count5 = 0;
    }
    CountM6()
    {
      if(this.crc != undefined && this.crc != null)
      {
        var temp = this.crc;
        if(sessionStorage.productName6 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName6.toLowerCase()));
        if(sessionStorage.dieCode6 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode6.toLowerCase()));
        if(sessionStorage.mfgPart6 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart6.toLowerCase()));
        if(sessionStorage.productLine6 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine6.toLowerCase()));
        if(sessionStorage.productOwner6 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner6.toLowerCase()));
        if(sessionStorage.approvedDate6 != "")
          temp = temp.filter(x=> x.approvedDate.toLowerCase().startsWith(sessionStorage.approvedDate6.toLowerCase()));
        if(sessionStorage.comments6 != "")
          temp = temp.filter(x=> x.comments.toLowerCase().startsWith(sessionStorage.comments6.toLowerCase()));
        this.Count6 = temp.length;
      }
      else
        this.Count6 = 0;
    }
    CountM7()
    {
      if(this.cre != undefined && this.cre != null)
      {
        var temp = this.cre;
        if(sessionStorage.productName7 != "")
          temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName7.toLowerCase()));
        if(sessionStorage.dieCode7 != "")
          temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode7.toLowerCase()));
        if(sessionStorage.mfgPart7 != "")
          temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart7.toLowerCase()));
        if(sessionStorage.productLine7 != "")
          temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine7.toLowerCase()));
        if(sessionStorage.productOwner7 != "")
          temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner7.toLowerCase()));
        if(sessionStorage.approvedDate7 != "")
          temp = temp.filter(x=> x.approvedDate.toLowerCase().startsWith(sessionStorage.approvedDate7.toLowerCase()));
        if(sessionStorage.comments7 != "")
          temp = temp.filter(x=> x.comments.toLowerCase().startsWith(sessionStorage.comments7.toLowerCase()));
        this.Count7 = temp.length;
      }
      else
        this.Count7 = 0;
    }
    getapprovalproducts() {
      this._facadeService.MyApproval(this.userId).subscribe(
        (data) => {
           this.activeproducts = data.returnObject;  
           this.CountM();
        },
        (error) => {
          console.log(error);
        }
      );
    }
    getcancelproducts() {
      this._facadeService.MyCancelApproval(this.userId).subscribe(
        (data) => {
           this.cancelledproducts = data.returnObject;  
           this.CountM1();
        },
        (error) => {
          console.log(error);
        }
      );
    }
    getConditionalproducts() {
      this._facadeService.getConditionalproducts(this.userId).subscribe(
        (data) => {
           this.conditionalproducts = data.returnObject;  
           this.CountM2();
        },
        (error) => {
          console.log(error);
        }
      );
    }
    getEvaluationproducts()
    {
      this._facadeService.getEvaluationproducts(this.userId).subscribe(
        (data) => {
           this.evaluationproducts = data.returnObject;  
           this.CountM3();
        },
        (error) => {
          console.log(error);
        }
      );
    }

    getphases() {
      this.phaseerror = "";
      if(this.selectedphasePeriod.id == null || this.selectedphasePeriod.id == undefined)
        this.phaseerror = "Please select period";
      else if(this.selectedphasePeriod.id == "custom" && (this.editphaseStartDate == null || this.editphaseStartDate == undefined || this.editphaseStartDate == ""))
        this.phaseerror = "Please enter start date";
      else if(this.selectedphasePeriod.id == "custom" && (this.editphaseEndDate == null || this.editphaseEndDate == undefined || this.editphaseEndDate == ""))
        this.phaseerror = "Please enter end date";
      else if(this.selectedphasePeriod.id == "custom" && new Date(this.editphaseEndDate) <= new Date(this.editphaseStartDate) )
        this.phaseerror = "Start Date should be less then End Date";
      else
      {
        this.showLoading = true;
        var searchModel={
          'type':"Phase",
          'userID':this.userId,
          'periodType':this.selectedphasePeriod.id,
          'startDate':this.editphaseStartDate == null || this.editphaseStartDate == undefined || this.editphaseStartDate == ""? new Date() : new Date(this.editphaseStartDate),
          'endDate':this.editphaseEndDate == null || this.editphaseEndDate == undefined || this.editphaseEndDate == ""? new Date() : new Date(this.editphaseEndDate)
        }
        this._facadeService.GetUserApproved(searchModel).subscribe(
          (data) => {
              this.phase = data.returnObject;  
              this.phase.forEach((value) => {
                if ( value.approvedDate !== null &&  value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00' ) 
                value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
                value.approvedDate=this.datePipe.transform(value.approvedDate, 'MM/dd/yyyy');
              }); 
            this.CountM4();
            this.showLoading = false;
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
    }
    getdiscontinue() {
      this.discontinueerror = "";
      if(this.selecteddiscontinuePeriod.id == null || this.selecteddiscontinuePeriod.id == undefined)
        this.discontinueerror = "Please select period";
      else if(this.selecteddiscontinuePeriod.id == "custom" && (this.editdiscontinueStartDate == null || this.editdiscontinueStartDate == undefined || this.editdiscontinueStartDate == ""))
        this.discontinueerror = "Please enter start date";
      else if(this.selecteddiscontinuePeriod.id == "custom" && (this.editdiscontinueEndDate == null || this.editdiscontinueEndDate == undefined || this.editdiscontinueEndDate == ""))
        this.discontinueerror = "Please enter end date";
      else if(this.selecteddiscontinuePeriod.id == "custom" && new Date(this.editdiscontinueEndDate) <= new Date(this.editdiscontinueStartDate) )
        this.discontinueerror = "Start Date should be less then End Date";
      else
      {
        this.showLoading = true;
        var searchModel={
          'type':"Product",
          'userID':this.userId,
          'periodType':this.selecteddiscontinuePeriod.id,
          'startDate':this.editdiscontinueStartDate == null || this.editdiscontinueStartDate == undefined || this.editdiscontinueStartDate == ""? new Date() : new Date(this.editdiscontinueStartDate),
          'endDate':this.editdiscontinueEndDate == null || this.editdiscontinueEndDate == undefined || this.editdiscontinueEndDate == ""? new Date() : new Date(this.editdiscontinueEndDate)
        }
        this._facadeService.GetUserApproved(searchModel).subscribe(
          (data) => {
              this.discontinue = data.returnObject;  
              this.discontinue.forEach((value) => {
                if ( value.approvedDate !== null &&  value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00' ) 
               value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
               value.approvedDate = this.datePipe.transform( value.approvedDate, 'MM/dd/yyyy');
              }); 
            this.CountM5();
            this.showLoading = false;
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
    }
    getcrc() {
      this.crcerror = "";
      if(this.selectedcrcPeriod.id == null || this.selectedcrcPeriod.id == undefined)
        this.crcerror = "Please select period";
      else if(this.selectedcrcPeriod.id == "custom" && (this.editcrcStartDate == null || this.editcrcStartDate == undefined || this.editcrcStartDate == ""))
        this.crcerror = "Please enter start date";
      else if(this.selectedcrcPeriod.id == "custom" && (this.editcrcEndDate == null || this.editcrcEndDate == undefined || this.editcrcEndDate == ""))
        this.crcerror = "Please enter end date";
      else if(this.selectedcrcPeriod.id == "custom" && new Date(this.editcrcEndDate) <= new Date(this.editcrcStartDate) )
        this.crcerror = "Start Date should be less then End Date";
      else
      {
        this.showLoading = true;
        var searchModel={
          'type':"CRC",
          'userID':this.userId,
          'periodType':this.selectedcrcPeriod.id,
          'startDate':this.editcrcStartDate == null || this.editcrcStartDate == undefined || this.editcrcStartDate == ""? new Date() : new Date(this.editcrcStartDate),
          'endDate':this.editcrcEndDate == null || this.editcrcEndDate == undefined || this.editcrcEndDate == ""? new Date() : new Date(this.editcrcEndDate)
        }
        this._facadeService.GetUserApproved(searchModel).subscribe(
          (data) => {
              this.crc = data.returnObject;  
              this.crc.forEach((value) => {
                if ( value.approvedDate !== null &&  value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00' ) 
               value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
               value.approvedDate = this.datePipe.transform( value.approvedDate, 'MM/dd/yyyy');
              }); 
            this.CountM6();
            this.showLoading = false;
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
    }
    getcre() {
      this.creerror = "";
      if(this.selectedcrePeriod.id == null || this.selectedcrePeriod.id == undefined)
        this.creerror = "Please select period";
      else if(this.selectedcrePeriod.id == "custom" && (this.editcreStartDate == null || this.editcreStartDate == undefined || this.editcreStartDate == ""))
        this.creerror = "Please enter start date";
      else if(this.selectedcrePeriod.id == "custom" && (this.editcreEndDate == null || this.editcreEndDate == undefined || this.editcreEndDate == ""))
        this.creerror = "Please enter end date";
      else if(this.selectedcrePeriod.id == "custom" && new Date(this.editcreEndDate) <= new Date(this.editcreStartDate) )
        this.creerror = "Start Date should be less then End Date";
      else
      {
        this.showLoading = true;
        var searchModel={
          'type':"CRE",
          'userID':this.userId,
          'periodType':this.selectedcrePeriod.id,
          'startDate':this.editcreStartDate == null || this.editcreStartDate == undefined || this.editcreStartDate == ""? new Date() : new Date(this.editcreStartDate),
          'endDate':this.editcreEndDate == null || this.editcreEndDate == undefined || this.editcreEndDate == ""? new Date() : new Date(this.editcreEndDate)
        }
        this._facadeService.GetUserApproved(searchModel).subscribe(
          (data) => {
              this.cre = data.returnObject;  
              this.cre.forEach((value) => {
                if ( value.approvedDate !== null &&  value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00' ) 
                value.approvedDate = this.datePipe.transform( value.approvedDate, 'MM/dd/yyyy');
              }); 
            this.CountM7();
            this.showLoading = false;
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
    }
    phaseDates()
    {
      this.phaseerror = "";
      this.phasedatesdisplay = false;
      this.editphaseStartDate = "";
      this.editphaseEndDate = "";
      if(this.selectedphasePeriod.id == "custom")
        this.phasedatesdisplay = true;
    }
    discontinueDates()
    {
      this.discontinueerror = "";
      this.discontinuedatesdisplay = false;
      this.editdiscontinueStartDate = "";
      this.editdiscontinueEndDate = "";
      if(this.selecteddiscontinuePeriod.id == "custom")
        this.discontinuedatesdisplay = true;
    }
    crcDates()
    {
      this.crcerror = "";
      this.crcdatesdisplay = false;
      this.editcrcStartDate = "";
      this.editcrcEndDate = "";
      if(this.selectedcrcPeriod.id == "custom")
        this.crcdatesdisplay = true;
    }
    creDates()
    {
      this.creerror = "";
      this.credatesdisplay = false;
      this.editcreStartDate = "";
      this.editcreEndDate = "";
      if(this.selectedcrePeriod.id == "custom")
        this.credatesdisplay = true;
    }

    download(val) {
       /* table id is passed over here */   
       let element = document.getElementById(val); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       if(val == "excel-table" || val == "excel-table1" || val == "excel-table2" || val == "excel-table3")
        ws['!cols'] = [{ wpx : 180 },{ wpx : 100 },{ wpx : 150 },{ wpx : 150 },{ wpx : 100 },{ wpx : 150 },{ wpx : 100 }];
      if(val == "excel-table4")
        ws['!cols'] = [{ wpx : 180 },{ wpx : 100 },{ wpx : 150 },{ wpx : 100 },{ wpx : 150 },{ wpx : 100 },{ wpx : 120 },{ wpx : 250 }];
      if(val == "excel-table5" || val == "excel-table6" || val == "excel-table7")
        ws['!cols'] = [{ wpx : 180 },{ wpx : 100 },{ wpx : 150 },{ wpx : 100 },{ wpx : 150 },{ wpx : 120 },{ wpx : 250 }];

       this.delete_row(wb.Sheets.Sheet1, 1,val);
       /* save to file */
      if(val == "excel-table")
        XLSX.writeFile(wb, "My Approval - Stage Gate Approval.xlsx");
      if(val == "excel-table1")
        XLSX.writeFile(wb, "My Approval - Products for Discontinuation.xlsx");
      if(val == "excel-table2")
        XLSX.writeFile(wb, "My Approval - Conditional Release.xlsx");
      if(val == "excel-table3")
        XLSX.writeFile(wb, "My Approval - Eval Sample Release.xlsx");
      if(val == "excel-table4")
         XLSX.writeFile(wb, "My Approved - Stage Gates.xlsx");
      if(val == "excel-table5")
         XLSX.writeFile(wb, "My Approved - Discontinuation.xlsx");
      if(val == "excel-table6")
         XLSX.writeFile(wb, "My Approved - Conditional Release.xlsx");
      if(val == "excel-table7")
         XLSX.writeFile(wb, "My Approved - Eval Sample Release.xlsx");
    }
    delete_row (ws, row_index,val){
      let range = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < range.e.r; ++R){
          for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
          }
      }
      range.e.r--
      if(val != "excel-table4")
        delete (ws['H1'])
      if(val == "excel-table4")
        delete (ws['I1'])

      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    
    Details(Type,ProductID, PhaseID,Type1,TransactionID) {
      sessionStorage.ProductID = ProductID;
      sessionStorage.PhaseID = PhaseID;
      sessionStorage.DisplayType = Type1;
      sessionStorage.TransactionID = TransactionID;
      if(Type == "Approve")
        this.router.navigate(['/approval-details']);
      else if(Type == "Cancel")
        this.router.navigate(['/canceldetails']);
      else if(Type == "CRC")
      {
        sessionStorage.ConditionalType = 1;
        this.router.navigate(['/conditionalreleases']);
      }
      else if(Type == "CRE")
      {
        sessionStorage.ConditionalType = 2;
        this.router.navigate(['/conditionalreleases']);
      }
      else
      {
        sessionStorage.FromWhere = "app";
        this.router.navigate(['/phasedetails']);
      }
    }
    print(MainID,SearchID) {
        var Content = document.getElementById(MainID).innerHTML;
        var Title = "";
        if(MainID == "invoice-content")
          Title =  "My Approval - Stage Gate Approval";
        if(MainID == "invoice-content1")
          Title = "My Approval - Products for Discontinuation";
        if(MainID == "invoice-content2")
          Title = "My Approval - Conditional Release";
        if(MainID == "invoice-content3")
          Title = "My Approval - Eval Sample Release";
        if(MainID == "invoice-content4")
          Title = "My Approved - Stage Gates";
        if(MainID == "invoice-content5")
          Title = "My Approved - Discontinuation";
        if(MainID == "invoice-content6")
          Title = "My Approved - Conditional Release";
        if(MainID == "invoice-content7")
          Title = "My Approved - Eval Sample Release";

        this.printService.Print(Content,Title,SearchID,true);
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;
    
        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (event.field === 'approvedDate') {
          // Convert the string dates to Date objects for correct sorting
          const date1 = value1 === '--' ? null : new Date(value1);
          const date2 = value2 === '--' ? null : new Date(value2);
    
          // Handle special case: treat "--" as greater or smaller than any date
          if (date1 === null) {
            result = date2 === null ? 0 : -1;
          } else if (date2 === null) {
            result = 1;
          } else {
            result = date1.getTime() - date2.getTime();
          }
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
    
        return (event.order * result);
      });
    
      event.data.forEach(e => {
        e.approvedDate = this.datePipe.transform( e.approvedDate, 'MM/dd/yyyy');
      });
    }
  }
  