<div class="p-grid">
    <div class="p-col-12">
        <div class="card" style="margin-bottom:-10px;">
            <div class="p-grid">
                <br />
                <div class="p-col-1 p-md-1" style="padding-right: 0rem;">
                    <a (click)="back()"> <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left"
                            label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"></button></a>
                </div>
                <div class="p-col-10 p-md-10"
                    style="text-align:center;padding-right: 0rem;padding-left: 0rem; margin-top: 7px;">
                    <div class="p-grid" style="text-align:center;place-content:center;" *ngIf='Bar != undefined'>
                        <div *ngFor="let item of Bar;">
                            <div class="col-md-2 p-md-2" (click)="phases(item.phaseID,item.imageName,'No')"
                                class="{{item.imageName}}"
                                style="width:150px;height:50px;padding-left: 0px; padding-top: 14px;text-align: center; vertical-align: middle; ">
                                <span style="font-size: 16px; color:#fff; cursor: pointer; ">
                                    {{item.phaseName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-1 p-md-1" style="padding-left: 0rem;float: right;">
                    <span style="float: right;">
                        <p-splitButton label="Action" [model]="items"
                            *ngIf="Product.isSendForApproval === true || Product.isReminder === true || Product.isRelease === true || Product.isClarify === true"
                            styleClass="p-button-raised p-button-primary p-mr-2 p-mb-2"></p-splitButton>
                    </span>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid" style="margin-bottom: -2rem;">
                <div class="p-col-8">
                    <p-accordion>
                        <p-accordionTab header="Product:&nbsp;{{ Product.productName }} &nbsp;&nbsp;&nbsp;Description:&nbsp;{{ Product.description }} " [selected]="true">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:80px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Project:</span>
                                                <span>{{ Product.productName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Product Line:</span>
                                                <span>{{ Product.spaceName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Die Code:</span>
                                                <span>{{ Product.dieCode }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Product Engineer:</span>
                                                <span>{{ Product.productOwner }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:80px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Project Description:</span>
                                                <span>{{ Product.projectDescription}}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Mfg Part:</span>
                                                <span style="font-size: small;">{{ Product.mfgPart }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Start Date:</span>
                                                <span>{{ Product.startDate | date:'MM/dd/yyyy' }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Due Date:</span>
                                                <span>{{ Product.dueDate | date:'MM/dd/yyyy' }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:80px; overflow-y: auto;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Project Status:</span>
                                                <span>{{ Product.productStatus }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Current Phase:</span>
                                                <span class="listitem-value">{{ Product.currentPhase }}</span>
                                            </li>
                                            <li>
                                                <!-- <span style="font-weight: bold;">Reasons:</span> -->
                                                <span class="listitem-value"
                                                    style="float:right; margin-bottom:5px; height:20px;">
                                                    <button pButton type="button" pTooltip="View Reasons"
                                                        tooltipPosition="right" (click)="projectreasonsdisplay=true"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                        &nbsp;&nbsp;
                                                        <a (click)="open(Product.productGuid)" style="cursor: pointer">
                                                            <button pButton pRipple type="button" icon="pi pi-th-large"
                                                                    pTooltip="Additional Attributes" tooltipPosition="right"
                                                                    iconPos="roght" class="p-button-raised p-button-primary"
                                                                    style="height:25px; width:25px;"></button></a>
                                                        &nbsp;&nbsp;
                                                        <button *ngIf="DisplayPClare" pButton type="button"
                                                                pTooltip="View Discontinuation Details" tooltipPosition="right"
                                                                (click)="PClare=true" icon="pi pi-times-circle"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">ECCN:</span>
                                                <span>{{ Product.eccn }}</span>
                                                <!-- <span style="font-weight: bold;">ECCN Document:</span> -->
                                                <span *ngIf="Product.eccnDocID > 0"><a href="javascript:;"
                                                        (click)="DMSToken1()">{{ Product.eccnDocCode }}</a></span>
                                                <span *ngIf="Product.eccnDocID == 0">{{ Product.eccnDocCode }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="p-col-12 p-md-12 p-xl-12">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:80px; overflow-y: auto;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;align-self: baseline;">Description:</span>
                                                <span style="text-align:right;">{{ Product.description }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <div class="card-header" style="border-radius: 5px; margin-top:-5px;height:40px;"
                        [style.background-color]="PhaseColor">
                        <h5 style="color:#fff; padding:15px 0 0 15px;">{{ CurrentPhase.phaseName }}{{ CR }}</h5>
                        <span style="float: right;">
                            <a *ngIf="PMbtnDisplay" (click)="PMToken()" href="javascript:;" style="color: white; font-size: 14px;
                        padding-right: 15px; font-weight: bold; text-decoration: underline; "> Project Details </a>
                        </span>
                    </div>
                    <div class="p-col-12">
                        <span style="float: right;" *ngIf="CurrentPhase.isEOLDisplay === true">
                            <label for="Phase"> EOL / OBS <span style="color: red">*</span></label>&nbsp;&nbsp;
                            <p-dropdown [options]="EOL" [(ngModel)]="selectedEOL" required="true"
                                placeholder="Select EOL / OBS" optionLabel="label"
                                requiredMessage="The input must not be empty"></p-dropdown>&nbsp;&nbsp;
                            <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                                style="height:25px;" class="p-button-raised p-button-success p-mr-2 p-mb-2 width100"
                                (click)="saveeol()"></button>
                            <br>
                            <small class="p-invalid">{{ EOLError }}</small>
                        </span>
                        <span style="float: right;">
                            <p-splitButton label="Action" [model]="phaseitems" [disabled]="ViewOnly"
                                *ngIf="CurrentPhase.isConditionalReleaseDisplay === true || CurrentPhase.isEvalutionDisplay === true || CurrentPhase.isSendForApproval === true || CurrentPhase.isReminder === true || CurrentPhase.isClarify === true"
                                styleClass="p-button-raised p-button-primary p-mr-2 p-mb-2"></p-splitButton>
                        </span>
                        <span class="listitem-value" style="float:right; margin-bottom:0px; height:20px;"
                            *ngIf="issuelink">
                            <button pButton type="button" pTooltip="Pending Issues / Milestones" tooltipPosition="right"
                                (click)="isuus_tasks=true" icon="pi pi-eye" label="View Pending Issues / Milestones"
                                class="p-button-raised p-button-primary" style="height:40px; width:250px;"></button>
                            &nbsp;&nbsp;
                        </span>
                    </div>
                    <div class="p-col-12" style="margin-top: 28px;">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-4 p-xl-4">
                                <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                    style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Phase:</span>
                                            <span>{{ CurrentPhase.phaseName }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Phase Status:</span>
                                            <span>{{ CurrentPhase.phaseStatus }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Phase Owner:</span>
                                            <span>{{ CurrentPhase.phaseOwner }}</span>
                                        </li>
                                        <li *ngIf="IsPrevEvalDisplay"> 
                                                <span style="font-weight: bold;">Previous Eval Sample Release:</span>
                                            <span><button (click)="PrevCR(2)" pButton type="button" pTooltip="View"
                                                    tooltipPosition="right" icon="pi pi-eye"
                                                    class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                            </span>
                                        </li>
                                        <li *ngIf="IsEvalutionExist">
                                                <span style="font-weight: bold;">Evaluation Sample Release:</span>
                                            <span class="listitem-value"><button
                                                    (click)="GetConditionalReleaseDetails(2)" pButton type="button"
                                                    pTooltip="View" tooltipPosition="right" icon="pi pi-eye"
                                                    class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-xl-4">
                                <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                    style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Responsible Dept:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button" pTooltip="View" tooltipPosition="right"
                                                    (click)="departmentsdisplay=true" icon="pi pi-eye"
                                                    class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                                <p-dialog header="Responsible Departments"
                                                    [(visible)]="departmentsdisplay" modal="modal" showEffect="fade"
                                                    [style]="{width:'25%'}">
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <p-table #dtDepartments [value]="CurrentDepartments"
                                                                [columns]="Departmentscols" sortMode="multiple"
                                                                [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0"
                                                                selectionMode="multiple"
                                                                [(selection)]="selectedDepartments">
                                                                <ng-template pTemplate="header" let-columns
                                                                    class="invoice-items">
                                                                    <tr>
                                                                        <th style="text-align: center;width: 50px;">S.No
                                                                        </th>
                                                                        <th style="text-align: center"
                                                                            *ngFor="let col of columns"
                                                                            [pSortableColumn]="col.field">{{ col.header
                                                                            }}
                                                                            <p-sortIcon [field]="col.field"
                                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                            </p-sortIcon>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                        <ng-container
                                                                            *ngFor="let col of columns; let i = index"
                                                                            [ngSwitch]="col.field">
                                                                            <td *ngIf="i == 0"
                                                                                style="text-align: center;width: 50px;">
                                                                                {{ j
                                                                                + 1 }}</td>
                                                                            <td *ngIf="i == 0"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="2">No records found...</td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <!-- <li>
                                            <span style="font-weight: bold;">Team:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button" (click)="teamdisplay=true" pTooltip="View"
                                                    tooltipPosition="right" class="p-button-info" icon="pi pi-eye"
                                                    class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                                <p-dialog header="Team Members" [(visible)]="teamdisplay" modal="modal"
                                                    showEffect="fade" [style]="{width:'60%'}">
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <p-table #dtTeam [value]="CurrentTeam" [columns]="Teamcols"
                                                                sortMode="multiple" [loading]="loading"
                                                                [autoLayout]="true" styleClass="p-datatable-striped"
                                                                [filterDelay]="0" selectionMode="multiple"
                                                                [(selection)]="selectedTeam">
                                                                <ng-template pTemplate="header" let-columns
                                                                    class="invoice-items">
                                                                    <tr>
                                                                        <th style="text-align: center; width:50px;">S.No
                                                                        </th>
                                                                        <th style="text-align:center;"
                                                                            *ngFor="let col of columns"
                                                                            [pSortableColumn]="col.field">{{ col.header
                                                                            }}
                                                                            <p-sortIcon [field]="col.field"
                                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                            </p-sortIcon>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                        <ng-container
                                                                            *ngFor="let col of columns; let i = index"
                                                                            [ngSwitch]="col.field">
                                                                            <td *ngIf="i == 0"
                                                                                style="text-align: center;width: 50px;;">
                                                                                {{
                                                                                j + 1 }}</td>
                                                                            <td *ngIf="i == 0"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 1"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 2"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="4">No records found...</td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </p-dialog>
                                            </span>
                                        </li> -->
                                        <li>
                                            <span style="font-weight: bold;">Approvers:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button" (click)="approvalmembersdisplay=true"
                                                    pTooltip="View" tooltipPosition="right" class="p-button-info"
                                                    icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                                <p-dialog header="Approvers" [(visible)]="approvalmembersdisplay"
                                                    modal="modal" showEffect="fade" [style]="{width:'95%'}">
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-12 p-xl-12">
                                                            <h5 style="text-align:center">{{ Product.productName }} -
                                                                {{CurrentPhase.phaseName}} Approvers</h5>
                                                            <p-table #dtApprovalTeam [value]="CurrentApprovalTeam"
                                                                [columns]="ApprovalTeamcols" sortMode="multiple"
                                                                [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0"
                                                                selectionMode="multiple"
                                                                [(selection)]="selectedApprovalTeam">
                                                                <ng-template pTemplate="header" let-columns
                                                                    class="invoice-items">
                                                                    <tr>
                                                                        <th style="text-align: left;width: 60px;">S.No
                                                                        </th>
                                                                        <th *ngFor="let col of columns;let i = index" style="text-align: left"
                                                                            [pSortableColumn]="col.field"
                                                                            [ngClass]="{'width150': i == 3, 'width180': i == 0 || i == 1 || i == 2 || i==4}">
                                                                            {{ col.header }}<p-sortIcon
                                                                                [field]="col.field"
                                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                            </p-sortIcon>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                        <ng-container
                                                                            *ngFor="let col of columns; let i = index"
                                                                            [ngSwitch]="col.field">
                                                                            <td *ngIf="i == 0"
                                                                                style="width: 60px;padding-left: 10px;">
                                                                                {{ j + 1 }}</td>
                                                                            <td *ngIf="i == 0"
                                                                                >{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 1"
                                                                                >{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 2"
                                                                                >{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 3"
                                                                                >
                                                                                <span
                                                                                    *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                    | date:'MM/dd/yyyy HH:mm'}}</span>
                                                                                <span
                                                                                    *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                            </td>
                                                                            <td *ngIf="i == 4"
                                                                                title="{{rowData.remarks}}">{{
                                                                                rowData[col.field] }}</td>
                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="6">No records found...</td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <h5 style="text-align:center">{{ Product.productName }} -
                                                                {{CurrentPhase.phaseName}} Notification Recipient</h5>
                                                            <p-table #dtNRTeam [value]="CurrentNRTeam"
                                                                [columns]="NRTeamcols" sortMode="multiple"
                                                                [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0"
                                                                selectionMode="multiple">
                                                                <ng-template pTemplate="header" let-columns
                                                                    class="invoice-items">
                                                                    <tr>
                                                                        <th style="text-align: center;width: 60px;">S.No
                                                                        </th>
                                                                        <th *ngFor="let col of columns;let i = index"
                                                                            [pSortableColumn]="col.field">{{ col.header
                                                                            }}
                                                                            <p-sortIcon [field]="col.field"
                                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-columns="columns" let-j="rowIndex">
                                                                    <tr [pSelectableRow]="rowData">
                                                                        <ng-container
                                                                            *ngFor="let col of columns; let i = index"
                                                                            [ngSwitch]="col.field">
                                                                            <td *ngIf="i == 0"
                                                                                style="text-align: center;width: 60px;">
                                                                                {{ j + 1 }}</td>
                                                                            <td *ngIf="i == 0"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 1"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 2"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="4">No records found...</td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <div [innerHTML]="listApprovalMembers"></div>
                                                            <h5 style="text-align:center">Clarification Details</h5>
                                                            <p-table #dtClarification [value]="Clarification"
                                                                [columns]="ClarificationCols" sortMode="multiple"
                                                                [loading]="loading" [autoLayout]="true"
                                                                styleClass="p-datatable-striped" [filterDelay]="0"
                                                                selectionMode="multiple"
                                                                [(selection)]="selectedClarification">
                                                                <ng-template pTemplate="header" let-columns
                                                                    class="invoice-items">
                                                                    <tr>
                                                                        <th style="text-align:center;"
                                                                            *ngFor="let col of columns"
                                                                            [pSortableColumn]="col.field">{{ col.header
                                                                            }}
                                                                            <p-sortIcon [field]="col.field"
                                                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                            </p-sortIcon>
                                                                        </th>
                                                                        <th>Sought To</th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-columns="columns">
                                                                    <tr [pSelectableRow]="rowData">
                                                                        <ng-container
                                                                            *ngFor="let col of columns; let i = index"
                                                                            [ngSwitch]="col.field">
                                                                            <!-- <td *ngIf="i == 0" style="padding-left:15px;">{{
                                                                            rowData[col.field] }}</td> -->
                                                                            <td *ngIf="i == 0"
                                                                                style="text-align: center">{{
                                                                                rowData[col.field] | date:'MM/dd/yyyy
                                                                                HH:mm'
                                                                                }}</td>
                                                                            <td *ngIf="i == 1"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 2"
                                                                                style="text-align: center">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 3"
                                                                                style="text-align: center">
                                                                                <span *ngIf="rowData.answer">{{
                                                                                    rowData[col.field] |
                                                                                    date:'MM/dd/yyyy
                                                                                    HH:mm' }}</span>
                                                                            </td>
                                                                            <td *ngIf="i == 4"
                                                                                style="padding-left:15px;">{{
                                                                                rowData[col.field] }}</td>
                                                                            <td *ngIf="i == 5"
                                                                                style="text-align: center">{{
                                                                                rowData[col.field] }}</td>
                                                                        </ng-container>
                                                                        <td style="text-align: center;">
                                                                            <button pButton type="button"
                                                                                pTooltip="View" tooltipPosition="right"
                                                                                (click)="GetTeamDetails(rowData)"
                                                                                icon="pi pi-eye"
                                                                                class="p-button-raised p-button-primary"
                                                                                style="height:25px; width:25px;"></button>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="7">No records found...</td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </p-dialog>
                                            </span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Notes:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button"
                                                    (click)="Notes('Phase',CurrentPhase.phaseID,CurrentPhase.phaseID,'Phase',CurrentPhase.phaseName,ViewOnly,CurrentPhase.isNotes,CurrentPhase.phaseStatus != 'Ready to Start' && CurrentPhase.phaseStatus != 'In-Process' && CurrentPhase.phaseStatus != 'Ready for Approval')"
                                                    [ngClass]="{'p-button-success': CurrentPhase.isNotes}"
                                                    [disabled]="ViewOnly && !CurrentPhase.isNotes" ptooltip="Notes"
                                                    tooltipposition="right" icon="pi pi-comment"
                                                    style="width:25px;height:25px;"></button>
                                            </span>
                                        </li>
                                        <li *ngIf="IsPrevCRDisplay"> 
                                            <span style="font-weight: bold;">Previous Conditional Release:</span>
                                            <span><button (click)="PrevCR(1)" pButton type="button" pTooltip="View"
                                                    tooltipPosition="right" icon="pi pi-eye"
                                                    class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                            </span>
                                        </li>
                                        <li *ngIf="IsConditionalExist">
                                            <span style="font-weight: bold;">Conditional Release:</span>
                                            <span><button (click)="GetConditionalReleaseDetails(1)" pButton
                                                    type="button" pTooltip="View" tooltipPosition="right"
                                                    icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                    style="height:25px; width:25px;"></button>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-xl-4">
                                <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                    style="min-height:150px;">
                                    <ul class="widget-image-list">
                                        <li>
                                            <span style="font-weight: bold;">Phase Start:</span>
                                            <span>{{ CurrentPhase.phaseStartDate }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Phase End:</span>
                                            <span>{{ CurrentPhase.phaseEndDate }}</span>
                                        </li>
                                        <li>
                                            <span style="font-weight: bold;">Documents:</span>
                                            <span class="listitem-value" style="float:right;">
                                                <button pButton type="button"
                                                    (click)="Documents('Phase',CurrentPhase.phaseID,CurrentPhase.phaseID,'Phase',CurrentPhase.phaseName,'0','',ViewOnly,CurrentPhase.isDocuments,CurrentPhase.phaseStatus != 'Ready to Start' && CurrentPhase.phaseStatus != 'In-Process' && CurrentPhase.phaseStatus != 'Ready for Approval')"
                                                    [ngClass]="{'p-button-success': CurrentPhase.isDocuments}"
                                                    [disabled]="ViewOnly && !CurrentPhase.isDocuments"
                                                    pTooltip="Documents" tooltipPosition="right" icon="pi pi-file"
                                                    style="width:25px;height:25px;"></button>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-4">
                    <p-accordion>
                        <p-accordionTab header="Team" [selected]="true">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-12 p-xl-12">
                                    <p-table #dtTeam [value]="CurrentTeam" [columns]="Teamcols" [scrollable]="true"
                                        scrollHeight="365px" sortMode="multiple" [loading]="loading" [autoLayout]="true"
                                        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                                        [(selection)]="selectedTeam">
                                        <ng-template pTemplate="header" let-columns class="invoice-items">
                                            <tr>
                                                <!-- <th style="text-align: center; width:50px;">S.No
                                            </th> -->
                                                <th style="text-align:center;"
                                                    [ngClass]="{'width33': i == 0 || i == 1 || i == 2}"
                                                    *ngFor="let col of columns" [pSortableColumn]="col.field">{{
                                                    col.header }}
                                                    <p-sortIcon [field]="col.field"
                                                        style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                    </p-sortIcon>
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData let-columns="columns"
                                            let-j="rowIndex">
                                            <tr [pSelectableRow]="rowData">
                                                <ng-container *ngFor="let col of columns; let i = index"
                                                    [ngSwitch]="col.field">
                                                    <!-- <td *ngIf="i == 0"
                                                    style="text-align: center;width: 50px;;">{{
                                                    j + 1 }}</td> -->
                                                    <td *ngIf="i == 0" style="padding-left:15px;width: 33%;">{{
                                                        rowData[col.field] }}</td>
                                                    <td *ngIf="i == 1" style="padding-left:15px;width: 33%;">{{
                                                        rowData[col.field] }}</td>
                                                    <td *ngIf="i == 2" style="padding-left:15px;width: 33%;">{{
                                                        rowData[col.field] }}</td>
                                                </ng-container>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="3">No records found...</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </p-accordionTab></p-accordion>
                </div>
            </div>
            <p-tabView *ngIf="CurrentRequiredDocuments?.length > 0 && ViewOnlyTabs === true" [activeIndex]="index"
                (onChange)="function($event)">
                <p-tabPanel header="Details">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="viewclarifydisplay">
                            <p-table #dtClarify [value]="ViewClarify" [columns]="ClarificationCols1" sortMode="multiple"
                                [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                selectionMode="multiple" [(selection)]="selectedViewClarify">
                                <ng-template pTemplate="caption">
                                    <div>Clarification Details</div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr>
                                        <th style="text-align:center;" [ngClass]="{'width300': i == 1}"
                                            *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">{{
                                            col.header }}<p-sortIcon [field]="col.field"
                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                                        </th>
                                        <th>Sought To</th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr class="p-selectable-row">
                                        <td style="text-align: center;"><span>{{rowData.askedDate | date:'MM/dd/yyyy
                                                HH:mm'}}</span></td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <span>{{rowData.question}}</span>
                                        </td>
                                        <td style="text-align:left; padding-left:15px;">
                                            <span>{{rowData.askedByName}}</span>
                                        </td>
                                        <td style="text-align: center;"><span
                                                *ngIf="rowData.answer">{{rowData.answerDate | date:'MM/dd/yyyy
                                                HH:mm'}}</span></td>
                                        <!-- <td style="text-align:left; padding-left:15px;"><span>{{rowData.answer}}</span></td> -->
                                        <td style="text-align:left; padding-left:15px;">
                                            <span>{{rowData.answerByName}}</span>
                                        </td>
                                        <td style="text-align: center;">
                                            <button pButton type="button" pTooltip="View" tooltipPosition="right"
                                                (click)="GetTeamDetails(rowData)" icon="pi pi-eye"
                                                class="p-button-raised p-button-primary"
                                                style="height:25px; width:25px;"></button>
                                        </td>
                                        <td style="text-align: center">
                                            <button pButton type="button" *ngIf="!rowData.answer && rowData.active"
                                                label="Reply"
                                                (click)="Response(rowData.clarificationID,rowData.question,rowData.askedByName)"
                                                icon="pi pi-answer" iconPos="right"
                                                class="p-button-outlined p-button-seconday p-mr-2 p-mb-2"></button>
                                            <button pButton type="button" *ngIf="rowData.answer" label="View Response"
                                                (click)="ViewResponse(rowData.clarificationID,rowData.question,rowData.askedByName,rowData.answer)"
                                                icon="pi pi-answer" iconPos="right"
                                                class="p-button-outlined p-button-seconday p-mr-2 p-mb-2"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="6">No records found...</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="card" *ngIf="addclar1">
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12">
                                        <h5 style="text-align:left;">{{askedByName}}: {{Question}}</h5>
                                        <textarea rows="6" pInputTextarea id="popupquestion" [(ngModel)]="popupquestion"
                                            placeholder="Enter response" required="true"
                                            requiredMessage="please enter response." maxlength="1000" ng-trim="false"
                                            style="width: 100%;"></textarea>
                                        <small class="p-invalid">{{1000 - popupquestion.length}} characters are
                                            remaining</small>
                                        <br>
                                        <small class="p-invalid">{{ QuestionError }}</small>
                                    </div>
                                </div>
                                <div class="p-grid p-fluid" *ngIf="QButton">
                                    <div class="p-col-12 p-md-9"> </div>
                                    <div class="p-col-12 p-md-3">
                                        <button pButton type="button" (click)="AddAnswer()" label="Continue"
                                            pTooltip="Continue" tooltipPosition="right" icon="pi pi-plus"
                                            style="width:100px;height:25px;"
                                            class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                                        <button pButton type="button" (click)="QuestionClear()" label="Reset"
                                            pTooltip="Reset" tooltipPosition="right" icon="pi pi-refresh"
                                            style="width:100px;height:25px;"
                                            class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="CurrentRequiredDocuments?.length > 0">
                            <div class="invoice invoice-header" id="invoice-content">
                                <p-table #dtDocs [value]="requestData" [columns]="documents" sortMode="multiple"
                                    [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                                    [scrollable]="true" scrollHeight="300px" selectionMode="multiple"
                                    [(selection)]="selectedDocs" dataKey="reqDocumentID" [autoLayout]="true">
                                    <ng-template pTemplate="caption">
                                        <div>
                                            <table width="100%">
                                                <tr>
                                                    <td width="20%" align="left">Tasks - {{TasksCount}}</td>
                                                    <td width="35%" align="center">
                                                        Tasks Required:&nbsp;
                                                        <button pButton pRipple type="button" label="Yes"
                                                            class="{{yesbtnlabel}}" (click)="geTasksfilter('Yes')"
                                                            style="width: 50px; height:25px;"></button>&nbsp;
                                                        <button pButton pRipple type="button" label="No"
                                                            class="{{nobtnlabel}}" (click)="geTasksfilter('No')"
                                                            style="width: 50px; height:25px;"></button>&nbsp;
                                                        <button pButton pRipple type="button" label="All"
                                                            class="{{allbtnlabel}}" (click)="geTasksfilter('All')"
                                                            style="width: 50px; height:25px;"></button>
                                                    </td>
                                                    <td width="45%" align="center">
                                                        Tasks Status:&nbsp;
                                                        <button pButton pRipple type="button" label="Pending"
                                                            class="{{PendingClass}}" (click)="geCompletedfilter('P')"
                                                            style="width: 90px; height:25px;"></button>&nbsp;
                                                        <button pButton pRipple type="button" label="Completed"
                                                            class="{{CompletedClass}}" (click)="geCompletedfilter('D')"
                                                            style="width: 100px; height:25px;"></button>&nbsp;
                                                        <button pButton pRipple type="button" label="All"
                                                            class="{{Allclass}}" (click)="geCompletedfilter('All')"
                                                            style="width: 50px; height:25px;"></button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header" let-columns class="invoice-items">
                                        <tr>
                                            <th *ngFor="let col of columns;let i = index"
                                                style="text-align:center;padding-left:2%"
                                                 [style.width]="col.width"
                                                 [pSortableColumn]="col.field">{{
                                                col.header }}<p-sortIcon [field]="col.field"
                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align:center;width:8%;">Documents</th>
                                            <th style="text-align:center;width:7%;">Notes</th>
                                        </tr>
                                        <tr>
                                            <th *ngFor="let col of columns ; let i=index " [ngSwitch]="col.field"
                                                [style.width]="col.width"
                                                style="text-align: left;padding-left:2%;">
                                                <input pInputText type="text"
                                                    (input)="test($event.target.value,col.field,dtDocs)"
                                                    placeholder="Search" style="width: 95%; height:25px;" />
                                            </th>
                                            <th colspan="2">
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData>
                                        <tr class="p-selectable-row"
                                            [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                            <td style="text-align:left; width: 15%;padding-left:2%;">
                                                <span>{{rowData.departmentName}}</span>
                                            </td>
                                            <td style="text-align:left;width: 15%;padding-left:2%;">
                                                <span>{{rowData.roleName}}</span>
                                            </td>
                                            <td style="text-align:left;padding-left:2%; width: 30%;">
                                                <span *ngIf="rowData.isMilestoneRelated">
                                                    <a href="javascript:;" (click)="PRJToken(rowData.taskId)" class="btn-link">
                                                        <span style="font-weight: bold;color:red;"><u>{{rowData.documentName}}</u></span>
                                                    </a>
                                                </span>                                                
                                                <span *ngIf="!rowData.isMilestoneRelated">{{rowData.documentName}}
                                                </span>
                                            </td>
                                            <td style="text-align:center;width:15%;">
                                                <span *ngIf="rowData.isMilestoneRelated && (rowData.taskStatus=='New' || rowData.taskStatus=='InProgress' || rowData.taskStatus=='Completed')"
                                                 [title]="rowData.taskStatus">
                                                    {{rowData.targetEndDate}}
                                                </span>
                                                <span *ngIf="(rowData.isMilestoneRelated &&  (rowData.taskStatus=='NotRequired' || rowData.taskStatus=='None'))"
                                                [title]="rowData.taskStatus">
                                                --
                                                </span> 
                                                <span *ngIf="!rowData.isMilestoneRelated"></span>                                                
                                            </td>
                                            <td style="text-align:center;width:15%;" [ngStyle]="{'color': (rowData.taskStatus=='New' || rowData.taskStatus=='InProgress') ? (rowData.startTaskDays > 0  ? 'red' :  '') : (rowData.taskStatus=='Completed' ? (rowData.startTaskDays > 0  ? 'red' :  'green') : '')
                                                , 'font-weight' : (rowData.taskStatus=='Completed' ? 'bold' :  '')}">
                                                <span *ngIf="rowData.isMilestoneRelated && (rowData.taskStatus=='New' || rowData.taskStatus=='InProgress' || rowData.taskStatus=='Completed') "
                                                [title]="rowData.taskStatus">
                                                    {{rowData.startDate}}
                                                </span>
                                                <span *ngIf="(rowData.isMilestoneRelated &&  (rowData.taskStatus=='NotRequired' || rowData.taskStatus=='None'))"
                                                [title]="rowData.taskStatus">
                                                --
                                                </span>                                                                                                                                               
                                                <!-- <span *ngIf="rowData.isMilestoneRelated">{{rowData.endDate}}</span> -->
                                                <span *ngIf="!rowData.isMilestoneRelated"></span>
                                            </td>
                                            <td style="text-align:center;width:15%;" [ngStyle]="{'color': (rowData.taskStatus=='New' || rowData.taskStatus=='InProgress') ? (rowData.endTaskDays > 0  ? 'red' :  '') : (rowData.taskStatus=='Completed' ? (rowData.endTaskDays > 0  ? 'red' :  'green') : '')
                                            , 'font-weight' : (rowData.taskStatus=='Completed' ? 'bold' :  '')}">
                                                <span *ngIf="rowData.isMilestoneRelated && (rowData.taskStatus=='New' || rowData.taskStatus=='InProgress' || rowData.taskStatus=='Completed') "
                                                [title]="rowData.taskStatus">
                                                    {{rowData.endDate}}
                                                </span>
                                                <span *ngIf="(rowData.isMilestoneRelated &&  (rowData.taskStatus=='NotRequired' || rowData.taskStatus=='None'))"
                                                [title]="rowData.taskStatus">
                                                --
                                                </span>                                                                                                                                               
                                                <!-- <span *ngIf="rowData.isMilestoneRelated">{{rowData.endDate}}</span> -->
                                                <span *ngIf="!rowData.isMilestoneRelated"></span>
                                            </td>
                                            <td style="text-align:left;width:15%;padding-left:2%;">
                                                <span>{{rowData.documentLabels
                                                    }}</span>
                                            </td>
                                            <td style="text-align:center;width: 15%;padding-left:1%;">
                                                <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                    <li class="templi"><span
                                                            *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                href="javascript:;"
                                                                (click)="DMSToken(temp)">{{temp.templateName}} </a>
                                                        </span>
                                                        <span
                                                            *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="text-align: center;width: 8%;">
                                                <span class="listitem-value">
                                                    <button pButton type="button"
                                                        (click)="Documents('phasedocument',rowData.phaseID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly,rowData.isDocuments,CurrentPhase.phaseStatus != 'Ready to Start' && CurrentPhase.phaseStatus != 'In-Process' && CurrentPhase.phaseStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                        [disabled]="ViewOnly && !rowData.isDocuments"
                                                        ptooltip="Documents" tooltipposition="right" icon="pi pi-file"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </td>
                                            <td style="text-align: center;width: 7%;">
                                                <span class="listitem-value">
                                                    <button pButton type="button"
                                                        (click)="Notes('phasedocument',rowData.phaseID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly,rowData.isNotes,CurrentPhase.phaseStatus != 'Ready to Start' && CurrentPhase.phaseStatus != 'In-Process' && CurrentPhase.phaseStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': rowData.isNotes}"
                                                        [disabled]="ViewOnly && !rowData.isNotes" pTooltip="Notes"
                                                        tooltipPosition="right" icon="pi pi-comment"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="8">No records found...</td>
                                        </tr>
                                    </ng-template>

                                </p-table>
                            </div>
                        </div>

                    </div>
                </p-tabPanel>
                <p-tabPanel header="Step" leftIcon="pi pi-compass"
                    *ngIf="Milestones?.length > 0 && ViewOnlyTabs === true" iconPos="left">
                    <p-accordion [activeIndex]="milestoneindex" (onOpen)="function1($event)">
                        <p-accordionTab header="{{ Milestone1.listName }}" *ngIf="Milestones?.length > 0"
                            [selected]="true">
                            <ng-template pTemplate="header"><span *ngIf="Milestone1.active == 'N'" style="color: red;">
                                    (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                    <span class="p-input-icon-left" style="float:right;">
                                        <button pButton pRipple class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone1.active == 'N'"
                                            *ngIf="Milestone1.isSendForApproval"
                                            (click)="SendApproval('List',Milestone1.listID)" pButton pRipple
                                            type="button" label="Send for Approval"
                                            style="width:150px;height: 25px;"></button>
                                        <button pButton pRipple class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone1.active == 'N'"
                                            *ngIf="Milestone1.isReminder" (click)="Reminder('List',Milestone1.listID)"
                                            pButton pRipple type="button" label="Approval Reminder"
                                            style="width:160px;height: 25px;"></button>
                                        <button pButton pRipple class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone1.active == 'N'"
                                            *ngIf="Milestone1.isClarify"
                                            (click)="Clarify('List',Milestone1.listID,'Step',Milestone1.listName)"
                                            pButton pRipple type="button" label="Respond"
                                            style="width:80px;height: 25px;"></button>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Name:</span>
                                                <span>{{ Milestone1.listName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Status:</span>
                                                <span>{{ Milestone1.listStatus }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Owner:</span>
                                                <span>{{ Milestone1.listOwner }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Responsible Departments:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" pTooltip="View"
                                                        tooltipPosition="right" (click)="mile1departmentsdisplay=true"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Responsible Departments"
                                                        [(visible)]="mile1departmentsdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'25%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtMile1Departments
                                                                    [value]="Milestone1Departments"
                                                                    [columns]="Departmentscols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile1Departments">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th style="text-align: center"
                                                                                *ngFor="let col of columns"
                                                                                [pSortableColumn]="col.field">{{
                                                                                col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="2">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approval Required:</span>
                                                <span style="padding-right:10px;">{{ Milestone1.approval }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approvers:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="Milestone1.approval=='N'"
                                                        pTooltip="View" tooltipPosition="right"
                                                        (click)="mile1approvalmembersdisplay=true" class="p-button-info"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Approvers"
                                                        [(visible)]="mile1approvalmembersdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'80%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <h5 style="text-align:center">{{ Milestone1.listName }}
                                                                    Approvers</h5>
                                                                <p-table #dtMile1ApprovalTeam
                                                                    [value]="Mile1ApprovalTeam"
                                                                    [columns]="ApprovalTeamcols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile1ApprovalTeam">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                [pSortableColumn]="col.field"
                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                {{ col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4"
                                                                                    style="text-align: center"><span
                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                        | date:'MM/dd/yyyy
                                                                                        HH:mm'}}</span>
                                                                                    <span
                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                </td>
                                                                                <td *ngIf="i == 5"
                                                                                    style="padding-left:15px;"
                                                                                    title="{{rowData.remarks}}">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="7">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Documents('List',Milestone1.phaseID,Milestone1.listID,'Step',Milestone1.listName,'0','',ViewOnly || Milestone1.active == 'N',Milestone1.isDocuments,Milestone1.listStatus != 'In-Process' && Milestone1.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone1.isDocuments}"
                                                        [disabled]="ViewOnly && !Milestone1.isDocuments"
                                                        pTooltip="Documents" tooltipPosition="right" icon="pi pi-file"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Notes('List',Milestone1.phaseID,Milestone1.listID,'Step',Milestone1.listName,ViewOnly || Milestone1.active == 'N',Milestone1.isNotes,Milestone1.listStatus != 'In-Process' && Milestone1.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone1.isNotes}"
                                                        [disabled]="ViewOnly && !Milestone1.isNotes" pTooltip="Notes"
                                                        tooltipPosition="right" icon="pi pi-comment"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile1Documents?.length > 0"
                                    style="margin-top: -1.5rem;">
                                    <div class="invoice invoice-header" id="invoice-content">
                                        <p-table #dtMile1Docs [value]="Mile1Documents" [columns]="documents"
                                            sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped"
                                            [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedMile1Docs"
                                            dataKey="reqDocumentID" [autoLayout]="true">
                                            <ng-template pTemplate="caption">
                                                <div>Tasks
                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float: right; margin-left:15px; width:25px; height:25px; margin-top: -4px;" (click)="Docsdownload('mile1')"></button>
                                            </span> -->
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                <tr>
                                                    <th style="text-align: center"
                                                        *ngFor="let col of columns;let i = index"
                                                        [pSortableColumn]="col.field"
                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                        col.header }}<p-sortIcon [field]="col.field"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th style="text-align: center">Documents</th>
                                                    <th style="text-align: center">Notes</th>
                                                </tr>
                                                <tr>
                                                    <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                        style="text-align: center">
                                                        <input pInputText type="text"
                                                            (input)="dtMile1Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                            placeholder="Search"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr class="p-selectable-row"
                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.departmentName}}</span>
                                                    </td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.documentName}}</span>
                                                    </td>
                                                    <td style="text-align: center;"><span>{{ rowData.isRequired }}
                                                        </span></td>
                                                    <td style="text-align: center;"><span>{{rowData.documentLabels
                                                            }}</span></td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                            <li class="templi"><span
                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                        href="javascript:;"
                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                    </a> </span>
                                                                <span
                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Milestone1.active == 'N',rowData.isDocuments,Milestone1.listStatus != 'In-Process' && Milestone1.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Milestone1.active == 'N',rowData.isNotes,Milestone1.listStatus != 'In-Process' && Milestone1.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7">No records found...</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone2.listName }}" *ngIf="Milestones?.length > 1">
                            <ng-template pTemplate="header"><span *ngIf="Milestone2.active == 'N'" style="color: red;">
                                    (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                    <span class="p-input-icon-left" style="float:right;">
                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone2.active == 'N'"
                                            *ngIf="Milestone2.isSendForApproval"
                                            (click)="SendApproval('List',Milestone2.listID)" pButton pRipple
                                            type="button" label="Send for Approval"
                                            style="width:150px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone2.active == 'N'"
                                            *ngIf="Milestone2.isReminder" (click)="Reminder('List',Milestone2.listID)"
                                            pButton pRipple type="button" label="Approval Reminder"
                                            style="width:160px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone2.active == 'N'"
                                            *ngIf="Milestone2.isClarify"
                                            (click)="Clarify('List',Milestone2.listID,'Step',Milestone2.listName)"
                                            pButton pRipple type="button" label="Respond"
                                            style="width:80px;height: 25px;"></button>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Name:</span>
                                                <span>{{ Milestone2.listName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Status:</span>
                                                <span>{{ Milestone2.listStatus }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Owner:</span>
                                                <span>{{ Milestone2.listOwner }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Responsible Departments:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" pTooltip="View"
                                                        tooltipPosition="right" (click)="mile2departmentsdisplay=true"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Responsible Departments"
                                                        [(visible)]="mile2departmentsdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'25%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtMile2Departments
                                                                    [value]="Milestone2Departments"
                                                                    [columns]="Departmentscols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile2Departments">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th style="text-align: center"
                                                                                *ngFor="let col of columns"
                                                                                [pSortableColumn]="col.field">{{
                                                                                col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="2">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approval Required:</span>
                                                <span style="padding-right:10px;">{{ Milestone2.approval }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approvers:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="Milestone2.approval=='N'"
                                                        pTooltip="View" tooltipPosition="right"
                                                        (click)="mile2approvalmembersdisplay=true" class="p-button-info"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Approvers"
                                                        [(visible)]="mile2approvalmembersdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'80%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <h5 style="text-align:center">{{ Milestone2.listName }}
                                                                    Approvers</h5>
                                                                <p-table #dtMile2ApprovalTeam
                                                                    [value]="Mile2ApprovalTeam"
                                                                    [columns]="ApprovalTeamcols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile2ApprovalTeam">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                [pSortableColumn]="col.field"
                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                {{ col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4"
                                                                                    style="text-align: center"><span
                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                        | date:'MM/dd/yyyy
                                                                                        HH:mm'}}</span>
                                                                                    <span
                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                </td>
                                                                                <td *ngIf="i == 5"
                                                                                    style="padding-left:15px;"
                                                                                    title="{{rowData.remarks}}">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="7">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Documents('List',Milestone2.phaseID,Milestone2.listID,'Step',Milestone2.listName,'0','',ViewOnly || Milestone2.active == 'N',Milestone2.isDocuments,Milestone2.listStatus != 'In-Process' && Milestone2.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone2.isDocuments}"
                                                        [disabled]="ViewOnly && !Milestone2.isDocuments"
                                                        pTooltip="Documents" tooltipPosition="right" icon="pi pi-file"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Notes('List',Milestone2.phaseID,Milestone2.listID,'Step',Milestone2.listName,ViewOnly || Milestone2.active == 'N',Milestone2.isNotes,Milestone2.listStatus != 'In-Process' && Milestone2.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone2.isNotes}"
                                                        [disabled]="ViewOnly && !Milestone2.isNotes" pTooltip="Notes"
                                                        tooltipPosition="right" icon="pi pi-comment"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile2Documents?.length > 0"
                                    style="margin-top: -1.5rem;">
                                    <div class="invoice invoice-header" id="invoice-content">
                                        <p-table #dtMile2Docs [value]="Mile2Documents" [columns]="documents"
                                            sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped"
                                            [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedMile2Docs"
                                            dataKey="reqDocumentID" [autoLayout]="true">
                                            <ng-template pTemplate="caption">
                                                <div>Tasks
                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float: right; margin-left:15px; width:25px; height:25px; margin-top: -4px;" (click)="Docsdownload('mile2')"></button>
                                            </span> -->
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                <tr>
                                                    <th style="text-align: center"
                                                        *ngFor="let col of columns;let i = index"
                                                        [pSortableColumn]="col.field"
                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                        col.header }}<p-sortIcon [field]="col.field"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th style="text-align: center">Documents</th>
                                                    <th style="text-align: center">Notes</th>
                                                </tr>
                                                <tr>
                                                    <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                        style="text-align: center">
                                                        <input pInputText type="text"
                                                            (input)="dtMile2Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                            placeholder="Search"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr class="p-selectable-row"
                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.departmentName}}</span>
                                                    </td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.documentName}}</span>
                                                    </td>
                                                    <td style="text-align: center;"><span>{{ rowData.isRequired }}
                                                        </span></td>
                                                    <td style="text-align: center;"><span>{{rowData.documentLabels
                                                            }}</span></td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                            <li class="templi"><span
                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                        href="javascript:;"
                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                    </a> </span>
                                                                <span
                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Milestone2.active == 'N',rowData.isDocuments,Milestone2.listStatus != 'In-Process' && Milestone2.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Milestone2.active == 'N',rowData.isNotes,Milestone2.listStatus != 'In-Process' && Milestone2.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7">No records found...</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone3.listName }}" *ngIf="Milestones?.length > 2">
                            <ng-template pTemplate="header"><span *ngIf="Milestone3.active == 'N'" style="color: red;">
                                    (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                    <span class="p-input-icon-left" style="float:right;">
                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone3.active == 'N'"
                                            *ngIf="Milestone3.isSendForApproval"
                                            (click)="SendApproval('List',Milestone3.listID)" pButton pRipple
                                            type="button" label="Send for Approval"
                                            style="width:150px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone3.active == 'N'"
                                            *ngIf="Milestone3.isReminder" (click)="Reminder('List',Milestone3.listID)"
                                            pButton pRipple type="button" label="Approval Reminder"
                                            style="width:160px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone3.active == 'N'"
                                            *ngIf="Milestone3.isClarify"
                                            (click)="Clarify('List',Milestone3.listID,'Step',Milestone3.listName)"
                                            pButton pRipple type="button" label="Respond"
                                            style="width:80px;height: 25px;"></button>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Name:</span>
                                                <span>{{ Milestone3.listName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Status:</span>
                                                <span>{{ Milestone3.listStatus }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Owner:</span>
                                                <span>{{ Milestone3.listOwner }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Responsible Departments:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" pTooltip="View"
                                                        tooltipPosition="right" (click)="mile3departmentsdisplay=true"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Responsible Departments"
                                                        [(visible)]="mile3departmentsdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'25%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtMile3Departments
                                                                    [value]="Milestone3Departments"
                                                                    [columns]="Departmentscols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile3Departments">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th style="text-align: center"
                                                                                *ngFor="let col of columns"
                                                                                [pSortableColumn]="col.field">{{
                                                                                col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="2">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approval Required:</span>
                                                <span style="padding-right:10px;">{{ Milestone3.approval }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approvers:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="Milestone3.approval=='N'"
                                                        pTooltip="View" tooltipPosition="right"
                                                        (click)="mile3approvalmembersdisplay=true" class="p-button-info"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Approvers"
                                                        [(visible)]="mile3approvalmembersdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'80%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <h5 style="text-align:center">{{ Milestone3.listName }}
                                                                    Approvers</h5>
                                                                <p-table #dtMile3ApprovalTeam
                                                                    [value]="Mile3ApprovalTeam"
                                                                    [columns]="ApprovalTeamcols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile3ApprovalTeam">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                [pSortableColumn]="col.field"
                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                {{ col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4"
                                                                                    style="text-align: center"><span
                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                        | date:'MM/dd/yyyy
                                                                                        HH:mm'}}</span>
                                                                                    <span
                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                </td>
                                                                                <td *ngIf="i == 5"
                                                                                    style="padding-left:15px;"
                                                                                    title="{{rowData.remarks}}">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="7">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Documents('List',Milestone3.phaseID,Milestone3.listID,'Step',Milestone3.listName,'0','',ViewOnly || Milestone3.active == 'N',Milestone3.isDocuments,Milestone3.listStatus != 'In-Process' && Milestone3.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone3.isDocuments}"
                                                        [disabled]="ViewOnly && !Milestone3.isDocuments"
                                                        pTooltip="Documents" tooltipPosition="right" icon="pi pi-file"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Notes('List',Milestone3.phaseID,Milestone3.listID,'Step',Milestone3.listName,ViewOnly || Milestone3.active == 'N',Milestone3.isNotes,Milestone3.listStatus != 'In-Process' && Milestone3.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone3.isNotes}"
                                                        [disabled]="ViewOnly && !Milestone3.isNotes" pTooltip="Notes"
                                                        tooltipPosition="right" icon="pi pi-comment"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile3Documents?.length > 0"
                                    style="margin-top: -1.5rem;">
                                    <div class="invoice invoice-header" id="invoice-content">
                                        <p-table #dtMile3Docs [value]="Mile3Documents" [columns]="documents"
                                            sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped"
                                            [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedMile3Docs"
                                            dataKey="reqDocumentID" [autoLayout]="true">
                                            <ng-template pTemplate="caption">
                                                <div>Tasks
                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('mile3')"></button>
                                            </span> -->
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                <tr>
                                                    <th style="text-align: center"
                                                        *ngFor="let col of columns;let i = index"
                                                        [pSortableColumn]="col.field"
                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                        col.header }}<p-sortIcon [field]="col.field"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th style="text-align: center">Documents</th>
                                                    <th style="text-align: center">Notes</th>
                                                </tr>
                                                <tr>
                                                    <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                        style="text-align: center">
                                                        <input pInputText type="text"
                                                            (input)="dtMile3Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                            placeholder="Search"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr class="p-selectable-row"
                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.departmentName}}</span>
                                                    </td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.documentName}}</span>
                                                    </td>
                                                    <td style="text-align: center;"><span>{{ rowData.isRequired }}
                                                        </span></td>
                                                    <td style="text-align: center;"><span>{{rowData.documentLabels
                                                            }}</span></td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                            <li class="templi"><span
                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                        href="javascript:;"
                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                    </a> </span>
                                                                <span
                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Milestone3.active == 'N',rowData.isDocuments,Milestone3.listStatus != 'In-Process' && Milestone3.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Milestone3.active == 'N',rowData.isNotes,Milestone3.listStatus != 'In-Process' && Milestone3.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7">No records found...</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone4.listName }}" *ngIf="Milestones?.length > 3">
                            <ng-template pTemplate="header"><span *ngIf="Milestone4.active == 'N'" style="color: red;">
                                    (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                    <span class="p-input-icon-left" style="float:right;">
                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone4.active == 'N'"
                                            *ngIf="Milestone4.isSendForApproval"
                                            (click)="SendApproval('List',Milestone4.listID)" pButton pRipple
                                            type="button" label="Send for Approval"
                                            style="width:150px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone4.active == 'N'"
                                            *ngIf="Milestone4.isReminder" (click)="Reminder('List',Milestone4.listID)"
                                            pButton pRipple type="button" label="Approval Reminder"
                                            style="width:160px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone4.active == 'N'"
                                            *ngIf="Milestone4.isClarify"
                                            (click)="Clarify('List',Milestone4.listID,'Step',Milestone4.listName)"
                                            pButton pRipple type="button" label="Respond"
                                            style="width:80px;height: 25px;"></button>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Name:</span>
                                                <span>{{ Milestone4.listName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Status:</span>
                                                <span>{{ Milestone4.listStatus }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Owner:</span>
                                                <span>{{ Milestone4.listOwner }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Responsible Departments:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" pTooltip="View"
                                                        tooltipPosition="right" (click)="mile4departmentsdisplay=true"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Responsible Departments"
                                                        [(visible)]="mile4departmentsdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'25%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtMile4Departments
                                                                    [value]="Milestone4Departments"
                                                                    [columns]="Departmentscols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile4Departments">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th style="text-align: center"
                                                                                *ngFor="let col of columns"
                                                                                [pSortableColumn]="col.field">{{
                                                                                col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="2">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approval Required:</span>
                                                <span style="padding-right:10px;">{{ Milestone4.approval }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approvers:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="Milestone4.approval=='N'"
                                                        pTooltip="View" tooltipPosition="right"
                                                        (click)="mile4approvalmembersdisplay=true" class="p-button-info"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Approvers"
                                                        [(visible)]="mile4approvalmembersdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'80%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <h5 style="text-align:center">{{ Milestone4.listName }}
                                                                    Approvers</h5>
                                                                <p-table #dtMile4ApprovalTeam
                                                                    [value]="Mile4ApprovalTeam"
                                                                    [columns]="ApprovalTeamcols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile4ApprovalTeam">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                [pSortableColumn]="col.field"
                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                {{ col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4"
                                                                                    style="text-align: center"><span
                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                        | date:'MM/dd/yyyy
                                                                                        HH:mm'}}</span>
                                                                                    <span
                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                </td>
                                                                                <td *ngIf="i == 5"
                                                                                    style="padding-left:15px;"
                                                                                    title="{{rowData.remarks}}">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="7">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Documents('List',Milestone4.phaseID,Milestone4.listID,'Step',Milestone4.listName,'0','',ViewOnly || Milestone4.active == 'N',Milestone4.isDocuments,Milestone4.listStatus != 'In-Process' && Milestone4.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone4.isDocuments}"
                                                        [disabled]="ViewOnly && !Milestone4.isDocuments"
                                                        pTooltip="Documents" tooltipPosition="right" icon="pi pi-file"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Notes('List',Milestone4.phaseID,Milestone4.listID,'Step',Milestone4.listName,ViewOnly || Milestone4.active == 'N',Milestone4.isNotes,Milestone4.listStatus != 'In-Process' && Milestone4.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone4.isNotes}"
                                                        [disabled]="ViewOnly && !Milestone4.isNotes" pTooltip="Notes"
                                                        tooltipPosition="right" icon="pi pi-comment"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile4Documents?.length > 0"
                                    style="margin-top: -1.5rem;">
                                    <div class="invoice invoice-header" id="invoice-content">
                                        <p-table #dtMile4Docs [value]="Mile4Documents" [columns]="documents"
                                            sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped"
                                            [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedMile4Docs"
                                            dataKey="reqDocumentID" [autoLayout]="true">
                                            <ng-template pTemplate="caption">
                                                <div>Tasks
                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('mile4')"></button>
                                            </span> -->
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                <tr>
                                                    <th style="text-align: center"
                                                        *ngFor="let col of columns;let i = index"
                                                        [pSortableColumn]="col.field"
                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                        col.header }}<p-sortIcon [field]="col.field"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th style="text-align: center">Documents</th>
                                                    <th style="text-align: center">Notes</th>
                                                </tr>
                                                <tr>
                                                    <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                        style="text-align: center">
                                                        <input pInputText type="text"
                                                            (input)="dtMile4Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                            placeholder="Search"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr class="p-selectable-row"
                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.departmentName}}</span>
                                                    </td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.documentName}}</span>
                                                    </td>
                                                    <td style="text-align: center;"><span>{{ rowData.isRequired }}
                                                        </span></td>
                                                    <td style="text-align: center;"><span>{{rowData.documentLabels
                                                            }}</span></td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                            <li class="templi"><span
                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                        href="javascript:;"
                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                    </a> </span>
                                                                <span
                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Milestone4.active == 'N',rowData.isDocuments,Milestone4.listStatus != 'In-Process' && Milestone4.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Milestone4.active == 'N',rowData.isNotes,Milestone4.listStatus != 'In-Process' && Milestone4.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7">No records found...</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Milestone5.listName }}" *ngIf="Milestones?.length > 4">
                            <ng-template pTemplate="header"><span *ngIf="Milestone5.active == 'N'" style="color: red;">
                                    (Not yet ready to start) </span></ng-template>
                            <div class="p-grid">
                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                    <span class="p-input-icon-left" style="float:right;">
                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone5.active == 'N'"
                                            *ngIf="Milestone5.isSendForApproval"
                                            (click)="SendApproval('List',Milestone5.listID)" pButton pRipple
                                            type="button" label="Send for Approval"
                                            style="width:150px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone5.active == 'N'"
                                            *ngIf="Milestone5.isReminder" (click)="Reminder('List',Milestone5.listID)"
                                            pButton pRipple type="button" label="Approval Reminder"
                                            style="width:160px;height: 25px;"></button>
                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                            [disabled]="ViewOnly || Milestone5.active == 'N'"
                                            *ngIf="Milestone5.isClarify"
                                            (click)="Clarify('List',Milestone5.listID,'Step',Milestone5.listName)"
                                            pButton pRipple type="button" label="Respond"
                                            style="width:80px;height: 25px;"></button>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Name:</span>
                                                <span>{{ Milestone5.listName }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Status:</span>
                                                <span>{{ Milestone5.listStatus }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Owner:</span>
                                                <span>{{ Milestone5.listOwner }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Responsible Departments:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" (click)="mile5departmentsdisplay=true"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Responsible Departments"
                                                        [(visible)]="mile5departmentsdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'25%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <p-table #dtMile5Departments
                                                                    [value]="Milestone5Departments"
                                                                    [columns]="Departmentscols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile5Departments">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th style="text-align: center"
                                                                                *ngFor="let col of columns"
                                                                                [pSortableColumn]="col.field">{{
                                                                                col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="2">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approval Required:</span>
                                                <span style="padding-right:10px;">{{ Milestone5.approval }}</span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Approvers:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button" [disabled]="Milestone5.approval=='N'"
                                                        pTooltip="View" tooltipPosition="right"
                                                        (click)="mile5approvalmembersdisplay=true" class="p-button-info"
                                                        icon="pi pi-eye" class="p-button-raised p-button-primary"
                                                        style="height:25px; width:25px;"></button>
                                                    <p-dialog header="Approvers"
                                                        [(visible)]="mile5approvalmembersdisplay" modal="modal"
                                                        showEffect="fade" [style]="{width:'80%'}">
                                                        <hr>
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                <h5 style="text-align:center">{{ Milestone5.listName }}
                                                                    Approvers</h5>
                                                                <p-table #dtMile5ApprovalTeam
                                                                    [value]="Mile5ApprovalTeam"
                                                                    [columns]="ApprovalTeamcols" sortMode="multiple"
                                                                    [loading]="loading" styleClass="p-datatable-striped"
                                                                    [filterDelay]="0" selectionMode="multiple"
                                                                    [(selection)]="selectMile5ApprovalTeam">
                                                                    <ng-template pTemplate="header" let-columns
                                                                        class="invoice-items">
                                                                        <tr>
                                                                            <th style="text-align: center;width: 60px;">
                                                                                S.No</th>
                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                [pSortableColumn]="col.field"
                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                {{ col.header }}<p-sortIcon
                                                                                    [field]="col.field"
                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                </p-sortIcon>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-columns="columns" let-j="rowIndex">
                                                                        <tr [pSelectableRow]="rowData">
                                                                            <ng-container
                                                                                *ngFor="let col of columns; let i = index"
                                                                                [ngSwitch]="col.field">
                                                                                <td *ngIf="i == 0"
                                                                                    style="text-align: center;width: 60px;">
                                                                                    {{ j + 1 }}</td>
                                                                                <td *ngIf="i == 0"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 1"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 2"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 3"
                                                                                    style="padding-left:15px;">{{
                                                                                    rowData[col.field] }}</td>
                                                                                <td *ngIf="i == 4"
                                                                                    style="text-align: center"><span
                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                        | date:'MM/dd/yyyy
                                                                                        HH:mm'}}</span>
                                                                                    <span
                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                </td>
                                                                                <td *ngIf="i == 5"
                                                                                    style="padding-left:15px;"
                                                                                    title="{{rowData.remarks}}">{{
                                                                                    rowData[col.field] }}</td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="7">No records found...</td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-dialog>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-4 p-xl-4">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                        style="min-height:150px;">
                                        <ul class="widget-image-list">
                                            <li>
                                                <span style="font-weight: bold;">Documents:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Documents('List',Milestone5.phaseID,Milestone5.listID,'Step',Milestone5.listName,'0','',ViewOnly || Milestone5.active == 'N',Milestone5.isDocuments,Milestone5.listStatus != 'In-Process' && Milestone5.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone5.isDocuments}"
                                                        [disabled]="ViewOnly && !Milestone5.isDocuments"
                                                        pTooltip="Documents" tooltipPosition="right" icon="pi pi-file"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                            <li>
                                                <span style="font-weight: bold;">Notes:</span>
                                                <span class="listitem-value" style="float:right;">
                                                    <button pButton type="button"
                                                        (click)="Notes('List',Milestone5.phaseID,Milestone5.listID,'Step',Milestone5.listName,ViewOnly || Milestone5.active == 'N',Milestone5.isNotes,Milestone5.listStatus != 'In-Process' && Milestone5.listStatus != 'Ready for Approval')"
                                                        [ngClass]="{'p-button-success': Milestone5.isNotes}"
                                                        [disabled]="ViewOnly && !Milestone5.isNotes" pTooltip="Notes"
                                                        tooltipPosition="right" icon="pi pi-comment"
                                                        style="width:25px;height:25px;"></button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Mile5Documents?.length > 0"
                                    style="margin-top: -1.5rem;">
                                    <div class="invoice invoice-header" id="invoice-content">
                                        <p-table #dtMile5Docs [value]="Mile5Documents" [columns]="documents"
                                            sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped"
                                            [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedMile5Docs"
                                            dataKey="reqDocumentID" [autoLayout]="true">
                                            <ng-template pTemplate="caption">
                                                <div>Tasks
                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('mile5')"></button>
                                            </span> -->
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                                <tr>
                                                    <th style="text-align: center"
                                                        *ngFor="let col of columns;let i = index"
                                                        [pSortableColumn]="col.field"
                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                        col.header }}<p-sortIcon [field]="col.field"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th style="text-align: center">Documents</th>
                                                    <th style="text-align: center">Notes</th>
                                                </tr>
                                                <tr>
                                                    <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                        style="text-align: center">
                                                        <input pInputText type="text"
                                                            (input)="dtMile5Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                            placeholder="Search"
                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr class="p-selectable-row"
                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.departmentName}}</span>
                                                    </td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <span>{{rowData.documentName}}</span>
                                                    </td>
                                                    <td style="text-align: center;"><span>{{ rowData.isRequired }}
                                                        </span></td>
                                                    <td style="text-align: center;"><span>{{rowData.documentLabels
                                                            }}</span></td>
                                                    <td style="text-align:left; padding-left:15px;">
                                                        <ul *ngFor="let temp of rowData.templates" class="tempul">
                                                            <li class="templi"><span
                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                        href="javascript:;"
                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                    </a> </span>
                                                                <span
                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Milestone5.active == 'N',rowData.isDocuments,Milestone5.listStatus != 'In-Process' && Milestone5.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <span class="listitem-value">
                                                            <button pButton type="button"
                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Milestone5.active == 'N',rowData.isNotes,Milestone5.listStatus != 'In-Process' && Milestone5.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7">No records found...</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
                <p-tabPanel header="Stages" leftIcon="pi pi-globe" *ngIf="Stages?.length > 0 && ViewOnlyTabs === true"
                    iconPos="left">
                    <p-accordion [activeIndex]="stageindex" (onOpen)="function2($event)">
                        <p-accordionTab header="{{ Stage1.listName }}" *ngIf="Stages?.length > 0" [selected]="true">
                            <ng-template pTemplate="header"><span *ngIf="Stage1.active == 'N'" style="color: red;"> (Not
                                    yet ready to start) </span></ng-template>
                            <div class="p-col-12" style="margin-bottom: -2rem;">
                                <span class="p-input-icon-left" style="float:right;">
                                    <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage1.active == 'N'" *ngIf="Stage1.isSendForApproval"
                                        (click)="SendApproval('List',Stage1.listID)" pButton pRipple type="button"
                                        label="Send for Approval" style="width:150px;height: 25px;"></button>
                                    <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage1.active == 'N'" *ngIf="Stage1.isReminder"
                                        (click)="Reminder('List',Stage1.listID)" pButton pRipple type="button"
                                        label="Approval Reminder" style="width:160px;height: 25px;"></button>
                                    <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage1.active == 'N'" *ngIf="Stage1.isClarify"
                                        (click)="Clarify('List',Stage1.listID,'Stage',Stage1.listName)" pButton pRipple
                                        type="button" label="Respond" style="width:80px;height: 25px;"></button>
                                </span>
                            </div>
                            <p-tabView [activeIndex]="stage1index" (onChange)="function6($event)">
                                <p-tabPanel header="Details" leftIcon="pi pi-eye" iconPos="left">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Name:</span>
                                                        <span>{{ Stage1.listName }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Status:</span>
                                                        <span>{{ Stage1.listStatus }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Owner:</span>
                                                        <span>{{ Stage1.listOwner }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Responsible Departments:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" pTooltip="View"
                                                                tooltipPosition="right"
                                                                (click)="Stage1departmentsdisplay=true" icon="pi pi-eye"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                            <p-dialog header="Responsible Departments"
                                                                [(visible)]="Stage1departmentsdisplay" modal="modal"
                                                                showEffect="fade" [style]="{width:'25%'}">
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage1Departments
                                                                            [value]="Stage1Departments"
                                                                            [columns]="Departmentscols"
                                                                            sortMode="multiple" [loading]="loading"
                                                                            [autoLayout]="true"
                                                                            styleClass="p-datatable-striped"
                                                                            [filterDelay]="0" selectionMode="multiple"
                                                                            [(selection)]="selectStage1Departments">
                                                                            <ng-template pTemplate="header" let-columns
                                                                                class="invoice-items">
                                                                                <tr>
                                                                                    <th
                                                                                        style="text-align: center;width: 50px;">
                                                                                        S.No</th>
                                                                                    <th style="text-align: center"
                                                                                        *ngFor="let col of columns"
                                                                                        [pSortableColumn]="col.field">{{
                                                                                        col.header }}<p-sortIcon
                                                                                            [field]="col.field"
                                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                        </p-sortIcon>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData
                                                                                let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                    <ng-container
                                                                                        *ngFor="let col of columns; let i = index"
                                                                                        [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: center;width: 50px;">
                                                                                            {{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: left;">{{
                                                                                            rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr>
                                                                                    <td colspan="2">No records found...
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </p-dialog>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Approval Required:</span>
                                                        <span style="padding-right:10px;">{{ Stage1.approval }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Approvers:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                [disabled]="Stage1.approval=='N'" pTooltip="View"
                                                                tooltipPosition="right"
                                                                (click)="Stage1approvalmembersdisplay=true"
                                                                class="p-button-info" icon="pi pi-eye"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                            <p-dialog header="Approvers"
                                                                [(visible)]="Stage1approvalmembersdisplay" modal="modal"
                                                                showEffect="fade" [style]="{width:'80%'}">
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <h5 style="text-align:center">{{ Stage1.listName
                                                                            }} Approvers</h5>
                                                                        <p-table #dtStage1ApprovalTeam
                                                                            [value]="Stage1ApprovalTeam"
                                                                            [columns]="ApprovalTeamcols"
                                                                            sortMode="multiple" [loading]="loading"
                                                                            [autoLayout]="true"
                                                                            styleClass="p-datatable-striped"
                                                                            [filterDelay]="0" selectionMode="multiple"
                                                                            [(selection)]="selectStage1ApprovalTeam">
                                                                            <ng-template pTemplate="header" let-columns
                                                                                class="invoice-items">
                                                                                <tr>
                                                                                    <th
                                                                                        style="text-align: center;width: 60px;">
                                                                                        S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index"
                                                                                        [pSortableColumn]="col.field"
                                                                                        [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                        {{ col.header }}<p-sortIcon
                                                                                            [field]="col.field"
                                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                        </p-sortIcon>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData
                                                                                let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                    <ng-container
                                                                                        *ngFor="let col of columns; let i = index"
                                                                                        [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: center;width: 60px;">
                                                                                            {{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 1"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 2"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 3"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 4"
                                                                                            style="text-align: center">
                                                                                            <span
                                                                                                *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                | date:'MM/dd/yyyy
                                                                                                HH:mm'}}</span>
                                                                                            <span
                                                                                                *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                        </td>
                                                                                        <td *ngIf="i == 5"
                                                                                            style="padding-left:15px;"
                                                                                            title="{{rowData.remarks}}">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr>
                                                                                    <td colspan="7">No records found...
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </p-dialog>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Documents:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                (click)="Documents('List',Stage1.phaseID,Stage1.listID,'Stage',Stage1.listName,'0','',ViewOnly || Stage1.active == 'N',Stage1.isDocuments,Stage1.listStatus != 'In-Process' && Stage1.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': Stage1.isDocuments}"
                                                                [disabled]="ViewOnly && !Stage1.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Notes:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                (click)="Notes('List',Stage1.phaseID,Stage1.listID,'Stage',Stage1.listName,ViewOnly || Stage1.active == 'N',Stage1.isNotes,Stage1.listStatus != 'In-Process' && Stage1.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': Stage1.isNotes}"
                                                                [disabled]="ViewOnly && !Stage1.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-xl-12">
                                            <!-- *ngIf="Stage1documents?.length > 0" -->
                                            <div class="invoice invoice-header" id="invoice-content"
                                                style="margin-top: -1.5rem;">
                                                <p-table #dtStage1Docs [value]="Stage1Documents" [columns]="documents"
                                                    sortMode="multiple" [loading]="loading"
                                                    styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage1Docs"
                                                    dataKey="reqDocumentID" [autoLayout]="true">
                                                    <ng-template pTemplate="caption">
                                                        <div>Tasks
                                                            <!-- <span class="p-input-icon-left" style="float:right">
                                                        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                        <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1')"></button>
                                                    </span> -->
                                                        </div>
                                                    </ng-template>
                                                    <ng-template pTemplate="header" let-columns class="invoice-items">
                                                        <tr>
                                                            <th style="text-align: center"
                                                                *ngFor="let col of columns;let i = index"
                                                                [pSortableColumn]="col.field"
                                                                [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                                col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th style="text-align: center">Documents</th>
                                                            <th style="text-align: center">Notes</th>
                                                        </tr>
                                                        <tr>
                                                            <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                                style="text-align: center">
                                                                <input pInputText type="text"
                                                                    (input)="dtStage1Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                    placeholder="Search"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData>
                                                        <tr class="p-selectable-row"
                                                            [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <span>{{rowData.departmentName}}</span>
                                                            </td>
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <span>{{rowData.documentName}}</span>
                                                            </td>
                                                            <td style="text-align: center;"><span>{{ rowData.isRequired
                                                                    }} </span></td>
                                                            <td style="text-align: center;">
                                                                <span>{{rowData.documentLabels }}</span>
                                                            </td>
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <ul *ngFor="let temp of rowData.templates"
                                                                    class="tempul">
                                                                    <li class="templi"><span
                                                                            *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                href="javascript:;"
                                                                                (click)="DMSToken(temp)">{{temp.templateName}}
                                                                            </a> </span>
                                                                        <span
                                                                            *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <span class="listitem-value">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage1.active == 'N',rowData.isDocuments,Stage1.listStatus != 'In-Process' && Stage1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                        [disabled]="ViewOnly && !rowData.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <span class="listitem-value">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage1.active == 'N',rowData.isNotes,Stage1.listStatus != 'In-Process' && Stage1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                        [disabled]="ViewOnly && !rowData.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7">No records found...</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Stage1Milestones?.length"
                                    iconPos="left">
                                    <p-accordion [activeIndex]="stage1mileindex" (onOpen)="function3($event)">
                                        <p-accordionTab header="{{ Stage1Milestone1.listName }}"
                                            *ngIf="Stage1Milestones?.length > 0" [selected]="true">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage1.active == 'N' || Stage1Milestone1.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N'"
                                                            *ngIf="Stage1Milestone1.isSendForApproval"
                                                            (click)="SendApproval('List',Stage1Milestone1.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N'"
                                                            *ngIf="Stage1Milestone1.isReminder"
                                                            (click)="Reminder('List',Stage1Milestone1.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N'"
                                                            *ngIf="Stage1Milestone1.isClarify"
                                                            (click)="Clarify('List',Stage1Milestone1.listID,'Step',Stage1Milestone1.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage1Milestone1.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage1Milestone1.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage1Milestone1.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" pTooltip="View"
                                                                        tooltipPosition="right"
                                                                        (click)="Stage1mile1departmentsdisplay=true"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage1mile1departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage1Mile1Departments
                                                                                    [value]="Stage1Milestone1Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage1Mile1Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage1Milestone1.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage1Milestone1.approval=='N'"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        (click)="Stage1mile1approvalmembersdisplay=true"
                                                                        class="p-button-info" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage1mile1approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage1Milestone1.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage1Mile1ApprovalTeam
                                                                                    [value]="Stage1Mile1ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage1Mile1ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage1Milestone1.phaseID,Stage1Milestone1.listID,'Step',Stage1Milestone1.listName,'0','',ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N',Stage1Milestone1.isDocuments,Stage1Milestone1.listStatus != 'In-Process' && Stage1Milestone1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage1Milestone1.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage1Milestone1.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage1Milestone1.phaseID,Stage1Milestone1.listID,'Step',Stage1Milestone1.listName,ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N',Stage1Milestone1.isNotes,Stage1Milestone1.listStatus != 'In-Process' && Stage1Milestone1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage1Milestone1.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage1Milestone1.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage1Mile1Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage1Mile1Docs [value]="Stage1Mile1Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage1Mile1Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1mile1')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage1Mile1Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N',rowData.isDocuments,Stage1Milestone1.listStatus != 'In-Process' && Stage1Milestone1.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage1.active == 'N' || Stage1Milestone1.active == 'N',rowData.isNotes,Stage1Milestone1.listStatus != 'In-Process' && Stage1Milestone1.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage1Milestone2.listName }}"
                                            *ngIf="Stage1Milestones?.length > 1">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage1.active == 'N' || Stage1Milestone2.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N'"
                                                            *ngIf="Stage1Milestone2.isSendForApproval"
                                                            (click)="SendApproval('List',Stage1Milestone2.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N'"
                                                            *ngIf="Stage1Milestone2.isReminder"
                                                            (click)="Reminder('List',Stage1Milestone2.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N'"
                                                            *ngIf="Stage1Milestone2.isClarify"
                                                            (click)="Clarify('List',Stage1Milestone2.listID,'Step',Stage1Milestone2.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage1Milestone2.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage1Milestone2.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage1Milestone2.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" pTooltip="View"
                                                                        tooltipPosition="right"
                                                                        (click)="Stage1mile2departmentsdisplay=true"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage1mile2departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage1Mile2Departments
                                                                                    [value]="Stage1Milestone2Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage1Mile2Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage1Milestone2.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage1Milestone2.approval=='N'"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        (click)="Stage1mile2approvalmembersdisplay=true"
                                                                        class="p-button-info" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage1mile2approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage1Milestone2.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage1Mile2ApprovalTeam
                                                                                    [value]="Stage1Mile2ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage1Mile2ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage1Milestone2.phaseID,Stage1Milestone2.listID,'Step',Stage1Milestone2.listName,'0','',ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N',Stage1Milestone2.isDocuments,Stage1Milestone2.listStatus != 'In-Process' && Stage1Milestone2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage1Milestone2.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage1Milestone2.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage1Milestone2.phaseID,Stage1Milestone2.listID,'Step',Stage1Milestone2.listName,ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N',Stage1Milestone2.isNotes,Stage1Milestone2.listStatus != 'In-Process' && Stage1Milestone2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage1Milestone2.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage1Milestone2.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage1Mile2Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage1Mile2Docs [value]="Stage1Mile2Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage1Mile2Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1mile2')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage1Mile2Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N',rowData.isDocuments,Stage1Milestone2.listStatus != 'In-Process' && Stage1Milestone2.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage1.active == 'N' || Stage1Milestone2.active == 'N',rowData.isNotes,Stage1Milestone2.listStatus != 'In-Process' && Stage1Milestone2.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage1Milestone3.listName }}"
                                            *ngIf="Stage1Milestones?.length > 2">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage1.active == 'N' || Stage1Milestone3.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N'"
                                                            *ngIf="Stage1Milestone3.isSendForApproval"
                                                            (click)="SendApproval('List',Stage1Milestone3.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N'"
                                                            *ngIf="Stage1Milestone3.isReminder"
                                                            (click)="Reminder('List',Stage1Milestone3.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N'"
                                                            *ngIf="Stage1Milestone3.isClarify"
                                                            (click)="Clarify('List',Stage1Milestone3.listID,'Step',Stage1Milestone3.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage1Milestone3.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage1Milestone3.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage1Milestone3.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Stage1mile3departmentsdisplay=true"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage1mile3departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage1Mile3Departments
                                                                                    [value]="Stage1Milestone3Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage1Mile3Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage1Milestone3.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage1Milestone3.approval=='N'"
                                                                        (click)="Stage1mile3approvalmembersdisplay=true"
                                                                        class="p-button-info" pTooltip="View"
                                                                        tooltipPosition="right" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage1mile3approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage1Milestone3.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage1Mile3ApprovalTeam
                                                                                    [value]="Stage1Mile3ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage1Mile3ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage1Milestone3.phaseID,Stage1Milestone3.listID,'Step',Stage1Milestone3.listName,'0','',ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N',Stage1Milestone3.isDocuments,Stage1Milestone3.listStatus != 'In-Process' && Stage1Milestone3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage1Milestone3.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage1Milestone3.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage1Milestone3.phaseID,Stage1Milestone3.listID,'Step',Stage1Milestone3.listName,ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N',Stage1Milestone3.isNotes,Stage1Milestone3.listStatus != 'In-Process' && Stage1Milestone3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage1Milestone3.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage1Milestone3.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage1Mile3Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage1Mile3Docs [value]="Stage1Mile3Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage1Mile3Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage1mile3')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage1Mile3Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N',rowData.isDocuments,Stage1Milestone3.listStatus != 'In-Process' && Stage1Milestone3.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage1.active == 'N' || Stage1Milestone3.active == 'N',rowData.isNotes,Stage1Milestone3.listStatus != 'In-Process' && Stage1Milestone3.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </p-tabPanel>
                            </p-tabView>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Stage2.listName }}" *ngIf="Stages?.length > 1">
                            <ng-template pTemplate="header"><span *ngIf="Stage2.active == 'N'" style="color: red;"> (Not
                                    yet ready to start) </span></ng-template>
                            <div class="p-col-12" style="margin-bottom: -2rem;">
                                <span class="p-input-icon-left" style="float:right;">
                                    <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage2.active == 'N'" *ngIf="Stage2.isSendForApproval"
                                        (click)="SendApproval('List',Stage2.listID)" pButton pRipple type="button"
                                        label="Send for Approval" style="width:150px;height: 25px;"></button>
                                    <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage2.active == 'N'" *ngIf="Stage2.isReminder"
                                        (click)="Reminder('List',Stage2.listID)" pButton pRipple type="button"
                                        label="Approval Reminder" style="width:160px;height: 25px;"></button>
                                    <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage2.active == 'N'" *ngIf="Stage2.isClarify"
                                        (click)="Clarify('List',Stage2.listID,'Stage',Stage2.listName)" pButton pRipple
                                        type="button" label="Respond" style="width:80px;height: 25px;"></button>
                                </span>
                            </div>
                            <p-tabView [activeIndex]="stage2index" (onChange)="function7($event)">
                                <p-tabPanel header="Details" leftIcon="pi pi-eye" iconPos="left">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Name:</span>
                                                        <span>{{ Stage2.listName }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Status:</span>
                                                        <span>{{ Stage2.listStatus }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Owner:</span>
                                                        <span>{{ Stage2.listOwner }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Responsible Departments:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" pTooltip="View"
                                                                tooltipPosition="right"
                                                                (click)="Stage2departmentsdisplay=true" icon="pi pi-eye"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                            <p-dialog header="Responsible Departments"
                                                                [(visible)]="Stage2departmentsdisplay" modal="modal"
                                                                showEffect="fade" [style]="{width:'25%'}">
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage2Departments
                                                                            [value]="Stage2Departments"
                                                                            [columns]="Departmentscols"
                                                                            sortMode="multiple" [loading]="loading"
                                                                            [autoLayout]="true"
                                                                            styleClass="p-datatable-striped"
                                                                            [filterDelay]="0" selectionMode="multiple"
                                                                            [(selection)]="selectStage2Departments">
                                                                            <ng-template pTemplate="header" let-columns
                                                                                class="invoice-items">
                                                                                <tr>
                                                                                    <th
                                                                                        style="text-align: center;width: 50px;">
                                                                                        S.No</th>
                                                                                    <th style="text-align: center"
                                                                                        *ngFor="let col of columns"
                                                                                        [pSortableColumn]="col.field">{{
                                                                                        col.header }}<p-sortIcon
                                                                                            [field]="col.field"
                                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                        </p-sortIcon>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData
                                                                                let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                    <ng-container
                                                                                        *ngFor="let col of columns; let i = index"
                                                                                        [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: center;width: 50px;">
                                                                                            {{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: left;">{{
                                                                                            rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr>
                                                                                    <td colspan="2">No records found...
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </p-dialog>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Approval Required:</span>
                                                        <span style="padding-right:10px;">{{ Stage2.approval }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Approvers:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                [disabled]="Stage2.approval=='N'" pTooltip="View"
                                                                tooltipPosition="right"
                                                                (click)="Stage2approvalmembersdisplay=true"
                                                                class="p-button-info" icon="pi pi-eye"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                            <p-dialog header="Approvers"
                                                                [(visible)]="Stage2approvalmembersdisplay" modal="modal"
                                                                showEffect="fade" [style]="{width:'80%'}">
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <h5 style="text-align:center">{{ Stage2.listName
                                                                            }} Approvers</h5>
                                                                        <p-table #dtStage2ApprovalTeam
                                                                            [value]="Stage2ApprovalTeam"
                                                                            [columns]="ApprovalTeamcols"
                                                                            sortMode="multiple" [loading]="loading"
                                                                            [autoLayout]="true"
                                                                            styleClass="p-datatable-striped"
                                                                            [filterDelay]="0" selectionMode="multiple"
                                                                            [(selection)]="selectStage2ApprovalTeam">
                                                                            <ng-template pTemplate="header" let-columns
                                                                                class="invoice-items">
                                                                                <tr>
                                                                                    <th
                                                                                        style="text-align: center;width: 60px;">
                                                                                        S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index"
                                                                                        [pSortableColumn]="col.field"
                                                                                        [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                        {{ col.header }}<p-sortIcon
                                                                                            [field]="col.field"
                                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                        </p-sortIcon>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData
                                                                                let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                    <ng-container
                                                                                        *ngFor="let col of columns; let i = index"
                                                                                        [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: center;width: 60px;">
                                                                                            {{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 1"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 2"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 3"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 4"
                                                                                            style="text-align: center">
                                                                                            <span
                                                                                                *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                | date:'MM/dd/yyyy
                                                                                                HH:mm'}}</span>
                                                                                            <span
                                                                                                *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                        </td>
                                                                                        <td *ngIf="i == 5"
                                                                                            style="padding-left:15px;"
                                                                                            title="{{rowData.remarks}}">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr>
                                                                                    <td colspan="7">No records found...
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </p-dialog>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Documents:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                (click)="Documents('List',Stage2.phaseID,Stage2.listID,'Stage',Stage2.listName,'0','',ViewOnly || Stage2.active == 'N',Stage2.isDocuments,Stage2.listStatus != 'In-Process' && Stage2.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': Stage2.isDocuments}"
                                                                [disabled]="ViewOnly && !Stage2.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Notes:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                (click)="Notes('List',Stage2.phaseID,Stage2.listID,'Stage',Stage2.listName,ViewOnly || Stage2.active == 'N',Stage2.isNotes,Stage2.listStatus != 'In-Process' && Stage2.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': Stage2.isNotes}"
                                                                [disabled]="ViewOnly && !Stage2.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -1.5rem;">
                                            <div class="invoice invoice-header" id="invoice-content">
                                                <p-table #dtStage2Docs [value]="Stage2Documents" [columns]="documents"
                                                    sortMode="multiple" [loading]="loading"
                                                    styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage2Docs"
                                                    dataKey="reqDocumentID" [autoLayout]="true">
                                                    <ng-template pTemplate="caption">
                                                        <div>Tasks
                                                            <!-- <span class="p-input-icon-left" style="float:right">
                                                        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                        <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2')"></button>
                                                    </span> -->
                                                        </div>
                                                    </ng-template>
                                                    <ng-template pTemplate="header" let-columns class="invoice-items">
                                                        <tr>
                                                            <th style="text-align: center"
                                                                *ngFor="let col of columns;let i = index"
                                                                [pSortableColumn]="col.field"
                                                                [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                                col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th style="text-align: center">Documents</th>
                                                            <th style="text-align: center">Notes</th>
                                                        </tr>
                                                        <tr>
                                                            <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                                style="text-align: center">
                                                                <input pInputText type="text"
                                                                    (input)="dtStage2Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                    placeholder="Search"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData>
                                                        <tr class="p-selectable-row"
                                                            [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <span>{{rowData.departmentName}}</span>
                                                            </td>
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <span>{{rowData.documentName}}</span>
                                                            </td>
                                                            <td style="text-align: center;"><span>{{ rowData.isRequired
                                                                    }} </span></td>
                                                            <td style="text-align: center;">
                                                                <span>{{rowData.documentLabels }}</span>
                                                            </td>
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <ul *ngFor="let temp of rowData.templates"
                                                                    class="tempul">
                                                                    <li class="templi"><span
                                                                            *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                href="javascript:;"
                                                                                (click)="DMSToken(temp)">{{temp.templateName}}
                                                                            </a> </span>
                                                                        <span
                                                                            *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <span class="listitem-value">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage2.active == 'N',rowData.isDocuments,Stage2.listStatus != 'In-Process' && Stage2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                        [disabled]="ViewOnly && !rowData.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <span class="listitem-value">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage2.active == 'N',rowData.isNotes,Stage2.listStatus != 'In-Process' && Stage2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                        [disabled]="ViewOnly && !rowData.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7">No records found...</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Stage2Milestones?.length"
                                    iconPos="left">
                                    <p-accordion [activeIndex]="stage2mileindex" (onOpen)="function4($event)">
                                        <p-accordionTab header="{{ Stage2Milestone1.listName }}"
                                            *ngIf="Stage2Milestones?.length > 0" [selected]="true">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage2.active == 'N' || Stage2Milestone1.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N'"
                                                            *ngIf="Stage2Milestone1.isSendForApproval"
                                                            (click)="SendApproval('List',Stage2Milestone1.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N'"
                                                            *ngIf="Stage2Milestone1.isReminder"
                                                            (click)="Reminder('List',Stage2Milestone1.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N'"
                                                            *ngIf="Stage2Milestone1.isClarify"
                                                            (click)="Clarify('List',Stage2Milestone1.listID,'Step',Stage2Milestone1.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage2Milestone1.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage2Milestone1.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage2Milestone1.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" pTooltip="View"
                                                                        tooltipPosition="right"
                                                                        (click)="Stage2mile1departmentsdisplay=true"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage2mile1departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage2Mile1Departments
                                                                                    [value]="Stage2Milestone1Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage2Mile1Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage2Milestone1.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage2Milestone1.approval=='N'"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        (click)="Stage2mile1approvalmembersdisplay=true"
                                                                        class="p-button-info" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage2mile1approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage2Milestone1.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage2Mile1ApprovalTeam
                                                                                    [value]="Stage2Mile1ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage2Mile1ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage2Milestone1.phaseID,Stage2Milestone1.listID,'Step',Stage2Milestone1.listName,'0','',ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N',Stage2Milestone1.isDocuments,Stage2Milestone1.listStatus != 'In-Process' && Stage2Milestone1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage2Milestone1.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage2Milestone1.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage2Milestone1.phaseID,Stage2Milestone1.listID,'Step',Stage2Milestone1.listName,ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N',Stage2Milestone1.isNotes,Stage2Milestone1.listStatus != 'In-Process' && Stage2Milestone1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage2Milestone1.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage2Milestone1.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage2Mile1Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage2Mile1Docs [value]="Stage2Mile1Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage2Mile1Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2mile1')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage2Mile1Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N',rowData.isDocuments,Stage2Milestone1.listStatus != 'In-Process' && Stage2Milestone1.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage2.active == 'N' || Stage2Milestone1.active == 'N',rowData.isNotes,Stage2Milestone1.listStatus != 'In-Process' && Stage2Milestone1.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage2Milestone2.listName }}"
                                            *ngIf="Stage2Milestones?.length > 1">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage2.active == 'N' || Stage2Milestone2.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N'"
                                                            *ngIf="Stage2Milestone2.isSendForApproval"
                                                            (click)="SendApproval('List',Stage2Milestone2.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N'"
                                                            *ngIf="Stage2Milestone2.isReminder"
                                                            (click)="Reminder('List',Stage2Milestone2.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N'"
                                                            *ngIf="Stage2Milestone2.isClarify"
                                                            (click)="Clarify('List',Stage2Milestone2.listID,'Step',Stage2Milestone2.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage2Milestone2.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage2Milestone2.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage2Milestone2.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" pTooltip="View"
                                                                        tooltipPosition="right"
                                                                        (click)="Stage2mile2departmentsdisplay=true"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage2mile2departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage2Mile2Departments
                                                                                    [value]="Stage2Milestone2Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage2Mile2Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage2Milestone2.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage2Milestone2.approval=='N'"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        (click)="Stage2mile2approvalmembersdisplay=true"
                                                                        class="p-button-info" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage2mile2approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage2Milestone2.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage2Mile2ApprovalTeam
                                                                                    [value]="Stage2Mile2ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage2Mile2ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage2Milestone2.phaseID,Stage2Milestone2.listID,'Step',Stage2Milestone2.listName,'0','',ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N',Stage2Milestone2.isDocuments,Stage2Milestone2.listStatus != 'In-Process' && Stage2Milestone2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage2Milestone2.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage2Milestone2.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage2Milestone2.phaseID,Stage2Milestone2.listID,'Step',Stage2Milestone2.listName,ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N',Stage2Milestone2.isNotes,Stage2Milestone2.listStatus != 'In-Process' && Stage2Milestone2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage2Milestone2.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage2Milestone2.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage2Mile2Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage2Mile2Docs [value]="Stage2Mile2Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage2Mile2Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2mile2')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage2Mile2Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N',rowData.isDocuments,Stage2Milestone2.listStatus != 'In-Process' && Stage2Milestone2.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage2.active == 'N' || Stage2Milestone2.active == 'N',rowData.isNotes,Stage2Milestone2.listStatus != 'In-Process' && Stage2Milestone2.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage2Milestone3.listName }}"
                                            *ngIf="Stage2Milestones?.length > 2">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage2.active == 'N' || Stage2Milestone3.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N'"
                                                            *ngIf="Stage2Milestone3.isSendForApproval"
                                                            (click)="SendApproval('List',Stage2Milestone3.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N'"
                                                            *ngIf="Stage2Milestone3.isReminder"
                                                            (click)="Reminder('List',Stage2Milestone3.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N'"
                                                            *ngIf="Stage2Milestone3.isClarify"
                                                            (click)="Clarify('List',Stage2Milestone3.listID,'Step',Stage2Milestone3.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage2Milestone3.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage2Milestone3.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage2Milestone3.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Stage2mile3departmentsdisplay=true"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage2mile3departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage2Mile3Departments
                                                                                    [value]="Stage2Milestone3Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage2Mile3Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage2Milestone3.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage2Milestone3.approval=='N'"
                                                                        (click)="Stage2mile3approvalmembersdisplay=true"
                                                                        class="p-button-info" pTooltip="View"
                                                                        tooltipPosition="right" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage2mile3approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage2Milestone3.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage2Mile3ApprovalTeam
                                                                                    [value]="Stage2Mile3ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage2Mile3ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage2Milestone3.phaseID,Stage2Milestone3.listID,'Step',Stage2Milestone3.listName,'0','',ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N',Stage2Milestone3.isDocuments,Stage2Milestone3.listStatus != 'In-Process' && Stage2Milestone3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage2Milestone3.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage2Milestone3.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage2Milestone3.phaseID,Stage2Milestone3.listID,'Step',Stage2Milestone3.listName,ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N',Stage2Milestone3.isNotes,Stage2Milestone3.listStatus != 'In-Process' && Stage2Milestone3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage2Milestone3.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage2Milestone3.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage2Mile3Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage2Mile3Docs [value]="Stage2Mile3Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage2Mile3Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage2mile3')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage2Mile3Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N',rowData.isDocuments,Stage2Milestone3.listStatus != 'In-Process' && Stage2Milestone3.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage2.active == 'N' || Stage2Milestone3.active == 'N',rowData.isNotes,Stage2Milestone3.listStatus != 'In-Process' && Stage2Milestone3.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </p-tabPanel>
                            </p-tabView>
                        </p-accordionTab>
                        <p-accordionTab header="{{ Stage3.listName }}" *ngIf="Stages?.length > 2">
                            <ng-template pTemplate="header"><span *ngIf="Stage3.active == 'N'" style="color: red;"> (Not
                                    yet ready to start) </span></ng-template>
                            <div class="p-col-12" style="margin-bottom: -2rem;">
                                <span class="p-input-icon-left" style="float:right;">
                                    <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage3.active == 'N'" *ngIf="Stage3.isSendForApproval"
                                        (click)="SendApproval('List',Stage3.listID)" pButton pRipple type="button"
                                        label="Send for Approval" style="width:150px;height: 25px;"></button>
                                    <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage3.active == 'N'" *ngIf="Stage3.isReminder"
                                        (click)="Reminder('List',Stage3.listID)" pButton pRipple type="button"
                                        label="Approval Reminder" style="width:160px;height: 25px;"></button>
                                    <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                        [disabled]="ViewOnly || Stage3.active == 'N'" *ngIf="Stage3.isClarify"
                                        (click)="Clarify('List',Stage3.listID,'Stage',Stage3.listName)" pButton pRipple
                                        type="button" label="Respond" style="width:80px;height: 25px;"></button>
                                </span>
                            </div>
                            <p-tabView [activeIndex]="stage3index" (onChange)="function8($event)">
                                <p-tabPanel header="Details" leftIcon="pi pi-eye" iconPos="left">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Name:</span>
                                                        <span>{{ Stage3.listName }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Status:</span>
                                                        <span>{{ Stage3.listStatus }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Owner:</span>
                                                        <span>{{ Stage3.listOwner }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Responsible Departments:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button" pTooltip="View"
                                                                tooltipPosition="right"
                                                                (click)="Stage3departmentsdisplay=true" icon="pi pi-eye"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                            <p-dialog header="Responsible Departments"
                                                                [(visible)]="Stage3departmentsdisplay" modal="modal"
                                                                showEffect="fade" [style]="{width:'25%'}">
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <p-table #dtStage3Departments
                                                                            [value]="Stage3Departments"
                                                                            [columns]="Departmentscols"
                                                                            sortMode="multiple" [loading]="loading"
                                                                            [autoLayout]="true"
                                                                            styleClass="p-datatable-striped"
                                                                            [filterDelay]="0" selectionMode="multiple"
                                                                            [(selection)]="selectStage3Departments">
                                                                            <ng-template pTemplate="header" let-columns
                                                                                class="invoice-items">
                                                                                <tr>
                                                                                    <th
                                                                                        style="text-align: center;width: 50px;">
                                                                                        S.No</th>
                                                                                    <th style="text-align: center"
                                                                                        *ngFor="let col of columns"
                                                                                        [pSortableColumn]="col.field">{{
                                                                                        col.header }}<p-sortIcon
                                                                                            [field]="col.field"
                                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                        </p-sortIcon>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData
                                                                                let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                    <ng-container
                                                                                        *ngFor="let col of columns; let i = index"
                                                                                        [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: center;width: 50px;">
                                                                                            {{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: left;">{{
                                                                                            rowData[col.field] }}</td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr>
                                                                                    <td colspan="2">No records found...
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </p-dialog>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Approval Required:</span>
                                                        <span style="padding-right:10px;">{{ Stage3.approval }}</span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Approvers:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                [disabled]="Stage3.approval=='N'" pTooltip="View"
                                                                tooltipPosition="right"
                                                                (click)="Stage3approvalmembersdisplay=true"
                                                                class="p-button-info" icon="pi pi-eye"
                                                                class="p-button-raised p-button-primary"
                                                                style="height:25px; width:25px;"></button>
                                                            <p-dialog header="Approvers"
                                                                [(visible)]="Stage3approvalmembersdisplay" modal="modal"
                                                                showEffect="fade" [style]="{width:'80%'}">
                                                                <hr>
                                                                <div class="p-grid">
                                                                    <div class="p-col-12 p-md-12 p-xl-12">
                                                                        <h5 style="text-align:center">{{ Stage3.listName
                                                                            }} Approvers</h5>
                                                                        <p-table #dtStage3ApprovalTeam
                                                                            [value]="Stage3ApprovalTeam"
                                                                            [columns]="ApprovalTeamcols"
                                                                            sortMode="multiple" [loading]="loading"
                                                                            [autoLayout]="true"
                                                                            styleClass="p-datatable-striped"
                                                                            [filterDelay]="0" selectionMode="multiple"
                                                                            [(selection)]="selectStage3ApprovalTeam">
                                                                            <ng-template pTemplate="header" let-columns
                                                                                class="invoice-items">
                                                                                <tr>
                                                                                    <th
                                                                                        style="text-align: center;width: 60px;">
                                                                                        S.No</th>
                                                                                    <th *ngFor="let col of columns;let i = index"
                                                                                        [pSortableColumn]="col.field"
                                                                                        [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                        {{ col.header }}<p-sortIcon
                                                                                            [field]="col.field"
                                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                        </p-sortIcon>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData
                                                                                let-columns="columns" let-j="rowIndex">
                                                                                <tr [pSelectableRow]="rowData">
                                                                                    <ng-container
                                                                                        *ngFor="let col of columns; let i = index"
                                                                                        [ngSwitch]="col.field">
                                                                                        <td *ngIf="i == 0"
                                                                                            style="text-align: center;width: 60px;">
                                                                                            {{ j + 1 }}</td>
                                                                                        <td *ngIf="i == 0"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 1"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 2"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 3"
                                                                                            style="padding-left:15px;">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                        <td *ngIf="i == 4"
                                                                                            style="text-align: center">
                                                                                            <span
                                                                                                *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                | date:'MM/dd/yyyy
                                                                                                HH:mm'}}</span>
                                                                                            <span
                                                                                                *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                        </td>
                                                                                        <td *ngIf="i == 5"
                                                                                            style="padding-left:15px;"
                                                                                            title="{{rowData.remarks}}">
                                                                                            {{ rowData[col.field] }}
                                                                                        </td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage">
                                                                                <tr>
                                                                                    <td colspan="7">No records found...
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </p-dialog>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-4 p-xl-4">
                                            <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                style="min-height:150px;">
                                                <ul class="widget-image-list">
                                                    <li>
                                                        <span style="font-weight: bold;">Documents:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                (click)="Documents('List',Stage3.phaseID,Stage3.listID,'Stage',Stage3.listName,'0','',ViewOnly || Stage3.active == 'N',Stage3.isDocuments,Stage3.listStatus != 'In-Process' && Stage3.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': Stage3.isDocuments}"
                                                                [disabled]="ViewOnly && !Stage3.isDocuments"
                                                                pTooltip="Documents" tooltipPosition="right"
                                                                icon="pi pi-file"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span style="font-weight: bold;">Notes:</span>
                                                        <span class="listitem-value" style="float:right;">
                                                            <button pButton type="button"
                                                                (click)="Notes('List',Stage3.phaseID,Stage3.listID,'Stage',Stage3.listName,ViewOnly || Stage3.active == 'N',Stage3.isNotes,Stage3.listStatus != 'In-Process' && Stage3.listStatus != 'Ready for Approval')"
                                                                [ngClass]="{'p-button-success': Stage3.isNotes}"
                                                                [disabled]="ViewOnly && !Stage3.isNotes"
                                                                pTooltip="Notes" tooltipPosition="right"
                                                                icon="pi pi-comment"
                                                                style="width:25px;height:25px;"></button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -1.5rem;">
                                            <div class="invoice invoice-header" id="invoice-content">
                                                <p-table #dtStage3Docs [value]="Stage3Documents" [columns]="documents"
                                                    sortMode="multiple" [loading]="loading"
                                                    styleClass="p-datatable-striped" [filterDelay]="0"
                                                    selectionMode="multiple" [(selection)]="selectedStage3Docs"
                                                    dataKey="reqDocumentID" [autoLayout]="true">
                                                    <ng-template pTemplate="caption">
                                                        <div>Tasks
                                                            <!-- <span class="p-input-icon-left" style="float:right">
                                                        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                        <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3')"></button>
                                                    </span> -->
                                                        </div>
                                                    </ng-template>
                                                    <ng-template pTemplate="header" let-columns class="invoice-items">
                                                        <tr>
                                                            <th style="text-align: center"
                                                                *ngFor="let col of columns;let i = index"
                                                                [pSortableColumn]="col.field"
                                                                [ngClass]="{'width120': i == 2, 'width120': i == 4}">{{
                                                                col.header }}<p-sortIcon [field]="col.field"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th style="text-align: center">Documents</th>
                                                            <th style="text-align: center">Notes</th>
                                                        </tr>
                                                        <tr>
                                                            <th *ngFor="let doc of documents" [ngSwitch]="doc.field"
                                                                style="text-align: center">
                                                                <input pInputText type="text"
                                                                    (input)="dtStage3Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                    placeholder="Search"
                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData>
                                                        <tr class="p-selectable-row"
                                                            [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <span>{{rowData.departmentName}}</span>
                                                            </td>
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <span>{{rowData.documentName}}</span>
                                                            </td>
                                                            <td style="text-align: center;"><span>{{ rowData.isRequired
                                                                    }} </span></td>
                                                            <td style="text-align: center;">
                                                                <span>{{rowData.documentLabels }}</span>
                                                            </td>
                                                            <td style="text-align:left; padding-left:15px;">
                                                                <ul *ngFor="let temp of rowData.templates"
                                                                    class="tempul">
                                                                    <li class="templi"><span
                                                                            *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                href="javascript:;"
                                                                                (click)="DMSToken(temp)">{{temp.templateName}}
                                                                            </a> </span>
                                                                        <span
                                                                            *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <span class="listitem-value">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage3.active == 'N',rowData.isDocuments,Stage3.listStatus != 'In-Process' && Stage3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                        [disabled]="ViewOnly && !rowData.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <span class="listitem-value">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage3.active == 'N',rowData.isNotes,Stage3.listStatus != 'In-Process' && Stage3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                        [disabled]="ViewOnly && !rowData.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7">No records found...</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Step" leftIcon="pi pi-compass" *ngIf="Stage3Milestones?.length"
                                    iconPos="left">
                                    <p-accordion [activeIndex]="stage3mileindex" (onOpen)="function5($event)">
                                        <p-accordionTab header="{{ Stage3Milestone1.listName }}"
                                            *ngIf="Stage3Milestones?.length > 0" [selected]="true">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage3.active == 'N' || Stage3Milestone1.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N'"
                                                            *ngIf="Stage3Milestone1.isSendForApproval"
                                                            (click)="SendApproval('List',Stage3Milestone1.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N'"
                                                            *ngIf="Stage3Milestone1.isReminder"
                                                            (click)="Reminder('List',Stage3Milestone1.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N'"
                                                            *ngIf="Stage3Milestone1.isClarify"
                                                            (click)="Clarify('List',Stage3Milestone1.listID,'Step',Stage3Milestone1.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage3Milestone1.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage3Milestone1.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage3Milestone1.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" pTooltip="View"
                                                                        tooltipPosition="right"
                                                                        (click)="Stage3mile1departmentsdisplay=true"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage3mile1departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage3Mile1Departments
                                                                                    [value]="Stage3Milestone1Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage3Mile1Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage3Milestone1.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage3Milestone1.approval=='N'"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        (click)="Stage3mile1approvalmembersdisplay=true"
                                                                        class="p-button-info" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage3mile1approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage3Milestone1.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage3Mile1ApprovalTeam
                                                                                    [value]="Stage3Mile1ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage3Mile1ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage3Milestone1.phaseID,Stage3Milestone1.listID,'Step',Stage3Milestone1.listName,'0','',ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N',Stage3Milestone1.isDocuments,Stage3Milestone1.listStatus != 'In-Process' && Stage3Milestone1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage3Milestone1.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage3Milestone1.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage3Milestone1.phaseID,Stage3Milestone1.listID,'Step',Stage3Milestone1.listName,ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N',Stage3Milestone1.isNotes,Stage3Milestone1.listStatus != 'In-Process' && Stage3Milestone1.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage3Milestone1.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage3Milestone1.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage3Mile1Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage3Mile1Docs [value]="Stage3Mile1Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage3Mile1Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3mile1')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage3Mile1Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N',rowData.isDocuments,Stage3Milestone1.listStatus != 'In-Process' && Stage3Milestone1.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage3.active == 'N' || Stage3Milestone1.active == 'N',rowData.isNotes,Stage3Milestone1.listStatus != 'In-Process' && Stage3Milestone1.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage3Milestone2.listName }}"
                                            *ngIf="Stage3Milestones?.length > 1">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage3.active == 'N' || Stage3Milestone2.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N'"
                                                            *ngIf="Stage3Milestone2.isSendForApproval"
                                                            (click)="SendApproval('List',Stage3Milestone2.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N'"
                                                            *ngIf="Stage3Milestone2.isReminder"
                                                            (click)="Reminder('List',Stage3Milestone2.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N'"
                                                            *ngIf="Stage3Milestone2.isClarify"
                                                            (click)="Clarify('List',Stage3Milestone2.listID,'Step',Stage3Milestone2.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage3Milestone2.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage3Milestone2.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage3Milestone2.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button" pTooltip="View"
                                                                        tooltipPosition="right"
                                                                        (click)="Stage3mile2departmentsdisplay=true"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage3mile2departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage3Mile2Departments
                                                                                    [value]="Stage3Milestone2Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage3Mile2Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage3Milestone2.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage3Milestone2.approval=='N'"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        (click)="Stage3mile2approvalmembersdisplay=true"
                                                                        class="p-button-info" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage3mile2approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage3Milestone2.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage3Mile2ApprovalTeam
                                                                                    [value]="Stage3Mile2ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage3Mile2ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage3Milestone2.phaseID,Stage3Milestone2.listID,'Step',Stage3Milestone2.listName,'0','',ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N',Stage3Milestone2.isDocuments,Stage3Milestone2.listStatus != 'In-Process' && Stage3Milestone2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage3Milestone2.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage3Milestone2.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage3Milestone2.phaseID,Stage3Milestone2.listID,'Step',Stage3Milestone2.listName,ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N',Stage3Milestone2.isNotes,Stage3Milestone2.listStatus != 'In-Process' && Stage3Milestone2.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage3Milestone2.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage3Milestone2.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage3Mile2Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage3Mile2Docs [value]="Stage3Mile2Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage3Mile2Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3mile2')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage3Mile2Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N',rowData.isDocuments,Stage3Milestone2.listStatus != 'In-Process' && Stage3Milestone2.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage3.active == 'N' || Stage3Milestone2.active == 'N',rowData.isNotes,Stage3Milestone2.listStatus != 'In-Process' && Stage3Milestone2.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="{{ Stage3Milestone3.listName }}"
                                            *ngIf="Stage3Milestones?.length > 2">
                                            <ng-template pTemplate="header"><span
                                                    *ngIf="Stage3.active == 'N' || Stage3Milestone3.active == 'N'"
                                                    style="color: red;"> (Not yet ready to start) </span></ng-template>
                                            <div class="p-grid">
                                                <div class="p-col-12" style="margin-bottom: -2rem;">
                                                    <span class="p-input-icon-left" style="float:right;">
                                                        <button class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N'"
                                                            *ngIf="Stage3Milestone3.isSendForApproval"
                                                            (click)="SendApproval('List',Stage3Milestone3.listID)"
                                                            pButton pRipple type="button" label="Send for Approval"
                                                            style="width:150px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N'"
                                                            *ngIf="Stage3Milestone3.isReminder"
                                                            (click)="Reminder('List',Stage3Milestone3.listID)" pButton
                                                            pRipple type="button" label="Approval Reminder"
                                                            style="width:160px;height: 25px;"></button>
                                                        <button class="p-button-raised p-button-info p-mr-2 p-mb-2"
                                                            [disabled]="ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N'"
                                                            *ngIf="Stage3Milestone3.isClarify"
                                                            (click)="Clarify('List',Stage3Milestone3.listID,'Step',Stage3Milestone3.listName)"
                                                            pButton pRipple type="button" label="Respond"
                                                            style="width:80px;height: 25px;"></button>
                                                    </span>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Name:</span>
                                                                <span>{{ Stage3Milestone3.listName }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Status:</span>
                                                                <span>{{ Stage3Milestone3.listStatus }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Owner:</span>
                                                                <span>{{ Stage3Milestone3.listOwner }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Responsible
                                                                    Departments:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Stage3mile3departmentsdisplay=true"
                                                                        pTooltip="View" tooltipPosition="right"
                                                                        icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Responsible Departments"
                                                                        [(visible)]="Stage3mile3departmentsdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'25%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <p-table #dtStage3Mile3Departments
                                                                                    [value]="Stage3Milestone3Departments"
                                                                                    [columns]="Departmentscols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage3Mile3Departments">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th style="text-align: center"
                                                                                                *ngFor="let col of columns"
                                                                                                [pSortableColumn]="col.field">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="2">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approval
                                                                    Required:</span>
                                                                <span style="padding-right:10px;">{{
                                                                    Stage3Milestone3.approval }}</span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Approvers:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        [disabled]="Stage3Milestone3.approval=='N'"
                                                                        (click)="Stage3mile3approvalmembersdisplay=true"
                                                                        class="p-button-info" pTooltip="View"
                                                                        tooltipPosition="right" icon="pi pi-eye"
                                                                        class="p-button-raised p-button-primary"
                                                                        style="height:25px; width:25px;"></button>
                                                                    <p-dialog header="Approvers"
                                                                        [(visible)]="Stage3mile3approvalmembersdisplay"
                                                                        modal="modal" showEffect="fade"
                                                                        [style]="{width:'80%'}">
                                                                        <hr>
                                                                        <div class="p-grid">
                                                                            <div class="p-col-12 p-md-12 p-xl-12">
                                                                                <h5 style="text-align:center">{{
                                                                                    Stage3Milestone3.listName }}
                                                                                    Approvers</h5>
                                                                                <p-table #dtStage3Mile3ApprovalTeam
                                                                                    [value]="Stage3Mile3ApprovalTeam"
                                                                                    [columns]="ApprovalTeamcols"
                                                                                    sortMode="multiple"
                                                                                    [loading]="loading"
                                                                                    styleClass="p-datatable-striped"
                                                                                    [filterDelay]="0"
                                                                                    selectionMode="multiple"
                                                                                    [(selection)]="selectStage3Mile3ApprovalTeam">
                                                                                    <ng-template pTemplate="header"
                                                                                        let-columns
                                                                                        class="invoice-items">
                                                                                        <tr>
                                                                                            <th
                                                                                                style="text-align: center;width: 60px;">
                                                                                                S.No</th>
                                                                                            <th *ngFor="let col of columns;let i = index"
                                                                                                [pSortableColumn]="col.field"
                                                                                                [ngClass]="{'width150': i == 0,'width150': i == 1,'width150': i == 2,'width100': i == 3}">
                                                                                                {{ col.header }}
                                                                                                <p-sortIcon
                                                                                                    [field]="col.field"
                                                                                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                                                </p-sortIcon>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-rowData
                                                                                        let-columns="columns"
                                                                                        let-j="rowIndex">
                                                                                        <tr [pSelectableRow]="rowData">
                                                                                            <ng-container
                                                                                                *ngFor="let col of columns; let i = index"
                                                                                                [ngSwitch]="col.field">
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="text-align: center;width: 60px;">
                                                                                                    {{ j + 1 }}</td>
                                                                                                <td *ngIf="i == 0"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 1"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 2"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 3"
                                                                                                    style="padding-left:15px;">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                                <td *ngIf="i == 4"
                                                                                                    style="text-align: center">
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate != '0001-01-01T00:00:00' && rowData.approvedDate != null">{{rowData[col.field]
                                                                                                        |
                                                                                                        date:'MM/dd/yyyy
                                                                                                        HH:mm'}}</span>
                                                                                                    <span
                                                                                                        *ngIf="rowData.approvedDate == '0001-01-01T00:00:00' || rowData.approvedDate == null">--</span>
                                                                                                </td>
                                                                                                <td *ngIf="i == 5"
                                                                                                    style="padding-left:15px;"
                                                                                                    title="{{rowData.remarks}}">
                                                                                                    {{
                                                                                                    rowData[col.field]
                                                                                                    }}</td>
                                                                                            </ng-container>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage">
                                                                                        <tr>
                                                                                            <td colspan="7">No records
                                                                                                found...</td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </p-dialog>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-4 p-xl-4">
                                                    <div class="card no-gutter widget-overview-box widget-overview-box-1"
                                                        style="min-height:150px;">
                                                        <ul class="widget-image-list">
                                                            <li>
                                                                <span style="font-weight: bold;">Documents:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Documents('List',Stage3Milestone3.phaseID,Stage3Milestone3.listID,'Step',Stage3Milestone3.listName,'0','',ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N',Stage3Milestone3.isDocuments,Stage3Milestone3.listStatus != 'In-Process' && Stage3Milestone3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage3Milestone3.isDocuments}"
                                                                        [disabled]="ViewOnly && !Stage3Milestone3.isDocuments"
                                                                        pTooltip="Documents" tooltipPosition="right"
                                                                        icon="pi pi-file"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">Notes:</span>
                                                                <span class="listitem-value" style="float:right;">
                                                                    <button pButton type="button"
                                                                        (click)="Notes('List',Stage3Milestone3.phaseID,Stage3Milestone3.listID,'Step',Stage3Milestone3.listName,ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N',Stage3Milestone3.isNotes,Stage3Milestone3.listStatus != 'In-Process' && Stage3Milestone3.listStatus != 'Ready for Approval')"
                                                                        [ngClass]="{'p-button-success': Stage3Milestone3.isNotes}"
                                                                        [disabled]="ViewOnly && !Stage3Milestone3.isNotes"
                                                                        pTooltip="Notes" tooltipPosition="right"
                                                                        icon="pi pi-comment"
                                                                        style="width:25px;height:25px;"></button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-md-12 p-xl-12"
                                                    *ngIf="Stage3Mile3Documents?.length > 0"
                                                    style="margin-top: -1.5rem;">
                                                    <div class="invoice invoice-header" id="invoice-content">
                                                        <p-table #dtStage3Mile3Docs [value]="Stage3Mile3Documents"
                                                            [columns]="documents" sortMode="multiple"
                                                            [loading]="loading" styleClass="p-datatable-striped"
                                                            [filterDelay]="0" selectionMode="multiple"
                                                            [(selection)]="selectedStage3Mile3Docs"
                                                            dataKey="reqDocumentID" [autoLayout]="true">
                                                            <ng-template pTemplate="caption">
                                                                <div>Tasks
                                                                    <!-- <span class="p-input-icon-left" style="float:right">
                                                                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right; width:25px; height:25px; margin-top: -4px;" (click)="print()" ></button>
                                                                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:10px;width:25px; ;height:25px; margin-top: -4px;" (click)="Docsdownload('Stage3mile3')"></button>
                                                            </span> -->
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="header" let-columns
                                                                class="invoice-items">
                                                                <tr>
                                                                    <th style="text-align: center"
                                                                        *ngFor="let col of columns;let i = index"
                                                                        [pSortableColumn]="col.field"
                                                                        [ngClass]="{'width120': i == 2, 'width120': i == 4}">
                                                                        {{ col.header }}<p-sortIcon [field]="col.field"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                                                        </p-sortIcon>
                                                                    </th>
                                                                    <th style="text-align: center">Documents</th>
                                                                    <th style="text-align: center">Notes</th>
                                                                </tr>
                                                                <tr>
                                                                    <th *ngFor="let doc of documents"
                                                                        [ngSwitch]="doc.field"
                                                                        style="text-align: center">
                                                                        <input pInputText type="text"
                                                                            (input)="dtStage3Mile3Docs.filter($event.target.value,doc.field, 'startsWith')"
                                                                            placeholder="Search"
                                                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData>
                                                                <tr class="p-selectable-row"
                                                                    [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' ">
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.departmentName}}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <span>{{rowData.documentName}}</span>
                                                                    </td>
                                                                    <td style="text-align: center;"><span>{{
                                                                            rowData.isRequired }} </span></td>
                                                                    <td style="text-align: center;">
                                                                        <span>{{rowData.documentLabels }}</span>
                                                                    </td>
                                                                    <td style="text-align:left; padding-left:15px;">
                                                                        <ul *ngFor="let temp of rowData.templates"
                                                                            class="tempul">
                                                                            <li class="templi"><span
                                                                                    *ngIf="temp.isDocAccess==true || ispe || ispo"><a
                                                                                        href="javascript:;"
                                                                                        (click)="DMSToken(temp)">{{temp.templateName}}
                                                                                    </a> </span>
                                                                                <span
                                                                                    *ngIf="temp.isDocAccess!=true && !ispe && !ispo">{{temp.templateName}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Documents('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,rowData.departmentID,rowData.departmentName,ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N',rowData.isDocuments,Stage3Milestone3.listStatus != 'In-Process' && Stage3Milestone3.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isDocuments}"
                                                                                [disabled]="ViewOnly && !rowData.isDocuments"
                                                                                pTooltip="Documents"
                                                                                tooltipPosition="right"
                                                                                icon="pi pi-file"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                    <td style="text-align: center;">
                                                                        <span class="listitem-value">
                                                                            <button pButton type="button"
                                                                                (click)="Notes('listdocument',rowData.listID,rowData.reqDocumentID,'Document',rowData.documentName,ViewOnly || Stage3.active == 'N' || Stage3Milestone3.active == 'N',rowData.isNotes,Stage3Milestone3.listStatus != 'In-Process' && Stage3Milestone3.listStatus != 'Ready for Approval')"
                                                                                [ngClass]="{'p-button-success': rowData.isNotes}"
                                                                                [disabled]="ViewOnly && !rowData.isNotes"
                                                                                pTooltip="Notes" tooltipPosition="right"
                                                                                icon="pi pi-comment"
                                                                                style="width:25px;height:25px;"></button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="7">No records found...</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </p-tabPanel>
                            </p-tabView>
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>

<p-dialog header="Pending Issues / Milestones" [(visible)]="isuus_tasks" modal="modal" showEffect="fade"
    [style]="{width:'70%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <div *ngIf="ViewIssues">
                <h5 style="text-align:center">Issues</h5>
                <p-table #dtIssues [value]="Issues" [columns]="issuesCol" sortMode="multiple" [loading]="loading"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    [(selection)]="selectIssues">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align:center;"
                                [ngClass]="{'width120': i == 0 || i == 2 || i == 3 || i == 4 || i == 5}"
                                *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                                    [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                </p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field]| date:'MM/dd/yyyy'
                                    }}</td>
                                <td *ngIf="i == 3" style="text-align: center">{{ rowData[col.field]| date:'MM/dd/yyyy'
                                    }}</td>
                                <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 5" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
                <br><br>
            </div>
            <div *ngIf="ViewTasks">
                <h5 style="text-align:center">Milestones</h5>
                <p-table #dtTasks [value]="Tasks" [columns]="TasksCol" sortMode="multiple" [loading]="loading"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    [(selection)]="selectTasks">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align:center;"
                                [ngClass]="{'width120': i == 0 || i == 4 || i == 5,'width60': i == 1,'width180' : i == 3}"
                                *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                                    [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                </p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 1" style="text-align: center">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 4" style="text-align: center;">{{ rowData[col.field] | date:'MM/dd/yyyy'
                                    }}</td>
                                <td *ngIf="i == 5" style="text-align: center;">{{ rowData[col.field] |
                                    date:'MM/dd/yyyy'}}</td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Discontinuation Details" [(visible)]="PClare" modal="modal" showEffect="fade" [style]="{width:'80%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center">{{Product.productName}} Discontinuation Approval Details</h5>
            <p-table #dtDiscontinueApprove [value]="DiscontinueApproveDetails" sortMode="multiple"
                [columns]="discontinueapprovers" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                selectionMode="multiple" [(selection)]="selectedDiscontinue"
                [globalFilterFields]="['actionDate','departmentName','userName','approvalStatus','approveDate','comments']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"
                            [ngClass]="{'width140': i == 0 || i == 6, 'width60': i == 1}">
                            {{ col.header }}<p-sortIcon [field]="col.field"
                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="p-selectable-row">
                        <td style="text-align: center;"><span>{{rowData.actionDate | date:'MM/dd/yyyy'}}</span></td>
                        <td style="text-align: center;"><span>{{rowData.seq}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.userName}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.roleName}}</span></td>
                        <td style="padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                        <td style="padding-left:15px;"><span *ngIf="rowData.type != 'Notification Recipient'">
                                <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                                <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                            </span></td>
                        <td style="text-align: center;"><span
                                *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate |
                                date:'MM/dd/yyyy HH:mm'}}</span></td>
                        <td style="padding-left:15px;" title="{{rowData.comments}}">
                            <span>{{rowData.shortComments}}</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
            <h5 style="text-align:center">{{Product.productName}} Discontinuation Clarification Details</h5>
            <p-table #dtPClarification [value]="PClarification" [columns]="ClarificationCols1" sortMode="multiple"
                [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0"
                selectionMode="multiple" [(selection)]="selectedPClarification">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{
                            col.header }}<p-sortIcon [field]="col.field"
                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                            <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy
                                HH:mm' }}</td>
                            <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{
                                    rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                            <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                            <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>

<p-dialog header="{{ Product.productName }}" [(visible)]="projectdisplay" modal="modal" showEffect="fade">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-xl-6">
            <!-- <h5>Product Information</h5> -->
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:250px;">
                <ul class="widget-image-list">
                    <li>
                        <span style="font-weight: bold;">Product Line:</span>
                        <span>{{ Product.spaceName }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Project:</span>
                        <span>{{ Product.productName }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Project Status:</span>
                        <span>{{ Product.productStatus }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Product Engineer:</span>
                        <span>{{ Product.productOwner }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Current Phase:</span>
                        <span>{{ Product.currentPhase }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-xl-6">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:250px;">
                <ul class="widget-image-list">
                    <li>
                        <span style="font-weight: bold;">Start Date:</span>
                        <span>{{ Product.startDate | date:'MM/dd/yyyy' }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;">Due Date:</span>
                        <span>{{ Product.dueDate | date:'MM/dd/yyyy' }}</span>
                    </li>
                    <li>
                        <span style="font-weight: bold;align-self: baseline;">Description:</span>
                        <span style="text-align:right;">{{ Product.description }}</span>
                    </li>
                    <li *ngIf="Product.productStatus == 'Hold'">
                        <span style="font-weight: bold;align-self: baseline;">Hold Reason:</span>
                        <span style="text-align:right;">{{ Product.reason }}</span>
                    </li>
                    <!-- <li>
                        <span style="font-weight: bold;vertical-align: top;">Concept Files:</span>
                        <span>
                            <div *ngIf='ProductFiles != undefined'>
                                <div style="padding-bottom:10px;" *ngFor="let item of ProductFiles;">
                                    <span>
                                    <a    href="{{ DMSURL }}{{item.filePath}}"> 
                                    {{ item.fileName }} 
                                    </a>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </li> -->
                </ul>
            </div>
        </div>
        <!-- <div class="p-col-12 p-md-6 p-xl-6" *ngIf='CustomFields != undefined'>
            <h5>Additional Attributes</h5>
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:380px;">
                <ul class="widget-image-list">
                    <li *ngFor="let item of CustomFields;">
                        <span style="font-weight: bold;">{{item.customFieldName}}:</span>
                        <span>{{item.value}}</span>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</p-dialog>

<p-dialog header="Documents" [(visible)]="documentsdisplay" modal="modal" showEffect="fade"
    [style]="{'width':'60%','min-height':'600px','background-color':'#fff'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center;">{{Level}}: {{LevelName}}</h5>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="!adddocumentsdisplay" style="margin-top: -1.5rem;">
            <div class="p-col-12 p-md-12 p-xl-12">
                <div class="p-grid" style="border: 1px solid #cccccc; border-radius: 5px;height: 40px;"><br>
                    <div class="p-col-12 p-md-4" style="margin-top: -5px;">
                        <div class="p-field-radiobutton">
                            <p-radioButton name="radiobutton" value="dms" [(ngModel)]="valRadio"
                                (onClick)="onRadioChange('dms')"></p-radioButton>
                            <label>From DMS</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3" style="margin-top: -5px;">
                        <div class="p-field-radiobutton">
                            <p-radioButton name="radiobutton" value="system" [(ngModel)]="valRadio"
                                (onClick)="onRadioChange('system')"></p-radioButton>
                            <label>From Folder</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3"></div>
                    <div class="p-col-12 p-md-2" style="margin-top: -8px;">
                        <button pButton type="button" (click)="AddDocs()" label="Add" pTooltip="Add Docs"
                            tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
                            class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                            style="width: 100px;height:25px; float: right;margin-left:120px;"></button>
                    </div>
                </div>
                <br>
                <div class="p-grid" *ngIf="docdms" style="border: #495057 1px solid;padding-bottom: 5px;">
                    <div class="p-col-12 p-md-2"></div>
                    <div class="p-col-12 p-md-10"><label style="color: orange;">
                            <b>Only documents pertaining to {{Dept}} department will be available to attach</b></label>
                    </div>
                    <div class="p-col-12 p-md-2"></div>
                    <div class="p-col-12 p-md-2">

                        <label><span style="float: right; margin-top:5px;">Documents:</span> </label>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p-multiSelect #cmp [options]="DocumentData" defaultLabel="Select Document"
                            [(ngModel)]="selectedDoc" optionLabel="title" [virtualScroll]="true" [filter]="true"
                            [itemSize]="34" class="multiselect-custom customMulti">
                        </p-multiSelect>
                    </div>
                    <div class="p-col-12 p-md-2"></div>
                </div>
                <div class="p-grid" *ngIf="docsystem">
                    <div class="p-col-12 p-md-12 p-xl-12">
                        <form>
                            <p-fileUpload #fileInput name="file" multiple="multiple" maxFileSize="104857600"
                                accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.rar,.zip"
                                [showUploadButton]="false" [showCancelButton]="false" fileLimit="25" auto="true">
                                <ng-template pTemplate="content">
                                    <ul *ngIf="fileInput.length">
                                        <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                                    </ul>
                                </ng-template>
                            </p-fileUpload>
                        </form>
                        <br />
                        <!-- <p-checkbox [(ngModel)]="IsRestricted" binary="IsRestricted"></p-checkbox> -->
                        <!-- <label style="padding-left:10px;">Is Restricted</label> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
            <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ DocsError
                    }}</small></span>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="viewdocumentsdisplay" style="margin-top: -1.5rem;">
            <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="viewdoccols" sortMode="multiple"
                [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0" [autoLayout]="true"
                selectionMode="multiple" [(selection)]="selectedViewAddedDocuments"
                [globalFilterFields]="['displayName','addedByName','addedDate']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">{{ col.header }}
                            <p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                            </p-sortIcon>
                        </th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    <tr>
                        <th style="text-align: center" [ngSwitch]="addedByName">
                            <input pInputText type="text"
                                (input)="dtViewDocuments.filter($event.target.value,addedByName, 'startsWith')"
                                placeholder="Search" style="margin: -10px 0 -10px 0; width:60%; height:25px;" />
                        </th>
                        <th style="text-align: center" [ngSwitch]="addedDate">
                            <input pInputText type="text"
                                (input)="dtViewDocuments.filter($event.target.value,addedDate, 'startsWith')"
                                placeholder="Search" style="margin: -10px 0 -10px 0; width:60%; height:25px;" />
                        </th>
                        <th style="text-align: center" [ngSwitch]="displayName">
                            <input pInputText type="text"
                                (input)="dtViewDocuments.filter($event.target.value,displayName, 'startsWith')"
                                placeholder="Search" style="margin: -10px 0 -10px 0; width:60%; height:25px;" />
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="p-selectable-row">
                        <td style="text-align: center"><span>{{rowData.addedByName}}</span></td>
                        <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                        <td style="text-align: center"><span *ngIf="rowData.isDocAccess==true || ispe || ispo"><a
                                    href="javascript:;" (click)="DMSToken(rowData)">{{rowData.displayName}} </a> </span>
                            <span *ngIf="rowData.isDocAccess!=true && !ispe && !ispo">{{rowData.displayName}}</span>
                        </td>
                        <td style="text-align: center;width: 50px;">
                            <button pButton type="button"
                                [disabled]="ViewOnly || !deletedocument || adddocumentsdisplay || (CurrentPhase.phaseStatus != 'Ready to Start' && CurrentPhase.phaseStatus != 'In-Process' && CurrentPhase.phaseStatus != 'Ready for Approval')"
                                (click)="removedocument(rowData.addedBy,rowData.id)" label="" icon="pi pi-minus"
                                class="p-button-primary p-button-info p-mr-2 p-mb-2"
                                style="width: 25px; height: 25px;"></button>
                            <p-confirmDialog key="confirm-document" header="Delete Document" acceptLabel="Yes"
                                rejectLabel="No" [(visible)]="confirmDeleteDocument"></p-confirmDialog>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    </div>
</p-dialog>
<p-confirmDialog key="confirm-cancelwith" header="Withdraaw Discontinue Product" acceptLabel="Yes" rejectLabel="No"
    [(visible)]="confirmCancelwith"></p-confirmDialog>
<p-confirmDialog key="confirm-cancel" header="Discontinue Product" acceptLabel="Yes" rejectLabel="No"
    [(visible)]="confirmCancel"></p-confirmDialog>
<p-confirmDialog key="confirm-hold" header="Hold Product" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmHold">
</p-confirmDialog>
<p-confirmDialog key="confirm-release" header="Release Product" acceptLabel="Yes" rejectLabel="No"
    [(visible)]="confirmRelease"></p-confirmDialog>
<p-dialog header="Reason" [(visible)]="reasondisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center;">Product: {{Product.productName}}</h5>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
            <div class="p-col-12 p-md-12 p-xl-12">
                <label>{{Action}} Reason <span style="color: red">*</span> </label>
                <textarea rows="5" pInputTextarea id="popupreason" [(ngModel)]="popupreason" placeholder="Enter Reason"
                    required="true" requiredMessage="please enter reason." maxlength="1000" ng-trim="false"
                    style="width: 100%;"></textarea>
                <small class="p-invalid">{{1000 - popupreason.length}} characters are remaining</small>
            </div>
            <div class="p-col-12 p-md-12 p-xl-12">
                <span style="float: right;">
                    <button pButton type="button" (click)="ReasonClear()" label="Clear" icon="pi pi-refresh"
                        pTooltip="Clear" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                        style="width: 100px;height: 25px;float: right;"></button>
                    <button pButton type="button" (click)="AddReason()" label="Confirm" icon="pi pi-plus"
                        pTooltip="Confirm" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                        style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
            <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ ReasonError
                    }}</small></span>
        </div>
    </div>
</p-dialog>

<p-dialog header="Notes" [(visible)]="notesdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center;">{{Level}}: {{LevelName}}</h5>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="!addnotesdisplay" style="margin-top: -3.5rem;">
            <div class="p-col-12 p-md-12 p-xl-12">
                <label> Notes <span style="color: red">*</span> </label>
                <textarea rows="5" pInputTextarea id="popupnote" [(ngModel)]="popupnote" placeholder="Enter Notes"
                    required="true" requiredMessage="please enter notes." maxlength="1000" ng-trim="false"
                    style="width: 100%;"></textarea>
                <small class="p-invalid">{{1000 - popupnote.length}} characters are remaining</small>
            </div>
            <div class="p-col-12 p-md-12 p-xl-12" *ngIf="ViewAddnotesdisplay">
                <span style="float: right;">
                    <button pButton type="button" (click)="NotesClear()" label="Clear" icon="pi pi-refresh"
                        pTooltip="Clear" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                        style="width: 100px;height: 25px;float: right;"></button>
                    <button pButton type="button" (click)="AddNote()" label="Add" icon="pi pi-plus" pTooltip="Add"
                        tooltipPosition="right" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                        style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
            <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ NoteError
                    }}</small></span>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="viewnotesdisplay" style="margin-top: -1.5rem;">
            <p-table #dtViewNotes [value]="ViewAddedNotes" sortMode="multiple" [columns]="viewnotecols"
                [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0" [autoLayout]="true"
                selectionMode="multiple" [(selection)]="selectedViewAddedNotes"
                [globalFilterFields]="['note','addedByName','addedDate']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">{{ col.header }}
                            <p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                            </p-sortIcon>
                        </th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    <tr>
                        <th style="text-align: center" [ngSwitch]="addedByName">
                            <input pInputText type="text"
                                (input)="dtViewNotes.filter($event.target.value,addedByName, 'startsWith')"
                                placeholder="Search" style="margin: -10px 0 -10px 0; width:60%; height:25px;" />
                        </th>
                        <th style="text-align: center" [ngSwitch]="addedDate">
                            <input pInputText type="text"
                                (input)="dtViewNotes.filter($event.target.value,addedDate, 'startsWith')"
                                placeholder="Search" style="margin: -10px 0 -10px 0; width:60%; height:25px;" />
                        </th>
                        <th style="text-align: center" [ngSwitch]="note">
                            <input pInputText type="text"
                                (input)="dtViewNotes.filter($event.target.value,note, 'startsWith')"
                                placeholder="Search" style="margin: -10px 0 -10px 0; width:60%; height:25px;" />
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="p-selectable-row">
                        <td style="text-align: center">{{rowData.addedByName}}</td>
                        <td style="text-align: center">{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</td>
                        <td style="text-align: center">
                            <div class="p-mb-3 demo-container p-text-nowrap p-text-truncate" style="width:15rem"
                                (click)=tablenotes(rowData.note)>{{rowData.shortnote}}</div>
                        </td>
                        <td style="text-align: center;width: 50px;">
                            <button pButton type="button"
                                [disabled]="ViewOnly || !deletenote || addnotesdisplay || (CurrentPhase.phaseStatus != 'Ready to Start' && CurrentPhase.phaseStatus != 'In-Process' && CurrentPhase.phaseStatus != 'Ready for Approval')"
                                (click)="removenote(rowData.addedBy,rowData.id)" label="" icon="pi pi-minus"
                                class="p-button-primary p-button-info p-mr-2 p-mb-2"
                                style="width: 25px; height: 25px;"></button>
                            <p-confirmDialog key="confirm-note" header="Delete Note" acceptLabel="Yes" rejectLabel="No"
                                [(visible)]="confirmDeleteNote"></p-confirmDialog>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>

<!-- <p-dialog header="Response to clarification - questions" [(visible)]="viewclarifydisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
    <hr>                                       
   <div class="p-grid">
       <div class="p-col-12 p-md-12 p-xl-12">
           <div class="card">
               <h5 style="text-align:center;">{{Level}}: {{LevelName}}</h5>
               <p-table #dtClarify [value]="ViewClarify" [columns]="ClarificationCols1" sortMode="multiple" [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0"
                   selectionMode="multiple" [(selection)]="selectedViewClarify">
                   <ng-template pTemplate="header" let-columns class="invoice-items">
                       <tr>
                           <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                           <th>Action</th>
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="body" let-rowData>
                       <tr class="p-selectable-row">
                           <td style="text-align: center;"><span>{{rowData.askedDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                           <td style="text-align:left; padding-left:15px;"><span>{{rowData.question}}</span></td>
                           <td style="text-align:left; padding-left:15px;"><span>{{rowData.askedByName}}</span></td>
                           <td style="text-align: center;"><span *ngIf="rowData.answer">{{rowData.answerDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                           <td style="text-align:left; padding-left:15px;"><span>{{rowData.answer}}</span></td>
                           <td style="text-align:left; padding-left:15px;"><span>{{rowData.answerByName}}</span></td>
                           <td style="text-align: center">
                            <button pButton type="button" [disabled]="rowData.answer" label="Respond" (click)="Response(rowData.clarificationID,rowData.question)"
                                icon="pi pi-answer" iconPos="right" class="p-button-outlined p-button-seconday p-mr-2 p-mb-2"></button>
                           </td>
                       </tr>
                   </ng-template> 
                   <ng-template pTemplate="emptymessage">
                       <tr><td colspan="7">No records found...</td></tr>
                   </ng-template>
                </p-table>
           </div>
           <div class="card" *ngIf= "addclar1" >
            <div class="p-grid p-fluid">
                <div class="p-col-12" >
                    <h5 style="text-align:center;">{{Question}}</h5> 
                    <textarea  rows="6" pInputTextarea id="popupquestion" [(ngModel)]="popupquestion" placeholder="Enter response"
                    required="true" requiredMessage="please enter response." maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
                    <small class="p-invalid">{{1000 - popupquestion.length}} characters are remaining</small>
                    <br>
                    <small class="p-invalid">{{ QuestionError }}</small>
                </div> 
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-8" > </div>
                <div class="p-col-12 p-md-2" >
                    <button pButton type="button" (click)="AddAnswer()" label="Continue" pTooltip="Continue" tooltipPosition="right" icon="pi pi-plus" style="width:100px;height:25px;" class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                </div>
                <div class="p-col-12 p-md-2" >
                    <button pButton type="button" (click)="QuestionClear()" label="Reset" pTooltip="Reset" tooltipPosition="right" icon="pi pi-refresh" style="width:100px;height:25px;" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                </div>
            </div>
           </div>
       </div>
   </div>            
</p-dialog> -->
<p-dialog header="Comments" [(visible)]="CommentsDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ CommentsShow }}</p>
</p-dialog>
<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<p-dialog header="{{EvalCondiDisplayName}}" [(visible)]="ConditionalReleaseDisplay" modal="modal" showEffect="fade"
    [style]="{width:'90%'}">
    <hr>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Quantity Limit<span style="color: red">*</span></label>
            <input (keypress)="numberOnly($event)" [disabled]="conditionalFieldsDisabled" [(ngModel)]="QuantityLimit"
                type="text" pInputText placeholder="Enter Quantity Limit" />
            <small class="p-invalid">{{QuantityLimitErrorMessage}}</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Time Limit</label><span style="color: red">*</span>
            <p-calendar [minDate]="minDate" [showIcon]="true" inputId="icon" [(ngModel)]="TimeLimit"
                [disabled]="conditionalFieldsDisabled"></p-calendar>
            <small class="p-invalid">{{TimeLimitError}}</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Status</label>
            <input [(ngModel)]="status" type="text" pInputText disabled />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="EvalConditionalType==1"><br>
            <div class="p-field-radiobutton" style="padding-top: 15px;">
                <p-radioButton name="radiobutton" [disabled]="conditionalFieldsDisabled" value="Yes"
                    [(ngModel)]="IsManufacturingAndShipping"></p-radioButton>
                <label>Manufacturing</label>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-4" *ngIf="EvalConditionalType==1"><br>
            <div class="p-field-radiobutton" style="padding-top: 15px;">
                <p-radioButton name="radiobutton" [disabled]="conditionalFieldsDisabled" value="No"
                    [(ngModel)]="IsManufacturingAndShipping"></p-radioButton>
                <label>Manufacturing and Shipping</label>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="status=='New'">
            <label>Supporting Docs</label>
            <p-fileUpload #fileInput name="file" multiple="multiple" maxFileSize="104857600"
                accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.rar,.zip"
                [disabled]="conditionalFieldsDisabled" [showUploadButton]="false" [showCancelButton]="false"
                fileLimit="25" auto="true">
                <ng-template pTemplate="content">
                    <ul *ngIf="conditionalDocs.length>0">
                        <li *ngFor="let file of conditionalDocs" style="padding-top: 15px;">
                            <span *ngIf="file.isDocAccess==true || ispe || ispo"><a href="javascript:;"
                                    (click)="DMSToken(file)">{{file.displayName}} </a> </span>
                            <span *ngIf="file.isDocAccess!=true && !ispe && !ispo">{{file.displayName}}</span>
                            <button *ngIf="status=='New'" pButton type="button" pTooltip="Delete"
                                tooltipPosition="right" icon="pi pi-times" (click)="removeCondDoc(file.fileGuid)"
                                class="p-button-raised p-button-primary"
                                style="height:25px; width:25px;margin-left: 25px;margin-top: 5px;"></button>

                        </li>
                    </ul>
                </ng-template>
            </p-fileUpload>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="status!='New' && conditionalDocs.length>0">
            <label>Supporting Docs</label>
            <ul>
                <li *ngFor="let file of conditionalDocs">
                    <span *ngIf="file.isDocAccess==true || ispe || ispo"><a href="javascript:;"
                            (click)="DMSToken(file)">{{file.displayName}} </a> </span>
                    <span *ngIf="file.isDocAccess!=true && !ispe && !ispo">{{file.displayName}}</span>
                </li>
            </ul>
        </div>
        <div class="p-field p-col-12">
            <label for="Comment">Justification</label>
            <textarea [disabled]="conditionalFieldsDisabled" [(ngModel)]="Justification" rows="3"
                placeholder="Your Justification" maxlength="280" pInputTextarea
                style="opacity: 1;overflow:auto;resize:none;">
            </textarea>
            <br /><br />
            <div *ngIf="status!='New'">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px;">Approval Details </h5>
                </div>
                <!-- <h6 style="color:#495057;">Approval History</h6>  -->
                <p-table #dt [value]="LIissuesData" [columns]="cols1" sortMode="multiple" class="table-align"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align: center;width: 60px;">S.No</th>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                {{ col.header }}<p-sortIcon [field]="col.field"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 4" style="text-align: center;">
                                    <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                                    <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                                </td>
                                <td *ngIf="i == 5" style="text-align: center;"><span
                                        *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate |
                                        date:'MM/dd/yyyy HH:mm'}}</span></td>
                                <td *ngIf="i == 6" style="text-align: left; padding-left:15px;"
                                    title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                                <!-- 
                        <td *ngIf="i == 6" style="text-align: center;">
                            <button pButton type="button" pTooltip="View Comment" tooltipPosition="right" (click)="ViewComment(rowData.comment)" 
                            icon="pi pi-eye" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                        </td> -->
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <br>
            <div *ngIf="status!='New'">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px;">Clarification Details </h5>
                </div>
                <p-table #dtClarification1 [value]="Clarification1" [columns]="ClarificationCols" sortMode="multiple"
                    [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    [(selection)]="selectedClarification1">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align: center;width: 60px;">S.No</th>
                            <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{
                                col.header }}<p-sortIcon [field]="col.field"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                            </th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy
                                    HH:mm' }}</td>
                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{
                                        rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                                <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                            </ng-container>
                            <td style="text-align: center">
                                <button pButton type="button" *ngIf="!rowData.answer && rowData.active" label="Reply"
                                    (click)="ResponseCon(rowData.clarificationID,rowData.question,rowData.askedByName)"
                                    icon="pi pi-answer" iconPos="right"
                                    class="p-button-outlined p-button-seconday p-mr-2 p-mb-2"></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="card" *ngIf="addclar2">
                <div class="p-grid p-fluid">
                    <div class="p-col-12">
                        <br>
                        <h5 style="text-align:left;">{{askedByName}}: {{Question}}</h5>
                        <textarea rows="6" pInputTextarea id="popupquestion" [(ngModel)]="popupquestion"
                            placeholder="Enter response" required="true" requiredMessage="please enter response."
                            maxlength="1000" ng-trim="false" style="width: 100%;"></textarea>
                        <small class="p-invalid">{{1000 - popupquestion.length}} characters are remaining</small>
                        <br>
                        <small class="p-invalid">{{ QuestionError }}</small>
                    </div>
                </div>
                <div class="p-grid p-fluid" *ngIf="QButton">
                    <div class="p-col-12 p-md-9"> </div>
                    <div class="p-col-12 p-md-3">
                        <button pButton type="button" (click)="AddAnswer()" label="Submit" pTooltip="Submit"
                            tooltipPosition="right" icon="pi pi-plus" style="width:100px;height:25px;"
                            class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                        <button pButton type="button" (click)="QuestionClear()" label="Reset" pTooltip="Reset"
                            tooltipPosition="right" icon="pi pi-refresh" style="width:100px;height:25px;"
                            class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-field p-col-12" *ngIf="status == 'New'">
            <span *ngIf="conditionalId > 0">
                <button pButton pRipple type="button" icon="pi pi-times" iconPos="left" label="Delete"
                    [disabled]="conditionalFieldsDisabled"
                    class="p-button-primary p-button-info p-button-danger p-mr-2 p-mb-2 width100"
                    style="width: auto;float: right;margin-left: 5px;"
                    (click)="ConditionalDeleteDocument=true;"></button>
            </span>
            <!-- *ngIf="!IsConditionalExistInProcess && !IsEvalutionExistInProcess" -->
            <span *ngIf="conditionalId == 0">
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left"
                    label="Save and Send for Approval" class="p-button-raised p-button-success p-mr-2 p-mb-2 width200"
                    [disabled]="conditionalFieldsDisabled" style="width: auto;float: right;margin-left: 5px;"
                    (click)="SaveConditionalRelease('SA')"></button>
            </span>
            <span *ngIf="conditionalId == 0">
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                    [disabled]="conditionalFieldsDisabled"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2 width100"
                    style="width: auto;float: right;margin-left: 5px;" (click)="SaveConditionalRelease('S')"></button>
            </span>
            <span *ngIf="conditionalId > 0">
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left"
                    [disabled]="conditionalFieldsDisabled" label="Update and Send for Approval"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2 width200"
                    style="width: auto;float: right;margin-left: 5px;" (click)="SaveConditionalRelease('SA')"></button>
            </span>
            <span *ngIf="conditionalId > 0">
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update"
                    [disabled]="conditionalFieldsDisabled"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2 width100"
                    style="width: auto;float: right;margin-left: 5px;" (click)="SaveConditionalRelease('S')"></button>
            </span>
            <small class="p-invalid">{{ConditionalError}}</small>
        </div>
    </div>
</p-dialog>
<p-dialog header="{{PrevCRName}}" [(visible)]="PrevDisplay" modal="modal" showEffect="fade" [style]="{width:'90%'}">
    <hr>
    <div class="p-fluid p-formgrid p-grid" *ngFor="let cr of prevCR">
        <div class="p-field p-col-12 p-md-2">
            <label><b>Quantity Limit:</b>&nbsp;&nbsp;{{cr.quantityLimit}}</label>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label><b>Time Limit:</b>&nbsp;&nbsp;{{cr.timeLimit | date:'MM/dd/yyyy'}}</label>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label><b>Status:</b>&nbsp;&nbsp;{{cr.status}}</label>
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="prevcrtype == 1">
            <label><b>Manufacturing:</b>&nbsp;&nbsp;<span *ngIf="cr.isManufacturingAndShipping == true">Yes</span>
                <span *ngIf="cr.isManufacturingAndShipping != true ">No</span></label>
        </div>
        <div class="p-field p-col-12 p-md-4" *ngIf="prevcrtype == 1">
            <label><b>Manufacturing and Shipping:</b>&nbsp;&nbsp;<span
                    *ngIf="cr.isManufacturingAndShipping == true">No</span>
                <span *ngIf="cr.isManufacturingAndShipping != true ">Yes</span></label>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="cr.viewDocuments != null && cr.viewDocuments.length > 0">
            <label><b>Supporting Docs</b></label>
            <ul>
                <li *ngFor="let file of cr.viewDocuments">
                    <span *ngIf="file.isDocAccess==true || ispe || ispo"><a href="javascript:;"
                            (click)="DMSToken(file)">{{file.displayName}} </a> </span>
                    <span *ngIf="file.isDocAccess!=true && !ispe && !ispo">{{file.displayName}}</span>
                </li>
            </ul>
        </div>
        <div class="p-field p-col-12">
            <label><b>Justification:</b>&nbsp;&nbsp;{{cr.justification}}</label>
            <br />
            <div *ngIf="cr.approvals != null && cr.approvals != undefined && cr.approvals.length > 0">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px;">Approval Details </h5>
                </div>
                <p-table #dt [value]="cr.approvals" [columns]="cols1" sortMode="multiple" class="table-align"
                    styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align: center;width: 60px;">S.No</th>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                [ngClass]="{'width150': i == 0 || i == 3, 'width180': i == 1 || i == 2}">
                                {{ col.header }}<p-sortIcon [field]="col.field"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 4" style="text-align: center;">
                                    <span *ngIf="rowData.approvalStatus == 'Approve'">Approved</span>
                                    <span *ngIf="rowData.approvalStatus != 'Approve'">{{rowData.approvalStatus}}</span>
                                </td>
                                <td *ngIf="i == 5" style="text-align: center;"><span
                                        *ngIf="rowData.approvalStatus != 'Waiting for Approval'">{{rowData.approveDate |
                                        date:'MM/dd/yyyy HH:mm'}}</span></td>
                                <td *ngIf="i == 6" style="text-align: left; padding-left:15px;"
                                    title="{{rowData.remarks}}">{{ rowData[col.field] }}</td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <br>
            <div *ngIf="cr.clarifications != null && cr.clarifications != undefined && cr.clarifications.length > 0">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px;">Clarification Details </h5>
                </div>
                <p-table #dtClarification1 [value]="cr.clarifications" [columns]="ClarificationCols" sortMode="multiple"
                    [loading]="loading" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th style="text-align: center;width: 60px;">S.No</th>
                            <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{
                                col.header }}<p-sortIcon [field]="col.field"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                                <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy
                                    HH:mm' }}</td>
                                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                                <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{
                                        rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                                <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <br>
            <div style="border-top:solid 1px;"></div>
        </div>
    </div>
</p-dialog>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !important;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Delete" [(visible)]="ConditionalconfirmDeleteDocument" modal="modal" showEffect="fade"
    [style]="{width:'50%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center;">Are you sure you want to delete a document?</h5>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
            <div class="p-col-12 p-md-12 p-xl-12">
                <span style="float: right;">
                    <button pButton type="button" (click)="removedConditionaldocument()" label="Yes" icon="pi pi-check"
                        pTooltip="Yes" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                        style="width: 100px;height: 35px;float: right;"></button>
                    <button pButton type="button" (click)="ConditionalconfirmDeleteDocument=false;" label="No"
                        icon="pi pi-times" pTooltip="No" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                        style="width: 100px;height: 30x;float: right;margin-left: 120px;"></button>
                </span>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Delete" [(visible)]="ConditionalDeleteDocument" modal="modal" showEffect="fade"
    [style]="{width:'50%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <h5 style="text-align:center;">Are you sure you want to delete {{EvalCondiDisplayName}}?</h5>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -2.5rem;">
            <div class="p-col-12 p-md-12 p-xl-12">
                <span style="float: right;">
                    <button pButton type="button" (click)="DeleteConditionalRelease()" label="Yes" icon="pi pi-check"
                        pTooltip="Yes" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                        style="width: 100px;height: 35px;"></button>
                    <button pButton type="button" (click)="ConditionalDeleteDocument=false;" label="No"
                        icon="pi pi-times" pTooltip="No" tooltipPosition="right"
                        class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                        style="width: 100px;height: 30x;margin-left: 10px;"></button>
                </span>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Reasons" [(visible)]="projectreasonsdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <p-table #dtReasons [value]="Reasons" [columns]="Reasoncols" sortMode="multiple" [loading]="loading"
                [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                [(selection)]="selectedReason">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align: center; width:50px;">S.No
                        </th>
                        <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{
                            col.header }}
                            <p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                            </p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                            <td *ngIf="i == 0" style="text-align: center;width: 50px;;">{{
                                j + 1 }}</td>
                            <td *ngIf="i == 0" style="padding-left:15px;">{{
                                rowData[col.field] }}</td>
                            <td *ngIf="i == 1" style="padding-left:15px;">{{
                                rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                            <td *ngIf="i == 2" style="padding-left:15px;">{{
                                rowData[col.field] }}</td>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>
<p-dialog header="Information" [(visible)]="TeamInfoDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
    <hr>
    <div class="p-grid">

        <div class="p-col-12" style="text-align: center;">
            <span style="font-size:18px;">Already answered this question.</span>
        </div>
    </div>
</p-dialog>

<p-dialog header="Team" [(visible)]="clariTeamdisplay" modal="modal" showEffect="fade" [style]="{width:'25%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <p-table #dt [value]="SelectedTeamDisplay" [columns]="Seekteamcols" sortMode="multiple" [loading]="loading"
                [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align: center;width: 50px;">S.No</th>
                        <th style="text-align: center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{
                            col.header }}<p-sortIcon [field]="col.field"
                                style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                            <td *ngIf="i == 0" style="text-align: center;width: 50px;">{{ j + 1 }}</td>
                            <td *ngIf="i == 0" style="text-align: left;">{{ rowData[col.field] }}</td>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>