
<div class="p-col-12">
  <div class="card">
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
      <h5 style="color:#495057; padding:15px 0 0 15px;">Projects Yearwise</h5> 
      <span class="p-input-icon-left" style="float:right; margin-right:5px;width: 90px;"> 
        <p-calendar [(ngModel)]="date1"></p-calendar> 
      </span>   
    </div>  
    <br>              
    <div class="card-header" style=" border-radius: 5px;height:40px; margin-bottom:5px; ">
      <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
      <span class="p-input-icon-left" style="float:right; margin-right:5px;">  
        <label style="padding-right:10px; margin-left:10px;">Phase Items :</label>
        <p-multiSelect [options]="phasesData" [(ngModel)]="selectedPhases" defaultLabel="Select Phase" optionLabel="name"> </p-multiSelect>
        <label style="padding-right:10px; margin-left:10px;">Product Engineer :</label>
        <p-multiSelect [options]="prodOwnerData" [(ngModel)]="selectedprodOwnerData" defaultLabel="Select Product Engineer" optionLabel="fullName">
        </p-multiSelect>
        <label style="padding-right:10px; margin-left:10px;">From :</label>
        <p-dropdown [options]="yearOptions" [(ngModel)]="selectedfromYear"></p-dropdown>
        <label style="padding-right:10px; margin-left:10px;">To :</label>
        <p-dropdown [options]="yearOptions" [(ngModel)]="selectedtoYear"></p-dropdown>
        <a>
          <button pButton pRipple type="button" icon="pi pi-refresh"
          (click)="resetCtrls()" label="Reset" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
          style="float:right; margin-left:10px ; "></button>
          <button (click)="GetPieChartProjectsData()" pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" class="p-button-raised p-button-primary "  style="width:auto; float:right; margin-left:10px;"></button>
        </a>
      </span>
    </div> 
    <span style="color: red;">{{error}}</span>

    <!-- <div class="p-col-12 p-lg-12">
      <div class="card">
        <h4 style="padding-bottom:15px;text-align: center;">Projects Bar Chart</h4>
        <p-chart type="bar" [data]="data" [options]="options"></p-chart>
      </div>
    </div> -->
    <div class="p-col-12 p-lg-12">
      <div class="revenue chart">
        <div class="revenue-chart-container" style="width:1200px;margin:auto;">
          <p-chart type="bar" id="revenue-chart" [data]="data" [options]="options" [responsive]="true"></p-chart>
        </div>
      </div>
    </div>
  
    <br/><br/><br/>
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
      <h5 style="color:#495057; padding:15px 0 0 15px;">Projects - {{ProjectCount}}</h5> 
      <span class="p-input-icon-left" style="float:right;padding-top: 5px;">
        <span class="p-input-icon-left">
            <button pButton pRipple type="button" (click)="print('invoice-content','trsearch')" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"  class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 12px;" ></button> 
            <button pButton pRipple type="button" (click)="download()" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 12px;" ></button>
        </span> 
      </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">           
      <p-table #dt (sortFunction)="customSort($event)" [customSort]="true" [value]="projects" id="excel-table"  [columns]="cols" [loading]="loading" [autoLayout]="true"
        styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedProducts"
        [scrollable]="true" scrollHeight="300px"
        [globalFilterFields]="['year','productName','dieCode','mfgPart','productOwner','phase','phaseStartDate','phaseEndDate','productStatus']"  dataKey="productID"> 
        <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>
            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
            [ngClass]="{'width80':i ==0,'w20':i==1 || i == 3 || i ==8, 'width100':  i==2,'width150':i == 4,'width110':i==5, 'width140':i == 6 || i ==7}" >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
            <th style="text-align: center;width: 70px;">Actions</th>
          </tr>
          <tr id="trsearch">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
              <input pInputText type="text" (input)="test($event.target.value,col.field,dt)"  placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
            </th>
            <th style="text-align: center"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
         <tr [pSelectableRow]="rowData">
           <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
             <td *ngIf="i == 0" style="text-align: center;width: 80px;">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 1" style="text-align: left; padding-left:15px; width: 20%;">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 100px;">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 150px">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width: 110px; font-weight: bold;" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 6" style="text-align: center;width: 140px;" >{{ rowData[col.field] }}</td>
             <td *ngIf="i == 7" style="text-align: center;width: 140px;">{{ rowData[col.field] }}</td>
             <td *ngIf="i == 8" style="text-align: left; padding-left:15px; width: 20%;">{{ rowData[col.field] }}</td>
           </ng-container>
           <td style="text-align: center;width: 70px;">
             <button pButton type="button" pTooltip="View"  tooltipPosition="right"  (click)="Details(rowData.productID, rowData.phaseID,rowData.type)"
             icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
           </td>
         </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
         <tr>
             <td colspan="11" style="text-align: left;">No records found...</td>
         </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>