import { Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../services/printcommon.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardCount, Timeline, TimelineOptions, DashboardProducts } from "../../model/DashboardData";
import { DomSanitizer } from "@angular/platform-browser";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isExpanded: boolean = false;
  expandedRows = {};
  userId = sessionStorage.getItem('userGuid');
  loading: "loading";
  Counts: DashboardCount;
  ProductCountFilter: number = 0;
  showLoading: boolean;
  AllProductsValue: boolean = false;

  phaseChart: any;
  labels: string[] = [];
  data: number[] = [];

  
  Timeline: Timeline[];
  TimelineOptions: TimelineOptions[];
  selectedTimelineOption: TimelineOptions;
  options: any;
  cols: any[];
  savelabel: any = "Expand All";
  products = [];
  productsD = [];//DashboardProducts[];
  selectedProducts = [];
  @ViewChild('dt') table: Table;
  isTimelineDataAvailable: boolean = false;
  isChartDataAvailable: boolean = false;
  dyndays: any;
  ProductCount: number = 0;
  selectedfilter ="startsWith";
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintcommonService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    this.Counts = new DashboardCount();
  }


  ngOnInit() {
    this.AllProductsValue = false;
    if (sessionStorage.allproducts != undefined && sessionStorage.allproducts != null)
      this.AllProductsValue = sessionStorage.allproducts == "true" ? true : false;

    this.TimelineOptions = [
      { name: 'Week', value: 7 },
      { name: '2 Weeks', value: 14 },
      { name: '3 Weeks', value: 21 },
      { name: 'Month', value: 30 },
    ];

    this.getCounts();
    //this.getChartData();
    this.cols = [
      { field: 'productName', header: 'Product' },
      { field: 'dieCode', header: 'Die Code' },
      { field: 'mfgPart', header: 'Mfg Part' },
      { field: 'projectDescription', header: 'Project Description' },
      { field: 'productLine', header: 'Product Line' },
      { field: 'foundry', header: 'Foundry' },
      { field: 'subcon', header: 'Subcon' },
      { field: 'productOwner', header: 'Product Engineer' },
      { field: 'productStatus', header: 'Product Status' },
      { field: 'phase', header: 'Phase' },
      { field: 'phaseStatus', header: 'Phase Status' },
      { field: 'phaseStartDate', header: 'Phase Start Date' },
    ];

    sessionStorage.productName = "";
    sessionStorage.dieCode = "";
    sessionStorage.mfgPart = "";
    sessionStorage.projectDescription = "";
    sessionStorage.productLine = "";
    sessionStorage.foundry = "";
    sessionStorage.subcon = "";
    sessionStorage.productOwner = "";
    sessionStorage.phase = "";
    sessionStorage.phaseStartDate = "";
    this.getproducts();
    this.GetTimeline(7, '');
  }

  GetTimeline(Days, from) {
    this.showLoading = true;
    if (Days === 7) { this.dyndays = Days }
    else { this.dyndays = Days.value }
    this._facadeService.Timeline(this.dyndays, this.userId).subscribe(
      (data) => {
        this.Timeline = data.returnObject;
        if ((this.Timeline !== null || this.Timeline !== undefined) && this.Timeline.length > 0) {
          this.isTimelineDataAvailable = true;
          var dict = []; // create an empty dictionary  
          for (let i = 0; i < this.Timeline.length; i++) {
            let actiondate = new Date(new Date(this.Timeline[i].actionDate).toDateString() + " " + new Date(this.Timeline[i].actionDate).toTimeString() + " UTC");
            dict.push({
              productID: this.Timeline[i].productID,
              phaseID: this.Timeline[i].phaseID,
              transactionID: this.Timeline[i].transactionID,
              type: this.Timeline[i].type,
              title: this.Timeline[i].title,
              subTitle: this.sanitizer.bypassSecurityTrustHtml(this.Timeline[i].subTitle1
                + '<a style="cursor:pointer;" (click)="Details(' + this.Timeline[i].productID + ',' + this.Timeline[i].phaseID + ',' + this.Timeline[i].type + ')">'
                + this.Timeline[i].subTitle2 + '</a> on '
                //+ this.Timeline[i].subTitle3 + "(UTC)") ,
                + actiondate.toLocaleDateString() + ' ' + actiondate.toLocaleTimeString()),
              icon: this.Timeline[i].icon,
              color: this.Timeline[i].color,
              actionBy: this.Timeline[i].actionBy,
              actionDate: this.Timeline[i].actionDate
            });
          }
          this.Timeline = dict;
        }
        if (from == "C")
          this.showLoading = false;
      },
      (error) => { console.log(error); this.showLoading = false; }
    );
  }
  getCounts() {
    this.showLoading = true;
    this._facadeService.DashboardCounts(this.userId).subscribe(
      (data) => {
        this.Counts = data.returnObject;
        console.log(this.Counts);
      },
      (error) => { console.log(error); this.showLoading = false; }
    );
  }

  getChartData() {
    this.showLoading = true;
    this.data = [];
    this.labels = [];
    this.isChartDataAvailable = false;
    this._facadeService.Chart(this.userId).subscribe(
      (res) => {
        for (let i = 0; i < res.returnObject.length; i++) {
          this.labels.push(res.returnObject[i].phase);
        }
        for (let i = 0; i < res.returnObject.length; i++) {
          this.data.push(res.returnObject[i].count);
          if (res.returnObject[i].count > 0) {
            this.isChartDataAvailable = true;
          }
        }
        this.phaseChart = {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: [
                '#c14a09',// '#00FF00',
                '#FFA500',
                '#008000',
                '#0000FF',
                '#3b2820',//'#90EE90',
                '#800080',
                '#2196F3',
              ],
            },
          ],
        };

        this.options = {
          legend: {
            display: true, position: 'right', align: 'center',
            labels: { fontColor: "#7b7c7c", fontSize: 16, padding: 24 }
          },
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getChartData1() {
    this.showLoading = true;
    this.data = [];
    this.labels = [];
    this.isChartDataAvailable = false;
    this._facadeService.Chart("").subscribe(
      (res) => {
        for (let i = 0; i < res.returnObject.length; i++) {
          this.labels.push(res.returnObject[i].phase);
        }
        for (let i = 0; i < res.returnObject.length; i++) {
          this.data.push(res.returnObject[i].count);
          if (res.returnObject[i].count > 0) {
            this.isChartDataAvailable = true;
          }
        }
        this.phaseChart = {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: [
                '#c14a09',// '#00FF00',
                '#FFA500',
                '#008000',
                '#0000FF',
                '#3b2820',//'#90EE90',
                '#800080',
                '#2196F3',
              ],
            },
          ],
        };

        this.options = {
          legend: {
            display: true, position: 'right', align: 'center',
            labels: { fontColor: "#7b7c7c", fontSize: 16, padding: 24 }
          },
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  //  refreshchart()
  //   {
  //     if(sessionStorage.dahsboardDT != null && sessionStorage.dahsboardDT != undefined)
  //     {
  //       this.data = [];
  //       var dt = JSON.parse(sessionStorage.getItem('dahsboardDT'));
  //       var res = [];
  //       for (let i = 0; i < this.labels.length; i++) {
  //         var records = dt.filter(x=> x.phase.toLowerCase().includes(this.labels[i].toLowerCase()));
  //         res.push(records.length);
  //       }
  //       for (let i = 0; i < res.length; i++) {
  //         this.data.push(res[i]);
  //       }
  //       if(this.data.length>0)
  //         this.isChartDataAvailable = true;

  //         this.phaseChart = {
  //           labels: this.labels,
  //           datasets: [
  //             {
  //               data: this.data,
  //               backgroundColor: [
  //                // '#2196F3',
  //                 '#c14a09',// '#00FF00',
  //                 '#FFA500',
  //                 '#008000',
  //                 '#0000FF',
  //                 '#3b2820',//'#90EE90',
  //                 '#800080',
  //                 '#2196F3',
  //                 ],
  //             },
  //           ],
  //         };
  //     }
  //   }


  test(val, field, dt: Table) {
    if (field == "productName")
      sessionStorage.productName = val;
    else if (field == "dieCode")
      sessionStorage.dieCode = val;
    else if (field == "mfgPart")
      sessionStorage.mfgPart = val;
    else if (field == "projectDescription")
      sessionStorage.projectDescription = val;
    else if (field == "phase")
      sessionStorage.phase = val;
    else if (field == "phaseStatus")
      sessionStorage.phaseStatus = val;
    else if (field == "productLine")
      sessionStorage.productLine = val;
    else if (field == "foundry")
      sessionStorage.foundry = val;
    else if (field == "subcon")
      sessionStorage.subcon = val;
    else if (field == "productOwner")
      sessionStorage.productOwner = val;
    else if (field == "productStatus")
      sessionStorage.productStatus = val;
    else if (field == "phaseStartDate")
      sessionStorage.phaseStartDate = val;

    if(this.selectedfilter=="startsWith")
      dt.filter(val, field, 'startsWith');
    else
    dt.filter(val, field, 'contains');
  }

  onFilter(event, dt) {
    if (event.filteredValue.length == this.products.length) {
      this.ProductCountFilter = 0;
    }
    else {
      this.ProductCountFilter = event.filteredValue.length;
    }
  }


  // test(val,field,dt)
  // {
  //   if(field == "productName")
  //     sessionStorage.productName = val;
  //   else if(field == "dieCode")
  //     sessionStorage.dieCode = val;
  //   else if(field == "mfgPart")
  //     sessionStorage.mfgPart = val;
  //   else if(field == "projectDescription")
  //     sessionStorage.projectDescription = val;
  //   else if(field == "productLine")
  //     sessionStorage.productLine = val;
  //   else if(field == "foundry")
  //     sessionStorage.foundry = val;
  //   else if(field == "subcon")
  //     sessionStorage.subcon = val;
  //   else if(field == "productOwner")
  //     sessionStorage.productOwner = val;
  //   else if(field == "phase")
  //     sessionStorage.phase = val;
  //   else if(field == "phaseStartDate")
  //     sessionStorage.phaseStartDate = val;

  //   dt = dt.filter(val,field, 'startsWith');
  //   this.count();
  // }

  expandAll() {
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) {
      var p = collection[i];
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") {
        if (val1 == "pi pi-chevron-right") {
          p.click();
        }
      }
      else {
        if (val1 == "pi pi-chevron-down") {
          p.click();
        }
      }
    }


    this.showLoading = false;
  }

  expandAll1() {
    this.savelabel = "Expand All";
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) {
      var p = collection[i];
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") {
        if (val1 == "pi pi-chevron-right") {
          p.click();
        }
      }
      else {
        if (val1 == "pi pi-chevron-down") {
          p.click();
        }
      }
    }


    this.showLoading = false;
  }

  onRowExpand() {
    console.log("row expanded", Object.keys(this.expandedRows).length);
    this.isExpanded = true;
  }

  onRowCollapse() {
    console.log("row collapsed", Object.keys(this.expandedRows).length);
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  Count() {
    if (this.productsD != undefined && this.productsD != null) {
      var temp = this.productsD;
      if (this.productsD.length > 0) {
        if (sessionStorage.productName != "")
          temp = temp.filter(x => x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
        if (sessionStorage.dieCode != "")
          temp = temp.filter(x => x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode.toLowerCase()));
        if (sessionStorage.mfgPart != "")
          temp = temp.filter(x => x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart.toLowerCase()));
        if (sessionStorage.projectDescription != "")
          temp = temp.filter(x => x.projectDescription.toLowerCase().startsWith(sessionStorage.projectDescription.toLowerCase()));
        if (sessionStorage.phase != "")
          temp = temp.filter(x => x.phase.toLowerCase().startsWith(sessionStorage.phase.toLowerCase()));
        if (sessionStorage.productLine != "")
          temp = temp.filter(x => x.productLine.toLowerCase().startsWith(sessionStorage.productLine.toLowerCase()));
        if (sessionStorage.foundry != "")
          temp = temp.filter(x => x.foundry.toLowerCase().startsWith(sessionStorage.foundry.toLowerCase()));
        if (sessionStorage.subcon != "")
          temp = temp.filter(x => x.subcon.toLowerCase().startsWith(sessionStorage.subcon.toLowerCase()));
        if (sessionStorage.productOwner != "")
          temp = temp.filter(x => x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner.toLowerCase()));
        if (sessionStorage.phaseStartDate != "")
          temp = temp.filter(x => x.phaseStartDate.startsWith(sessionStorage.phaseStartDate));
      }
      this.ProductCount = temp.length;
    }
    else
      this.ProductCount = 0;
  }

  getproducts() {
    this.showLoading = true;
    sessionStorage.allproducts = this.AllProductsValue;
    if (this.AllProductsValue) {
      this._facadeService.GetDashboardUserProducts("", "Y").subscribe(
        (data) => {
          this.products = data.returnObject;
          //this.Count();
          //this.showLoading = false;
        },
        (error) => { console.log(error); this.showLoading = false; }
      );

      this._facadeService.GetDashboardUserProductsD("", "H").subscribe(
        (data) => {
          this.productsD = data.returnObject;
          this.Count();
          this.getChartData1();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else {
      this._facadeService.GetDashboardUserProducts(this.userId, "Y").subscribe(
        (data) => {
          this.products = data.returnObject;
          //this.Count();
          //this.showLoading = false;
        },
        (error) => { console.log(error); this.showLoading = false; }
      );

      this._facadeService.GetDashboardUserProductsD(this.userId, "H").subscribe(
        (data) => {
          this.productsD = data.returnObject;
          this.Count();
          this.getChartData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );

    }
  }


  Count1(p, p1) {
    if (this.productsD != undefined && this.productsD != null) {
      var temp = this.productsD;
      if (this.productsD.length > 0) {
        if (p == "productName")
          temp = temp.filter(x => x.productName.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "dieCode")
          temp = temp.filter(x => x.dieCode.toLowerCase().includes(p1.toLowerCase()));
        if (p == "mfgPart")
          temp = temp.filter(x => x.mfgPart.toLowerCase().includes(p1.toLowerCase()));
        if (p == "projectDescription")
          temp = temp.filter(x => x.projectDescription.toLowerCase().includes(p1.toLowerCase()));
        if (p == "phase")
          temp = temp.filter(x => x.phase.toLowerCase().includes(p1.toLowerCase()));
        if (p == "phaseStatus")
          temp = temp.filter(x => x.phaseStatus.toLowerCase().includes(p1.toLowerCase()));
        if (p == "productLine")
          temp = temp.filter(x => x.productLine.toLowerCase().includes(p1.toLowerCase()));
        if (p == "foundry")
          temp = temp.filter(x => x.foundry.toLowerCase().includes(p1.toLowerCase()));
        if (p == "subcon")
          temp = temp.filter(x => x.subcon.toLowerCase().includes(p1.toLowerCase()));
        if (p == "productOwner")
          temp = temp.filter(x => x.productOwner.toLowerCase().includes(p1.toLowerCase()));
        if (p == "productStatus")
          temp = temp.filter(x => x.productStatus.toLowerCase().includes(p1.toLowerCase()));
        if (p == "phaseStartDate")
          temp = temp.filter(x => x.phaseStartDate.toLowerCase().includes(p1.toLowerCase()));
        this.ProductCount = temp.length;
      }
      else
        this.ProductCount = 0;
    }
    else
      this.ProductCount = 0;
  }

  download() {
    //let element = document.getElementById('excel-table'); 
    var dte = [];
    this.productsD.map(d => (
      {
        "Product": d.productName,
        "Die Code": d.dieCode,
        "Mfg Part": d.mfgPart,
        "Project Description": d.projectDescription,
        "Product Line": d.productLine,
        "Foundry": d.foundry,
        "Subcon": d.subcon,
        "Product Engineer": d.productOwner,
        "Product Status": d.productStatus,
        "Phase": d.phase,
        "Phase Status": d.phaseStatus,
        "Phase Start Date": d.phaseStartDate

      }
    )).forEach((d: any) => {
      dte.push(d);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
    //this.delete_row(wb.Sheets.Sheet1, 1);
    / save to file /
    XLSX.writeFile(wb, "Product Dashboard.xlsx");
  }

  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['K1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }


  Details(ProductID, PhaseID, Type) {
    sessionStorage.ProductID = ProductID;
    sessionStorage.PhaseID = PhaseID;
    sessionStorage.DisplayType = Type;
    sessionStorage.FromWhere = "dash";
    this.router.navigate(['/phasedetails']);
  }
  product(val) {
    this.router.navigate(['/myproducts', { index: val }]);
  }
  docs(val) {
    this.router.navigate(['/mydocuments', { index: val }]);
  }
  oproduct(val) {
    this.router.navigate(['/otherproducts', { index: val }]);
  }
  odocs(val) {
    this.router.navigate(['/otherdocuments', { index: val }]);
  }
  app(val) {
    this.router.navigate(['/myapproval', { index: val }]);
  }

  print(MainID, SearchID) {
    var Content = document.getElementById(MainID).innerHTML;
    var Title = "Active Products";
    this.printService.Print(Content, Title, SearchID, true);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'phaseStartDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === "--" ? null : new Date(value1);
        const date2 = value2 === "--" ? null : new Date(value2);

        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });
  }
}
