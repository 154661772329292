<div class="p-grid table-demo">
<div class="p-col-12">
<div class="card" style="min-height:600px;">
      
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Pending ECCN Products - {{Count}}</h5> 
        <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
          <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
          <input pInputText type="text" (input)="CountGlobalFilter($event.target.value,dt)"
          placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('invoice-content','trSearch','Pending ECCN Products')" ></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px; " (click)="download('excel-table','Pending ECCN Products')"></button>
        </span>
    </div>
    <div  class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="products" id="excel-table" [columns]="cols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedProducts" [scrollable]="true" scrollHeight="550px"
        [globalFilterFields]="['eccnRequestedDate','productName','dieCode','mfgPart','fgPart','productLine','productOwner','phase']" dataKey="productID"> 
        <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>              
            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
            >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
            <th style="text-align: center;width: 100px;">Actions</th>
            </tr>
            <tr id="trSearch">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                <input pInputText type="text" (input)="CountFilter($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px;"/>
            </th>
            <th style="text-align: center"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: center;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 1" style="text-align: left;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 2" style="text-align: left;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 3" style="text-align: left;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 4" style="text-align: left;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 5" style="text-align: center;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 6" style="text-align: left;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 7" style="text-align: center;font-weight: bold;" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td>
            </ng-container>
            <td style="text-align: center;width: 100px;">
                <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="Details(rowData.productID)"
                icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
            </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" style="text-align: left;">No records found...</td>
            </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</div>
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
<i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>