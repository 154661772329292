import { Component, Inject, OnDestroy } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router,ActivatedRoute } from '@angular/router';
import { FacadeService } from './facade/facade.service';
import { BASE_API_URL_TOKEN } from './injectors';
import { API_ENDPOINTS } from './app.component';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnDestroy {
  subscription: Subscription;
  firstName = sessionStorage.getItem('firstName');
  profileDisplayExist = sessionStorage.getItem('profileDisplayExist')=="true"?true:false;
  profileDisplayPic = sessionStorage.getItem('profileDisplayPic');
  applications = JSON.parse(sessionStorage.getItem('applications'));
  departments = [];
  userGuid = sessionStorage.getItem('userGuid'); 
  userFeatures = sessionStorage.getItem('userFeatures');
  items: MenuItem[];
  TopMenuDisplay = false;
  userId = parseInt(sessionStorage.getItem('userId'));
  showLoading:boolean;
  TopMenuItemsList:any[]=[];
  HeaderText:boolean=true;
  constructor(
    public breadcrumbService: BreadcrumbService,
    public app: AppMainComponent,
    private route: ActivatedRoute,
    private _facadeService: FacadeService,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    var w = window.innerWidth;
    if(w <= 500)
      this.HeaderText = false;
    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
    });
    var bearer_token=this.route.snapshot.queryParamMap.get('bt');
    if(bearer_token!=null&&bearer_token!=""&&bearer_token!=undefined){
      sessionStorage.bt= bearer_token;
    }
    var guid = this.route.snapshot.queryParamMap.get('userguid');
    var userId = this.route.snapshot.queryParamMap.get('userId');
    if(guid!='' && guid!=undefined){
      this.userGuid=guid;
      this.userId = parseInt(userId);
      this.getUserDetails();
    }
    else{
      if(this.applications!=null&&this.applications.length>0){
       this.bindTopMenuList();
      }
    }
    if(this.userGuid==null &&this.userGuid=="" ||this.userGuid==undefined){
      location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"];
    }
  }

    getUserDetails(){
      this.showLoading = true;
     this._facadeService.GetUserDetails(this.userGuid)
    .subscribe(
       (data) => {
        this.showLoading = false;
      var userDetails = data.returnObject;
      sessionStorage.userId = userDetails.userId;
      sessionStorage.timeZone = userDetails.timeZone;
      sessionStorage.userGuid =  this.userGuid;
      this.applications=userDetails.userApplications;
       sessionStorage.un= userDetails.username;
      sessionStorage.pwd= userDetails.password;
      sessionStorage.defaultIdentifierApp = userDetails.defaultIdentifierApp;
      sessionStorage.applications = JSON.stringify(userDetails.userApplications);
      this.applications.forEach((value, key) => {
        if(value.name == "Product Management")
            sessionStorage.applicationID = value.id;
      });
      // sessionStorage.roles = JSON.stringify(userDetails.userRoles);
      // sessionStorage.userdepts= JSON.stringify(userDetails.userDepartments);
      this.departments = userDetails.userDepartments;
      this.departments= this.departments.slice(0,1);
      this.departments.forEach((value, key) => {
        sessionStorage.userDepartmentID = value.id;
        sessionStorage.userDepartment = value.name;
      });
      sessionStorage.userFeatures="";
      if(userDetails.userFeatures!=null&&userDetails.userFeatures.length>0){
        sessionStorage.userFeatures = JSON.stringify(userDetails.userFeatures);
      }
      sessionStorage.firstName= userDetails.firstName;
      this.firstName= userDetails.firstName;
      sessionStorage.ManagerGuid = userDetails.managerGuid;
      sessionStorage.defaultApplication = userDetails.defaultApplication;
      sessionStorage.defaultIdentifierApp = userDetails.defaultIdentifierApp;
      this.bindTopMenuList();
      if(userDetails.userProfilePic!='' && userDetails.userProfilePic!=undefined){
        this.profileDisplayExist=true;
        if(this.baseUrl["API_ENDPOINTS"]["Storage"]=="azure"){
          this.profileDisplayPic = this.baseUrl["API_ENDPOINTS"]["BlobDownloadURL"]+userDetails.userProfilePic;
        }
        else{
          this.profileDisplayPic =
          API_ENDPOINTS.UMSAPI +
          "user/Download?filename=" +
          userDetails.userProfilePic;
        }
        sessionStorage.profileDisplayPic= this.profileDisplayPic;
        sessionStorage.profileDisplayExist= this.profileDisplayExist;
      }
    },
    (error) => { 
      this.showLoading = false;
      console.log(error);
    }
  );
  }

  ProfileClick(){
    let ShowMenu="N";
    var applicationId="";
    this.applications.forEach((value, key) => {
      if(value.name == "User Management"){
        applicationId=value.applicationId;
      }
      if(value.name == "User Management" && value.active == "Y"){
        ShowMenu="Y";
        
      }
    });
    this.showLoading = true;
          this._facadeService.CreateSecurityToken().subscribe(
            (data) => {
              this.showLoading = false;
              if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                var accessToken = data.returnObject;
                var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
                var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
                window.open(this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'entrypoint?userguid='+this.userGuid+'&userId='+this.userId+
                '&ShowMenu='+ShowMenu+"&access_token="+accessToken+'&IsDCC='+IsDCC
                +'&bt='+sessionStorage.getItem('bt')+"&appliId="+applicationId+"&clientId="+clientId, '_blank');
              }
              else{
                location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            } 
          );
  }
  LogoutClick(){
    var userId = parseInt(sessionStorage.getItem('userId'));
        var appId= parseInt(sessionStorage.getItem('appliId'));
        var modelData ={
          "userId": userId,
          "applicationId": appId,
          "source":"logout"
        };
        this._facadeService.AddUserTracking(modelData).subscribe(
          (data) => {
            sessionStorage.clear();
            location.href =this.baseUrl["API_ENDPOINTS"]["UMSUI"];
          },
          (error) => {
            console.log(error);
          }
        );
   
  } 
  help(){
    this.router.navigate(["/helpguide"]);
}

 
  TopMenuClicks(menuItemclick,id){ 
    this.showLoading = true;
          this._facadeService.CreateSecurityToken().subscribe(
            (data) => {
              this.showLoading = false;
              if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                var access_token = data.returnObject;
                var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
                var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
                window.open(this.baseUrl["API_ENDPOINTS"][menuItemclick]+'entrypoint?userguid='+this.userGuid
                +'&userId='+this.userId+'&IsDCC='+IsDCC+'&access_token='+access_token+
                '&source=dashboard'
                +'&bt='+sessionStorage.getItem('bt')+"&appliId="+id+"&clientId="+clientId, '_self');
              }
              else{
                location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            } 
          );
  }

bindTopMenuList(){
  this.TopMenuItemsList=[];
  this.applications.forEach((value, key) => {
    if(value.projectIdentifier!="ProductUI"){
      this.TopMenuItemsList.push({ id:value.applicationId, identifier: value.projectIdentifier, iconPath: value.iconPath, displayText: value.displayText});
    }
  });
}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
