import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { DashboardService } from "../services/dashboard.service";
import { PhaseDetailsService } from "../services/phasedetails.service";
import { ApprovalService } from "../services/approval.service";
import { TeamService } from "../services/team.service";

@Injectable({
  providedIn: "root",
})
export class FacadeService {
  UpdateOwner(ProductID, Owner) {
    return this._phasedetailsService.UpdateOwner(ProductID,Owner);
  }
  constructor(
    private _loginService: LoginService,
    private _dashboardService: DashboardService,
    private _phasedetailsService: PhaseDetailsService,
    private _approvalService: ApprovalService,
    private _teamService: TeamService
  ) {}
  GetIssues(ProductID,PhaseID){
    return this._phasedetailsService.GetIssues(ProductID,PhaseID);
  }

  GetTasks(ProductID,PhaseID){
    return this._phasedetailsService.GetTasks(ProductID,PhaseID);
  }
  GetAllProducts()
  {
    return this._phasedetailsService.GetAllProducts();
  }
  //Dashboard Start
  GetUserDetails(userGuid){
    return this._dashboardService.GetUserDetails(userGuid);
  }
  login(model) {
    return this._loginService.login(model);
  }
  DashboardCounts(UserID) {
    return this._dashboardService.DashboardCounts(UserID);
  }
  DashboardProducts() {
    return this._dashboardService.DashboardProductList();
  }

  // GetDashboardUserProducts(UserID){
  //   return this._dashboardService.GetDashboardUserProducts(UserID);
  // }

  GetDashboardUserProducts(UserID,Download){
    return this._dashboardService.GetDashboardUserProducts(UserID,Download);
  }

  GetDashboardUserProductsD(UserID,Download){
    return this._dashboardService.GetDashboardUserProducts(UserID,Download);
  }
  
  Chart(UserID) {
    return this._dashboardService.GetChart(UserID);
  }
  Timeline(Days, UserID) {
    return this._dashboardService.Timeline(Days, UserID);
  }
  //Dashboard End
  //Product Pages Start
  ECCNProducts(){
    return this._dashboardService.ECCNProducts();
  }
  // UserProducts(UserID,Type,PE){
  //   return this._dashboardService.ProductList(UserID,Type,PE);
  // }

  UserProducts(UserID,Type,PE,Download){
    return this._dashboardService.ProductList(UserID,Type,PE,Download);
  }

  Documents(UserID,Type,PE,MyRole) {
    return this._dashboardService.DocsList(UserID,Type,PE,MyRole);
  }
  MyApproval(UserID) {
    return this._approvalService.ApprovalList(UserID);
  }
  MyCancelApproval(UserID) {
    return this._approvalService.ApprovalCancelList(UserID);
  }
  ClarificationProducts(UserID,PE) {
    return this._approvalService.ClarificationProducts(UserID,PE);
  }
  //Product Pages End
  //Phase Details begin
  PhaseBar(ProductID) {
    return this._phasedetailsService.PhaseBar(ProductID);
  }
  ProductDetails(ProductID,UserID,isPLMAdmin) {
    return this._phasedetailsService.ProductDetails(ProductID,UserID,isPLMAdmin);
  }
  getPhaseByID(PhaseID,UserID) {
    return this._phasedetailsService.PhaseDetails(PhaseID,UserID);
  }
  getDocuments(Type,TransactionID,UserID) {
    return this._phasedetailsService.getDocuments(Type,TransactionID,UserID);
  }
  getNotes(Type,TransactionID) {
    return this._phasedetailsService.getNotes(Type,TransactionID);
  }
  addDocuments(model) {
    return this._phasedetailsService.addDocuments(model);
  }
  UpdateECCN(model) {
    return this._phasedetailsService.UpdateECCN(model);
  }
  addNotes(model) {
    return this._phasedetailsService.addNotes(model);
  }
  deleteDocument(model) {
    return this._phasedetailsService.deleteDocument(model);
  }
  deleteNote(model) {
    return this._phasedetailsService.deleteNote(model);
  }
  Hold(model) {
    return this._phasedetailsService.Hold(model);
  }
  Release(model) {
    return this._phasedetailsService.Release(model);
  }
  PhaseTask(model,Checked) {
    return this._phasedetailsService.PhaseTask(model,Checked);
  }
  ListTask(model,Checked) {
    return this._phasedetailsService.ListTask(model,Checked);
  }
  getDOCDocuments(DeptID) {
    return this._phasedetailsService.getDOCDocuments(DeptID);
  }
  UpdateEOL(EOL,ProductID,PhaseID)
  {
    return this._phasedetailsService.UpdateEOL(EOL,ProductID,PhaseID);
  }
  //Phase Details End

  //Approval Begin
  GetUserApproved(model)
  {
    return this._approvalService.GetUserApproved(model);
  }
  SendApproval(model) {
    return this._approvalService.SendApproval(model);
  }
  Reminder(model) {
    return this._approvalService.Reminder(model);
  }
  ECCNReminder(ProductID) {
    return this._approvalService.ECCNReminder(ProductID);
  }
  SendCancelApproval(model) {
    return this._approvalService.SendCancelApproval(model);
  }
  CancelWithdraw(model) {
    return this._approvalService.CancelWithdraw(model);
  }
  CancelReminder(model) {
    return this._approvalService.CancelReminder(model);
  }
  ClarificationDetails(PhaseID) {
    return this._approvalService.ClarificationDetails(PhaseID);
  }
  ClarificationDetailsbyID(ID,Type) {
    return this._approvalService.ClarificationDetailsbyID(ID,Type);
  }
  ClarificationDetailsbyParentID(ID,Type) {
    return this._approvalService.ClarificationDetailsbyParentID(ID,Type);
  }
  TeamAction(model) {
    return this._approvalService.TeamAction(model);
  }
  Action(model) {
    return this._approvalService.Action(model);
  }

  CancelApprovalDetails(ProductID) {
    return this._approvalService.CancelApprovalDetails(ProductID);
  }
  GetCancelClarificationDetails(ProductID) {
    return this._approvalService.GetCancelClarificationDetails(ProductID);
  }

  GetAllCancelApprovalDetails(ProductID)
  {
    return this._approvalService.GetAllCancelApprovalDetails(ProductID); 
  }


  ApprovalDetails(PhaseID) {
    return this._approvalService.ApprovalDetails(PhaseID);
  }
  GetApprovalDetailsByParentID(ID,Type) {
    return this._approvalService.GetApprovalDetailsByParentID(ID,Type);
  }
  //Approval end

  //Team Start

  PhaseDocuments(PhaseID,UserID)
  {
    return this._phasedetailsService.PhaseDocuments(PhaseID,UserID);
  }
  UpdatePhaseDocuments(model,PhaseID)
  {
    return this._phasedetailsService.UpdatePhaseDocuments(model,PhaseID);
  }
  IsOwner(Type,ID,UserID) {
    return this._teamService.IsOwner(Type,ID,UserID);
  }

  GetPhasesbyID(ProductID) {
    return this._teamService.GetPhasesbyID(ProductID);
  }
  GetStagesbyID(PhaseID) {
    return this._teamService.GetStagesbyID(PhaseID);
  }
  GetTeam(Type,TeamType,ID) {
    return this._teamService.GetTeam(Type,TeamType,ID);
  }
  getCancelTeamData(ProductLineID) {
    return this._teamService.getCancelTeamData(ProductLineID);
  }
  UpdateCancelTeam(model,ProductLineID) {
    return this._teamService.UpdateCancelTeam(model,ProductLineID);
  }

  getCRBData(ProductLineID,Type) {
    return this._teamService.getCRBData(ProductLineID,Type);
  }
  UpdateCRB(model,ProductLineID,Type) {
    return this._teamService.UpdateCRB(model,ProductLineID,Type);
  }

  UpdateTeam(model) {
    return this._teamService.UpdateTeam(model);
  }
  getDepartments() {
    return this._teamService.getDepartments();
  }
  getRolesByDepartmentGuid(departMentGuid) {
    return this._teamService.getRolesByDepartmentGuid(departMentGuid);
  }
  GetUsersByRoleGuid(RoleGUID) {
    return this._teamService.GetUsersByRoleGuid(RoleGUID);
  }
  getRolesData() {
    return this._teamService.getRoles();
  }
  ReportProducts(model) {
    return this._teamService.ReportProducts(model);
  }

  ReportProductsD(model) {
    return this._teamService.ReportProducts(model);
  }

  GetProductsAttributes(model) {
    return this._teamService.GetProductsAttributes(model);
  }
  GetAllProductsAttributes(model) {
    return this._teamService.GetAllProductsAttributes(model);
  }
  GetReportTeam(ProductID)
  {
    return this._teamService.GetReportTeam(ProductID);
  }
  GetReportDocuments(ProductID,UserID)
  {
    return this._teamService.GetReportDocuments(ProductID,UserID);
  }

  CreateConditionalRelease(data)
  {
    return this._phasedetailsService.CreateConditionalRelease(data);
  }
  UpdateEvalutionConditionalRelease(data)
  {
    return this._phasedetailsService.UpdateEvalutionConditionalRelease(data);
  }
  GetConditionalReleaseDetails(ProductID,type,UserID)
  {
    return this._phasedetailsService.GetConditionalReleaseDetails(ProductID,type,UserID);
  }
  GetPreviousCR_Eval(ProductID,type,UserID)
  {
    return this._phasedetailsService.GetPreviousCR_Eval(ProductID,type,UserID);
  }

  getRolesByDepartmentGuids(departMentGuids) {
    return this._phasedetailsService.getRolesByDepartmentGuids(departMentGuids);
  }
  GetProductLines(){
    return this._phasedetailsService.GetProductLines();
  }
  getConditionalproducts(userId){
    return this._phasedetailsService.GetConditionalProducts(userId);
  }
  getEvaluationproducts(userId){
    return this._phasedetailsService.GetEvaluationProducts(userId);
  }
  // ApproveRejectConditionalRelease(model){
  //   return this._phasedetailsService.ApproveRejectConditionalRelease(model);
  // }
  GetConditionalApprovalsHistory(conditionalId){
    return this._phasedetailsService.GetConditionalApprovalsHistory(conditionalId);
  }
  GetConditionalClarifications(conditionalId){
    return this._phasedetailsService.GetConditionalClarifications(conditionalId);
  }
  GetPhaseConditionalDetails(productId){
    return this._phasedetailsService.GetPhaseConditionalDetails(productId);
  }
  DeleteConditionalRelease(id){
    return this._phasedetailsService.DeleteConditionalRelease(id);
  }
  //Team End
  CreateSecurityToken(){
    return this._phasedetailsService.CreateSecurityToken();
  }
  UpdateSecurityToken(token){
    return this._phasedetailsService.UpdateSecurityToken(token);
  }
  GetMenusByUser(userId,applicationid)
  {
    return this._phasedetailsService.GetMenusByUser(userId,applicationid);
  }

  downloadDMSFile(filename,newname){
    return this._phasedetailsService.downloadDMSFile(filename,newname);
  }
  AddUserTracking(model){
    return this._phasedetailsService.AddUserTracking(model);
  }
}
