<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <p-tabView [activeIndex]="index">
        <p-tabPanel leftIcon="pi pi-check-circle">
          <ng-template pTemplate="header">
            Stage Gate Approval ({{ Count }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approval - Stage Gate Approval</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="CountGlobalFilter($event.target.value,dtactive)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content','trSearch')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table')"></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content">
            <p-table #dtactive [value]="activeproducts" id="excel-table" [columns]="cols" sortMode="multiple"
              [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" class="table-align"
              [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedactiveProducts"
              [globalFilterFields]="['productName','dieCode','mfgPart','productdescription','productLine','productOwner','phase']"
              dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="CountFilter($event.target.value,col.field,dtactive)"
                      placeholder="Search" style="margin: -10px 0 -10px 0;width:95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="text-align: center;font-weight: bold;"
                      [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
                      {{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('Approve',Data.productID, Data.phaseID,Data.type,Data.transactionID)"
                      icon="pi pi-eye" class="p-button-raised p-button-primary"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-minus">
          <ng-template pTemplate="header">
            To Discontinue ({{ Count1 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approval - Products for Discontinuation</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count1GlobalFilter($event.target.value,dtcancelled)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content1','trSearch1')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table1')"></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content1">
            <p-table #dtcancelled [value]="cancelledproducts" id="excel-table1" [columns]="cols" sortMode="multiple"
              [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0"
              selectionMode="multiple" [(selection)]="selectedcancelledProducts"
              [globalFilterFields]="['productName','dieCode','mfgPart','fgPart','productLine','productOwner','phase']"
              dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch1">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count1Filter($event.target.value,col.field,dtcancelled)"
                      placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="text-align: center;font-weight: bold;"
                      [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
                      {{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('Cancel',Data.productID, Data.phaseID,Data.type,Data.transactionID)"
                      icon="pi pi-eye" class="p-button-raised p-button-primary"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-book">
          <ng-template pTemplate="header">
            Eval Sample Release ({{ Count3 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approval - Eval Sample Release</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count3GlobalFilter($event.target.value,dtevaluation)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content3','trSearch3')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table3')"></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content3">
            <p-table #dtevaluation [value]="evaluationproducts" id="excel-table3" [columns]="cols" sortMode="multiple"
              [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0"
              selectionMode="multiple" [(selection)]="selectedevaluationProducts"
              [globalFilterFields]="['productName','dieCode','mfgPart','fgPart','productLine','productOwner','phase']"
              dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch3">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count1Filter($event.target.value,col.field,dtevaluation)"
                      placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="text-align: center;font-weight: bold;"
                      [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
                      {{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('CRE',Data.productID, Data.phaseID,Data.type,Data.transactionID)"
                      icon="pi pi-eye" class="p-button-raised p-button-primary"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-credit-card">
          <ng-template pTemplate="header">
            Conditional Release ({{ Count2 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approval - Products for Conditional Release</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count2GlobalFilter($event.target.value,dtconditional)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content2','trSearch2')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table2')"></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content2">
            <p-table #dtconditional [value]="conditionalproducts" id="excel-table2" [columns]="cols" sortMode="multiple"
              [loading]="loading" [autoLayout]="true" styleClass="p-datatable-striped" [filterDelay]="0"
              selectionMode="multiple" [(selection)]="selectedconditionalProducts"
              [globalFilterFields]="['productName','dieCode','mfgPart','fgPart','productLine','productOwner','phase']"
              dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch2">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count1Filter($event.target.value,col.field,dtconditional)"
                      placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="text-align: center;font-weight: bold;"
                      [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
                      {{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('CRC',Data.productID, Data.phaseID,Data.type,Data.transactionID)"
                      icon="pi pi-eye" class="p-button-raised p-button-primary"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-check-circle">
          <ng-template pTemplate="header">
            Approved Stage Gates ({{ Count4 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approved - Stage Gates</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count4GlobalFilter($event.target.value,dtphase)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content4','trSearch4')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table4')"></button>
            </span>
          </div>
          <div class="p-fluid p-formgrid p-grid" style="padding-top:10px;">
            <div class="p-field p-col-12 p-xl-2">
              <label style="padding-right:10px; margin-left:10px;">Period:</label>
              <p-dropdown [options]="phaseperiod" [(ngModel)]="selectedphasePeriod" placeholder="Select Period"
                optionLabel="name" (onChange)="phaseDates()"></p-dropdown>
            </div>
            <div *ngIf="phasedatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>Start Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editphaseStartDate"></p-calendar>
            </div>
            <div *ngIf="phasedatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>End Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editphaseEndDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-xl-2">
              <a (click)="getphases()">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                  class="p-button-raised p-button-primary" style="width:100%;float:left;margin-top: 25px;"></button>
              </a>
            </div>
            <div class="p-field p-col-4">
              <label style="color: red;width:auto;padding-top:30px;">{{phaseerror}}</label>
            </div>
          </div>
          <div class="invoice invoice-header" id="invoice-content4">
            <p-table (sortFunction)="customSort($event)" [customSort]="true" #dtphase [value]="phase" id="excel-table4"
              [columns]="cols1" [loading]="loading" [autoLayout]="true"
              [globalFilterFields]="['productName','dieCode','mfgPart','productLine','productOwner','phase','approvedDate','comments']"
              styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"
              [(selection)]="selectedphase" dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch4">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count4Filter($event.target.value,col.field,dtphase)"
                      placeholder="Search" style="margin: -10px 0 -10px 0;width:95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="text-align: center;font-weight: bold;"
                      [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
                      {{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="text-align: center">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 7" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('Phase',Data.productID, Data.phaseID,'Phase',Data.phaseID)" icon="pi pi-eye"
                      class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="9" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-minus">
          <ng-template pTemplate="header">
            Approved Discontinuation ({{ Count5 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approved - Product Discontinuations</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count5GlobalFilter($event.target.value,dtdiscontinue)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content5','trSearch5')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table5')"></button>
            </span>
          </div>
          <div class="p-fluid p-formgrid p-grid" style="padding-top: 10px;">
            <div class="p-field p-col-12 p-xl-2">
              <label style="padding-right:10px; margin-left:10px;">Period:</label>
              <p-dropdown [options]="discontinueperiod" [(ngModel)]="selecteddiscontinuePeriod"
                placeholder="Select Period" optionLabel="name" (onChange)="discontinueDates()"></p-dropdown>
            </div>
            <div *ngIf="discontinuedatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>Start Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editdiscontinueStartDate"></p-calendar>
            </div>
            <div *ngIf="discontinuedatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>End Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editdiscontinueEndDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-xl-2">
              <a (click)="getdiscontinue()">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                  class="p-button-raised p-button-primary" style="width:100%;float:left;margin-top: 25px;"></button>
              </a>
            </div>
            <div class="p-field p-col-4">
              <label style="color: red;width:auto;padding-top:30px;">{{discontinueerror}}</label>
            </div>
          </div>
          <div class="invoice invoice-header" id="invoice-content5">
            <p-table (sortFunction)="customSort($event)" [customSort]="true" #dtdiscontinue [value]="discontinue"
              id="excel-table5" [columns]="cols2" [loading]="loading" [autoLayout]="true"
              [globalFilterFields]="['productName','dieCode','mfgPart','productLine','productOwner','approvedDate','comments']"
              styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"
              [(selection)]="selecteddiscontinue" dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch5">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count5Filter($event.target.value,col.field,dtdiscontinue)"
                      placeholder="Search" style="margin: -10px 0 -10px 0;width:95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('Phase',Data.productID, Data.phaseID,'Phase',Data.phaseID)" icon="pi pi-eye"
                      class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-book">
          <ng-template pTemplate="header">
            Approved Eval Sample Release ({{ Count7 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approved - Eval Sample Release</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count7GlobalFilter($event.target.value,dtcre)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content7','trSearch7')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table7')"></button>
            </span>
          </div>
          <div class="p-fluid p-formgrid p-grid" style="padding-top: 10px;">
            <div class="p-field p-col-12 p-xl-2">
              <label style="padding-right:10px; margin-left:10px;">Period:</label>
              <p-dropdown [options]="creperiod" [(ngModel)]="selectedcrePeriod" placeholder="Select Period"
                optionLabel="name" (onChange)="creDates()"></p-dropdown>
            </div>
            <div *ngIf="credatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>Start Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editcreStartDate"></p-calendar>
            </div>
            <div *ngIf="credatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>End Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editcreEndDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-xl-2">
              <a (click)="getcre()">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                  class="p-button-raised p-button-primary" style="width:100%;float:left;margin-top: 25px;"></button>
              </a>
            </div>
            <div class="p-field p-col-4">
              <label style="color: red;width:auto;padding-top:30px;">{{creerror}}</label>
            </div>
          </div>
          <div class="invoice invoice-header" id="invoice-content7">
            <p-table (sortFunction)="customSort($event)" [customSort]="true" #dtcre [value]="cre" id="excel-table7"
              [columns]="cols2" [loading]="loading" [autoLayout]="true"
              [globalFilterFields]="['productName','dieCode','mfgPart','productLine','productOwner','approvedDate','comments']"
              styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"
              [(selection)]="selectedcre" dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch7">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count7Filter($event.target.value,col.field,dtcre)"
                      placeholder="Search" style="margin: -10px 0 -10px 0;width:95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('Phase',Data.productID, Data.phaseID,'Phase',Data.phaseID)" icon="pi pi-eye"
                      class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel leftIcon="pi pi-credit-card">
          <ng-template pTemplate="header">
            Approved Conditional Release ({{ Count6 }})
          </ng-template>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">My Approved - Conditional Release</h5>
            <span class="p-input-icon-left" style="float:right;top:4px;">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="Count6GlobalFilter($event.target.value,dtcrc)"
                placeholder="Search..." style="margin-top: 6px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;margin-top: 10px;"
                (click)="print('invoice-content6','trSearch6')"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 10px;"
                (click)="download('excel-table6')"></button>
            </span>
          </div>
          <div class="p-fluid p-formgrid p-grid" style="padding-top: 10px;">
            <div class="p-field p-col-12 p-xl-2">
              <label style="padding-right:10px; margin-left:10px;">Period:</label>
              <p-dropdown [options]="crcperiod" [(ngModel)]="selectedcrcPeriod" placeholder="Select Period"
                optionLabel="name" (onChange)="crcDates()"></p-dropdown>
            </div>
            <div *ngIf="crcdatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>Start Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editcrcStartDate"></p-calendar>
            </div>
            <div *ngIf="crcdatesdisplay" class="p-field p-col-12 p-xl-2">
              <label>End Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="editcrcEndDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-xl-2">
              <a (click)="getcrc()">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                  class="p-button-raised p-button-primary" style="width:100%;float:left;margin-top: 25px;"></button>
              </a>
            </div>
            <div class="p-field p-col-4">
              <label style="color: red;width:auto;padding-top:30px;">{{crcerror}}</label>
            </div>
          </div>
          <div class="invoice invoice-header" id="invoice-content6">
            <p-table (sortFunction)="customSort($event)" [customSort]="true" #dtcrc [value]="crc" id="excel-table6"
              [columns]="cols2" [loading]="loading" [autoLayout]="true"
              [globalFilterFields]="['productName','dieCode','mfgPart','productLine','productOwner','approvedDate','comments']"
              styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"
              [(selection)]="selectedcrc" dataKey="productID">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon
                      [field]="col.field"></p-sortIcon></th>
                  <th style="text-align: center">Actions</th>
                </tr>
                <tr id="trSearch6">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" (input)="Count6Filter($event.target.value,col.field,dtcrc)"
                      placeholder="Search" style="margin: -10px 0 -10px 0;width:95%;height:25px;" />
                  </th>
                  <th style="text-align: center"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 1" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 4" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 6" style="padding-left:15px;">{{ rowData[col.field] }}</td>
                  </ng-container>
                  <td style="text-align: center">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right"
                      (click)="Details('Phase',Data.productID, Data.phaseID,'Phase',Data.phaseID)" icon="pi pi-eye"
                      class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>