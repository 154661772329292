 <div class="layout-sidebar" (click)="onMenuClick($event)">
    <a class="logo">
        <img (click)="LogoClick()" id="app-logo" class="logo-image" src="assets/gallery/pficon.png" alt="FactoryPRO-Logo"style="width:60px; height:60px;margin-left:-1rem;">
        
    </a>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>
</div> 
<!-- 
<div class="layout-wrapper" [ngClass]="{
    'layout-overlay': menuMode === 'overlay',
    'layout-static': menuMode === 'static',
    'layout-slim': menuMode === 'slim',
    'layout-sidebar-dim': colorScheme === 'dim',
    'layout-sidebar-dark': colorScheme === 'dark',
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
    'p-input-filled': inputStyle === 'filled', 'p-ripple-disabled': !ripple}" [class]="colorScheme === 'light' ? menuTheme : ''"
     [attr.data-theme]="colorScheme" (click)="onLayoutClick()">

    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>

    <app-config></app-config>

    <app-menu></app-menu>

    <app-search></app-search>

    <app-rightmenu></app-rightmenu>

    <div class="layout-mask modal-in"></div>
</div> -->
<p-dialog header="Access" [(visible)]="InfoDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}" >
    <hr>
    <div class="p-grid">
   
    <div class="p-col-12" style="text-align: center;">
     <span style="font-size:18px;">User doesn't have permission to perform the operation</span>
    </div>
    </div>
    </p-dialog>
    <div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>