import { Component, OnInit} from '@angular/core';
import { PrintcommonService } from '../../services/printcommon.service';
import { Router} from '@angular/router';
import {DashboardProducts} from "../../model/DashboardData";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { ProductService } from 'src/app/services/product.service';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-piechartreport',
  templateUrl: './piechartreport.component.html',
  styleUrls: ['./piechartreport.component.scss']
})
export class PiechartreportComponent implements OnInit {
  selectedProducts: DashboardProducts[];
  error="";
  prodOwnerData=[];
  selectedprodOwnerData=[];
  loading:any;
  cols: any[];
  ProjectCount:number = 0;
  date1: string;
  project:any[];
  status:any[];
  line:any;
  isChartDataAvailable:any;
  projects:any[];
  showLoading:boolean;
  data: any;

  options: any;
  selectedfromYear: number;
  yearOptions: any[];
  selectedtoYear: number;
  phasesData=[];
  selectedPhases=[];
  constructor(
    public printService: PrintcommonService,
    private router: Router,
    private _productService:ProductService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { const currentYear = new Date().getFullYear();
    this.yearOptions = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      this.yearOptions.push({ label: i.toString(), value: i });
    }
  }

  ngOnInit(): void {
    this.selectedfromYear =this.yearOptions[4].value;
    this.selectedtoYear=this.yearOptions[0].value;
    let today = new Date();
    this.date1 = today.getMonth() +1 + '/' + today.getDate() + '/' + today.getFullYear();
    sessionStorage.year="";
    this.phasesData=[
      {id:1,name:"P0"},
      {id:2,name:"Review"},
      {id:3,name:"P3 RTS"},
      {id:4,name:"P3 Production"},
      {id:5,name:"EOL/OB"},
      {id:6,name:"Discontinued"},
    ];
    this.selectedPhases= this.phasesData;
    this.cols = [
      { field: 'year', header: 'Year' },
      { field: 'productName', header: 'Product' },
      { field: 'dieCode', header: 'Die Code' },
      { field: 'mfgPart', header: 'Mfg Part' },
      { field: 'productOwner', header: 'Product Engineer' },
      { field: 'phase', header: 'Phase' },
      { field: 'phaseStartDate', header: 'Phase Start Date' },
      { field: 'phaseEndDate', header: 'Phase End Date' },
      { field: 'productStatus', header: 'Product Status' },
    ];
    sessionStorage.productName = "";
    sessionStorage.dieCode = "";
    sessionStorage.mfgPart = "";
    sessionStorage.phaseStartDate = "";
    sessionStorage.phaseEndDate = "";
    sessionStorage.productStatus = "";
    sessionStorage.subcon = "";
    sessionStorage.productOwner = "";
    sessionStorage.phase = "";
    sessionStorage.year = "";
    this.GetProductOwners();
  }
  GetProductOwners(){
    this.showLoading = true;
    this._productService.GetProductOwners().subscribe(
      (data) => {
        if(data.returnObject!=null&&data.returnObject.length>0){
          data.returnObject.forEach(element => {
            element.fullName=element.firstName+" "+element.lastName;
          });
          this.prodOwnerData=data.returnObject;
        }
        this.showLoading = false;
        this.selectedprodOwnerData=this.prodOwnerData;
        this.GetPieChartProjectsData();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  GetPieChartProjectsData(){
    this.error="";
    if(this.selectedfromYear>this.selectedtoYear){
      this.error="Selected From Year should not be greater than To Year.";
    }
    else if(this.selectedPhases==null||this.selectedPhases.length==0){
      this.error="Please select at least one phase.";
    }
    else{
      this.showLoading = true;
      var ProductOwnerIds=[];
      var phases=[];
      if(this.selectedprodOwnerData!=null&&this.selectedprodOwnerData!=undefined&&this.selectedprodOwnerData.length>0){
        this.selectedprodOwnerData.forEach(element => {
          ProductOwnerIds.push(element.userGuid)
        });
      }
      if(this.selectedPhases!=null&&this.selectedPhases!=undefined&&this.selectedPhases.length>0){
        this.selectedPhases.forEach(element => {
          phases.push(element.name);
        });
      }
      var model={
        SelectedFromYear:this.selectedfromYear,
        SelectedToYear:this.selectedtoYear,
        ProductOwnerIds : ProductOwnerIds,
        Phases:phases
      }
      this._productService.GetPieChartProjectsData(model).subscribe(
        (data) => {
          this.showLoading = false;
          this.data=data.returnObject.pieChartData;
          this.projects=data.returnObject.projects;
          this.ProjectCount=this.projects.length;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        } 
      );
    }
  }
  test(val,field,dt)
  {
    if(field == "year")
      sessionStorage.year = val;
      else if(field == "productName")
      sessionStorage.productName = val;
    else if(field == "dieCode")
      sessionStorage.dieCode = val;
    else if(field == "mfgPart")
      sessionStorage.mfgPart = val;
    else if(field == "phaseStartDate")
      sessionStorage.phaseStartDate = val;
    else if(field == "phaseEndDate")
      sessionStorage.phaseEndDate = val;
    else if(field == "productStatus")
      sessionStorage.productStatus = val;
    else if(field == "subcon")
      sessionStorage.subcon = val;
    else if(field == "productOwner")
      sessionStorage.productOwner = val;
    else if(field == "phase")
      sessionStorage.phase = val;
    else if(field == "phaseStartDate")
      sessionStorage.phaseStartDate = val;

    dt = dt.filter(val,field, 'startsWith');
    this.count();
  }
  count()
  {
    if(this.projects != undefined && this.projects != null&&this.projects.length>0)
    {
      var temp = this.projects;
      if(sessionStorage.year != "")
      temp = temp.filter(x => x.year.toString().startsWith(sessionStorage.year));
        if(sessionStorage.productName != "")
        temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
      if(sessionStorage.dieCode != ""&&sessionStorage.dieCode !=undefined&&sessionStorage.dieCode !=null)
        temp = temp.filter(x=> x.dieCode!=null&&x.dieCode!=""&&x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode.toLowerCase()));
      if(sessionStorage.mfgPart != ""&&sessionStorage.mfgPart !=undefined&&sessionStorage.mfgPart != null)
        temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart.toLowerCase()));
      if(sessionStorage.phaseStartDate != "")
        temp = temp.filter(x=> x.phaseStartDate.toLowerCase().startsWith(sessionStorage.phaseStartDate.toLowerCase()));
      if(sessionStorage.phaseEndDate != "")
        temp = temp.filter(x=> x.phaseEndDate.toLowerCase().startsWith(sessionStorage.phaseEndDate.toLowerCase()));
      if(sessionStorage.productStatus != ""&&sessionStorage.productStatus != undefined)
        temp = temp.filter(x=> x.productStatus!=null&&x.productStatus!=""&&x.productStatus.toLowerCase().startsWith(sessionStorage.productStatus.toLowerCase()));
      if(sessionStorage.subcon != ""&&sessionStorage.subcon !=undefined)
        temp = temp.filter(x=> x.subcon!=null&&x.subcon!=""&&x.subcon.toLowerCase().startsWith(sessionStorage.subcon.toLowerCase()));
      if(sessionStorage.productOwner != ""&&sessionStorage.productOwner != undefined)
        temp = temp.filter(x=> x.productOwner!=null&&x.productOwner!=""&&x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner.toLowerCase()));
      if(sessionStorage.phase != "")
        temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase.toLowerCase()));
      this.ProjectCount = temp.length; 
      sessionStorage.dahsboardDT = JSON.stringify(temp);
    }
    else
      this.ProjectCount = 0;
  }
  download() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [{ wpx : 70 },{ wpx : 180 },{ wpx : 70 },{ wpx : 150 },{ wpx : 150 },{ wpx : 70 },{ wpx : 150 },{ wpx : 150 },{ wpx : 120 },{ wpx : 80 }];
    this.delete_row(wb.Sheets.Sheet1, 1);
    /* save to file */
    XLSX.writeFile(wb, "Projects.xlsx");
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['J1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  print(MainID,SearchID) {
    var Content = document.getElementById(MainID).innerHTML;
    var Title = "Projects";
    this.printService.Print(Content,Title,SearchID,true);
  }
  Details(ProductID, PhaseID,Type) {
    sessionStorage.ProductID = ProductID;
    sessionStorage.PhaseID = PhaseID;
    sessionStorage.DisplayType = Type;
    sessionStorage.FromWhere = "piechart";
    this.router.navigate(['/phasedetails']);
  }
  resetCtrls(){
    this.selectedfromYear =this.yearOptions[4].value;
    this.selectedtoYear=this.yearOptions[0].value;
    let today = new Date();
    this.date1 = today.getMonth() +1 + '/' + today.getDate() + '/' + today.getFullYear();
    sessionStorage.year="";
    this.selectedPhases= this.phasesData;
    sessionStorage.productName = "";
    sessionStorage.dieCode = "";
    sessionStorage.mfgPart = "";
    sessionStorage.phaseStartDate = "";
    sessionStorage.phaseEndDate = "";
    sessionStorage.productStatus = "";
    sessionStorage.subcon = "";
    sessionStorage.productOwner = "";
    sessionStorage.phase = "";
    sessionStorage.year = "";
    this.GetProductOwners();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'phaseStartDate'||event.field === 'phaseEndDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === "--" ? null : new Date(value1);
        const date2 = value2 === "--" ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  }
}
