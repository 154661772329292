import { Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { Table } from "primeng/table";
import { CancelTeam } from '../../../model/PhaseDetails';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from "../../../services/printcommon.service";
import { DatePipe } from '@angular/common';@Component({
  selector: 'app-conditionalreleaseboard',
  templateUrl: './conditionalreleaseboard.component.html',
  styleUrls: ['./conditionalreleaseboard.component.scss']
})
export class ConditionalreleaseboardComponent implements OnInit {
  showLoading:boolean;
  userId = sessionStorage.getItem("userGuid");
  ProducLine: any[];
  selectedProductLine: any;
  ErrorDisplay: boolean = false;
  Error: string = "";
  productLineError:string = "";
  SelProductLine: string = "";

  Type: any[];
  selectedType: any;
  typeError:string = "";
  SelType: string = "";

  docTypes: CancelTeam[];
  clicked: boolean;
  ViewOnly:boolean = true;
  ok: boolean;
  clonedTeams: { [s: string]: iCancelTeam } = {};
  departmentsData: any;
  selectedDept: any;
  RolesData: any;
  selectedRole: any;
  UsersData: any;
  fromRoleData: any;
  departmentNameError: any;
  roleNameError: any;
  userNameError: any;
  cancelteamModel: any;
  selectedUser: any;
  cols: any[];
  dynamicplaceholder: string;
  apiResponse:any;
  @ViewChild("dt") table: Table;
  TeamCount:number=0;
  userFeature:boolean;
  userFeature1:boolean;
  constructor(private _facadeService: FacadeService,private pipe: DatePipe,
    private printService: PrintcommonService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
  }
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PDM_SRABS");
        if(featureExist!=null){
          this.userFeature = true;
        }

        var featureExist = feature.find(m=>m.code=="PDM_UCBD");
        if(featureExist!=null){
          this.userFeature1 = true;
        }
      }
    }
      this.showLoading = true;
      this.Type = [
        { name: 'Evaluation Sample Release', id: 2 },
        { name: 'Conditional Release', id: 1 },
      ];

      this.GetProductLne();
      this.cols = [
        { field: 'departmentName', header: 'Department', dynamicplaceholder: "Search by Department" },
        { field: 'roleName', header: 'Role', dynamicplaceholder: "Search by Role" },
        { field: 'userName', header: 'Name', dynamicplaceholder: "Search by Name" },
      ];
      sessionStorage.departmentName = "";
      sessionStorage.roleName = "";
      sessionStorage.userName = "";
  }

    GetProductLne() {
      this._facadeService.GetProductLines()
      .subscribe((data) => {
        this.ProducLine = data.returnObject;
        this.getDepartments();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });

  }

  productlineChange(selectedProduct) {
    this.showLoading=true;
    this.SelProductLine = selectedProduct.value.id;
    this.clicked = false;
    this.getCancelTeam();
  }
  typeChange(selectedType) {
    this.showLoading=true;
    this.SelType = selectedType.id;
    this.clicked=false;
    this.getCancelTeam();
  }
  getCancelTeam() {
    this.showLoading = true;
    if(this.SelProductLine != "" && this.SelProductLine != null && this.SelProductLine != undefined
    &&  this.SelType != "" &&  this.SelType != null &&  this.SelType != undefined)
    {
      this._facadeService.getCRBData(this.SelProductLine, this.SelType).subscribe(
        (data) => {
          this.docTypes = data.returnObject;
          if(this.docTypes != null && this.docTypes != undefined)
          {
            this.docTypes.forEach(e => {
              e.datakey =  e.departmentID.toUpperCase()  + " : " + e.roleID.toUpperCase() + " : " + e.userID.toUpperCase(); 
            });
          }
          this.ViewOnly = false;
          this.Count();
          this.showLoading=false;
        },
        (error) => {
          this.showLoading=false;
          console.log(error);
        }
      );
    }
    else
    {
      this.ViewOnly = true;
      this.clicked=false;
      this.showLoading=false;
    }
  }

  test(val,field,dt)
  {
    if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "roleName")
      sessionStorage.roleName = val;
    else if(field == "userName")
      sessionStorage.userName = val;
    dt = dt.filter(val,field, 'startsWith');
    this.Count();
  }
  Count(){
    if(this.docTypes != undefined && this.docTypes != null)
    {
      var temp = this.docTypes;
      if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
      if(sessionStorage.roleName != "")
        temp = temp.filter(x=> x.roleName.toLowerCase().startsWith(sessionStorage.roleName.toLowerCase()));
      if(sessionStorage.userName != "")
        temp = temp.filter(x=> x.userName.toLowerCase().startsWith(sessionStorage.userName.toLowerCase()));
      this.TeamCount = temp.length;
    }
    else
      this.TeamCount = 0;
  }
  getDepartments() {
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        this.getCancelTeam();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getRoleList() {
    this.showLoading = true;
    this.selectedRole = [];
    this.selectedUser = [];
    this._facadeService
      .getRolesByDepartmentGuid(this.selectedDept.departmentGuid)
      .subscribe((result: any) => {
        this.RolesData = result.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
    this.departmentNameError='';
  }

  getUserList() {
    this.showLoading = true;
    this.selectedUser = [];
    this._facadeService
      .GetUsersByRoleGuid(this.selectedRole.roleGuid)
      .subscribe((result: any) => {
        this.UsersData = result.returnObject;
        if(this.UsersData != undefined && this.UsersData != null)
        {
          if(this.UsersData.length > 0)
          {
            this.UsersData.forEach(element => {
              element.firstName = element.firstName + " " + element.lastName;
            });
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
    this.roleNameError='';
  }

  onRowEditInit(cancelteamModel: iCancelTeam) {
    this.showLoading = true;
    this.clicked = false;
    let doc= [];
    this.docTypes.forEach(i => {
      if(i.departmentName == null)    i.departmentName = "";
      if(i.roleName == null)    i.roleName = "";
      if(i.userName == null)    i.userName = "";
      if(cancelteamModel.departmentName == null)    cancelteamModel.departmentName = "";
      if(cancelteamModel.roleName == null)    cancelteamModel.roleName = "";
      if(cancelteamModel.userName == null)    cancelteamModel.userName = "";
      if(i.departmentName.toUpperCase().trim() == cancelteamModel.departmentName.toUpperCase().trim() &&
      i.roleName.toUpperCase().trim() == cancelteamModel.roleName.toUpperCase().trim() 
      && i.userName.toUpperCase().trim() == cancelteamModel.userName.toUpperCase().trim())
      {
        i.isEdit = "D";
        doc.push(i);
      }
      else
        doc.push(i);
    });
    this.docTypes = doc;
    this.Count();
    this.showLoading = false;
  }

  onRowEditSave(docTypes: iCancelTeam) {
    this.showLoading = true;
    this.addCancelTeam();
  }

  UndoDelete(teamModel: iCancelTeam) {
    this.showLoading = true;
    this.userNameError = '';
    var duplicate = false;
    this.docTypes.forEach(i => {
      if(i.departmentName.toUpperCase().trim() == teamModel.departmentName.toUpperCase().trim() &&
      i.roleName.toUpperCase().trim() == teamModel.roleName.toUpperCase().trim() 
      && i.userName.toUpperCase().trim() == teamModel.userName.toUpperCase().trim() && i.isEdit != "D" )
        duplicate = true;
    });
    if(duplicate)
        this.userNameError = 'Duplicate records found.';
    else 
      teamModel.isEdit = "Y";
    this.showLoading = false;
  }

  onRowEditCancel(row: iCancelTeam, index: number) {
    this.showLoading = true;
    this.clicked = false;
    var a = [];
    this.docTypes.forEach(x => {
      if(x.departmentName == row.departmentName &&  x.roleName == row.roleName && x.userName == row.userName)
      {

      }
      else
        a.push(x);
    });
    this.docTypes = a;
    this.Count();
    this.selectedRole='';
    this.selectedDept='';
    this.selectedUser='';
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';    
    this.showLoading = false;
  }

  newRow() {
    this.Count();
    return { departmentName: '', roleName: '', userName: '' };
  }

  addCancelTeam() {
    this.showLoading = true;
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';

    if (this.selectedDept == undefined || this.selectedDept == '' || this.selectedDept == null) {
      this.departmentNameError = 'Please select department.';this.showLoading = false;
    }
    else if (this.selectedRole == undefined || this.selectedRole == '' || this.selectedRole == null) {
      this.roleNameError = 'Please select role.';this.showLoading = false;
    }
    else if (this.selectedUser == undefined || this.selectedUser == '' || this.selectedUser == null) {
      this.userNameError = 'Please select user.';this.showLoading = false;
    }
    else if (
      this.SelProductLine != undefined &&
      this.SelProductLine != '' &&
      this.selectedDept != undefined &&
      this.selectedDept != '' &&
      this.selectedRole != undefined &&
      this.selectedRole != '' &&
      this.selectedUser != undefined &&
      this.selectedUser != ''
    ) {
      this.cancelteamModel = {
        departmentID: this.selectedDept.departmentGuid,
        departmentName: this.selectedDept.departmentName,
        roleID: this.selectedRole.roleGuid,
        roleName: this.selectedRole.roleName,
        userID: this.selectedUser.userGuid,
        userName: this.selectedUser.firstName,
        isEdit:"Y",
        datakey: this.selectedDept.departmentGuid + " : " + this.selectedRole.roleGuid + " : " + this.selectedUser.userGuid
      };
      this.ok = true;
      this.docTypes.forEach(element => {
        if (this.selectedDept.departmentName == element.departmentName && this.selectedRole.roleName == element.roleName
          && this.selectedUser.firstName == element.userName && this.ok && element.isEdit != "D") {
          this.ok = false;
          this.departmentNameError = 'Duplicate records found.';
        }
      });
      if (this.ok) {
        this.clicked = false;
        this.docTypes.push(this.cancelteamModel);
        this.docTypes = this.docTypes.filter(i => i.departmentName != "");
        this.Count();
        this.selectedRole='';
        this.selectedDept='';
        this.selectedUser='';
        this.departmentNameError = '';
        this.roleNameError = '';
        this.userNameError = '';
      }
      this.showLoading = false;
    }
  }
  savecanceldata() {
    this.showLoading = true;
    if( this.clicked)
      this.addCancelTeam();
    else
    {
      this.productLineError = "";
      this.typeError = "";
      if(this.SelProductLine == "")
      { this.productLineError = "Please select productline";this.showLoading = false;}
      else if(this.SelType == "")
      { this.typeError = "Please select type";this.showLoading = false;}
      else
      {
        let ok = true;
        let doc= [];
        
        this.docTypes.forEach(e =>
        {
          if(e.departmentID == null && ok)
            ok = false;
          if(e.isEdit!="D")
            doc.push(e);
        });
        if(ok)
        {
          if(doc.length == 0)
          {
            this.showLoading=false;
            this.ErrorDisplay = true;
            this.Error = "Please add atleast one member.";
          }
          else{
            doc.forEach(element => {
              element.actionBy =this.userId;
              element.actionDate = new Date(this.pipe.transform(new Date(),'MM/dd/yyyy hh:mm','+0000'));
            });
            this._facadeService.UpdateCRB(doc,this.SelProductLine,this.SelType).subscribe(
              (data) => {
                this.apiResponse = data;
                  if(this.apiResponse.returnCode == 0){
                    this.showLoading=false;
                    this.ErrorDisplay = true;
                    this.clicked = false;
                    this.getCancelTeam();
                    this.Error = "Saved successfully.";
                  }
                  else{
                    this.showLoading=false;
                    this.ErrorDisplay = true;
                    this.Error = this.apiResponse.returnMessage;
                  }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              }
            );
          }
        }
        else
        {
          this.showLoading=false;
          this.ErrorDisplay = true;
          this.Error = "some null values are found. Please refresh the page and try again.";
        }
      }
    }
   }

  resetCtrls() {
    this.showLoading = true;
    this.getCancelTeam();
  }

  exportExcel() {
    if(this.selectedProductLine != undefined && this.selectedProductLine != null)
    {
     /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 150 },{ wpx : 150 },{ wpx : 150 }];
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, this.selectedProductLine.name + " - Conditional Release Board.xlsx");
    }
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;   &nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  print() {
    if(this.selectedProductLine != undefined && this.selectedProductLine != null)
     {
      var Content = document.getElementById('invoice-content').innerHTML;
      var Title = this.selectedProductLine.name + " - Discontinue Approvers";
      this.printService.Print(Content,Title,"trSearch",true);
     }
  }
}
export interface iCancelTeam {
  departmentName: string;
  roleName: string;
  userName: string;
  isEdit : string;
}
