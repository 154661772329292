<div class="p-col-12">
  <div class="card">
    <div class="card-header" style=" border-radius: 5px;height:40px; margin-bottom:5px; ">
      <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
      <span class="p-input-icon-left" style="float:right; margin-right:5px;">
        <label style="padding-right:10px; margin-left:10px;">Product Line:</label>
        <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Line" optionLabel="name" [options]="line"
          [(ngModel)]="selectedLine">
          <ng-template let-value pTemplate="selectedItems">
            <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
              <div>option.name</div>
            </div>
            <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
              Select Product Line
            </div>
          </ng-template>
        </p-multiSelect>
        <label style="padding-right:10px; margin-left:10px;">Project ID:</label>
        <input type="text" maxlength="12" style="padding-left:5px" [(ngModel)]="ProjectID" pInputText placeholder="Enter Project ID"/>      
          <label style="padding-right:10px; margin-left:10px;">Product Status:</label>
        <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Status" [options]="status"
          [(ngModel)]="selectedStatus">
          <ng-template let-value pTemplate="selectedItems">
            <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
              <div>option.name</div>
            </div>
            <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
              Select Product Status
            </div>
          </ng-template>
        </p-multiSelect>
        <a (click)="getproducts('','false')">
          <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
            class="p-button-raised p-button-primary " style="width:auto; float:right; margin-left:10px;"></button>
        </a>
      </span>
    </div>
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
      <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
      <span class="p-field-radiobutton" style="float:left;">
        <label style="margin-right: 10px;">Filter by:</label>
        <p-radioButton value="startsWith" [(ngModel)]="selectedfilter"></p-radioButton>
        <label style="margin-right: 10px;">startsWith</label>
        <p-radioButton value="Contains" [(ngModel)]="selectedfilter"></p-radioButton>
        <label>Contains</label>
      </span>
      <span class="p-input-icon-left" style="float:right;">
        <span class="p-input-icon-left">
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" 
              class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 12px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 12px;"
            (click)="download()"></button>
        </span>
      </span>
    </div>

    <div>
      <p-table #dt  [columns]="cols"  [value]="products"
       id="excel-table" [loading]="loading" [autoLayout]="true" (onFilter)="onFilter($event, dt)"
       styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" [scrollable]="true" scrollHeight="570px">
       <ng-template pTemplate="header" let-columns class="invoice-items">
         <tr>
           <th style="text-align: center;width: 80PX;padding-left:15px">Actions</th>
           <th style="text-align: center;padding-left:15px" *ngFor="let col of columns;let i = index"
             [ngClass]="{'dieCode': i == 1, 'productLine': i == 4 || i ==11,  'phase': i ==9,'w':i==0 || i == 2 || i == 7 || i == 8 || i ==10 || i==3  || i == 5 || i == 6}">
             {{ col.header }}</th>
         </tr>
         <tr id="trSearch">
           <th style="text-align: center;"></th>
          <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
             <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search"
               style="margin: -10px 0 -10px 0; width: 95%;height:25px" />
           </th>
         </tr>
       </ng-template>
       <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
         <tr *ngIf="rowData.productOwner==''" [pSelectableRow]="rowData" style="background-color:#008EBD;color:white;border: 2px;vertical-align: middle;">
           <td style="text-align: left;width: 80PX;">
           </td>
             <th style="text-align: left; padding-left:15px;width:15%;font-size: medium;">{{ rowData.productName }}</th>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
             <td style="text-align: left; padding-left:15px;width:15%;"></td>
         </tr>
         <tr *ngIf="rowData.productOwner!=''" [pSelectableRow]="rowData" style="vertical-align: middle;">
           <td style="text-align: center;width: 80PX;">
               <button pButton type="button" pTooltip="View" tooltipPosition="right"
               (click)="Details(rowData.productID, rowData.phaseID,rowData.type)" icon="pi pi-eye"
               class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
           </td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productName }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.dieCode }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.mfgPart }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productIDDisplay }}</td>
           <td style="text-align: center; padding-left:15px;width:15%;">{{ rowData.productLine }}</td>
           <td style="text-align: left; padding-left:15px;width:15%">{{ rowData.foundry }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.subcon }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productOwner }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productStatus }}</td>
           <td style="text-align: left; padding-left:15px;width:15%;font-weight: bold;"
             [ngStyle]="{'color': rowData.phase === 'P0' ? '#c14a09': rowData.phase === 'Review' ? '#FFA500' :rowData.phase === 'P1' ? '#008000' :rowData.phase === 'P2' ?  '#0000FF': rowData.phase === 'P3 RTS' ? '#3b2820': rowData.phase === 'P3 Production' ? '#800080' :rowData.phase === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
             {{ rowData.phase }}</td>
           <td style="text-align: left; padding-left:15px; width:15%;">{{ rowData.phaseStatus }}</td>
           <td style="text-align: center;padding-left:15px; width:15%;">{{ rowData.phaseStartDate }}</td>
   </tr>
       </ng-template>
       <ng-template pTemplate="summary"> <h5 style="color:#495057; padding:15px 0 0 15px;"> {{ProductCountFilter}}  rows found. </h5></ng-template>
      </p-table>
     </div>
</div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !important;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>