import { Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { Table } from "primeng/table";
import { AllTeam } from '../../../model/PhaseDetails';
import { ManageCustomFieldsService } from "../../../services/manage-custom-fields.service";
import { PrintcommonService } from "../../../services/printcommon.service";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-manageapprovers',
  templateUrl: './manageapprovers.component.html',
  styleUrls: ['./manageapprovers.component.scss']
})
export class ManageapproversComponent implements OnInit {
  showLoading:boolean;
  userId = sessionStorage.getItem("userGuid");
  Product: any[];
  selectedProduct: any;
  productError:string = "";
  SelProduct: string = "";
  Phase: any[];
  selectedPhase: any;
  phaseError:string = "";
  SelPhase: string = "";
  Stage: any[];
  selectedStage: any;
  stageError:string = "";
  SelStage: string = "";

  TeamType: any[];
  selectedType: any;
  typeError:string = "";
  SelType: string = "";

  ErrorDisplay: boolean = false;
  Error: string = "";

  docTypes: AllTeam[];
  clicked: boolean;
  ViewOnly:boolean = true;
  ok: boolean;
  clonedTeams: { [s: string]: iCancelTeam } = {};
  departmentsData: any;
  selectedDept: any;
  RolesData: any;
  selectedRole: any;
  UsersData: any;
  departmentNameError: any;
  roleNameError: any;
  userNameError: any;
  selectedUser: any;
  cols: any[];
  dynamicplaceholder: string;
  apiResponse:any;
  @ViewChild("dt") table: Table;
  canmodify: boolean=false;
  filteredProdType:  any[];
  TeamCount:number=0;
  isChange:boolean=false;
  EditRow: boolean = false;
  OldDID:string = "";
  OldRID:string = "";
  OldUID:string = "";
  userFeature:boolean;
  userFeature1:boolean;
  constructor(
    private _facadeService: FacadeService,private pipe: DatePipe,
    // private router: Router,
    // private http: HttpClient,
    private _ManageCustomFieldsService: ManageCustomFieldsService,
    private printService: PrintcommonService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
  }
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PDM_GAPVIEW");
        if(featureExist!=null){
          this.userFeature = true;
        }

        var featureExist = feature.find(m=>m.code=="PDM_Owner");
        if(featureExist!=null){
          this.userFeature1 = true;
        }
      }
    }
      this.showLoading = true;
      this.GetProduct();

      this.TeamType = [
        { name: 'Approvers', id: 'approval' },
        { name: 'Notification Recipients', id: 'collaborator' },
      ];

      this.cols = [
        { field: 'departmentName', header: 'Department', dynamicplaceholder: "Search by Department" },
        { field: 'roleName', header: 'Role', dynamicplaceholder: "Search by Role" },
        { field: 'userName', header: 'Name', dynamicplaceholder: "Search by Name" },
      ];
      sessionStorage.departmentName = "";
      sessionStorage.roleName = "";
      sessionStorage.userName = "";
  }
  test(val,field,dt)
  {
    if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "roleName")
      sessionStorage.roleName = val;
    else if(field == "userName")
      sessionStorage.userName = val;

    dt = dt.filter(val,field, 'startsWith');
    this.Count();
  }
  Count(){
    if(this.docTypes != undefined && this.docTypes != null)
    {
      var temp = this.docTypes;
      if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
      if(sessionStorage.roleName != "")
        temp = temp.filter(x=> x.roleName.toLowerCase().startsWith(sessionStorage.roleName.toLowerCase()));
      if(sessionStorage.userName != "")
        temp = temp.filter(x=> x.userName.toLowerCase().startsWith(sessionStorage.userName.toLowerCase()));
      this.TeamCount = temp.length;
    }
    else
      this.TeamCount = 0;
  }
  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.Product.length; i++) {
        let product= this.Product[i];
        if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredProdType = filtered;
}

    GetProduct() {
      this.SelProduct="";
      if(this.docTypes != null && this.docTypes != undefined)
      {  this.table.value.forEach(x => {
        this.table.cancelRowEdit(x);
        });
      }
      if(this.userFeature)
      {
        this._ManageCustomFieldsService
        .GetAllActiveProducts()
        .subscribe((data) => {
          this.Product = data.returnObject;
          this.getDepartments();
        });
      }
      else{

        this._ManageCustomFieldsService
        .GetProductByUser(this.userId)
        .subscribe((data) => {
          this.Product = data.returnObject;
          this.getDepartments();
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
      }
  }

  productChange() {
    this.showLoading=true;
    this.phaseError = "";
    this.SelProduct =this.selectedProduct.productGuid;
    this.ViewOnly = true;
    this.SelPhase = "";
    this.SelStage = "";
    this.clicked=false;
    this.isChange = false;
    this.getphase();
  }

  getphase() {
    this.SelPhase="";
    if(this.docTypes != null && this.docTypes != undefined)
    {  this.table.value.forEach(x => {
      this.table.cancelRowEdit(x);
      });
    }
    this._facadeService.GetPhasesbyID(this.SelProduct)
    .subscribe((data) => {
      this.Phase = data.returnObject;
      this.ViewOnly = true;
      this.getCancelTeam();
    },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
}

phaseChange(selectedPhase) {
  this.showLoading=true;
  this.SelPhase = selectedPhase.phaseGuid;
  this.SelStage="";
  this.clicked=false;
  this.isChange = false;
  this.getstage();
}
getstage()
{
  this.SelStage = "";
  if(this.docTypes != null && this.docTypes != undefined)
  {  this.table.value.forEach(x => {
    this.table.cancelRowEdit(x);
    });
  }
  this._facadeService.GetStagesbyID(this.SelPhase)
  .subscribe((data) => {
    this.Stage = data.returnObject;
    this.ViewOnly = true;
    this.getCancelTeam();
  },
  (error) => {
    this.showLoading = false;
    console.log(error);
  });
}
listChange(selectedList) {
  this.showLoading=true;
  this.SelStage = selectedList.listGuid;
  this.clicked=false;
  this.isChange = false;
  this.getCancelTeam();
}
typeChange(selectedType) {
  this.showLoading=true;
  this.SelType = selectedType.id;
  this.clicked=false;
  this.isChange = false;
  this.getCancelTeam();
}
  getCancelTeam() {
    this.showLoading = true;
    this.EditRow = false;
    this.OldDID = "";
    this.OldRID = "";
    this.OldUID = ""; 
    this.selectedRole='';
    this.selectedDept='';
    this.selectedUser='';
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';  
    if(this.docTypes != null && this.docTypes != undefined)
    {  this.table.value.forEach(x => {
      this.table.cancelRowEdit(x);
      });
    }
    this.phaseError="";
    if(this.SelProduct != "" && this.SelProduct != null && this.SelProduct != undefined 
    && this.SelPhase != "" && this.SelPhase != null && this.SelPhase != undefined 
    && this.SelType != "" && this.SelType != null && this.SelType != undefined
    && this.SelStage != "" && this.SelStage != null && this.SelStage != undefined)
    {
      this._facadeService.GetTeam("List",this.SelType,this.SelStage).subscribe(
        (data) => {
          this.docTypes = data.returnObject.team;
          if(this.docTypes != null && this.docTypes != undefined)
          {
            this.docTypes.forEach(e => {
              e.datakey =  e.departmentID.toUpperCase()  + " : " + e.roleID.toUpperCase() + " : " + e.userID.toUpperCase(); 
            });
          }
          if(this.userFeature)
            this.ViewOnly = false;
          this.Count();
          if(this.userFeature1 && !this.ViewOnly)
          {
            this._facadeService.IsOwner('List',this.SelStage,this.userId).subscribe(
              (data) => {
                this.canmodify=data.returnObject;
                if(!this.canmodify )
                {
                  this.phaseError="You don't have access to modify the selected approvers/notifiers.";
                  this.ViewOnly = true;
                }
                this.showLoading=false;
              },
              (error) => {  console.log(error); this.showLoading = false;  }
              );
          }
          else
            this.showLoading=false;
        },
        (error) => {  console.log(error); this.showLoading = false;  }
      );
    }
    else if(this.SelProduct != "" && this.SelProduct != null && this.SelProduct != undefined 
    && this.SelPhase != "" && this.SelPhase != null && this.SelPhase != undefined 
    && this.SelType != "" && this.SelType != null && this.SelType != undefined)
    {
      this.ViewOnly = true;
      this._facadeService.GetTeam("Phase",this.SelType,this.SelPhase).subscribe(
        (data) => {
          this.docTypes = data.returnObject.team;
          if(this.docTypes != null && this.docTypes != undefined)
          {
            this.docTypes.forEach(e => {
              e.datakey =  e.departmentID.toUpperCase()  + " : " + e.roleID.toUpperCase() + " : " + e.userID.toUpperCase(); 
            });
          }
          if(this.userFeature)
            this.ViewOnly = false;
          this.Count();
          if(this.userFeature1)
          {
            this._facadeService.IsOwner('Phase',this.SelPhase,this.userId).subscribe(
              (data) => {
                this.canmodify=data.returnObject;
                if(!this.canmodify)
                {
                  this.phaseError="You don't have access to modify the selected approvers/notifiers.";
                  this.ViewOnly = true;
                }
                else
                  this.ViewOnly = false;
                this.showLoading=false;
              },
              (error) => {  console.log(error); this.showLoading = false;  }
              );
            }
            else
              this.showLoading=false;
        },
        (error) => {  console.log(error); this.showLoading = false;  }
      );
      
    }
    else
    {
      this.docTypes = [];
      this.Count();
      this.ViewOnly = true;
      this.showLoading=false;
    }
  }

  getDepartments() {
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        this.getCancelTeam();
      },
      (error) => {  console.log(error); this.showLoading = false;  }
    );
  }
  getRoleList(RoleID,UserID) {
    this.showLoading = true;
    this.selectedRole = [];
    this.selectedUser = [];
    this._facadeService
      .getRolesByDepartmentGuid(this.selectedDept.departmentGuid)
      .subscribe((result: any) => {
        this.RolesData = result.returnObject;
        if(RoleID != "")
          this.selectedRole = this.RolesData.find(x=>x.roleGuid.toUpperCase() == RoleID.toUpperCase());
        if(UserID)
          this.getUserList(UserID);
        console.log("from_Roles ::" + JSON.stringify(this.RolesData));
        this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  });
    this.departmentNameError='';
  }

  getUserList(UserID) {
    this.showLoading = true;
    this.selectedUser = [];
    this._facadeService
      .GetUsersByRoleGuid(this.selectedRole.roleGuid)
      .subscribe((result: any) => {
        this.UsersData = result.returnObject;
        if(this.UsersData != undefined && this.UsersData != null)
        {
          if(this.UsersData.length > 0)
          {
            this.UsersData.forEach(element => {
              element.firstName = element.firstName + " " + element.lastName;
            });
            if(UserID != "")
              this.selectedUser = this.UsersData.find(x=>x.userGuid.toUpperCase() == UserID.toUpperCase());
          }
        }
        console.log("from_Users ::" + JSON.stringify(this.UsersData));
        this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  });
    this.roleNameError='';
  }

  onRowEditInit(teamModel: iCancelTeam) {
    this.showLoading = true;
    this.clicked = false;
    let doc= [];
    this.docTypes.forEach(i => {
      if(i.departmentName == null)    i.departmentName = "";
      if(i.roleName == null)    i.roleName = "";
      if(i.userName == null)    i.userName = "";
      if(teamModel.departmentName == null)    teamModel.departmentName = "";
      if(teamModel.roleName == null)    teamModel.roleName = "";
      if(teamModel.userName == null)    teamModel.userName = "";
      if(i.departmentName.toUpperCase().trim() == teamModel.departmentName.toUpperCase().trim() &&
      i.roleName.toUpperCase().trim() == teamModel.roleName.toUpperCase().trim() 
      && i.userName.toUpperCase().trim() == teamModel.userName.toUpperCase().trim())
      {
        i.isEdit = "D";
        //if(i.isEdit == "N")
          doc.push(i);
      }
      else
        doc.push(i);
    });
    this.docTypes = doc;
    this.Count();
    this.showLoading = false;
  }
  onRowEditInit1(row) {
    this.showLoading = true;
    if(this.docTypes != null && this.docTypes != undefined)
    {  this.table.value.forEach(x => {
      if(x.departmentName == row.departmentName &&  x.roleName == row.roleName && x.userName == row.userName)
      {

      }
      else
        this.table.cancelRowEdit(x);
      });
    }
    this.EditRow = true;
    this.OldDID = row.departmentID;
    this.OldRID = row.roleID;
    this.OldUID = row.userID;
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';    
    this.clicked = true;
    this.selectedDept = this.departmentsData.find(x=>x.departmentGuid.toUpperCase() == row.departmentID.toUpperCase());
    this.getRoleList(row.roleID,row.userID);
    this.showLoading = false;
  }

  onRowEditSave(docTypes: iCancelTeam) {
    this.showLoading = true;
    this.addCancelTeam();
  }

  UndoDelete(teamModel: iCancelTeam) {
    this.showLoading = true;
    this.userNameError = '';
    var duplicate = false;
    this.docTypes.forEach(i => {
      if(i.departmentName.toUpperCase().trim() == teamModel.departmentName.toUpperCase().trim() &&
      i.roleName.toUpperCase().trim() == teamModel.roleName.toUpperCase().trim() 
      && i.userName.toUpperCase().trim() == teamModel.userName.toUpperCase().trim() && i.isEdit != "D" )
        duplicate = true;
    });
    if(duplicate)
        this.userNameError = 'Duplicate records found.';
    else 
      teamModel.isEdit = "Y";
    this.showLoading = false;
  }

  onRowEditCancel(row: iCancelTeam, index: number) {
    this.showLoading = true;
    this.clicked = false;
    if(!this.EditRow)
    {
      var a = [];
      this.docTypes.forEach(x => {
        if(x.departmentName == row.departmentName &&  x.roleName == row.roleName && x.userName == row.userName)
        {
  
        }
        else
          a.push(x);
      });
      this.docTypes = a;
    }
    this.Count();
    this.selectedRole='';
    this.selectedDept='';
    this.selectedUser='';
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';   
    this.EditRow = false;
    this.OldDID = "";
    this.OldRID = "";
    this.OldUID = "";   
    this.showLoading = false;
  }

  newRow() {
    this.Count();
    return { departmentName: '', roleName: '', userName: '',datakey: ''  };
  }

  addCancelTeam() {
    this.showLoading = true;
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';

    if (this.selectedDept == undefined || this.selectedDept == '' || this.selectedDept == null) {
      this.departmentNameError = 'Please select department.';this.showLoading = false;
    }
    else if (this.selectedRole == undefined || this.selectedRole == '' || this.selectedRole == null) {
      this.roleNameError = 'Please select role.';this.showLoading = false;
    }
    else if (this.selectedUser == undefined || this.selectedUser == '' || this.selectedUser == null) {
      this.userNameError = 'Please select user.';this.showLoading = false;
    }
    else if (
      this.SelProduct != undefined &&
      this.SelProduct != '' &&
      this.SelPhase != undefined &&
      this.SelPhase != '' &&
      this.selectedDept != undefined &&
      this.selectedDept != '' &&
      this.selectedRole != undefined &&
      this.selectedRole != '' &&
      this.selectedUser != undefined &&
      this.selectedUser != ''
    ) {
      this.ok = true;
      this.docTypes.forEach(element => {
        if (this.selectedDept.departmentName == element.departmentName && this.selectedRole.roleName == element.roleName
          && this.selectedUser.firstName == element.userName && this.ok && element.isEdit != "D") {
          this.ok = false;
          this.departmentNameError = 'Duplicate records found.';
        }
      });
      if (this.ok) {
        if(this.EditRow)
        {
          this.docTypes.forEach(element => {
            if (this.OldDID.toUpperCase() == element.departmentID.toUpperCase() && this.OldRID.toUpperCase() == element.roleID.toUpperCase()
              && this.OldUID.toUpperCase() == element.userID.toUpperCase() && element.isEdit != "D") {
                element.isEdit = "E";
                element.departmentID = this.selectedDept.departmentGuid;
                element.departmentName = this.selectedDept.departmentName;
                element.roleID = this.selectedRole.roleGuid;
                element.roleName = this.selectedRole.roleName;
                element.userID = this.selectedUser.userGuid;
                element.userName = this.selectedUser.firstName;
                element.datakey = this.selectedDept.departmentGuid + " : " + this.selectedRole.roleGuid + " : " + this.selectedUser.userGuid
            }
          });
          this.EditRow = false;
          this.OldDID = "";
          this.OldRID = "";
          this.OldUID = "";
        }
        else
        {
          var teamModel = {
            type:this.selectedType.id, 
            departmentID: this.selectedDept.departmentGuid,
            departmentName: this.selectedDept.departmentName,
            roleID: this.selectedRole.roleGuid,
            roleName: this.selectedRole.roleName,
            userID: this.selectedUser.userGuid,
            userName: this.selectedUser.firstName,
            isEdit:"Y",
            datakey: this.selectedDept.departmentGuid + " : " + this.selectedRole.roleGuid + " : " + this.selectedUser.userGuid
          };
          this.docTypes.push(teamModel);
        }
        this.clicked = false;
        this.docTypes = this.docTypes.filter(i => i.departmentName != "");
        this.Count();
        this.selectedRole='';
        this.selectedDept='';
        this.selectedUser='';
        this.departmentNameError = '';
        this.roleNameError = '';
        this.userNameError = '';
      }
      this.showLoading = false;
    }
  }

  savecanceldata() {
    this.showLoading = true;
    if( this.clicked)
      this.addCancelTeam();
    else
    {
      this.productError = '';
      this.phaseError = '';
      if(this.SelProduct == "")
      { this.productError = "Please select product";this.showLoading = false;}
      else if(this.SelPhase == "")
      { this.phaseError = "Please select phase";this.showLoading = false;}
      else
      {
        let ok = true;
        let doc= [];
        this.docTypes.forEach(e =>
        {
          if(e.type == null && ok)
            ok = false;
          if(e.isEdit!="D")
            doc.push(e);
        });
        if(ok)
        {
          if(doc.length == 0 && this.selectedPhase.isApproval)
          {
            this.showLoading=false;
            this.ErrorDisplay = true;
            this.Error = "Please add atleast one member.";
          }
          else
          {   
            var MangeteamModel = {
              type:"Phase", 
              phaseID: this.SelPhase,
              listID: this.SelPhase,
              team: doc,  
              AddedBy: this.userId,
              type1: this.selectedType.id, 
              ChangeFuturePhases: this.isChange
            };
            
            this._facadeService.UpdateTeam(MangeteamModel).subscribe(
              (data) => {
                this.apiResponse = data;
                  if(this.apiResponse.returnCode == 0){
                    this.showLoading=false;
                    this.getCancelTeam();
                    this.ErrorDisplay = true;
                    this.Error = "Saved successfully.";
                  }
                  else{
                    this.showLoading=false;
                    this.ErrorDisplay = true;
                    this.Error = this.apiResponse.returnMessage;
                  }
              },
              (error) => {  console.log(error); this.showLoading = false;  }
            );
          }
        }
        else
        {
          this.showLoading=false;
          this.ErrorDisplay = true;
          this.Error = "Some fields are not selected. You can save after selecting them.";
        }
      }
    }
   }

  resetCtrls() {
    this.showLoading = true;
    this.getCancelTeam();
  }

  exportExcel() {  
     if(this.selectedPhase != undefined && this.selectedPhase != null && this.selectedType != undefined && this.selectedType != null)
     {
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 150 },{ wpx : 150 },{ wpx : 150 }];
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, this.selectedProduct.productName + " - " + this.selectedPhase.phaseName + " - " + this.selectedType.name + ".xlsx");
     }
  }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }  
  print(SearchID) {
    if(this.selectedPhase != undefined && this.selectedPhase != null && this.selectedType != undefined && this.selectedType != null)
     {
      var Content = document.getElementById('invoice-content').innerHTML;
      var Title = this.selectedProduct.productName + " - " + this.selectedPhase.phaseName + " - " + this.selectedType.name;
      this.printService.Print(Content,Title,SearchID,true);
     }
  }
}
export interface iCancelTeam {
  departmentName: string;
  roleName: string;
  userName: string;
  isEdit : string;
}

