import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent implements OnInit {
  showLoading = false;
  constructor(private route: Router, private router: ActivatedRoute,
    private _facadeService: FacadeService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    var guid = this.router.snapshot.queryParamMap.get('userguid');
    var userId = this.router.snapshot.queryParamMap.get('userId');
    var IsDCCQuery = this.router.snapshot.queryParamMap.get('IsDCC');
    var token = this.router.snapshot.queryParamMap.get('access_token');
    var ProductID = this.router.snapshot.queryParamMap.get("ProductID");
    var destination = this.router.snapshot.queryParamMap.get('destination');
    var pid = this.router.snapshot.queryParamMap.get("PhaseID");
    var bearer_token = this.router.snapshot.queryParamMap.get('bt');
    var appliId = this.router.snapshot.queryParamMap.get('appliId');
    var clientId = this.router.snapshot.queryParamMap.get('clientId');
    var QuestionId = this.router.snapshot.queryParamMap.get('QuestionId');
    sessionStorage.appliId = appliId;
    sessionStorage.clientId = clientId;
    sessionStorage.bt = bearer_token;
    var userIdint = parseInt(userId);
    sessionStorage.userId = userIdint;
    sessionStorage.userGuid = guid;
    sessionStorage.IsDCC = IsDCCQuery;
    sessionStorage.setItem("access_token", token);
    if (ProductID != null && ProductID != "" && ProductID != undefined) {
      sessionStorage.ProductID = ProductID;
    }
    if (pid != null && pid != "" && pid != undefined) {
      sessionStorage.PhaseID = pid;
    }
    this.showLoading = false;
    this._facadeService.UpdateSecurityToken(token).subscribe(
      (data) => {
        if (data.returnObject) {
          if (destination == "phasedetails") 
          location.href = this.baseUrl["API_ENDPOINTS"]["ProductUI"] + 'phasedetails?QuestionId=' +QuestionId;
          else if (destination == "myapproval")
            this.route.navigate(["/myapproval"]);
          else if (destination == "eccn")
            this.route.navigate(["/eccn"]);
          else 
            this.route.navigate(["/dashboard", { IsDCC: IsDCCQuery }]);
        }
        else
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
