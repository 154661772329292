import { Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { ManageCustomFieldsService } from "../../../services/manage-custom-fields.service";
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../../services/printcommon.service';
import { Router} from '@angular/router';
import {DashboardProducts} from "../../../model/DashboardData";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import { SearchModel } from '../../../model/PhaseDetails';
import {SelectItem} from 'primeng/api';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-productattributes',
  templateUrl: './productattributes.component.html',
  styleUrls: ['./productattributes.component.scss']
})
export class ProductattributesComponent implements OnInit {
  ErrorDisplay: boolean = false;
  Error: string = "";
  loading: "loading";
  showLoading:boolean;
  ProductGUID = sessionStorage.getItem('ReportProductGUID');
  productName:any="";
  dieCode:any="";
  productOwner:any="";
  productline:any="";
  mfgPart:any="";
  productStatus:any="";
  currentPhase:any="";
  projectDescription:any="";
  currentphasestatus:any="";
  
  searchModel: SearchModel;
  cols: any[];
  products: any[];
  selectedProducts: DashboardProducts[];
  @ViewChild('dt') table: Table;
  productaccordian : boolean = true;
  attcols:any[];
  attributes: any[];
  selectedattributes: any[];
  @ViewChild('dtatt') atttable: Table;

  teamcols:any[];
  teams: any[];
  selectedteams: any[];
  @ViewChild('dtteam') teamtable: Table;

  attdisplay:boolean = false;
  teamdisplay:boolean=false;
  
  SelectedCount:number = 0;
  ProductCount: number = 0;
  AttrCount: number = 0;
  TeamCount: number = 0;

  status: SelectItem[];
  line: any[];
  l:string[];
  selectedLine:any[]=[];
  selectedStatus:any[]=[];

  productArray: any[] = [];
  multipleproducts:any[];
  singleproducts:any[];
  mulcols: any[];
  @ViewChild('dtmulti') multitable: Table;
  @ViewChild('dtsin') singletable: Table;
  printTitle:any;
  userFeature:boolean;
constructor(
  private _facadeService: FacadeService,
  private _ManageCustomFieldsService: ManageCustomFieldsService,
  public printService: PrintcommonService,
  private router: Router,
  @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
) {}

ngOnInit() {
  const userFeatures = sessionStorage.getItem('userFeatures');
  if (userFeatures) {
    var feature = JSON.parse(userFeatures);
    if(feature.length>0){
      var featureExist = feature.find(m=>m.code=="PDM_DOWNLOAD");
      if(featureExist!=null){
        this.userFeature = true;
      }
    }
  }
  this.showLoading = true;
  this.attcols = [
    { field: 'customGroup', header: 'Attribute Group' },
    { field: 'fieldName', header: 'PLM Custom Attribute' },
    { field: 'valueName', header: 'Value' },
    { field: 'isChangeMangement', header: 'CCB Approval' }
  ];
  this.mulcols = [
    { field: 'customGroup', header: 'Attribute Group' },
    { field: 'fieldName', header: 'PLM Custom Attribute' },
    { field: 'p1', header: 'Product 1'},
  ];
  this.teamcols = [
  { field: 'phaseName', header: 'Phase' },
  { field: 'typeDisplay', header: 'Type' },
  { field: 'departmentName', header: 'Department' },
  { field: 'roleName', header: 'Role' },
  { field: 'userName', header: 'User' }
];
  this.cols = [
    { field: 'productName', header: 'Product' },
    { field: 'dieCode', header: 'Die Code' },
    { field: 'mfgPart', header: 'Mfg Part' },
    { field: 'productLine', header: 'Product Line' },
    { field: 'productOwner', header: 'Product Engineer' },
    { field: 'productStatus', header: 'Product Status' },
    { field: 'phase', header: 'Phase' },
    { field: 'phaseStatus', header: 'Phase Status' },
  ];
  this.status = [ 
    {label: 'New', value: 'New'},
    {label: 'In-Process', value: 'In-Process'},
    {label: 'Completed', value: 'Completed'},
    {label: 'EOL', value: 'EOL'},
    {label: 'OBS', value: 'OBS'},
    {label: 'Waiting for Discontinuation', value: 'Waiting for Discontinuation'},
    {label: 'Seek Clarification for Discontinuation', value: 'Seek Clarification for Discontinuation'},
    {label: 'Hold', value: 'Hold'},
    {label: 'Discontinued', value: 'Discontinued'},
  ];
  sessionStorage.productName = "";
  sessionStorage.dieCode = "";
  sessionStorage.mfgPart = "";
  sessionStorage.productLine = "";
  sessionStorage.productOwner = "";
  sessionStorage.productStatus = "";
  sessionStorage.phase = "";
  sessionStorage.phaseStatus = "";

  sessionStorage.customGroup = "";
  sessionStorage.fieldName = "";
  sessionStorage.valueName = "";
  sessionStorage.isChangeMangement = "";

  sessionStorage.phaseName = "";
  sessionStorage.typeDisplay = "";
  sessionStorage.departmentName = "";
  sessionStorage.roleName = "";
  sessionStorage.userName = "";

  let ok = false;
  if(sessionStorage.getItem('reportattstatus') != null && sessionStorage.getItem('reportattstatus') != undefined)
  {
    var projectstats = [];
    projectstats = sessionStorage.getItem('reportattstatus').split(',');
    projectstats.forEach((element) => {
      this.selectedStatus.push(element);
    });
    ok = true;
  }
  if(!ok)
  {
    this.selectedStatus.push('New');
    this.selectedStatus.push('In-Process');
    // this.selectedStatus.push('Completed');
    this.selectedStatus.push('Waiting for Discontinuation');
    this.selectedStatus.push('Seek Clarification for Discontinuation');
    sessionStorage.reportattstatus = this.selectedStatus;
  }
  this.GetProductLne();
}
GetProductLne() {
  this._facadeService.GetProductLines()
  .subscribe((data) => {
    this.line = data.returnObject;
    let ok = false;
    if(sessionStorage.getItem('reportattline') != null && sessionStorage.getItem('reportattline') != undefined && sessionStorage.getItem('reportattline').indexOf("object") <= -1)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('reportattline').split(',');
      projectstats.forEach((element) => {
        this.line.forEach((ln) => {
          if(ln.id == element)
            this.selectedLine.push(ln);
        });
      });
      ok = true;
    }
    if(!ok)
    {
      this.line.forEach((ln) => {
        this.selectedLine.push(ln);
      });
      var lm=[];
      this.selectedLine.forEach((element) => {
        lm.push(element.id);
      });
      sessionStorage.reportattline = lm;
    }
    this.getproducts(this.selectedLine,'true');
    console.log("ProductLine data ::" + JSON.stringify(this.line));
  },
  (error) => {  console.log(error); this.showLoading = false;  });
}
test(val,field,dt)
{
  if(field == "productName")
    sessionStorage.productName = val;
  else if(field == "dieCode")
    sessionStorage.dieCode = val;
  else if(field == "mfgPart")
    sessionStorage.mfgPart = val;
  else if(field == "phase")
    sessionStorage.phase = val;
  else if(field == "phaseStatus")
    sessionStorage.phaseStatus = val;
  else if(field == "productLine")
    sessionStorage.productLine = val;
  else if(field == "productOwner")
    sessionStorage.productOwner = val;
  else if(field == "productStatus")
    sessionStorage.productStatus = val;
  dt = dt.filter(val,field, 'startsWith');
  this.count();
}
test1(val,field,dt)
{
  if(field == "customGroup")
    sessionStorage.customGroup = val;
  else if(field == "fieldName")
    sessionStorage.fieldName = val;
  else if(field == "valueName")
    sessionStorage.valueName = val;
  else if(field == "isChangeMangement")
    sessionStorage.isChangeMangement = val;
  dt = dt.filter(val,field, 'startsWith');
  this.count1();
}
test2(val,field,dt)
{
  if(field == "phaseName")
    sessionStorage.phaseName = val;
  else if(field == "typeDisplay")
    sessionStorage.typeDisplay = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "roleName")
    sessionStorage.roleName = val;
  else if(field == "userName")
    sessionStorage.userName = val;
  dt = dt.filter(val,field, 'startsWith');
  this.count2();
}
getproducts(l,Type) {
  this.showLoading = true;
  var lm = [];
  if(Type == 'false')
  {
    this.selectedLine.forEach((element) => {
        lm.push(element.id);
    });
    sessionStorage.reportattline = lm;
    sessionStorage.reportattstatus = this.selectedStatus;
  }
  else
  {
    l.forEach((element) => {
      lm.push(element.id);
    });
  }
  this.searchModel={
    productLineID:lm,
    productStatus:this.selectedStatus,
    projectID: "",
    download: ""
  }
  this._facadeService.ReportProductsD(this.searchModel).subscribe(
    (data) => {
        this.products = data.returnObject;  
        var att = [];
        var count = 1;

        this.products.forEach((element) => {
          if(count < 26)
            att.push({Seq:count,ProductGuid:element.productGuid,ProductName:element.productName,ProductOwner:element.productOwner});
          count ++;
        });
        this.singleproducts = [];
        this._facadeService.GetProductsAttributes(att).subscribe(
          (data) => {
            this.multipleproducts = data.returnObject;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );

        this.count();
        if(this.ProductGUID != undefined && this.ProductGUID != null)
        {
          var row = this.products.filter(m=>m.productGuid.toLowerCase()==this.ProductGUID.toLowerCase());
          this.Product(row);
        }
        console.log(this.products); 
        this.showLoading = false;
    },
    (error) => {  console.log(error); this.showLoading = false;  }
  );
}
count()
{
  if(this.products != undefined && this.products != null)
  {
    var temp = this.products;
    if(sessionStorage.productName != "")
      temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
    if(sessionStorage.dieCode != "")
      temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode.toLowerCase()));
    if(sessionStorage.mfgPart != "")
      temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart.toLowerCase()));
    if(sessionStorage.phase != "")
      temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase.toLowerCase()));
    if(sessionStorage.phaseStatus != "")
      temp = temp.filter(x=> x.phaseStatus.toLowerCase().startsWith(sessionStorage.phaseStatus.toLowerCase()));
    if(sessionStorage.productLine != "")
      temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine.toLowerCase()));
    if(sessionStorage.productOwner != "")
      temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner.toLowerCase()));
    if(sessionStorage.productStatus != "")
      temp = temp.filter(x=> x.productStatus.toLowerCase().startsWith(sessionStorage.productStatus.toLowerCase()));
    this.ProductCount = temp.length;
  }
  else
    this.ProductCount = 0;
}
onSelectionChange(value = []) {
  this.showLoading = true;
  this.productArray =[];
  this.SelectedCount = value.length;
  if(value.length > 0)
  {
    value.forEach((value, key) => {
      this.productArray.push(value.productGuid);
    });
  }
  this.selectedProducts = value;
  if(this.productArray!=null && this.productArray !=undefined && this.productArray.length>0)
  {
    if(this.productArray.length <= 25)
    {
      var att = [];
      var count = 1;
      this.productArray.forEach((element) => {
        var name,owner;
        this.products.forEach((value, key) => {
          if(value.productGuid.toUpperCase() == element.toUpperCase())
          {
            name = value.productName;
            owner = value.productOwner;
          }
        });
        att.push({Seq:count,ProductGuid:element,ProductName:name,ProductOwner:owner});
        count ++;
      });
      if(this.productArray.length  == 1)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
          ];
        }
        else if(this.productArray.length  == 2)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
          ];
        }
        else if(this.productArray.length  == 3)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
          ];
        }
        else if(this.productArray.length  == 4)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
          ];
        }
        else if(this.productArray.length  == 5)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
          ];
        }
        else if(this.productArray.length  == 6)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
          ];
        }
        else if(this.productArray.length  == 7)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
          ];
        }
        else if(this.productArray.length  == 8)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
          ];
        }
        else if(this.productArray.length  == 9)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
          ];
        }
        else if(this.productArray.length  == 10)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
          ];
        }
        else if(this.productArray.length  == 11)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
          ];
        }
        else if(this.productArray.length  == 12)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
          ];
        }
        else if(this.productArray.length  == 13)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
          ];
        }
        else if(this.productArray.length  == 14)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
          ];
        }
        else if(this.productArray.length  == 15)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
          ];
        }
        else if(this.productArray.length  == 16)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
          ];
        }
        else if(this.productArray.length  == 17)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
          ];
        }
        else if(this.productArray.length  == 18)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
          ];
        }
        else if(this.productArray.length  == 19)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
          ];
        }
        else if(this.productArray.length  == 20)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
            { field: 'p20', header: 'Product 20'},
          ];
        }
        else if(this.productArray.length  == 21)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
            { field: 'p20', header: 'Product 20'},
            { field: 'p21', header: 'Product 21'},
          ];
        }
        else if(this.productArray.length  == 22)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
            { field: 'p20', header: 'Product 20'},
            { field: 'p21', header: 'Product 21'},
            { field: 'p22', header: 'Product 22'},
          ];
        }
        else if(this.productArray.length  == 23)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
            { field: 'p20', header: 'Product 20'},
            { field: 'p21', header: 'Product 21'},
            { field: 'p22', header: 'Product 22'},
            { field: 'p23', header: 'Product 23'},
          ];
        }
        else if(this.productArray.length  == 24)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
            { field: 'p20', header: 'Product 20'},
            { field: 'p21', header: 'Product 21'},
            { field: 'p22', header: 'Product 22'},
            { field: 'p23', header: 'Product 23'},
            { field: 'p24', header: 'Product 24'},
          ];
        }
        else if(this.productArray.length  == 25)
        {
          this.mulcols = [
            { field: 'customGroup', header: 'Attribute Group' },
            { field: 'fieldName', header: 'PLM Custom Attribute' },
            { field: 'p1', header: 'Product 1'},
            { field: 'p2', header: 'Product 2'},
            { field: 'p3', header: 'Product 3'},
            { field: 'p4', header: 'Product 4'},
            { field: 'p5', header: 'Product 5'},
            { field: 'p6', header: 'Product 6'},
            { field: 'p7', header: 'Product 7'},
            { field: 'p8', header: 'Product 8'},
            { field: 'p9', header: 'Product 9'},
            { field: 'p10', header: 'Product 10'},
            { field: 'p11', header: 'Product 11'},
            { field: 'p12', header: 'Product 12'},
            { field: 'p13', header: 'Product 13'},
            { field: 'p14', header: 'Product 14'},
            { field: 'p15', header: 'Product 15'},
            { field: 'p16', header: 'Product 16'},
            { field: 'p17', header: 'Product 17'},
            { field: 'p18', header: 'Product 18'},
            { field: 'p19', header: 'Product 19'},
            { field: 'p20', header: 'Product 20'},
            { field: 'p21', header: 'Product 21'},
            { field: 'p22', header: 'Product 22'},
            { field: 'p23', header: 'Product 23'},
            { field: 'p24', header: 'Product 24'},
            { field: 'p25', header: 'Product 25'},
          ];
        }
        this._facadeService.GetProductsAttributes(att).subscribe(
          (data) => {
            this.multipleproducts = data.returnObject;
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
    }
    else
      this.showLoading = false;
  }
  else
    this.showLoading = false;
}

private date_TO_String(date_Object) {
  var date_String = date_Object.getFullYear() +
     "/" +
     (date_Object.getMonth() + 1) +
     "/" +
     +date_Object.getDate() +
     " " +
     +date_Object.getHours() +
     ":" +
     +date_Object.getMinutes();
  return date_String;
}
AllDownload() {
  this.showLoading = true;
  var att = [];
  var count = 1;
  this.products.forEach((element) => {
    att.push({Seq:count,ProductGuid:element.productGuid,ProductName:element.productName,ProductOwner:element.productOwner});
    count ++;
  });
  this._facadeService.GetAllProductsAttributes(att).subscribe(response => {
    response.filename = "Products Attributes " + this.date_TO_String(new Date());
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = "Products Attributes " + this.date_TO_String(new Date());
    anchor.href = url;
    anchor.click();
    anchor.remove();
    this.showLoading = false;
  },
  (error) => {  console.log(error); this.showLoading = false;  });
}

multipledownload()
{
  this.ErrorDisplay = false;
  this.Error = "";
  if(this.productArray!=null && this.productArray !=undefined && this.productArray.length>0){
    if(this.productArray.length > 25)
    {
      this.ErrorDisplay = true;
      this.Error = "Maximum 25 products selection is allowed";
    }
    else
    {
      this.showLoading = true;
      this.download('excel-table4');
      this.showLoading = false;
    }
  }
  else
  {
    this.ErrorDisplay = true;
    this.Error = "Please select atleast one Product";
  }
}
download(val) {
  let element = document.getElementById(val); 
  element.innerHTML
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  if(val == "excel-table")
    ws['!cols'] = [{ wpx : 1 },{ wpx : 180 },{ wpx : 80 },{ wpx : 150 },{ wpx : 90 },{ wpx : 130 },{ wpx : 150 },{ wpx : 100 },{ wpx : 110 }];
  if(val == "excel-table4" || val == "excel-table5")
    ws['!cols'] = [{ wpx : 110 },{ wpx : 220 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },
      { wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 }
      ,{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 },{ wpx : 350 }];
  if(val == "excel-table2")
    ws['!cols'] = [{ wpx : 100 },{ wpx : 120 },{ wpx : 150 },{ wpx : 150 },{ wpx : 150 }];

  if(val != "excel-table4" && val != "excel-table5")
   this.delete_row(wb.Sheets.Sheet1, 1,val);

  /* save to file */
  if(val == "excel-table")
    XLSX.writeFile(wb, "Products.xlsx");
  if(val == "excel-table5")
    XLSX.writeFile(wb,this.productName + " (" + this.mfgPart + ") Attributes.xlsx");
  if(val == "excel-table2")
    XLSX.writeFile(wb,this.productName + " (" + this.mfgPart + ") Team.xlsx");
  if(val == "excel-table4")
    XLSX.writeFile(wb, "Products Attributes.xlsx");
}

  delete_row (ws, row_index,val){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    if(val == "excel-table")
      delete (ws['J1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
Details(ProductID, PhaseID,Type) {
  sessionStorage.ProductID = ProductID;
  sessionStorage.PhaseID = PhaseID;
  sessionStorage.DisplayType = Type;
  sessionStorage.FromWhere = "att";
  this.router.navigate(['/phasedetails']);
}
print(MainID,SearchID) {
  var HideLastColumn = true;
  var Content = document.getElementById(MainID).innerHTML;
  if(MainID == "invoice-content1" || MainID == "invoice-content2")
  {
    Content = '<br>' + document.getElementById("productdetails").innerHTML + '<br>' + Content;
    HideLastColumn = false;
  }
  var Title = "";
  if(MainID == "invoice-content")
    Title =  "Products";
  if(MainID == "invoice-content1")
    Title = this.productName + " (" + this.mfgPart + ") Attributes";
  if(MainID == "invoice-content2")
    Title = this.productName + " (" + this.mfgPart + ") Team";
  this.printService.Print(Content,Title,SearchID,HideLastColumn);
}

SelectProduct(row)
{
  sessionStorage.ReportProductGUID = row.productGuid;
  location.reload();
}

count1()
{
  if(this.attributes != undefined && this.attributes != null)
  {
    var temp = this.attributes;
    if(sessionStorage.customGroup != "")
      temp = temp.filter(x=> x.customGroup.toLowerCase().startsWith(sessionStorage.customGroup.toLowerCase()));
    if(sessionStorage.fieldName != "")
      temp = temp.filter(x=> x.fieldName.toLowerCase().startsWith(sessionStorage.fieldName.toLowerCase()));
    if(sessionStorage.valueName != "")
      temp = temp.filter(x=> x.valueName.toLowerCase().startsWith(sessionStorage.valueName.toLowerCase()));
    if(sessionStorage.isChangeMangement != "")
      temp = temp.filter(x=> x.isChangeMangement.toLowerCase().startsWith(sessionStorage.isChangeMangement.toLowerCase()));
    this.AttrCount = temp.length;
  }
  else
    this.AttrCount = 0;
}
count2()
{
  if(this.teams != undefined && this.teams != null)
  {
    var temp = this.teams;
    if(sessionStorage.phaseName != "")
      temp = temp.filter(x=> x.phaseName.toLowerCase().startsWith(sessionStorage.phaseName.toLowerCase()));
    if(sessionStorage.typeDisplay != "")
      temp = temp.filter(x=> x.typeDisplay.toLowerCase().startsWith(sessionStorage.typeDisplay.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.roleName != "")
      temp = temp.filter(x=> x.roleName.toLowerCase().startsWith(sessionStorage.roleName.toLowerCase()));
    if(sessionStorage.userName != "")
      temp = temp.filter(x=> x.userName.toLowerCase().startsWith(sessionStorage.userName.toLowerCase()));
    this.TeamCount = temp.length;
  }
  else
    this.TeamCount = 0;
}
Product(row)
{
  this.showLoading = true;
  this.attributes = [];
  this.teams = [];
  this.attdisplay = false;
  this.teamdisplay = false;
  this.productName="";
  this.dieCode="";
  this.productOwner="";
  this.productline="";
  this.mfgPart="";
  this.productStatus="";
  this.currentPhase="";
  this.projectDescription="";
  this.currentphasestatus="";
  let ok = false;
  if (this.products.length > 0)
  {
    this.products.forEach(element => {
      if(row.length > 0)
      {
        if(element.productGuid != row[0].productGuid)
          element.select = false;
        else
        {
          element.select = true;
          ok = true;
        }
      }
    });
  }
  if(ok)
  {
    var att = [];
    att.push({Seq:1,ProductGuid:row[0].productGuid,ProductName:row[0].productName,ProductOwner:row[0].productOwner});
    this._facadeService.GetProductsAttributes(att).subscribe(
      (data) => {
        this.singleproducts = data.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    
    this.attdisplay = true;
    this.teamdisplay = true;
    this.productaccordian = false;
    this.productName=row[0].productName;
    this.dieCode=row[0].dieCode;
    this.productOwner=row[0].productOwner;
    this.productline=row[0].productLine;
    this.mfgPart=row[0].mfgPart;
    this.productStatus=row[0].productStatus;
    this.currentPhase=row[0].phase;
    this.projectDescription=row[0].projectDescription;
    this.currentphasestatus=row[0].phaseStatus;

    this._ManageCustomFieldsService.GetFieldsByProductID(row[0].productGuid).subscribe(
      (data) => {
          this.attributes = data.returnObject;
          this.count1();
      },
      (error) => {  console.log(error); this.showLoading = false;  }
    );

    this._facadeService.GetReportTeam(row[0].productGuid).subscribe(
      (data) => {
          this.teams = data.returnObject; 
          this.count2();
          this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  }
    );
  }
}

}
