<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12" style="float: left">
                  <h4 style="margin-top: 5px">Manage Approvers</h4>
                </div>
            </div>
            <hr />
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:1000px">
                <div class="p-col-12 p-md-6">
                    <!-- <label for="Product"> Project<span style="color: red">*</span></label>
                    <p-multiSelect [options]="Product" [(ngModel)]="selectedProduct"
                    required="true" placeholder="Select Product" optionLabel="productName"
                    requiredMessage="The input must not be empty" (onChange)="productChange($event.value)"
                    ></p-multiSelect>
                    <small class="p-invalid">{{ productError }}</small>  -->
                    <label>Project<span style="color: red">*</span></label>
                <p-autoComplete [(ngModel)]="selectedProduct" [suggestions]="filteredProdType" placeholder="Select Product" 
                (completeMethod)="filterproductListChange($event)"   field="productName" [dropdown]="true"  (onSelect)="productChange()">
                <ng-template>
                  <div>
                    <div>{{ filteredProdType.productName }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <small class="p-invalid">{{ productError }}</small>  
                </div>
                <div class="p-col-12 p-md-6">
                    <label> Phase<span style="color: red">*</span></label>
                    <p-dropdown [options]="Phase" [(ngModel)]="selectedPhase"
                        required="true" placeholder="Select Phase" optionLabel="phaseName"
                        requiredMessage="The input must not be empty" (onChange)="phaseChange($event.value)"
                    ></p-dropdown>
                    <small class="p-invalid">{{ phaseError }}</small> 
                </div>
                <br>
                <div class="p-col-12 p-md-6">
                    <label> Type<span style="color: red">*</span></label>
                    <p-dropdown [options]="TeamType" [(ngModel)]="selectedType"
                        required="true" placeholder="Select Type" optionLabel="name"
                        requiredMessage="The input must not be empty" (onChange)="typeChange($event.value)"
                    ></p-dropdown>
                    <small class="p-invalid">{{ typeError }}</small> 
                </div>
                <div class="p-col-12 p-md-6">
                    <label for="Stage_Step"> Stage / Step</label>
                    <p-dropdown [options]="Stage" [(ngModel)]="selectedStage"
                      required="true" placeholder="Select Stage / Step" optionLabel="listName"
                      requiredMessage="The input must not be empty" (onChange)="listChange($event.value)"
                    ></p-dropdown>
                    <small class="p-invalid">{{ stageError }}</small> 
                </div>
                <div class="p-col-12 p-md-10">
                    <span style="float: right; margin-top: 5px;">
                    <table><tr><td style="background-color:tan; width: 25px;"></td><td>Edited&nbsp;&nbsp;</td>
                        <td style="background-color: aquamarine; width: 25px;"></td><td>Added&nbsp;&nbsp;</td>
                        <td style="background-color: lightcoral;width: 25px;"></td><td>Deleted</td></tr></table></span>
                </div>
                <div class="p-col-12 p-md-2">
                    <span class="p-input-icon-left" style="float:right; margin-right:5px;">
                        <button [disabled]="clicked || ViewOnly" (click)="clicked = true;" pButton type="button" icon="pi pi-plus"
                        class="ui-button-info" style="float: right; margin-bottom:10px;" [label]="'Add New'" pAddRow [table]="dt" [newRow]="newRow()" 
                        ></button>
                    </span>
                </div>
                <div class="p-col-12 p-md-12" style="margin-top: -37px;">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 20%;">{{TeamCount}}</h5>
                        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
                            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('trSearch')" 
                                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                                style="float: right; width:25px; height:25px;"></button>
                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float: right; margin-left:15px; width:25px; height:25px;" (click)="exportExcel()"></button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:975px;">
                <div class="invoice invoice-header" id="invoice-content">
                <p-table #dt [value]="docTypes" id="excel-table" styleClass="p-datatable-striped" [columns]="cols" sortMode="multiple" class="table-align" editMode="row" 
                    [filterDelay]="0" selectionMode="multiple" dataKey="datakey">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="text-align: center">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th style="text-align: center; width:100px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicplaceholder"
                                (input)="test($event.target.value,col.field,dt)" 
                                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="rowData" [style.background]="rowData.isEdit == 'Y' ? 'aquamarine' : rowData.isEdit == 'D' ? 'lightcoral' : rowData.isEdit == 'E' ? 'tan' : ''">
                            <td style="text-align:left; padding-left:15px;">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown inputId="Department" [options]="departmentsData"
                                            [(ngModel)]="selectedDept" placeholder="Select" optionLabel="departmentName"
                                            required="true" (onChange)="getRoleList('','')"
                                            requiredMessage="The input must not be empty">
                                        </p-dropdown>
                                        <small class="p-invalid">{{ departmentNameError }}</small>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{rowData.departmentName}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="text-align:left; padding-left:15px;">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown inputId="fromRole" [options]="RolesData" [(ngModel)]="selectedRole"
                                            placeholder="Select" optionLabel="roleName" required="true"
                                            (onChange)="getUserList('')" requiredMessage="The input must not be empty">
                                        </p-dropdown>
                                        <small class="p-invalid">{{ roleNameError }}</small> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{rowData.roleName}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="text-align:left; padding-left:15px;">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown inputId="fromUser" [options]="UsersData" [(ngModel)]="selectedUser"
                                            placeholder="Select" optionLabel="firstName" required="true" (onChange)="onRowEditSave(rowData)"
                                            requiredMessage="The input must not be empty">
                                        </p-dropdown>
                                        <small class="p-invalid">{{ userNameError }}</small>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{rowData.userName}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="text-align:center">
                                <button *ngIf="!editing && rowData.isEdit != 'D'" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" 
                                (click)="onRowEditInit1(rowData)" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                                <button *ngIf="!editing && rowData.isEdit != 'D'" pButton type="button" icon="pi pi-minus"
                                    class="ui-button-info" (click)="onRowEditInit(rowData)" style="height:25px; width:25px;"></button>
                                <button *ngIf="!editing && rowData.isEdit == 'D'" pButton type="button" icon="pi pi-undo"
                                    class="ui-button-info" (click)="UndoDelete(rowData)" style="height:25px; width:25px;"></button>
                                <button *ngIf="editing" pButton type="button" pCancelEditableRow
                                    icon="pi pi-times-circle" class="ui-button-danger"
                                    (click)="onRowEditCancel(rowData, ri)" style="height:25px; width:25px;"></button>
                            </td>
                        </tr>
                    </ng-template>
				    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                
                </p-table>
                </div>
                <br>
                <div class="p-col-12" style="float: right">
                    <div class="card-header">
                        <div class="p-field p-col-12 p-md-8">&nbsp;</div>
                        <div class="p-field p-col-12 p-md-4"  style="float: right;">
                            <p-checkbox [disabled]="ViewOnly" binary="isChange" [(ngModel)]="isChange" [value]="isChange" ></p-checkbox>&nbsp;&nbsp;Change for Future Phases
                        </div>
                    </div>
                    <div class="card-header">
                        <div class="p-field p-col-12 p-md-8">&nbsp;</div>
                        <div class="p-field p-col-12 p-md-4"  style="float: right;margin-top: -30px;">
                            <button pButton pRipple [disabled]="ViewOnly" type="button" icon="pi pi-check" iconPos="left" label="Save"
                                class="p-button-raised p-button-success p-mr-2 p-mb-2 width100" (click)="savecanceldata()"></button>&nbsp;&nbsp;&nbsp;
                            <button pButton pRipple [disabled]="ViewOnly" type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                            class="p-button-raised p-button-secondary p-mr-2 p-mb-2 width100" (click)="resetCtrls()"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>


<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>