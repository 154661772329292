<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12" style="float: left">
                  <h4 style="margin-top: 5px">Manage Task Required</h4>
                </div>
            </div>
            <hr />
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:1200px">
                <div class="p-col-12 p-md-6">
                    <!-- <label for="Product"> Project<span style="color: red">*</span></label> -->
                    <!-- <p-multiSelect [options]="Product" [(ngModel)]="selectedProduct"
                      required="true" placeholder="Select Product" optionLabel="productName"
                      requiredMessage="The input must not be empty" (onChange)="productChange($event.value)"
                    ></p-multiSelect>
                    <small class="p-invalid">{{ productError }}</small>  -->
                    <label>Project<span style="color: red">*</span></label>
                    <p-autoComplete [(ngModel)]="selectedProduct" [suggestions]="filteredProdType" placeholder="Select Product" 
                    (completeMethod)="filterproductListChange($event)"   field="productName" [dropdown]="true"  (onSelect)="productChange()">
                    <ng-template>
                      <div>
                        <div>{{ filteredProdType.productName }}</div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                  <small class="p-invalid">{{ productError }}</small> 
                </div>
                <div class="p-col-12 p-md-6">
                    <label for="Phase"> Phase<span style="color: red">*</span></label>
                    <p-dropdown [options]="Phase" [(ngModel)]="selectedPhase"
                        required="true" placeholder="Select Phase" optionLabel="phaseName"
                        requiredMessage="The input must not be empty" (onChange)="phaseChange($event.value)"
                    ></p-dropdown>
                    <small class="p-invalid">{{ phaseError }}</small> 
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 20%;">Tasks - {{DocsCount}}</h5>
                        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
                            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('trSearch')" 
                                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                                style="float: right; width:25px; height:25px;"></button>
                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float: right; margin-left:15px; width:25px; height:25px;" (click)="exportExcel()"></button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:1175px;">
                <div class="invoice invoice-header" id="invoice-content" style="width: 100%;">
                    <p-table #dtDocs [value]="CurrentRequiredDocuments" id="excel-table" [columns]="cols" sortMode="multiple" styleClass="p-datatable-striped" [filterDelay]="0"
                    selectionMode="multiple" [(selection)]="selectedDocs" dataKey="reqDocumentID" [autoLayout]="true" [scrollable]="true" scrollHeight="500px">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th style="text-align: center" *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
                                [ngClass]="{'w': i==0, 'w1': i ==1,'width120':i == 3 || i ==4,'width100': i == 2}" >{{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th> 
                            </tr>
                            <tr id="trSearch">
                                <th  *ngFor="let doc of documents" [ngSwitch]="doc.field" style="text-align: center">
                                    <input pInputText type="text" (input)="test($event.target.value,col.field,dtDocs)"  placeholder="Search" style="margin: -10px 0 -10px 0; width:95%; height:25px;"/>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="p-selectable-row" [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' " >
                                <td style="text-align:left; padding-left:15px;width: 20%;"><span>{{rowData.departmentName}}</span></td>
                                <td style="text-align:left; padding-left:15px;width: 40%;"><span>{{rowData.documentName}}</span></td>
                                <td style="text-align: center;width: 100px;">
                                    <p-checkbox [disabled]="rowData.isCompletedDisplay" binary="rowData.isRequiredDisplay" [(ngModel)]="rowData.isRequiredDisplay"
                                     [value]="rowData.isRequiredDisplay" (click)="DocumentRequired(rowData.reqDocumentID,$event)"></p-checkbox>
                                </td>
                                <!-- <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td> -->
                                <td style="text-align:center;width: 140px;"><span>{{rowData.documentLabels }}</span></td>
                                <td style="text-align:center;width: 140px;">
                                    <ul *ngFor="let temp of rowData.templates" class="tempul">
                                        <li class="templi">
                                            <span><a href="javascript:;" (click)="DMSToken(temp)">{{temp.templateName}} </a> </span>
                                            <!-- <span *ngIf="temp.isDocAccess!=true">{{temp.templateName}}</span> *ngIf="temp.isDocAccess==true" -->
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr><td colspan="5">No records found...</td></tr>
                        </ng-template>
                    </p-table>
                </div>
                <br>
                <div class="p-col-12" style="float: right">
                <div class="card-header">
                    <div class="p-field p-col-12 p-md-8">&nbsp;</div>
                    <div class="p-field p-col-12 p-md-4"  style="float: right;">
                    <button pButton pRipple [disabled]="ViewOnly" type="button" icon="pi pi-check" iconPos="left" label="Save"
                        class="p-button-raised p-button-success p-mr-2 p-mb-2 width100" (click)="savedata()"></button>&nbsp;&nbsp;&nbsp;
                    <button pButton pRipple [disabled]="ViewOnly" type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                    class="p-button-raised p-button-secondary p-mr-2 p-mb-2 width100" (click)="resetCtrls()"></button>
                    </div>
                </div></div>
            </div>
        </div>
    </div>
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>


<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>