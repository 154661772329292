import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardProducts } from '../../model/DashboardData';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../services/printcommon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { Button } from 'primeng/button';

@Component({
  selector: 'app-myproducts',
  templateUrl: './myproducts.component.html',
  styleUrls: ['./myproducts.component.scss']
})
export class MyproductsComponent implements OnInit {
  isExpanded: boolean = false;
  expandedRows = {};
  userId = sessionStorage.getItem('userGuid');
  loading: "loading";
  showLoading: boolean;
  btnindex1: any = "p-button-primary";
  btnindex2: any = "p-button-primary";
  btnindex3: any = "p-button-primary";
  btnindex4: any = "p-button-primary";
  btnindex5: any = "p-button-primary";
  btnindex6: any = "p-button-primary";
  btnindex7: any = "p-button-primary";
  btnindex8: any = "p-button-primary";
  btnindex9: any = "p-button-primary";
  cols: any[];
  upcols: any[];
  products = [];
  productsD = [];//DashboardProducts[];
  selectedProducts = [];
  @ViewChild('dtactive') table: Table;
  savelabel: any = "Expand All";

  index: number = 0;
  Count0: number = 0;
  Count: number = 0;
  Count2: number = 0;
  Count3: number = 0;
  Count4: number = 0;
  Count5: number = 0;
  Count6: number = 0;
  Count7: number = 0;
  Count8: number = 0;
  CurrentTab: string = "";
  CurrentTab1: number;
  ProductCountFilter: number =0;
  selectedfilter ="startsWith";
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintcommonService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.cols = [
      { field: 'productName', header: 'Product' },
      { field: 'dieCode', header: 'Die Code' },
      { field: 'mfgPart', header: 'Mfg Part' },
      { field: 'projectDescription', header: 'Project Description' },
      { field: 'productLine', header: 'Product Line' },
      { field: 'foundry', header: 'Foundry' },
      { field: 'subcon', header: 'Subcon' },
      { field: 'productOwner', header: 'Product Engineer' },
      { field: 'productStatus', header: 'Product Status' },
      { field: 'phase', header: 'Phase' },
      { field: 'phaseStatus', header: 'Phase Status' },
      { field: 'phaseStartDate', header: 'Phase Start Date' },
    ];
    sessionStorage.productName = "";
    sessionStorage.dieCode = "";
    sessionStorage.mfgPart = "";
    sessionStorage.projectDescription = "";
    sessionStorage.productLine = "";
    sessionStorage.foundry = "";
    sessionStorage.subcon = "";
    sessionStorage.productOwner = "";
    sessionStorage.phase = "";
    sessionStorage.phaseStartDate = "";
    this.index = 0;
      if(this.route.snapshot.paramMap.get('index')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('index');
        if(ii != '0' && ii != '')
          this.index = Number(ii);
      }

      if(this.index==0)
      {
        this.RefreshTable('Active','Active',1);
      }
      else if(this.index==1)
      {
        this.RefreshTable('PendingApproval','Pending Approval',2);
      }
      else if(this.index==2)
      {
        this.RefreshTable('PendingClarification','Seek Clarification',9);
      }
      else if(this.index==3)
      {
        this.RefreshTable('Hold','On Hold',4);
      }
      // else if(this.index==3)
      // {
      //   this.RefreshTable('ForCancel','To Discontinue',3);
      // }
      // else if(this.index==5)
      // {
      //   this.RefreshTable('Completed','Completed',5);
      // }
      // else if(this.index==6)
      // {
      //   this.RefreshTable('EOL','EOL / OBS',6);
      // }
      // else if(this.index==7)
      // {
      //   this.RefreshTable('Cancel','Discontinued',7);
      // }
      // else if(this.index==8)
      // {
      //   this.RefreshTable('Upcoming','Upcoming',8);
      // }
    console.log(this.index);
    //this.RefreshTable("active", "Active");
    this.showLoading = false;
  } 

  test(val, field, dt) {
    if (field == "productName")
      sessionStorage.productName = val;
    else if (field == "dieCode")
      sessionStorage.dieCode = val;
    else if (field == "mfgPart")
      sessionStorage.mfgPart = val;
    else if (field == "projectDescription")
      sessionStorage.projectDescription = val;
    else if (field == "phase")
      sessionStorage.phase = val;
    else if (field == "phaseStatus")
      sessionStorage.phaseStatus = val;
    else if (field == "productLine")
      sessionStorage.productLine = val;
    else if (field == "foundry")
      sessionStorage.foundry = val;
    else if (field == "subcon")
      sessionStorage.subcon = val;
    else if (field == "productOwner")
      sessionStorage.productOwner = val;
    else if (field == "productStatus")
      sessionStorage.productStatus = val;
    else if (field == "phaseStartDate")
      sessionStorage.phaseStartDate = val;

    if(this.selectedfilter=="startsWith")
      dt.filter(val, field, 'startsWith');
    else
      dt.filter(val, field, 'contains');

      //dt = dt.filter(val, field, 'startsWith');
    //this.Count1(field, val);
    //this.expandAll1();
  }
  
  onFilter(event, dt) {  
    if(event.filteredValue.length == this.products.length)
    {
      this.ProductCountFilter = 0;
    }
    else
    {
      this.ProductCountFilter = event.filteredValue.length;
    }
 }

  Count1(p, p1) {
    if (this.productsD != undefined && this.productsD != null) {
      var temp = this.productsD;
      if (this.productsD.length > 0) {
        if (p == "productName")
          temp = temp.filter(x => x.productName.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "dieCode")
          temp = temp.filter(x => x.dieCode.toLowerCase().includes(p1.toLowerCase()));
        if (p == "mfgPart")
          temp = temp.filter(x => x.mfgPart.toLowerCase().includes(p1.toLowerCase()));
        if (p == "projectDescription")
          temp = temp.filter(x => x.projectDescription.toLowerCase().includes(p1.toLowerCase()));
        if (p == "phase")
          temp = temp.filter(x => x.phase.toLowerCase().includes(p1.toLowerCase()));
        if (p == "phaseStatus")
          temp = temp.filter(x => x.phaseStatus.toLowerCase().includes(p1.toLowerCase()));
        if (p == "productLine")
          temp = temp.filter(x => x.productLine.toLowerCase().includes(p1.toLowerCase()));
        if (p == "foundry")
          temp = temp.filter(x => x.foundry.toLowerCase().includes(p1.toLowerCase()));
        if (p == "subcon")
          temp = temp.filter(x => x.subcon.toLowerCase().includes(p1.toLowerCase()));
        if (p == "productOwner")
          temp = temp.filter(x => x.productOwner.toLowerCase().includes(p1.toLowerCase()));
        if (p == "productStatus")
          temp = temp.filter(x => x.productStatus.toLowerCase().includes(p1.toLowerCase()));
        if (p == "phaseStartDate")
          temp = temp.filter(x => x.phaseStartDate.toLowerCase().includes(p1.toLowerCase()));
        this.CurrentTab1 = temp.length;
      }
      else
        this.CurrentTab1 = 0;
    }
    else
      this.CurrentTab1 = 0;
  }

  RefreshTable(p: any, p1: any,i:any) {
    if(i==1)
    {
      this.btnindex1="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==2)
    {
      this.btnindex2="p-button-secondary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==3)
    {
      this.btnindex3="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==4)
    {
      this.btnindex4="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";   
      this.btnindex9="p-button-primary p-ripple p-button p-component";
     }
    else if(i==5)
    {
      this.btnindex5="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==6)
    {
      this.btnindex6="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==7)
    {
      this.btnindex7="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==8)
    {
      this.btnindex8="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
      this.btnindex9="p-button-primary p-ripple p-button p-component";
    }
    else if(i==9)
    {
      this.btnindex9="p-button-secondary p-ripple p-button p-component";
      this.btnindex2="p-button-primary p-ripple p-button p-component";
      this.btnindex3="p-button-primary p-ripple p-button p-component";
      this.btnindex4="p-button-primary p-ripple p-button p-component";
      this.btnindex5="p-button-primary p-ripple p-button p-component";
      this.btnindex6="p-button-primary p-ripple p-button p-component";
      this.btnindex7="p-button-primary p-ripple p-button p-component";
      this.btnindex8="p-button-primary p-ripple p-button p-component";
      this.btnindex1="p-button-primary p-ripple p-button p-component";
    }
    this.collapseAll();
    this.getproducts(p, p1);
  }

  // getclareproducts() {
  //   this._facadeService.ClarificationProducts(this.userId,true).subscribe(
  //     (data) => {
  //        this.clareproducts = data.returnObject; 
  //        this.Count2 = this.MAP(this.clareproducts,sessionStorage.productName2, sessionStorage.dieCode2, sessionStorage.mfgPart2, sessionStorage.projectDescription2, 
  //         sessionStorage.productLine2, sessionStorage.productOwner2, sessionStorage.phase2, "N", "");
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  getproducts(Type, p1) {
    this.showLoading = true;
    this._facadeService.UserProducts(this.userId, Type, true, "Y").subscribe(
      (data) => {

        this.products = data.returnObject;
        //this.Count();
        this.showLoading = false;
      },
      (error) => { console.log(error); this.showLoading = false; }
    );
    this.showLoading = true;
    this._facadeService.UserProducts(this.userId, Type, true, "H").subscribe(
      (data) => {
        this.CurrentTab = p1;
        this.CurrentTab1 = data.returnObject.length;
        this.productsD = data.returnObject;
        this.showLoading = false;
        //this.Count();
        //this.getChartData();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  expandAll() {
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) {
      var p = collection[i];
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") {
        if (val1 == "pi pi-chevron-right") {
          p.click();
        }
      }
      else {
        if (val1 == "pi pi-chevron-down") {
          p.click();
        }
      }
    }


    this.showLoading = false;
  }

  expandAll1() {
    this.savelabel = "Expand All";
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) {
      var p = collection[i];
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") {
        if (val1 == "pi pi-chevron-right") {
          p.click();
        }
      }
      else {
        if (val1 == "pi pi-chevron-down") {
          p.click();
        }
      }
    }


    this.showLoading = false;
  }

  collapseAll() {
    this.savelabel = "Collapse All";
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) {
      var p = collection[i];
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") {
        if (val1 == "pi pi-chevron-right") {
          p.click();
        }
      }
      else {
        if (val1 == "pi pi-chevron-down") {
          p.click();
        }
      }
    }


    this.showLoading = false;
  }

  onRowExpand() {
    console.log("row expanded", Object.keys(this.expandedRows).length);
    this.isExpanded = true;
  }

  onRowCollapse() {
    console.log("row collapsed", Object.keys(this.expandedRows).length);
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }


  download() {
    //let element = document.getElementById('excel-table'); 
    var dte = [];
    this.productsD.map(d => (
      {
        //ProductGroup:d.productGroupName,
        "Product": d.productName,
        "Die Code": d.dieCode,
        "Mfg Part": d.mfgPart,
        "Project Description": d.projectDescription,
        "Product Line": d.productLine,
        "Foundry": d.foundry,
        "Subcon": d.subcon,
        "Product Engineer": d.productOwner,
        "Product Status": d.productStatus,
        "Phase": d.phase,
        "Phase Status": d.phaseStatus,
        "Phase Start Date": d.phaseStartDate
      }
    )).forEach((d: any) => {
      dte.push(d);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
    //this.delete_row(wb.Sheets.Sheet1, 1);
    /* save to file */
    XLSX.writeFile(wb, "MyProducts - Products.xlsx");
  }

  delete_row(ws, row_index, val) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    if (val == "excel-table8")
      delete (ws['I1'])
    else
      delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

  Details(ProductID, PhaseID, Type, TransactionID, ProductLine) {
    sessionStorage.ProductID = ProductID;
    sessionStorage.PhaseID = PhaseID;
    sessionStorage.DisplayType = Type;
    sessionStorage.TransactionID = TransactionID;
    sessionStorage.ProductLine = ProductLine;
    sessionStorage.FromWhere = "mprod";
    this.router.navigate(['/phasedetails']);
  }
  print(MainID, SearchID, Title) {
    var Content = document.getElementById(MainID).innerHTML;
    this.printService.Print(Content, Title, SearchID, true);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'startDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);

        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });

    event.data.forEach(e => {
      e.startDate = this.datePipe.transform(e.startDate, 'MM/dd/yyyy');
    });
  }
}
