import { Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { Table } from 'primeng/table';
import { TreeNode } from 'primeng/api';
import { PrintcommonService } from '../../../services/printcommon.service';
import { Router } from '@angular/router';
import { DashboardProducts } from "../../../model/DashboardData";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import { Phases, SearchModel } from '../../../model/PhaseDetails';
//import { SearchModel } from '../../../model/PhaseDetails';
import { SelectItem, SortEvent } from 'primeng/api';
import * as XLSX from 'xlsx';
import { Button } from 'primeng/button';
import { Conditional } from '@angular/compiler';

@Component({
  selector: 'app-screport',
  templateUrl: './screport.component.html',
  styleUrls: ['./screport.component.scss']
})
export class ScreportComponent  implements OnInit {
  isExpanded: boolean = false;
  expandedRows = {};
  loading: "loading";
  showLoading: boolean;
  searchModel: SearchModel;
  searchModelD: SearchModel;
  cols: any[];
  savelabel: any = "Expand All";
  products = [];//DashboardProducts[];
  productsD = [];//DashboardProducts[];
  selectedProducts: DashboardProducts[];
  @ViewChild('dt') table: Table;
  ProductCount: number = 0;
  ProductCountFilter: number = 0;
  @ViewChild('btncollapse') btncollapse: Button;
  status: SelectItem[];
  line: any[];
  l: string[];
  ProjectID: any = "";
  selectedLine: any[] = [];
  selectedStatus: any[] = [];
  userId = sessionStorage.getItem('userGuid');
  labels: string[] = [];
  data: number[] = [];
  isChartDataAvailable: boolean = false;
  phaseChart: any;
  nobtnlabel: any = "pi pi-chevron-right";
  nobtnclass: any = "p-button-text p-button-rounded p-button-plain";
  options: any;
  selectedfilter ="startsWith";
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintcommonService,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit() {
    this.showLoading = true;

    this.cols = [
      { field: 'productName', header: 'Product' },
      { field: 'dieCode', header: 'Die Code' },
      { field: 'mfgPart', header: 'Mfg Part' },
      { field: 'projectDescription', header: 'Project Description' },
      { field: 'productLine', header: 'Product Line' },
      { field: 'foundry', header: 'Foundry' },
      { field: 'subcon', header: 'Subcon' },
      { field: 'productOwner', header: 'Product Engineer' },
      { field: 'productStatus', header: 'Product Status' },
      { field: 'phase', header: 'Phase' },
      { field: 'phaseStatus', header: 'Phase Status' },
      { field: 'phaseStartDate', header: 'Phase Start Date' },
    ];
    this.status = [
      { label: 'New', value: 'New' },
      { label: 'In-Process', value: 'In-Process' },
      { label: 'Completed', value: 'Completed' },
      { label: 'EOL', value: 'EOL' },
      { label: 'OBS', value: 'OBS' },
      { label: 'Waiting for Discontinuation', value: 'Waiting for Discontinuation' },
      { label: 'Seek Clarification for Discontinuation', value: 'Seek Clarification for Discontinuation' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Discontinued', value: 'Discontinued' },
    ];

    let ok = false;
    if (sessionStorage.getItem('sumstatus') != null && sessionStorage.getItem('sumstatus') != undefined) {
      var projectstats = [];
      projectstats = sessionStorage.getItem('sumstatus').split(',');
      projectstats.forEach((element) => {
        this.selectedStatus.push(element);
      });
      ok = true;
    }
    if (!ok) {
      this.selectedStatus.push('New');
      this.selectedStatus.push('In-Process');
      // this.selectedStatus.push('Completed');
      this.selectedStatus.push('Waiting for Discontinuation');
      this.selectedStatus.push('Seek Clarification for Discontinuation');
      sessionStorage.sumstatus = this.selectedStatus;
    }

    sessionStorage.productName = "";
    sessionStorage.dieCode = "";
    sessionStorage.fgPart = "";
    sessionStorage.mfgPart = "";
    sessionStorage.projectDescription = "";    
    sessionStorage.phase = "";
    sessionStorage.phaseStatus = "";
    sessionStorage.productLine = "";
    sessionStorage.foundry = "";
    sessionStorage.subcon = "";
    sessionStorage.productOwner = "";
    sessionStorage.productStatus = "";
    sessionStorage.phaseStartDate = "";

    this.GetProductLne();
  }

  GetProductLne() {
    this.line =[{"id": "A7FCF5E5-F1EF-4C78-99E4-9F90C9955669","name": "SC"}]
    let ok = false;
        if (!ok) {
          this.line.forEach((ln) => {
            this.selectedLine.push(ln);
          });
          var lm = [];
          this.selectedLine.forEach((element) => {
            lm.push(element.id);
          });
        }
        this.getproducts(this.selectedLine, 'true');
        console.log("ProductLine data ::" + JSON.stringify(this.line));    
  }

  Count() {
    if (this.productsD != undefined && this.productsD != null) {
      var temp = this.productsD;
      this.ProductCount = temp.length;
    }
    else
      this.ProductCount = 0;
  }

  Count1(p,p1) {
    if (this.products != undefined && this.products != null) {
      var temp = this.products;
      if (this.products.length > 0) {
        if (p == "productName")
          temp = temp.filter(x => x.productName.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "dieCode")
          temp = temp.filter(x => x.dieCode.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "fgPart")
          temp = temp.filter(x => x.fgPart.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "mfgPart")
          temp = temp.filter(x => x.mfgPart.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "projectDescription")
          temp = temp.filter(x => x.projectDescription.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "phase")
          temp = temp.filter(x => x.phase.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "phaseStatus")
          temp = temp.filter(x => x.phaseStatus.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "productLine")
          temp = temp.filter(x => x.productLine.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "foundry")
          temp = temp.filter(x => x.foundry.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "subcon")
          temp = temp.filter(x => x.subcon.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "productOwner")
          temp = temp.filter(x => x.productOwner.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "productStatus")
          temp = temp.filter(x => x.productStatus.toLowerCase().startsWith(p1.toLowerCase()));
        if (p == "phaseStartDate")
          temp = temp.filter(x => x.phaseStartDate.toLowerCase().startsWith(p1.toLowerCase()));
        this.ProductCount = temp.length;
      }      
      else
        this.ProductCount = 0;
    }
    else
      this.ProductCount = 0;
  }

 
  // Count(){
  //   if (this.productsD != undefined && this.productsD != null) {
  //     this.ProductCount=this.productsD.length;
  //   }
  //   else{
  //     this.ProductCount=0;
  //   }
  // }
  test(val, field, dt) {
    if (field == "productName")
      sessionStorage.productName = val;
    else if (field == "dieCode")
      sessionStorage.dieCode = val;
    else if (field == "fgPart")
      sessionStorage.fgPart = val;
    else if (field == "mfgPart")
      sessionStorage.mfgPart = val;
    else if (field == "projectDescription")
      sessionStorage.projectDescription = val;
    else if (field == "phase")
      sessionStorage.phase = val;
    else if (field == "phaseStatus")
      sessionStorage.phaseStatus = val;
    else if (field == "productLine")
      sessionStorage.productLine = val;
    else if (field == "foundry")
      sessionStorage.foundry = val;
    else if (field == "subcon")
      sessionStorage.subcon = val;
    else if (field == "productOwner")
      sessionStorage.productOwner = val;
    else if (field == "productStatus")
      sessionStorage.productStatus = val;
    else if (field == "phaseStartDate")
      sessionStorage.phaseStartDate = val;
    //dt = dt.filter(val, field, 'contains');
    if(this.selectedfilter=="startsWith")
     dt.filter(val, field, 'startsWith');
    else
      dt.filter(val, field, 'contains');
    //dt = dt.filter(val, field, 'startsWith');
  }

  onFilter(event, dt) {  
    if(event.filteredValue.length == this.products.length)
    {
      this.ProductCountFilter = 0;
    }
    else
    {
      this.ProductCountFilter = event.filteredValue.length;
    }
 }

  getproducts(l, Type) {
    this.showLoading = true;
    var lm = [];
    if (Type == 'false') {
      this.selectedLine.forEach((element) => {
        lm.push(element.id);
      });
      sessionStorage.sline = lm;
      sessionStorage.sumstatus = this.selectedStatus;
    }
    else {
      l.forEach((element) => {
        lm.push(element.id);
      });
    }

    this.searchModel = {
      productLineID: lm,
      productStatus: this.selectedStatus,
      projectID: "",
      download: "Y"
    }

    this.searchModelD = {
      productLineID: lm,
      productStatus: this.selectedStatus,
      projectID: "",
      download: ""
    }


    this._facadeService.ReportProducts(this.searchModel).subscribe(
      (data) => {
        this.products = data.returnObject;
        //this.Count();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );

    this._facadeService.ReportProductsD(this.searchModelD).subscribe(
      (data) => {
        this.productsD = data.returnObject;
        this.Count();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  // expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
  expandAll() {
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) 
    { 
      var p = collection[i]; 
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") 
      {
        if(val1=="pi pi-chevron-right")
        {
          p.click();
        }
      }
      else 
      {
        if(val1=="pi pi-chevron-down")
        {
          p.click();
        }
      }
    }    

    
    this.showLoading = false;
  }

  expandAll1() {
    this.savelabel = "Expand All";
    this.showLoading = true;
    var pp = this.savelabel;
    if (pp == "Expand All") {
      this.savelabel = "Collapse All";
      console.log(this.savelabel);
    }
    else {
      this.savelabel = "Expand All";
      console.log(this.savelabel);
    }
    const collection = document.getElementsByName('btn123');
    for (let i = 0; i < collection.length; i++) 
    { 
      var p = collection[i]; 
      var val1 = p.getAttribute("ng-reflect-icon");
      console.log(val1);
      if (pp == "Expand All") 
      {
        if(val1=="pi pi-chevron-right")
        {
          p.click();
        }
      }
      else 
      {
        if(val1=="pi pi-chevron-down")
        {
          p.click();
        }
      }
    }    

    
    this.showLoading = false;
  }
  
  onPage(event: any) {
    this.isExpanded = true;
    this.expandedRows = {};
  }

  onRowExpand() {
    console.log("row expanded", Object.keys(this.expandedRows).length);
    this.isExpanded = true;
  }

  onRowCollapse() {
    console.log("row collapsed", Object.keys(this.expandedRows).length);
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

    download() {
      //let element = document.getElementById('excel-table'); 
      var dte = [];
      this.productsD.map(d => (
        {
          //ProductGroup:d.productGroupName,
          "Product": d.productName,
          "Die Code": d.dieCode,
          "Mfg Part": d.mfgPart,
          "Project Description": d.projectDescription,
          "Product Line": d.productLine,
          "Foundry": d.foundry,
          "Subcon": d.subcon,
          "Product Engineer": d.productOwner,
          "Product Status": "Sold to Indie",
          "Phase": d.phase,
          "Phase Status": d.phaseStatus,
          "Phase Start Date": d.phaseStartDate
          }
      )).forEach((d: any) => {
        dte.push(d);
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
      //this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, "Product Summary.xlsx");
    }

    delete_row(ws, row_index) {
      let range = XLSX.utils.decode_range(ws["!ref"])
      for (var R = row_index; R < range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
          ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
          if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
            ws[this.ec(R + 1, C)].v = "";
        }
      }
      range.e.r--
      delete (ws['M1'])
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({ r: r, c: c })
    }
    Details(ProductID, PhaseID, Type) {
      sessionStorage.ProductID = ProductID;
      sessionStorage.PhaseID = PhaseID;
      sessionStorage.DisplayType = Type;
      sessionStorage.FromWhere = "summ";
      this.router.navigate(['/phasedetails']);
    }
    print(SearchID) {
      var Content = document.getElementById('invoice-content').innerHTML;
      var Title = "Product Summary";
      this.printService.Print(Content, Title, SearchID, true);
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (event.field === 'phaseStartDate') {
          // Convert the string dates to Date objects for correct sorting
          const date1 = value1 === "--" ? null : new Date(value1);
          const date2 = value2 === "--" ? null : new Date(value2);

          // Handle special case: treat "--" as greater or smaller than any date
          if (date1 === null) {
            result = date2 === null ? 0 : -1;
          } else if (date2 === null) {
            result = 1;
          } else {
            result = date1.getTime() - date2.getTime();
          }
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }

        return (event.order * result);
      });
    }
  }