import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _httputilityService: HttputilityService) {}
  DashboardCounts(UserID) {
    return this._httputilityService.Get("Dashboard/DashboardCounts?UserID=" + UserID ,"");
  }
  DashboardProductList() {
    var timeZone = sessionStorage.getItem('timeZone');
    if(timeZone == null)
    {
      this.GetUserDetails(sessionStorage.getItem('userGuid'))
      .subscribe(
         (data) => {
        var userDetails = data.returnObject;
        sessionStorage.timeZone = userDetails.timeZone;
        timeZone = userDetails.timeZone;
      });
    }
    return this._httputilityService.Get("Dashboard/GetDashboardProducts?TimeZone="+timeZone,"");
  }
  GetChart(UserID){
    return this._httputilityService.Get("Dashboard/GetChart?UserID="+UserID,"");
  }
  Timeline(Days,UserID) {
    return this._httputilityService.Get("Dashboard/GetTimeline?Days=" + Days+"&UserID="+UserID,"");
  }
  
  GetUserDetails(userGuid){
    return this._httputilityService.GetU("user/GetUserDetails?UserGUID=" + userGuid ,"");
  }

  // GetDashboardUserProducts(UserID) {
  //   var timeZone = sessionStorage.getItem('timeZone');
  //   if(timeZone == null)
  //   {
  //     this.GetUserDetails(sessionStorage.getItem('userGuid'))
  //     .subscribe(
  //        (data) => {
  //       var userDetails = data.returnObject;
  //       sessionStorage.timeZone = userDetails.timeZone;
  //       timeZone = userDetails.timeZone;
  //     });
  //   }
  //   return this._httputilityService.Get("Dashboard/GetDashboardUserProducts?UserID=" + UserID+"&TimeZone="+timeZone,"");
  // }

  GetDashboardUserProducts(UserID,Download) {
    var timeZone = sessionStorage.getItem('timeZone');
    if(timeZone == null)
    {
      this.GetUserDetails(sessionStorage.getItem('userGuid'))
      .subscribe(
         (data) => {
        var userDetails = data.returnObject;
        sessionStorage.timeZone = userDetails.timeZone;
        timeZone = userDetails.timeZone;
      });
    }
    return this._httputilityService.Get("Dashboard/GetDashboardUserProducts?UserID=" + UserID+"&Download="+Download+"&TimeZone="+timeZone,"");
  }

  // ProductList(UserID,Type,PE) {
  //   var timeZone = sessionStorage.getItem('timeZone');
  //   return this._httputilityService.Get("Dashboard/GetUserProducts?UserID=" + UserID + "&Type=" + Type+"&PE="+ PE+"&TimeZone="+timeZone,"");
  // }

  ProductList(UserID,Type,PE,Download) {
    var timeZone = sessionStorage.getItem('timeZone');
    return this._httputilityService.Get("Dashboard/GetUserProducts?UserID=" + UserID + "&Type=" + Type+"&PE="+ PE+"&Download="+Download+"&TimeZone="+timeZone,"");
  }

  ECCNProducts()
  {
    return this._httputilityService.Get("Dashboard/GetECCNProducts","");
  }

  DocsList(UserID,Type,PE,MyRole) {
    return this._httputilityService.Get("Dashboard/GetDocuments?UserID=" + UserID + "&Type="+Type+"&PE="+ PE + "&MyRole=" + MyRole,"");
  }
}
