import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import { DatePipe} from '@angular/common'
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightmenuComponent} from './app.rightmenu.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSearchComponent} from './app.search.component';
import {AppFooterComponent} from './app.footer.component';
import {BreadcrumbService} from './app.breadcrumb.service';

import {LoginComponent } from './components/login/login.component';
import { FacadeService } from './facade/facade.service';
import {MenuService} from './app.menu.service';
import {PrintService} from './services/print.service';
import {PrintcommonService} from './services/printcommon.service';

import {DashboardService} from './services/dashboard.service';
import {PhaseDetailsService} from './services/phasedetails.service';
import {TeamService} from './services/team.service';
import {ApprovalService} from './services/approval.service';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MyDocumentsComponent } from './components/mydocuments/mydocuments.component';
import { MyproductsComponent } from './components/myproducts/myproducts.component';
import { MyapprovalComponent } from './components/myapproval/myapproval.component';
import { PhasedetailsComponent } from './components/phasedetails/phasedetails.component';
import { ApprovalDetailsComponent } from './components/approval-details/approval-details.component';
import { CanceldetailsComponent } from './components/canceldetails/canceldetails.component';
import { CancelteamComponent } from './components/Admin/cancelteam/cancelteam.component';
import { AddRowDirective } from '../app/components/Admin/cancelteam/add-row.directive';
import { ManagecustomfieldsComponent } from './components/managecustomfields/managecustomfields.component';
import { ReportsComponent } from './components/AllReports/reports/reports.component';
import { ManageteamComponent } from './components/Admin/manageteam/manageteam.component';
import { ManageapproversComponent } from './components/Admin/manageapprovers/manageapprovers.component';
import { CreateproductComponent } from './components/createproduct/createproduct.component';
import { OtherproductsComponent } from './components/otherproducts/otherproducts.component';
import { OtherdocumentsComponent } from './components/otherdocuments/otherdocuments.component';
import { ProductcreationComponent } from './components/productcreation/productcreation.component';
import { ProductownerComponent } from './components/Admin/productowner/productowner.component';
import { EntrypointComponent } from './components/entrypoint/entrypoint.component';
import { ConditionalreleaseboardComponent } from './components/Admin/conditionalreleaseboard/conditionalreleaseboard.component';
// import { CreateconditionalreleaseboardComponent } from './components/createconditionalreleaseboard/createconditionalreleaseboard.component';
import { ConditionalreleasesComponent } from './components/conditionalreleases/conditionalreleases.component';
import { ManagetaskrequiredComponent } from './components/Admin/managetaskrequired/managetaskrequired.component';
import { HelpguideComponent } from './components/helpguide/helpguide.component';
import { ProductsdocumentsComponent } from './components/AllReports/productsdocuments/productsdocuments.component';
import { ProductattributesComponent } from './components/AllReports/productattributes/productattributes.component';
import { ProductidComponent } from './components/AllReports/productid/productid.component';
import { ProductcreationchildComponent } from './components/productcreationchild/productcreationchild.component';
import { CreatechildproductComponent } from './components/createchildproduct/createchildproduct.component';
import { TokenInterceptor } from "./token.interceptor";
import { PiechartreportComponent } from './components/piechartreport/piechartreport.component';
import { BnNgIdleService } from './bn-ng-idle.service';
import { DefaultteamComponent } from './components/Admin/defaultteam/defaultteam.component';
import { EccnComponent } from './components/eccn/eccn.component';
import { EccnproductsComponent } from './components/eccnproducts/eccnproducts.component';
import { ScreportComponent } from './components/AllReports/screport/screport.component';
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppRightmenuComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppSearchComponent,
        AppFooterComponent,
        LoginComponent,
        DashboardComponent,
        MyDocumentsComponent,
        MyproductsComponent,
        MyapprovalComponent,
        PhasedetailsComponent,
        ApprovalDetailsComponent,
        CanceldetailsComponent,
        CancelteamComponent,
        AddRowDirective,
        ManagecustomfieldsComponent,
        ReportsComponent,
        ManageteamComponent,
        ManageapproversComponent,
        CreateproductComponent,
        OtherproductsComponent,
        OtherdocumentsComponent,
        ProductcreationComponent,
        ProductownerComponent,
        EntrypointComponent,
        ConditionalreleaseboardComponent,
        // CreateconditionalreleaseboardComponent,
        ConditionalreleasesComponent,
        ManagetaskrequiredComponent,
        HelpguideComponent,
        ProductsdocumentsComponent,
        ProductattributesComponent,
        ProductidComponent,
        ProductcreationchildComponent,
        CreatechildproductComponent,
        PiechartreportComponent,
        DefaultteamComponent,
        EccnComponent,
        EccnproductsComponent,
        ScreportComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
         MenuService, BreadcrumbService, DashboardService,FacadeService,PrintService,PrintcommonService,
         PhaseDetailsService,TeamService,ApprovalService,DatePipe,BnNgIdleService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
