import { Variable } from '@angular/compiler/src/render3/r3_ast';

//import { Guid } from "guid-typescript";
export class PhaseBar {
    phaseID: number;
    phaseGuid: string;
    phaseName: string;
    phaseStatus: string;
    phaseSeq: number;
    imageName:string;
}

export class Product {
    productID: number;
    productGuid: string;
    spaceID: number;
    spaceName: string;
    productName: string;
    productOwnerID: string;
    productOwner: string;
    productStatus: string;
    startDate: Date;
    dueDate: Date;
    description:string;
    itemID:string;
    reason:string;
    dieCode: string;
    mfgPart: string;
    fgPart: string;
    pmProjectID:string;
    projectDescription : string;

    isSendForApproval:boolean;
    isReminder:boolean;
    isHold:boolean;
    isRelease:boolean;
    isClarify:boolean;
    isReminderECCN:boolean;

    actionBy:string;
    actionDate:Date;
    currentPhase: string;
    customFields:CustomFields[];
    productFiles:ProductFiles[];
    reasons:any[];
    phases:Phases[];

    eccnDocID:number;
    eccn:string;
    requestedDate:Date;
    productDefiner:string;
}

export class CustomFields {
    entityId: string;
    customFieldId: number;
    customFieldName: string;
    value: string;
    //createdBy: Guid;
    createdDate: Date;
    updatedBy: string;
    //updatedDate: Guid;
}
export class ProductFiles {
    productID: number;
    fileGuid: string;
    fileName: string;
    filePath: string;

    fromDOC:string;
    applicationID:string;
    documentTitle:string;
    departmentID:string;
    //files: any[];
    dOCFiles:string[];
    actionBy: string;
    actionDate: Date;
}
export class Phases {
    phaseID:number;
    phaseGuid: string;
    productID: number;
    phaseName: string;
    phaseOwnerID: string;
    phaseOwner: number;
    phaseStatus: string;
    active: string;
    phaseSeq: number;
    phaseComplete: string;
    approval: string;
    isOwner: boolean;
    isViewOnly: boolean;
    isEOLDisplay:boolean;
    isConditionalReleaseDisplay:boolean;
    isEvalutionDisplay:boolean;
    isPrevEvalDisplay:boolean;
    isPrevCRDisplay:boolean;
    isClarify:boolean;
    isSendForApproval: boolean;
    isReminder: boolean;
    isNotes: boolean;
    isDocuments: boolean;
    actionBy: string;
    actionDate:Date;
    phaseStartDate:string;
    phaseEndDate:string
    phaseDepartments: PhaseDepartments[];
    phaseTeam: PhaseTeam[];
    phaseRequiredDocuments: PhaseRequiredDocuments[];
    list: List[];
}
export class PhaseDepartments {
    phaseID: number;
    departmentID: string;
    departmentName: string;
    actionBy: string;
    actionDate:Date;
}
export class PhaseTeam {
    phaseID: number;
    type: string;
    departmentID: string;
    departmentName: string;
    roleID: string;
    roleName: string;
    userID: string;
    userName: string;
    actionBy: string;
    actionDate:Date;
    approvedDate:Date;
    remarks:string;
    shortRemarks:string;
}
export class PhaseRequiredDocuments {
    reqDocumentID:number;
    reqDocumentGuid:string;
    phaseID: number;
    departmentID: string;
    departmentName: string;
    roleID: string;
    roleName: string;
    documentName: string;
    isRequired: string;
    isCompleted: string;
    isRequiredDisplay: boolean;
    isCompletedDisplay: boolean;
    documentLabels:string;
    templates:Templates[];
    isNotes: boolean;
    isDocuments: boolean;
    actionBy: string;
    actionDate:Date;
}

export class List {
    listID:number;
    listGuid: string;
    phaseID: number;
    listName: string;
    listOwnerID: string;
    listOwner: number;
    listStatus: string;
    approval: string;
    active: string;
    listSeq: number;
    isCompleted: string;
    isSequenced:string;
    listLevel:number;
    listType:string;
    listParentID:number;

    isOwner: boolean;
    isSendForApproval: boolean;
    isReminder: boolean;
    isClarify:boolean;
    isNotes: boolean;
    isDocuments: boolean;
    actionBy: string;
    actionDate:Date;
    listDepartments: ListDepartments[];
    listTeam: ListTeam[];
    listRequiredDocuments: ListRequiredDocuments[];
}

export class ListDepartments {
    listID: number;
    departmentID: string;
    departmentName: string;
    actionBy: string;
    actionDate:Date;
}
export class ListTeam {
    listID: number;
    type: string;
    departmentID: string;
    departmentName: string;
    roleID: string;
    roleName: string;
    userID: string;
    userName: string;
    actionBy: string;
    actionDate:Date;
    approvedDate:Date;
    remarks:string;
    shortRemarks:string;
}
export class ListRequiredDocuments {
    reqDocumentID:number;
    reqDocumentGuid:string;
    listID: number;
    departmentID: string;
    departmentName: string;
    documentName: string;
    isRequired: string;
    isCompleted: string;
    isRequiredDisplay: boolean;
    isCompletedDisplay: boolean;
    documentLabels:string;
    templates:Templates[];
    isNotes: boolean;
    isDocuments: boolean;
    actionBy: string;
    actionDate:Date;
}
export class Templates
{
    docTemplateID:number;
    type:string;
    reqDocumentID:number;
    templateID: string;
    templateName: string;
    templatePath: string;
}
export class ViewDocuments {
    id: number;
    fileGuid:string;
    fileName:string;
    filePath:string;
    addedBy:string;
    addedByName:string;
    addedDate:Date;
}

export class ViewNotes {
    id: number;
    note:string;
    shortnote:string;
    addedBy:string;
    addedByName:string;
    addedDate:Date;
}

export class AddNotes {
    type:string;
    transactionID:number;
    phaseID:number;
    listID:number;
    note:string;
    addedBy:string;
    addedByName:string;
    addedDate:Date;
}
export class DeleteModel
{
    type:string;
    transactionID:number;
    actionBy:string
    actionByName:string;
    actionDate:Date;
}

export class Approval {
    type:string;
    iD:number;
    desicion:string;
    comments:string;
    actionBy:string;
    actionByName:string;
    actionDate:Date;
    SeekTeam=[];
}

export class ApprovalDetails {
    type:string;
    phaseID:number;
    phaseGuid:string;
    phase:string;
    departmentID:string;
    departmentName:string;
    roleID:string;
    roleName:string;
    userID:string;
    userName:string;
    approvalStatus:string;
    approveDate:Date;
    comments:string;
    shortComments:string;
}

export class Clarification {
    type:string;
    phaseID:number;
    phase:string;
    clarificationID:number;
    question:string;
    askedBy:string;
    askedByName:string;
    askedDate:Date;
    answer:string;
    answerBy:string;
    answerByName:string;
    answerDate:Date;
    isAnswered:string;
}


export class CancelTeam {
    departmentName: string;
    departmentID: string;
    roleName: string;
    roleID: string;
    userID: string;
    userName: string;
    actionBy: string;
    actionByName: string;
    actionDate: Date;
  isEdit: string;
  seq:number;
  datakey:string;
}

export class AllTeam {   
    type: string;
    departmentName: string;
    departmentID: string;
    roleName: string;
    roleID: string;
    userID: string;
    userName: string;    
   isEdit: string;
   datakey:string
}

export class SearchModel {   
    productLineID: string[];
    productStatus: string[];
    projectID: string;
    download: string;
}