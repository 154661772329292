import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {LoginComponent } from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {MyDocumentsComponent} from './components/mydocuments/mydocuments.component';
import {MyapprovalComponent} from './components/myapproval/myapproval.component';
import {MyproductsComponent} from './components/myproducts/myproducts.component';
import {PhasedetailsComponent} from './components/phasedetails/phasedetails.component';
import {ApprovalDetailsComponent} from './components/approval-details/approval-details.component';
import {CanceldetailsComponent} from './components/canceldetails/canceldetails.component';
import {CancelteamComponent} from './components/Admin/cancelteam/cancelteam.component';
import {ManagecustomfieldsComponent} from './components/managecustomfields/managecustomfields.component';
import { ManageteamComponent } from './components/Admin/manageteam/manageteam.component';
import { ManageapproversComponent } from './components/Admin/manageapprovers/manageapprovers.component';
import { CreateproductComponent } from './components/createproduct/createproduct.component';
import { CreatechildproductComponent } from './components/createchildproduct/createchildproduct.component';
import { OtherproductsComponent } from './components/otherproducts/otherproducts.component';
import { OtherdocumentsComponent } from './components/otherdocuments/otherdocuments.component';
import { ProductcreationComponent } from './components/productcreation/productcreation.component';
import { ProductcreationchildComponent } from './components/productcreationchild/productcreationchild.component';
import { ProductownerComponent } from './components/Admin/productowner/productowner.component';
import {AuthGuard} from './guard/auth.guard';
import { EntrypointComponent } from './components/entrypoint/entrypoint.component';
// import {CreateconditionalreleaseboardComponent} from './components/createconditionalreleaseboard/createconditionalreleaseboard.component';
import { ConditionalreleaseboardComponent } from './components/Admin/conditionalreleaseboard/conditionalreleaseboard.component';
import {ConditionalreleasesComponent} from './components/conditionalreleases/conditionalreleases.component';
import {ManagetaskrequiredComponent} from './components/Admin/managetaskrequired/managetaskrequired.component';
import { HelpguideComponent } from './components/helpguide/helpguide.component';
import { ReportsComponent } from './components/AllReports/reports/reports.component';
import {ProductattributesComponent} from './components/AllReports/productattributes/productattributes.component';
import {ProductsdocumentsComponent} from './components/AllReports/productsdocuments/productsdocuments.component';
import {ProductidComponent} from './components/AllReports/productid/productid.component';
import { PiechartreportComponent } from './components/piechartreport/piechartreport.component';
import { DefaultteamComponent} from './components/Admin/defaultteam/defaultteam.component';
import { EccnComponent} from './components/eccn/eccn.component';
import { EccnproductsComponent} from './components/eccnproducts/eccnproducts.component';
import { ScreportComponent } from './components/AllReports/screport/screport.component';
@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: "", component: LoginComponent },
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: 'entrypoint', component:EntrypointComponent},
                    {path: 'dashboard', component:DashboardComponent,canActivate:[AuthGuard]},
                    {path: "login", component:LoginComponent,canActivate:[AuthGuard]},
                    {path: "createproduct", component:CreateproductComponent,canActivate:[AuthGuard]},
                    {path: "createproduct/:PId", component: CreateproductComponent,canActivate:[AuthGuard] },
                    {path: "createchildproduct", component:CreatechildproductComponent,canActivate:[AuthGuard]},
                    {path: "createchildproduct/:PId", component: CreatechildproductComponent,canActivate:[AuthGuard] },
                    {path: "reports", component:ReportsComponent,canActivate:[AuthGuard]},
                    {path: "myproducts", component:MyproductsComponent,canActivate:[AuthGuard]},
                    {path: "mydocuments", component:MyDocumentsComponent,canActivate:[AuthGuard]},
                    {path: "otherproducts", component:OtherproductsComponent,canActivate:[AuthGuard]},
                    {path: "otherdocuments", component:OtherdocumentsComponent,canActivate:[AuthGuard]},
                    {path: "myapproval", component:MyapprovalComponent,canActivate:[AuthGuard]},
                    {path: "phasedetails", component:PhasedetailsComponent,canActivate:[AuthGuard]},
                    {path: "approval-details", component:ApprovalDetailsComponent,canActivate:[AuthGuard]},
                    {path: "canceldetails", component:CanceldetailsComponent,canActivate:[AuthGuard]},
                    {path: "cancelteam", component:CancelteamComponent,canActivate:[AuthGuard]},
                    {path: "managecustomfields", component:ManagecustomfieldsComponent,canActivate:[AuthGuard]},
                    {path: "manageteam", component:ManageteamComponent,canActivate:[AuthGuard]},
                    {path: "manageapprovers", component:ManageapproversComponent,canActivate:[AuthGuard]},
                    {path: "managetaskrequired", component:ManagetaskrequiredComponent,canActivate:[AuthGuard]},
                    {path: "productcreation", component:ProductcreationComponent,canActivate:[AuthGuard]},
                    {path: "productcreationchild", component:ProductcreationchildComponent,canActivate:[AuthGuard]},
                    {path: "productowner", component:ProductownerComponent,canActivate:[AuthGuard]},
                    {path: "conditionalreleaseboard", component:ConditionalreleaseboardComponent,canActivate:[AuthGuard]},
                    // {path: "createconditionalreleaseboard", component:CreateconditionalreleaseboardComponent,canActivate:[AuthGuard]},
                    {path: "conditionalreleases", component:ConditionalreleasesComponent,canActivate:[AuthGuard]},
                    {path: "helpguide", component:HelpguideComponent,canActivate:[AuthGuard]},
                    {path: "productsattributes", component:ProductattributesComponent,canActivate:[AuthGuard]},
                    {path: "productdocuments", component:ProductsdocumentsComponent,canActivate:[AuthGuard]},
                    {path: "productid", component:ProductidComponent,canActivate:[AuthGuard]},
                    {path: "piechartreport", component:PiechartreportComponent,canActivate:[AuthGuard]},
                    {path: "defaultteam", component:DefaultteamComponent,canActivate:[AuthGuard]},
                    {path: "eccn", component:EccnComponent,canActivate:[AuthGuard]},
                    {path: "eccnproducts", component:EccnproductsComponent,canActivate:[AuthGuard]},
                    {path: "screport", component:ScreportComponent,canActivate:[AuthGuard]},
                ]
            },
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
