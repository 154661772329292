<div class="layout-dashboard">
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-xl-6">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:170px;">
        <span class="overview-icon"><i class="pi pi-desktop"></i></span>
        <span class="overview-title">My Products</span>
        <div class="p-grid overview-detail">
          <div class="p-col-2" style="border-right: 1px solid #dee2e6;">
            <a (click)="product(0)" style="cursor: pointer">
              <!-- routerLink="/myproducts?index=0"> -->
              <div class="overview-number">{{ Counts.peActiveProducts }}</div>
              <div class="overview-subtext">Active</div>
            </a>
          </div>
          <div class="p-col-2" style="border-right: 1px solid #dee2e6;">
            <a (click)="product(3)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.peHoldProducts }}</div>
              <div class="overview-subtext">Hold</div>
            </a>
          </div>
          <div class="p-col-3" style="border-right: 1px solid #dee2e6;">
            <a (click)="product(2)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.peClarification }}</div>
              <div class="overview-subtext">Seek Clarification</div>
            </a>
          </div>
          <div class="p-col-2" style="border-right: 1px solid #dee2e6;">
            <a (click)="product(1)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.peApproval }}</div>
              <div class="overview-subtext">Pending Approval</div>
            </a>
          </div>
          <div class="p-col-3">
            <a (click)="docs(0)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.peDocuments }}</div>
              <div class="overview-subtext">Tasks - Pending Upload</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-6">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:170px;">
        <span class="overview-icon"><i class="pi pi-desktop"></i></span>
        <span class="overview-title">Products In-Collaboration</span>
        <div class="p-grid overview-detail">
          <div class="p-col-2" style="border-right: 1px solid #dee2e6;">
            <a (click)="oproduct(0)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.activeProducts }}</div>
              <div class="overview-subtext">Active</div>
            </a>
          </div>
          <div class="p-col-2" style="border-right: 1px solid #dee2e6;">
            <a (click)="oproduct(3)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.holdProducts }}</div>
              <div class="overview-subtext">Hold</div>
            </a>
          </div>
          <div class="p-col-3" style="border-right: 1px solid #dee2e6;">
            <a (click)="oproduct(2)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.clarification }}</div>
              <div class="overview-subtext">Seek Clarification</div>
            </a>
          </div>
          <div class="p-col-2" style="border-right: 1px solid #dee2e6;">
            <a (click)="app(0)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.approval }}</div>
              <div class="overview-subtext">My Approval</div>
            </a>
          </div>
          <div class="p-col-3">
            <a (click)="odocs(0)" style="cursor: pointer">
              <div class="overview-number">{{ Counts.documents }}</div>
              <div class="overview-subtext">Tasks - Pending Upload</div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-8">
      <div class="card revenue" style="height:400px;">
        <h4 style="padding-bottom:15px;">Products by Phase</h4>
        <hr>
        <div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container"
          style="width:550px;height:450px;margin-left: 20%;margin-top: 3%;">
          <p-chart type="pie" id="revenue-chart" [data]="phaseChart" [options]="options"></p-chart>
        </div>
        <ng-template #Chartemptymessage>
          <div style="text-align: center;">No products found...</div>
        </ng-template>
      </div>
    </div>

    <div class="p-col-12 p-lg-4">
      <div class="card" style="height:400px; overflow-y: scroll;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>Timeline</h4>
          <p-dropdown [options]="TimelineOptions" [(ngModel)]="selectedTimelineOption" optionLabel="name"
            (onChange)="GetTimeline($event.value,'C')" styleClass="dashbard-demo-dropdown"
            [ngStyle]="{'min-width': '8rem'}"></p-dropdown>
        </div>
        <hr>
        <div class="widget-timeline" *ngIf='isTimelineDataAvailable;else TimeLineemptymessage'>
          <div *ngFor="let item of Timeline;">
            <div class="timeline-event">
              <span class="timeline-event-icon {{item.color}}">
                <i class="pi {{item.icon}}"></i>
              </span>
              <div class="timeline-event-title">{{item.title}}</div>
              <div class="timeline-event-detail" (click)="Details(item.productID,item.phaseID,item.type)"
                [innerHTML]="item.subTitle"></div>
            </div>
          </div>
        </div>
        <ng-template #TimeLineemptymessage>
          <div style="text-align: center;">No timeline found...</div>
        </ng-template>
      </div>
    </div>

    <div class="p-col-12">
      <div class="card">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Active Product Summary - {{ProductCount}}</h5>
            <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Search Count - {{ProductCountFilter}} -->
            <span class="p-field-radiobutton" style="float:left;">
              <label style="margin-right: 10px;">Filter by:</label>
              <p-radioButton value="startsWith" [(ngModel)]="selectedfilter"></p-radioButton>
              <label style="margin-right: 10px;">startsWith</label>
              <p-radioButton value="Contains" [(ngModel)]="selectedfilter"></p-radioButton>
              <label>Contains</label>
            </span>
            <span class="p-input-icon-left" style="float:right;">
            <p-checkbox name="groupname" (click)="getproducts()" label="All Products" binary="false" inputId="binary"
              class="padding" [(ngModel)]="AllProductsValue"></p-checkbox>
            <span class="p-input-icon-left">
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                (click)="print('invoice-content','trsearch')" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right;width:25px; height:25px;margin-top: 12px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 12px;"
                (click)="download()"></button>
            </span>
          </span>
        </div>
        <div>
          <p-table #dt [columns]="cols" [value]="products" id="excel-table" [loading]="loading" [autoLayout]="true"
            (onFilter)="onFilter($event, dt)" styleClass="p-datatable-striped" class="table-align" [filterDelay]="0"
            [scrollable]="true" scrollHeight="570px">
            <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>
                <th style="text-align: center;width: 80PX;padding-left:15px">Actions</th>
                <th style="text-align: center;padding-left:15px" *ngFor="let col of columns;let i = index"
                  [ngClass]="{'dieCode': i == 1, 'productLine': i == 4 || i ==11,  'phase': i ==9,'w':i==0 || i == 2 || i == 7 || i == 8 || i ==10 || i==3  || i == 5 || i == 6}">
                  {{ col.header }}</th>
              </tr>
              <tr id="trSearch">
                <th style="text-align: center;"></th>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                  <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search"
                    style="margin: -10px 0 -10px 0; width: 95%;height:25px" />
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
              <tr *ngIf="rowData.productOwner==''" [pSelectableRow]="rowData"
                style="background-color:#008EBD;color:white;border: 2px;vertical-align: middle;">
                <td style="text-align: left;width: 80PX;">
                </td>
                <th style="text-align: left; padding-left:15px;width:15%;font-size: medium;">{{ rowData.productName }}
                </th>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
                <td style="text-align: left; padding-left:15px;width:15%;"></td>
              </tr>
              <tr *ngIf="rowData.productOwner!=''" [pSelectableRow]="rowData" style="vertical-align: middle;">
                <td style="text-align: center;width: 80PX;">
                  <button pButton type="button" pTooltip="View" tooltipPosition="right"
                    (click)="Details(rowData.productID, rowData.phaseID,rowData.type)" icon="pi pi-eye"
                    class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                </td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productName }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.dieCode }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.mfgPart }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.projectDescription }}</td>
                <td style="text-align: center; padding-left:15px;width:15%;">{{ rowData.productLine }}</td>
                <td style="text-align: left; padding-left:15px;width:15%">{{ rowData.foundry }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.subcon }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productOwner }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;">{{ rowData.productStatus }}</td>
                <td style="text-align: left; padding-left:15px;width:15%;font-weight: bold;"
                  [ngStyle]="{'color': rowData.phase === 'P0' ? '#c14a09': rowData.phase === 'Review' ? '#FFA500' :rowData.phase === 'P1' ? '#008000' :rowData.phase === 'P2' ?  '#0000FF': rowData.phase === 'P3 RTS' ? '#3b2820': rowData.phase === 'P3 Production' ? '#800080' :rowData.phase === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
                  {{ rowData.phase }}</td>
                <td style="text-align: left; padding-left:15px; width:15%;">{{ rowData.phaseStatus }}</td>
                <td style="text-align: center;padding-left:15px; width:15%;">{{ rowData.phaseStartDate }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <h5 style="color:#495057; padding:15px 0 0 15px;"> {{ProductCountFilter}} rows found. </h5>
            </ng-template>
          </p-table>
        </div>




      </div>
    </div>
  </div>
</div>

<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>