import { Injectable } from "@angular/core";
import { HttputilityService } from "./httputility.service";
import { DashboardService } from "./dashboard.service";
@Injectable({
  providedIn: "root",
})
export class PhaseDetailsService {
  UpdateOwner(ProductID, Owner) {
    return this._httputilityService.Get("Product/UpdateOwner?ProductID=" + ProductID + "&Owner="+Owner,"");
  }
  constructor(private _httputilityService: HttputilityService,private _dashboardservice: DashboardService) {}

  GetIssues(ProductID,PhaseID) {
    return this._httputilityService.GetI("GetProductPhaseOnHoldIssues?productId=" + ProductID + "&phaseId="+PhaseID,"");
  }
  GetTasks(ProductID,PhaseID) {
    return this._httputilityService.GetP("Tasks/GetOpenTasks?ProductID=" + ProductID + "&PhaseID="+PhaseID,"");
  }

  GetAllProducts() {
  return this._httputilityService.Get("Product/GetAllProducts","");
}
 PhaseBar(ProductID) {
    return this._httputilityService.Get("Product/GetBarbyProductID?ProductID=" + ProductID,"");
  }

  ProductDetails(ProductID,UserID,isPLMAdmin) {
    return this._httputilityService.Get("Product/GetProductDetailsbyID?ProductID=" + ProductID+"&UserID=" + UserID+"&isPLMAdmin="+isPLMAdmin,"");
    // GetProductbyID
  }
  PhaseDetails(PhaseID,UserID) {
    var timeZone = sessionStorage.getItem('timeZone');
    if(timeZone == null)
    {
      this._dashboardservice.GetUserDetails(sessionStorage.getItem('userGuid'))
      .subscribe(
         (data) => {
        var userDetails = data.returnObject;
        sessionStorage.timeZone = userDetails.timeZone;
        timeZone = userDetails.timeZone;
      });
    }
    return this._httputilityService.Get("Phase/GetPhasebyID?PhaseID=" + PhaseID+"&UserID=" + UserID+"&TimeZone="+timeZone,"");
  }

  PhaseDocuments(PhaseID,UserID) {
    return this._httputilityService.Get("Phase/GetDocumentbyPhaseID?PhaseID=" + PhaseID+"&UserID="+UserID,"");
  }
  UpdatePhaseDocuments(model,PhaseID){
    return this._httputilityService.Add("Phase/UpdatePhaseDocuments?PhaseID=" + PhaseID,model);
  }

  getDocuments(Type,TransactionID,UserID) {
    return this._httputilityService.Get("Transaction/ViewDocuments?Level=" + Type+"&ID=" + TransactionID+"&UserID="+UserID,"");
  }
  getNotes(Type,TransactionID) {
    return this._httputilityService.Get("Transaction/ViewNotes?Level=" + Type+"&ID=" + TransactionID,"");
  }

  addDocuments(model) {
    return this._httputilityService.FormDataPost("Transaction/AddDocuments", model);
  }
  UpdateECCN(model) {
    return this._httputilityService.FormDataPost("Transaction/AddECCN", model);
  }
  addNotes(model) {
    return this._httputilityService.Add("Transaction/AddNote", model);
  }

  deleteDocument(model) {
    return this._httputilityService.Add("Transaction/DeleteDocument", model);
  }
  deleteNote(model) {
    return this._httputilityService.Add("Transaction/DeleteNote", model);
  }
  Hold(model) {
    return this._httputilityService.Add("Transaction/HoldProduct", model);
  }
  Release(model) {
    return this._httputilityService.Add("Transaction/ReleaseProduct", model);
  }
  getDOCDocuments(deptId) {
    return this._httputilityService.GetD("PDMDocuments?deptId="+deptId,'');
  }
  PhaseTask(model,Checked) {
    return this._httputilityService.Add("Transaction/PhaseTaskChecked?Checked="+Checked, model);
  }
  ListTask(model,Checked) {
    return this._httputilityService.Add("Transaction/ListTaskChecked?Checked="+Checked, model);
  }
  UpdateEOL(EOL,ProductID,PhaseID)
  {
    return this._httputilityService.Get("Transaction/UpdateEOL?EOL="+EOL+"&ProductID="+ProductID+"&PhaseID="+PhaseID,'');
  }
  CreateConditionalRelease(model){
    return this._httputilityService.FormDataPost("CreateEvalutionConditionalRelease", model);
  }
  UpdateEvalutionConditionalRelease(model){
    return this._httputilityService.FormDataPost("UpdateEvalutionConditionalRelease", model);
  }
  GetConditionalReleaseDetails(ProductID,type,UserID)
  {
    return this._httputilityService.Get("GetConditionalReleaseDetails?productId=" + ProductID+'&type='+type+'&UserID='+UserID,"");
  }
  GetPreviousCR_Eval(ProductID,type,UserID)
  {
    return this._httputilityService.Get("GetPreviousCR_Eval?productId=" + ProductID+'&type='+type+'&UserID='+UserID,"");
  }
  getRolesByDepartmentGuids(departmentGuids){
    return this._httputilityService.PostU("user/GetRolesByDepartments", departmentGuids);
  }
  GetProductLines(){
    return this._httputilityService.Get('CustomFields/GetData?Query=select * from tblproductline',"");
  }
  GetConditionalProducts(userId){
    return this._httputilityService.Get('GetConditionalProducts?userId='+userId,"");
  }
  GetEvaluationProducts(userId){
    return this._httputilityService.Get('GetEvaluationProducts?userId='+userId,"");
  }
  // ApproveRejectConditionalRelease(model){
  //   return this._httputilityService.Add("ApproveRejectConditionalRelease",model);
  // }
  GetConditionalApprovalsHistory(conditionalId){
    return this._httputilityService.Get('GetConditionalApprovalsHistory?conditionalId='+conditionalId,"");
  }
  GetConditionalClarifications(conditionalId){
    return this._httputilityService.Get('GetConditionalClarifications?conditionalId='+conditionalId,"");
  }
  GetPhaseConditionalDetails(productId){
    return this._httputilityService.Get('PhaseConditionalReleaseAvailable?productId='+productId,"");
  }
  DeleteConditionalRelease(id){
    return this._httputilityService.Get('DeleteConditionalRelease?id='+id,"");
  }
  UpdateSecurityToken(token){
    return this._httputilityService.PostU("user/UpdateSecurityToken?Token=" + token ,null);
  }
  CreateSecurityToken(){
    return this._httputilityService.PostU("user/CreateSecurityToken" ,null);
  }
  GetMenusByUser(userId,applicationid){
    return this._httputilityService.GetU('GetMenusByUser?userid='+userId+'&applicationid='+applicationid,'');
  }
  downloadDMSFile(filename,newname){
    return this._httputilityService.downloadDMSFile('Download?filename='+filename+"&newname="+newname);
  }
  AddUserTracking(model){
    return this._httputilityService.PostU('user/AddUserTracking',model);
  }
}
