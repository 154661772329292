import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private _HttputilityService : HttputilityService) { }
  GetAllDieCodes() {
    return this._HttputilityService.GetU('customfields/GetAllDieCode','')
  }
  GetAllMFGParts() {
    return this._HttputilityService.GetU('customfields/GetAllMFGParts','')
  }

  GetAllTasksForChildProduct(ProductID)
  {
    return this._HttputilityService.GetP('Tasks/GetAllTasksForChildProduct?ProductID='+ProductID,'')
  }

  GetProductInfo(ProductID,ChildType)
  {
    return this._HttputilityService.Get('Template/GetProductInfo?ProductID='+ProductID+'&ChildType='+ChildType,'')
  }

  GetDates(phaseTasks, da) {
    return this._HttputilityService.Add('Template/GetDates?strDate='+ da,phaseTasks);
  }
  GetTemplates(productLineId,Type,IsAdmin,UserID){
    return this._HttputilityService.Get('Template/GetTemplates?productLineId='+ productLineId + '&Type=' + Type+ '&IsAdmin=' + IsAdmin+ '&UserID=' + UserID,'');
  }
  GetChildDepartment(ChildType)
  {
    return this._HttputilityService.Get('Template/GetChildDepartment?ChildType='+ChildType,'');
  }
  GetProductOwners(){
    return this._HttputilityService.Get('Template/GetProductOwners','');
  }
  GetCustomGroups(templateGuid){
    return this._HttputilityService.Get('Template/GetCustomGroups?templateGuid='+templateGuid,'');
  }
  GetPhases(templateGuid,Type,MilestoneID,DeptID){
    return this._HttputilityService.Get('Template/GetPhases?templateGuid='+templateGuid+'&Type='+Type+'&MilestoneID='+MilestoneID+ '&DeptID='+DeptID,'');
  }

  GetPhaseTasks(templateGuid,startdate,tmpProductId,Type,MilestoneID,Level,SiliconeType,DeptID){
    return this._HttputilityService.Add('Template/GetPhaseTasks?templateGuid='+templateGuid+'&startdate='+startdate+'&ProductID='+tmpProductId+'&Type='+Type+'&MilestoneID='+MilestoneID+'&Level='+Level + '&SiliconeType='+SiliconeType+ '&DeptID='+DeptID,'');
  }

  GetPhaseDocuments(templateGuid,ProductID,Type,MilestoneID,SiliconeType,DeptID,UserID){
    return this._HttputilityService.Get('Template/GetPhaseDocuments?templateGuid='+templateGuid+'&ProductID='+ProductID+'&Type='+Type+'&MilestoneID='+MilestoneID+'&SiliconeType='+SiliconeType+ '&DeptID='+DeptID+'&UserID='+UserID,'');
  }
  GetProductLine(){
    return this._HttputilityService.Get('Template/GetProductLine','');
  }
  SaveTmpProductCustomFields(data){
    return this._HttputilityService.Add('Template/SaveTmpProductCustomFields',data);
  }
  SaveTmpPhaseTasksAndDocuments(data){
    return this._HttputilityService.Add('Template/SaveTmpTasksAndDocuments',data);
  }
  SaveTmpPhaseTeam(data){
    return this._HttputilityService.Add('Template/SaveTmpPhaseAndTeam',data);
  }
  CreateProduct(tmpProductId,userId){
    return this._HttputilityService.Add('Template/CreateProduct?ProductID='+tmpProductId+'&ActionBy='+userId,'');
  }
  GetTmpProducts(Type){
    return this._HttputilityService.Get('Template/GetTmpProducts?Active=true&Type='+Type,'');
  }
  GetProductById(PID,startdate,UserID){
    return this._HttputilityService.Add('Template/GetTmpProductById?PID='+PID+'&startdate='+startdate+"&UserID="+UserID,'');
  }
  GetCustomFields(TCGID,Type,ChildType){
    return this._HttputilityService.Get('Template/GetCustomFields?templateGuid='+TCGID + '&Type='+Type+'&ChildType='+ChildType,'');
  }
  GetPhaseTeam(templateGuid,tmpProductId,Type,MilestoneID,DeptID){
    return this._HttputilityService.Get('Template/GetPhaseTeam?templateGuid='+templateGuid+'&ProductID='+tmpProductId+'&Type='+Type+'&MilestoneID='+MilestoneID+ '&DeptID='+DeptID,'');
  }
  GetDepartments(){
    return this._HttputilityService.GetU('user/GetDepartments','');
  }
  GetUsersByRoleGuid(RoleGUID){
    return this._HttputilityService.GetU(`user/GetUsersByRole?RoleGUID=${RoleGUID}`,'');
  }
  GetRoles(departmentId){
    return this._HttputilityService.GetU(`user/GetRolesByDepartment?DepartmentGUID=${departmentId}`,'');
  }
  UpdateTemplateTeam(model)
  {
    return this._HttputilityService.Add('Template/UpdateTemplateTeam',model);
  }
  GetPieChartProjectsData(model){
    return this._HttputilityService.Add('Report/GetPieChartProjectsData',model);
  }
}
