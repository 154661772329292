import { Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { Table } from "primeng/table";
import { ManageCustomFieldsService } from "../../../services/manage-custom-fields.service";
import { PrintcommonService } from "../../../services/printcommon.service";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';
import { PhaseRequiredDocuments} from "../../../model/PhaseDetails";
import { NullTemplateVisitor } from '@angular/compiler';

@Component({
  selector: 'app-managetaskrequired',
  templateUrl: './managetaskrequired.component.html',
  styleUrls: ['./managetaskrequired.component.scss']
})
export class ManagetaskrequiredComponent implements OnInit {

  showLoading:boolean;
  userId = sessionStorage.getItem("userGuid");
  userintId = sessionStorage.getItem("userId");
  Product: any[];
  selectedProduct: any;
  productError:string = "";
  SelProduct: string = "";
  Phase: any[];
  selectedPhase: any;
  phaseError:string = "";
  SelPhase: number = 0;
  SelPhaseGU:string = "";
  DocsCount:number=0;
  filteredProdType: any[];

  ErrorDisplay: boolean = false;
  Error: string = "";

  cols: any[];
  CurrentRequiredDocuments: PhaseRequiredDocuments[];
  OrgCurrentRequiredDocuments: any[];
  selectedDocs: PhaseRequiredDocuments[];
  @ViewChild("dtDocs") tableDocs: Table;

  ViewOnly:boolean = true;
  
  dynamicplaceholder: string;
  apiResponse:any;
  canmodify: boolean=false;
  userFeature:boolean;
  userFeature1:boolean;
  constructor(private _facadeService: FacadeService,private pipe: DatePipe,
    private _ManageCustomFieldsService: ManageCustomFieldsService,
    private printService: PrintcommonService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
  }
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PDM_GAPVIEW");
        if(featureExist!=null){
          this.userFeature = true;
        }

        var featureExist = feature.find(m=>m.code=="PDM_Owner");
        if(featureExist!=null){
          this.userFeature1 = true;
        }
      }
    }
      this.showLoading = true;
      sessionStorage.DisplayType = "Phase";
      this.GetProduct();

      this.cols = [
        { field: "departmentName",header: "Responsible Department" },
        { field: "documentName", header: "Task" },
        { field: "isRequired", header: "Task Required" },
        { field: "documentLabels", header: "Document Submission"},
        { field: "templates", header: "Template"},
      ];
  }
  test(val,field,dt)
  {
    if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "documentName")
      sessionStorage.documentName = val;
    else if(field == "isRequired")
      sessionStorage.isRequired = val;
    else if(field == "documentLabels")
      sessionStorage.documentLabels = val;
    else if(field == "templates")
      sessionStorage.templates = val;

    dt = dt.filter(val,field, 'startsWith');
    this.Count();
  }

    GetProduct() {
      this.SelProduct="";
      if(this.userFeature)
      {
        this._ManageCustomFieldsService
        .GetAllActiveProducts()
        .subscribe((data) => {
          this.Product = data.returnObject;
           this.getdocuments();
        });
      }
      else{
        this._ManageCustomFieldsService
        .GetProductByUser(this.userId)
        .subscribe((data) => {
          this.Product = data.returnObject;
          this.getdocuments();
        });
      }
  }
  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.Product.length; i++) {
        let product= this.Product[i];
        if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredProdType = filtered;
}


  productChange() {
    this.showLoading=true;
    this.phaseError = "";
    this.SelProduct =this.selectedProduct.productGuid;
    this.ViewOnly = true;
    this.getphase();
  }

  getphase() {
    this.SelPhase=0;
    this.SelPhaseGU = "";
    this._facadeService.GetPhasesbyID(this.SelProduct)
    .subscribe((data) => {
      this.Phase = data.returnObject;
      this.ViewOnly = true;
      this.getdocuments();
      console.log("Product data ::" + JSON.stringify(this.Product));
    },
    (error) => {  console.log(error); this.showLoading = false;  });
}

phaseChange(selectedPhase) {
  this.showLoading=true;
  this.SelPhase = selectedPhase.phaseID;
  this.SelPhaseGU = selectedPhase.phaseGuid;
  this.getdocuments();
}
Count()
{
  if(this.CurrentRequiredDocuments != undefined && this.CurrentRequiredDocuments != null)
  {
    var temp = this.CurrentRequiredDocuments;
    if(sessionStorage.departmentName != "" && sessionStorage.departmentName != undefined)
      temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.documentName != "" && sessionStorage.documentName != undefined)
      temp = temp.filter(x=> x.documentName.toLowerCase().startsWith(sessionStorage.documentName.toLowerCase()));
    if(sessionStorage.isRequired != "" && sessionStorage.isRequired != undefined)
      temp = temp.filter(x=> x.isRequired.toLowerCase().startsWith(sessionStorage.isRequired.toLowerCase()));
    if(sessionStorage.documentLabels != "" && sessionStorage.documentLabels != undefined)
      temp = temp.filter(x=> x.documentLabels.toLowerCase().startsWith(sessionStorage.documentLabels.toLowerCase()));
    if(sessionStorage.templates != "" && sessionStorage.templates != undefined)
      temp = temp.filter(x=> x.templates.filter( y=> y.templateName.toLowerCase().startsWith(sessionStorage.templates.toLowerCase())));
    this.DocsCount = temp.length;
  }
  else
    this.DocsCount = 0;
}

getdocuments() {
  this.showLoading = true;
    this.phaseError="";
    if(this.SelProduct != "" && this.SelProduct != null && this.SelProduct != undefined 
    && this.SelPhase != 0 && this.SelPhase != null && this.SelPhase != undefined)
    {
      this._facadeService.PhaseDocuments(this.SelPhase,this.userintId).subscribe(
        (data) => {
          this.CurrentRequiredDocuments = data.returnObject;
          sessionStorage.OrgCurrentRequiredDocuments = JSON.stringify(data.returnObject);
          if(this.CurrentRequiredDocuments.length > 0)
          {
            if(this.userFeature)
              this.ViewOnly = false;
          }
          else
            this.ViewOnly = true;
         this.Count();
          
          if(this.userFeature1)
          {
            this._facadeService.IsOwner('Phase',this.SelPhaseGU,this.userId).subscribe(
              (data) => {
                this.canmodify=data.returnObject;
                if(!this.canmodify)
                {
                  this.phaseError="You don't have access to modify the selected tasks.";
                  this.ViewOnly = true;
                }
                else if(this.CurrentRequiredDocuments.length > 0)
                  this.ViewOnly = false;
                this.showLoading=false;
              },
              (error) => {  console.log(error);  this.showLoading=false;   }
              );
          }
          else
            this.showLoading=false;
        },
        (error) => {  console.log(error);  this.showLoading=false; }
        );
    }
    else
    {
      this.CurrentRequiredDocuments = [];
      sessionStorage.OrgCurrentRequiredDocuments = JSON.stringify([]);
      this.ViewOnly = true;
      this.showLoading=false;
    }
  }

  DocumentRequired(docId,event) {
    const checked = event.target.ariaChecked;
    if (checked) {
      var originalPhaseDocsData=this.CurrentRequiredDocuments.find(m=>m.reqDocumentID==docId);
      if(originalPhaseDocsData!=null){
        originalPhaseDocsData.isRequired="Yes";
      }
    }
    else {
      var originalPhaseDocsData=this.CurrentRequiredDocuments.find(m=>m.reqDocumentID==docId);
      if(originalPhaseDocsData!=null){
        originalPhaseDocsData.isRequired="No";
      }
    }
  }

  savedata() {
    this.showLoading = true;
    this.productError = '';
    this.phaseError = '';
    if(this.SelProduct == "")
    { this.productError = "Please select product";this.showLoading = false;}
    else if(this.SelPhase == 0)
    { this.phaseError = "Please select phase";this.showLoading = false;}
    else
    {
      let final= [];

      this.CurrentRequiredDocuments.forEach(element => {
        var model = {
          ReqDocumentID:element.reqDocumentID, 
          IsRequired:element.isRequired,
          ActionBy: this.userId
        };
        final.push(model);
      });
          
      this._facadeService.UpdatePhaseDocuments(final,this.SelPhase).subscribe(
        (data) => {
          this.apiResponse = data;
            if(this.apiResponse.returnCode == 0){
              this.showLoading=false;
              this.getdocuments();
              this.ErrorDisplay = true;
              this.Error = "Updated successfully.";
            }
            else{
              this.showLoading=false;
              this.ErrorDisplay = true;
              this.Error = this.apiResponse.returnMessage;
            }
        },
        (error) => {  console.log(error); this.showLoading = false;  }
      );
    }
   }

  resetCtrls() {
    this.showLoading = true;
    this.CurrentRequiredDocuments = JSON.parse(sessionStorage.getItem("OrgCurrentRequiredDocuments"));
    this.showLoading = false;
  }

  exportExcel() {
    if(this.selectedPhase != undefined && this.selectedPhase != null)
    {
      /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 150 },{ wpx : 450 },{ wpx : 80 },{ wpx : 120 },{ wpx : 120 }];
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, this.selectedProduct.productName + " - " + this.selectedPhase.phaseName + " - Tasks.xlsx");
    }
  }
  DMSToken(rowData){
    this.showLoading = true;
    if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
      this.showLoading = true;
      var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
      this.Download(rowData.fileName, newname, rowData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadDMSFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  print(SearchID) {
    if(this.selectedPhase != undefined && this.selectedPhase != null)
    {
      var Content = document.getElementById('invoice-content').innerHTML;
      var Title = this.selectedProduct.productName + " - " + this.selectedPhase.phaseName + " - Tasks";
      this.printService.Print(Content,Title,SearchID,false);
    }
  }
}
