import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { ManageCustomFieldsService } from "../../../services/manage-custom-fields.service";
import { FacadeService } from '../../../facade/facade.service';
import {ProductService} from 'src/app/services/product.service';
import { Product } from "../../../model/PhaseDetails";

@Component({
  selector: 'app-productowner',
  templateUrl: './productowner.component.html',
  styleUrls: ['./productowner.component.scss']
})
export class ProductownerComponent implements OnInit {
  showLoading:boolean;
  userId = sessionStorage.getItem("userGuid");
  ProductDrop: any[];
  selectedProduct: any;
  productError:string = "";
  SelProduct: string = "";
  prodOwnerData: any[];
  selectedProductOwner: any;
  ProductEngError:string = "";
  Product: Product;
  View : boolean = false;
  productLine:string="";
  projecName:string = "";
  dieCode:string="";
  mfgPart:string = "";
  projectDescription:string = "";
  description:string = "";
  phase:string ="";
  status:string = "";
  startDate:any;
  endDate:any;
  filteredProdType: any[];

  apiResponse:any;
  ErrorDisplay: boolean = false;
  Error: string = "";
  userFeature:boolean;
  constructor( private _facadeService: FacadeService,private _productService:ProductService,
     private _ManageCustomFieldsService: ManageCustomFieldsService,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PDM_GAPVIEW");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.showLoading = true;
    this.GetProduct();
  }
  GetProduct() {
    this.SelProduct="";
    if(this.userFeature)
    {
      this._ManageCustomFieldsService
      .GetAllActiveProducts()
      .subscribe((data) => {
        this.ProductDrop = data.returnObject;
        this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  });
    }
    else
      this.showLoading = false;
  }

  productChange() {
    this.showLoading=true;
    this.View = false;
    this.SelProduct =this.selectedProduct.productID;
    this.getProductDetails();
  }

  getProductDetails() {
    this.productLine = this.projecName = this.dieCode = this.mfgPart = this.projectDescription = this.description = this.phase = this.status = "";
    this.startDate = this.endDate = "";

    if(this.SelProduct != "")
    {
      this._facadeService.ProductDetails(this.SelProduct, this.userId,false).subscribe(
        (data) => {
          this.Product = data.returnObject;
          this.productLine = this.Product.spaceName;
          this.projecName = this.Product.productName;
          this.dieCode = this.Product.dieCode;
          this.mfgPart = this.Product.mfgPart;
          this.projectDescription = this.Product.projectDescription;
          this.description = this.Product.description;
          this.phase = this.Product.currentPhase;
          this.status = this.Product.productStatus;

          this.View = true;
          if ( this.Product.dueDate !== null && this.Product.dueDate !== undefined) 
            this.Product.dueDate = new Date(new Date(this.Product.dueDate).toDateString() + " " + new Date(this.Product.dueDate).toTimeString() + " UTC");
          if ( this.Product.startDate !== null && this.Product.startDate !== undefined)
            this.Product.startDate = new Date(new Date(this.Product.startDate).toDateString() + " " + new Date(this.Product.startDate).toTimeString() + " UTC");
          this.startDate = this.Product.startDate;
          this.endDate = this.Product.dueDate;
           console.log(this.Product);
          this.GetProductOwners(this.Product.productOwnerID);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
    {
      this.prodOwnerData = [];
      this.showLoading = false;
    }
  }

  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    if(this.ProductDrop!=null&&this.ProductDrop!=undefined&&this.ProductDrop.length>0){
      for(let i = 0; i < this.ProductDrop.length; i++) {
        let product= this.ProductDrop[i];
        if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }  
    }
     
    this.filteredProdType = filtered;
}
  GetProductOwners(productOwnerId){
    this._productService.GetProductOwners().subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          // console.log("GetProductOwners data:"+JSON.stringify(data.returnObject));
          data.returnObject.forEach(element => {
            element.fullName=element.firstName+" "+element.lastName;
          });
          this.prodOwnerData=data.returnObject;
          if(productOwnerId!=null&&productOwnerId!=""&&productOwnerId!=undefined){
            this.selectedProductOwner=this.prodOwnerData.find(m=>m.userGuid.toUpperCase()==productOwnerId.toUpperCase());
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  savedata()
  {
    this.showLoading = true;
    this.productError = '';
    this.ProductEngError = '';
    if(this.SelProduct == "")
    { this.productError = "Please select product";this.showLoading = false;}
    else if(this.selectedProductOwner == "" || this.selectedProductOwner == null || this.selectedProductOwner == undefined)
    { this.ProductEngError = "Please select Product Engineer";this.showLoading = false;}
    else
    {
      let ok = true;
      if(this.selectedProductOwner.userGuid.toUpperCase() == this.Product.productOwnerID.toUpperCase())
      { this.ProductEngError = "Product Engineer should be different from original one";this.showLoading = false; ok = false;}
      if(ok)
      {
        this._facadeService.UpdateOwner(this.SelProduct,this.selectedProductOwner.userGuid).subscribe(
          (data) => {
            this.apiResponse = data;
              if(this.apiResponse.returnCode == 0){
                this.SelProduct = "";
                this.getProductDetails();
                this.ErrorDisplay = true;
                this.Error = "Updated successfully.";
              }
              else{
                this.showLoading=false;
                this.ErrorDisplay = true;
                this.Error = this.apiResponse.returnMessage;
              }
          },
          (error) => {
          console.log(error);this.showLoading=false;
          }
        );
      }
    }
  }

}
