import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { DashboardProducts } from 'src/app/model/DashboardData';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-eccnproducts',
  templateUrl: './eccnproducts.component.html',
  styleUrls: ['./eccnproducts.component.scss']
})
export class EccnproductsComponent implements OnInit {
  loading: "loading";
  showLoading:boolean;

  cols: any[];
  products: any;
  selectedProducts: DashboardProducts[];
  @ViewChild('dt') table: Table;
  Count : number = 0;

  constructor(  private _facadeService: FacadeService,
    public printService: PrintcommonService,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.cols = [
      { field: 'eccnRequestedDate', header: 'Requested Date' },
      { field: 'productName', header: 'Product' },
      { field: 'dieCode', header: 'Die Code' },
      { field: 'mfgPart', header: 'Mfg Part' },
      { field: 'fgPart', header: 'FG Part' },
      { field: 'productLine', header: 'Product Line' },
      { field: 'productOwner', header: 'Product Engineer' },
      { field: 'phase', header: 'Phase' },
    ];
    sessionStorage.eccnRequestedDate = "";sessionStorage.productName = ""; sessionStorage.dieCode = ""; sessionStorage.mfgPart = ""; sessionStorage.fgPart = "";
    sessionStorage.productLine = ""; sessionStorage.productOwner = ""; sessionStorage.phase = "";
    this.getproducts();
  }

  CountFilter(val,field,dt)
  {
    if(field == "eccnRequestedDate")
      sessionStorage.eccnRequestedDate = val;
    if(field == "productName")
      sessionStorage.productName = val;
    else if(field == "dieCode")
      sessionStorage.dieCode = val;
    else if(field == "mfgPart")
      sessionStorage.mfgPart = val;
    else if(field == "phase")
      sessionStorage.phase = val;
    else if(field == "fgPart")
      sessionStorage.fgPart = val;
    else if(field == "productLine")
      sessionStorage.productLine = val;
    else if(field == "productOwner")
      sessionStorage.productOwner = val;
    dt = dt.filter(val,field, 'startsWith');

    this.Count = this.MAP(this.products,sessionStorage.eccnRequestedDate,  sessionStorage.productName, sessionStorage.dieCode, sessionStorage.mfgPart, sessionStorage.fgPart, 
        sessionStorage.productLine, sessionStorage.productOwner, sessionStorage.phase);
  }
  CountGlobalFilter(val,dt)
  {
    sessionStorage.eccnRequestedDate = val; sessionStorage.productName = val; sessionStorage.dieCode = val; sessionStorage.mfgPart = val; sessionStorage.fgPart = val;
      sessionStorage.productLine = val; sessionStorage.phase = val; sessionStorage.productOwner = val;
    dt = dt.filterGlobal(val, 'startsWith');

    this.Count = this.MAP(this.products,sessionStorage.eccnRequestedDate,sessionStorage.productName, sessionStorage.dieCode, sessionStorage.mfgPart, sessionStorage.fgPart, 
        sessionStorage.productLine, sessionStorage.productOwner, sessionStorage.phase); 
  }

  MAP(dt,eccnRequestedDate,productName,dieCode,mfgPart,fgPart,productLine,productOwner,phase)
  {
    if(dt != undefined && dt != null)
    {
      var temp = dt;
      if(eccnRequestedDate != "") temp = temp.filter(x=> x.eccnRequestedDate.toLowerCase().startsWith(eccnRequestedDate.toLowerCase()));
      if(productName != "") temp = temp.filter(x=> x.productName.toLowerCase().startsWith(productName.toLowerCase()));
      if(dieCode != "") temp = temp.filter(x=> (x.dieCode == null ? "" : x.dieCode.toLowerCase()).startsWith(dieCode.toLowerCase()));
      if(mfgPart != "")  temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(mfgPart.toLowerCase()));
      if(fgPart != "") temp = temp.filter(x=> x.fgPart.toLowerCase().startsWith(fgPart.toLowerCase()));
      if(productLine != "") temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(productLine.toLowerCase()));
      if(productOwner != "") temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(productOwner.toLowerCase()));
      if(phase != "") temp = temp.filter(x=> x.phase.toLowerCase().startsWith(phase.toLowerCase()));
      return temp.length;
    }
    return 0;
  }
  getproducts() {
    this._facadeService.ECCNProducts().subscribe(
      (data) => {
        this.products = data.returnObject; 
        this.Count = this.MAP(this.products,sessionStorage.eccnRequestedDate,sessionStorage.productName, sessionStorage.dieCode, sessionStorage.mfgPart, sessionStorage.fgPart, 
          sessionStorage.productLine, sessionStorage.productOwner, sessionStorage.phase);
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  download(val,title) {
      /* table id is passed over here */   
      let element = document.getElementById(val); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 120 },{ wpx : 120 },{ wpx : 90 },{ wpx : 150 },{ wpx : 150 },{ wpx : 90 },{ wpx : 110 },{ wpx : 50 }];
      this.delete_row(wb.Sheets.Sheet1, 1,val);
      /* save to file */
      XLSX.writeFile(wb, title + ".xlsx");
  }
  delete_row (ws, row_index,val){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['I1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  Details(ProductID) {
    sessionStorage.ProductID = ProductID;
    this.router.navigate(['/eccn']);
  }
  print(MainID,SearchID,Title) {
    var Content = document.getElementById(MainID).innerHTML;
    this.printService.Print(Content,Title,SearchID,true);
  }

}
