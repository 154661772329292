import { Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../../services/printcommon.service';
import { Router} from '@angular/router';
import {DashboardProducts} from "../../../model/DashboardData";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import { SearchModel } from '../../../model/PhaseDetails';
import {SelectItem} from 'primeng/api';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-productsdocuments',
  templateUrl: './productsdocuments.component.html',
  styleUrls: ['./productsdocuments.component.scss']
})
export class ProductsdocumentsComponent implements OnInit {
  ProductCount:number=0;
  DocumentCount:number = 0;
  loading: "loading";
  showLoading:boolean;
  ProductGUID = sessionStorage.getItem('ReportProductGUID');
  productName:any="";
  dieCode:any="";
  productOwner:any="";
  productline:any="";
  mfgPart:any="";
  productStatus:any="";
  currentPhase:any="";
  projectDescription:any="";
  currentphasestatus:any="";

  searchModel: SearchModel;
  cols: any[];
  products: DashboardProducts[];
  selectedProducts: DashboardProducts[];
  @ViewChild('dt') table: Table;
  productaccordian : boolean;
  attcols:any[];
  attributes: any[];
  selectedattributes: any[];
  @ViewChild('dtatt') atttable: Table;

  attdisplay:boolean = false;
  status: SelectItem[];
  line: any[];
  l:string[];
  selectedLine:any[]=[];
  selectedStatus:any[]=[];
  userId = sessionStorage.getItem("userGuid");
  userintId = sessionStorage.getItem("userId");
constructor(
  private _facadeService: FacadeService,
  public printService: PrintcommonService,
  private router: Router,
  @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
) {}

ngOnInit() {
  this.showLoading = true;
  this.productaccordian = true;
  
  this.attcols = [
    { field: 'phaseName', header: 'Phase' },
    { field: 'departmentName', header: 'Department' },
    { field: 'documentName', header: 'Task' },
    // { field: 'isRequired', header: 'Task Required' },
    // { field: 'documentLabel', header: 'Document Label' },
    // { field: 'templates', header: 'Templates' },
    { field: 'displayName', header: 'Document' },
    { field: 'addedByName', header: 'Added By' },
    { field: 'addedDate', header: 'Added Date' }
  ];
  this.cols = [
    { field: 'productName', header: 'Product' },
    { field: 'dieCode', header: 'Die Code' },
    { field: 'mfgPart', header: 'Mfg Part' },
    { field: 'productLine', header: 'Product Line' },
    { field: 'productOwner', header: 'Product Engineer' },
    { field: 'productStatus', header: 'Product Status' },
    { field: 'phase', header: 'Phase' },
    { field: 'phaseStatus', header: 'Phase Status' },
  ];
  sessionStorage.productName = "";
  sessionStorage.dieCode = "";
  sessionStorage.mfgPart = "";
  sessionStorage.productLine = "";
  sessionStorage.productOwner = "";
  sessionStorage.productStatus = "";
  sessionStorage.phase = "";
  sessionStorage.phaseStatus = "";

  sessionStorage.phaseName = "";
  sessionStorage.departmentName = "";
  sessionStorage.documentName = "";
  sessionStorage.displayName = "";
  sessionStorage.addedByName = "";
  sessionStorage.addedDate = "";
  this.status = [ 
    {label: 'New', value: 'New'},
    {label: 'In-Process', value: 'In-Process'},
    {label: 'Completed', value: 'Completed'},
    {label: 'EOL', value: 'EOL'},
    {label: 'OBS', value: 'OBS'},
    {label: 'Waiting for Discontinuation', value: 'Waiting for Discontinuation'},
    {label: 'Seek Clarification for Discontinuation', value: 'Seek Clarification for Discontinuation'},
    {label: 'Hold', value: 'Hold'},
    {label: 'Discontinued', value: 'Discontinued'},
  ];
  let ok = false;
  if(sessionStorage.getItem('reportdocstatus') != null && sessionStorage.getItem('reportdocstatus') != undefined)
  {
    var projectstats = [];
    projectstats = sessionStorage.getItem('reportdocstatus').split(',');
    projectstats.forEach((element) => {
      this.selectedStatus.push(element);
    });
    ok = true;
  }
  if(!ok)
  {
    this.selectedStatus.push('New');
    this.selectedStatus.push('In-Process');
    // this.selectedStatus.push('Completed');
    this.selectedStatus.push('Waiting for Discontinuation');
    this.selectedStatus.push('Seek Clarification for Discontinuation');
    sessionStorage.reportdocstatus = this.selectedStatus;
  }
  this.GetProductLne();
}
Count()
{
  if(this.products != undefined && this.products != null)
  {
    var temp = this.products;
    if(sessionStorage.productName != "")
      temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
    if(sessionStorage.dieCode != "")
      temp = temp.filter(x=> x.dieCode.toLowerCase().startsWith(sessionStorage.dieCode.toLowerCase()));
    if(sessionStorage.mfgPart != "")
      temp = temp.filter(x=> x.mfgPart.toLowerCase().startsWith(sessionStorage.mfgPart.toLowerCase()));
    if(sessionStorage.phase != "")
      temp = temp.filter(x=> x.phase.toLowerCase().startsWith(sessionStorage.phase.toLowerCase()));
    if(sessionStorage.phaseStatus != "")
      temp = temp.filter(x=> x.phaseStatus.toLowerCase().startsWith(sessionStorage.phaseStatus.toLowerCase()));
    if(sessionStorage.productLine != "")
      temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine.toLowerCase()));
    if(sessionStorage.productOwner != "")
      temp = temp.filter(x=> x.productOwner.toLowerCase().startsWith(sessionStorage.productOwner.toLowerCase()));
    if(sessionStorage.productStatus != "")
      temp = temp.filter(x=> x.productStatus.toLowerCase().startsWith(sessionStorage.productStatus.toLowerCase()));
    this.ProductCount = temp.length;
  }
  else
    this.ProductCount = 0;
}
test(val,field,dt)
{
  if(field == "productName")
    sessionStorage.productName = val;
  else if(field == "dieCode")
    sessionStorage.dieCode = val;
  else if(field == "mfgPart")
    sessionStorage.mfgPart = val;
  else if(field == "phase")
    sessionStorage.phase = val;
  else if(field == "phaseStatus")
    sessionStorage.phaseStatus = val;
  else if(field == "productLine")
    sessionStorage.productLine = val;
  else if(field == "productOwner")
    sessionStorage.productOwner = val;
  else if(field == "productStatus")
    sessionStorage.productStatus = val;
  dt = dt.filter(val,field, 'startsWith');
  this.Count();
}
Count1()
{
  if(this.attributes != undefined && this.attributes != null)
  {
    var temp = this.attributes;
    if(sessionStorage.phaseName != "")
      temp = temp.filter(x=> x.phaseName.toLowerCase().startsWith(sessionStorage.phaseName.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.documentName != "")
      temp = temp.filter(x=> x.documentName.toLowerCase().startsWith(sessionStorage.documentName.toLowerCase()));
    if(sessionStorage.displayName != "")
      temp = temp.filter(x=> x.displayName.toLowerCase().startsWith(sessionStorage.displayName.toLowerCase()));
    if(sessionStorage.addedByName != "")
      temp = temp.filter(x=> x.addedByName.toLowerCase().startsWith(sessionStorage.addedByName.toLowerCase()));
    if(sessionStorage.addedDate != "")
      temp = temp.filter(x=> x.addedDate.toLowerCase().startsWith(sessionStorage.addedDate.toLowerCase()));
    this.DocumentCount = temp.length;
  }
  else
    this.DocumentCount = 0;
}
test1(val,field,dt)
{
  if(field == "phaseName")
    sessionStorage.phaseName = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "documentName")
    sessionStorage.documentName = val;
  else if(field == "displayName")
    sessionStorage.displayName = val;
  else if(field == "addedByName")
    sessionStorage.addedByName = val;
  else if(field == "addedDate")
    sessionStorage.addedDate = val;
  dt = dt.filter(val,field, 'startsWith');
  this.Count1();
}

GetProductLne() {
  this._facadeService.GetProductLines()
  .subscribe((data) => {
    this.line = data.returnObject;
    let ok = false;
    if(sessionStorage.getItem('reportdocline') != null && sessionStorage.getItem('reportdocline') != undefined && sessionStorage.getItem('reportdocline').indexOf("object") <= -1)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('reportdocline').split(',');
      projectstats.forEach((element) => {
        this.line.forEach((ln) => {
          if(ln.id == element)
            this.selectedLine.push(ln);
        });
        // this.selectedLine.push(element);
      });
      ok = true;
    }
    if(!ok)
    {
      this.line.forEach((ln) => {
        this.selectedLine.push(ln);
      });
      var lm=[];
      this.selectedLine.forEach((element) => {
        lm.push(element.id);
      });
      sessionStorage.reportdocline = lm;
    }
    this.getproducts(this.selectedLine,'true');
  },
  (error) => {  console.log(error); this.showLoading = false;  });
}
getproducts(l,Type) {
  this.showLoading = true;
  var lm = [];
  if(Type == 'false')
  {
    this.selectedLine.forEach((element) => {
        lm.push(element.id);
    });
    sessionStorage.reportdocline = lm;
    sessionStorage.reportdocstatus = this.selectedStatus;
  }
  else
  {
    l.forEach((element) => {
      lm.push(element.id);
    });
  }
  this.searchModel={
    productLineID:lm,
    productStatus:this.selectedStatus,
    projectID: "",
    download: ""
  }
  this._facadeService.ReportProductsD(this.searchModel).subscribe(
    (data) => {
        this.products = data.returnObject; 
        this.Count();

        if(this.ProductGUID != undefined && this.ProductGUID != null)
        {
          var row = this.products.filter(m=>m.productGuid.toLowerCase()==this.ProductGUID.toLowerCase());
          this.Product(row);
        }
        console.log(this.products); 
        this.showLoading = false;
    },
    (error) => {  console.log(error); this.showLoading = false;  }
  );
}
download(val) {
  let element = document.getElementById(val); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    if(val == "excel-table")
      ws['!cols'] = [{ wpx : 180 },{ wpx : 100 },{ wpx : 180 },{ wpx : 80 },{ wpx : 130 },{ wpx : 150 },{ wpx : 110 },{ wpx : 130 }];
    if(val == "excel-table1")
      ws['!cols'] = [{ wpx : 100 },{ wpx : 150 },{ wpx : 450 },{ wpx : 600 },{ wpx : 130 },{ wpx : 130 }];

    this.delete_row(wb.Sheets.Sheet1, 1,val);
    /* save to file */
    if(val == "excel-table")
      XLSX.writeFile(wb, "Products.xlsx");
    if(val == "excel-table1")
      XLSX.writeFile(wb,this.productName + " (" + this.mfgPart + ") Tasks.xlsx");
  }

  delete_row (ws, row_index,val){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    if(val == "excel-table")
      delete (ws['I1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  
Details(ProductID, PhaseID,Type) {
  sessionStorage.ProductID = ProductID;
  sessionStorage.PhaseID = PhaseID;
  sessionStorage.DisplayType = Type;
  sessionStorage.FromWhere = "doc";
  this.router.navigate(['/phasedetails']);
}
print(MainID,SearchID) {
  var HideLastColumn = true;
  var Content = document.getElementById(MainID).innerHTML;
  if(MainID == "invoice-content1")
  {
    Content = '<br>' + document.getElementById("productdetails").innerHTML + '<br>' + Content;
    HideLastColumn = false;
  }
  var Title = "";
  if(MainID == "invoice-content")
    Title =  "Products";
  if(MainID == "invoice-content1")
    Title = this.productName + " (" + this.mfgPart + ") Tasks";
  this.printService.Print(Content,Title,SearchID,HideLastColumn);
}
SelectProduct(row)
{
  sessionStorage.ReportProductGUID = row.productGuid;
  location.reload();
}
Product(row)
{
  this.showLoading = true;
  this.attributes = [];
  this.attdisplay = false;
  this.productaccordian = true;
  this.productName="";
  this.dieCode="";
  this.productOwner="";
  this.productline="";
  this.mfgPart="";
  this.productStatus="";
  this.currentPhase="";
  this.projectDescription="";
  this.currentphasestatus="";
  let ok = false;
  if (this.products.length > 0)
  {
    this.products.forEach(element => {
      if(row.length > 0)
      {
        if(element.productGuid != row[0].productGuid)
          element.select = false;
        else
        {
          element.select = true;
          ok = true;
        }
      }
    });
  }
  if(ok)
  {
    this.attdisplay = true;
    this.productaccordian = false;
    this.productName=row[0].productName;
    this.dieCode=row[0].dieCode;
    this.productOwner=row[0].productOwner;
    this.productline=row[0].productLine;
    this.mfgPart=row[0].mfgPart;
    this.productStatus=row[0].productStatus;
    this.currentPhase=row[0].phase;
    this.projectDescription=row[0].projectDescription;
    this.currentphasestatus=row[0].phaseStatus;

    this._facadeService.GetReportDocuments(row[0].productGuid,this.userintId).subscribe(
      (data) => {
          this.attributes = data.returnObject;
          this.attributes.forEach((value, key) => {
            if ( value.addedDate !== null &&  value.addedDate !== undefined )
            value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
          });
          this.Count1();
          this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  }
    );
  }
}
DMSToken(rowData){
  this.showLoading = true;
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadDMSFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');
}
}
