<div class="p-col-12">
    <div class="card">
        <div class="card-header" style=" border-radius: 5px;height:40px; margin-bottom:5px; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
            <span class="p-input-icon-left" style="float:right; margin-right:5px;">  
                
                <label style="padding-right:10px; margin-left:10px;">Product Line:</label>
           <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Line"  optionLabel="name" [options]="line" [(ngModel)]="selectedLine" >
                <ng-template let-value pTemplate="selectedItems">
                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                        <div>option.name</div>
                    </div>
                    <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                        Select Product Line
                    </div>
                </ng-template>
            </p-multiSelect> 
            <label style="padding-right:10px; margin-left:10px;">Product Status:</label>
            <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Status" [options]="status" [(ngModel)]="selectedStatus">
                <ng-template let-value pTemplate="selectedItems">
                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                        <div>option.name</div>
                    </div>
                    <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                        Select Product Status
                    </div>
                </ng-template>
            </p-multiSelect>
              <a (click)="getproducts('','false')">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" class="p-button-raised p-button-primary "  style="width:auto; float:right; margin-left:10px;"></button>
            </a>
         </span>
        </div> 
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Product Tasks</h5> 
        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('invoice-content','trSearch')" 
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left:15px; width:25px; height:25px;" (click)="download('excel-table')"></button>
        </span>
    </div>
    <p-accordion>
        <p-accordionTab header="Products - {{ProductCount}}" [selected]="productaccordian" >
    <div class="invoice invoice-header" id="invoice-content">           
 <p-table #dt [value]="products" [columns]="cols" sortMode="multiple" id="excel-table" [loading]="loading" [autoLayout]="true"
styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedProducts"
[globalFilterFields]="['productName','dieCode','mfgPart','productStatus','productLine','productOwner','phase','phaseStatus']"  dataKey="productID"
[scrollable]="true" scrollHeight="500px" > 
<ng-template pTemplate="header" let-columns class="invoice-items">
<tr>          
    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
    [ngClass]="{'dieCode': i == 1, 'productLine': i == 3,  'phase': i == 6,'w':i==0 || i == 2 || i == 4 || i == 5,'w1':i==7}" >
    <!-- [ngClass]="{'productName': i == 0,'dieCode': i == 1,'mfg':i==2, 'productLine': i == 3,  'phase': i == 6}" > -->
    {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
  <th style="text-align: center;width: 80px;">Actions</th>
</tr>
<tr id="trSearch">
  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
    <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
</th>
  <th></th>
</tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns">
<tr [pSelectableRow]="rowData" title="Select" (click)="SelectProduct(rowData)" [style.background]="rowData.select == true ? '#e8ccc2' : '' ">
  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width:15%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 1" style="text-align: left; padding-left:15px;width:100px;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width:15%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:110px;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 4" style="text-align: left; padding-left:15px; width:15%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:15%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 6" style="text-align: center; width:140px;font-weight: bold;" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td> 
    <td *ngIf="i == 7" style="text-align: left; padding-left:15px; width:10%;">{{ rowData[col.field] }}</td>
  </ng-container>
  <td style="text-align: center;width: 80px;">
    <button pButton type="button" pTooltip="View"  tooltipPosition="right"  (click)="Details(rowData.productID, rowData.phaseID,rowData.type)"
    icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
  </td>
</tr>
</ng-template>
<ng-template pTemplate="emptymessage">
<tr>
    <td colspan="9" style="text-align: left;">No records found...</td>
</tr>
</ng-template>

 </p-table>
    </div>
</p-accordionTab>
</p-accordion>
<div *ngIf="attdisplay">
    <div id="productdetails">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12"></div>
        <div class="p-col-12 p-md-4 p-xl-4">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                <ul class="widget-image-list">
                <li>
                    <span style="font-weight: bold;">Project:</span>
                    <span>{{ productName }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Die Code:</span>
                    <span>{{ dieCode }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Product Engineer:</span>
                    <span>{{ productOwner }}</span>
                </li>
                </ul>
            </div>
        </div>
        <div class="p-col-12 p-md-4 p-xl-4">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                <ul class="widget-image-list">
                <li>
                    <span style="font-weight: bold;">Product Line:</span>
                    <span class="listitem-value">{{ productline }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Mfg Part:</span>
                    <span>{{ mfgPart }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Project Status:</span>
                    <span>{{ productStatus }}</span>
                </li>
                </ul>
            </div>
        </div>
        <div class="p-col-12 p-md-4 p-xl-4">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px; overflow-y: auto;">
                <ul class="widget-image-list">
                <li>
                    <span style="font-weight: bold;">Current Phase:</span>
                    <span class="listitem-value">{{ currentPhase }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Project Description:</span>
                    <span>{{ projectDescription }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Current Phase Status:</span>
                    <span>{{ currentphasestatus }}</span>
                </li>
                </ul>
            </div>
        </div>
    </div>
    </div>

    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Tasks - {{DocumentCount}}</h5> 
        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('invoice-content1','trSearch1')" 
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left:15px; width:25px; height:25px;" (click)="download('excel-table1')"></button>
        </span>
    </div>

    <div class="invoice invoice-header" id="invoice-content1" >     
        <p-table #dtatt [value]="attributes" id="excel-table1" [columns]="attcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
            styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedattributes"> 
            <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
            </tr>
            <tr id="trSearch1">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                <input pInputText type="text" (input)="test1($event.target.value,col.field,dtatt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
            </th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr class="p-selectable-row">
                    <!-- [style.background]="rowData.isRequired == 'Yes' ? 'lightblue' : '' " -->
                    <!-- <td style="text-align:left; padding-left:15px;"><span>{{rowData.phaseName}}</span></td>-->
                    <td style="text-align:left; padding-left:15px;font-weight: bold;" [ngStyle]="{'color': rowData.phaseName === 'P0' ? '#c14a09': rowData.phaseName === 'Review' ? '#FFA500' : rowData.phaseName === 'P1' ? '#008000' : rowData.phaseName === 'P2' ?  '#0000FF': rowData.phaseName === 'P3 RTS' ? '#3b2820': rowData.phaseName === 'P3 Production' ? '#800080': rowData.phaseName === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData.phaseName}}</td>
                    <td style="text-align:left; padding-left:15px;"><span>{{rowData.departmentName}}</span></td>
                    <td style="text-align:left; padding-left:15px;"><span>{{rowData.documentName}}</span></td>
                    <!-- <td style="text-align: center;"><span>{{ rowData.isRequired }} </span></td> -->
                    <!-- <td style="text-align: center;"><span>{{rowData.documentLabel }}</span></td> -->
                    <!-- <td style="text-align:left; padding-left:15px;">
                        <ul *ngFor="let temp of rowData.templates" class="tempul">
                            <li class="templi"><a target="_blank" href="{{ DMSURL }}{{temp.templatePath}}"> {{temp.templateName}} </a></li>
                        </ul>
                    </td> -->
                    <td style="text-align: left; padding-left:15px;">
                        <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="DMSToken(rowData)">{{rowData.displayName}} </a> </span>
                        <span *ngIf="rowData.isDocAccess!=true">{{rowData.displayName}}</span>
                    </td>
                    <td style="text-align: center"><span>{{rowData.addedByName}}</span></td>
                    <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6" style="text-align: left;">No records found...</td>
            </tr>
            </ng-template>
        </p-table>
       </div>
    </div>
    </div>
</div>


<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !important;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>