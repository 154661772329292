import { Injectable } from '@angular/core';
import { HttputilityService } from "./httputility.service";
import { DashboardService } from "./dashboard.service";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private _httputilityService: HttputilityService,private _dashboardservice: DashboardService) { }

  IsOwner(Type,ID,UserID){
    return this._httputilityService.Get("Team/IsOwner?Type="+ Type+ "&ID="+ID+"&UserID="+UserID,"");
  }

  GetPhasesbyID(ProductID){
    return this._httputilityService.Get("Team/GetPhasesbyID?ProductID="+ ProductID,"");
  }

  GetStagesbyID(PhaseID){
    return this._httputilityService.Get("Team/GetStagesbyID?PhaseID="+ PhaseID,"");
  }

  GetTeam(Type,TeamType,ID){
    return this._httputilityService.Get("Team/GetTeam?Type="+Type+"&TeamType="+TeamType+"&ID="+ ID,"");
  }

   getCancelTeamData(ProductLineID) {
    return this._httputilityService.Get("Team/GetCancelTeam?ProductLineID="+ProductLineID,"");
  }

  UpdateCancelTeam(model,ProductLineID){
    return this._httputilityService.Add("Team/UpdateCancelTeam?ProductLineID="+ProductLineID, model);
  }

  getCRBData(ProductLineID,Type) {
    return this._httputilityService.Get("CR/GetCRB?ProductLineID="+ProductLineID+"&Type="+Type,"");
  }

  UpdateCRB(model,ProductLineID,Type){
    return this._httputilityService.Add("CR/UpdateCRB?ProductLineID="+ProductLineID+"&Type="+Type, model);
  }

  UpdateTeam(model){
    return this._httputilityService.Add("Team/UpdateTeam", model);
  }

  getDepartments() {
    return this._httputilityService.GetU("user/GetDepartments","");
  }

  getRolesByDepartmentGuid(departmentGuid){
    return this._httputilityService.GetU("user/GetRolesByDepartment?departmentGuid="+departmentGuid,"");
  }  

  GetUsersByRoleGuid(RoleGUID){
    return this._httputilityService.GetU("user/GetUsersByRole?RoleGUID="+RoleGUID,"");
  }

  getRoles() {
    return this._httputilityService.GetU("user/GetRoles","");
  }

  ReportProducts(model) {
    var timeZone = sessionStorage.getItem('timeZone');
    if(timeZone == null)
    {
      this._dashboardservice.GetUserDetails(sessionStorage.getItem('userGuid'))
      .subscribe(
         (data) => {
        var userDetails = data.returnObject;
        sessionStorage.timeZone = userDetails.timeZone;
        timeZone = userDetails.timeZone;
      });
    }
    return this._httputilityService.Add("Report/GetProducts?TimeZone="+timeZone,model);
  }

  GetProductsAttributes(model) {
    return this._httputilityService.Add("Report/GetProductsAttributes",model);
  }
  GetAllProductsAttributes(model) {
    return this._httputilityService.downloadFile("customfields/GetAllProductsAttributes",model);
  }
  
  GetReportTeam(ProductID)
  {
    return this._httputilityService.Get("Report/GetTeam?ProductID=" + ProductID,"");
  }
  GetReportDocuments(ProductID,UserID)
  {
    return this._httputilityService.Get("Report/GetReportDocuments?ProductID=" + ProductID+"&UserID="+UserID,"");
  }

}
