import { Injectable } from '@angular/core';
import { HttputilityService } from '../services/httputility.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GetDataQuery } from '../model/CustomFields';

@Injectable({
  providedIn: 'root'
})
export class ManageCustomFieldsService {

  constructor(private _HttputilityService : HttputilityService) { }

  GetProductByUser(userGuid){
    return this._HttputilityService.Get('Product/GetProductsbyUser?UserID='+ userGuid,'');
  }  

  GetAllActiveProducts(){
    return this._HttputilityService.Get('Product/GetAllActiveProducts','');
  }  

  GetCustomGroups(){
    return this._HttputilityService.Get('CustomFields/GetCustomGroups' ,'');
  }

  GetFieldsByGroupID(GroupID, ProductID, ApplicationID){
    return this._HttputilityService.Get('CustomFields/GetFieldsByGroupID?GroupID='+ GroupID +'&ProductID='+ ProductID +'&ApplicationID='+ ApplicationID ,'');
  }

  SaveCustomFields(model){
    return this._HttputilityService.Add('CustomFields/SaveCustomFields', model);
  }

  DataSourceAsQuery(datasource){
    return this._HttputilityService.Get('CustomFields/Getdata?Query='+ datasource, '');
  }

  GetHistory(ProductID,CustomFieldID){
    return this._HttputilityService.Get('CustomFields/GetHistory?ProductID='+ ProductID + '&CustomFieldID=' + CustomFieldID, '');
  }

  GetFieldsByProductID(ProductID){
    return this._HttputilityService.Get('CustomFields/GetFieldsByProductID?ProductID='+ ProductID, '');
  }

  GetDataDependent(datasource,Value){
    return this._HttputilityService.GetU('customfields/GetDataDependent?Query='+ datasource + "&Value=" + Value, '');
  }
  GetDependentAttributes(CustomFieldID){
    return this._HttputilityService.GetU('customfields/GetDependentAttributes?CustomFieldID='+ CustomFieldID, '');
  }
}
