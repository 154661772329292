import { Component, OnInit, ViewChild} from '@angular/core';
import { Table } from 'primeng/table';
import { PrintcommonService } from '../../services/printcommon.service';
import { Router} from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-productcreation',
  templateUrl: './productcreation.component.html',
  styleUrls: ['./productcreation.component.scss']
})
export class ProductcreationComponent implements OnInit {
    loading: "loading";
    showLoading:boolean;
    cols: any[];
    productsData:any=[];
    @ViewChild('dt') table: Table;
    ProductCount:number=0;
    userFeature:boolean;
    userFeature1:boolean;
constructor(
    public printService: PrintcommonService,
    private _productService:ProductService,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  
  ngOnInit() {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PDM_DSBL_NEW_VIEW");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    sessionStorage.tmpProductId=0;
    this.cols = [
      { field: 'productLine', header: 'Product Line' },
      { field: 'productName', header: 'Product' },
      { field: 'templateName', header: 'Template' },
    ];
    sessionStorage.productLine = "";
    sessionStorage.productName = "";
    sessionStorage.templateName = "";
    this.GetTmpProducts();
  }
  test(val,field,dt)
  {
    if(field == "productName")
      sessionStorage.productName3= val;
    else if(field == "productLine")
      sessionStorage.productLine = val;
    else if(field == "templateName")
      sessionStorage.templateName = val;
    dt = dt.filter(val,field, 'startsWith');
    this.Count();
  }
  Count()
  {
    if(this.productsData != undefined && this.productsData != null)
    {
    var temp = this.productsData;
    if(sessionStorage.productName != "")
      temp = temp.filter(x=> x.productName.toLowerCase().startsWith(sessionStorage.productName.toLowerCase()));
    if(sessionStorage.productLine != "")
      temp = temp.filter(x=> x.productLine.toLowerCase().startsWith(sessionStorage.productLine.toLowerCase()));
    if(sessionStorage.templateName != "")
      temp = temp.filter(x=> x.templateName.toLowerCase().startsWith(sessionStorage.templateName.toLowerCase()));
    this.ProductCount = temp.length;
    }
    else
    this.ProductCount = 0;
  }

  GetTmpProducts(){
    this.showLoading = true;
    this._productService.GetTmpProducts("P").subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0 && data.returnObject!=null){
          this.productsData=data.returnObject;
          this.Count();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  ViewProduct(prodId){
    this.router.navigate(['/createproduct',prodId]);
  }
  CreateNew(){
     this.router.navigate(['/createproduct']);
  }

  download() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     ws['!cols'] = [{ wpx : 100 },{ wpx : 180 },{ wpx : 180 }];
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Products to create.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
      }
  }
  range.e.r--
  delete (ws['D1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}


print(SearchID) {
    var Content = document.getElementById('invoice-content').innerHTML;
    var Title = "Products to create";
    this.printService.Print(Content,Title,SearchID,true);
  }
}
