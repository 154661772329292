<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12">
                    <div class="card-header" id="cardheader"
                        style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Product Management Help Guide</h5> 
                       <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                            <button *ngIf="showplus" pButton pRipple type="button" icon="pi pi-plus" pTooltip="Expand" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>

                                <button *ngIf="showminus" pButton pRipple type="button" icon="pi pi-minus" pTooltip="Collapse" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>                           
                        </span>
                    </div><br><br>
                    <!-- <div class="card-header">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">My Documents</h5>                        
                    </div> -->
                    <p-accordion>
                        <p-accordionTab header="Phase" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Phase">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Phase</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Product Details" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProductDetails">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Product Details</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="My Products" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Products">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Products</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ActiveProducts">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Active Products</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#PendingforApproval">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Pending for Approval</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#PendingforClarification">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Pending for Clarification</span>
                                        </div>
                                    </a>
                                </div>   
                            </div>
                                <div class="p-grid p-fluid p-formgrid" style="margin-top:20px;"> 
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ToDiscontinue">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">To Discontinue</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#OnHold">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">On Hold</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Completed">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Completed</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Discontinued">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Discontinued</span>
                                        </div>
                                    </a>
                                </div>  
                            </div>
                            <div class="p-grid p-fluid p-formgrid" style="margin-top:20px;"> 
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Upcoming">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Upcoming</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#PendingUpload">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Pending Upload</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Uploaded">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Uploaded</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Products In-Collaboration" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProductsInCollaboration">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Products In-Collaboration</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="My Approvals" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MyApprovalsForApproval">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">For Approval</span>
                                    </div>
                                    </a>
                                </div>  
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MyApprovalsToDiscontinue">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">To Discontinue</span>
                                    </div>
                                    </a>
                                </div>                            
                            </div>                            
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Reports" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Reports">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Reports</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Admin" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ManageCustomAttributes">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Manage Custom Attributes</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ManageTeam">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Manage Team</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ManageApprovers">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Manage Approvers</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#CreateProduct">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Create Product</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="PLM Admin" [selected]="showexpand" >
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProductDiscontinueApprovers">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Product Discontinue Approvers</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ManageProductEngineer">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Manage Product Engineer</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>

        <div class="card" style="min-height:600px;">
            <div class="p-grid p-formgrid p-fluid">
                <!-- <h3 style="margin-left: 20px;">My Documents</h3><br> -->
                <div id="Phase">
                    <p style="font-size: large;margin-left: 20px;"><strong>Phase:</strong> Part of the product development, every product will go through different phases; here in this application, we can see 6 phases as follows.</p>
                    <img src="assets/gallery/phase.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Product lifecycle starts from phase P0 to EOL (End of life); during each of these phases, the tasks listed need to be completed by assigned users/teams, and the phase needs to get approved with pre-defined approvers from various departments</p>
                    <img src="assets/gallery/phase1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br><br>
                </div>
                <div id="ProductDetails">
                    <p style="font-size: large;margin-left: 20px;"><strong>Product Details:</strong> By clicking on the view icon of the product summary, the user can enter into the product details page; there the user can see the below image which describes the full information of the product.</p>
                    <img src="assets/gallery/productdetails.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, the user can see an “eye” icon; when clicked on this icon, the user can see more information about the product in the modal popup; also, the user can see a grid icon that shows custom fields of the selected product.</p>
                    <img src="assets/gallery/productdetails1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Custom fields of the selected product are shown in the below image, which can be managed.</p>
                    <img src="assets/gallery/productdetails2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">As mentioned earlier every product has to get approved in each phase; once all the required tasks are performed, documents are uploaded successfully, and the phase has been approved, then it moves to the next phase. Here in the below image, the user can see the first phase P0 details.</p> 
                    <img src="assets/gallery/productdetails3.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">In the above image, user can see icons to view more information about Responsible departments, Team, Approvers, Documents and Notes related to the phase. All the above details can be seen in the modal popup by clicking on the view icon.</p>
                    <img src="assets/gallery/productdetails4.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>  
                    <p style="font-size: large;margin-left: 20px;">User can also see the team authorized to the particular phase.</p>
                    <img src="assets/gallery/productdetails5.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>  
                     <p style="font-size: large;margin-left: 20px;">User can also see the pre-defined approvers of the particular phase.</p>
                     <img src="assets/gallery/productdetails6.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                     <br>  
                    <p style="font-size: large;margin-left: 20px;">Upon completing tasks, user can upload the related documents from their system (desktop/laptop) or from DMS</p>
                    <img src="assets/gallery/productdetails7.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>  
                    <p style="font-size: large;margin-left: 20px;">If required, the user can also add some notes related to the above phase or product.</p>
                    <img src="assets/gallery/productdetails8.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">Under the Phase details, the user can also see the document list of the Product. Once a task is completed, the user can click on the Document icon to upload the related document. Once the document has been uploaded, the icon’s colour turns from Blue to Green which indicates that a document has been uploaded. Similarly, the Notes icon’s colour changes from Blue to Green once notes are added. </p>
                    <p style="font-size: large;margin-left: 20px;">From this screen, the user can also see the tasks that are mandatory and optional.</p>
                    <img src="assets/gallery/productdetails9.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                <div id="Products">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Products > Products:</strong> Products have been categorized into 8 categories.</p>
                        <p style="font-size: large;margin-left: 20px;">• Active</p>
                        <p style="font-size: large;margin-left: 20px;">• Pending Approval </p>
                        <p style="font-size: large;margin-left: 20px;">• Pending Clarification</p>
                        <p style="font-size: large;margin-left: 20px;">• To Discontinue</p>
                        <p style="font-size: large;margin-left: 20px;">• On Hold</p>
                        <p style="font-size: large;margin-left: 20px;">• Completed</p>
                        <p style="font-size: large;margin-left: 20px;">• Discontinued</p>
                        <p style="font-size: large;margin-left: 20px;">• Upcoming </p>
                    <br><br>
                </div> 
                <div id="ActiveProducts">
                    <p style="font-size: large;margin-left: 20px;"><strong>Active Products:</strong> A product that is active and In-Process is called an active product, this may be at any of the phases from P0 to EOL. In the below grid we can find all active products and with different phases.</p>
                    <img src="assets/gallery/activeproducts.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">If you click on the “eye” icon, full information about the product is displayed.</p>
                     <img src="assets/gallery/activeproducts1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>     
                <div id="PendingforApproval">
                    <p style="font-size: large;margin-left: 20px;"><strong>Pending For Approval:</strong> Products that are sent for approval but not yet approved will be listed under the Pending for approval category. </p>
                    <img src="assets/gallery/pendingforapproval.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                <div id="PendingforClarification">
                    <p style="font-size: large;margin-left: 20px;"><strong>Pending For Clarification:</strong> When Products sent for approval, Approver can seek clarification from the product owner; these products will be listed under Pending for clarification category. </p>
                    <img src="assets/gallery/pendingforclarification.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="ToDiscontinue">
                    <p style="font-size: large;margin-left: 20px;"><strong>To Discontinue:</strong> For various reasons Products get discontinued forever, so the identified products which are ready to discontinue will be listed under the category of To Discontinue Products.</p>
                    <img src="assets/gallery/todiscontinue.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>    
                <div id="OnHold">
                    <p style="font-size: large;margin-left: 20px;"><strong>On Hold:</strong> For various reasons, Products are put on “hold” for a certain duration; during the hold period these products are shown under the category of On-Hold Products.</p>
                   <img src="assets/gallery/onhold.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="Completed">
                    <p style="font-size: large;margin-left: 20px;"><strong>Completed:</strong> Products that have completed all phases and reached the final phase EOL (End of Life) is called Completed; from here this product gets aborted or upgraded as another product with additional features.</p>
                     <img src="assets/gallery/completed.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="Discontinued">
                    <p style="font-size: large;margin-left: 20px;"><strong>Discontinued:</strong> Products that are discontinued for various reasons will be listed here. </p>
                   <img src="assets/gallery/discontinued.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="Upcoming">
                    <p style="font-size: large;margin-left: 20px;"><strong>Upcoming:</strong> Products that are not yet started and may start in the future are called Upcoming Products. It’s an imaginary idea to start the product. These kinds of product details will be listed here.</p>
                   <img src="assets/gallery/upcoming.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  

                <div id="PendingUpload">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Products > Documents:</strong> Product-related documents are required to be uploaded for the approval part; documents that are not uploaded yet are called Pending Upload; Documents that are uploaded in time are called Uploaded Documents.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Pending Upload:</strong></p>
                    <img src="assets/gallery/pendingupload.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="Uploaded">
                    <p style="font-size: large;margin-left: 20px;"><strong>Uploaded Documents:</strong></p>                    
                    <img src="assets/gallery/uploadeddocuments.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="ProductsInCollaboration">
                    <p style="font-size: large;margin-left: 20px;"><strong>Products In-Collaboration > Products:</strong> Products In-Collaboration have been categorized into 8 categories.</p>
                    <p style="font-size: large;margin-left: 20px;">• Active</p>
                    <p style="font-size: large;margin-left: 20px;">• Pending Approval</p> 
                    <p style="font-size: large;margin-left: 20px;">• Pending Clarification</p>
                    <p style="font-size: large;margin-left: 20px;">• To Discontinue</p>
                    <p style="font-size: large;margin-left: 20px;">• On Hold</p>
                    <p style="font-size: large;margin-left: 20px;">• Completed</p>
                    <p style="font-size: large;margin-left: 20px;">• Discontinued</p>
                    <p style="font-size: large;margin-left: 20px;">• Upcoming</p>
                    <p style="font-size: large;margin-left: 20px;">A description of these categories was presented in the preceding sections.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Products In-Collaboration > Documents:</strong> Products In-Collaboration-related documents have to be uploaded for the approval part; documents that were not uploaded yet are called Pending Upload; Documents that are uploaded in time are called Uploaded Documents.</p>
                    <br><br>
                </div>  
                <div id="MyApprovalsForApproval">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Approval > For Approval:</strong> Products that are waiting for the logged-in user are listed in this section as follows.</p>
                   <img src="assets/gallery/myapprovalsforapproval.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="MyApprovalsToDiscontinue">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Approval > To Discontinue:</strong> Products that are listed to be discontinued by the logged-in user are shown under the “To Discontinue” category.</p>
                   <img src="assets/gallery/myapprovalstodiscontinue.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="Reports">
                    <p style="font-size: large;margin-left: 20px;"><strong>Reports:</strong> A sample Product summary report is shown below. Here we can search with the available filter options - Product Line and Product Status.</p>
                   <img src="assets/gallery/reports.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="ManageCustomAttributes">
                <p style="font-size: large;margin-left: 20px;"><strong>Admin:</strong> Administrator has all privilege to Manage Attributes, Team, Approvers and Product Creation.</p>
                <p style="font-size: large;margin-left: 20px;">• Manage Custom Attributes</p>
                <p style="font-size: large;margin-left: 20px;">• Manage Team</p>
                <p style="font-size: large;margin-left: 20px;">• Manage Approvers</p>
                <p style="font-size: large;margin-left: 20px;">• Product Creation</p>
                <br>
                <p style="font-size: large;margin-left: 20px;"><strong>Manage Custom Attributes:</strong> When the user clicks on this menu, the user can reach Manage Custom Attributes page. The Administrator has all privileges; the admin can manage custom fields related to the Product and its Group. Additional attributes will be there for every product; those attributes can be modified or customized according to the product; some of the attribute values have to be entered as a text, and some of the attribute values can be selected from the pre-defined list.</p>
                <img src="assets/gallery/managecustomattributes.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                <br>
                <p style="font-size: large;margin-left: 20px;">When the user selects any particular Project and Group, then related attributes are listed for possible modifications.</p>
                <img src="assets/gallery/managecustomattributes1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                <br>
                <img src="assets/gallery/managecustomattributes2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                <br><br>
                </div>  
                <div id="ManageTeam">
                    <p style="font-size: large;margin-left: 20px;"><strong>Manage Team:</strong> The User can click on the menu to reach the Manage Team page. The Administrator has all privileges, so he/she can add or delete the team member. In the below images, when the user selects a Product, an appropriate phase will be displayed.</p>
                    <img src="assets/gallery/manageteam.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Here Admin can manage the team, Project wise and Phase wise.</p>
                    <img src="assets/gallery/manageteam1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br> 
                    <img src="assets/gallery/manageteam2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="ManageApprovers">
                    <p style="font-size: large;margin-left: 20px;"><strong>Manage Approvers:</strong> By clicking through the menu, the user can reach the Manage Approvers page. Product moves from phase to the next phase when it is fully approved by pre-defined approvers; if it is not approved or canceled, then the product will not go further, these approvals or cancellations will be done by the pre-defined approvers; Approvers are managed by the admin from here. </p>
                    <img src="assets/gallery/manageapprovers.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, you can see the Product and its Phase, also the Product Type and its Stage if available. By selecting the fields, we get the existing list that has already been added.</p>
                    <img src="assets/gallery/manageapprovers1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>  
                <div id="CreateProduct">
                    <p style="font-size: large;margin-left: 20px;"><strong>Product Creation:</strong> From the menu, the user can reach the Product Creation page. After creating the Product, Admin will assign this product-to-Product Engineer.</p>
                    <p style="font-size: large;margin-left: 20px;">Product Creation will be in 4 step wizards. If the Product creation is incomplete or saved for resuming later, then that product will be listed here on this page.</p>
                    <p style="font-size: large;margin-left: 20px;">If we click on the +Create New button, then the Product creation wizard will open.</p>
                    <img src="assets/gallery/createproduct.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">4 Step wizard will have </p>
                    <p style="font-size: large;margin-left: 20px;">• Product Header</p>
                    <p style="font-size: large;margin-left: 20px;">• Phases and Teams</p>
                    <p style="font-size: large;margin-left: 20px;">• Phases, Milestones and Documents</p>
                    <p style="font-size: large;margin-left: 20px;">• Preview and Confirmation</p>                        
                    <p style="font-size: large;margin-left: 20px;">Step1: Project Header will be there,</p>
                    <img src="assets/gallery/createproduct1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the following wizard window, the Product Line needs to be Provided, according to the Product Line Template will be binded. Once the user selects the Template Project, Attributes will be binded automatically. Here, the user must provide Project Name and Target Start Date</p>
                    <img src="assets/gallery/createproduct2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                     <p style="font-size: large;margin-left: 20px;">Step2: Phases and Teams will be there, in this step Team, Approvers, and Notification Recipients should be defined.</p>
                    <img src="assets/gallery/createproduct3.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>                
                    <p style="font-size: large;margin-left: 20px;">From here, the user can remove or add Approvers. These approvers will approve/reject the phases.</p>
                    <img src="assets/gallery/createproduct4.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>                
                    <p style="font-size: large;margin-left: 20px;">In the third step Phases, Milestones and Documents needs to be defined.</p>
                    <p style="font-size: large;margin-left: 20px;">These milestones will be updated in Project Management</p>    
                    <img src="assets/gallery/createproduct5.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">These documents can be uploaded at the Phase level at Product Management.</p>
                    <img src="assets/gallery/createproduct6.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Step4: Preview and Confirmation is the combination of the first 3 steps. Whatever the updates are given there are previewed here; after that, If you click on confirm, then the Product will be created.</p>
                    <img src="assets/gallery/createproduct7.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Once the product is created, then it will be visible under the “My Products” menu of the Product Engineer to whom the Product is been assigned.</p>
                <br><br>
                </div>  
                <div id="ProductDiscontinueApprovers">
                    <p style="font-size: large;margin-left: 20px;"><strong>PLM Admin > Product Discontinue Approvers:</strong> For various reasons Product may get discontinued forever; to discontinue such products, it needs approvals from its approvers, and those approvers need to be pre-defined by the admin.</p>
                    <img src="assets/gallery/productdiscontinueapprovers.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Admin will select the Product Line; then according to the Product line he/she would pre-allocate the user to approve for the product discontinuation.</p>
                    <img src="assets/gallery/productdiscontinueapprovers1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, there is a button called the +Add New button; if you click on the button, you will get extra fields to select the department and role and also the approver name.</p>
                    <img src="assets/gallery/productdiscontinueapprovers2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, Admin has selected Product line as ESIA, then clicked on +Add New button, then some extra fields added down the list. From the selection field of Department, when Marketing is selected, then roles of the Marketing Departments will be loaded automatically. Once the role is selected, then the Names of people who would play that role will be displayed.</p>
                    <img src="assets/gallery/productdiscontinueapprovers3.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, Department Marketing Selected and Role selected as All Marketing Head then the Marketing Head Name Erik Ogren is selected. Once you click on the save button, then the Discontinue Approver for ESIA product line Marketing related will be listed with approvers Erik Ogren.</p>
                    <p style="font-size: large;margin-left: 20px;">From the above, you can not only add the approver, you also can delete the approver by clicking on the - button.</p>
                    <br><br>
                </div>  
                <div id="ManageProductEngineer">
                    <p style="font-size: large;margin-left: 20px;"><strong>PLM Admin > Product Engineer</strong> If Admin has created a Product and assigned it to one Product Engineer, in some cases If he/she wants to assign the same Product/ Project to an additional Product Engineer, then he/she has to enter to this page.</p>
                    <p style="font-size: large;margin-left: 20px;">Here on this page, there is an option to select the Project by its Name. </p>
                    <img src="assets/gallery/manageproductengineer.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By selecting the Project Name, project details will be binded automatically along with the existing Product Engineer Name.</p>
                    <img src="assets/gallery/manageproductengineer1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the Manage Product Engineer page, there is a Dropdown selection from there Admin can select the desired Product Engineer when he/she clicks on +update button, then the New Product Engineer will be Updated to the selected Project.</p>
                    <br><br>
                </div>  

            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-10"></div>
            <div class="p-field p-col-12 p-md-2">
            <a href="#cardheader" >
                <button pButton pRipple type="button" icon="pi pi-arrow-up" pTooltip="Go to Top"
                tooltipPosition="top" class="p-button-rounded p-button-info p-mr-2 p-mb-2"
                style="float:right;height:25px; width:25px; "></button>
                </a>        
        </div>  
    </div>
    </div>
</div>


