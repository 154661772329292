import { Component, OnInit, ViewChild,ViewEncapsulation} from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintService } from '../../services/print.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {Product,CustomFields,ProductFiles,Phases,
PhaseDepartments,PhaseTeam,PhaseRequiredDocuments,List,
ListDepartments,ListTeam,ListRequiredDocuments,
ViewDocuments,ViewNotes,Approval,ApprovalDetails
} from "../../model/PhaseDetails";
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-conditionalreleases',
  templateUrl: './conditionalreleases.component.html',
  styleUrls: ['./conditionalreleases.component.scss']
})
export class ConditionalreleasesComponent implements OnInit {
    showLoading:boolean;

    ConditionalReleaseType:string="";
    QuantityLimit:any;
    TimeLimit:any;
    status:any="New";
    IsManufacturingAndShipping:any;
    Justification:any;
    conditionalDocs:any=[];
    EvalConditionalType = Number(sessionStorage.getItem('ConditionalType'));
    ConditionalID:any;

    approvers: any[];
    ApproveDetails: ApprovalDetails[];
    selectedApprove: ApprovalDetails[];
    @ViewChild('dtApprove') tableApprove: Table;
    Clarification1: any[];
    selectedClarification1: any[];
    @ViewChild('dtClarification1') tableClarification1: Table;
    Clarification1Cols: any[];

    departmentsdisplay:boolean;
    teamdisplay:boolean;
    approvalmembersdisplay:boolean;
    documents:any[];
    viewdoccols: any[];
    viewnotecols: any[];
    popupnote: string;
    userId = sessionStorage.getItem('userGuid');//"2A6E2C91-C022-4743-9691-6FAEC9CEF6E1";
    userintId = sessionStorage.getItem('userId');

    ProductID = Number(sessionStorage.getItem('ProductID'));
    PhaseID = Number(sessionStorage.getItem('PhaseID'));
    ImageName:string = "";
    PhaseColor:string = "";
    loading: "loading";
    
    Product: Product;
    CustomFields: CustomFields[];
    ProductFiles: ProductFiles[];
    projectdisplay:boolean;

    @ViewChild("dtDiscontinueApprove") tablePApproval: Table;
  DiscontinueApproveDetails:any[];
  selectedDiscontinue:any[];
  discontinueapprovers:any[];
  
    PClarification:any[];
    DisplayPClare:boolean=false;
    @ViewChild("dtPClarification") tablePClarification: Table;
    selectedPClarification: any[];
    PClare:boolean=false;

    index: number = 0;
    milestoneindex: number = 0;
    stageindex: number = 0;
    stage1mileindex:number = 0;
    stage2mileindex:number = 0;
    stage3mileindex:number = 0;
    stage1index: number;
    stage2index: number;
    stage3index: number;
    
    documentsdisplay : boolean = false;
    notesdisplay : boolean = false;
  
    AllPhases: Phases[];
    CurrentPhase: Phases;
    CurrentDepartments: PhaseDepartments[];
    selectedDepartments: PhaseDepartments[];
    @ViewChild('dtDepartments') tableDepartments: Table;
    Departmentscols: any[];

    CurrentAllTeam: PhaseTeam[];
    CurrentTeam: PhaseTeam[];
    CurrentApprovalTeam: PhaseTeam[];
    selectedTeam: PhaseTeam[];
    @ViewChild('dtTeam') tableTeam: Table;
    selectedApprovalTeam: PhaseTeam[];
    @ViewChild('dtApprovalTeam') tableApprovalTeam: Table;
    Teamcols : any[];
    ApprovalTeamcols : any[];
    listApprovalMembers : any;
    approvalvalues: string[];

    CurrentRequiredDocuments: PhaseRequiredDocuments[];
    selectedDocs: PhaseRequiredDocuments[];
    @ViewChild('dtDocs') tableDocs: Table;
    
    AllList: List[];
    ListDepartments: ListDepartments[];
    ListTeam: ListTeam[];
    ListRequiredDocuments: ListRequiredDocuments[];
    
    Milestones : List[];
    Stages: List[];

    Clarification: any[];
    selectedClarification: any[];
    @ViewChild('dtClarification') tableClarification: Table;
    ClarificationCols: any[];
    
    //Stage1 begin
  Stage1:List;
  Stage1Departments: ListDepartments[];
  selectStage1Departments: ListDepartments[];
  @ViewChild('dtStage1Departments') tableStage1Departments: Table;

  Stage1ApprovalTeam: ListTeam[];
  selectStage1ApprovalTeam: ListTeam[];
  @ViewChild('dtStage1ApprovalTeam') tableStage1ApprovalTeam: Table;

  Stage1Documents: ListRequiredDocuments[];
  selectedStage1Docs: ListRequiredDocuments[];
  @ViewChild('dtStage1Docs') tableStage1Docs: Table;

  Stage1Milestones : List[];
  //Milestone1 begin
  Stage1Milestone1: List;
  Stage1Milestone1Departments: ListDepartments[];
  selectStage1Mile1Departments: ListDepartments[];
  @ViewChild('dtStage1Mile1Departments') tableStage1Mile1Departments: Table;

  Stage1Mile1ApprovalTeam: ListTeam[];
  selectStage1Mile1ApprovalTeam: ListTeam[];
  @ViewChild('dtStage1Mile1ApprovalTeam') tableStage1Mile1ApprovalTeam: Table;

  Stage1Mile1Documents: ListRequiredDocuments[];
  selectedStage1Mile1Docs: ListRequiredDocuments[];
  @ViewChild('dtStage1Mile1Docs') tableStage1Mile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Stage1Milestone2: List;
  Stage1Milestone2Departments: ListDepartments[];
  selectStage1Mile2Departments: ListDepartments[];
  @ViewChild('dtStage1Mile2Departments') tableStage1Mile2Departments: Table;

  Stage1Mile2ApprovalTeam: ListTeam[];
  selectStage1Mile2ApprovalTeam: ListTeam[];
  @ViewChild('dtStage1Mile2ApprovalTeam') tableStage1Mile2ApprovalTeam: Table;

  Stage1Mile2Documents: ListRequiredDocuments[];
  selectedStage1Mile2Docs: ListRequiredDocuments[];
  @ViewChild('dtStage1Mile2Docs') tableStage1Mile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Stage1Milestone3: List;
  Stage1Milestone3Departments: ListDepartments[];
  selectStage1Mile3Departments: ListDepartments[];
  @ViewChild('dtStage1Mile3Departments') tableStage1Mile3Departments: Table;

  Stage1Mile3ApprovalTeam: ListTeam[];
  selectStage1Mile3ApprovalTeam: ListTeam[];
  @ViewChild('dtStage1Mile3ApprovalTeam') tableStage1Mile3ApprovalTeam: Table;

  Stage1Mile3Documents: ListRequiredDocuments[];
  selectedStage1Mile3Docs: ListRequiredDocuments[];
  @ViewChild('dtStage1Mile3Docs') tableStage1Mile3Docs: Table;
  //Milestone3 End
  //Stage1 end

  //Stage2 begin
  Stage2:List;
  Stage2Departments: ListDepartments[];
  selectStage2Departments: ListDepartments[];
  @ViewChild('dtStage2Departments') tableStage2Departments: Table;

  Stage2ApprovalTeam: ListTeam[];
  selectStage2ApprovalTeam: ListTeam[];
  @ViewChild('dtStage2ApprovalTeam') tableStage2ApprovalTeam: Table;

  Stage2Documents: ListRequiredDocuments[];
  selectedStage2Docs: ListRequiredDocuments[];
  @ViewChild('dtStage2Docs') tableStage2Docs: Table;

  Stage2Milestones : List[];
  //Milestone1 begin
  Stage2Milestone1: List;
  Stage2Milestone1Departments: ListDepartments[];
  selectStage2Mile1Departments: ListDepartments[];
  @ViewChild('dtStage2Mile1Departments') tableStage2Mile1Departments: Table;

  Stage2Mile1ApprovalTeam: ListTeam[];
  selectStage2Mile1ApprovalTeam: ListTeam[];
  @ViewChild('dtStage2Mile1ApprovalTeam') tableStage2Mile1ApprovalTeam: Table;

  Stage2Mile1Documents: ListRequiredDocuments[];
  selectedStage2Mile1Docs: ListRequiredDocuments[];
  @ViewChild('dtStage2Mile1Docs') tableStage2Mile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Stage2Milestone2: List;
  Stage2Milestone2Departments: ListDepartments[];
  selectStage2Mile2Departments: ListDepartments[];
  @ViewChild('dtStage2Mile2Departments') tableStage2Mile2Departments: Table;

  Stage2Mile2ApprovalTeam: ListTeam[];
  selectStage2Mile2ApprovalTeam: ListTeam[];
  @ViewChild('dtStage2Mile2ApprovalTeam') tableStage2Mile2ApprovalTeam: Table;

  Stage2Mile2Documents: ListRequiredDocuments[];
  selectedStage2Mile2Docs: ListRequiredDocuments[];
  @ViewChild('dtStage2Mile2Docs') tableStage2Mile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Stage2Milestone3: List;
  Stage2Milestone3Departments: ListDepartments[];
  selectStage2Mile3Departments: ListDepartments[];
  @ViewChild('dtStage2Mile3Departments') tableStage2Mile3Departments: Table;

  Stage2Mile3ApprovalTeam: ListTeam[];
  selectStage2Mile3ApprovalTeam: ListTeam[];
  @ViewChild('dtStage2Mile3ApprovalTeam') tableStage2Mile3ApprovalTeam: Table;

  Stage2Mile3Documents: ListRequiredDocuments[];
  selectedStage2Mile3Docs: ListRequiredDocuments[];
  @ViewChild('dtStage2Mile3Docs') tableStage2Mile3Docs: Table;
  //Milestone3 End
  //Stage2 end

  //Stage3 begin
  Stage3:List;
  Stage3Departments: ListDepartments[];
  selectStage3Departments: ListDepartments[];
  @ViewChild('dtStage3Departments') tableStage3Departments: Table;

  Stage3ApprovalTeam: ListTeam[];
  selectStage3ApprovalTeam: ListTeam[];
  @ViewChild('dtStage3ApprovalTeam') tableStage3ApprovalTeam: Table;

  Stage3Documents: ListRequiredDocuments[];
  selectedStage3Docs: ListRequiredDocuments[];
  @ViewChild('dtStage3Docs') tableStage3Docs: Table;

  Stage3Milestones : List[];
  //Milestone1 begin
  Stage3Milestone1: List;
  Stage3Milestone1Departments: ListDepartments[];
  selectStage3Mile1Departments: ListDepartments[];
  @ViewChild('dtStage3Mile1Departments') tableStage3Mile1Departments: Table;

  Stage3Mile1ApprovalTeam: ListTeam[];
  selectStage3Mile1ApprovalTeam: ListTeam[];
  @ViewChild('dtStage3Mile1ApprovalTeam') tableStage3Mile1ApprovalTeam: Table;

  Stage3Mile1Documents: ListRequiredDocuments[];
  selectedStage3Mile1Docs: ListRequiredDocuments[];
  @ViewChild('dtStage3Mile1Docs') tableStage3Mile1Docs: Table;
  //Milestone1 End

  //Milestone2 begin
  Stage3Milestone2: List;
  Stage3Milestone2Departments: ListDepartments[];
  selectStage3Mile2Departments: ListDepartments[];
  @ViewChild('dtStage3Mile2Departments') tableStage3Mile2Departments: Table;

  Stage3Mile2ApprovalTeam: ListTeam[];
  selectStage3Mile2ApprovalTeam: ListTeam[];
  @ViewChild('dtStage3Mile2ApprovalTeam') tableStage3Mile2ApprovalTeam: Table;

  Stage3Mile2Documents: ListRequiredDocuments[];
  selectedStage3Mile2Docs: ListRequiredDocuments[];
  @ViewChild('dtStage3Mile2Docs') tableStage3Mile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Stage3Milestone3: List;
  Stage3Milestone3Departments: ListDepartments[];
  selectStage3Mile3Departments: ListDepartments[];
  @ViewChild('dtStage3Mile3Departments') tableStage3Mile3Departments: Table;

  Stage3Mile3ApprovalTeam: ListTeam[];
  selectStage3Mile3ApprovalTeam: ListTeam[];
  @ViewChild('dtStage3Mile3ApprovalTeam') tableStage3Mile3ApprovalTeam: Table;

  Stage3Mile3Documents: ListRequiredDocuments[];
  selectedStage3Mile3Docs: ListRequiredDocuments[];
  @ViewChild('dtStage3Mile3Docs') tableStage3Mile3Docs: Table;
  //Milestone3 End
  //Stage3 end

  //Milestone1 begin
  Milestone1: List;
  Milestone1Departments: ListDepartments[];
  selectMile1Departments: ListDepartments[];
  @ViewChild('dtMile1Departments') tableMile1Departments: Table;

  Mile1ApprovalTeam: ListTeam[];
  selectMile1ApprovalTeam: ListTeam[];
  @ViewChild('dtMile1ApprovalTeam') tableMile1ApprovalTeam: Table;

  Mile1Documents: ListRequiredDocuments[];
  selectedMile1Docs: ListRequiredDocuments[];
  @ViewChild('dtMile1Docs') tableMile1Docs: Table;
  //Milestone1 End
  TasksCount :number = 0;
  //Milestone2 begin
  Milestone2: List;
  Milestone2Departments: ListDepartments[];
  selectMile2Departments: ListDepartments[];
  @ViewChild('dtMile2Departments') tableMile2Departments: Table;

  Mile2ApprovalTeam: ListTeam[];
  selectMile2ApprovalTeam: ListTeam[];
  @ViewChild('dtMile2ApprovalTeam') tableMile2ApprovalTeam: Table;

  Mile2Documents: ListRequiredDocuments[];
  selectedMile2Docs: ListRequiredDocuments[];
  @ViewChild('dtMile2Docs') tableMile2Docs: Table;
  //Milestone2 End

  //Milestone3 begin
  Milestone3: List;
  Milestone3Departments: ListDepartments[];
  selectMile3Departments: ListDepartments[];
  @ViewChild('dtMile3Departments') tableMile3Departments: Table;

  Mile3ApprovalTeam: ListTeam[];
  selectMile3ApprovalTeam: ListTeam[];
  @ViewChild('dtMile3ApprovalTeam') tableMile3ApprovalTeam: Table;

  Mile3Documents: ListRequiredDocuments[];
  selectedMile3Docs: ListRequiredDocuments[];
  @ViewChild('dtMile3Docs') tableMile3Docs: Table;
  //Milestone3 End

  //Milestone4 begin
  Milestone4: List;
  Milestone4Departments: ListDepartments[];
  selectMile4Departments: ListDepartments[];
  @ViewChild('dtMile4Departments') tableMile4Departments: Table;

  Mile4ApprovalTeam: ListTeam[];
  selectMile4ApprovalTeam: ListTeam[];
  @ViewChild('dtMile4ApprovalTeam') tableMile4ApprovalTeam: Table;

  Mile4Documents: ListRequiredDocuments[];
  selectedMile4Docs: ListRequiredDocuments[];
  @ViewChild('dtMile4Docs') tableMile4Docs: Table;
  //Milestone4 End

  //Milestone5 begin
  Milestone5: List;
  Milestone5Departments: ListDepartments[];
  selectMile5Departments: ListDepartments[];
  @ViewChild('dtMile5Departments') tableMile5Departments: Table;

  Mile5ApprovalTeam: ListTeam[];
  selectMile5ApprovalTeam: ListTeam[];
  @ViewChild('dtMile5ApprovalTeam') tableMile5ApprovalTeam: Table;

  Mile5Documents: ListRequiredDocuments[];
  selectedMile5Docs: ListRequiredDocuments[];
  @ViewChild('dtMile5Docs') tableMile5Docs: Table;
  //Milestone5 End
    
    Level: string;
    LevelName: string;
    ViewAddedDocuments: ViewDocuments[];
    selectedViewAddedDocuments: ViewDocuments[];
    @ViewChild('dtViewDocuments') tableViewDocuments: Table;
  
    ViewAddedNotes: ViewNotes[];
    selectedViewAddedNotes: ViewNotes[];
    @ViewChild('dtViewNotes') tableViewNotes: Table;
  
    approveview:boolean=false;
    RemarkError:string = '';
    popupremark:string='';
    AddModel:Approval;
    apiResponse:any;
    ViewOnlyTabs:boolean;
    PrevDisplay:boolean = false;
  prevCR:any[];
  PrevCRName:any;
  prevcrtype:0;
  IsPrevCRDisplay:boolean;
  IsPrevEvalDisplay:boolean;
    constructor(
      private _facadeService: FacadeService,
      public printService: PrintService,
      private router: Router,private pipe: DatePipe,
      @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) {
      this.Product = new Product();
      this.CurrentPhase = new Phases();
      this.listApprovalMembers = "";
      this.approvalvalues = [];
      this.ViewOnlyTabs = false;
    }
    open(val)
    {
      this.router.navigate(['/managecustomfields',{ ProductGuid: val }]);     
    }
    ngOnInit(): void {
        this.showLoading = true;
        this.departmentsdisplay = false;this.teamdisplay = false;this.approvalmembersdisplay=false;this.projectdisplay = false;
        this.documentsdisplay = false; this.notesdisplay = false;this.documentsdisplay = false;
        this.approvalvalues = []; this.Level='';this.LevelName='';this.ViewOnlyTabs = false;this.popupnote = "";
    
        this.getProductDetails();
        this.GetConditionalReleaseDetails();
        
        this.Departmentscols = [
          { field: 'departmentName', header: 'Department' }
        ];
        this.Teamcols = [
          { field: 'userName', header: 'Name' },
          { field: 'roleName', header: 'Role' },
          { field: 'departmentName', header: 'Department' }
        ];
        this.ApprovalTeamcols = [
          { field: 'type', header: 'Type' },
          { field: 'userName', header: 'Name' },
          { field: 'roleName', header: 'Role' },
          { field: 'departmentName', header: 'Department' },
          { field: 'approvedDate', header: 'Approved Date' },
          { field: "shortRemarks", header: "Remarks" },
        ];
        this.ClarificationCols = [
          { field: 'Phase', header: 'Stage / Step' },
          { field: 'askedDate', header: 'Sought Date' },
          { field: 'question', header: 'Clarification' },
          { field: 'askedByName', header: 'Sought By' },
          { field: 'answerDate', header: 'Response Date' },
          { field: 'answer', header: 'Response' },
          { field: 'answerByName', header: 'Response By' }
        ];
        this.approvers = [
          { field: 'type', header: 'Type'}, 
          { field: 'userName', header: 'Name' }, 
          { field: 'roleName', header: 'Role' },
          { field: 'departmentName', header: 'Department' },   
          { field: 'approvalStatus', header: 'Status' },
          { field: 'approvedDate', header: 'Approved Date' },
          { field: 'comments', header: 'Comments' }
        ];
        this.viewdoccols = [
          { field: "addedByName", header: "Added By" },
          { field: "addedDate", header: "Date" },
          { field: "fileName", header: "Document" },
        ];
        this.viewnotecols = [
          { field: "addedByName", header: "Added By" },
          { field: "addedDate", header: "Date" },
          { field: "note", header: "Note" },
        ];
        this.documents = [
          { field: "departmentName",header: "Responsible Department" },
          { field: "documentName", header: "Task" },
          { field: "isRequired", header: "Task Required" },
          { field: "documentLabels", header: "Document Submission" },
          { field: "templates", header: "Template"},
        ];
        sessionStorage.departmentName = "";
        sessionStorage.documentName = "";
        sessionStorage.isRequired = "";
        sessionStorage.documentLabels = "";
        sessionStorage.templates = "";
        this.Clarification1Cols = [
          { field: 'askedDate', header: 'Sought Date' },
          { field: 'question', header: 'Clarification' },
          { field: 'askedByName', header: 'Sought By' },
          { field: 'answerDate', header: 'Response Date' },
          { field: 'answer', header: 'Response' },
          { field: 'answerByName', header: 'Response By' }
        ];
        this.discontinueapprovers = [
          { field: 'actionDate', header: 'Sent Date' },
          { field: 'seq', header: 'Seq' },
          { field: 'userName', header: 'Approver' },
          { field: 'roleName', header: 'Role' },
          { field: 'departmentName', header: 'Department' },
          { field: 'approvalStatus', header: 'Status' },
          { field: 'approvedDate', header: 'Approved Date' },
          { field: "comments", header: "Remarks" },
        ];
    }
    PrevCR(type){
      this.showLoading=true;
      this.PrevDisplay= true;
      this.prevcrtype = type;
      if(type==1)
        this.PrevCRName="Previous Conditional Release";
      else
        this.PrevCRName="Previous Evaluation Sample Release";
      this._facadeService.GetPreviousCR_Eval(this.ProductID,type,this.userintId).subscribe(
        (data) => {
          if (data.returnCode == 0) {
            this.prevCR = data.returnObject;
            if(this.prevCR != null && this.prevCR != undefined)
            {
              this.prevCR.forEach((value, key) => {
                if (value.timeLimit !== null && value.timeLimit !== undefined)
                  value.timeLimit = new Date(new Date(value.timeLimit).toDateString() + " " + new Date(value.timeLimit).toTimeString() + " UTC");
              });
            }
            this.showLoading = false;
          } 
          else
            this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    GetConditionalReleaseDetails(){
      this.showLoading = true;
      this.status="New";
      this.IsManufacturingAndShipping="No";
      this.Justification="";
      this.TimeLimit="";
      this.QuantityLimit="";
      this.ConditionalID=0;
      this.conditionalDocs=[];
      if(this.EvalConditionalType == 1)
        this.ConditionalReleaseType="Conditional Release";
      else
        this.ConditionalReleaseType="Evaluation Sample Release";
      this._facadeService.GetConditionalReleaseDetails(this.ProductID,this.EvalConditionalType,this.userintId).subscribe(
        (data) => {
          if (data.returnCode == 0) {
            if(data.returnObject!=null)
            {
              this.status=data.returnObject.statusName;
              this.IsManufacturingAndShipping=data.returnObject.isManufacturingAndShipping==true?"Yes":"No";
              this.Justification= data.returnObject.justification == "null" ? "" : data.returnObject.justification;
              this.TimeLimit= new Date(new Date(data.returnObject.timeLimit).toDateString() + " " + new Date(data.returnObject.timeLimit).toTimeString() + " UTC");
              this.QuantityLimit=data.returnObject.quantityLimit;
              this.ConditionalID=data.returnObject.id;
              this.conditionalDocs =data.returnObject.viewDocuments;
              if(this.conditionalDocs == null || this.conditionalDocs == undefined)
                this.conditionalDocs = [];
              this.GetConditionalApprovalsHistory(data.returnObject.id);
              this.getClarificationDetails(data.returnObject.id);
            }
            this.showLoading = false;
          } 
          else
            this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  
    GetConditionalApprovalsHistory(conditionalId){
      this.showLoading = true;
      this.ApproveDetails=[];
      this._facadeService.GetConditionalApprovalsHistory(conditionalId).subscribe(
        (data) => {
          this.showLoading = false;
          this.ApproveDetails=data.returnObject;
          for (let i = 0; i < this.ApproveDetails.length; i++)
          {
            if(this.ApproveDetails[i].userID.toLowerCase()== this.userId.toLowerCase())
              this.approveview = true;
          }
          this.ApproveDetails.forEach((value, key) => {
            if ( value.approveDate !== null &&  value.approveDate !== undefined ) 
            value.approveDate = new Date(new Date(value.approveDate).toDateString() + " " + new Date(value.approveDate).toTimeString() + " UTC");
          });
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    getClarificationDetails(conditionalId)
    {
      this._facadeService.GetConditionalClarifications(conditionalId).subscribe(
        (data) => {
          this.Clarification1 = data.returnObject;
          this.Clarification1.forEach((value, key) => {
            if ( value.askedDate !== null &&  value.askedDate !== undefined )
            value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
            if ( value.answerDate !== null &&  value.answerDate !== undefined )
            value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
          });
          this.showLoading = false;
        });
    }
  Add(Type)
  {
    this.showLoading = true;
    this.RemarkError = '';
    if (Type == undefined || Type == '') { this.RemarkError = 'Error occured, please referesh the page.';this.showLoading = false; }
    if(Type == "Approve")
    {
      if(this.popupremark == undefined || this.popupremark == '')
        this.popupremark = "Approved";
    }
    else
    {
      if (this.popupremark == undefined || this.popupremark == '') { this.RemarkError = 'Please enter remarks.';this.showLoading = false; }
    }
   
    if (this.popupremark != undefined && this.popupremark != '' && Type != undefined && Type != ''
    && this.RemarkError == '' )
    {
      this.AddModel = {
              type: "Conditional",
              iD: this.ConditionalID,
              desicion: Type,
              comments: this.popupremark,
              actionBy:this.userId,
              actionDate: new Date(),
              actionByName:'',
              SeekTeam:[]
            };
      this._facadeService.Action(this.AddModel).subscribe(
        (data) => {
          this.apiResponse = data;
          if(this.apiResponse.returnCode == 0){
            if(this.EvalConditionalType == 1)
              this.router.navigate(['/myapproval',{ index: 3 }]); 
            else
              this.router.navigate(['/myapproval',{ index: 2 }]); 
          }
          else{
            this.showLoading=false;
            this.RemarkError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
        );
      }
  }
  getProductDetails() {
    this._facadeService.ProductDetails(this.ProductID,this.userId,false).subscribe(
      (data) => {
        this.Product = data.returnObject;
        if (this.Product.dueDate !== null && this.Product.dueDate !== undefined)
        this.Product.dueDate = new Date(new Date(this.Product.dueDate).toDateString() + " " + new Date(this.Product.dueDate).toTimeString() + " UTC");
        if ( this.Product.startDate !== null && this.Product.startDate !== undefined)
        this.Product.startDate = new Date(new Date(this.Product.startDate).toDateString() + " " + new Date(this.Product.startDate).toTimeString() + " UTC");
        this.CustomFields = this.Product.customFields;
        this.ProductFiles = this.Product.productFiles;
        this.phases(this.PhaseID,"bluebtn");

          let count = false;
          this._facadeService
          .GetCancelClarificationDetails(this.Product.productID)
          .subscribe((data) => {
            this.PClarification = data.returnObject;
            if(this.PClarification.length > 0 && !count)
              count = true;
            this.PClarification.forEach((value, key) => {
              if ( value.askedDate !== null &&  value.askedDate !== undefined ) 
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
              if ( value.answerDate !== null &&  value.answerDate !== undefined )
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
            });
            if(count)
              this.DisplayPClare = true;
            else
              this.DisplayPClare = false;
          });

          this._facadeService
          .GetAllCancelApprovalDetails(this.Product.productID)
          .subscribe((data) => {
            this.DiscontinueApproveDetails = data.returnObject;
            if(this.DiscontinueApproveDetails.length > 0 && !count)
              count = true;
            this.DiscontinueApproveDetails.forEach((value, key) => {
              if ( value.actionDate !== null &&  value.actionDate !== undefined ) 
                value.actionDate = new Date(new Date(value.actionDate).toDateString() + " " + new Date(value.actionDate).toTimeString() + " UTC");
            });
            if(count)
              this.DisplayPClare = true;
            else
              this.DisplayPClare = false;
          });
        console.log(this.Product);
      },
      (error) => {
        console.log(error);
      }
    );
   }

   Count()
   {
     if(this.CurrentRequiredDocuments != undefined && this.CurrentRequiredDocuments != null)
     {
      var temp = this.CurrentRequiredDocuments;
      if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
      if(sessionStorage.documentName != "")
        temp = temp.filter(x=> x.documentName.toLowerCase().startsWith(sessionStorage.documentName.toLowerCase()));
      if(sessionStorage.isRequired != "")
        temp = temp.filter(x=> x.isRequired.toLowerCase().startsWith(sessionStorage.isRequired.toLowerCase()));
      if(sessionStorage.documentLabels != "")
        temp = temp.filter(x=> x.documentLabels.toLowerCase().startsWith(sessionStorage.documentLabels.toLowerCase()));
      if(sessionStorage.templates != "")
        temp = temp.filter(x=> x.templates.filter(y=>y.templateName.toLowerCase().startsWith(sessionStorage.templates.toLowerCase())));
      this.TasksCount = temp.length;
     }
     else
      this.TasksCount = 0;
   }
   test(val,field,dt)
    {
      if(field == "departmentName")
        sessionStorage.departmentName = val;
      else if(field == "documentName")
        sessionStorage.documentName = val;
      else if(field == "isRequired")
        sessionStorage.isRequired = val;
      else if(field == "documentLabels")
        sessionStorage.documentLabels = val;
      else if(field == "templates")
        sessionStorage.templates = val;
      dt = dt.filter(val,field, 'startsWith');
      this.Count();
    }

   phases(phaseID,ImageName) {
    this.PhaseID = phaseID;
    this.ImageName = ImageName;
    if(this.ImageName == "greybtn")    this.PhaseColor = "#a5a5a5";
    if(this.ImageName == "greenbtn")   this.PhaseColor = "#37df73";
    if(this.ImageName == "bluebtn")    this.PhaseColor = "#3776df";
    this.departmentsdisplay = false;this.teamdisplay = false;this.approvalmembersdisplay=false;this.projectdisplay = false;
    this.documentsdisplay = false; this.notesdisplay = false;this.documentsdisplay = false;
    this.approvalvalues = []; this.popupnote = "";
  
    this._facadeService.getPhaseByID(this.PhaseID,this.userId).subscribe(
      (data) => {
        this.CurrentPhase = data.returnObject;
        this.CurrentDepartments= this.CurrentPhase.phaseDepartments;
        this.CurrentPhase.phaseTeam.forEach((value, key) => {
          if ( value.approvedDate !== null &&  value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00')
          value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
        });
        this.CurrentTeam = this.CurrentPhase.phaseTeam.filter(item => item.type.toLocaleLowerCase() === "team");
        this.CurrentApprovalTeam = this.CurrentPhase.phaseTeam.filter(item => item.type.toLocaleLowerCase() === "approval" || item.type.toLocaleLowerCase() === "collaborator"); 
        for (let i = 0; i < this.CurrentApprovalTeam.length; i++)
        {
          if(this.CurrentApprovalTeam[i].type.toLowerCase().includes("coll"))
            this.CurrentApprovalTeam[i].type = "Notification Recipient";
          else
            this.CurrentApprovalTeam[i].type = "Approver";
        }
        this.IsPrevEvalDisplay = false;
        this.IsPrevCRDisplay = false;
        if(this.CurrentPhase.isPrevEvalDisplay === true)
          this.IsPrevEvalDisplay = true;
        if(this.CurrentPhase.isPrevCRDisplay === true)
          this.IsPrevCRDisplay = true;
  
        this.ViewOnlyTabs = true;
        if(this.Product.productStatus.toLocaleLowerCase() === "hold" && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
          this.CurrentPhase.phaseStatus = "Hold";
        if((this.Product.productStatus.toLocaleLowerCase() === "discontinued" ||  this.Product.productStatus.toLocaleLowerCase() === "eol"
        || this.Product.productStatus.toLocaleLowerCase() === "obs") 
        && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
          this.CurrentPhase.phaseStatus = "--";
        if(this.Product.productStatus.toLocaleLowerCase() === "completed" && (this.CurrentPhase.phaseStatus.toLocaleLowerCase() == "in-process" || this.CurrentPhase.phaseStatus.toLocaleLowerCase() == "ready to start") && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
          this.CurrentPhase.phaseStatus = "In-Production";
        // if(this.Product.productStatus.toLocaleLowerCase() === "completed" && this.CurrentPhase.phaseStatus.toLocaleLowerCase() == "ready to start" && this.Product.currentPhase.toUpperCase() === this.CurrentPhase.phaseName.toUpperCase())
        //   this.CurrentPhase.phaseStatus = "In-Process";
        this.CurrentRequiredDocuments = this.CurrentPhase.phaseRequiredDocuments;
        this.Count();

        this.AllList = this.CurrentPhase.list;
        this.Milestones = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "step" && item.listLevel === 1);
        this.Stages = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "stage" && item.listLevel === 1);
        
        this._facadeService.ClarificationDetails(this.PhaseID).subscribe(
          (data) => {
            this.Clarification = data.returnObject;
            this.Clarification.forEach((value, key) => {
              if ( value.askedDate !== null &&  value.askedDate !== undefined )
              value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
              if ( value.answerDate !== null &&  value.answerDate !== undefined )
              value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
            });
          })
        
        this.approvalvalues.push(  "<h5 class='hclass'>Other Approvers</h5>" +
        "<table cellspacing='0' class='apptable'><tr class='apptr'><th class='appths'>S.No</th><th class='apptht'>Type</th><th class='appth'>Name</th><th class='appth'>Role</th>" + 
        "<th class='appth'>Department</th><th class='appth'>Approved Date</th><th class='appth'>Remarks</th></tr>");
        let count = 0;
        for (let i = 0; i < this.AllList.length; i++) {
            this.AllList[i].listTeam.forEach((value, key) => {
              if ( value.approvedDate !== null &&  value.approvedDate !== undefined && value.approvedDate.toString() !== '0001-01-01T00:00:00' ) 
              value.approvedDate = new Date(new Date(value.approvedDate).toDateString() + " " + new Date(value.approvedDate).toTimeString() + " UTC");
            });
            let approvalteam : ListTeam[];
            approvalteam = this.AllList[i].listTeam.filter(item => item.type.toLocaleLowerCase() === "approval" || item.type.toLocaleLowerCase() === "collaborator") ;
            if(approvalteam.length>0)
            { 
                for (let j = 0; j < approvalteam.length; j++) {
                  count ++;
                  if(approvalteam[j].type.toLowerCase().includes("coll"))
                    approvalteam[j].type = "Notification Recipient";
                  else
                    approvalteam[j].type = "Approver";
                    this.approvalvalues.push(
                      "<tr><td class='apptds'>" + count + "</td> <td class='apptd'>" + this.AllList[i].listName + " - " +
                        approvalteam[j].type + "</td><td class='apptd'>" + approvalteam[j].userName + "</td><td class='apptd'>" +
                        approvalteam[j].roleName + "</td><td class='apptd'>" + approvalteam[j].departmentName + "</td><td class='apptdc'>" +
                        (approvalteam[j].approvedDate.toString() == '0001-01-01T00:00:00' ? '--' 
                        : (new Date(approvalteam[j].approvedDate)).toLocaleDateString()+' '+ (new Date(approvalteam[j].approvedDate)).toLocaleTimeString()) +
                         "</td><td class='apptd'>" + (approvalteam[j].remarks)  + "</td></tr>"
                    );
                }
            }
        }
        if(count == 0)
          this.approvalvalues.push( "<tr><th class='apptd' colspan='7'>No record found...</th></tr>");
        this.approvalvalues.push( " </table>") ;
        if(count != 0)
          this.listApprovalMembers = this.approvalvalues.join("");
        for (let i = 0; i < this.Milestones.length; i++) {
          if(i == 0)
          {
            this.Milestone1 = this.Milestones[i];
            this.Milestone1Departments =  this.Milestone1.listDepartments;
            this.Mile1ApprovalTeam = this.Milestone1.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Mile1Documents = this.Milestone1.listRequiredDocuments;
          }
          if(i == 1)
          {
            this.Milestone2 = this.Milestones[i];
            this.Milestone2Departments =  this.Milestone2.listDepartments;
            this.Mile2ApprovalTeam = this.Milestone2.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Mile2Documents = this.Milestone2.listRequiredDocuments;
          }
          if(i == 2)
          {
            this.Milestone3 = this.Milestones[i];
            this.Milestone3Departments =  this.Milestone3.listDepartments;
            this.Mile3ApprovalTeam = this.Milestone3.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Mile3Documents = this.Milestone3.listRequiredDocuments;
          }
          if(i == 3)
          {
            this.Milestone4 = this.Milestones[i];
            this.Milestone4Departments =  this.Milestone4.listDepartments;
            this.Mile4ApprovalTeam = this.Milestone4.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Mile4Documents = this.Milestone4.listRequiredDocuments;
          }
          if(i == 4)
          {
            this.Milestone5 = this.Milestones[i];
            this.Milestone5Departments =  this.Milestone5.listDepartments;
            this.Mile5ApprovalTeam = this.Milestone5.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Mile5Documents = this.Milestone5.listRequiredDocuments;
          }
        }
  
        for (let i = 0; i < this.Stages.length; i++) {
          if(i == 0)
          {
            this.Stage1 = this.Stages[i];
            this.Stage1Departments =  this.Stage1.listDepartments;
            this.Stage1ApprovalTeam = this.Stage1.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Stage1Documents = this.Stage1.listRequiredDocuments;
            this.Stage1Milestones = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "step" && item.listLevel === 2 && item.listParentID === this.Stage1.listID);
  
            for (let i = 0; i < this.Stage1Milestones.length; i++) {
              if(i == 0)
              {
                this.Stage1Milestone1 = this.Stage1Milestones[i];
                this.Stage1Milestone1Departments =  this.Stage1Milestone1.listDepartments;
                this.Stage1Mile1ApprovalTeam = this.Stage1Milestone1.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage1Mile1Documents = this.Stage1Milestone1.listRequiredDocuments;
              }
              if(i == 1)
              {
                this.Stage1Milestone2 = this.Stage1Milestones[i];
                this.Stage1Milestone2Departments =  this.Stage1Milestone2.listDepartments;
                this.Stage1Mile2ApprovalTeam = this.Stage1Milestone2.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage1Mile2Documents = this.Stage1Milestone2.listRequiredDocuments;
              }
              if(i == 2)
              {
                this.Stage1Milestone3 = this.Stage1Milestones[i];
                this.Stage1Milestone3Departments =  this.Stage1Milestone3.listDepartments;
                this.Stage1Mile3ApprovalTeam = this.Stage1Milestone3.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage1Mile3Documents = this.Stage1Milestone3.listRequiredDocuments;
              }
            }
          }
          if(i == 1)
          {
            this.Stage2 = this.Stages[i];
            this.Stage2Departments =  this.Stage2.listDepartments;
            this.Stage2ApprovalTeam = this.Stage2.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Stage2Documents = this.Stage2.listRequiredDocuments;
            this.Stage2Milestones = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "step" && item.listLevel === 2 && item.listParentID === this.Stage2.listID);
  
            for (let i = 0; i < this.Stage2Milestones.length; i++) {
              if(i == 0)
              {
                this.Stage2Milestone1 = this.Stage2Milestones[i];
                this.Stage2Milestone1Departments =  this.Stage2Milestone1.listDepartments;
                this.Stage2Mile1ApprovalTeam = this.Stage2Milestone1.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage2Mile1Documents = this.Stage2Milestone1.listRequiredDocuments;
              }
              if(i == 1)
              {
                this.Stage2Milestone2 = this.Stage2Milestones[i];
                this.Stage2Milestone2Departments =  this.Stage2Milestone2.listDepartments;
                this.Stage2Mile2ApprovalTeam = this.Stage2Milestone2.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage2Mile2Documents = this.Stage2Milestone2.listRequiredDocuments;
              }
              if(i == 2)
              {
                this.Stage2Milestone3 = this.Stage2Milestones[i];
                this.Stage2Milestone3Departments =  this.Stage2Milestone3.listDepartments;
                this.Stage2Mile3ApprovalTeam = this.Stage2Milestone3.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage2Mile3Documents = this.Stage2Milestone3.listRequiredDocuments;
              }
            }
          }
          if(i == 2)
          {
            this.Stage3 = this.Stages[i];
            this.Stage3Departments =  this.Stage3.listDepartments;
            this.Stage3ApprovalTeam = this.Stage3.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
            this.Stage3Documents = this.Stage3.listRequiredDocuments;
            this.Stage3Milestones = this.AllList.filter(item => item.listType.toLocaleLowerCase() === "step" && item.listLevel === 2 && item.listParentID === this.Stage3.listID);
  
            for (let i = 0; i < this.Stage3Milestones.length; i++) {
              if(i == 0)
              {
                this.Stage3Milestone1 = this.Stage3Milestones[i];
                this.Stage3Milestone1Departments =  this.Stage3Milestone1.listDepartments;
                this.Stage3Mile1ApprovalTeam = this.Stage3Milestone1.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage3Mile1Documents = this.Stage3Milestone1.listRequiredDocuments;
              }
              if(i == 1)
              {
                this.Stage3Milestone2 = this.Stage3Milestones[i];
                this.Stage3Milestone2Departments =  this.Stage3Milestone2.listDepartments;
                this.Stage3Mile2ApprovalTeam = this.Stage3Milestone2.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage3Mile2Documents = this.Stage3Milestone2.listRequiredDocuments;
              }
              if(i == 2)
              {
                this.Stage3Milestone3 = this.Stage3Milestones[i];
                this.Stage3Milestone3Departments =  this.Stage3Milestone3.listDepartments;
                this.Stage3Mile3ApprovalTeam = this.Stage3Milestone3.listTeam.filter(item => item.type.toLocaleLowerCase() === "approver" || item.type.toLocaleLowerCase() === "notification recipient");
                this.Stage3Mile3Documents = this.Stage3Milestone3.listRequiredDocuments;
              }
            }
          }
        }
        this.index = 0;this.milestoneindex = 0;this.stageindex = 0;this.stage1mileindex = 0;this.stage2mileindex = 0;this.stage3mileindex = 0;
        this.stage1index = 0;this.stage2index = 0;this.stage3index = 0;
        let TransactionID = 0;
        if(sessionStorage.getItem("TransactionID") != undefined)
        {
          if(sessionStorage.getItem("TransactionID") != "")
            TransactionID = Number(sessionStorage.getItem("TransactionID"));
        }
        if(sessionStorage.getItem("DisplayType") != undefined)
        {
          if(sessionStorage.getItem("DisplayType") != "")
          {
            if(sessionStorage.getItem("DisplayType").toLowerCase() == "phase")
              this.index = 0;
            else if(sessionStorage.getItem("DisplayType").toLowerCase() == "step")
            {
              this.index = 1;
              if(this.Milestone1.listID == TransactionID)   this.milestoneindex = 0;
              else if(this.Milestone2.listID == TransactionID)   this.milestoneindex = 1;
              else if(this.Milestone3.listID == TransactionID)   this.milestoneindex = 2;
              else if(this.Milestone4.listID == TransactionID)   this.milestoneindex = 3;
              else if(this.Milestone5.listID == TransactionID)   this.milestoneindex = 4;
            }
            else if(sessionStorage.getItem("DisplayType").toLowerCase() == "stage")
            {
              if(this.Milestones.length > 0)
                this.index = 2;
              else
                this.index = 1;
              if(this.Stage1.listID == TransactionID) 
              {
                this.stageindex = 0;
                this.stage1index = 0;
                if(this.Stage1Milestones.length > 0)
                {
                  if(this.Stage1Milestone1.listID == TransactionID) { this.stage1index = 1;  this.stage1mileindex = 0; }
                  else if(this.Stage1Milestone2.listID == TransactionID)  { this.stage1index = 1; this.stage1mileindex = 1; }
                  else if(this.Stage1Milestone3.listID == TransactionID)  { this.stage1index = 1; this.stage1mileindex = 2; }
                }
              }  
              else if(this.Stage2.listID == TransactionID)  
              {
                this.stageindex = 1;
                this.stage2index = 0; 
                if(this.Stage2Milestones.length > 0)
                {
                  if(this.Stage2Milestone1.listID == TransactionID)  { this.stage2index = 1;  this.stage2mileindex = 0; }
                  else if(this.Stage2Milestone2.listID == TransactionID) { this.stage2index = 1;   this.stage2mileindex = 1; }
                  else if(this.Stage2Milestone3.listID == TransactionID)  { this.stage2index = 1;  this.stage2mileindex = 2; }
                }
              } 
              else if(this.Stage3.listID == TransactionID)  
              {
                this.stageindex = 2;
                this.stage3index = 0; 
                if(this.Stage3Milestones.length > 0)
                {
                  if(this.Stage3Milestone1.listID == TransactionID)  { this.stage3index = 1;   this.stage3mileindex = 0; }
                  else if(this.Stage3Milestone2.listID == TransactionID)  { this.stage3index = 1; this.stage3mileindex = 1; }
                  else if(this.Stage3Milestone3.listID == TransactionID)  { this.stage3index = 1; this.stage3mileindex = 2; }
                }
              } 
            }
          }
        }
        this.showLoading = false;
        console.log(this.CurrentPhase);
      },
      (error) => {
        console.log(error);
      }
    );
   
  }
  
    Documents(Type,TransactionID,l,ln)
    {
      this.showLoading = true;
      this.documentsdisplay = true;
      this.Level=l;
      this.LevelName=ln;
      this._facadeService.getDocuments(Type,TransactionID,this.userintId).subscribe(
        (data) => {
          this.ViewAddedDocuments = data.returnObject;
          this.ViewAddedDocuments.forEach((value, key) => {
            if ( value.addedDate !== null &&  value.addedDate !== undefined )
            value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
          });
          console.log(this.ViewAddedDocuments);
          this.showLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    Notes(Type,TransactionID,l,ln)
    {
      this.showLoading = true;
      this.notesdisplay = true;
      this.Level=l;
      this.LevelName=ln;
      this._facadeService.getNotes(Type,TransactionID).subscribe(
        (data) => {
          this.ViewAddedNotes = data.returnObject;
          this.ViewAddedNotes.forEach((value, key) => {
            if ( value.addedDate !== null &&  value.addedDate !== undefined ) 
            value.addedDate = new Date(new Date(value.addedDate).toDateString() + " " + new Date(value.addedDate).toTimeString() + " UTC");
          });
          console.log(this.ViewAddedNotes);
          this.showLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    tablenotes(rowdata) {
      this.popupnote = rowdata;
    }
    DMSToken(rowData){
      this.showLoading = true;
      if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
        this.showLoading = true;
        var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
        this.Download(rowData.fileName, newname, rowData.userFileName);
      }
    }
    Download(filename, newname, userfilename) {
      this._facadeService.downloadDMSFile(filename, newname).subscribe(response => {
        response.filename = userfilename;
        let url = window.URL.createObjectURL(response);
        var anchor = document.createElement("a");
        anchor.download = userfilename;
        anchor.href = url;
        anchor.click();
        anchor.remove();
        // window.open(url, '_blank').focus();
        this.showLoading = false;
      }),
        error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    }
  }
  
  