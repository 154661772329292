import { Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../../facade/facade.service';
import { Table } from "primeng/table";
import { ManageCustomFieldsService } from "../../../services/manage-custom-fields.service";
import { PrintcommonService } from "../../../services/printcommon.service";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Inject } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';
import {ProductService} from 'src/app/services/product.service';
@Component({
  selector: 'app-defaultteam',
  templateUrl: './defaultteam.component.html',
  styleUrls: ['./defaultteam.component.scss']
})
export class DefaultteamComponent implements OnInit {

  showLoading:boolean;
  ProducLine: any[];
  selectedProductLine: any;
  productLineError:string = "";

  userId = sessionStorage.getItem("userGuid");
  templatesData: any[];
  selectedTemplate: any;
  templateError:string = "";

  Phase: any[];
  selectedPhase: any;
  phaseError:string = "";
  
  TeamType: any[];
  selectedType: any;
  typeError:string = "";

  ErrorDisplay: boolean = false;
  Error: string = "";

  ViewOnly:boolean = true;
  docTypes: any[] = [];
  clicked: boolean;
  ok: boolean;
  departmentsData: any;
  selectedDept: any;
  RolesData: any;
  selectedRole: any;
  UsersData: any;
  selectedUser: any;
  departmentNameError: any;
  roleNameError: any;
  userNameError: any;
  cols: any[];
  dynamicplaceholder: string;
  @ViewChild("dt") table: Table;
  TeamCount:number=0;
  EditRow: boolean = false;
  OldDID:string = "";
  OldRID:string = "";
  OldUID:string = "";
  userFeature:boolean=false;
  originalPhaseTeamData:any[]=[];
  constructor(
    private _facadeService: FacadeService,private pipe: DatePipe,private _productService:ProductService,
    private printService: PrintcommonService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
  }
  ngOnInit(): void {
    this.showLoading = true;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PDM_SRABS");
        if(featureExist!=null)
          this.userFeature = true;
        // var featureExist = feature.find(m=>m.code=="PDM_UCBD");
        // if(featureExist!=null)
        //   this.userFeature1 = true;
      }
    }
    this.TeamType = [
      { name: 'Team', id: 'Team' },
      { name: 'Approvers', id: 'Approval' },
      { name: 'Notification Recipients', id: 'Collaborator' },
    ];

    this.cols = [
      { field: 'departmentName', header: 'Department', dynamicplaceholder: "Search by Department" },
      { field: 'roleName', header: 'Role', dynamicplaceholder: "Search by Role" },
      { field: 'userName', header: 'Name', dynamicplaceholder: "Search by Name" },
    ];
    sessionStorage.departmentName = "";
    sessionStorage.roleName = "";
    sessionStorage.userName = "";
    this.GetProductLne();
  }

  GetProductLne() {
    this._facadeService.GetProductLines()
    .subscribe((data) => {
      this.ProducLine = data.returnObject;
      this.getDepartments();
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    });
  }
  getDepartments() {
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  }
    );
  }
  getTemplates()
  { 
    if(this.selectedProductLine != null && this.selectedProductLine != undefined && this.selectedProductLine != "")
    {
      this.showLoading=true;
      if(this.docTypes != null && this.docTypes != undefined) {  this.table.value.forEach(x => { this.table.cancelRowEdit(x); }); }
      this.clicked = false;
      this.selectedTemplate = ""; this.selectedType = ""; this.selectedPhase = "";
      this.productLineError = ""; this.phaseError = ""; this.templateError = ""; this.typeError = "";
      this.docTypes = [];
      this._productService.GetTemplates(this.selectedProductLine.id,"P",false,this.userId).subscribe(
        (data) => {
          if(data.returnCode==0 && data.returnObject!=null)
          {
            this.templatesData=data.returnObject;
            if(this.templatesData.length == 1)
            {
              var v = this.templatesData[0].templateGUID;
              this.selectedTemplate=this.templatesData.find(m=>m.templateGUID==v);
              this.GetPhases();
            }
            else
              this.showLoading = false;
          }
          else
            this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        } 
      );
    }
  }
  GetPhases(){
    if(this.selectedProductLine != null && this.selectedProductLine != undefined && this.selectedProductLine != ""
    && this.selectedTemplate != null && this.selectedTemplate != undefined && this.selectedTemplate != "")
    {
      this.showLoading=true;
      if(this.docTypes != null && this.docTypes != undefined) {  this.table.value.forEach(x => { this.table.cancelRowEdit(x); }); }
      this.clicked = false;
      this.selectedType = "";
      this.selectedPhase = "";
      this.productLineError = ""; this.phaseError = ""; this.templateError = ""; this.typeError = "";
      this.docTypes = [];
      this._productService.GetPhases(this.selectedTemplate.templateGUID,"P","","").subscribe(
        (data) => {
          if(data.returnCode==0 && data.returnObject!=null)
          {
            this.Phase=data.returnObject;
            this.GetPhaseTeam();
          }
          else
            this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        } 
      );
    }
  }
  GetPhaseTeam(){
    this.showLoading = true;
    this._productService.GetPhaseTeam(this.selectedTemplate.templateGUID,0,"P","","").subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0 && data.returnObject!=null)
          this.originalPhaseTeamData=data.returnObject;
        this.getCancelTeam();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  test(val,field,dt)
  {
    if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "roleName")
      sessionStorage.roleName = val;
    else if(field == "userName")
      sessionStorage.userName = val;

    dt = dt.filter(val,field, 'startsWith');
    this.Count();
  }
  Count(){
    if(this.docTypes != undefined && this.docTypes != null)
    {
      var temp = this.docTypes;
      if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName.toLowerCase().startsWith(sessionStorage.departmentName.toLowerCase()));
      if(sessionStorage.roleName != "")
        temp = temp.filter(x=> x.roleName.toLowerCase().startsWith(sessionStorage.roleName.toLowerCase()));
      if(sessionStorage.userName != "")
        temp = temp.filter(x=> x.userName.toLowerCase().startsWith(sessionStorage.userName.toLowerCase()));
      this.TeamCount = temp.length;
    }
    else
      this.TeamCount = 0;
  }

  getCancelTeam() {
    this.showLoading = true;
    this.clicked=false;
    this.productLineError = ""; this.phaseError = ""; this.templateError = ""; this.typeError = "";
    this.EditRow = false;
    this.OldDID = "";
    this.OldRID = "";
    this.OldUID = ""; 
    this.selectedRole='';
    this.selectedDept='';
    this.selectedUser='';
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';  
    if(this.docTypes != null && this.docTypes != undefined)
    {  this.table.value.forEach(x => {
      this.table.cancelRowEdit(x);
      });
    }
    this.docTypes = [];
    this.TeamCount = 0;
    this.ViewOnly = true;
    if(this.selectedProductLine != null && this.selectedProductLine != undefined && this.selectedProductLine != ""
    && this.selectedTemplate != null && this.selectedTemplate != undefined && this.selectedTemplate != ""
    && this.selectedPhase != null && this.selectedPhase != undefined && this.selectedPhase != ""
    && this.selectedType != null && this.selectedType != undefined && this.selectedType != "")
    {
      this.docTypes = this.originalPhaseTeamData.filter(m=>m.phaseID==this.selectedPhase.phaseID && m.type == this.selectedType.id);
      if(this.docTypes != null && this.docTypes != undefined)
      {
        this.Count();
        if(this.userFeature && this.docTypes.length > 0)
        {
          this.ViewOnly = false;
          this.docTypes.forEach(e => {
            e.datakey =  e.departmentGUID.toUpperCase()  + " : " + e.roleGUID.toUpperCase() + " : " + e.userGUID.toUpperCase();
            e.isEdit = "N";
          });
        }
      }
      this.showLoading=false;
    }
    else
      this.showLoading=false;
  }

  getRoleList(RoleID,UserID) {
    this.showLoading = true;
    this.selectedRole = [];
    this.selectedUser = [];
    this._facadeService
      .getRolesByDepartmentGuid(this.selectedDept.departmentGuid)
      .subscribe((result: any) => {
        this.RolesData = result.returnObject;
        if(RoleID != "")
          this.selectedRole = this.RolesData.find(x=>x.roleGuid.toUpperCase() == RoleID.toUpperCase());
        if(UserID)
          this.getUserList(UserID);
        this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  });
    this.departmentNameError='';
  }

  getUserList(UserID) {
    this.showLoading = true;
    this.selectedUser = [];
    this._facadeService
      .GetUsersByRoleGuid(this.selectedRole.roleGuid)
      .subscribe((result: any) => {
        this.UsersData = result.returnObject;
        if(this.UsersData != undefined && this.UsersData != null)
        {
          if(this.UsersData.length > 0)
          {
            this.UsersData.forEach(element => {
              element.firstName = element.firstName + " " + element.lastName;
            });
            if(UserID != "")
              this.selectedUser = this.UsersData.find(x=>x.userGuid.toUpperCase() == UserID.toUpperCase());
          }
        }
        this.showLoading = false;
      },
      (error) => {  console.log(error); this.showLoading = false;  });
    this.roleNameError='';
  }

  onRowEditInit(teamModel: iCancelTeam) {
    this.showLoading = true;
    this.clicked = false;
    this.docTypes.forEach(i => {
      if(i.departmentName == null)    i.departmentName = "";
      if(i.roleName == null)    i.roleName = "";
      if(i.userName == null)    i.userName = "";
      if(teamModel.departmentName == null)    teamModel.departmentName = "";
      if(teamModel.roleName == null)    teamModel.roleName = "";
      if(teamModel.userName == null)    teamModel.userName = "";
      if(i.departmentName.toUpperCase().trim() == teamModel.departmentName.toUpperCase().trim() &&
      i.roleName.toUpperCase().trim() == teamModel.roleName.toUpperCase().trim() 
      && i.userName.toUpperCase().trim() == teamModel.userName.toUpperCase().trim())
      {
        i.isEdit = "D";
      }
    });
    this.showLoading = false;
  }
  onRowEditInit1(row) {
    this.showLoading = true;
    if(this.docTypes != null && this.docTypes != undefined)
    {  this.table.value.forEach(x => {
      if(x.departmentName == row.departmentName &&  x.roleName == row.roleName && x.userName == row.userName)
      {

      }
      else
        this.table.cancelRowEdit(x);
      });
    }
    this.EditRow = true;
    this.OldDID = row.departmentGUID;
    this.OldRID = row.roleGUID;
    this.OldUID = row.userGUID;
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';    
    this.clicked = true;
    this.selectedDept = this.departmentsData.find(x=>x.departmentGuid.toUpperCase() == row.departmentGUID.toUpperCase());
    this.getRoleList(row.roleGUID,row.userGUID);
    this.showLoading = false;
  }

  UndoDelete(teamModel: iCancelTeam) {
    this.showLoading = true;
    this.userNameError = '';
    var duplicate = false;
    this.docTypes.forEach(i => {
      if(i.departmentName.toUpperCase().trim() == teamModel.departmentName.toUpperCase().trim() &&
      i.roleName.toUpperCase().trim() == teamModel.roleName.toUpperCase().trim() 
      && i.userName.toUpperCase().trim() == teamModel.userName.toUpperCase().trim() && i.isEdit != "D" )
        duplicate = true;
    });
    if(duplicate)
        this.userNameError = 'Duplicate records found.';
    else 
      teamModel.isEdit = "Y";
    this.showLoading = false;
  }

  onRowEditCancel(teamModel: iCancelTeam, index: number) {
    this.showLoading = true;
    this.clicked = false;
    if(!this.EditRow)
      this.docTypes = this.docTypes.filter(i => i.departmentName != teamModel.departmentName &&
        i.roleName != teamModel.roleName && i.userName != teamModel.userName);
    this.Count();
    this.selectedRole='';
    this.selectedDept='';
    this.selectedUser='';
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';   
    this.EditRow = false;
    this.OldDID = "";
    this.OldRID = "";
    this.OldUID = "";   
    this.showLoading = false;
  }

  newRow() {
    this.Count();
    return { departmentName: '', roleName: '', userName: '',datakey: '' };
  }

  addCancelTeam() {
    this.showLoading = true;
    this.departmentNameError = '';
    this.roleNameError = '';
    this.userNameError = '';
    if (this.selectedDept == undefined || this.selectedDept == '' || this.selectedDept == null) {
      this.departmentNameError = 'Please select department.';this.showLoading = false;
    }
    else if (this.selectedRole == undefined || this.selectedRole == '' || this.selectedRole == null) {
      this.roleNameError = 'Please select role.';this.showLoading = false;
    }
    else if (this.selectedUser == undefined || this.selectedUser == '' || this.selectedUser == null) {
      this.userNameError = 'Please select user.';this.showLoading = false;
    }
    else if (
      this.selectedDept != undefined && this.selectedDept != '' &&
      this.selectedRole != undefined && this.selectedRole != '' &&
      this.selectedUser != undefined && this.selectedUser != ''
    ) {
      this.ok = true;
      this.docTypes.forEach(element => {
        if (this.selectedDept.departmentName == element.departmentName && this.selectedRole.roleName == element.roleName
          && this.selectedUser.firstName == element.userName && this.ok && element.isEdit != "D") {
          this.ok = false;
          this.departmentNameError = 'Duplicate records found.';
        }
      });
      if (this.ok) {
        if(this.EditRow)
        {
          this.docTypes.forEach(element => {
            if (this.OldDID.toUpperCase() == element.departmentGUID.toUpperCase() && this.OldRID.toUpperCase() == element.roleGUID.toUpperCase()
              && this.OldUID.toUpperCase() == element.userGUID.toUpperCase() && element.isEdit != "D") {
                element.isEdit = "E";
                element.departmentGUID = this.selectedDept.departmentGuid;
                element.departmentName = this.selectedDept.departmentName;
                element.roleGUID = this.selectedRole.roleGuid;
                element.roleName = this.selectedRole.roleName;
                element.userGUID = this.selectedUser.userGuid;
                element.userName = this.selectedUser.firstName;
                element.datakey = this.selectedDept.departmentGuid + " : " + this.selectedRole.roleGuid + " : " + this.selectedUser.userGuid;
            }
          });
          this.EditRow = false;
          this.OldDID = "";
          this.OldRID = "";
          this.OldUID = "";
        }
        else
        {
          var teamModel = {
            type:this.selectedType.id, 
            departmentGUID: this.selectedDept.departmentGuid,
            departmentName: this.selectedDept.departmentName,
            roleGUID: this.selectedRole.roleGuid,
            roleName: this.selectedRole.roleName,
            userGUID: this.selectedUser.userGuid,
            userName: this.selectedUser.firstName,
            isEdit:"Y",
            datakey: this.selectedDept.departmentGuid + " : " + this.selectedRole.roleGuid + " : " + this.selectedUser.userGuid
          };
          this.docTypes.push(teamModel);
        }
        this.clicked = false;
        this.docTypes = this.docTypes.filter(i => i.departmentName != "");
        this.Count();
        this.selectedRole='';
        this.selectedDept='';
        this.selectedUser='';
        this.departmentNameError = '';
        this.roleNameError = '';
        this.userNameError = '';
      }
      this.showLoading = false;
    }
  }

  savecanceldata() {
    this.showLoading = true;
    if(this.clicked)
      this.addCancelTeam();
    else
    {
      this.productLineError = ''; this.templateError = ""; this.phaseError = ''; this.typeError = "";
      if(this.selectedProductLine == "" || this.selectedProductLine == null || this.selectedProductLine == undefined)
      { this.productLineError = "Please select product line";this.showLoading = false;}
      else if(this.selectedTemplate == "" || this.selectedTemplate == null || this.selectedTemplate == undefined)
      { this.templateError = "Please select template";this.showLoading = false;}
      else if(this.selectedPhase == "" || this.selectedPhase == null || this.selectedPhase == undefined)
      { this.phaseError = "Please select phase";this.showLoading = false;}
      else if(this.selectedType == "" || this.selectedType == null || this.selectedType == undefined)
      { this.typeError = "Please select type";this.showLoading = false;}
      else
      {
        let ok = true;
        let doc= [];
        this.docTypes.forEach(e =>
        {
          if(e.type == null && ok)
            ok = false;
          if(e.isEdit!="D")
            doc.push(e);
        });
        if(ok)
        {
          if(doc.length == 0)
          {
            this.showLoading=false;
            this.ErrorDisplay = true;
            this.Error = "Please add atleast one member.";
          }
          else
          {   
            var MangeteamModel = {
              Type:this.selectedType.id, 
              PhaseID: this.selectedPhase.phaseID,
              Team: doc,  
              AddedBy: this.userId
            };
            
            this._productService.UpdateTemplateTeam(MangeteamModel).subscribe(
              (data) => {
                var apiResponse = data;
                  if(apiResponse.returnCode == 0){
                    this.GetPhaseTeam();
                    this.ErrorDisplay = true;
                    this.Error = "Saved successfully.";
                    this.showLoading=false;
                  }
                  else{
                    this.showLoading=false;
                    this.ErrorDisplay = true;
                    this.Error = apiResponse.returnMessage;
                  }
              },
              (error) => {  console.log(error); this.showLoading = false;  }
            );
          }
        }
        else
        {
          this.showLoading=false;
          this.ErrorDisplay = true;
          this.Error = "Some fields are not selected. You can save after selecting them.";
        }
      }
    }
   }

  exportExcel() {  
     if(this.selectedPhase != undefined && this.selectedPhase != null && this.selectedType != undefined && this.selectedType != null)
     {
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 150 },{ wpx : 150 },{ wpx : 150 }];
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, this.selectedTemplate.templateName + " - " + this.selectedPhase.phaseName + " - " + this.selectedType.name + ".xlsx");
     }
  }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;   &nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp; &nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }  
  print(SearchID) {
    if(this.selectedPhase != undefined && this.selectedPhase != null && this.selectedType != undefined && this.selectedType != null)
     {
      var Content = document.getElementById('invoice-content').innerHTML;
      var Title = this.selectedTemplate.templateName + " - " + this.selectedPhase.phaseName + " - " + this.selectedType.name;
      this.printService.Print(Content,Title,SearchID,true);
     }
  }
}
export interface iCancelTeam {
  departmentName: string;
  roleName: string;
  userName: string;
  isEdit : string;
}

