import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { ManageCustomFieldsService } from 'src/app/services/manage-custom-fields.service';
import {ProductService} from 'src/app/services/product.service';
import { Table } from "primeng/table";

@Component({
  selector: 'app-createproduct',
  templateUrl: './createproduct.component.html',
  styleUrls: ['./createproduct.component.scss']
})
export class CreateproductComponent implements OnInit {
  @ViewChild("dt") table: Table;
  activeIndex: number=0;
  items: MenuItem[];
  OriginalCustomFieldgroupId: any;
  CustomFieldgroupId:any=[];
  datasourcelist: any = {};
  
  showLoading:boolean;
  templatesData:any=[];
  prodOwnerData:any=[];
  selectedProdOwner:any;
  customGroupsData:any=[];
  PhasesData:any=[];
  PhaseTaskData:any=[];
  PhaseDockData:any[]=[];
  ProdLineData:any=[];
  selectedProdLine:any=[];
  selectedTemplate:any=[];
  showCust:boolean;  Step1Error:any;
  Step4Error:any;
  productName:any;
  targetDate:any;
  userId = sessionStorage.getItem('userGuid');
  userintId = sessionStorage.getItem("userId");
  tmpProductId:any=0;
  CustomFieldsFromById:any=[];
  PhaseTeamData:any=[];
  selectedPhaseOwner:any;
  phaseOwnerData:any=[];
  Step2Error:any;

  departmentsData:any=[];
  rolesData:any=[];
  usersData:any=[];
  selectedTeamDepartment:any;
  selectedTeamRole:any;
  selectedTeamUser:any;
  departmentNameError: any;
  roleNameError: any;
  userNameError: any;
  EditTRow: boolean = false;
  OldTDID:string = "";
  OldTRID:string = "";
  OldTUID:string = "";
  Tclicked: boolean;
  TeamError:string = "";
  TANID:number=0;
  @ViewChild("dtT") tableT: Table;

  departmentsAData:any=[];
  rolesAData:any=[];
  usersAData:any=[];
  selectedApproverDepartment:any;
  selectedApproverRole:any;
  selectedApproverUser:any;
  departmentNameAError: any;
  roleNameAError: any;
  userNameAError: any;
  EditARow: boolean = false;
  OldADID:string = "";
  OldARID:string = "";
  OldAUID:string = "";
  Aclicked: boolean;
  ApproverError:any;
  TANAID:number=0;
  @ViewChild("dtA") tableA: Table;

  departmentsNData:any=[];
  rolesNData:any=[];
  usersNData:any=[];
  selectedNotiDepartment:any;
  selectedNotiRole:any;
  selectedNotiUser:any;
  departmentNameNError: any;
  roleNameNError: any;
  userNameNError: any;
  EditNRow: boolean = false;
  OldNDID:string = "";
  OldNRID:string = "";
  OldNUID:string = "";
  Nclicked: boolean;
  NotiError:any;
  TANNID:number=0;
  @ViewChild("dtN") tableN: Table;
  
  originalPhaseDocsData:any=[];
  originalPhaseTaskData:any=[];
  step2TaskDataById:any=[];
  step2DocDataById:any=[];
  isFromSecondStepFromLogin:boolean=false;
  originalPhaseTeamData:any=[];
  Step3Error:any;
  isFromThirdStepFromLogin:boolean;
  description:any;
  FinalProductByIdData:any;
  index:Number;
  deleteDisplay:boolean=false;
  docId:number=0;
  AreYouSureText:any;
  deleteADisplay:boolean=false;
  deleteNDisplay:boolean=false;
  deleteTDisplay:boolean=false;
  constructor(private _ManageCustomFieldsService: ManageCustomFieldsService,
    private router: Router,private pipe:DatePipe,
    private route:ActivatedRoute,private _productService:ProductService) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.items = [{
      label: 'Project Header',
  },
  {
    label: 'Phases and Teams',
  },
  {
      label: 'Phases, Milestones and Tasks',
  },
  {
    label: 'Preview and Confirmation',
}
];
var PID = this.route.snapshot.paramMap.get('PId');
if(PID!=null && PID!=""&&PID!=undefined){
  sessionStorage.tmpProductId = parseInt(PID);
  this.tmpProductId=PID;
  this.GetProductById(parseInt(PID),"");
}
else{
  var tmpProductId=sessionStorage.getItem('tmpProductId');
  if(tmpProductId!=null && tmpProductId!=""&&tmpProductId!=undefined){
    this.tmpProductId=parseInt(tmpProductId);
    if(this.tmpProductId>0){
      this.GetProductById(parseInt(tmpProductId),"");
    }
    else{
      this.GetProductOwners("");
      this.GetProductLine("");
    }
  }
  else{
    this.tmpProductId=0;
    this.GetProductOwners("");
    this.GetProductLine("");
  }
  
}
  }
  CustomAttribute(event,value,customFieldID,source,ParentDependent){
    if(this.OriginalCustomFieldgroupId.length > 0)
    {
      var selectedValueBind =this.OriginalCustomFieldgroupId.find(m=>m.customFieldID==customFieldID);
      if(selectedValueBind.entryType.toLowerCase().includes("textbox") && selectedValueBind.fieldDataType.toLowerCase().includes("decimal"))
      {
        var e;
        e = selectedValueBind.fieldDataType.toLowerCase().replace("decimal(", "");
        e = e.toLowerCase().replace(")", ""); 
        var splitted = e.split(",");
        var resut;
        if(value.includes("."))
          resut=  new RegExp("^[0-9]{0," + splitted[0] +"}\.?[0-9]{0," + splitted[1] + "}$").test(value);
        else
          resut=  new RegExp("^[0-9]{0," + splitted[0] +"}$").test(value);
        if (!resut)
        {
          event.target.value = "";
          value = "";
        }
      }
      else if(selectedValueBind.entryType.toLowerCase().includes("textbox") && selectedValueBind.fieldDataType.toLowerCase().includes("date"))
      {
        let timestamp = Date.parse(value);
        if (isNaN(timestamp)) {
          event.target.value = "";
          value = "";
        }
      }
      var valueName= source!=null && source.length>0?source.find(m=>m.id==value):"";
      if(selectedValueBind!=null){
        selectedValueBind.value=value;
        if(selectedValueBind.entryType=="DropDown"){
          selectedValueBind.customFieldValueName=valueName.name;
        }
        else{
          selectedValueBind.customFieldValueName=value;
        }
      }
      if(this.CustomFieldsFromById!=null && this.CustomFieldsFromById.length>0){
        var custFiedl =this.CustomFieldsFromById.find(m=>m.customFieldID==customFieldID);
        if(custFiedl!=null){
          custFiedl.customFieldValue=value;
          if(custFiedl.entryType=="DropDown"){
            custFiedl.customFieldValueName=valueName.name;
          }
          else{
            custFiedl.customFieldValueName=value;
          }
        }
      }
      if(value != null && value != undefined && value != "" && ParentDependent)
      { 
        this._ManageCustomFieldsService
          .GetDependentAttributes(customFieldID)
          .subscribe((data) => {
            var list = [];
            list =  data.returnObject;
            if(list != null && list != undefined)
            {
              if(list.length > 0)
              {
                list.forEach(element => {
                  this._ManageCustomFieldsService
                  .GetDataDependent(element.dataSource,value)
                  .subscribe((data) => {
                    this.showLoading=false;
                    this.datasourcelist[element.customFieldID] = {
                      list: data.returnObject,
                      value: "",
                    };
                  });
                });
              }
            }
          });
      }
    }
  }

  ChangeTemplate(){
    this.showLoading = true;
    this.Step1Error="";
    this.OriginalCustomFieldgroupId=[];
    this.customGroupsData=[];
    this.showCust=false;
    this.GetCustomGroups(this.selectedTemplate.templateGUID,"");
  }

  step1(type){
    this.Step1Error="";
    if(this.selectedProdLine==null||this.selectedProdLine==""||this.selectedProdLine==undefined){
      this.Step1Error="Please select Product Line";
    }
    else if(this.selectedTemplate==null||this.selectedTemplate==""||this.selectedTemplate==undefined){
      this.Step1Error="Please select Template";
    }
    else if(this.productName==null||this.productName==""||this.productName==undefined){
      this.Step1Error="Please enter Project Name";
    }
    else if((this.targetDate==null||this.targetDate==""||this.targetDate==undefined) &&type==""){
      this.Step1Error="Please select Target Start Date";
    }
    else if((this.selectedProdOwner==null||this.selectedProdOwner==""||this.selectedProdOwner==undefined)&&type==""){
      this.Step1Error="Please select Product Owner";
    }
    // else if((this.description==null ||this.description==""||this.description==undefined)&&type==""){
    //   this.Step1Error="Please enter description";
    // }
    else{
      let isRequired=false;
      var mandatorFieldList = this.OriginalCustomFieldgroupId.filter(m=>m.isRequired==true);
      mandatorFieldList.forEach(element => {
        if(!element.fieldName.includes("Product Line")){
          if(element.value==null||element.value==""||element.value==undefined||element.value=="null"){
            isRequired=true;
           }
        }
      });
      if(isRequired && type==""){
        this.Step1Error="Please fill all mandatory fields.";
      }
      else{
        let allok = true;
        let copyvalues=[];
        this.OriginalCustomFieldgroupId.forEach(element => {
          let insert = false;
          if(element.value!=null && element.value!=""&&element.value!=undefined&&element.value!="null"){
            var custGrp = this.customGroupsData.find(m=>m.templateCustomGroupID==element.templateCustomGroupID);
            if(element.fieldName.includes("Product Line")){
              var custData={
                "customFieldId":element.customFieldID,
                "templateGuid": element.templateGuid,
                "customGroupGuid": custGrp.customGroupGUID,
                "customFieldGuid": element.customFieldGUID,
                "FieldName": element.fieldName,
                "isRequired":element.isRequired,
                "customFieldValue": this.selectedProdLine.id,
                "customFieldValueName":this.selectedProdLine.name,
                "TmpProductId":this.tmpProductId,
                "CustomGroupName":custGrp.customGroupName,
                "EntryType":element.entryType,
                "DataSource":element.dataSource,
                "Seq":element.seq,
                "CCBApproval":element.ccbApproval
              }
              copyvalues.push(custData);
            }
            else if (element.fieldName.includes("Die Code (Root)"))
            {
              if(element.value!=null && element.value!=null && element.value!="" 
              && element.value!=undefined && (element.value?.length<5 ||element.value?.length>6)){
                this.showLoading = false;
                allok = insert = false;
                this.Step1Error="Please enter minimum 5 and maximum 6 characters for Die Code (Root).";
              }
              else
                insert = true
            }
            else if (element.fieldName.includes("Die Code Rev (d)"))
            {
              if(element.value!=null && element.value!=null && element.value!="" 
              && element.value!=undefined && element.value?.length>1){
                this.showLoading = false;
                allok = insert = false;
                this.Step1Error="Please enter maximum 1 characters for Die Code Rev (d).";
              }
              else
                insert = true;
            }
            else if (element.fieldName.includes("Die Code Rev (e)"))
            {
              if(element.value!=null && element.value!=null && element.value!="" 
              && element.value!=undefined && element.value?.length>1){
                this.showLoading = false;
                allok = insert = false;
                this.Step1Error="Please enter maximum 1 characters for Die Code Rev (e).";
              }
              else
                insert = true;
            }
            else
              insert = true;
            if(insert)
            {
              var custData={
                "customFieldId":element.customFieldID,
                "templateGuid": this.selectedTemplate.templateGUID,
                "customGroupGuid": custGrp.customGroupGUID,
                "customFieldGuid": element.customFieldGUID,
                "FieldName": element.fieldName,
                "isRequired":element.isRequired,
                "customFieldValue": element.value,
                "customFieldValueName":element.customFieldValueName,
                "TmpProductId":this.tmpProductId,
                "CustomGroupName":custGrp.customGroupName,
                "EntryType":element.entryType,
                "DataSource":element.dataSource,
                "Seq":element.seq,
                "CCBApproval":element.ccbApproval
              }
              copyvalues.push(custData);
            }
          }
        });
          if(allok)
          {
           var data= {
             "tmpProductId":this.tmpProductId,
              "productName": this.productName,
              "startDate": new Date(this.pipe.transform(this.targetDate,"MM/dd/yyyy hh:mm")),
              "actionBy": this.userId,
              "templateGuid": this.selectedTemplate.templateGUID,
              "templateName": this.selectedTemplate.templateName,
              "productLineId": this.selectedProdLine.id,
              "productOwnerId":this.selectedProdOwner!=""&&this.selectedProdOwner!=null&&this.selectedProdOwner!=undefined?this.selectedProdOwner.userGuid:null,
              "ProductLine":this.selectedProdLine!=""&&this.selectedProdLine!=null&&this.selectedProdLine!=undefined?this.selectedProdLine.name:"",
              "description":this.description,
              "customFields": copyvalues,
              "Active":true,
              "Step":type=="R"?0:1,
              "ChildType":"",
              "Type": "P",
              "ChildLevel":0,
              "MilestoneID":"",
              "DepartmentID":""
           }

           this.showLoading = true;
           console.log("Saved Data:" +JSON.stringify(data));
            this._productService.SaveTmpProductCustomFields(data).subscribe(
              (data) => {
                if(data.returnCode==0){
                  if(type=="R"){
                    this.router.navigate(['/productcreation']);
                  }
                  else{
                    this.tmpProductId=data.returnObject.tmpProductID;
                    sessionStorage.tmpProductId=this.tmpProductId;
                      this.GetProductById(this.tmpProductId,"CF");
                  }
                }
                else{
                  this.showLoading = false;
                  this.Step1Error=data.returnMessage;
                }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              } 
            );
          }
      }
    }
  }

  step3(type){
      let phases=[];
      if(this.PhasesData!=null && this.PhasesData.length>0){
        this.PhasesData.forEach(element => {
          let phaseTasks=[];
          if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
            var phasesTasks = this.originalPhaseTaskData.filter(m=>m.phaseID==element.phaseID);
            if(phasesTasks!=null){
              phasesTasks.forEach(element => {
                var phaseTaks={
                  "PhaseID":element.phaseID,
                  "TaskSeq":element.taskSeq,
                  "DepartmentGuid":element.departmentGUID,
                  "DepartmentName":element.departmentName,
                  "TaskName":element.taskName,
                  "IsRequired":element.isRequired,
                  "Predecessor":element.predecessor,
                  "AssignTo":element.assignTo,
                  "taskPredecessors":element.taskPredecessors,
                  "CycleTime":element.cycleTime,
                  "CycleTimeEdit":element.cycleTimeEdit,
                  "OverlapDays":element.overlapDays,
                  "OverlapDaysEdit":element.overlapDaysEdit,
                  "ProportionateTime":element.proportionateTime,
                  "taskAssignees":element.taskAssignees,
                  "IsWeekend":element.isWeekend,
                  "AllowMultipleAssignee":element.allowMultipleAssignee,
                  "Override" :element.override,
                  "StartDate":element.startDate!=null && element.startDate!="--"&&element.startDate!=undefined?this.pipe.transform(element.startDate,"MM/dd/yyyy hh:mm"):null,
                  "EndDate":element.endDate!=null && element.endDate!="--"&&element.endDate!=undefined?this.pipe.transform(element.endDate,"MM/dd/yyyy hh:mm"):null
                }
                phaseTasks.push(phaseTaks);
              });
            }
          }
          let phaseDocuments=[];
          if(this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0){
            var phasesDocs = this.originalPhaseDocsData.filter(m=>m.phaseID==element.phaseID);
            if(phasesDocs!=null){
              phasesDocs.forEach(element => {
                var phaseDoc={
                  "PhaseID":element.phaseID,
                  "RequiredDocumentID":element.requiredDocumentID,
                  "DepartmentGUID":element.departmentGUID,
                  "DepartmentName":element.departmentName,
                  "RoleGUID":element.roleGUID,
                  "RoleName":element.roleName,
                  "DocumentName":element.documentName,
                  "DocumentLabel":element.documentLabel,
                  "Seq":element.seq,
                  "IsRequired":element.isRequired,
                  "TemplatesNames":element.templatesNames,
                  "Templates":element.templates
                }
                phaseDocuments.push(phaseDoc);
              });
            }
          }
          var owner="";
          if(element.phaseOwnerGUID!=null &&element.phaseOwnerGUID!=""&&element.phaseOwnerGUID!=undefined){
            owner = element.phaseOwnerGUID;
          }
          else if(this.selectedProdOwner!=null &&this.selectedProdOwner!=""&&this.selectedProdOwner!=undefined){
            owner = this.selectedProdOwner.userGuid;
          }
          var phase={
            "PhaseID":element.phaseID,
            "TmpProductID":this.tmpProductId,
            "PhaseName":element.phaseName,
            "PhaseSeq":element.phaseSeq,
            "PhaseOwnerGUID":owner,
            "Tasks":phaseTasks,
            "Documents":phaseDocuments
          }
          phases.push(phase);
        });
      }
         var data= {
               "tmpProductId":this.tmpProductId,
               "Active":true,
                "phases":phases,
                "Step":type=="R"?2:3
             }
             console.log("2nd Step Saved Data:" +JSON.stringify(data));
             this.showLoading = true;
          this._productService.SaveTmpPhaseTasksAndDocuments(data).subscribe(
            (data) => {
              if(data.returnCode==1){
                if(type=="R"){
                  this.router.navigate(['/productcreation']);
                }
                else{
                 this.GetProductById(this.tmpProductId,"");
                }
              }
              else{
                this.showLoading = false;
                this.Step2Error=data.returnMessage;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            } 
          );
  }

  CycleTimeChange(id,event,phaseID){
    var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
    if(originalPhaseTeam!=null){
      var cycle= event.target.value;
      if(cycle!=null && cycle!=""&&cycle!=undefined){
        originalPhaseTeam.cycleTime=parseInt(cycle);
      }
      else{
        originalPhaseTeam.cycleTime=0;
      }
    }
    this.Getdates(phaseID);
}

OverlapChange(id,event,phaseID){
  var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
  if(originalPhaseTeam!=null){
    var cycle= event.target.value;
    if(cycle!=null && cycle!=""&&cycle!=undefined){
      originalPhaseTeam.overlapDays=parseInt(cycle);
    }
    else{
      originalPhaseTeam.overlapDays=0;
    }
  }
  this.Getdates(phaseID);
}

Getdates(phaseID)
{
  let phaseTasks=[];
    if(this.PhasesData!=null && this.PhasesData.length>0){
      this.PhasesData.forEach(element => {
        if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
          var phasesTasks = this.originalPhaseTaskData.filter(m=>m.phaseID==element.phaseID);
          if(phasesTasks!=null){
            phasesTasks.forEach(element => {
              var phaseTaks={
                "PhaseID":element.phaseID,
                "TaskID":element.taskID,
                "TaskSeq":element.taskSeq,
                "DepartmentGuid":element.departmentGUID,
                "DepartmentName":element.departmentName,
                "TaskName":element.taskName,
                "IsRequired":element.isRequired,
                "Predecessor":element.predecessor,
                "AssignTo":element.assignTo,
                "taskPredecessors":element.taskPredecessors,
                "CycleTime":element.cycleTime,
                "CycleTimeEdit":element.cycleTimeEdit,
                "OverlapDays":element.overlapDays,
                "OverlapDaysEdit":element.overlapDaysEdit,
                "ProportionateTime":element.proportionateTime,
                "taskAssignees":element.taskAssignees,
                "IsWeekend":element.isWeekend,
                "Override" :element.override,
                "AllowMultipleAssignee":element.allowMultipleAssignee,
                "StartDate":element.startDate!=null && element.startDate!="--"&&element.startDate!=undefined?this.pipe.transform(element.startDate,"MM/dd/yyyy hh:mm"):null,
                "EndDate":element.endDate!=null && element.endDate!="--"&&element.endDate!=undefined?this.pipe.transform(element.endDate,"MM/dd/yyyy hh:mm"):null
              }
              phaseTasks.push(phaseTaks);
            });
          }
        }
      });
    }
    this.showLoading = true;
    var startdate = new Date(this.pipe.transform(this.targetDate,"MM/dd/yyyy"));
    let da = this.pipe.transform(startdate,"MM/dd/yyyy");
    this._productService.GetDates(phaseTasks,da).subscribe(
      (data) => {
        if(data.returnCode==0){
          this.showLoading = false;
          this.originalPhaseTaskData = data.returnObject;
          if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
            this.originalPhaseTaskData.forEach(element => {
              var t = element.taskAssignees[0];
              element.taskAssignees1 = t;
              if(!element.allowMultipleAssignee)
              {
                element.taskAssignees=[];
                element.taskAssignees.push(t);
                element.assignTo = t.assignedName;
              }
            });
            this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==phaseID);
            this.BindTaskTeam(phaseID);
          }
        }
        else{
          this.showLoading = false;
          this.Step2Error=data.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
}
private BindTaskTeam(PhaseID)
{
  var team = this.originalPhaseTeamData.filter(m=>m.phaseID==PhaseID);
  if(this.PhaseTaskData != null && this.PhaseTaskData != undefined)
  {
    if(this.PhaseTaskData.length > 0)
    {
      this.PhaseTaskData.forEach(element => {
        element.TeamData = [];
        var depts = team.filter(x=>x.departmentGUID.toUpperCase() == element.departmentGUID.toUpperCase() && x.type.toLowerCase()=="team");
        if(depts != null && depts != undefined)
        {
          if(depts.length > 0)
          {
            depts.forEach(e => {
              var m = {
                "taskID" : element.taskID,
                "assignedTo" : e.userGUID,
                "assignedName" : e.userName
              };
              element.TeamData.push(m);
            });
          }
        }
        var t = element.taskAssignees[0];
        element.taskAssignees1 = t;
        if(!element.allowMultipleAssignee)
        {
          element.taskAssignees=[];
          element.taskAssignees.push(t);
          element.assignTo = t.assignedName;
        }
      });
    }
  }
}
  step2(type){
    this.RESETTEAM();
    let isValidate = true;
    this.Step3Error="";
    if(this.PhasesData!=null && this.PhasesData.length>0 && !this.selectedTemplate.templateName.toUpperCase().includes("OLD")){
      this.PhasesData.forEach(element => {
        if(element.validateDepartmentGUID!=null &&element.validateDepartmentGUID.length>0){
          var data = this.originalPhaseTeamData.filter(m=>m.type=="Team" && m.phaseID==element.phaseID);
          if(data!=null && data.length>0){
            element.validateDepartmentGUID.forEach(element1 => {
             var data1 = data.find(m=>m.departmentGUID.toUpperCase()==element1.toUpperCase());
             if(data1==null && data1==undefined){
              isValidate= false;
              var dept = this.departmentsData.find(m=>m.departmentGuid.toUpperCase()==element1.toUpperCase());
              this.Step3Error=dept.departmentName+" department should be there in "+element.phaseName+" in Team section";
              return;
             }
            });
          }
        }
      });
    }
   if(isValidate){
    let phases=[];
    if(this.PhasesData!=null && this.PhasesData.length>0){
      this.PhasesData.forEach(element => {
        let teams=[];
        if(this.originalPhaseTeamData!=null && this.originalPhaseTeamData.length>0){
          var phasesTeams = this.originalPhaseTeamData.filter(m=>m.phaseID==element.phaseID);
          if(phasesTeams!=null){
            phasesTeams.forEach(element => {
              var phaseTeam={
                "PhaseID":this.isFromSecondStepFromLogin?element.phaseID:0,
                 "Type":element.type,
                 "DepartmentGuid":element.departmentGUID,
                 "DepartmentName":element.departmentName,
                 "RoleName":element.roleName,
                 "RoleGuid":element.roleGUID,
                "UserGuid":element.userGUID,
                "UserName":element.userName
              }
              teams.push(phaseTeam);
            });
          }
        }
        var owner="";
        if(element.phaseOwnerGUID!=null &&element.phaseOwnerGUID!=""&&element.phaseOwnerGUID!=undefined){
          owner = element.phaseOwnerGUID;
        }
        else if(this.selectedProdOwner!=null &&this.selectedProdOwner!=""&&this.selectedProdOwner!=undefined){
          owner = this.selectedProdOwner.userGuid;
        }
        var phase={
          "PhaseID":this.isFromSecondStepFromLogin?element.phaseID:0,
          "TmpProductID":this.tmpProductId,
          "PhaseName":element.phaseName,
          "PhaseSeq":element.phaseSeq,
          "PhaseOwnerGUID":owner,
          "Team":teams
        }
        phases.push(phase);
      });
    }
       var data= {
             "tmpProductId":this.tmpProductId,
              "phases":phases,
              "Active":true,
              "Step":type=="R"?1:2
           }
          console.log("Team Saved Data:" +JSON.stringify(data));
          this.showLoading = true;
       this._productService.SaveTmpPhaseTeam(data).subscribe(
         (data) => {
           if(data.returnCode==1){
             if(type=="R"){
               this.router.navigate(['/productcreation']);
             }
             else{
              this.GetProductById(this.tmpProductId,"");
             }
           }
           else{
              this.showLoading = false;
             this.Step3Error=data.returnMessage;
           }
         },
         (error) => {
           this.showLoading = false;
           console.log(error);
         } 
       );
   }
  }

  stepBack1(){
    this.RESETTEAM();
    this.GetCustomGroups(this.selectedTemplate.templateGUID,"");
    this.activeIndex=0;
  }
  stepBack2(){
    this.isFromSecondStepFromLogin= true;
    this.GetDepartments();
    if(this.originalPhaseTeamData!=null && this.originalPhaseTeamData.length>0){
      this.PhaseTeamData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
      }
    this.activeIndex=1;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  stepBack3(){
    this.isFromThirdStepFromLogin= true;
    this.selectedPhaseOwner = [];
    this.phaseOwnerData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID && m.type.toLowerCase() == "team");
    this.selectedPhaseOwner = this.phaseOwnerData.find(x=> x.userGUID.toUpperCase() == this.PhasesData[0].phaseOwnerGUID.toUpperCase() );
    if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
      this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
      this.BindTaskTeam(this.PhasesData[0].phaseID);
    }
    if(this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0){
    this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
    this.ngAfterViewInit();
    }
    this.activeIndex=2;
  }
 
  GetTemplates(productLineId,templateGuid){
    this._productService.GetTemplates(productLineId,"P",false,this.userId).subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetTemplates data:"+JSON.stringify(data.returnObject));
          this.templatesData=data.returnObject;
          if(templateGuid!=null&&templateGuid!=""&&templateGuid!=undefined){
            this.selectedTemplate=this.templatesData.find(m=>m.templateGUID==templateGuid);
          }
          else
          {
            var v = this.templatesData[0].templateGUID;
            this.selectedTemplate=this.templatesData.find(m=>m.templateGUID==v);
            this.ChangeTemplate();
          }
        }
        if(templateGuid == "")
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  } 

  GetProductOwners(productOwnerId){
    this._productService.GetProductOwners().subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          // console.log("GetProductOwners data:"+JSON.stringify(data.returnObject));
          data.returnObject.forEach(element => {
            element.fullName=element.firstName+" "+element.lastName;
          });
          this.prodOwnerData=data.returnObject;
          if(productOwnerId!=null&&productOwnerId!=""&&productOwnerId!=undefined){
            this.selectedProdOwner=this.prodOwnerData.find(m=>m.userGuid.toUpperCase()==productOwnerId.toUpperCase());
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetCustomGroups(templateGuid,from){
    this._productService.GetCustomGroups(templateGuid).subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
         if(this.customGroupsData == null || this.customGroupsData == undefined || this.customGroupsData.length == 0)
      this.customGroupsData =data.returnObject;
          console.log("GetCustomGroups data:"+JSON.stringify(this.customGroupsData));
          this.showCust=true;
          
          this.getFieldsByGroupID(from);
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetCustomFieldsById(item){
    this.customGroupsData.forEach((value, key) => {
      if(key==item.index){
        this.BindCustomAttributeByGroup(value.templateCustomGroupID,"");
      }
    });
  }

  getFieldsByGroupID(from) {
    this.showLoading=true;
    if(this.selectedTemplate != null && this.selectedTemplate != undefined)
    {
      this._productService
      .GetCustomFields(this.selectedTemplate.templateGUID,"P","")
      .subscribe((data) => {
        this.showLoading=false;
        this.OriginalCustomFieldgroupId = data.returnObject;
        console.log("this.getFieldsByGroupID----", this.OriginalCustomFieldgroupId);
        this.BindCustomAttributeByGroup(this.customGroupsData[0].templateCustomGroupID,from);
      });
    }
  }
  ngAfterViewInit() {
    if(this.table != undefined)
    {
      var id = this.PhaseDockData[0].phaseID;
      this.table.onRowReorder.subscribe(data => {
        var dropIndex = data.dropIndex + 1;
        var dragIndex = data.dragIndex + 1;
        if(dragIndex > dropIndex)
        {
          this.originalPhaseDocsData.forEach(e =>
          {
            if(e.seq >= dropIndex && e.seq <= dragIndex && e.phaseID == id)
            {
              if(e.seq == dragIndex)
                e.seq = dropIndex;
              else
                e.seq = e.seq + 1;
            }
          });
        }
        if(dragIndex < dropIndex)
        {
          this.originalPhaseDocsData.forEach(e =>
          {
            if(e.seq <= dropIndex && e.seq >= dragIndex && e.phaseID == id)
            {
              if(e.seq == dragIndex)
                e.seq = dropIndex;
              else
                e.seq = e.seq - 1;
            }
          });
        }
      });
      this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID == id);
    }
  }

  BindCustomAttributeByGroup(groupId,from){
    this.CustomFieldgroupId= this.OriginalCustomFieldgroupId.filter(m=>m.templateCustomGroupID==groupId);
   if (this.CustomFieldgroupId!=null && this.CustomFieldgroupId.length > 0) {
      this.CustomFieldgroupId.forEach((element) => {
        let colname = element.customFieldID.toString();
       
        if(element.fieldName.includes("MPW-ShuttleProj Grp")
         && ((element.value==""||element.value==null||element.value==undefined) && this.tmpProductId <=0)){
          element.value="Standard";
        }
        element.length = 0;
        if(element.entryType == "TextBox")
        {
            if(element.fieldDataType.toLowerCase().includes("varchar"))
            {
              var e;
              e = element.fieldDataType.toLowerCase().replace("varchar(", "");
              e = e.toLowerCase().replace(")", ""); 
              element.length = Number(e);
              element.fieldName = element.fieldName + " ("+ e + " char)";
            }
            else if(element.fieldDataType.toLowerCase().includes("decimal"))
            {
              var e;
              e = element.fieldDataType.toLowerCase().replace("decimal(", "");
              e = e.toLowerCase().replace(")", ""); 
              e = e.toLowerCase().replace(" ", ""); 
              var splitted = e.split(",");
              if(Number(splitted[1]) > 0)
                element.length = Number(splitted[0]) + Number(splitted[1]) + 1;
              else
                element.length = Number(splitted[0]) + Number(splitted[1]);
              element.fieldName = element.fieldName + " (";
              for (let i = 0; i < splitted[0]; i++) {
                element.fieldName = element.fieldName + "n";
              }
              if(Number(splitted[1]) > 0)
              {
                element.fieldName = element.fieldName + ".";
                for (let i = 0; i < splitted[1]; i++) {
                  element.fieldName = element.fieldName + "n";
                }
              }
              element.fieldName = element.fieldName + ")";
            }
            else if(element.fieldDataType.toLowerCase().includes("date"))
              element.fieldName = element.fieldName + " (mm/dd/yyyy)";
        }
        else
        {
          this.datasourcelist[colname] = {
            list: [],
            value: ""
          };
        }
        this.DataSourceAsQuery(element.dataSource, colname, element.value,element.customFieldID,element.isDependent,element.dependentID);
      });
      if(from=="ById"){
        this.GetPhases(this.FinalProductByIdData.templateGUID,1);
      }
    }
    console.log(JSON.stringify(this.datasourcelist))
  }

  DataSourceAsQuery(query, colname,Value,customFieldID,isDependent,dependentID) {
    this.showLoading=true;
    if(this.CustomFieldsFromById!=null && this.CustomFieldsFromById.length>0)
    {
      var custFiedl =this.CustomFieldsFromById.find(m=>m.customFieldID==customFieldID);
      if(custFiedl!=null)
        Value=custFiedl.customFieldValue;
      if(this.OriginalCustomFieldgroupId!=null && this.OriginalCustomFieldgroupId.length>0)
      {
        this.OriginalCustomFieldgroupId.forEach(element => {
          var custFId = this.CustomFieldsFromById.find(m=>m.customFieldID==element.customFieldID);
          if(custFId!=null){
            if(element.customFieldID==custFId.customFieldID){
              element.customFieldValue = custFId.customFieldValue;
              element.value=custFId.customFieldValue;
              element.customFieldValueName = custFId.customFieldValueName;
            }
          }
        });
      }
    }
    if(Value == null || Value == undefined)
      Value = "";
    if(query != null && query != "null")
    {
      if(isDependent)
      {
        var dep = this.CustomFieldgroupId.filter(x=>x.customFieldID.toString() == dependentID.toString());
        if(dep != null && dep != undefined)
        {
          if(dep[0].value != null && dep[0].value != undefined)
          {
            this._ManageCustomFieldsService
            .GetDataDependent(query,dep[0].value)
            .subscribe((data) => {
              this.showLoading=false;
              this.datasourcelist[colname] = {
                list: data.returnObject,
                value: Value,
              };
            });
          }
        }
      }
      else
      {
        this._ManageCustomFieldsService
        .DataSourceAsQuery(query)
        .subscribe((data) => {
          this.showLoading=false;
          this.datasourcelist[colname] = {
            list: data.returnObject,
            value: Value,
          };
        });
      }
    }
    else
    { 
      this.datasourcelist[colname] = {
        list: [],
        value: Value,
      };
      this.showLoading=false;
    }
  }

  GetPhases(templateGuid,activeIndex){
    this.showLoading = true;
    this._productService.GetPhases(templateGuid,"P","","").subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetPhases data:"+JSON.stringify(data.returnObject));
          this.PhasesData=data.returnObject;
          if(activeIndex==2){
            this.activeIndex=activeIndex;
            this.selectedPhaseOwner = [];
            this.phaseOwnerData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID && m.type.toLowerCase() == "team");
            this.selectedPhaseOwner = this.phaseOwnerData.find(x=> x.userGUID.toUpperCase() == this.PhasesData[0].phaseOwnerGUID.toUpperCase());
            if(!this.isFromSecondStepFromLogin){
              this.GetPhaseTasks(templateGuid);
              this.GetPhaseDocuments(templateGuid);
            }
            else{
              if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
                this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
                this.BindTaskTeam(this.PhasesData[0].phaseID);
              }
              if(this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0){
              this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
              this.ngAfterViewInit();
              }
              this.showLoading = false;
            }
          }
          else if(activeIndex==1){
          this.activeIndex=activeIndex;
          if(!this.isFromThirdStepFromLogin){
            this.GetPhaseTeam(templateGuid);
          }
          else{
            if(this.originalPhaseTeamData!=null && this.originalPhaseTeamData.length>0){
            this.PhaseTeamData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
            }
            this.showLoading = false;
          }
          }
        }
        else{
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetPhaseTasks(templateGuid){
    this.showLoading = true;
    var startdate = new Date(this.pipe.transform(this.targetDate,"MM/dd/yyyy"));
    let da = this.pipe.transform(startdate,"MM/dd/yyyy");
    this._productService.GetPhaseTasks(templateGuid, da,this.tmpProductId,"P","",0,"","").subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetPhaseTasks data:"+JSON.stringify(data.returnObject));
          // data.returnObject.forEach(element => {
          //   if ( element.startDate !== null &&  element.startDate !== undefined && element.startDate != "--" )
          //   element.startDate = new Date(new Date(element.startDate).toDateString() + " " + new Date(element.startDate).toTimeString() + " UTC");
          //   if ( element.endDate !== null &&  element.endDate !== undefined && element.endDate != "--")
          //   element.endDate = new Date(new Date(element.endDate).toDateString() + " " + new Date(element.endDate).toTimeString() + " UTC");
          // });
          this.originalPhaseTaskData= data.returnObject;
          if(this.originalPhaseTaskData.length > 0)
          {
            this.originalPhaseTaskData.forEach(element => {
              var t = element.taskAssignees[0];
              element.taskAssignees1 = t;
              if(!element.allowMultipleAssignee)
              {
                element.taskAssignees=[];
                element.taskAssignees.push(t);
                element.assignTo = t.assignedName;
              }
            });
            this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
            this.BindTaskTeam(this.PhasesData[0].phaseID);
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetPhaseDetails(item){
    this.showLoading=true;
    // this.index = 0;
    this.Step2Error="";
    if(this.PhasesData!=null && this.PhasesData.length>0){
      this.PhasesData.forEach((value, key) => {
        if(key==item.index){
          this.selectedPhaseOwner = [];
          this.phaseOwnerData = this.originalPhaseTeamData.filter(m=>m.phaseID==value.phaseID && m.type.toLowerCase() == "team");
          this.selectedPhaseOwner = this.phaseOwnerData.find(x=> x.userGUID.toUpperCase() == value.phaseOwnerGUID.toUpperCase() );

          if(this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0){
            this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID==value.phaseID);
            this.ngAfterViewInit();
          }
          if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
            this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==value.phaseID);
            this.BindTaskTeam(value.phaseID);
          }
        }
      });
    }
    this.showLoading=false;
  }

  GetPhaseDocuments(templateGuid){
    this.showLoading = true;
    this._productService.GetPhaseDocuments(templateGuid,this.tmpProductId,"P","","","",this.userintId).subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetPhaseDocuments data:"+JSON.stringify(data.returnObject));
          this.originalPhaseDocsData =data.returnObject;
          this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
          this.ngAfterViewInit();
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetProductLine(productLineId){
    this._productService.GetProductLine().subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetProductLine data:"+JSON.stringify(data.returnObject));
          this.ProdLineData=data.returnObject;
          if(productLineId!=null && productLineId!=""&&productLineId!=undefined){
            this.selectedProdLine = this.ProdLineData.find(m=>m.id.toUpperCase()==productLineId.toUpperCase());
          }
        }
        if(productLineId == "")
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  ChangeProduLine(){
    this.showLoading = true;
    this.Step1Error="";
    this.OriginalCustomFieldgroupId=[];
    this.customGroupsData=[];
    this.showCust=false;
    this.selectedTemplate="";
    this.GetTemplates(this.selectedProdLine.id,"");
  }

  GetProductById(PID,from){
    let startdate;
    let da = "";
    this.showLoading = true;
    if(from=="CF"){
      // startdate= new Date(this.pipe.transform(this.targetDate,"MM/dd/yyyy hh:mm","+0000")).toString();
      startdate= new Date(this.pipe.transform(this.targetDate,"MM/dd/yyyy"));
      da = this.pipe.transform(startdate,"MM/dd/yyyy");
    }
    this._productService.GetProductById(PID,da,this.userintId).subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetProductById data:"+JSON.stringify(data.returnObject));
          this.FinalProductByIdData = data.returnObject;
          this.GetProductByIDBinding();
        }
        else{
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetProductByIDBinding(){
    this.showLoading = true;
    this.tmpProductId=this.FinalProductByIdData.tmpProductID;
    this.productName = this.FinalProductByIdData.productName;
    this.description=this.FinalProductByIdData.description;
   this.CustomFieldsFromById=this.FinalProductByIdData.customFields;
   this.GetProductLine(this.FinalProductByIdData.productLineID); 
   this.GetTemplates(this.FinalProductByIdData.productLineID,this.FinalProductByIdData.templateGUID);
   //this.targetDate=this.FinalProductByIdData.startDate!=null&&this.FinalProductByIdData.startDate!=""&&this.FinalProductByIdData.startDate!=undefined?new Date(this.FinalProductByIdData.startDate):null;
   if(this.FinalProductByIdData.startDate!=null&&this.FinalProductByIdData.startDate!=""&&this.FinalProductByIdData.startDate!=undefined){
    // let convertactiondate = new Date(this.FinalProductByIdData.startDate+'Z');
    // this.targetDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
    this.targetDate = new Date(this.pipe.transform(this.FinalProductByIdData.startDate,"MM/dd/yyyy"));
   }
  this.GetProductOwners(this.FinalProductByIdData!=null&&this.FinalProductByIdData!=""&&this.FinalProductByIdData!=undefined?this.FinalProductByIdData.productOwnerID:"");
  
    if(this.FinalProductByIdData.phases!=null &&this.FinalProductByIdData.phases.length>0){
      this.PhasesData=[];
      this.originalPhaseTaskData=[];
      this.originalPhaseDocsData=[];
      this.originalPhaseTeamData=[];
      this.FinalProductByIdData.phases.forEach(element => {
        var phase={
          "phaseID": element.phaseID,
          "tmpProductID": element.tmpProductID,
          "phaseName": element.phaseName,
          "phaseOwnerGUID": element.phaseOwnerGUID,
          "phaseSeq": element.phaseSeq,
          "validateDepartmentGUID":element.validateDepartmentGUID
        }
        this.PhasesData.push(phase);
        if(element.tasks!=null && element.tasks.length>0){
          this.isFromThirdStepFromLogin = true;
          element.tasks.forEach(element1 => {
            element1.phaseName=element.phaseName;
            // if ( element1.startDate !== null &&  element1.startDate !== undefined && element1.startDate != "--" )
            // element1.startDate = new Date(new Date(element1.startDate).toDateString() + " " + new Date(element1.startDate).toTimeString() + " UTC");
            // if ( element1.endDate !== null &&  element1.endDate !== undefined && element1.endDate != "--")
            // element1.endDate = new Date(new Date(element1.endDate).toDateString() + " " + new Date(element1.endDate).toTimeString() + " UTC");
            this.originalPhaseTaskData.push(element1);
          });
        }
        if(element.documents!=null && element.documents.length>0){
          element.documents.forEach(element1 => {
            element1.phaseName=element.phaseName;
            this.originalPhaseDocsData.push(element1);
          });
        }
        if(element.team!=null && element.team.length>0){
          this.isFromSecondStepFromLogin = true;
          element.team.forEach(element1 => {
            element1.phaseName=element.phaseName;
            this.originalPhaseTeamData.push(element1);
          });
        }
      });
      //this.GetPhaseOwners(this.PhasesData[0].phaseOwnerGUID,"load");
      this.try();
    }
    else
      this.try();
  }
  private try()
  {
    if(this.FinalProductByIdData.step==0){
      this.GetCustomGroups(this.FinalProductByIdData.templateGUID,"");
      this.activeIndex=0;
    }
    else if(this.FinalProductByIdData.step==2){
      this.selectedPhaseOwner = [];
      this.phaseOwnerData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID && m.type.toLowerCase() == "team");
      this.selectedPhaseOwner = this.phaseOwnerData.find(x=> x.userGUID.toUpperCase() == this.PhasesData[0].phaseOwnerGUID.toUpperCase() );
      if((this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0) ||
      (this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0)){
        if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
          this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
          this.BindTaskTeam(this.PhasesData[0].phaseID);
        }
        if(this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0){
        this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
        this.ngAfterViewInit();
        }
      }
      else{
        // this.GetPhaseOwners("","load");
        this.GetPhaseTasks(this.FinalProductByIdData.templateGUID);
        this.GetPhaseDocuments(this.FinalProductByIdData.templateGUID);
      }
      this.activeIndex=2;
    }
    else if(this.FinalProductByIdData.step==1){
      this.GetDepartments();
      if(this.originalPhaseTeamData!=null && this.originalPhaseTeamData.length>0){
        this.activeIndex=1;
        this.originalPhaseTeamData.forEach(element => {
          if(element.roleName.toUpperCase()=="PRODUCT ENGINEER")
          {
            var o = this.FinalProductByIdData!=null&&this.FinalProductByIdData!=""&&this.FinalProductByIdData!=undefined?this.FinalProductByIdData.productOwnerID:"";
            if(o != "" && element.userGUID.toUpperCase() != o.toUpperCase())
            {
              element.userGUID = o;
              this._productService.GetProductOwners().subscribe(
                (data) => {
                  if(data.returnCode==0 && data.returnObject!=null){
                    data.returnObject.forEach(element1 => {
                      if(element1.userGuid.toUpperCase() == o.toUpperCase())
                        element.userName=element1.firstName+" "+element1.lastName;
                    });
                  }
                },
                (error) => {
                  this.showLoading = false;
                  console.log(error);
                } 
              );
            }
          }
        });
        this.PhaseTeamData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
      }
      else{
        this.GetCustomGroups(this.FinalProductByIdData.templateGUID,"ById");
        //this.GetPhaseTeam(this.FinalProductByIdData.templateGUID);
      }
      
    }
    else{
      this.activeIndex = 3;
      this.PhasesData.forEach(element => {
        var PhaseTeamData = this.originalPhaseTeamData.filter(m=>m.phaseID==element.phaseID && m.type.toLowerCase() == "team");
        var ow = PhaseTeamData.find(x => x.userGUID.toUpperCase() == element.phaseOwnerGUID.toUpperCase());
        if(ow != null)
        element.ownerName = ow.userName;
      });
    }
    this.showLoading = false;
  }
  DocumentDelete(docId,docName){
    this.docId=docId;
    this.Step2Error = "";
    if(this.PhaseDockData!=null && this.PhaseDockData.length==1){
       this.Step2Error= "There should be at least one document.";
       return;
    }
    else{
      this.deleteDisplay=true;
      this.AreYouSureText="Are you sure you want to delete "+ docName+" ?";
    }
  }

  GetPhaseTeam(templateGuid){
    this.showLoading = true;
    this._productService.GetPhaseTeam(templateGuid,this.tmpProductId,"P","","").subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetPhaseTeam data:"+JSON.stringify(data.returnObject));
          this.originalPhaseTeamData=data.returnObject;
          this.PhaseTeamData=this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  GetTeamDetails(item){
    this.PhasesData.forEach((value, key) => {
      if(key==item.index){
        this.PhaseTeamData=this.originalPhaseTeamData.filter(m=>m.phaseID==value.phaseID);
      }
    });
    this.RESETTEAM();
  }
  DocumentRequired(docId,event) {
    const checked = event.target.ariaChecked;
    if (checked) {
      var originalPhaseDocsData=this.originalPhaseDocsData.find(m=>m.requiredDocumentId==docId);
      if(originalPhaseDocsData!=null){
        originalPhaseDocsData.isRequired=true;
      }
    }
    else {
      var originalPhaseDocsData=this.originalPhaseDocsData.find(m=>m.requiredDocumentId==docId);
      if(originalPhaseDocsData!=null){
        originalPhaseDocsData.isRequired=false;
      }
    }
  }
  GetDepartments(){
    this.showLoading = true;
    this._productService.GetDepartments().subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0 && data.returnObject!=null){
          console.log("GetDepartments data:"+JSON.stringify(data.returnObject));
          this.departmentsData=data.returnObject;
          this.departmentsNData=data.returnObject;
          this.departmentsAData=data.returnObject;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  newRow(t) { 
    if(this.PhaseTeamData != null && this.PhaseTeamData != undefined)
    {  
      if(t == "T")
      {
        if(this.tableT != null && this.tableT != undefined)
          this.tableT.value.forEach(x => { this.tableT.cancelRowEdit(x);  });
      }
      if(t == "A")
      {
        if(this.tableA != null && this.tableA != undefined)
          this.tableA.value.forEach(x => { this.tableA.cancelRowEdit(x);  });
      }
      if(t == "N")
      {
        if(this.tableN != null && this.tableN != undefined)
          this.tableN.value.forEach(x => { this.tableN.cancelRowEdit(x);  });
      }
    } 
    return { type:t, departmentName: '', roleName: '', userName: '' }; }

  onRowEdit(row,Type) {
    this.showLoading = true;
    this.TeamError = "";
    this.ApproverError = "";
    this.NotiError = "";
    this.PhaseTeamData = this.PhaseTeamData.filter(i => i.departmentName != "");
    if(Type == "T")
    {
      if(this.EditTRow)
      {
        if(this.tableT != null && this.tableT != undefined)
          this.tableT.value.forEach(x => { 
            if (this.OldTDID.toUpperCase() == x.departmentGUID.toUpperCase() && this.OldTRID.toUpperCase() == x.roleGUID.toUpperCase()
            && this.OldTUID.toUpperCase() == x.userGUID.toUpperCase())
              this.tableT.cancelRowEdit(x);  
        });
      }
      this.EditTRow = true;
      this.OldTDID = row.departmentGUID;
      this.OldTRID = row.roleGUID;
      this.OldTUID = row.userGUID;
      this.departmentNameError = '';
      this.roleNameError = '';
      this.userNameError = '';    
      this.Tclicked = true;
      this.selectedTeamDepartment = this.departmentsData.find(x=>x.departmentGuid.toUpperCase() == row.departmentGUID.toUpperCase());
      this.GetRolesByDepartment(row.departmentGUID,row.roleGUID,row.userGUID,Type);
    }
    if(Type == "A")
    {
      if(this.EditTRow)
      {
        if(this.tableA != null && this.tableA != undefined)
          this.tableA.value.forEach(x => { 
            if (this.OldADID.toUpperCase() == x.departmentGUID.toUpperCase() && this.OldARID.toUpperCase() == x.roleGUID.toUpperCase()
            && this.OldAUID.toUpperCase() == x.userGUID.toUpperCase())
              this.tableA.cancelRowEdit(x);  
        });
      }
      this.EditARow = true;
      this.OldADID = row.departmentGUID;
      this.OldARID = row.roleGUID;
      this.OldAUID = row.userGUID;
      this.departmentNameAError = '';
      this.roleNameAError = '';
      this.userNameAError = '';    
      this.Aclicked = true;
      this.selectedApproverDepartment = this.departmentsAData.find(x=>x.departmentGuid.toUpperCase() == row.departmentGUID.toUpperCase());
      this.GetRolesByDepartment(row.departmentGUID,row.roleGUID,row.userGUID,Type);
    }
    if(Type == "N")
    {
      if(this.EditNRow)
      {
        if(this.tableN != null && this.tableN != undefined)
          this.tableN.value.forEach(x => { 
            if (this.OldNDID.toUpperCase() == x.departmentGUID.toUpperCase() && this.OldNRID.toUpperCase() == x.roleGUID.toUpperCase()
            && this.OldNUID.toUpperCase() == x.userGUID.toUpperCase())
              this.tableN.cancelRowEdit(x);  
        });
      }
      this.EditNRow = true;
      this.OldNDID = row.departmentGUID;
      this.OldNRID = row.roleGUID;
      this.OldNUID = row.userGUID;
      this.departmentNameNError = '';
      this.roleNameNError = '';
      this.userNameNError = '';    
      this.Nclicked = true;
      this.selectedNotiDepartment = this.departmentsNData.find(x=>x.departmentGuid.toUpperCase() == row.departmentGUID.toUpperCase());
      this.GetRolesByDepartment(row.departmentGUID,row.roleGUID,row.userGUID,Type);
    }
  }
  RESETTEAM()
  {
    this.Tclicked = false;  this.EditTRow = false;
    this.selectedTeamDepartment=''; this.selectedTeamRole=''; this.selectedTeamUser='';
    this.departmentNameError = ''; this.roleNameError = ''; this.userNameError = '';
    this.OldTDID = ""; this.OldTRID = ""; this.OldTUID = ""; 

    this.Aclicked = false;  this.EditARow = false;
    this.selectedApproverDepartment=''; this.selectedApproverRole=''; this.selectedApproverUser='';
    this.departmentNameAError = ''; this.roleNameAError = ''; this.userNameAError = '';
    this.OldADID = ""; this.OldARID = ""; this.OldAUID = ""; 

    this.Nclicked = false;  this.EditNRow = false;
    this.selectedNotiDepartment=''; this.selectedNotiRole=""; this.selectedNotiUser="";
    this.departmentNameNError = ''; this.roleNameNError = ''; this.userNameNError = ''; 
    this.OldNDID = ""; this.OldNRID = ""; this.OldNUID = ""; 
    if(this.PhaseTeamData != null && this.PhaseTeamData != undefined)
    { 
      if(this.tableT != null && this.tableT != undefined) 
        this.tableT.value.forEach(x => { this.tableT.cancelRowEdit(x);  });
      if(this.tableA != null && this.tableA != undefined)
        this.tableA.value.forEach(x => { this.tableA.cancelRowEdit(x);  });
      if(this.tableN != null && this.tableN != undefined)
        this.tableN.value.forEach(x => { this.tableN.cancelRowEdit(x);  });
    } 
    this.PhaseTeamData = this.PhaseTeamData.filter(i => i.departmentName != "");
  }
  addCancelTeam(Type) {
    this.showLoading = true;
    this.TeamError = "";
    this.ApproverError = "";
    this.NotiError = "";
    var allok = false, OrgType = "", selectedDepartment:any, selectedRole:any, selectedUser:any, EditRow:boolean;
    var OldDID = "", OldRID = "", OldUID = "";
    if(Type == "T")
    { 
      this.departmentNameError = ''; this.roleNameError = ''; this.userNameError = '';
      if (this.selectedTeamDepartment == undefined || this.selectedTeamDepartment == '' || this.selectedTeamDepartment == null) {
        this.departmentNameError = 'Please select department.';this.showLoading = false;
      }
      else if (this.selectedTeamRole == undefined || this.selectedTeamRole == '' || this.selectedTeamRole == null) {
        this.roleNameError = 'Please select role.';this.showLoading = false;
      }
      else if (this.selectedTeamUser == undefined || this.selectedTeamUser == '' || this.selectedTeamUser == null) {
        this.userNameError = 'Please select user.';this.showLoading = false;
      }
      else
      {
        allok = true;
        OrgType = "Team";
        selectedDepartment = this.selectedTeamDepartment; selectedRole = this.selectedTeamRole; selectedUser = this.selectedTeamUser;
        EditRow = this.EditTRow;
        OldDID = this.OldTDID; OldRID = this.OldTRID; OldUID = this.OldTUID;
      }
    }
    if(Type == "A")
    { 
      this.departmentNameAError = ''; this.roleNameAError = ''; this.userNameAError = '';
      if (this.selectedApproverDepartment == undefined || this.selectedApproverDepartment == '' || this.selectedApproverDepartment == null) {
        this.departmentNameAError = 'Please select department.';this.showLoading = false;
      }
      else if (this.selectedApproverRole == undefined || this.selectedApproverRole == '' || this.selectedApproverRole == null) {
        this.roleNameAError = 'Please select role.';this.showLoading = false;
      }
      else if (this.selectedApproverUser == undefined || this.selectedApproverUser == '' || this.selectedApproverUser == null) {
        this.userNameAError = 'Please select user.';this.showLoading = false;
      }
      else
      {
        allok = true;
        OrgType = "Approval";
        selectedDepartment = this.selectedApproverDepartment; selectedRole = this.selectedApproverRole; selectedUser = this.selectedApproverUser;
        EditRow = this.EditARow;
        OldDID = this.OldADID; OldRID = this.OldARID; OldUID = this.OldAUID;
      }
    }
    if(Type == "N")
    { 
      this.departmentNameNError = ''; this.roleNameNError = ''; this.userNameNError = '';
      if (this.selectedNotiDepartment == undefined || this.selectedNotiDepartment == '' || this.selectedNotiDepartment == null) {
        this.departmentNameNError = 'Please select department.';this.showLoading = false;
      }
      else if (this.selectedNotiRole == undefined || this.selectedNotiRole == '' || this.selectedNotiRole == null) {
        this.roleNameNError = 'Please select role.';this.showLoading = false;
      }
      else if (this.selectedNotiUser == undefined || this.selectedNotiUser == '' || this.selectedNotiUser == null) {
        this.userNameNError = 'Please select user.';this.showLoading = false;
      }
      else
      {
        allok = true;
        OrgType = "Collaborator";
        selectedDepartment = this.selectedNotiDepartment; selectedRole = this.selectedNotiRole; selectedUser = this.selectedNotiUser;
        EditRow = this.EditNRow;
        OldDID = this.OldNDID; OldRID = this.OldNRID; OldUID = this.OldNUID;
      }
    }
    if(allok)
    {
      let isDuplicate=false;
      if(this.PhaseTeamData!=null&&this.PhaseTeamData.length>0){
        this.PhaseTeamData.forEach(element => {
          if(element.type == OrgType && element.departmentName != ""){
            if (selectedDepartment.departmentGuid.toUpperCase() == element.departmentGUID.toUpperCase() && selectedRole.roleGuid.toUpperCase() == element.roleGUID.toUpperCase()
              && selectedUser.userGuid.toUpperCase() == element.userGUID.toUpperCase()) {
                isDuplicate=true;
            }
          }
      });
      }
        
      if(!isDuplicate){
        if(EditRow)
        {
          this.PhaseTeamData.forEach(element => {
            if (OldDID.toUpperCase() == element.departmentGUID.toUpperCase() && OldRID.toUpperCase() == element.roleGUID.toUpperCase()
              && OldUID.toUpperCase() == element.userGUID.toUpperCase() && element.type ==OrgType) {
                element.departmentGUID = selectedDepartment.departmentGuid;
                element.departmentName = selectedDepartment.departmentName;
                element.roleGUID = selectedRole.roleGuid;
                element.roleName = selectedRole.roleName;
                element.userGUID = selectedUser.userGuid;
                element.userName = selectedUser.fullName;
            }
          });
          this.originalPhaseTeamData.forEach(element => {
            if (OldDID.toUpperCase() == element.departmentGUID.toUpperCase() && OldRID.toUpperCase() == element.roleGUID.toUpperCase()
              && OldUID.toUpperCase() == element.userGUID.toUpperCase() && element.type == OrgType && element.phaseID == this.PhaseTeamData[0].phaseID) {
                element.departmentGUID = selectedDepartment.departmentGuid;
                element.departmentName = selectedDepartment.departmentName;
                element.roleGUID = selectedRole.roleGuid;
                element.roleName = selectedRole.roleName;
                element.userGUID = selectedUser.userGuid;
                element.userName = selectedUser.fullName;
            }
          });
          if(Type == "T")
          {
            this.EditTRow = false; this.OldTDID = ""; this.OldTRID = ""; this.OldTUID = "";
          }
          if(Type == "A")
          {
            this.EditARow = false; this.OldADID = ""; this.OldARID = ""; this.OldAUID = "";
          }
          if(Type == "N")
          {
            this.EditNRow = false; this.OldNDID = ""; this.OldNRID = ""; this.OldNUID = "";
          }
        }
        else
        {
          var highestTeamId= Math.max.apply(Math, this.originalPhaseTeamData.map(function(o) { return o.teamID; }))
          var add =
          {
            "teamID":highestTeamId+1,
            "phaseID":this.PhaseTeamData[0].phaseID,
            "type":OrgType,
            "departmentGUID":selectedDepartment.departmentGuid,
            "departmentName":selectedDepartment.departmentName,
            "roleGUID":selectedRole.roleGuid,
            "roleName":selectedRole.roleName,
            "userGUID":selectedUser.userGuid,
            "userName":selectedUser.fullName
          }
          this.originalPhaseTeamData.push(add);
          this.PhaseTeamData.push(add);
        }
        this.PhaseTeamData = this.PhaseTeamData.filter(i => i.departmentName != "");
        if(Type == "T")
        {
          this.Tclicked = false; this.departmentNameError = ''; this.roleNameError = ''; this.userNameError = '';
          this.selectedTeamDepartment=""; this.selectedTeamRole=""; this.selectedTeamUser="";
        }
        if(Type == "A")
        {
          this.Aclicked = false; this.departmentNameAError = ''; this.roleNameAError = ''; this.userNameAError = '';
          this.selectedApproverDepartment=""; this.selectedApproverRole=""; this.selectedApproverUser="";
        }
        if(Type == "N")
        {
          this.Nclicked = false; this.departmentNameNError = ''; this.roleNameNError = ''; this.userNameNError = '';
          this.selectedNotiDepartment=""; this.selectedNotiRole=""; this.selectedNotiUser="";
        }
      }
      else
      {
        if(Type == "T")
          this.TeamError = 'User Already Exists.';
        if(Type == "A")
          this.ApproverError = 'User Already Exists.';
        if(Type == "N")
          this.NotiError = 'User Already Exists.';
      }
      this.showLoading = false;
    }
    else
      this.showLoading = false;
  }

  SelectDepartment(type){
    this.showLoading = true;
    if(type == "T")
    {
      this.departmentNameError = ""; this.roleNameError = ""; this.userNameError = "";
      this.rolesData = []; this.usersData=[];  this.selectedTeamRole=""; this.selectedTeamUser="";
      this.GetRolesByDepartment(this.selectedTeamDepartment.departmentGuid,"","",type);
    }
    if(type == "A")
    {
      this.departmentNameAError = ""; this.roleNameAError = ""; this.userNameAError = "";
      this.rolesAData = []; this.usersAData=[]; this.selectedApproverRole=""; this.selectedApproverUser="";
      this.GetRolesByDepartment(this.selectedApproverDepartment.departmentGuid,"","",type);
    }
     if(type == "N")
    {
      this.departmentNameNError = ""; this.roleNameNError = ""; this.userNameNError = "";
      this.rolesNData = []; this.usersNData=[];  this.selectedNotiRole=""; this.selectedNotiUser="";
      this.GetRolesByDepartment(this.selectedNotiDepartment.departmentGuid,"","",type);
    }
  }
  GetRolesByDepartment(departmentId,RoleID,UserID,type){
    this.showLoading = true;
    this._productService.GetRoles(departmentId).subscribe(
      (data) => {
        if(data.returnCode==0 && data.returnObject!=null)
        {
          if(type == "T")
            this.rolesData = data.returnObject;
          if(type == "A")
            this.rolesAData = data.returnObject;
          if(type == "N")
            this.rolesNData = data.returnObject;
        }
        if(RoleID != "")
        {
          if(type == "T")
            this.selectedTeamRole = this.rolesData.find(x=>x.roleGuid.toUpperCase() == RoleID.toUpperCase());
          if(type == "A")
            this.selectedApproverRole = this.rolesAData.find(x=>x.roleGuid.toUpperCase() == RoleID.toUpperCase());
          if(type == "N")
            this.selectedNotiRole = this.rolesNData.find(x=>x.roleGuid.toUpperCase() == RoleID.toUpperCase());
        } 
        if(UserID)
        {
          if(type == "T")
            this.getUserList(this.selectedTeamRole.roleGuid,UserID,type);
          if(type == "A")
            this.getUserList(this.selectedApproverRole.roleGuid,UserID,type);
          if(type == "N")
            this.getUserList(this.selectedNotiRole.roleGuid,UserID,type);
        }
        else
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  SelectRole(type){
    this.showLoading = true;
    if(type == "T")
    {
      this.departmentNameError = ""; this.roleNameError = ""; this.userNameError = "";
      this.usersData=[]; this.selectedTeamUser="";
      this.getUserList(this.selectedTeamRole.roleGuid,"",type);
    }
    if(type == "A")
    {
      this.departmentNameAError = ""; this.roleNameAError = ""; this.userNameAError = "";
      this.usersAData=[]; this.selectedApproverUser="";
      this.getUserList(this.selectedApproverRole.roleGuid,"",type);
    }
     if(type == "N")
    {
      this.departmentNameNError = ""; this.roleNameNError = ""; this.userNameNError = "";
      this.usersNData=[];  this.selectedNotiUser="";
      this.getUserList(this.selectedNotiRole.roleGuid,"",type);
    }
  }
  getUserList(roleGuid,UserID,type) {
    this._productService
      .GetUsersByRoleGuid(roleGuid)
      .subscribe((result: any) => {
        if(result.returnObject != undefined && result.returnObject != null)
        {
          result.returnObject.forEach((value, key) => {
            value.fullName= value.firstName + " "+value.lastName;
          });
        }
        if(type=="T"){
          this.usersData = result.returnObject;
          if(UserID != "")
            this.selectedTeamUser = this.usersData.find(x=>x.userGuid.toUpperCase() == UserID.toUpperCase());
        }
        if(type=="A"){
          this.usersAData = result.returnObject;
          if(UserID != "")
            this.selectedApproverUser = this.usersAData.find(x=>x.userGuid.toUpperCase() == UserID.toUpperCase());
        }
        if(type=="N"){
          this.usersNData = result.returnObject;
          if(UserID != "")
            this.selectedNotiUser = this.usersNData.find(x=>x.userGuid.toUpperCase() == UserID.toUpperCase());
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
  }
  
  function(e) {   this.index = e.index;   }
  
  onRowEditCancel(teamModel: any,type) {
    this.showLoading = true;
    this.TeamError = "";
    this.ApproverError = "";
    this.NotiError = "";
    if(type == "T")
    {
      this.Tclicked = false;
      if(!this.EditTRow)
        this.PhaseTeamData = this.PhaseTeamData.filter(i => i.departmentName != teamModel.departmentName && i.roleName != teamModel.roleName && i.userName != teamModel.userName);
      this.selectedTeamDepartment='';
      this.selectedTeamRole='';
      this.selectedTeamUser='';
      this.departmentNameError = '';
      this.roleNameError = '';
      this.userNameError = '';  
      this.EditTRow = false;
      this.OldTDID = "";
      this.OldTRID = "";
      this.OldTUID = "";  
    }
    if(type == "A")
    {
      this.Aclicked = false;
      if(!this.EditARow)
        this.PhaseTeamData = this.PhaseTeamData.filter(i => i.departmentName != teamModel.departmentName && i.roleName != teamModel.roleName && i.userName != teamModel.userName);
      this.selectedApproverDepartment='';
      this.selectedApproverRole='';
      this.selectedApproverUser='';
      this.departmentNameAError = '';
      this.roleNameAError = '';
      this.userNameAError = '';  
      this.EditARow = false;
      this.OldADID = "";
      this.OldARID = "";
      this.OldAUID = "";  
    }
    if(type == "N")
    {
      this.Nclicked = false;
      if(!this.EditNRow)
        this.PhaseTeamData = this.PhaseTeamData.filter(i => i.departmentName != teamModel.departmentName && i.roleName != teamModel.roleName && i.userName != teamModel.userName);
      this.selectedNotiDepartment='';
      this.selectedNotiRole='';
      this.selectedNotiUser='';
      this.departmentNameNError = '';
      this.roleNameNError = '';
      this.userNameNError = '';  
      this.EditNRow = false;
      this.OldNDID = "";
      this.OldNRID = "";
      this.OldNUID = "";  
    }
    this.showLoading = false;
  }

  Delete(row,type){
    this.TeamError = "";
    this.ApproverError = "";
    this.NotiError = "";
    if(type == "T")
    {
      if(this.tableT != null && this.tableT != undefined)
        this.tableT.value.forEach(x => { this.tableT.cancelRowEdit(x);  });
      this.Tclicked = false;
      this.TANID=row.teamID;
      var teamDa = this.PhaseTeamData.filter(m=>m.type=="Team");
      if(teamDa!=null && teamDa.length==1){
        this.TeamError = "There should be at least one member.";
        return;
      }
      else{
        this.deleteTDisplay=true;
        this.AreYouSureText="Are you sure you want to delete Team Member?";
      }
    }
    if(type == "A")
    {
      if(this.tableA != null && this.tableA != undefined)
        this.tableA.value.forEach(x => { this.tableA.cancelRowEdit(x);  });
      this.Aclicked = false;
      this.TANAID=row.teamID;
      var apprDa = this.PhaseTeamData.filter(m=>m.type=="Approval");
      if(apprDa!=null && apprDa.length==1){
        this.ApproverError = "There should be at least one member.";
         return;
      }
      else{
        this.deleteADisplay=true;
        this.AreYouSureText="Are you sure you want to delete Approver Member?";
      }
    }
    if(type == "N")
    {
      this.tableN.value.forEach(x => { this.tableN.cancelRowEdit(x);  });
      this.Nclicked = false;
      this.TANNID=row.teamID;
      var NotiDa = this.PhaseTeamData.filter(m=>m.type=="Collaborator");
      if(NotiDa!=null && NotiDa.length==1){
        this.NotiError = "There should be at least one member.";
        return;
      }
      else{
        this.deleteNDisplay=true;
        this.AreYouSureText="Are you sure you want to delete Notification Recipient Member?";
      }
    }
  }

  deleteTeam(TYPE){
    let originalPhaseTeam=[];
    var TT = "", TeamID = 0;
    if(TYPE == "T")
    {
      TT = "Team";
      TeamID = this.TANID;
    }
    if(TYPE == "A")
    {
      TT = "Approval";
      TeamID = this.TANAID;
    }
    if(TYPE == "N")
    {
      TT = "Collaborator";
      TeamID = this.TANNID;
    }
    this.originalPhaseTeamData.forEach(element => {
      if(element.teamID!=TeamID)
        originalPhaseTeam.push(element);
    });
    this.originalPhaseTeamData=originalPhaseTeam;
    let PhaseTeamData=[];
    this.PhaseTeamData.forEach(element => {
      if(element.teamID!=TeamID)
        PhaseTeamData.push(element);
    });
    this.PhaseTeamData=PhaseTeamData;
    if(TYPE == "T")
      this.deleteTDisplay=false;
    if(TYPE == "A")
      this.deleteADisplay=false;
    if(TYPE == "N")
      this.deleteNDisplay=false;
  }

  FirstStepEdit(){
    this.GetCustomGroups(this.selectedTemplate.templateGUID,"");
    this.activeIndex=0;
  }

  ThirdStepEdit(){
    this.isFromThirdStepFromLogin= true;
    this.selectedPhaseOwner = [];
    this.phaseOwnerData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID && m.type.toLowerCase() == "team");
    this.selectedPhaseOwner = this.phaseOwnerData.find(x=> x.userGUID.toUpperCase() == this.PhasesData[0].phaseOwnerGUID.toUpperCase() );
    if(this.originalPhaseTaskData!=null && this.originalPhaseTaskData.length>0){
      this.PhaseTaskData=this.originalPhaseTaskData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
      this.BindTaskTeam(this.PhasesData[0].phaseID);
    }
    if(this.originalPhaseDocsData!=null && this.originalPhaseDocsData.length>0){
    this.PhaseDockData=this.originalPhaseDocsData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
    this.ngAfterViewInit();
    }
    this.activeIndex=2;
  }

  SecondStepEdit(){
    this.isFromSecondStepFromLogin= true;
    this.GetDepartments();
    if(this.originalPhaseTeamData!=null && this.originalPhaseTeamData.length>0){
      this.PhaseTeamData = this.originalPhaseTeamData.filter(m=>m.phaseID==this.PhasesData[0].phaseID);
      }
    this.activeIndex=1;
  }
  OwnerChange(event,id) {
    this.showLoading=true;
    var originalPhaseTeam=this.PhasesData.find(m=>m.phaseID==id);
    if(originalPhaseTeam!=null){
      originalPhaseTeam.phaseOwnerGUID = event.value.userGUID;
    }
    this.showLoading = false;
  }

  FinalStep(){
    console.log("Final Step:  "+ this.tmpProductId);
    this.showLoading=true;
    this._productService
      .CreateProduct(this.tmpProductId,this.userId)
      .subscribe((data) => {
        this.showLoading=false;
        if(data.returnCode==0){
          this.router.navigate(['/productcreation']);
        }
        else{
         this.Step4Error=data.returnMessage;
        }
      },(error) => {
        this.showLoading = false;
        console.log(error);
      } );
  }

  OverrideChange(id,event,phaseID){
    const checked = event.target.ariaChecked;
    if (checked) {
      var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        originalPhaseTeam.override=true;
      }
    }
    else {
      var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        originalPhaseTeam.override=false;
      }
    }
    // this.Getdates(phaseID);
  }

  IsWeekedRequired(id,event,phaseID){
    const checked = event.target.ariaChecked;
    if (checked) {
      var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        originalPhaseTeam.isWeekend=true;
      }
    }
    else {
      var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        originalPhaseTeam.isWeekend=false;
      }
    }
    this.Getdates(phaseID);

  }

  AssigneeChange(event,id) {
    this.showLoading=true;
    var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
    if(originalPhaseTeam!=null){
      originalPhaseTeam.taskAssignees=[];
      originalPhaseTeam.taskAssignees.push(event.value);
      originalPhaseTeam.assignTo = event.value.assignedName;
    }
    this.showLoading = false;
  }

  AssigneeMultiChange(event,id) {
    this.showLoading=true;
    var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
    if(originalPhaseTeam!=null){
      originalPhaseTeam.assignTo  = "";
      var a = "";
      if(event.value.length > 0)
      {
        event.value.forEach(q => {
          a = a + q.assignedName + ", ";
        });
      }
      originalPhaseTeam.assignTo = a.trim();
    }
    this.showLoading = false;
  }

  IsAllowMuultipleRequired(id,event){
    const checked = event.target.ariaChecked;
    if (checked) {
      var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        originalPhaseTeam.allowMultipleAssignee=true;
      }
    }
    else {
      var originalPhaseTeam=this.originalPhaseTaskData.find(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        originalPhaseTeam.allowMultipleAssignee=false;
        var t = originalPhaseTeam.taskAssignees[0];
        originalPhaseTeam.taskAssignees1 = t;
        originalPhaseTeam.taskAssignees=[];
        originalPhaseTeam.taskAssignees.push(t);
        originalPhaseTeam.assignTo = t.assignedName;
      }
      var team = this.PhaseTaskData.filter(m=>m.taskID==id);
      if(originalPhaseTeam!=null){
        var t = team.taskAssignees[0];
        team.taskAssignees1 = t;
        team.taskAssignees=[];
        team.taskAssignees.push(t);
        team.assignTo = t.assignedName;
      }
    }
  }
  deleteDoc(){
    let originalPDocsData=[];
    this.originalPhaseDocsData.forEach(element => {
      if(element.requiredDocumentID!=this.docId){
        originalPDocsData.push(element);
      }
    });
    this.originalPhaseDocsData=originalPDocsData;

    let PhaseDData=[];
    this.PhaseDockData.forEach(element => {
      if(element.requiredDocumentID!=this.docId){
        PhaseDData.push(element);
      }
    });
    this.PhaseDockData=PhaseDData;
    this.deleteDisplay=false;
  }
}