<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">General Counsel Product Export Control Classification (ECCN)</h5>
        </div>
        <hr />
        <div class="card-header" style="text-align: center;" *ngIf="ECCNAvailable">
            <h5 style="color:red; padding:15px 0 0 15px;font-weight: bolder;width: 100%;">ECCN has already been assigned for {{ Product?.fgPart }}</h5>
        </div>
        <div class="card-header" style="text-align: center;" *ngIf="!ISGC">
            <h5 style="color:red; padding:15px 0 0 15px;font-weight: bolder;width: 100%;">Only General Counsel can enter the ECCN</h5>
        </div>
        <div class="card-header" style="text-align: center" *ngIf="ProductNot" >
            <h5 style="color:red; padding:15px 0 0 15px;font-weight: bolder;width: 100%;">Product not available</h5>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12" style="margin-bottom:-10px;" *ngIf="Reminder">
                <div class="p-grid">
                    <br />
                    <div class="p-col-10 p-md-10"> &nbsp;</div>
                    <div class="p-col-2 p-md-2" style="padding-right: 15px;">
                        <a (click)="ECCNReminder()"> <button pButton pRipple type="button" icon="pi pi-bell" iconPos="left"
                                label="Send ECCN Reminder" class="p-button-raised p-button-primary p-mr-4 p-mb-4"></button></a>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-xl-4">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                            <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;">Project:</span>
                                    <span>{{ Product?.productName }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Die Code:</span>
                                    <span>{{ Product?.dieCode }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Product Engineer:</span>
                                    <span>{{ Product?.productOwner }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Product Line:</span>
                                    <span>{{ Product?.spaceName }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-xl-4">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                            <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;">FG Part #:</span>
                                    <span>{{ Product?.fgPart }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Mfg Part:</span>
                                    <span>{{ Product?.mfgPart }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Project Status:</span>
                                    <span>{{ Product?.productStatus }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Project Description:</span>
                                    <span>{{ Product?.projectDescription }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-xl-4">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1"
                            style="min-height:80px; overflow-y: auto;">
                            <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;">Requested Date:</span>
                                    <span>{{ Product?.requestedDate | date:'MM/dd/yyyy' }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Product Definer:</span>
                                    <span>{{ Product?.productDefiner }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Current Phase:</span>
                                    <span>{{ Product?.currentPhase }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;">Description:</span>
                                    <span>{{ Product?.description }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="ECCNAvailable">
                    <div class="p-grid">
                    <div class="p-col-12 p-md-6">
                    <label><b>ECCN: {{Product?.eccn}}</b></label>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <label><b>ECCN Document: </b></label>
                        <span *ngIf="Product.eccnDocID > 0"><a href="javascript:;" (click)="DMSToken1()">{{ Product?.eccnDocCode }}</a></span>
                        <span *ngIf="Product.eccnDocID == 0">{{ Product?.eccnDocCode }}</span>
                    </div>
                    </div>
                    <br><br><br><br>
                </div>

            <!-- <div class="card" *ngIf="!ECCNAvailable && ISGC && !ProductNot && Product?.productStatus == 'In-Process'"> -->
            <div class="card" *ngIf="!ECCNAvailable && ISGC && !ProductNot && (Product.productStatus == 'In-Process' || Product.productStatus == 'Waiting for Discontinuation' || Product.productStatus == 'Seek Clarification for Discontinuation' || Product.productStatus == 'Discontinued' || Product.productStatus == 'Hold')">
                <div class="p-grid">
                <div class="p-col-12 p-md-3"></div>
                <div class="p-col-12 p-md-6">
                <label><b>ECCN</b><span style="color: red">*</span></label>
                <input type="text" pInputText placeholder="Enter ECCN" maxlength="15" [(ngModel)]="ECCN" />
                </div>
                <div class="p-col-12 p-md-3"><br><small class="p-invalid">{{ ECCNError }}</small></div>
                <div class="p-col-12 p-md-3"></div>
                <div class="p-col-12 p-md-6">
                    <label><b>Message</b></label> <small class="p-invalid" style="float: right;">{{1000 - Message.length}} characters are remaining</small>
                    <textarea rows="6" pInputTextarea id="Message" [(ngModel)]="Message"
                    placeholder="Enter your comments" required="true" requiredMessage="please enter Message." maxlength="1000" ng-trim="false"
                    style="width: 100%;"></textarea>
                </div>
                <div class="p-col-12 p-md-3"><br><small class="p-invalid">{{ MessageError }}</small></div>
                <div class="p-col-12 p-md-3"></div>
                <div class="p-col-12 p-md-6">
                    <label><b>Document</b><span style="color: red">*</span></label>
                    <form>
                        <p-fileUpload #fileInput name="file" multiple="multiple" maxFileSize="104857600"
                            accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.rar,.zip"
                            [showUploadButton]="false" [showCancelButton]="false" fileLimit="1" auto="true">
                            <ng-template pTemplate="content">
                                <ul *ngIf="fileInput.length">
                                    <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                    </form>
                </div>
                <div class="p-col-12 p-md-3"><br><small class="p-invalid">{{ FileError }}</small></div>
                <div class="p-col-12 p-md-6"></div>
                <div class="p-col-12 p-md-3">
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Save ECCN"  class="p-button-success" (click)="savedata()"
                    style="width:180px; float:right; margin-left:10px;margin-top: 10px;"></button>
                </div> 
                <div class="p-col-12 p-md-3"></div>
                </div>
                <br><br><br><br>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>


<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>