<div class="p-col-12">
    <div class="card">
        <div class="card-header" style=" border-radius: 5px;height:40px; margin-bottom:5px; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
            <span class="p-input-icon-left" style="float:right; margin-right:5px;">  
                
                <label style="padding-right:10px; margin-left:10px;">Product Line:</label>
           <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Line"  optionLabel="name" [options]="line" [(ngModel)]="selectedLine" >
                <ng-template let-value pTemplate="selectedItems">
                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                        <div>option.name</div>
                    </div>
                    <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                        Select Product Line
                    </div>
                </ng-template>
            </p-multiSelect> 
            <label style="padding-right:10px; margin-left:10px;">Product Status:</label>
            <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Status" [options]="status" [(ngModel)]="selectedStatus">
                <ng-template let-value pTemplate="selectedItems">
                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                        <div>option.name</div>
                    </div>
                    <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                        Select Product Status
                    </div>
                </ng-template>
            </p-multiSelect>
              <a (click)="getproducts('','false')">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" class="p-button-raised p-button-primary "  style="width:auto; float:right; margin-left:10px;"></button>
            </a>
         </span>
        </div> 
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Product Attributes</h5> 
        <span class="p-input-icon-left" style="margin-top: 10px;" *ngIf="userFeature">
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download All Product Attributes" tooltipPosition="top" 
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" label="All Products Attributes"
                style="float: right; margin-left:15px; width:200px; height:25px;" (click)="AllDownload()"></button>
        </span>

        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
            Selected Products - {{ SelectedCount }}
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download Multiple Product Attributes" tooltipPosition="top" 
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" label="Multiple Products Attributes"
                style="float: right; margin-left:15px; width:235px; height:25px;" (click)="multipledownload()"></button>
        </span>
    </div>
    <p-accordion>
        <p-accordionTab header="Products" [selected]="productaccordian"  >
    <div class="invoice invoice-header" id="invoice-content">  
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Products - {{ProductCount}}</h5> 
            <span class="p-input-icon-left" style="float: right; margin-top: 10px">
                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('invoice-content','trsearch')" 
                    class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                    style="float: right; width:25px; height:25px;"></button>
                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                    class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                    style="float: right; margin-left:15px; width:25px; height:25px;" (click)="download('excel-table')"></button>
            </span>
        </div>         
 <p-table #dt [value]="products" id="excel-table" [columns]="cols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" [(selection)]="selectedProducts" (selectionChange)="onSelectionChange($event)" dataKey="productID"
[scrollable]="true" scrollHeight="500px" > 
<ng-template pTemplate="header" let-columns class="invoice-items">
    <tr>
        <th style="width: 3rem;"></th>
        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
        [ngClass]="{'dieCode': i == 1, 'productLine': i == 3,  'phase': i == 6,'w':i==0 || i == 2 || i == 4 || i == 5,'w1':i==7}" >
        <!-- [ngClass]="{'productName': i == 0,'dieCode': i == 1,'mfg':i == 2, 'productLine': i == 3,  'phase': i == 6}" > -->
        {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
        <th style="text-align: center;width: 80px;">Actions</th>
    </tr>
    <tr id="trsearch">
        <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            <!-- <p-tableHeaderCheckbox [(ngModel)]="headerCheckbox" binary="headerCheckbox" (click)="allChecked($event)"></p-tableHeaderCheckbox>                                      -->
        </th>
        <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
            <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
        </th>
        <th></th>
    </tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns">
<tr [pSelectableRow]="rowData" title="Select" [style.background]="rowData.select == true ? '#e8ccc2' : '' ">
    <td style="text-align: center;width: 3rem;">
        <!-- <p-tableCheckbox (click)="selectedId(rowData,$event)" [value]="rowData.rowcheck"></p-tableCheckbox> -->
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
    </td>
  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width:15%; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 1" style="text-align: left; padding-left:15px;width:100px; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width:15%; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:110px; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 4" style="text-align: left; padding-left:15px; width:15%; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:15%; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 6" style="text-align: center; width:140px; cursor: pointer;font-weight: bold;" (click)="SelectProduct(rowData)" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080' :rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td> 
    <td *ngIf="i == 7" style="text-align: left; padding-left:15px; width:10%; cursor: pointer;" (click)="SelectProduct(rowData)">{{ rowData[col.field] }}</td>
  </ng-container>
  <td style="text-align: center;width: 80px;">
    <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="Details(rowData.productID, rowData.phaseID,rowData.type)"
    icon="pi pi-eye" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
  </td>
</tr>
</ng-template>
<ng-template pTemplate="emptymessage">
<tr>
    <td colspan="9" style="text-align: left;">No records found...</td>
</tr>
</ng-template>

 </p-table>
    </div>
</p-accordionTab>
</p-accordion>
<div *ngIf="attdisplay">
    <div id="productdetails">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12"></div>
        <div class="p-col-12 p-md-4 p-xl-4">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                <ul class="widget-image-list">
                <li>
                    <span style="font-weight: bold;">Project:</span>
                    <span>{{ productName }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Die Code:</span>
                    <span>{{ dieCode }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Product Engineer:</span>
                    <span>{{ productOwner }}</span>
                </li>
                </ul>
            </div>
        </div>
        <div class="p-col-12 p-md-4 p-xl-4">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px;">
                <ul class="widget-image-list">
                <li>
                    <span style="font-weight: bold;">Product Line:</span>
                    <span class="listitem-value">{{ productline }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Mfg Part:</span>
                    <span>{{ mfgPart }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Project Status:</span>
                    <span>{{ productStatus }}</span>
                </li>
                </ul>
            </div>
        </div>
        <div class="p-col-12 p-md-4 p-xl-4">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:80px; overflow-y: auto;">
                <ul class="widget-image-list">
                <li>
                    <span style="font-weight: bold;">Current Phase:</span>
                    <span class="listitem-value">{{ currentPhase }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Project Description:</span>
                    <span>{{ projectDescription }}</span>
                </li>
                <li>
                    <span style="font-weight: bold;">Current Phase Status:</span>
                    <span>{{ currentphasestatus }}</span>
                </li>
                </ul>
            </div>
        </div>
    </div>
    </div>
    <p-accordion>
        <p-accordionTab header="Product Attributes - {{AttrCount}}" [selected]="true">
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Product Attributes</h5> 
        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('invoice-content1','trsearch1')" 
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left:15px; width:25px; height:25px;" (click)="download('excel-table5')"></button>
        </span>
    </div>

    <div class="invoice invoice-header" id="invoice-content1" >     
        <p-table #dtatt [value]="attributes" id="excel-table1" [columns]="attcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
            styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedattributes"> 
            <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
            </tr>
            <tr id="trsearch1">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                <input pInputText type="text" (input)="test1($event.target.value,col.field,dtatt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
            </th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 3" style="text-align: center;">
                    <span *ngIf="rowData.isChangeMangement === true">Yes</span><span *ngIf="rowData.isChangeMangement === false">No</span>
                </td>
                </ng-container>
            </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4" style="text-align: left;">No records found...</td>
            </tr>
            </ng-template>
        </p-table>
       </div>
</p-accordionTab>
</p-accordion>
    </div>
    <div *ngIf="teamdisplay">
        <p-accordion>
            <p-accordionTab header="Product Team - {{TeamCount}}" [selected]="true">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px;">Product Team</h5> 
                    <span class="p-input-icon-left" style="float: right; margin-top: 10px">
                        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('invoice-content2','trsearch2')" 
                            class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                            style="float: right; width:25px; height:25px;"></button>
                        <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                            style="float: right; margin-left:15px; width:25px; height:25px;" (click)="download('excel-table2')"></button>
                    </span>
                </div>
       <div class="invoice invoice-header" id="invoice-content2">           
        <p-table #dtteam [value]="teams" id="excel-table2" [columns]="teamcols" sortMode="multiple" [loading]="loading" [autoLayout]="true"
       styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedteams"> 
       <ng-template pTemplate="header" let-columns class="invoice-items">
       <tr>
           <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
           {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
       </tr>
       <tr id="trsearch2">
         <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
           <input pInputText type="text" (input)="test2($event.target.value,col.field,dtteam)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
       </th>
       </tr>
       </ng-template>
       <ng-template pTemplate="body" let-rowData let-columns="columns">
       <tr [pSelectableRow]="rowData">
         <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
           <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
           <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
           <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
           <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
           <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>        
        </ng-container>
       </tr>
       </ng-template>
       <ng-template pTemplate="emptymessage">
       <tr>
           <td colspan="5" style="text-align: left;">No records found...</td>
       </tr>
       </ng-template>
       
        </p-table>
           </div>
    </p-accordionTab>
    </p-accordion>
        </div>
    </div>
</div>


<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !important;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>


      <div style="width: 0px; height: 0px;">
        <p-table #dtmulti [value]="multipleproducts" id="excel-table4" [columns]="mulcols" [loading]="loading" [autoLayout]="true"
        styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"> 
        <ng-template pTemplate="header" let-columns class="invoice-items">
        <tr>    
            <th *ngFor="let col of columns;" >{{ col.header }}</th>
        </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr><td *ngFor="let col of columns">{{rowData[col.field]}}</td></tr>
            </ng-template>
            </p-table>

            <p-table #dtsin [value]="singleproducts" id="excel-table5" [columns]="mulcols" [loading]="loading" [autoLayout]="true"
        styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"> 
        <ng-template pTemplate="header" let-columns class="invoice-items">
        <tr>    
            <th *ngFor="let col of columns;" >{{ col.header }}</th>
        </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr><td *ngFor="let col of columns">{{rowData[col.field]}}</td></tr>
            </ng-template>
            </p-table>
    </div>



<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>