<div class="p-col-12">
    <div class="card">
        <div class="card-header" style=" border-radius: 5px;height:40px; margin-bottom:5px; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;">
            </h5>
            <span class="p-input-icon-left" style="float:right; margin-right:5px;">   
                <button [disabled]="userFeature" pButton type="button" label="Create New" iconPos="left" class="p-button-raised p-button-primary" (click)="CreateNew()" 
                icon="pi pi-plus" style="margin-left:5px ; height:35px;"></button>
         </span>
        </div> 
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Product Creation - {{ProductCount}}</h5> 
        <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
            <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'startsWith')"
            placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('trSearch')" ></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px;" (click)="download()"></button>
          </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">           
 <p-table #dt [value]="productsData" id="excel-table" [columns]="cols" sortMode="multiple" [autoLayout]="true"
styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"
[globalFilterFields]="['productLine','productName','templates']"  dataKey="tmpProductId"> 
<ng-template pTemplate="header" let-columns class="invoice-items">
<tr>              
    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
    [ngClass]="{'productLine': i == 0,'productName': i == 1, 'templates': i == 2}" >
    {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
  <th style="text-align: center">Actions</th>
</tr>
<tr id="trSearch">
  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
    <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px"/>
</th>
  <th style="text-align: center"></th>
</tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns">
<tr [pSelectableRow]="rowData">
  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
  </ng-container>
  <td style="text-align: center;">
    <button pButton type="button" [disabled]="userFeature" pTooltip="View" tooltipPosition="right" (click)="ViewProduct(rowData.tmpProductID)"
    icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
  </td>
</tr>
</ng-template>
<ng-template pTemplate="emptymessage">
<tr>
    <td colspan="4" style="text-align: left;">No records found...</td>
</tr>
</ng-template>

 </p-table>
    </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>