import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import { catchError } from "rxjs/operators";

import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { BASE_API_URL_TOKEN } from '../injectors';
import { Inject } from '@angular/core';
import { API_ENDPOINTS } from "../app.component";
@Injectable({
  providedIn: "root",
})
export class HttputilityService {
  constructor(private http: HttpClient,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) {}
    public httpOptions1 = {
      headers: new HttpHeaders({
        "Content-Type": "blob",
      }),
    };

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  public httpOptionsFile = {
    headers: new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
    }),
  };
  Get(routeUrl, data): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetU(routeUrl, data): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.UMSAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetI(routeUrl, data): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetP(routeUrl, data): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetD(routeUrl, data): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  Add(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.PLMAPI + routeUrl,
      data,
      this.httpOptions
    );
  }
  downloadFile(routeUrl,data): any {
    return this.http.post<any>(
      API_ENDPOINTS.UMSAPI + routeUrl,data,{responseType: 'blob' as 'json'}
    );
  }
  downloadDMSFile(routeUrl): any {
    return this.http.get(API_ENDPOINTS.PLMAPI + routeUrl, {responseType: 'blob'});
  }
  FormDataPost(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.PLMAPI + routeUrl,
      data,
      this.httpOptionsFile
    );
  }
  PostU(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.UMSAPI + routeUrl,
      data,
      this.httpOptions
    );
  }
  handleError(error) {
    return throwError(error.message);
  }
  AddAuthh(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.UMSAuthAPI + routeUrl, data, this.httpOptions);
  }
}
